import axios from 'axios'
import { StringOrNumber } from 'src/models/common'
import { getApiUrl } from 'src/apis/apiUtils';
import { MacroOperationSegmentUpsertParams } from 'src/models/api/macroOperationSegmentRequest';
import { MacroOperationSegment, convertMacroOperationSegmentResponse } from 'src/models/macroOperationSegment';

function getResourceBaseUrl(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, macroOperationMasterId: StringOrNumber): string {
  return getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/macro_operation_masters/${macroOperationMasterId}/macro_operation_segments`)
}

export default {
  async upsert(params: MacroOperationSegmentUpsertParams): Promise<MacroOperationSegment> {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      macro_operation_master_id: macroOperationMasterId,
      ...paramsRest
    } = params

    const { data } = await axios.post(
      `${getResourceBaseUrl(workplaceId, budgetGroupId, macroOperationMasterId)}/upsert`,
      paramsRest
    )
    return convertMacroOperationSegmentResponse(data)
  },
}
