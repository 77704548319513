

































































































































































































































import {
  defineComponent,
  type PropType,
  ref,
  type SetupContext,
  watch,
  reactive,
  onMounted,
} from '@vue/composition-api';
import keyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import forkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import customFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import customFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import customFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import type { SearchParams } from '../../types';
import { StaffGenderSelectOptions, StaffTypeSelectOptions } from 'src/consts';
import { isExist } from 'src/util/isExist';
import ListSwitchPanel from 'src/views/Dashboard/Workplace/Settings/ListSwitchPanel.vue';
import type { StaffAgency } from 'src/models/staffAgency';
import type { Staff } from 'src/models/staff';
import type { BudgetGroup } from 'src/models/budgetGroup';
import type { StaffLabel } from 'src/models/staffLabel';
import type { TimetableMaster } from 'src/models/timetableMaster';
import type { MacroOperationMaster } from 'src/models/macroOperationMaster';
import type { WorkplaceExtension } from 'src/models/workplaceExtension';
import staffAgencyApi from 'src/apis/workplace_masters/staff_agency';
import staffLabelApi from 'src/apis/workplace_masters/staff_label';
import staffApi from 'src/apis/workplace_masters/staff';
import budgetGroupApi from 'src/apis/workplace_masters/budget_group';

type State = {
  selectedStaffs: Array<Staff>;
  staffAgencies: Array<StaffAgency>;
  staffLabels: Array<StaffLabel>;
  budgetGroups: Array<BudgetGroup>;
  selectedTimetableMasters: Array<TimetableMaster>;
  selectedMacroOperationMasters: Array<MacroOperationMaster>;
};

export default defineComponent({
  components: {
    keyPlayerIcon,
    forkliftIcon,
    customFlag1Icon,
    customFlag2Icon,
    customFlag3Icon,
    ListSwitchPanel,
  },
  props: {
    initialSearchParams: {
      type: Object as PropType<SearchParams>,
      required: true,
    },
    workplaceExtension: {
      type: Object as PropType<WorkplaceExtension>,
      required: true,
    },
    workplaceId: {
      type: Number,
      required: true,
    },
    macroOperationMasters: {
      type: Array as PropType<Array<MacroOperationMaster>>,
      required: true,
    },
    timetableMasters: {
      type: Array as PropType<Array<TimetableMaster>>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const searchParams = ref<SearchParams>(props.initialSearchParams);

    const state = reactive<State>({
      selectedStaffs: [],
      staffAgencies: [],
      staffLabels: [],
      budgetGroups: [],
      selectedTimetableMasters: [],
      selectedMacroOperationMasters: [],
    });

    const staffMasters = ref<Staff[]>([]);

    const onBudgetGroupChanged = async (): Promise<void> => {
      loadStaffMasters();
      selectStaffMasters();
      selectMacroOperationMasters();
      selectTimetableMasters();
      initializeSearchStaffsParams();
    };

    const initializeSearchStaffsParams = (): void => {
      searchParams.value.staff = null;
      searchParams.value.macroOperationMasterId = null;
      searchParams.value.timetableMasterId = null;
    };

    const getStaffsWithUpdatedSearchParams = async (params: { displayDisabledModel: boolean }): Promise<void> => {
      searchParams.value.displayListDisabledItem = params.displayDisabledModel;
    };

    watch(searchParams, () => {
      context.emit('update-search-params', searchParams);
    });

    onMounted(async () => {
      const { data: staffAgencies } = await staffAgencyApi.index({
        workplaceId: props.workplaceId,
        params: { is_enabled: true },
      });

      state.staffAgencies = staffAgencies;

      const { data: staffLabels } = await staffLabelApi.index({
        workplaceId: props.workplaceId,
        params: { is_enabled: true },
      });

      state.staffLabels = staffLabels;

      selectTimetableMasters();

      selectMacroOperationMasters();

      await loadStaffMasters();
      selectStaffMasters();

      const { data: budgetGroups } = await budgetGroupApi.index({
        workplaceId: props.workplaceId,
        params: { is_enabled: true },
      });

      state.budgetGroups = budgetGroups;
    });

    const selectMacroOperationMasters = () => {
      const budgetGroup = searchParams.value.budgetGroup;
      state.selectedMacroOperationMasters =
        budgetGroup !== null
          ? props.macroOperationMasters.filter(
              (macroOperationMaster) => macroOperationMaster.budget_group_id === budgetGroup.id,
            )
          : props.macroOperationMasters;
    };

    const selectTimetableMasters = () => {
      const budgetGroup = searchParams.value.budgetGroup;
      state.selectedTimetableMasters =
        budgetGroup !== null
          ? props.timetableMasters.filter((timetableMaster) => timetableMaster.budget_group_id === budgetGroup.id)
          : props.timetableMasters;
    };

    const loadStaffMasters = async (): Promise<void> => {
      const { data: staffs } = await staffApi.index({
        workplaceId: props.workplaceId,
        params: { is_enabled: true },
      });
      staffMasters.value = staffs;
    };

    const selectStaffMasters = () => {
      const budgetGroup = searchParams.value.budgetGroup;
      state.selectedStaffs =
        budgetGroup !== null
          ? staffMasters.value.filter((staffMaster) => staffMaster.budget_group_id === budgetGroup.id)
          : staffMasters.value;
    };

    return {
      state,
      searchParams,
      StaffGenderSelectOptions,
      StaffTypeSelectOptions,
      isExist,
      getStaffsWithUpdatedSearchParams,
      onBudgetGroupChanged,
    };
  },
});
