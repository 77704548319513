<script>
/**
 * src/components/UIComponents/Charts/LineChart.jsを使っていないのはoptionsの変更時にチャートを更新したいため
 * .vueにしているのは型エラーを回避するため（.vueから.jsを直接importする場合はでなかった型エラーがindex.tsを経由すると出るようになる）
 */
import { Line, mixins } from 'vue-chartjs'
import AnnotationPlugin from 'chartjs-plugin-annotation'
import { createVueEmitterPlugin } from './createVueEmitterPlugin'

export default {
  created() {
    this.addPlugin(AnnotationPlugin)
    this.addPlugin(createVueEmitterPlugin(this))
  },
  extends: Line,
  mixins: [mixins.reactiveProp],
  mounted() {
    this.renderChart(this.chartData, this.options)
  },
  props: {
    options: {
      type: Object,
    },
    /**
     * width, height, stylesのデフォルト値を変更（width, heightを指定しないことで親要素に合わせて幅と高さが決定される）
     * @see {@link https://github.com/apertureless/vue-chartjs/blob/v3.5.1/src/BaseCharts.js#L31}
     */
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
    styles: {
      type: Object,
      default: () => ({ height: '100%' }),
    },
  },
  watch: {
    options() {
      if (this.$data._chart) {
        /**
         * @see {@link https://github.com/apertureless/vue-chartjs/issues/195#issuecomment-516495261}
         * @see {@link https://www.chartjs.org/docs/2.9.4/developers/updates.html#updating-options}
         */
        this.$data._chart.options = this.options
        this.$data._chart.update()
      }
    },
  },
}
</script>
