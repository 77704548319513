















import { defineComponent } from '@vue/composition-api'
import BackgroundProcessStatusListRow from './BackgroundProcessStatusListRow.vue'
import BackgroundProcessStatusListCell from './BackgroundProcessStatusListCell.vue'

export default defineComponent({
  components: {
    BackgroundProcessStatusListRow,
    BackgroundProcessStatusListCell,
  },
})
