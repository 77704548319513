
















import { defineComponent, watch, type PropType } from '@vue/composition-api'
import { type TimetableLabel } from 'src/models/timetableLabel'

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<TimetableLabel | null>,
      default: null,
    },
    timetableLabels: {
      type: Array as PropType<TimetableLabel[]>,
      required: true,
    },
  },
  setup(props, context) {
    // 選択可能な工程ラベルに選択中の工程ラベルが含まれていなければ、選択可能な工程ラベルの先頭のものを選択中にする
    watch(
      [() => props.value, () => props.timetableLabels],
      () => {
        const hasValue = props.timetableLabels.some((timetableLabel) => timetableLabel.id === props.value?.id)
        const newValue = props.timetableLabels[0] ?? null
        if (!hasValue && newValue !== props.value) { // newValue !== props.value は双方nullの場合を除外するため
          context.emit('input', newValue)
        }
      },
      {
        immediate: true,
      }
    )
  },
})
