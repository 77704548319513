import axios from 'axios';
import { StringOrNumber } from 'src/models/common';
import { getApiUrl } from './apiUtils';
import { StaffAttendanceIndexParams } from 'src/models/api/staffAttendanceRequest';
import { StaffAttendance } from 'src/models/staffAttendance';

function getResourceBaseUrl(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/staff_attendances`);
}

export default {
  async index(params: StaffAttendanceIndexParams): Promise<StaffAttendance[]> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    const { data } = await axios.get<StaffAttendance[]>(getResourceBaseUrl(workplaceId, budgetGroupId), {
      params: paramsRest,
    });
    return data;
  },
};
