































import { defineComponent } from '@vue/composition-api';
import { ATTENDANCE_TYPE } from 'src/consts';
import { Radio } from '../Radio';

export default defineComponent({
  components: {
    Radio,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      ATTENDANCE_TYPE,
    };
  },
});
