<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <form @submit.prevent="doPasswordReset">
                <card type="login">
                  <h3 slot="header" class="header text-center" style="font-size:1.5rem;">
                    パスワードリセット
                  </h3>

                  <div v-if="!isPasswordReset">
                    <fg-input v-model="form.password" addon-left-icon="fal fa-lock-alt"
                      placeholder="パスワード" type="password">
                    </fg-input>
                    <fg-input v-model="form.passwordConfirm" addon-left-icon="fal fa-lock-alt"
                      placeholder="パスワード確認" type="password">
                    </fg-input>

                    <p class="mb-3 ta-center text-danger" v-show="showErrMsg1">
                      パスワードが一致しません
                    </p>
                    <p class="mb-3 ta-center text-danger" v-show="showErrMsg2">
                      パスワードは{{pwMinLength}}文字以上で<br>設定してください
                    </p>
                    <p class="mb-3 ta-center text-danger" v-show="showErrMsg3">
                      パスワードのリセットに失敗しました
                    </p>
                  </div>
                  <div v-else class="ta-center">
                    パスワードをリセットしました。
                  </div>

                  <p-button native-type="submit" slot="footer" type="primary"
                      round block class="mb-3" v-if="!isPasswordReset">
                    リセット
                  </p-button>
                  <p class="back-to-login mb-3 ta-center text-primary" slot="footer"
                      @click="toLoginPage" v-else>
                    ログイン画面へ
                  </p>
                </card>
              </form>
            </div>
          </div>
        </div>
        <div class="full-page-background" style="background-image: url(/static/img/logiboard/signin-background.jpg) "></div>
      </div>
    </div>
  </div>
</template>

<script>
import userApi from 'src/apis/user'

export default {
  name: 'password-reset',
  data() {
    return {
      form: {
        password: '',
        passwordConfirm: '',
      },
      showErrMsg1: false,
      showErrMsg2: false,
      showErrMsg3: false,
      isPasswordReset: false,
      pwMinLength: 8,
    }
  },
  computed: {
    resetPasswordToken() {
      return this.$route.params.token
    },
  },
  methods: {
    async doPasswordReset() {
      if (!this.form.password) { return }
      this.showErrMsg1 = false
      this.showErrMsg2 = false
      this.showErrMsg3 = false
      if (this.form.password !== this.form.passwordConfirm) {
        this.showErrMsg1 = true
        return
      }
      if (this.form.password.length < this.pwMinLength) {
        this.showErrMsg2 = true
        return
      }
      try {
        await userApi.resetPassword({
          password: this.form.password,
          password_confirmation: this.form.passwordConfirm,
          reset_password_token: this.resetPasswordToken,
        })
        this.isPasswordReset = true
      } catch (err) {
        this.showErrMsg3 = true
      }
    },
    toLoginPage() {
      this.$router.replace({ name: 'Login' })
    }
  },
}
</script>

<style lang="scss" scoped>
.back-to-login {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
