import { toNumberOrNull } from 'src/util/numbers';
import { PlanProgressBlockResponse } from 'src/models/api/planProgressBlockResponse';

export interface PlanProgressBlock {
  id: number
  timetable_header_id: number
  hour: number
  scheduled_amount: number | null
  result_amount: number | null
  scheduled_headcount: number | null
  result_headcount: number | null
}

export function convertPlanProgressBlockResponse(response: PlanProgressBlockResponse): PlanProgressBlock {
  return {
    ...response,
    scheduled_headcount: toNumberOrNull(response.scheduled_headcount),
    result_headcount: toNumberOrNull(response.result_headcount),
  }
}
