import Vue, { type Component, type ComponentOptions } from 'vue';
import { defineComponent, ref, onMounted, h } from '@vue/composition-api';
import { USER_WORKPLACE_ROLE_W_OWNER, USER_WORKPLACE_ROLE_W_LEADER, USER_WORKPLACE_STATUS_SHARED } from 'src/consts';

type WorkplaceRole = { role: string; status: string };

async function getWorkplaceRoleMap(root: Vue): Promise<Record<number, WorkplaceRole | undefined>> {
  try {
    await root.$store.dispatch('user/getMe');
  } catch {
    // 認証エラーなどが起きてもここでは無視する（後続のreturnでは{}が返る）
  }
  return root.$store.getters['user/workplaceRoleMap'];
}

async function getWorkplaceRole(root: Vue, workplaceId: number): Promise<WorkplaceRole | null> {
  const workplaceRoleMap = await getWorkplaceRoleMap(root);
  return workplaceRoleMap[workplaceId] ?? null;
}

function hasValidWorkplaceRole({ role, status }: WorkplaceRole): boolean {
  return (
    [USER_WORKPLACE_ROLE_W_OWNER, USER_WORKPLACE_ROLE_W_LEADER].includes(role) &&
    status === USER_WORKPLACE_STATUS_SHARED
  );
}

async function toLoginPage(root: Vue): Promise<void> {
  if (root.$route.name !== 'Login') {
    // NavigationDuplicatedエラーになるのを防ぐ
    await root.$router.replace({ name: 'Login' });
  }
}

export function withWorkplaceRoleGuard(Component: Component): ComponentOptions<Vue> {
  return defineComponent({
    setup(_, context) {
      const root = context.root as Vue;
      const workplaceId = Number(root.$route.params.workplaceId);
      const isAuthorized = ref(false);

      onMounted(async () => {
        // src/hooks/appRoleHook.tsにあるロジックは、
        // users_workplacesのstatusを見ていないのと、
        // 不要なエラーがスローされるため使用していない
        const workplaceRole = await getWorkplaceRole(root, workplaceId);
        if (workplaceRole !== null && hasValidWorkplaceRole(workplaceRole)) {
          isAuthorized.value = true;
        } else {
          await toLoginPage(root);
        }
      });

      // 現状この関数を使う箇所では必要ないため、Componentにpropsやlistenersを渡す処理は省略している
      return () => (isAuthorized.value ? h(Component) : h('div'));
    },
  });
}
