import { StaffWithShifts, PreShiftItem, ShiftItem, ShiftDisplayContent } from "src/models/regularShift";

type SearchShiftAndPreShiftResult = {
  preShift: PreShiftItem | undefined,
  shift: ShiftItem | undefined
}

export const searchShiftAndPreShift = (staffWithShifts: StaffWithShifts, dateStr: string): SearchShiftAndPreShiftResult => {
  const preShift = staffWithShifts.orig_pre_shifts.find((e) => e.date === dateStr);
  const shift = staffWithShifts.orig_shifts.find((e) => e.date === dateStr);

  return {
    preShift,
    shift,
  }
}

export const searchDisplayShift = (staffWithShifts: StaffWithShifts, dateStr: string): ShiftDisplayContent | undefined => {
  return staffWithShifts.shifts.find((e) => e.date === dateStr);
}

export const searchStaff = (staffsWithShifts: StaffWithShifts[], staffId: number): StaffWithShifts | undefined => {
  return staffsWithShifts.find((e) => e.staff_id === staffId);
}

export const countDisplayShiftRecord = (staffsWithShifts: StaffWithShifts[]): number => {
  return staffsWithShifts.reduce((accu, staff) => {
    return staff.should_show ? accu + 1 : accu
  }, 0)
}