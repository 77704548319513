import axios from 'axios'
import { StringOrNumber } from 'src/models/common'
import { QueueResultResponse } from 'src/models/api/queueResultResponse';
import { getApiUrl } from 'src/apis/apiUtils';
import {
  StaffMonthlySummaryBulkUpsertParams,
  StaffMonthlySummaryIndexParams
} from 'src/models/api/staffMonthlySummaryRequest';
import { convertStaffMonthlySummaryResponse, StaffMonthlySummary } from 'src/models/staffMonthlySummary';

function getResourceBaseUrl(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/staff_monthly_summaries`)
}

export default {
  async index(params: StaffMonthlySummaryIndexParams): Promise<StaffMonthlySummary[]> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.get(`${getResourceBaseUrl(workplaceId, budgetGroupId)}`, { params: paramsRest })
    return data.map(convertStaffMonthlySummaryResponse)
  },
  // スタッフ月合計更新
  async bulkUpsert(params: StaffMonthlySummaryBulkUpsertParams): Promise<QueueResultResponse> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.post(`${getResourceBaseUrl(workplaceId, budgetGroupId)}/bulk_upsert`, paramsRest)
    return data
  },
}
