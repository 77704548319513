
import Vue from 'vue'
import { SetupContext, computed, defineComponent, inject, reactive, watch } from '@vue/composition-api'
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal'
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift'
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState'
import { formatDate } from 'src/util/datetime'
import { parse, addDays, subDays } from 'date-fns'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';

import { RegularShiftBulkCreateParams } from 'src/models/regularShift'

type State = {
  startDate: Date
  endDate: Date
}

const CHECKED_SHIFT = {
  preShift: 'preShift',
  shift: 'shift'
}

export default defineComponent({
  name: 'bulk-shift-creation-modal',
  setup(_, context: SetupContext) {
    const root = context.root as Vue
    const modalState = inject(ModalDisplayStateKey);
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const commonState = inject(CommonStateKey);
    if (!modalState || !staffWithShiftsState || !commonState) {
      throw new Error('State Error');
    }

    const {
      isDisplayBulkCreateModal,
      closeBulkCreateModal,
    } = modalState

    const {
      bulkCreate
    } = staffWithShiftsState

    const {
      budgetGroup,
      workplaceId,
      baseDate,
    } = commonState

    const state = reactive<State>({
      startDate: baseDate.value,
      endDate: addDays(baseDate.value, 6),
    })

    watch([baseDate], () => {
      state.startDate = baseDate.value
      state.endDate = addDays(baseDate.value, 6)
    })

    // 開始日が終了日以降を選択した場合、終了日に開始日と同日を格納する
    watch(() => state.startDate, () => {
      if (state.startDate > state.endDate) {
        state.endDate = state.startDate
      }
    })

    const clickBulkCreateAction = async() => {
      const params: RegularShiftBulkCreateParams = {
        workplace_id: workplaceId.value,
        budget_group_id: budgetGroup.value.id,
        start_date: formatDate(state.startDate, 'yyyy-MM-dd'),
        end_date: formatDate(state.endDate, 'yyyy-MM-dd'),
      }

      try {
        await bulkCreate(params);
        // モーダルを閉じる
        closeBulkCreateModal();
        // 実行ポップアップを表示
        notifySuccess1(root, 'シフトの一括作成を開始しました')
      } catch {
        notifyError1(root, 'シフトの一括作成に失敗しました')
      }
    }

    // シフト条件のdate-pickerの対象範囲に無い日付をdisabledにする
    const startDatePickerOptions = {
      disabledDate(date: Date) {
        const year = baseDate.value.getFullYear().toString()
        const month = (baseDate.value.getMonth() + 1).toString()
        const day = baseDate.value.getDate().toString()
        const baseDateStr = `${year}-${month.length === 1 ? `0${month}` : month}-${day.length === 1 ? `0${day}` : day}`
        const startDate = parse(baseDateStr, 'yyyy-MM-dd', new Date());
        const endDate = addDays(startDate, 31);

        return !(date >= startDate && date < endDate)
      }
    }

    const endDatePickerOptions = computed(() => {
      return {
        disabledDate(date: Date) {
          const startDate = subDays(state.startDate, 1);
          const endDate = addDays(state.startDate, 31);

          return !(date >= startDate && date < endDate)
        }
      }
    })

    return {
      isDisplayBulkCreateModal,
      closeBulkCreateModal,
      clickBulkCreateAction,
      budgetGroup,
      workplaceId,
      state,
      formatDate,
      CHECKED_SHIFT,
      startDatePickerOptions,
      endDatePickerOptions,
    }
  },
})

