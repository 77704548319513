




























import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  props: {
    location: {
      type: Object as PropType<Location>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    iconClasses: {
      type: Array as PropType<String[]>,
      required: true,
    },
    visibleRequiredText: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const iconClass = props.iconClasses.reduce((accu, e) => {
      return `${accu} ${e}`;
    }, 'fa-4x text-color-default light');

    return {
      iconClass,
    };
  },
});
