import { computed, type Ref } from '@vue/composition-api'
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext'
import { type ProgressHeader } from 'src/models/progressHeader'
import { createInjection } from 'src/util/createInjection'

type ModalContext = {
  progressHeader: Ref<ProgressHeader>
  fetchProgressHeader: () => Promise<unknown>
}

type InjectionValue = {
  progressHeader: Ref<ProgressHeader | null>
  fetchProgressHeader: Ref<(() => Promise<unknown>) | null>
  showsProgressDetailListModal: ModalWithContext<ModalContext>['showsModal']
  showProgressDetailListModal: ModalWithContext<ModalContext>['showModal']
  hideProgressDetailListModal: ModalWithContext<ModalContext>['hideModal']
}

const { provide, inject } = createInjection<InjectionValue>('useProgressDetailListModal')

export function useProgressDetailListModalProvider(): void {
  const {
    context,
    showsModal: showsProgressDetailListModal,
    showModal: showProgressDetailListModal,
    hideModal: hideProgressDetailListModal,
  } = useModalWithContext<ModalContext>()

  provide({
    progressHeader: computed(() => context.value?.progressHeader.value ?? null),
    fetchProgressHeader: computed(() => context.value?.fetchProgressHeader ?? null),
    showsProgressDetailListModal,
    showProgressDetailListModal,
    hideProgressDetailListModal,
  })
}

export function useProgressDetailListModal(): InjectionValue {
  return inject()
}
