import axios, { type AxiosResponse } from 'axios'
import * as utils from 'src/apis/apiUtils'
import {
  type ProgressHeaderPerformanceBoardDetailIndexParams,
  type ProgressHeaderPerformanceBoardDetailShowParams,
  type ProgressHeaderPerformanceBoardListIndexParams,
  type ProgressHeaderBulkCreateParams,
  type ProgressHeaderUpdateParams,
} from 'src/models/api/progressHeaderRequest'
import {
  type ProgressHeaderPerformanceBoardDetailIndexResponse,
  type ProgressHeaderPerformanceBoardDetailShowResponse,
  type ProgressHeaderPerformanceBoardListIndexResponse,
} from 'src/models/api/progressHeaderResponse'

export default {
  performanceBoardDetailIndex({ workplace_id, ...params }: ProgressHeaderPerformanceBoardDetailIndexParams): Promise<AxiosResponse<ProgressHeaderPerformanceBoardDetailIndexResponse>> {
    return axios.get(utils.getApiUrl(`/workplaces/${workplace_id}/progress_headers/performance_board_detail_index`), {
      params,
    })
  },
  performanceBoardDetailShow({ workplace_id, id }: ProgressHeaderPerformanceBoardDetailShowParams): Promise<AxiosResponse<ProgressHeaderPerformanceBoardDetailShowResponse>> {
    return axios.get(utils.getApiUrl(`/workplaces/${workplace_id}/progress_headers/${id}/performance_board_detail_show`))
  },
  performanceBoardListIndex({ workplace_id, ...params }: ProgressHeaderPerformanceBoardListIndexParams): Promise<AxiosResponse<ProgressHeaderPerformanceBoardListIndexResponse>> {
    return axios.get(utils.getApiUrl(`/workplaces/${workplace_id}/progress_headers/performance_board_list_index`), {
      params,
    })
  },
  bulkCreate({ workplace_id, ...data }: ProgressHeaderBulkCreateParams): Promise<AxiosResponse<{}>> {
    return axios.post(utils.getApiUrl(`/workplaces/${workplace_id}/progress_headers/bulk_create`), data)
  },
  update({ workplace_id, id, ...data }: ProgressHeaderUpdateParams): Promise<AxiosResponse<{}>> {
    return axios.put(utils.getApiUrl(`/workplaces/${workplace_id}/progress_headers/${id}`), data)
  },
}
