









import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { useBudgetGroupPlanBoardMiscsProvider } from '../../composables/useBudgetGroupPlanBoardMiscs';
import { useLoadLogimeterStaffAttendancesProvider } from '../../composables/useLoadLogimeterStaffAttendances';
import { useMacroOperationMastersProvider } from '../../composables/useMacroOperationMasters';
import { useSearchConditionsProvider } from '../../composables/useSearchConditions';
import { useStaffLabelsProvider } from '../../composables/useStaffLabels';
import { useStaffShiftOverviewProvider } from '../../composables/useStaffShiftOverview';
import { Content } from '../Content';
import { ControlPanel } from '../ControlPanel';
import { MainPanel } from '../MainPanel';
import { LogimeterStaffAttendancesLoadModal } from '../LogimeterStaffAttendancesLoadModal';

export default defineComponent({
  components: {
    Content,
    ControlPanel,
    MainPanel,
    LogimeterStaffAttendancesLoadModal,
  },
  setup(_, context) {
    const root = context.root as Vue;
    const workplaceId = Number(root.$route.params.workplaceId);

    setPageName(root, 'シフト特性');
    useBudgetGroupsProvider({ workplaceId });
    useMacroOperationMastersProvider({ workplaceId });
    useStaffLabelsProvider({ workplaceId });
    useSearchConditionsProvider();
    useBudgetGroupPlanBoardMiscsProvider();
    useStaffShiftOverviewProvider();
    useLoadLogimeterStaffAttendancesProvider();
  },
});
