import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import {
  IndexOptParams,
  CreateOptParams,
  UpdateOptParams,
  BulkUpdateOptParams
} from 'src/models/api/collectiveStaffRequest'
import { convertCollectiveStaffResponse, CollectiveStaff } from 'src/models/collectiveStaff';

export default {
  async index({ workplaceId, budgetGroupId, params }: { workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, params: IndexOptParams }): Promise<CollectiveStaff[]> {
    const { data } = await axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/collective_staffs`), {
      params: params || {}
    })
    return data.map(convertCollectiveStaffResponse)
  },
  async create({ workplaceId, data }: { workplaceId: StringOrNumber, data: CreateOptParams }):Promise<CollectiveStaff> {
    const { data: resData } = await axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${data.budget_group_id}/collective_staffs`), data)
    return convertCollectiveStaffResponse(resData)
  },
  async update({ workplaceId, data }: { workplaceId: StringOrNumber, data: UpdateOptParams }):Promise<CollectiveStaff> {
    const { data: resData } = await axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${data.budget_group_id}/collective_staffs/${data.id}`), data)
    return convertCollectiveStaffResponse(resData)
  },
  async delete({ workplaceId, budgetGroupId, itemId }: { workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, itemId: StringOrNumber }) {
    const { data } = await axios.delete(utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/collective_staffs/${itemId}`))
    return convertCollectiveStaffResponse(data)
  },
  async bulkUpdateDispOrder({ workplaceId, budgetGroupId, items }: { workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, items: BulkUpdateOptParams[] }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/collective_staffs/bulk_update_disp_order`),
      { items: items }
    )
  },
}
