import { defineComponent, PropType, reactive, ref, SetupContext } from '@vue/composition-api'

type MoveHeadcountFunction = (headcount: number, isAlsoMovedAfterThis: boolean) => void

export type HeadcountMoveModalState = {
  isVisible: boolean
  headcount: number
  maxHeadcount: number
  isAlsoMovedAfterThis: boolean
  moveHeadcount: MoveHeadcountFunction
}

export const headcountMoveModalHooks = ({ moveHeadcount }: {moveHeadcount: MoveHeadcountFunction}) => {
  const state: HeadcountMoveModalState = reactive({
    isVisible: false,
    headcount: 0,
    maxHeadcount: 0,
    isAlsoMovedAfterThis: true,
    moveHeadcount,
  })

  const openHeadcountMoveModal = (maxHeadcount: number): void => {
    state.isVisible = true
    state.maxHeadcount = maxHeadcount
  }

  const closeHeadcountMoveModal = (): void => {
    state.isVisible = false
  }

  return {
    state,
    openHeadcountMoveModal,
    closeHeadcountMoveModal,
  }
}

export default defineComponent({
  name: 'daily-board-prep-headcount-move-modal',
  props: {
    state: {
      type: Object as PropType<HeadcountMoveModalState>,
      default: null,
    },
  },
  setup(props, context: SetupContext) {
    const validationState = reactive({
      isValid: true,
      errorMessage: ''
    })
    const closeHeadcountMoveModal = (): void => {
      validationState.isValid = true
      validationState.errorMessage = ''
      context.emit('close')
    }
    const onMoveButtonClick = (): void => {
      validate()
      if (!validationState.isValid) {
        return
      }
      const { headcount, isAlsoMovedAfterThis } = props.state
      props.state.moveHeadcount(headcount, isAlsoMovedAfterThis)
    }
    const validate = (): void => {
      const { headcount, maxHeadcount } = props.state
      const headcountNum = Number(headcount)
      if (isNaN(headcountNum) || headcount.toString().match(/\D/)) {
        validationState.errorMessage = '入力に誤りがあります。'
        validationState.isValid = false
        return
      }
      if (headcountNum > maxHeadcount) {
        validationState.errorMessage = `移動可能人数は ${maxHeadcount} 人です。`
        validationState.isValid = false
        return
      }
      validationState.errorMessage = ''
      validationState.isValid = true
    }
    const onInputBlur = (): void => {
      if (!validationState.isValid) {
        validate()
      }
    }
    return {
      validationState,
      closeHeadcountMoveModal,
      onMoveButtonClick,
      onInputBlur,
    }
  },
})
