import { computed, defineComponent, getCurrentInstance, onMounted, ref } from '@vue/composition-api';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group';
import { HEADCOUNT_MODE, SHOW_MODE } from 'src/consts';

export default defineComponent({
  setup() {
    const root = getCurrentInstance()!.proxy;
    setPageName(root, '機能マップ');
    const date = 'today';
    const defaultBudgetGroupId = ref(0);

    onMounted(async () => {
      const budgetGroups = await budgetGroupApi.index(root.$route.params.workplaceId);
      defaultBudgetGroupId.value = budgetGroups[0]?.id ?? 0;
    });

    const regularShiftLink = computed(() => ({ name: 'WorkplaceRegularShift', params: { date } }));
    const monthlyBoardPrepLink = computed(() => ({
      name: 'WorkplaceMonthlyBoard',
      params: { date },
      query: {
        budget_group_id: defaultBudgetGroupId.value.toString(),
        show_mode: SHOW_MODE.SCHEDULED.toString(),
        headcount_mode: HEADCOUNT_MODE.MAN_HOUR.toString(),
      },
    }));
    const monthlyBoardReviewLink = computed(() => ({
      name: 'WorkplaceMonthlyBoard',
      params: { date },
      query: {
        budget_group_id: defaultBudgetGroupId.value.toString(),
        show_mode: SHOW_MODE.ACTUAL.toString(),
        headcount_mode: HEADCOUNT_MODE.MAN_HOUR.toString(),
      },
    }));
    const dailySimulationLink = computed(() => ({ name: 'WorkplaceDailySimulation', params: { date } }));
    const dailyBoardReviewLink = computed(() => ({ name: 'WorkplaceDailyBoardReview', params: { date } }));
    const performanceBoardListLink = computed(() => ({ name: 'WorkplacePerformanceBoardList', params: { date } }));
    const performanceBoardDetailLink = computed(() => ({ name: 'WorkplacePerformanceBoardDetail', params: { date } }));
    const operationOverviewLink = computed(() => ({ name: 'WorkplaceOperationOverview', params: { date } }));
    const staffOverviewLink = computed(() => ({ name: 'WorkplaceStaffOverview' }));
    const attendanceListLink = computed(() => ({ name: 'WorkplaceAttendanceList' }));
    const planProgressLink = computed(() => ({ name: 'WorkplacePlanProgress', params: { date } }));
    const progressBoardLink = computed(() => ({ name: 'WorkplaceProgressBoard', params: { date } }));
    const staffExperienceOverviewLink = computed(() => ({ name: 'StaffExperienceOverview' }));
    const staffShiftOverviewLink = computed(() => ({ name: 'WorkplaceStaffShiftOverview' }));

    return {
      regularShiftLink,
      monthlyBoardPrepLink,
      monthlyBoardReviewLink,
      dailySimulationLink,
      dailyBoardReviewLink,
      performanceBoardListLink,
      performanceBoardDetailLink,
      operationOverviewLink,
      staffOverviewLink,
      attendanceListLink,
      planProgressLink,
      progressBoardLink,
      staffExperienceOverviewLink,
      staffShiftOverviewLink,
    };
  },
});
