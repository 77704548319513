







import { defineComponent, ref } from '@vue/composition-api'
import { ValidationProvider, type ValidationProviderInstance } from 'vee-validate'

export default defineComponent({
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: [String, Object],
      default: undefined,
    },
    vid: {
      type: [String, Number],
      default: undefined,
    },
  },
  setup() {
    const validationProvider = ref<ValidationProviderInstance | null>(null)

    return {
      validationProvider,
    }
  },
})
