
































import { defineComponent } from '@vue/composition-api';
import { navigateToLogiCoredata } from 'src/util/logibase';
import { mapGetters } from 'vuex';

export default defineComponent({
  props: {
    workplaceId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', ['logiCoredataUrl']),
  },
  methods: {
    toLogiCoredata() {
      const link = `/workplace/${this.$props.workplaceId}/settings`;
      navigateToLogiCoredata(this.logiCoredataUrl, link, true);
    },
  },
});
