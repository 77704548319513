














import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { type TimetableHeader } from 'src/models/timetableHeader'
import { type ActivitySummary } from 'src/models/progressBoard'
import { formatInteger } from '../../utils/filters'
import { getHeadCountsByLabel } from './getHeadCountsByLabel'

export default defineComponent({
  filters: {
    formatInteger,
  },
  props: {
    timetableHeader: {
      type: Object as PropType<TimetableHeader>,
      required: true,
    },
    activitySummaries: {
      type: Array as PropType<ActivitySummary[]>,
      required: true,
    },
  },
  setup(props) {
    const headCountsByLabel = computed(() =>
      getHeadCountsByLabel({
        timetableHeader: props.timetableHeader,
        activitySummaries: props.activitySummaries,
      })
    )

    return {
      headCountsByLabel,
    }
  },
})
