import { type TimetableMaster } from 'src/models/timetableMaster';
import { type TimetableActivityMaster } from 'src/models/timetableActivityMaster';

export function getTimetableMasterLogimeterActivitiesString(
  timetableMaster: TimetableMaster,
  timetableActivityMasters: TimetableActivityMaster[],
): string {
  return timetableMaster.timetable_master_logimeter_activities
    .map((activity) => {
      const timetableActivityMaster = timetableActivityMasters.find(
        (timetableActivityMaster) => timetableActivityMaster.id === activity.activity_master_id,
      );

      if (timetableActivityMaster === undefined) {
        return { name: '', dispOrder: 0 };
      }

      return {
        name: [timetableActivityMaster.budget_unit.name, timetableActivityMaster.name].join(' '),
        dispOrder: timetableActivityMaster.disp_order,
      };
    })
    .filter((v) => v.name !== '')
    .sort((a, b) => a.dispOrder - b.dispOrder)
    .map((v) => v.name)
    .join(', ');
}
