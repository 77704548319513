










import { defineComponent } from '@vue/composition-api'
import { useCreateProgressDetail } from '../../composables/useCreateProgressDetail'
import { ProgressDetailInputModal } from '../ProgressDetailInputModal'

export default defineComponent({
  components: {
    ProgressDetailInputModal,
  },
  setup() {
    const {
      progressHeader,
      showsProgressDetailCreateModal,
      hideProgressDetailCreateModal,
      createProgressDetail,
    } = useCreateProgressDetail()

    return {
      progressHeader,
      showsProgressDetailCreateModal,
      hideProgressDetailCreateModal,
      createProgressDetail,
    }
  },
})
