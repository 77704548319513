





















import { defineComponent, ref } from '@vue/composition-api';
import { StaffExperienceMatrixHeader } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/StaffExperienceMatrixHeader';
import { SortSpec, useSorter2Container } from 'src/components/Workplace/Sorter2Container.vue';
import { StaffExperienceMatrixTotal } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/StaffExperienceMatrixTotal';
import { StaffExperienceMatrixRow } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/StaffExperienceMatrixRow';
import { useStaffExperience } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/composables/useStaffExperience';

export default defineComponent({
  components: {
    StaffExperienceMatrixHeader,
    Sorter2Container: useSorter2Container(),
    StaffExperienceMatrixTotal,
    StaffExperienceMatrixRow,
  },
  setup() {
    const { staffsWithTimetableMasterSummaries } = useStaffExperience();

    const defaultSortSpec = ref<SortSpec[]>([{ key: 'summary_by_timetable_master', asc: true }]);

    return {
      staffsWithTimetableMasterSummaries,
      defaultSortSpec,
    };
  },
});
