import { PlanBoardIndexResult } from 'src/models/planBoard';
import {
  formatTimeInteger,
  packToTimeInteger,
  timeDifferenceInSeconds,
  timeIntegerAdd,
  timeIntegerDiff,
  timeStrToTimeInteger,
  unpackTimeInteger,
} from 'src/util/datetime';
import { isExist } from 'src/util/isExist';
import {
  PlanBoardBreakTime,
  PlanBoardHeadcountData,
  PlanBoardHeadcountHourBlocks,
  PlanBoardHeader,
  PlanBoardHourBlock,
  PlanBoardOverviewState,
  PlanBoardStaffForAllocation,
  PlanBoardState,
  PlanBoardTimetable,
} from './types';
import { PLAN_BOARD_BREAK_TIME_MASTER_ID, PLAN_BOARD_SURPLUS_MASTER_ID, PLAN_BOARD_REGULAR_MASTER_ID } from './const';
import { WorkplaceExtension } from 'src/models/workplaceExtension';
import { reactive } from '@vue/composition-api';
import { TimetableHeader } from 'src/models/timetableHeader';
import { PlanBoardBlock } from 'src/models/planBoardBlock';
import { CollectiveStaffShift } from 'src/models/collectiveStaffShift';
import { TimeInteger } from 'src/models/common';
import { CollectiveStaff } from 'src/models/collectiveStaff';
import { StaffShift } from 'src/models/staffShift';
import { StaffExtension } from 'src/models/staffExtension';
import { SomeRequired } from 'src/util/type_util';
import { Staff } from 'src/models/staff';

type TimeRange = { targetStartTime: TimeInteger; targetEndTime: TimeInteger };

/**
 * スタッフ用の休憩時間の key 名の対応
 */
const StaffBreakTimeKeyMap = [
  ['scheduled_break1_start_time', 'scheduled_break1_end_time'],
  ['scheduled_break2_start_time', 'scheduled_break2_end_time'],
] as const;

/**
 * スタッフ集合体用の休憩時間の key 名の対応
 */
const CollectiveStaffBreakTimeKeyMap = [
  ['break1_start_time', 'break1_end_time'],
  ['break2_start_time', 'break2_end_time'],
] as const;

/**
 * 1 時間内のブロック数を計算して返す
 */
export const getBlockLengthPerHour = (planBoardReponse: PlanBoardIndexResult) => {
  for (const timetableHeader of planBoardReponse.timetable_headers) {
    for (const { start_time, end_time } of timetableHeader.plan_board_blocks) {
      if (!isExist(start_time) || !isExist(end_time) || start_time === 0 || end_time === 0) {
        continue;
      }
      return Math.round((60 * 60) / timeDifferenceInSeconds(start_time, end_time));
    }
  }
  return 4;
};

/**
 * 人時情報からその背景色を計算して返す
 */
export const getHeadcountBackgroundColor = (headcount: number) => {
  if (headcount === 0) {
    return '#fff';
  }
  // headcount が大きくなるにつれて青系の色で濃くなるよう計算を行う
  // マジックナンバーは KURANDO ブルー系の色で濃淡が変わるように調整した結果の数値
  const r = Math.max(34, 203 - 3 * headcount);
  const g = Math.max(40, 227 - 3 * headcount);
  const b = Math.max(53, 254 - 3 * headcount);
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

/**
 * 指定された staff の休憩の時間帯分 callback を叩く
 */
export const forEachBreakTimeBlockTime = (
  staff: PlanBoardStaffForAllocation,
  blockLengthPerHour: number,
  callback: (hour: number, blockIndex: number) => void,
) => {
  staff.breakTimes.forEach(({ startTime, endTime }) => {
    const [startHour] = unpackTimeInteger(startTime);
    const [endHour] = unpackTimeInteger(endTime);

    for (let hour = startHour; hour <= endHour; hour++) {
      for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
        const targetTime = packToTimeInteger(hour, Math.round((60 / blockLengthPerHour) * blockIndex), 0);
        if (
          startTime <= targetTime &&
          targetTime < endTime &&
          staff.startTime <= targetTime &&
          targetTime < staff.endTime
        ) {
          callback(hour, blockIndex);
        }
      }
    }
  });
};

/**
 * 指定された staff の勤務時間帯分 callback を叩く
 */
export const forEachWorkingBlockTime = (
  staff: PlanBoardStaffForAllocation,
  blockLengthPerHour: number,
  callback: (hour: number, blockIndex: number) => void,
) => {
  const [startHour] = unpackTimeInteger(staff.startTime);
  const [endHour] = unpackTimeInteger(staff.endTime);

  for (let hour = startHour; hour <= endHour; hour++) {
    for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
      const targetTime = packToTimeInteger(hour, Math.round((60 / blockLengthPerHour) * blockIndex), 0);
      if (staff.startTime <= targetTime && targetTime < staff.endTime) {
        callback(hour, blockIndex);
      }
    }
  }
};

/**
 * 工程の概要情報と人時情報を更新する
 */
export const updateHeaderAndBoardHourBlocks = (
  _header: PlanBoardHeader,
  headcountHourBlocks: PlanBoardHeadcountHourBlocks,
  blockLengthPerHour: number,
  surplusTimetable: PlanBoardTimetable | null,
) => {
  let lastQuantity = _header.amountWorkBeforehand ?? 0;
  let headerTotalHeadcount = 0;
  const { startTime, endTime } = _header;
  const startTimeInteger = timeStrToTimeInteger(startTime);
  const endTimeInteger = timeStrToTimeInteger(endTime);
  const boardHourBlocks: PlanBoardHourBlock[] = headcountHourBlocks.map((currentHeadcountList, currentHour) => {
    const headcountList = currentHeadcountList.map((headcountData, blockIndex) => {
      const currentTimeInteger = packToTimeInteger(currentHour, (blockIndex * 60) / blockLengthPerHour, 0);
      if (
        isExist(surplusTimetable) &&
        (currentTimeInteger < startTimeInteger || endTimeInteger <= currentTimeInteger)
      ) {
        surplusTimetable.boardHourBlocks[currentHour].headcountList[blockIndex].headcount += headcountData.headcount;
        surplusTimetable.boardHourBlocks[currentHour].headcountList[blockIndex].backgroundColor =
          getHeadcountBackgroundColor(
            surplusTimetable.boardHourBlocks[currentHour].headcountList[blockIndex].headcount,
          );
        headcountData.headcount = 0;
      }
      return {
        ...headcountData,
        backgroundColor: getHeadcountBackgroundColor(headcountData.headcount),
        isStartTime: currentTimeInteger === startTimeInteger,
        isEndTime: currentTimeInteger === endTimeInteger,
      };
    });
    const totalHeadcount = headcountList.reduce((total, headcountData) => total + headcountData.headcount, 0);
    const quantity = lastQuantity + (totalHeadcount * _header.productivity) / blockLengthPerHour;
    lastQuantity = quantity;
    headerTotalHeadcount += totalHeadcount;
    return {
      totalHeadcount,
      quantity,
      completionRatio: Math.min(100, Math.round((quantity / _header.targetQuantity) * 100)),
      headcountList,
    };
  });
  const validation = validateHeader(_header);
  const header = {
    ..._header,
    validation: {
      targetQuantity: _header.validation.targetQuantity || validation.targetQuantity,
      startTime: _header.validation.startTime || validation.startTime,
      endTime: _header.validation.endTime || validation.endTime,
      productivity: _header.validation.productivity || validation.productivity,
      maxAllocations: _header.validation.maxAllocations || validation.maxAllocations,
    },
    excessOrDeficiency: lastQuantity - _header.targetQuantity,
    totalHeadcount: headerTotalHeadcount / blockLengthPerHour,
    requiredHeadcount: !_header.productivity ? null : _header.targetQuantity / _header.productivity,
  };

  return {
    header,
    boardHourBlocks,
  };
};

/**
 * 工程ヘッダーの検証を行う
 */
export const validateHeader = (header: PlanBoardHeader): PlanBoardHeader['validation'] => {
  return {
    targetQuantity: isNumber(header.targetQuantity),
    startTime: validateWorkingTime(header.startTime) && validateStartEnd(header.startTime, header.endTime),
    endTime: validateWorkingTime(header.endTime) && validateStartEnd(header.startTime, header.endTime),
    productivity: isNumber(header.productivity),
    maxAllocations: isNumber(header.maxAllocations),
  };
};

const isNumber = (num: number | string | null | undefined): num is number => {
  return typeof num === 'number';
};

const validateWorkingTime = (workingTime: string | null | undefined): boolean => {
  if (typeof workingTime !== 'string' || !workingTime.match(/^\d\d:\d\d$/)) {
    return false;
  }
  const timeInteger = Number(workingTime.replace(':', '')) * 100;
  const [h, m] = unpackTimeInteger(timeInteger);
  if (h >= 48) {
    return false;
  }
  if (m >= 60) {
    return false;
  }
  return true;
};

const validateStartEnd = (startTimeStr: string, endTimeStr: string) => {
  const startTime = Number(startTimeStr.replace(':', ''));
  const endTime = Number(endTimeStr.replace(':', ''));
  return startTime < endTime;
};

/**
 * API のレスポンスから工程情報を生成する
 */
export const createTimetablesFromApiResponse = (
  { timetable_headers, staff_shifts, collective_staff_shifts }: PlanBoardIndexResult,
  blockLengthPerHour: number,
  displayHourPeriod: number,
  workplaceExtension: WorkplaceExtension,
): PlanBoardTimetable[] => {
  const surplusTimeTimetable = createSystemTimetable(
    '余剰',
    '#222a35',
    PLAN_BOARD_SURPLUS_MASTER_ID,
    displayHourPeriod,
    blockLengthPerHour,
  );
  const timetables = timetable_headers
    .concat()
    .sort(({ timetable_master: a }, { timetable_master: b }) => a.disp_order - b.disp_order)
    .map((timetableHeader) => {
      const { header, boardHourBlocks } = updateHeaderAndBoardHourBlocks(
        createHeaderFromApiResponse(timetableHeader),
        createHeadcountHourBlocksFromApiResponse(
          timetableHeader.plan_board_blocks,
          blockLengthPerHour,
          displayHourPeriod,
        ),
        blockLengthPerHour,
        surplusTimeTimetable,
      );
      const timetable: PlanBoardTimetable = reactive({
        id: timetableHeader.id,
        masterId: timetableHeader.timetable_master_id,
        header,
        boardHourBlocks,
        isChanged: false,
      });
      return timetable;
    });

  const breakTimeTimetable = createSystemTimetable(
    '休憩',
    '#7f7f7f',
    PLAN_BOARD_BREAK_TIME_MASTER_ID,
    displayHourPeriod,
    blockLengthPerHour,
  );

  // 画面には表示しない
  const regularTimetable = createSystemTimetable(
    'レギュラー',
    '#ffffff',
    PLAN_BOARD_REGULAR_MASTER_ID,
    displayHourPeriod,
    blockLengthPerHour,
  );

  timetables.push(surplusTimeTimetable);
  timetables.push(breakTimeTimetable);
  timetables.push(regularTimetable);

  const timeBlockLength = displayHourPeriod * blockLengthPerHour;
  const staffsFromStaffShifts = createStaffForAllocationFromStaffShifts(staff_shifts, timeBlockLength);
  const staffsFromCollectiveStaffShifts = createStaffForAllocationFromCollectiveStaffShifts(
    collective_staff_shifts,
    timeBlockLength,
  );
  const staffs = staffsFromStaffShifts.concat(staffsFromCollectiveStaffShifts);

  for (let hour = 0; hour < displayHourPeriod; hour++) {
    for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
      const targetTime = packToTimeInteger(hour, Math.round((60 / blockLengthPerHour) * blockIndex), 0);
      const timeBlockIndex = hour * blockLengthPerHour + blockIndex;

      // 現在の時間帯で有効なスタッフを抽出
      const availableStaffs = staffs.filter((staff) => staff.startTime <= targetTime && targetTime < staff.endTime);
      let regularStaffCount = 0;
      // 休憩の場合は休憩に割り付ける
      availableStaffs.forEach((staff) => {
        if (staff.timeBlocks[timeBlockIndex].isAllocated) {
          return;
        }
        if (staff.isRegular) {
          regularStaffCount++;
        }
        if (staff.breakTimes.some(({ startTime, endTime }) => startTime <= targetTime && targetTime < endTime)) {
          breakTimeTimetable.boardHourBlocks[hour].headcountList[blockIndex].headcount++;
        }
      });

      // 割り付けられた合計数
      const allocatedStaffCount = timetables.reduce((sum, timetable) => {
        return sum + timetable.boardHourBlocks[hour].headcountList[blockIndex].headcount;
      }, 0);
      // 残りを余剰へ割り付ける（有効なスタッフ数 - 割り付けられた合計数）
      surplusTimeTimetable.boardHourBlocks[hour].headcountList[blockIndex].headcount =
        availableStaffs.length - allocatedStaffCount;
      regularTimetable.boardHourBlocks[hour].headcountList[blockIndex].headcount = regularStaffCount;
    }
  }

  return timetables;
};

/**
 * API のレスポンスから工程の概要情報を生成する
 */
export const createHeaderFromApiResponse = (timetableHeader: TimetableHeader): PlanBoardHeader => {
  const header: PlanBoardHeader = {
    name: timetableHeader.timetable_master.name,
    backgroundColor: `#${timetableHeader.timetable_master.disp_color}`,
    amountWorkBeforehand: timetableHeader.amount_work_beforehand,
    targetQuantity: timetableHeader.quantity ?? timetableHeader.timetable_master.planned_quantity,
    excessOrDeficiency: 0,
    startTime: isExist(timetableHeader.start_time)
      ? formatTimeInteger(timetableHeader.start_time)
      : isExist(timetableHeader.timetable_master.start_time)
      ? formatTimeInteger(timetableHeader.timetable_master.start_time)
      : '',
    endTime: isExist(timetableHeader.end_time)
      ? formatTimeInteger(timetableHeader.end_time)
      : isExist(timetableHeader.timetable_master.end_time)
      ? formatTimeInteger(timetableHeader.timetable_master.end_time)
      : '',
    productivity: timetableHeader.productivity,
    plannedProductivity: timetableHeader.timetable_master.planned_productivity,
    totalHeadcount: 0,
    requiredHeadcount: null,
    maxAllocations: timetableHeader.max_allocations ?? timetableHeader.timetable_master.max_allocations,
    validation: createInitialValidation(),
  };
  return header;
};

/**
 * API のレスポンスから工程の人時ブロック情報を生成する
 */
export const createHeadcountHourBlocksFromApiResponse = (
  planBoardlocks: PlanBoardBlock[],
  blockLengthPerHour: number,
  displayHourPeriod: number,
) => {
  const headcountHourBlocks: PlanBoardHeadcountHourBlocks = [];
  for (let hour = 0; hour < displayHourPeriod; hour++) {
    const headcountList: PlanBoardHeadcountData[] = [];
    for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
      const targetStartTime = packToTimeInteger(hour, Math.round((60 / blockLengthPerHour) * blockIndex), 0);
      const headcount =
        planBoardlocks.find((planBoardBlock) => planBoardBlock.start_time === targetStartTime)?.headcount ?? 0;
      headcountList.push({
        headcount,
        backgroundColor: getHeadcountBackgroundColor(headcount),
        isStartTime: false,
        isEndTime: false,
      });
    }
    headcountHourBlocks.push(headcountList);
  }
  return headcountHourBlocks;
};

/**
 * API のレスポンスから人時ブロックの合計情報を生成する
 */
export const createTotalHeadcountHourBlocksFromApiResponse = (
  timetables: PlanBoardTimetable[],
  blockLengthPerHour: number,
  displayHourPeriod: number,
) => {
  const totalHeadcountHourBlocks: PlanBoardHeadcountHourBlocks = new Array(displayHourPeriod).fill('').map(() => {
    return new Array(blockLengthPerHour)
      .fill('')
      .map(() => ({ headcount: 0, backgroundColor: '', isStartTime: false, isEndTime: false }));
  });
  timetables.forEach((timetable) => {
    timetable.boardHourBlocks.forEach(({ headcountList }, i) => {
      headcountList.forEach((headcountData, j) => {
        if (
          timetable.masterId === PLAN_BOARD_BREAK_TIME_MASTER_ID ||
          timetable.masterId === PLAN_BOARD_REGULAR_MASTER_ID
        ) {
          return;
        }
        totalHeadcountHourBlocks[i][j].headcount += headcountData.headcount;
      });
    });
  });
  totalHeadcountHourBlocks.forEach((totalHeadcountHourBlock) => {
    totalHeadcountHourBlock.forEach((totalHeadcountData) => {
      totalHeadcountData.backgroundColor = getHeadcountBackgroundColor(totalHeadcountData.headcount);
    });
  });
  return totalHeadcountHourBlocks;
};

/**
 * API のレスポンスから シフト内訳 用の情報を生成する
 */
export const createShiftInputFromApiResponse = (
  collectiveStaffShifts: CollectiveStaffShift[],
  timeRange: TimeRange,
) => {
  return collectiveStaffShifts.map(({ collective_staff, headcount }) => {
    return {
      name: collective_staff?.name ?? '',
      headcount: {
        total: headcount ?? 0,
        targetTimeRange: isCollectiveStaffInTimeRange(collective_staff, timeRange) && headcount ? headcount : 0,
      },
    };
  });
};

/**
 * targetStatTime と targetEndTime の時間帯が containerStartTime と containerEndTime の時間内に含まれるかどうかを返す
 */
const isContainTimeRange = (
  containerStartTime?: TimeInteger | null,
  containerEndTime?: TimeInteger | null,
  targetStartTime?: TimeInteger | null,
  targetEndTime?: TimeInteger | null,
) => {
  if (!isExist(containerStartTime)) {
    return false;
  }
  if (!isExist(containerEndTime)) {
    return false;
  }
  if (!isExist(targetStartTime)) {
    return false;
  }
  if (!isExist(targetEndTime)) {
    return false;
  }
  if (targetStartTime < containerStartTime) {
    return false;
  }
  if (containerEndTime < targetEndTime) {
    return false;
  }
  return true;
};

/**
 * 勤務時間（startTime, endTime）と休憩時間（break1, break2 の各 startTime, endTime）から、対象が集計対象かどうかを返す
 */
const isTargetInTimeRange = (
  startTime?: TimeInteger | null,
  endTime?: TimeInteger | null,
  break1StartTime?: TimeInteger | null,
  break1EndTime?: TimeInteger | null,
  break2StartTime?: TimeInteger | null,
  break2EndTime?: TimeInteger | null,
  targetStartTime?: TimeInteger | null,
  targetEndTime?: TimeInteger | null,
) => {
  // 勤務時間、集計対象時間が存在しない場合は集計対象としない
  if (!isExist(startTime) || !isExist(endTime) || !isExist(targetStartTime) || !isExist(targetEndTime)) {
    return false;
  }
  // 勤務時間が集計対象範囲外の場合は集計対象としない
  if (endTime <= targetStartTime || targetEndTime <= startTime) {
    return false;
  }
  // 休憩時間内に集計対象時間帯が内包されている場合は集計対象としない
  if (isContainTimeRange(break1StartTime, break1EndTime, targetStartTime, targetEndTime)) {
    return false;
  }
  if (isContainTimeRange(break2StartTime, break2EndTime, targetStartTime, targetEndTime)) {
    return false;
  }
  return true;
};

/**
 * スタッフ集合体が集計対象かどうかを返す
 */
export const isCollectiveStaffInTimeRange = (
  collectiveStaff: CollectiveStaff | undefined,
  { targetStartTime, targetEndTime }: TimeRange,
) => {
  const { start_time, end_time, break1_start_time, break1_end_time, break2_start_time, break2_end_time } =
    collectiveStaff ?? {};
  return isTargetInTimeRange(
    start_time,
    end_time,
    break1_start_time,
    break1_end_time,
    break2_start_time,
    break2_end_time,
    targetStartTime,
    targetEndTime,
  );
};

/**
 * スタッフシフトが集計対象かどうかを返す
 */
const isStaffShiftInTimeRange = (
  {
    scheduled_work_start_time,
    scheduled_work_end_time,
    scheduled_break1_start_time,
    scheduled_break1_end_time,
    scheduled_break2_start_time,
    scheduled_break2_end_time,
  }: StaffShift,
  { targetStartTime, targetEndTime }: TimeRange,
) => {
  return isTargetInTimeRange(
    scheduled_work_start_time,
    scheduled_work_end_time,
    scheduled_break1_start_time,
    scheduled_break1_end_time,
    scheduled_break2_start_time,
    scheduled_break2_end_time,
    targetStartTime,
    targetEndTime,
  );
};

/**
 * API のレスポンスから シフト内訳/シフト管理対象 用の情報を生成する
 */
export const createShiftInputRegularFromApiResponse = (
  staffShifts: StaffShift[],
  staffs: Staff[],
  countingTimeRange: TimeRange,
) => {
  let total = 0;
  let targetTimeRange = 0;
  const filteredStaffIds = staffs.filter((staff) => checkIfRegularStaff(staff)).map((staff) => staff.id);

  staffShifts.forEach((shift) => {
    if (!filteredStaffIds.includes(shift.staff_id)) {
      return;
    }

    if (isExist(shift.scheduled_work_start_time) && isExist(shift.scheduled_work_end_time)) {
      total++;
    }
    if (isStaffShiftInTimeRange(shift, countingTimeRange)) {
      targetTimeRange++;
    }
  });

  return { total, targetTimeRange };
};

/**
 * PlanBoardState の内容から 工数合計 用の情報を生成する
 */
export const createHeadcountTotal = (planBoardState: PlanBoardState) => {
  const shift = {
    total: 0,
    targetTimeRange: 0,
  };
  const required = {
    total: 0,
    targetTimeRange: 0,
  };
  planBoardState.timetables.forEach(({ header, boardHourBlocks, masterId }) => {
    if (masterId < 0) {
      if (masterId === PLAN_BOARD_REGULAR_MASTER_ID) {
        boardHourBlocks.forEach((block) => {
          block.headcountList.forEach((list) => {
            shift.total += list.headcount / planBoardState.blockLengthPerHour;
          });
        });
      }
      return;
    }
    required.total += header.requiredHeadcount ?? 0;
  });

  const excessOrDeficiency = {
    total: shift.total - required.total,
    targetTimeRange: 0,
  };
  return {
    shift,
    required,
    excessOrDeficiency,
  };
};

/**
 * API のレスポンスからチェック項目用の情報を生成する
 */
export const createCheckListFromApiResponse = (
  checkList: PlanBoardOverviewState['checkList'],
  staffShifts: StaffShift[],
  checkTimeRange: TimeRange,
  workplaceExtension: WorkplaceExtension,
) => {
  const { custom_skill_name1, custom_skill_name2, custom_skill_name3 } = workplaceExtension;
  const checkListMap = checkList.reduce<Record<string, { total: number; targetTimeRange: number }>>((ret, item) => {
    ret[item.key] = { total: 0, targetTimeRange: 0 };
    return ret;
  }, {});
  const keys = checkList.map((item) => item.key);
  staffShifts.forEach((shift) => {
    const staffExtension = shift.staff?.staff_extension;
    if (!isExist(staffExtension)) {
      return;
    }
    if (!isExist(shift.scheduled_work_start_time) || !isExist(shift.scheduled_work_end_time)) {
      return;
    }
    const activeKeys = keys.filter((key) => staffExtension[key as keyof StaffExtension]);
    activeKeys.forEach((key) => {
      checkListMap[key].total++;
      if (isStaffShiftInTimeRange(shift, checkTimeRange)) {
        checkListMap[key].targetTimeRange++;
      }
    });
  });
  checkList[2].name = custom_skill_name1 ?? '-';
  checkList[3].name = custom_skill_name2 ?? '-';
  checkList[4].name = custom_skill_name3 ?? '-';
  return checkList.map((item) => {
    return {
      ...item,
      ...checkListMap[item.key],
    };
  });
};

/**
 * priority の小さい順に timetable_master_id を返す
 */
export const createSkillsFromApiResponse = (skills?: Array<{ priority: number; timetable_master_id: number }>) => {
  return (
    skills
      ?.concat()
      .sort((a, b) => a.priority - b.priority)
      .map((skill) => skill.timetable_master_id) ?? []
  );
};

/**
 * staff_shifts から割り付け用のスタッフを生成する
 */
export const createStaffForAllocationFromStaffShifts = (
  staff_shifts: StaffShift[],
  timeBlockLength: number,
): PlanBoardStaffForAllocation[] => {
  return staff_shifts
    .map(({ scheduled_work_start_time, scheduled_work_end_time, staff, ...break_times }) => {
      const breakTimes: PlanBoardBreakTime[] = [];
      StaffBreakTimeKeyMap.forEach(([startKey, endKey]) => {
        const startTime = break_times[startKey];
        const endTime = break_times[endKey];
        if (isExist(startTime) && isExist(endTime)) {
          breakTimes.push({ startTime, endTime });
        }
      });
      const startTime = scheduled_work_start_time ?? 0;
      const endTime = scheduled_work_end_time ?? 0;
      return {
        startTime,
        endTime,
        breakTimes,
        workingTime: timeIntegerDiff(
          breakTimes.reduce(
            (breakTime, { startTime, endTime }) => timeIntegerAdd(breakTime, timeIntegerDiff(startTime, endTime)),
            0,
          ),
          timeIntegerDiff(startTime, endTime),
        ),
        skills: createSkillsFromApiResponse(staff?.staff_skills),
        timeBlocks: new Array(timeBlockLength).fill(0).map(() => ({ isAllocated: false })),
        isRegular: staff !== undefined && checkIfRegularStaff(staff),
      };
    })
    .sort(({ workingTime: a }, { workingTime: b }) => b - a);
};

const checkIfRegularStaff = (staff: Staff): boolean => {
  return (staff.staff_extension?.is_shift_management_target && staff.staff_extension?.is_appropriation_target) ?? false;
};

/**
 * collective_staff_shifts から割り付け用のスタッフを生成する
 */
export const createStaffForAllocationFromCollectiveStaffShifts = (
  collective_staff_shifts: CollectiveStaffShift[],
  timeBlockLength: number,
): PlanBoardStaffForAllocation[] => {
  return collective_staff_shifts
    .filter((shift): shift is SomeRequired<CollectiveStaffShift, 'collective_staff'> => isExist(shift.collective_staff))
    .flatMap(({ headcount, collective_staff }) => {
      const breakTimes: PlanBoardBreakTime[] = [];
      CollectiveStaffBreakTimeKeyMap.forEach(([startKey, endKey]) => {
        const startTime = collective_staff[startKey];
        const endTime = collective_staff[endKey];
        if (isExist(startTime) && isExist(endTime)) {
          breakTimes.push({ startTime, endTime });
        }
      });
      return new Array(headcount).fill(0).map(() => {
        return {
          startTime: collective_staff.start_time ?? 0,
          endTime: collective_staff.end_time ?? 0,
          breakTimes,
          skills: createSkillsFromApiResponse(collective_staff.collective_staff_skills),
          timeBlocks: new Array(timeBlockLength).fill(0).map(() => ({ isAllocated: false })),
          isRegular: false,
        };
      });
    });
};

/**
 * 応援、余剰、休憩などのシステムで予め用意されている工程を生成する
 */
export const createSystemTimetable = (
  name: string,
  backgroundColor: string,
  id: number,
  displayHourPeriod: number,
  blockLengthPerHour: number,
) => {
  return reactive({
    id: id,
    masterId: id,
    header: {
      name,
      backgroundColor,
      amountWorkBeforehand: 0,
      targetQuantity: Number.MAX_SAFE_INTEGER,
      excessOrDeficiency: 0,
      startTime: '0:00',
      endTime: `${displayHourPeriod}:00`,
      productivity: 1,
      plannedProductivity: 0,
      totalHeadcount: 0,
      requiredHeadcount: Number.MAX_SAFE_INTEGER,
      maxAllocations: Number.MAX_SAFE_INTEGER,
      validation: createInitialValidation(),
    },
    boardHourBlocks: createInitialBoadHourBlocks(displayHourPeriod, blockLengthPerHour),
    isChanged: false,
  } as PlanBoardTimetable);
};

/**
 * 初期状態の PlanBoardHourBlock 配列を返す
 */
export const createInitialBoadHourBlocks = (
  displayHourPeriod: number,
  blockLengthPerHour: number,
): PlanBoardHourBlock[] => {
  return new Array(displayHourPeriod).fill(0).map(() => {
    return {
      totalHeadcount: 0,
      quantity: 0,
      completionRatio: 0,
      headcountList: new Array(blockLengthPerHour).fill(0).map((headcount) => {
        return {
          headcount,
          backgroundColor: getHeadcountBackgroundColor(headcount),
          isStartTime: false,
          isEndTime: false,
        };
      }),
    };
  });
};

/**
 * 初期状態のバリデーション情報を返す
 */
export const createInitialValidation = () => {
  return {
    targetQuantity: true,
    startTime: true,
    endTime: true,
    productivity: true,
    maxAllocations: true,
  };
};

export function formatExcessOrDeficiency(
  excessOrDeficiency: PlanBoardHeader['excessOrDeficiency'],
  targetQuantity: PlanBoardHeader['targetQuantity'],
): number | null {
  if (excessOrDeficiency === null) {
    return null;
  }
  return Math.trunc(targetQuantity) === targetQuantity
    ? Math.round(excessOrDeficiency)
    : Math.round(excessOrDeficiency * 10) / 10;
}
