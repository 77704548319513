import {
  Radio,
  Checkbox,
  DatePicker,
  FgInput,
  Button,
  Dropdown,
  Card,
  Sidebar,
  Modal,
  Pagination,
  Switch,
} from 'src/components/UIComponents';
import { Select, Option, TimePicker, TimeSelect, Input, OptionGroup } from 'element-ui';
import type { PluginObject } from 'vue';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 * Ideally, only small components that are re-used many times across your application should be registered here.
 * For plugins and bigger components local registration is preferable because it will allow you to do code splitting easier :)
 */

const GlobalComponents: PluginObject<never> = {
  install(Vue) {
    type Component = Parameters<typeof Vue.component>[1];
    const registerComponent = (name: string | undefined, component: Component) => {
      if (name === undefined) {
        return;
      }
      Vue.component(name, component);
    };
    registerComponent(Button.name, Button as Component);
    registerComponent(FgInput.name, FgInput as Component);
    registerComponent(Dropdown.name, Dropdown as Component);
    registerComponent(Checkbox.name, Checkbox as Component);
    registerComponent(DatePicker.name, DatePicker as Component);
    registerComponent(Radio.name, Radio as Component);
    registerComponent(Card.name, Card as Component);
    registerComponent(Sidebar.name, Sidebar as Component);
    registerComponent(Modal.name, Modal as Component);
    registerComponent(Pagination.name, Pagination as Component);
    registerComponent(Switch.name, Switch as Component);
    registerComponent(Option.name, Option);
    registerComponent(OptionGroup.name, OptionGroup);
    registerComponent(Select.name, Select);
    registerComponent(TimePicker.name, TimePicker);
    registerComponent(TimeSelect.name, TimeSelect);
    registerComponent(Input.name, Input);
  },
};

export default GlobalComponents;
