export const DISPLAY_HOUR_PERIOD = 48 // 表示したい時間数: 48 固定
export const TIMELINE_COLUMN_WIDTH = 100 // 時間列の 1 列分の幅
export const BLOCK_LENGTH_PER_HOUR = 4 // 1 時間内の単位時間数 例) 単位時間が 15 分の場合 4
export const WEEK_DAY = ['日', '月', '火', '水', '木', '金', '土'];
export const DAY_LABELS = [
  { key: 1, label: '月曜日' },
  { key: 2, label: '火曜日' },
  { key: 3, label: '水曜日' },
  { key: 4, label: '木曜日' },
  { key: 5, label: '金曜日' },
  { key: 6, label: '土曜日' },
  { key: 0, label: '日曜日' },
]
export const MAX_SEARCH_YEAR_RANGE = 1 // 単位:年
