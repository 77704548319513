export const ATTENDANCE_STATUS = {
  ATTENDING: 0,
  NOT_ATTENDING: 1,
  FINISHED: 2,
  ABSENCE: 3,
} as const;
export type AttendanceStatus = (typeof ATTENDANCE_STATUS)[keyof typeof ATTENDANCE_STATUS];

export const ATTENDANCE_STATUS_OPTIONS: { id: AttendanceStatus; name: string }[] = [
  { id: ATTENDANCE_STATUS.ATTENDING, name: '出勤中' },
  { id: ATTENDANCE_STATUS.NOT_ATTENDING, name: '未出勤' },
  { id: ATTENDANCE_STATUS.FINISHED, name: '退勤済' },
  { id: ATTENDANCE_STATUS.ABSENCE, name: '欠勤' },
];
