import { ComputedRef, Ref, computed, ref } from '@vue/composition-api';
import { SkillOption } from 'src/models/staffExperienceOverview';
import { generateInitialSkillOptions } from '../libs/skillOptionsGenerator';
import { SKILL_MAP, Skill } from '../consts';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  skillOptions: Ref<SkillOption[]>;
  changeCheckedSkill: (skill: Skill) => void;
  changeEnabledSkill: (skill: Skill, enabled: boolean) => void;
  enabledCustomSkill1: ComputedRef<boolean>;
  enabledCustomSkill2: ComputedRef<boolean>;
  enabledCustomSkill3: ComputedRef<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffSkills');

export const useStaffSkillProvider = () => {
  const skillOptions = ref<SkillOption[]>(generateInitialSkillOptions());

  const changeCheckedSkill = (skillType: Skill): void => {
    const shouldChangeSkill = skillOptions.value.find((e) => e.type === skillType);
    if (shouldChangeSkill === undefined) {
      return;
    }

    shouldChangeSkill.checked = !shouldChangeSkill.checked;
  };

  const changeEnabledSkill = (skillType: Skill, enabled: boolean): void => {
    const shouldChangeSkill = skillOptions.value.find((e) => e.type === skillType);
    if (shouldChangeSkill === undefined) {
      return;
    }

    shouldChangeSkill.enabled = enabled;
  };

  const getCustomSkillEnabled = (customSkill: Skill): boolean => {
    const skillOption = skillOptions.value.find((e) => e.type === customSkill);
    if (skillOption === undefined) {
      return false;
    }
    return skillOption.enabled;
  };

  const enabledCustomSkill1 = computed(() => getCustomSkillEnabled(SKILL_MAP.CUSTOM_1));
  const enabledCustomSkill2 = computed(() => getCustomSkillEnabled(SKILL_MAP.CUSTOM_2));
  const enabledCustomSkill3 = computed(() => getCustomSkillEnabled(SKILL_MAP.CUSTOM_3));

  provide({
    skillOptions,
    changeCheckedSkill,
    changeEnabledSkill,
    enabledCustomSkill1,
    enabledCustomSkill2,
    enabledCustomSkill3,
  });
};

export const useStaffSkill = (): InjectionValue => {
  return inject();
};
