












































import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { type TimetableHeader } from 'src/models/timetableHeader'
import { formatInteger, formatDecimal, orDefault } from '../../utils/filters'
import {
  getCurrentResultAmount,
  getProgressColor,
  getLastScheduledAmount,
  getSumResultHeadCount,
  getHeadCountColor,
  getSumScheduledHeadCount,
  getCurrentProductivity,
  getCurrentProductivityColor,
  getCurrentTimeScheduledHeadCount,
} from '../../utils/timetableHeaderHelpers'

export default defineComponent({
  filters: {
    formatInteger,
    formatDecimal,
    orDefault,
  },
  props: {
    timetableHeader: {
      type: Object as PropType<TimetableHeader>,
      required: true,
    },
  },
  setup(props) {
    const currentResultAmount = computed(() => getCurrentResultAmount(props.timetableHeader))
    const progressColor = computed(() => getProgressColor(props.timetableHeader))
    const lastScheduledAmount = computed(() => getLastScheduledAmount(props.timetableHeader))
    const sumResultHeadCount = computed(() => getSumResultHeadCount(props.timetableHeader))
    const headCountColor = computed(() => getHeadCountColor(props.timetableHeader))
    const sumScheduledHeadCount = computed(() => getSumScheduledHeadCount(props.timetableHeader))
    const currentProductivity = computed(() => getCurrentProductivity(props.timetableHeader))
    const currentProductivityColor = computed(() => getCurrentProductivityColor(props.timetableHeader))
    const currentTimeScheduledHeadCount = computed(() => getCurrentTimeScheduledHeadCount(props.timetableHeader))

    return {
      currentResultAmount,
      progressColor,
      lastScheduledAmount,
      sumResultHeadCount,
      headCountColor,
      sumScheduledHeadCount,
      currentProductivity,
      currentProductivityColor,
      currentTimeScheduledHeadCount,
    }
  },
})
