import { unref } from '@vue/composition-api'
import shiftPatternApi from 'src/apis/workplace_masters/shiftPattern'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { useModal } from 'src/composables/useModal'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'
import { createInjection } from 'src/util/createInjection'
import { useShiftPatterns } from './useShiftPatterns'

type ShiftPatternSubset = Pick<ShiftPattern,
  | 'name'
  | 'is_enabled'
  | 'start_time'
  | 'end_time'
  | 'break1_start_time'
  | 'break1_end_time'
  | 'break2_start_time'
  | 'break2_end_time'
>

type InjectionValue = {
  showsCreateModal: ReturnType<typeof useModal>['showsModal']
  showCreateModal: ReturnType<typeof useModal>['showModal']
  hideCreateModal: ReturnType<typeof useModal>['hideModal']
  createShiftPattern: (shiftPatternSubset: ShiftPatternSubset) => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useCreateShiftPattern')

async function requestToCreateShiftPattern({
  budgetGroup,
  shiftPatternSubset,
}: {
  budgetGroup: BudgetGroup
  shiftPatternSubset: ShiftPatternSubset
}): Promise<void> {
  await shiftPatternApi.create({
    workplace_id: budgetGroup.workplace_id,
    budget_group_id: budgetGroup.id,
    ...shiftPatternSubset,
  })
}

export function useCreateShiftPatternProvider(): void {
  const { notifySuccess } = useNotification()
  const errorBoundary = useErrorBoundary()
  const {
    showsModal: showsCreateModal,
    showModal: showCreateModal,
    hideModal: hideCreateModal,
  } = useModal()
  const { fetchShiftPatterns, budgetGroup } = useShiftPatterns()
  const createShiftPattern = errorBoundary.wrap(
    async(shiftPatternSubset: ShiftPatternSubset) => {
      const unwrappedBudgetGroup = unref(budgetGroup)
      if (unwrappedBudgetGroup !== null) {
        await requestToCreateShiftPattern({
          budgetGroup: unwrappedBudgetGroup,
          shiftPatternSubset,
        })
        hideCreateModal()
        notifySuccess('シフトパターンを作成しました')
        await fetchShiftPatterns()
      }
    },
    {
      fallbackMessage: 'シフトパターンの作成に失敗しました。',
      shouldContactUs: true,
    },
  )

  provide({
    showsCreateModal,
    showCreateModal,
    hideCreateModal,
    createShiftPattern,
  })
}

export function useCreateShiftPattern(): InjectionValue {
  return inject()
}
