import { createFilter } from 'src/util/filters'
import { timeInteger } from 'src/filters/datetime_filters'
import { addCommaFraction } from 'src/filters/number_filters'

export const formatTime = createFilter((v: number) => timeInteger(v))

export const formatNumber = createFilter((v: number) => addCommaFraction(v, 1))

export const formatFixedDecimal = createFilter(
  (v: number) => v.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 })
)

export const formatRate = createFilter((v: number) => addCommaFraction(v, 0))
