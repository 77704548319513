
import Draggable, { MoveEvent } from 'vuedraggable'
import {
  defineComponent,
  reactive,
  SetupContext,
  getCurrentInstance,
  computed, ComputedRef, watchEffect, onUnmounted, onMounted
} from '@vue/composition-api';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import collectiveStaffApi from 'src/apis/workplace_masters/collective_staff';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { vvHasError, vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { CollectiveStaff } from 'src/models/collectiveStaff';
import { CollectiveStaffSkill } from 'src/models/collectiveStaffSkill';
import { TimetableMaster } from 'src/models/timetableMaster';

import Axios from 'axios';
import { IndexOptParams, CreateOptParams, UpdateOptParams as CollectiveStaffUpdateOptParams } from 'src/models/api/collectiveStaffRequest';
import { packToTimeIntegerWithGuard, unpackTimeIntegerToString, unpackTimeIntegerToStringFormat } from 'src/util/datetime';
import { TimeInteger } from 'src/models/common';

const msgVars = {
  create: '作成',
  update: '編集',
  delete: '削除',
  update_disp_order: '表示順変更',
};
type OpType = 'create' | 'update' | 'delete' | 'update_disp_order'

type SaveCollectiveStaffCandidate = Partial<CollectiveStaff> & {
  isNew?: boolean
  timetableMasterIds?: number[]
  scheduledWorkStartTime?: TimeInteger | null
  scheduledWorkEndTime?: TimeInteger | null
  scheduledBreak1StartTime?: TimeInteger | null
  scheduledBreak1EndTime?: TimeInteger | null
  scheduledBreak2StartTime?: TimeInteger | null
  scheduledBreak2EndTime?: TimeInteger | null
}
type DeleteCollectiveStaffCandidate = Partial<CollectiveStaff>

type TimeLabelNames = 'scheduledWorkStartTime' | 'scheduledWorkEndTime' | 'scheduledBreak1StartTime' | 'scheduledBreak1EndTime' | 'scheduledBreak2StartTime' | 'scheduledBreak2EndTime'

type TimesForEditModal = {
  scheduledWorkStartTimeHour: string | null,
  scheduledWorkStartTimeMin: string | null,
  scheduledWorkEndTimeHour: string | null,
  scheduledWorkEndTimeMin: string | null,
  scheduledBreak1StartTimeHour: string | null,
  scheduledBreak1StartTimeMin: string | null,
  scheduledBreak1EndTimeHour: string | null,
  scheduledBreak1EndTimeMin: string | null,
  scheduledBreak2StartTimeHour: string | null,
  scheduledBreak2StartTimeMin: string | null,
  scheduledBreak2EndTimeHour: string | null,
  scheduledBreak2EndTimeMin: string | null,
}
const initTimesForEditModal: TimesForEditModal = {
  scheduledWorkStartTimeHour: '',
  scheduledWorkStartTimeMin: '',
  scheduledWorkEndTimeHour: '',
  scheduledWorkEndTimeMin: '',
  scheduledBreak1StartTimeHour: '',
  scheduledBreak1StartTimeMin: '',
  scheduledBreak1EndTimeHour: '',
  scheduledBreak1EndTimeMin: '',
  scheduledBreak2StartTimeHour: '',
  scheduledBreak2StartTimeMin: '',
  scheduledBreak2EndTimeHour: '',
  scheduledBreak2EndTimeMin: '',
}

interface State {
  collectiveStaffs: CollectiveStaff[]
  timetableMasters: TimetableMaster[]
  saveCollectiveStaffCandidate: SaveCollectiveStaffCandidate
  timesForEditModal: TimesForEditModal
  displayListDisabledItem: boolean
  showCollectiveStaffSaveModal: boolean
  deleteCollectiveStaffCandidate: DeleteCollectiveStaffCandidate
  showCollectiveStaffDeleteModal: boolean
  selectedBudgetGroupId: number | null
  hasDupTimetableMaster: boolean
}

function getValidationMap(): Record<string, Object> {
  const ruleStr = { required: true, max: 10 }
  const ruleHour = { numeric: true, max: 2, max_value: 48 }
  const ruleMin = { numeric: true, max: 2, regex: /^(00|15|30|45)$/ }
  const ruleHourReq = { ...ruleHour, required: true }
  const ruleMinReq = { ...ruleMin, required: true }
  return {
    name: ruleStr,
    scheduledWorkStartTimeHour: ruleHourReq,
    scheduledWorkStartTimeMin: ruleMinReq,
    scheduledWorkEndTimeHour: ruleHourReq,
    scheduledWorkEndTimeMin: ruleMinReq,
    scheduledBreak1StartTimeHour: ruleHour,
    scheduledBreak1StartTimeMin: ruleMin,
    scheduledBreak1EndTimeHour: ruleHour,
    scheduledBreak1EndTimeMin: ruleMin,
    scheduledBreak2StartTimeHour: ruleHour,
    scheduledBreak2StartTimeMin: ruleMin,
    scheduledBreak2EndTimeHour: ruleHour,
    scheduledBreak2EndTimeMin: ruleMin,
  }
}

function setupState(): State {
  return reactive({
    collectiveStaffs: [],
    timetableMasters: [],
    saveCollectiveStaffCandidate: {
      isNew: true,
      timetableMasterIds: [],
      scheduledWorkStartTime: null,
      scheduledWorkEndTime: null,
      scheduledBreak1StartTime: null,
      scheduledBreak1EndTime: null,
      scheduledBreak2StartTime: null,
      scheduledBreak2EndTime: null,
    },
    timesForEditModal: initTimesForEditModal,
    displayListDisabledItem: false,
    showCollectiveStaffSaveModal: false,
    deleteCollectiveStaffCandidate: {},
    showCollectiveStaffDeleteModal: false,
    selectedBudgetGroupId: null,
    hasDupTimetableMaster: false
  });
}

export default defineComponent({
  components: { Draggable },
  name: 'budget-group-extension',
  props: {
    budgetGroupId: {
      type: Number
    },
    isEnableStatus: {
      type: Boolean
    }
  },
  setup(props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    const state = setupState();

    const userId: ComputedRef<number> = wrappedMapGetters(
      vueInstance.$store,
      'user',
      ['id']
    ).id;
    const workplaceId = computed(() => {
      return vueInstance.$route.params.workplaceId;
    });
    const hasError = computed(() => {
      return vvHasError(vueInstance);
    });

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }
    function clearErrors() {
      vvReset(vueInstance);
    }

    watchEffect(async() => {
      state.displayListDisabledItem = props.isEnableStatus
      await getCollectiveStaffs(props.budgetGroupId!)
    });

    // スタッフグループ編集モーダルopen
    function openCollectiveStaffSaveModal(item?: CollectiveStaff): void {
      let saveCandidate: SaveCollectiveStaffCandidate;
      if (!item) {
        saveCandidate = { isNew: true };
        saveCandidate.budget_group_id = state.collectiveStaffs[0].budget_group_id
        saveCandidate.is_enabled = true
        saveCandidate.timetableMasterIds = [0, 0, 0, 0, 0] // デフォルトを0にするため
        state.timesForEditModal = { ...initTimesForEditModal }
      } else {
        const timetableMasterIds = [...Array(5)].map((_, i) => {
          const collective_staff_skill = findCollectiveStaffSkill(item.collective_staff_skills, i + 1)
          return collective_staff_skill?.timetable_master_id || 0
        })
        saveCandidate = {
          ...item,
          isNew: false,
          timetableMasterIds: timetableMasterIds,
          scheduledWorkStartTime: item.start_time,
          scheduledWorkEndTime: item.end_time,
          scheduledBreak1StartTime: item.break1_start_time,
          scheduledBreak1EndTime: item.break1_end_time,
          scheduledBreak2StartTime: item.break2_start_time,
          scheduledBreak2EndTime: item.break2_end_time,
        };
        state.timesForEditModal = createTimesForEditModal(item)
      }
      state.saveCollectiveStaffCandidate = saveCandidate;
      hasDupTimetableMaster()
      state.showCollectiveStaffSaveModal = true;
      clearErrors()
    }

    const createTimesForEditModal = (collectiveStaff: CollectiveStaff): TimesForEditModal => {
      const [scheduledWorkStartTimeHour, scheduledWorkStartTimeMin] = timeIntegerToString(collectiveStaff.start_time)
      const [scheduledWorkEndTimeHour, scheduledWorkEndTimeMin] = timeIntegerToString(collectiveStaff.end_time)
      const [scheduledBreak1StartTimeHour, scheduledBreak1StartTimeMin] = timeIntegerToString(collectiveStaff.break1_start_time)
      const [scheduledBreak1EndTimeHour, scheduledBreak1EndTimeMin] = timeIntegerToString(collectiveStaff.break1_end_time)
      const [scheduledBreak2StartTimeHour, scheduledBreak2StartTimeMin] = timeIntegerToString(collectiveStaff.break2_start_time)
      const [scheduledBreak2EndTimeHour, scheduledBreak2EndTimeMin] = timeIntegerToString(collectiveStaff.break2_end_time)
      return {
        scheduledWorkStartTimeHour,
        scheduledWorkStartTimeMin,
        scheduledWorkEndTimeHour,
        scheduledWorkEndTimeMin,
        scheduledBreak1StartTimeHour,
        scheduledBreak1StartTimeMin,
        scheduledBreak1EndTimeHour,
        scheduledBreak1EndTimeMin,
        scheduledBreak2StartTimeHour,
        scheduledBreak2StartTimeMin,
        scheduledBreak2EndTimeHour,
        scheduledBreak2EndTimeMin,
      }
    }

    // スタッフスキル検索
    function findCollectiveStaffSkill(collective_staff_skills: CollectiveStaffSkill[], num: number): CollectiveStaffSkill | undefined {
      return collective_staff_skills.find((collective_staff_skill: CollectiveStaffSkill) => {
        return collective_staff_skill.priority === num
      })
    }

    // スタッフグループ編集モーダルclose
    function closeCollectiveStaffSaveModal(): void {
      state.saveCollectiveStaffCandidate = { isNew: true, timetableMasterIds: [] };
      state.hasDupTimetableMaster = false
      state.showCollectiveStaffSaveModal = false;
      clearErrors();
    }

    // スタッフグループsave
    async function _saveCollectiveStaff() {
      if (!state.saveCollectiveStaffCandidate) { return; }
      const isValid = await vvValidate(vueInstance);
      if (!isValid) { return }

      if (state.saveCollectiveStaffCandidate.isNew) {
        await doCollectiveStaffCreate()
      } else {
        await doCollectiveStaffUpdate()
      }
    }

    // スタッフグループcreate
    async function doCollectiveStaffCreate(): Promise<void> {
      const opType: OpType = 'create'
      const data: CreateOptParams = {
        budget_group_id: state.saveCollectiveStaffCandidate.budget_group_id!,
        name: state.saveCollectiveStaffCandidate.name!,
        is_enabled: state.saveCollectiveStaffCandidate.is_enabled!,
        timetable_master_ids: state.saveCollectiveStaffCandidate.timetableMasterIds || [],
        start_time: state.saveCollectiveStaffCandidate.scheduledWorkStartTime ?? null,
        end_time: state.saveCollectiveStaffCandidate.scheduledWorkEndTime ?? null,
        break1_start_time: state.saveCollectiveStaffCandidate.scheduledBreak1StartTime ?? null,
        break1_end_time: state.saveCollectiveStaffCandidate.scheduledBreak1EndTime ?? null,
        break2_start_time: state.saveCollectiveStaffCandidate.scheduledBreak2StartTime ?? null,
        break2_end_time: state.saveCollectiveStaffCandidate.scheduledBreak2EndTime ?? null,
      }
      try {
        await collectiveStaffApi.create({ workplaceId: workplaceId.value, data: data as CreateOptParams });
        await getCollectiveStaffs(state.saveCollectiveStaffCandidate.budget_group_id!)
        closeCollectiveStaffSaveModal();
        notifySuccess1(vueInstance, `スタッフグループを${msgVars[opType]}しました`);
      } catch (err: any) {
        onCollectiveStaffSaveError(err, opType)
      }
    }

    // スタッフグループupdate
    async function doCollectiveStaffUpdate(): Promise<void> {
      const opType: OpType = 'update'
      const data: CollectiveStaffUpdateOptParams = {
        id: state.saveCollectiveStaffCandidate.id!,
        budget_group_id: state.saveCollectiveStaffCandidate.budget_group_id!,
        name: state.saveCollectiveStaffCandidate.name!,
        is_enabled: state.saveCollectiveStaffCandidate.is_enabled!,
        timetable_master_ids: state.saveCollectiveStaffCandidate.timetableMasterIds || [],
        start_time: state.saveCollectiveStaffCandidate.scheduledWorkStartTime ?? null,
        end_time: state.saveCollectiveStaffCandidate.scheduledWorkEndTime ?? null,
        break1_start_time: state.saveCollectiveStaffCandidate.scheduledBreak1StartTime ?? null,
        break1_end_time: state.saveCollectiveStaffCandidate.scheduledBreak1EndTime ?? null,
        break2_start_time: state.saveCollectiveStaffCandidate.scheduledBreak2StartTime ?? null,
        break2_end_time: state.saveCollectiveStaffCandidate.scheduledBreak2EndTime ?? null,
      }
      try {
        await collectiveStaffApi.update({ workplaceId: workplaceId.value, data: data });
        await getCollectiveStaffs(state.saveCollectiveStaffCandidate.budget_group_id!)
        closeCollectiveStaffSaveModal();
        notifySuccess1(vueInstance, `スタッフグループを${msgVars[opType]}しました`);
      } catch (err: any) {
        onCollectiveStaffSaveError(err, opType)
      }
    }

    // スタッフグループ error
    function onCollectiveStaffSaveError(err: any, opType: OpType): void {
      if (Axios.isAxiosError(err)) {
        const errStatus = err.response!.status;
        const errRes = err.response!.data || {};
        if (errStatus === 400 && errRes.reason === 'dup_name') {
          const msg = '同一のスタッフグループ名があります。'
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 })
        } else if (errStatus === 400 && errRes.reason === 'dup_timetable_master') {
          const msg = '同じ担当工程は選択できません。'
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 })
        } else if (errStatus >= 400 && errStatus < 500) {
          const msg = Array.isArray(errRes) ? errRes[0] : Object.values(errRes)[0];
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          const errId = state.saveCollectiveStaffCandidate.isNew ? 'ERR00001' : 'ERR00002';
          const msg = `スタッフグループの${msgVars[opType]}に失敗しました。` +
              '管理者に連絡してください。' +
              `(ERR: スタッフグループ ${errId}, user_id:${userId.value})`;
          notifyError1(vueInstance, msg, { err });
        }
      }
    }

    // スタッフグループ削除モーダルopen
    function openCollectiveStaffDeleteModal(item: CollectiveStaff): void {
      state.deleteCollectiveStaffCandidate = item as DeleteCollectiveStaffCandidate;
      state.showCollectiveStaffDeleteModal = true;
    }

    // スタッフグループ削除モーダルclose
    function closeCollectiveStaffDeleteModal(): void {
      state.deleteCollectiveStaffCandidate = {};
      state.showCollectiveStaffDeleteModal = false;
    }

    // スタッフグループ削除
    async function _deleteCollectiveStaff(): Promise<void> {
      if (!state.deleteCollectiveStaffCandidate) { return; }
      const opType = 'delete';
      try {
        await collectiveStaffApi.delete({ workplaceId: workplaceId.value, budgetGroupId: state.deleteCollectiveStaffCandidate.budget_group_id!, itemId: state.deleteCollectiveStaffCandidate.id! });
        await getCollectiveStaffs(state.deleteCollectiveStaffCandidate.budget_group_id!)
        closeCollectiveStaffDeleteModal();
        notifySuccess1(vueInstance, `スタッフグループを${msgVars[opType]}しました`);
      } catch (err) {
        if (Axios.isAxiosError(err)) {
          const errStatus = err.response!.status;
          const errRes = err.response!.data || {}
          if (errStatus === 400 && errRes.reason === 'is_undeletable') {
            const msg = '一定時間が経過したため削除できません。無効化をおすすめします。'
            notifyError1(vueInstance, msg, { timeout: 5 * 1000 })
          } else if (errStatus === 400 && errRes.reason === 'in_use') {
            const msg = 'すでに使われているマスタです。削除できません。無効化をおすすめします。'
            notifyError1(vueInstance, msg, { timeout: 5 * 1000 })
          } else {
            const errId = 'ERR00003';
            const msg = `スタッフグループの${msgVars[opType]}に失敗しました。` +
              '管理者に連絡してください。' +
              `(ERR: スタッフグループ ${errId}, user_id:${userId.value})`;
            notifyError1(vueInstance, msg, { err });
          }
        }
      }
    }

    async function _updateDispOrder() {
      const opType = 'update_disp_order';
      try {
        state.collectiveStaffs.forEach((e, i) => {
          e.disp_order = i + 1
        })
        const reqObj = {
          workplaceId: workplaceId.value,
          budgetGroupId: state.selectedBudgetGroupId!,
          items: state.collectiveStaffs,
        }
        await collectiveStaffApi.bulkUpdateDispOrder(reqObj)
        await getCollectiveStaffs(state.selectedBudgetGroupId!)
      } catch (err) {
        if (Axios.isAxiosError(err)) {
          const msg = `スタッフグループの${msgVars[opType]}に失敗しました。` +
          '管理者に連絡してください。' +
          `(ERR: スタッフグループ ERR00004, user_id:${userId.value})`
          notifyError1(vueInstance, msg, { err })
        }
      }
    }

    // スタッフグループ取得
    async function getCollectiveStaffs(budgetGroupId: number) {
      state.selectedBudgetGroupId = budgetGroupId
      const params: IndexOptParams = {}
      if (!state.displayListDisabledItem) {
        params.is_enabled = true
      }

      state.collectiveStaffs = (await collectiveStaffApi.index({
        workplaceId: workplaceId.value,
        budgetGroupId: budgetGroupId,
        params: params
      })) as CollectiveStaff[]

      state.timetableMasters = await timetableMasterApi.index({
        workplaceId: workplaceId.value,
        params: { budget_group_id: budgetGroupId }
      });
    }

    function isDraggable(e: MoveEvent<CollectiveStaff>) {
      const dragged = e.draggedContext.element
      const target = e.relatedContext.element
      return dragged.budget_group_id === target.budget_group_id && dragged.collective_type === 'normal' && target.collective_type === 'normal'
    }

    const isDupSelect = (value: number): boolean => {
      const count: {[key: number]: number} = {};
      state.saveCollectiveStaffCandidate.timetableMasterIds?.forEach((i) => {
        count[i] = (count[i] || 0) + 1;
      });

      if (value && count[value] > 1) {
        return true
      }
      return false
    }

    const hasDupTimetableMaster = () => {
      const ids: number[] = state.saveCollectiveStaffCandidate.timetableMasterIds?.filter((i) => i > 0) || []
      const setIds = new Set(ids);
      state.hasDupTimetableMaster = setIds.size !== ids.length;
    }

    const gatedFuncGenerator = getGatedFuncGenerator();

    async function onTimeChange(name: TimeLabelNames) {
      state.saveCollectiveStaffCandidate[`${name}`] = packToTimeIntegerWithGuard(state.timesForEditModal[`${name}Hour`], state.timesForEditModal[`${name}Min`], 0)
      vvValidate(vueInstance, 'time_check')
    }

    // マウント時処理
    onMounted(async() => {
      vueInstance.$validator.attach({
        name: 'time_check',
        rules: 'custom_after2',
        getter: () => {
          return {
            start_time: state.saveCollectiveStaffCandidate.scheduledWorkStartTime,
            end_time: state.saveCollectiveStaffCandidate.scheduledWorkEndTime,
            break1_start_time: state.saveCollectiveStaffCandidate.scheduledBreak1StartTime,
            break1_end_time: state.saveCollectiveStaffCandidate.scheduledBreak1EndTime,
            break2_start_time: state.saveCollectiveStaffCandidate.scheduledBreak2StartTime,
            break2_end_time: state.saveCollectiveStaffCandidate.scheduledBreak2EndTime,
          }
        },
      })
    })
    onUnmounted(() => {
      vueInstance.$validator.detach('time_check')
    })

    return {
      state,
      validations: getValidationMap(),
      // computed
      hasError,
      // methods
      getError,
      openCollectiveStaffSaveModal,
      closeCollectiveStaffSaveModal,
      openCollectiveStaffDeleteModal,
      closeCollectiveStaffDeleteModal,
      saveCollectiveStaff: gatedFuncGenerator.makeAsyncFuncGated(_saveCollectiveStaff),
      deleteCollectiveStaff: gatedFuncGenerator.makeAsyncFuncGated(_deleteCollectiveStaff),
      updateDispOrder: gatedFuncGenerator.makeAsyncFuncGated(_updateDispOrder),
      findCollectiveStaffSkill,
      isDraggable,
      isDupSelect,
      hasDupTimetableMaster,
      timeString,
      onTimeChange,
      dragOptions: {
        handle: '.grabbable',
        animation: 300,
      },
    }
  },
});

// 0 表示ではなく空表示になるように wrap
const timeIntegerToString = (timeInteger: TimeInteger | null) => {
  if (timeInteger == null) { return ['', '', ''] }
  return unpackTimeIntegerToString(timeInteger)
}

const timeString = (timeInteger: TimeInteger | null): string => {
  return timeInteger !== null ? unpackTimeIntegerToStringFormat(timeInteger) : ''
}
