import { onBeforeMount, onUnmounted } from '@vue/composition-api'
import { Validator, type Rule, type ExtendOptions } from 'vee-validate'
import { createUniqueName } from 'src/util/createUniqueName'

type Params = Omit<Rule, 'options' | 'paramNames'> & {
  name: string
  options?: object & ExtendOptions
}

export { Params as Validator }

export function useCustomValidator({ name, validate, getMessage, options }: Params): string {
  const uniqueName = createUniqueName(`useCustomValidator_${name}`)

  onBeforeMount(() => {
    Validator.extend(uniqueName, { validate, getMessage }, options)
  })

  onUnmounted(() => {
    /**
     * deprecatedかつproduction以外ではwarningが出るが、グローバルに追加したバリデーションを残しておくのも気持ち悪いので削除する。
     * ちなみに、VeeValidateのv3では削除する方法がなくなっているが、v4ではグローバルに追加しなくてもバリデーションを行う方法が提供されている（v4はVue3専用）。
     *
     * @see {@link https://github.com/logaretm/vee-validate/blob/4f84da06f98a1bfb478ac130eff8cc2812beda59/src/core/validator.js#L149-L159}
     */
    Validator.remove(uniqueName)
  })

  return uniqueName
}
