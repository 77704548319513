import type { SomeRequired } from 'src/util/type_util';
import type { Skill } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/consts';
import type { StaffExperienceOverviewResponse } from './api/staffExperienceOverviewResponse';
import type { StaffResponse } from 'src/models/api/staffResponse';
import type { TimetableMasterResponse } from 'src/models/api/timetableMasterResponse';

export type MonthRange = {
  startMonth: string;
  endMonth: string;
};

export type SkillOption = {
  type: Skill;
  checked: boolean;
  enabled: boolean;
};

export type TimetableMasterSummary = {
  timetable_master: TimetableMasterResponse;
  work_hours: number;
};

export type TotalTimetableMasterSummary = Pick<TimetableMasterSummary, 'timetable_master'> & {
  experience_count: number;
};

export type TotalSummary = {
  staffCount: number;
  keyPlayerCount: number;
  forkmanCount: number;
  customSkill1Count: number;
  customSkill2Count: number;
  customSkill3Count: number;
  timetableMasterSummaries: TotalTimetableMasterSummary[];
};

export type StaffWithTimetableMasterSummaries = SomeRequired<StaffResponse, 'staff_extension' | 'staff_label'> & {
  timetable_master_summaries: TimetableMasterSummary[];
};

export type StaffExperienceOverview = {
  staffs_with_timetable_master_summaries: StaffWithTimetableMasterSummaries[];
};

export const convertStaffExperienceOverviewResponse = (
  response: StaffExperienceOverviewResponse,
): StaffExperienceOverview => {
  const { staffs_with_timetable_master_summaries } = response;
  const newStaffsWithTimetableMasterSummaries = staffs_with_timetable_master_summaries.map((staff) => {
    const newStaff = {
      ...staff,
      timetable_master_summaries: staff.timetable_master_summaries.map((timetable_master_summary) => ({
        ...timetable_master_summary,
        work_hours: Number(timetable_master_summary.work_hours),
      })),
    };
    return newStaff;
  });

  return {
    staffs_with_timetable_master_summaries: newStaffsWithTimetableMasterSummaries,
  };
};
