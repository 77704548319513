





















import { defineComponent, watch, type PropType } from '@vue/composition-api'
import { useDarkColorSet } from './useDarkColorSet'

export default defineComponent({
  props: {
    value: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  setup(props, context) {
    const { darkColorSet } = useDarkColorSet()

    // 選択可能な色に選択中の色が含まれていなければ、選択可能な色の先頭のものを選択中にする
    watch(
      [() => props.value, darkColorSet],
      () => {
        const hasValue = darkColorSet.value.some((v) => v.key === props.value)
        const newValue = darkColorSet.value[0]?.key ?? null
        if (!hasValue && newValue !== props.value) { // newValue !== props.value は双方nullの場合を除外するため
          context.emit('input', newValue)
        }
      }
    )

    return {
      darkColorSet,
    }
  },
})
