




























import { defineComponent, reactive, watch } from '@vue/composition-api';
import { ModalForm, FormGroup, FormItem } from 'src/components/UIComponents/Form';
import { TimeInput } from 'src/components/UIComponents/Inputs/TimeInput';
import { Validator, useCustomValidator } from 'src/composables/useCustomValidator';
import { useCommonSetting } from '../composables/useCommonSetting';
import { CommonSettingState } from '../types';
import { isValid15StepMinutes } from 'src/values/TimeInteger/isValid15StepMinutes';

const targetClockOutTimeValidator: Validator = {
  name: 'targetClockOutTime',
  validate(value: unknown) {
    if (typeof value !== 'number') {
      return false;
    }
    return isValid15StepMinutes(value);
  },
};

export default defineComponent({
  components: {
    FormGroup,
    FormItem,
    ModalForm,
    TimeInput,
  },
  setup() {
    const targetClockOutTimeValidationRule = useCustomValidator(targetClockOutTimeValidator);
    const { commonSetting, hideCommonSettingModal, setCommonSetting, showsCommonSettingModal } = useCommonSetting();
    const state: CommonSettingState = reactive({
      targetClockOutTime: null,
    });

    watch(
      () => showsCommonSettingModal.value,
      () => {
        state.targetClockOutTime = commonSetting.targetClockOutTime;
      },
    );

    const onSubmit = async () => {
      setCommonSetting(state);
      hideCommonSettingModal();
    };

    return {
      state,
      targetClockOutTimeValidationRule,
      showsCommonSettingModal,
      hideCommonSettingModal,
      onSubmit,
    };
  },
});
