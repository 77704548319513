
























































































































import { defineComponent } from '@vue/composition-api';
import { useDisplayConditions } from '../composables/useDisplayConditions';
import { useBudgetGroupPlanBoardMisc } from '../composables/useBudgetGroupPlanBoardMiscs';
import { useDailySimulation } from '../composables/useDailySimulation';
import { useStaffShiftTimelines } from '../composables/useStaffShiftTimelines';
import { useScrollSync } from '../composables/useScrollSync';

const MEMO_MAX_LENGTH = 255;

export default defineComponent({
  setup() {
    const { state: budgetGroupPlanBoardMiscState, updateMemo } = useBudgetGroupPlanBoardMisc();
    const { state: dailySimulationState, timetableRowStyle } = useDailySimulation();
    const { state: staffShiftState } = useStaffShiftTimelines();
    const { isShiftGroupDisplayed } = useDisplayConditions();
    const { shiftTableElement: shiftTablesRef } = useScrollSync();

    const handleBlurMemo = async () => {
      if (budgetGroupPlanBoardMiscState.memo.length > MEMO_MAX_LENGTH) {
        return;
      }

      await updateMemo();
    };

    const totalTimeBlockCount = (displayTime: string): number => {
      return staffShiftState.staffShiftTimelineRow.reduce((total, row) => {
        return (
          total +
          row.totalHourBlocks.reduce((sum, totalHourBlock) => {
            const unitTimeBlock = totalHourBlock.unitTimeBlocks.find((block) => block.displayTime === displayTime);
            return sum + (unitTimeBlock?.count || 0);
          }, 0)
        );
      }, 0);
    };

    return {
      timetableRowStyle,
      isShiftGroupDisplayed,
      budgetGroupPlanBoardMiscState,
      dailySimulationState,
      staffShiftState,
      handleBlurMemo,
      totalTimeBlockCount,
      MEMO_MAX_LENGTH,
      shiftTablesRef,
    };
  },
});
