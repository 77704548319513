import { MonthRange } from 'src/models/staffExperienceOverview';
import { DEFAULT_MONTH_RANGE } from '../consts';
import { add, sub } from 'date-fns';
import { formatDate, parseYmdDate } from 'src/util/datetime';

const getStartDate = (date: Date | null = null): string => {
  if (!date) {
    date = new Date();
    date = sub(date, { months: DEFAULT_MONTH_RANGE });
  }
  return formatDate(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
};

const getEndDate = (date: Date | null = null): string => {
  if (!date) {
    date = new Date();
  }
  return formatDate(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
};

export const getInitialStartAndEndMonth = (): MonthRange => {
  return {
    startMonth: getStartDate(),
    endMonth: getEndDate(),
  };
};

export const getMonthRangeOnMonthChange = (
  newMonth: string,
  startOrEndMonth: string,
  isChangedStartMonth: boolean,
): MonthRange => {
  const monthRange: MonthRange = {
    startMonth: isChangedStartMonth ? newMonth : startOrEndMonth,
    endMonth: !isChangedStartMonth ? newMonth : startOrEndMonth,
  };

  const newMonthRange = isChangedStartMonth
    ? getMonthRangeOnStartMonthChange(monthRange)
    : getMonthRangeOnEndMonthChange(monthRange);

  return newMonthRange;
};

const getMonthRangeOnStartMonthChange = (monthRange: MonthRange): MonthRange => {
  const startMonth = parseYmdDate(monthRange.startMonth);
  const endMonth = parseYmdDate(monthRange.endMonth);
  monthRange.startMonth = getStartDate(startMonth);
  if (sub(endMonth, { months: 5 }) > startMonth) {
    monthRange.endMonth = getEndDate(add(startMonth, { months: 5 }));
  }

  return monthRange;
};

const getMonthRangeOnEndMonthChange = (monthRange: MonthRange): MonthRange => {
  const startMonth = parseYmdDate(monthRange.startMonth);
  const endMonth = parseYmdDate(monthRange.endMonth);
  monthRange.endMonth = getEndDate(endMonth);
  if (sub(endMonth, { months: 5 }) > startMonth) {
    monthRange.startMonth = getStartDate(sub(endMonth, { months: 5 }));
  }

  return monthRange;
};
