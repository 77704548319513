import { getCurrentInstance, type ComputedRef } from '@vue/composition-api'
import { useNotification } from 'src/composables/useNotification';
import { wrappedMapGetters } from 'src/hooks/storeHook'

type Options = {
  fallbackMessage?: string
  shouldContactUs?: boolean
}

type Callback = (...args: any[]) => Promise<unknown> | unknown

type ErrorBoundary = {
  wrap: <T extends Callback>(callback: T, options?: Options) => (...args: Parameters<T>) => Promise<boolean>
}

function isObject(value: unknown): value is Record<keyof any, unknown> {
  return typeof value === 'object' && value !== null
}

function isString(value: unknown): value is string {
  return typeof value === 'string'
}

function isRecordInvalid(e: unknown): e is { response: { data: { reason: 'record_invalid', messages: string[] } } } {
  return isObject(e)
    && isObject(e.response)
    && isObject(e.response.data)
    && e.response.data.reason === 'record_invalid'
    && Array.isArray(e.response.data.messages)
}

function hasMessage(e: unknown): e is { response: { data: { message: string } } } {
  return isObject(e)
    && isObject(e.response)
    && isObject(e.response.data)
    && isString(e.response.data.message)
}

function getErrorMessages(e: unknown, userId: number, options: Options): string[] {
  if (isRecordInvalid(e)) {
    return e.response.data.messages
  }

  if (hasMessage(e)) {
    return [e.response.data.message]
  }

  const fallbackMessage = options.fallbackMessage ?? 'エラーが発生しました。'
  if (options.shouldContactUs) {
    return [`${fallbackMessage}管理者に連絡してください。(ERR: user_id:${userId})`]
  }

  return [fallbackMessage]
}

function useUserId(): ComputedRef<number> {
  const root = getCurrentInstance()?.proxy!
  return wrappedMapGetters(root.$store, 'user', ['id']).id
}

export function useErrorBoundary(): ErrorBoundary {
  const { notifyError } = useNotification()
  const userId = useUserId()
  const wrap = <T extends Callback>(callback: T, options: Options = {}) => {
    return async (...args: Parameters<T>) => {
      try {
        await callback(...args)
        return true
      } catch (e: unknown) {
        const messages = getErrorMessages(e, userId.value, options)
        messages.forEach(v => notifyError(v, { cause: e }))
        return false
      }
    }
  }

  return {
    wrap,
  }
}
