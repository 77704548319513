<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
import notificationMixin from 'src/mixins/notificationMixin'
import masterMixin from 'src/mixins/masterMixin'
import budgetUnitApi from 'src/apis/workplace_masters/budget_unit'
import timingControlMixin from 'src/mixins/timingControlMixin'

const api = budgetUnitApi
const displayPageName = '管理グループに含む荷主'
const msgVars = { create: '作成', delete: '削除', update_disp_order: '表示順変更' }

export default {
  components: { Draggable },
  displayPageName: displayPageName,
  props: {
    searchParams: {
      type: Object,
      default: () => {},
      description: 'id of selected budget group'
    },
  },
  data() {
    return {
      displayPageName: displayPageName,
      list: [],
      saveCandidate: {},
      deleteCandidate: {},
      showSaveModal: false,
      showDeleteModal: false,
      budgetUnitCandidates: [],

      pagination: {
        perPage: 50,
        currentPage: 1,
        total: 0,
      },

      dragOptions: {
        handle: '.grabbable',
        animation: 300,
        chosenClass: 'bg-color-default',
      },
    }
  },
  computed: {
    ...mapGetters('user', {
      userId: 'id',
    }),
    workplaceId() {
      return this.$route.params.workplaceId
    },
    hasList() {
      return this.list.length > 0
    },
    paginatedList() {
      const startIdx =
        this.pagination.perPage * Math.max((this.pagination.currentPage - 1), 0)
      return this.list.slice(startIdx, startIdx + this.pagination.perPage)
    },
    shouldPaginate() {
      return this.pagination.total > this.pagination.perPage
    },
    hasError() {
      return this.errors.any()
    },
  },
  watch: {
    searchParams: {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  mixins: [notificationMixin, masterMixin, timingControlMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getApiParams() {
      const apiParams = {}
      if (!this.searchParams.displayListDisabledItem) {
        apiParams.is_enabled = true
      }
      return apiParams
    },
    clearErrors() {
      this.$validator.reset()
    },
    async getList() {
      const { data: budgetUnits } = await api.index({
        workplaceId: this.workplaceId,
        params: this.getApiParams(),
      })

      // For retrieving the data and select box's candidates in one API call,
      // filtering is done both in API and VUE side.
      this.list = budgetUnits.filter(e => e.budget_group_id === this.searchParams.budgetGroup.id)
      this.pagination.total = this.list.length

      // Display only un-assigned activity master
      this.budgetUnitCandidates = budgetUnits.filter(e => e.budget_group_id === null)
    },
    openSaveModal() {
      const errItems = []
      if (this.budgetUnitCandidates.length === 0) {
        errItems.push('荷主')
      }
      if (errItems.length > 0) {
        errItems.forEach(item => {
          const msg = `選択できる${item}がありません。先に${item}を作成してください。`
          this.notifyError1(msg)
        })
        return
      }

      const saveCandidate = {}
      saveCandidate.id = this.budgetUnitCandidates[0].id
      this.saveCandidate = saveCandidate
      this.showSaveModal = true
    },
    closeSaveModal() {
      this.saveCandidate = {}
      this.clearErrors()
      this.showSaveModal = false
    },
    async saveItem() {
      this.saveCandidate.budget_group_id = this.searchParams.budgetGroup.id
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        }
        await api.update(reqObj)
        this.getList()
        this.closeSaveModal()
        this.notifySuccess1(`${displayPageName}を${msgVars.create}しました`)
      } catch (err) {
        const errId = this.saveCandidate.isNew ? 'ERR00001' : 'ERR00002'
        const msg = `${displayPageName}の${msgVars.create}に失敗しました。` +
          '管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    openDeleteModal(item) {
      this.deleteCandidate = item
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.deleteCandidate = {}
      this.showDeleteModal = false
    },
    async deleteItem() {
      this.saveCandidate.budget_group_id = null
      this.saveCandidate.id = this.deleteCandidate.id
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        }
        await api.update(reqObj)
        this.getList()
        this.closeDeleteModal()
        this.notifySuccess1(`${displayPageName}を${msgVars.delete}しました`)
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'is_system_use') {
          const msg = '共通荷主は削除できません。' +
            '無効化をおすすめします。'
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00003'
          const msg = `${displayPageName}の${msgVars.delete}に失敗しました。` +
            '管理者に連絡してください。' +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    isDraggable(e) {
      return true
    },
    async updateDispOrder() {
      const opType = 'update_disp_order'
      try {
        this.list.forEach((e, i) => {
          e.disp_order = i + 1
        })
        const reqObj = {
          workplaceId: this.workplaceId,
          items: this.list,
        }
        await api.bulkUpdateDispOrder(reqObj)
        this.getList()
      } catch (err) {
        const errId = 'ERR00004'
        const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
          '管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    makeFuncGated() {
      this.saveItem = this.makeAsyncFuncGated(this.saveItem)
      this.deleteItem = this.makeAsyncFuncGated(this.deleteItem)
      this.updateDispOrder = this.makeAsyncFuncGated(this.updateDispOrder)
    }
  },
  created() {
    this.makeFuncGated()
  },
  async mounted() {
    await this.ensureUserAndMasters()
    this.getList()
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
