import { CurrentStaffWorkSumResponse } from 'src/models/api/currentStaffWorkResponse'

export type StaffHeadCounts = {
  working: number | null
  resting: number | null
  waiting: number | null
  other_working: number | null
}

export type ActivityStatusMap = {
  our_staff_head_counts: StaffHeadCounts
  rescue_staff_head_counts: StaffHeadCounts
}

export type AttendanceStatusMap = {
  working: number | null
  rescuing: number | null
  left_work: number | null
  not_yet_at_work: number | null
  unscheduled_absent: number | null
}

export type CurrentStaffWork = {
  activity_status_map: ActivityStatusMap
  attendance_status_map: AttendanceStatusMap
}

export function convertCurrentStaffWorkSumResponse(response: CurrentStaffWorkSumResponse): CurrentStaffWork {
  return response
}
