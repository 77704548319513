import { type ChartDataSets } from 'chart.js'
import { type TimetableHeader } from 'src/models/timetableHeader'
import {
  getCurrentProductivityRate,
  getCurrentProductivityRateColor,
} from '../../utils/timetableHeaderHelpers'

function getInnerDataset(): ChartDataSets {
  return {
    data: [70, 20, 20, 90],
    borderAlign: 'inner',
    borderWidth: 0,
    backgroundColor: [
      '#f8cbad',
      '#ffe699',
      '#c5e0b4',
      '#b4c7e7',
    ],
    weight: 3,
  }
}

function getProgressRateDataset(timetableHeader: TimetableHeader): ChartDataSets {
  const PRODUCTIVITY_RATE_UPPER_BOUND = 200
  const currentProductivityRate = Math.min(getCurrentProductivityRate(timetableHeader) || 0, PRODUCTIVITY_RATE_UPPER_BOUND)
  const restProductivityRate = PRODUCTIVITY_RATE_UPPER_BOUND - currentProductivityRate

  return {
    backgroundColor: [
      getCurrentProductivityRateColor(timetableHeader),
      '#f2f2f2'
    ],
    borderAlign: 'inner',
    borderWidth: 0,
    data: [currentProductivityRate, restProductivityRate],
    weight: 7,
  }
}

export function getProductivityChartDataset(timetableHeader: TimetableHeader): [ChartDataSets, ChartDataSets] {
  return [getProgressRateDataset(timetableHeader), getInnerDataset()]
}
