





















import { defineComponent, computed } from '@vue/composition-api';
import { orDefault } from 'src/util/filters';
import { formatNumber } from '../../utils/filters';
import { getTotalManHoursColor, getTotalManHoursRank } from 'src/util/progressHeaderHelpers';

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
  },
  props: {
    remainingManHours: {
      type: Number,
      required: true,
    },
    scheduledManHours: {
      type: Number,
      required: true,
    },
    resultManHours: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const speedRankColor = computed(() => getTotalManHoursColor(props.remainingManHours, props.resultManHours));
    const speedRankLabel = computed(() => getTotalManHoursRank(props.remainingManHours, props.resultManHours));

    return {
      speedRankColor,
      speedRankLabel,
    };
  },
});
