







import Vue from 'vue';
import { defineComponent, SetupContext, computed } from '@vue/composition-api';
import { ControlPanel } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/ControlPanel';
import { StaffExperienceMatrix } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/StaffExperienceMatrix';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { useStaffSkillProvider } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/composables/useStaffSkill';
import {
  useStaffExperienceProvider,
  useStaffExperience,
} from 'src/views/Dashboard/Workplace/StaffExperienceOverview/composables/useStaffExperience';

export default defineComponent({
  components: {
    ControlPanel,
    StaffExperienceMatrix,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, '経験 早見表');
    useStaffSkillProvider();
    useStaffExperienceProvider();

    const { staffsWithTimetableMasterSummaries } = useStaffExperience();
    const isDataFetching = computed(() => {
      return staffsWithTimetableMasterSummaries.value.length !== 0;
    });

    return {
      isDataFetching,
    };
  },
});
