import axios, { type AxiosResponse } from 'axios'
import * as utils from 'src/apis/apiUtils'
import { type CurrentStaffWorkSumRequest } from 'src/models/api/currentStaffWorkRequest'
import { type CurrentStaffWorkSumResponse } from 'src/models/api/currentStaffWorkResponse'

export default {
  getSum({ workplace_id, ...params }: CurrentStaffWorkSumRequest): Promise<AxiosResponse<CurrentStaffWorkSumResponse>> {
    return axios.get(utils.getApiUrl(`/workplaces/${workplace_id}/current_staff_works/sum`), {
      params,
    })
  },
}
