import axios, { type AxiosResponse } from 'axios'
import * as utils from 'src/apis/apiUtils'
import {
  type ShiftPatternIndexRequest,
  type ShiftPatternCreateRequest,
  type ShiftPatternUpdateRequest,
  type ShiftPatternBulkUpdateDispOrderRequest,
  type ShiftPatternDeleteRequest,
} from 'src/models/api/workplaceMasters/shiftPatternRequest'
import { type ShiftPatternIndexResponse } from 'src/models/api/workplaceMasters/shiftPatternResponse'

export default {
  index({ workplace_id, budget_group_id, ...params }: ShiftPatternIndexRequest): Promise<AxiosResponse<ShiftPatternIndexResponse>> {
    return axios.get(utils.getApiUrl(`/masters/workplaces/${workplace_id}/budget_groups/${budget_group_id}/shift_patterns`), {
      params
    })
  },
  create({ workplace_id, budget_group_id,  ...data }: ShiftPatternCreateRequest): Promise<AxiosResponse<{}>> {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplace_id}/budget_groups/${budget_group_id}/shift_patterns`), data)
  },
  update({ workplace_id, budget_group_id, id, ...data }: ShiftPatternUpdateRequest): Promise<AxiosResponse<{}>> {
    return axios.put(utils.getApiUrl(`/masters/workplaces/${workplace_id}/budget_groups/${budget_group_id}/shift_patterns/${id}`), data)
  },
  bulkUpdateDispOrder({ workplace_id, budget_group_id, ...data }: ShiftPatternBulkUpdateDispOrderRequest): Promise<AxiosResponse<{}>> {
    return axios.put(utils.getApiUrl(`/masters/workplaces/${workplace_id}/budget_groups/${budget_group_id}/shift_patterns/bulk_update_disp_order`), data)
  },
  delete({ workplace_id, budget_group_id, id }: ShiftPatternDeleteRequest): Promise<AxiosResponse<{}>> {
    return axios.delete(utils.getApiUrl(`/masters/workplaces/${workplace_id}/budget_groups/${budget_group_id}/shift_patterns/${id}`))
  },
}
