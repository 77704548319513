import { toNumberOrNull } from 'src/util/numbers';
import { PlanProgressBlockMasterResponse } from 'src/models/api/planProgressBlockMasterResponse';

export interface PlanProgressBlockMaster {
  id: number
  timetable_master_id: number
  time_period: number
  scheduled_amount_rate: number | null
  scheduled_headcount: number | null
  is_enabled: number
}

export function convertPlanProgressBlockMasterResponse(response: PlanProgressBlockMasterResponse): PlanProgressBlockMaster {
  return {
    ...response,
    scheduled_amount_rate: toNumberOrNull(response.scheduled_amount_rate),
    scheduled_headcount: toNumberOrNull(response.scheduled_headcount),
  }
}
