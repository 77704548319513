import { parseYmdDate } from 'src/util/datetime';
import { RescueAdjustmentMemoOwnerType } from 'src/consts';
import { RescueAdjustmentMemoResponse } from 'src/models/api/rescueAdjustmentMemoResponse';

export interface RescueAdjustmentMemo {
  id: number
  workplace_id: number
  dt: Date
  memo_owner_type: RescueAdjustmentMemoOwnerType
  memo_owner_id: number
  memo: string | null
}

export function convertRescueAdjustmentMemoResponse(response: RescueAdjustmentMemoResponse): RescueAdjustmentMemo {
  return {
    ...response,
    dt: parseYmdDate(response.dt),
  }
}
