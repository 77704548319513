import { computed, defineComponent, reactive, watch, getCurrentInstance } from '@vue/composition-api';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { TimetableMasterWithProgressHeader } from '../types';
import { isYesterday } from 'date-fns';
import { vvHasError, vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import { packToTimeIntegerWithGuard, unpackTimeIntegerToString } from 'src/util/datetime';
import { notifyError1 } from 'src/hooks/notificationHook';
import { addCommaFraction } from 'src/filters/number_filters';
import { useDailySimulation } from '../composables/useDailySimulation';
import { StringOrNumber } from 'src/models/common';
import { useProgressDetailBulkCreateFetchModal } from '../composables/useProgressDetailBulkCreateModal';

interface SaveCandidate {
  progress_header_id: number;
  name: string;
  start_time_hour: string | null;
  start_time_min: string | null;
  end_time_hour: string | null;
  end_time_min: string | null;
  quantity: number | null;
  man_hours: number | null;
  result_quantity: number;
  diff_quantity: StringOrNumber;
  has_activity: Boolean;
  disp_color: string;
}

export type ProgressDetailBulkCreateModalState = {
  saveCandidate: SaveCandidate[];
};

export default defineComponent({
  components: {
    NormalButton,
  },
  name: 'ProgressDetailBulkCreateModal',
  setup() {
    const vueInstance = getCurrentInstance()!.proxy.$root;
    const state: ProgressDetailBulkCreateModalState = reactive({
      saveCandidate: [],
    });

    const {
      hideProgressDetailBulkCreateFetchModal,
      showsProgressDetailBulkCreateFetchModal,
      progressDetailBulkCreate,
      isLoading: isProgressDetailBulkCreateLoading,
    } = useProgressDetailBulkCreateFetchModal();

    const { saveProgressDetailCandidate } = useDailySimulation();

    watch(
      () => showsProgressDetailBulkCreateFetchModal.value,
      (isVisible) => {
        if (isVisible) {
          const saveCandidate = saveProgressDetailCandidate.timetableMasters
            .filter((item) => item.progressHeader)
            .map((timetableMaster) => {
              const preStartTime = timetableMaster.progressHeader?.end_time || timetableMaster.start_time;
              const [preStartHour, preStartMin] = unpackTimeIntegerToString(preStartTime);
              const [preEndHour, preEndMin] = getPreEndTimeToString(timetableMaster);

              return {
                progress_header_id: timetableMaster.progressHeader!.id,
                name: timetableMaster.name,
                start_time_hour: preStartHour,
                start_time_min: preStartMin,
                end_time_hour: preEndHour,
                end_time_min: preEndMin,
                quantity: null,
                man_hours: null,
                result_quantity: timetableMaster.progressHeader!.result_quantity || 0,
                diff_quantity: '-',
                has_activity: timetableMaster.timetable_master_logimeter_activities.length > 0,
                disp_color: timetableMaster.disp_color,
              };
            });

          state.saveCandidate = saveCandidate;
          attachValidate();
        }
      },
    );

    function attachValidate() {
      state.saveCandidate.forEach((item) => {
        vueInstance.$validator.attach({
          name: `${item.progress_header_id}CustomValidate`,
          rules: 'custom_after1',
          getter: () => {
            return item.quantity !== null && String(item.quantity) !== ''
              ? {
                  start_time: packToTimeIntegerWithGuard(item.start_time_hour, item.start_time_min, 0) || 0,
                  end_time: packToTimeIntegerWithGuard(item.end_time_hour, item.end_time_min, 0) || 0,
                  disallow_equal: true,
                }
              : {};
          },
        });
      });
    }

    const hasError = computed(() => {
      return vvHasError(vueInstance);
    });

    const getPreEndTimeToString = (timetableMaster: TimetableMasterWithProgressHeader): string[] => {
      const nowTimeHour = isYesterday(timetableMaster.progressHeader!.dt)
        ? new Date().getHours() + 24
        : new Date().getHours();

      const nowTimeMinStr = calcMinutes(new Date().getMinutes());

      return [String(nowTimeHour), nowTimeMinStr];
    };

    const calcMinutes = (minutes: number): string => {
      // 15分単位で切り下げる
      if (minutes < 15) {
        return '00';
      } else if (minutes < 30) {
        return '15';
      } else if (minutes < 45) {
        return '30';
      } else {
        return '45';
      }
    };

    const onCancel = (): void => {
      clearErrors();
      hideProgressDetailBulkCreateFetchModal();
    };

    async function onSave() {
      if (!(await vvValidate(vueInstance))) {
        return;
      }

      const data = state.saveCandidate
        .filter(
          (item) =>
            item.quantity !== null &&
            String(item.quantity) !== '' &&
            (item.has_activity === true ||
              (item.has_activity === false && item.man_hours !== null && String(item.man_hours) !== '')),
        )
        .map((item) => {
          return {
            progress_header_id: item.progress_header_id,
            start_time: packToTimeIntegerWithGuard(item.start_time_hour, item.start_time_min, 0) || 0,
            end_time: packToTimeIntegerWithGuard(item.end_time_hour, item.end_time_min, 0) || 0,
            quantity: Number(item.diff_quantity),
            man_hours: item.man_hours || 0,
          };
        });
      progressDetailBulkCreate(data);
      clearErrors();
    }

    function getValidationMaps(state: ProgressDetailBulkCreateModalState) {
      const ruleHour = { numeric: true, max: 2, max_value: 47, required: true };
      const ruleMin = { numeric: true, max: 2, max_value: 59, required: true };
      const dynamicRules = state.saveCandidate.reduce((dynamicRules, cur) => {
        const timeValidations =
          cur.quantity !== null && String(cur.quantity) !== ''
            ? {
                [`${cur.progress_header_id}_start_time_hour`]: ruleHour,
                [`${cur.progress_header_id}_start_time_min`]: ruleMin,
                [`${cur.progress_header_id}_end_time_hour`]: ruleHour,
                [`${cur.progress_header_id}_end_time_min`]: ruleMin,
              }
            : {};
        const quantityValidation = {
          [`${cur.progress_header_id}_quantity`]: {
            decimal: 1,
            max_value: 9999999999.9,
            min_value: cur.result_quantity,
          },
        };
        return {
          ...dynamicRules,
          ...timeValidations,
          ...quantityValidation,
        };
      }, {});

      return {
        ...dynamicRules,
        man_hours: { decimal: 1, max_value: 99999.9, min_value: 0 },
      };
    }

    async function onTimeChange() {
      await vvValidate(vueInstance);
    }

    async function onInputFormQuantity(id: number) {
      const candidate = state.saveCandidate.find((item) => item.progress_header_id === id);
      if (candidate === undefined) {
        return;
      }

      await vvValidate(vueInstance, `${id}CustomValidate`);
      const isValid = await vvValidate(vueInstance, `${id}Quantity`);
      const diffQuantity = Number(candidate.quantity || 0) - candidate.result_quantity;
      const quantityIsEmpty = candidate.quantity === null || String(candidate.quantity) === '';
      // ”-（マイナス）”のバリデーションにかかってる場合はマイナスで表記する。
      if (!quantityIsEmpty && diffQuantity < 0) {
        candidate.diff_quantity = diffQuantity;
      } else {
        candidate.diff_quantity = !quantityIsEmpty && isValid ? diffQuantity : '-';
      }
    }

    //　先頭行の終了時刻を他の行にコピーする
    const onCopyEndTime = () => {
      if (state.saveCandidate[0].end_time_hour === '' || state.saveCandidate[0].end_time_min === '') {
        const msg = '先頭行の終了時刻に値が入力されていません。入力後にボタンを押下してください。';
        notifyError1(vueInstance, msg);
        return;
      }
      const firstRowEndTimeHour = state.saveCandidate[0].end_time_hour;
      const firstRowEndTimeMin = state.saveCandidate[0].end_time_min;

      state.saveCandidate.forEach((item) => {
        item.end_time_hour = firstRowEndTimeHour;
        item.end_time_min = firstRowEndTimeMin;
      });
    };

    const displayDiffQuantity = (diffQuantity: StringOrNumber): string => {
      if (!isNumber(diffQuantity)) {
        return '-';
      }

      const sign = diffQuantity > 0 ? '+' : '';
      return sign + addCommaFraction(diffQuantity, 1);
    };

    const isNumber = (value: StringOrNumber): value is number => {
      return typeof value === 'number' && Number.isFinite(value);
    };

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }

    async function clearErrors() {
      await vvReset(vueInstance);
      detachValidate();
    }

    function detachValidate() {
      state.saveCandidate.forEach((item) => {
        vueInstance.$validator.detach(`${item.progress_header_id}CustomValidate`);
      });
    }

    return {
      showsProgressDetailBulkCreateFetchModal,
      isProgressDetailBulkCreateLoading,
      state,
      onCopyEndTime,
      onSave,
      onCancel,
      onTimeChange,
      onInputFormQuantity,
      validations: computed(() => getValidationMaps(state)),
      displayDiffQuantity,
      addCommaFraction,
      getError,
      hasError,
    };
  },
});
