export function toNumber(str: string | number | null): number {
  if (str === '' || str === null) { return 0 }
  const num = Number(str)
  return isNaN(num) ? 0 : num
}

export function toNumberOrNull(str: string | number | null): number | null {
  if (str === '' || str === null) { return null }
  const num = Number(str)
  return isNaN(num) ? null : num
}
