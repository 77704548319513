import { unref } from '@vue/composition-api';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { createInjection } from 'src/util/createInjection';
import { type TimetableMasterSaveItem } from '../types/TimetableMasterSaveItem';
import { useTimetableMasters } from './useTimetableMasters';

type InjectionValue = {
  timetableMaster: ModalWithContext<TimetableMaster>['context'];
  showsUpdateModal: ModalWithContext<TimetableMaster>['showsModal'];
  showUpdateModal: ModalWithContext<TimetableMaster>['showModal'];
  hideUpdateModal: ModalWithContext<TimetableMaster>['hideModal'];
  updateTimetableMaster: (timetableMasterSaveItem: TimetableMasterSaveItem) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useUpdateTimetableMaster');

async function requestToUpdateTimetableMaster({
  budgetGroup,
  timetableMaster,
  timetableMasterSaveItem,
}: {
  budgetGroup: BudgetGroup;
  timetableMaster: TimetableMaster;
  timetableMasterSaveItem: TimetableMasterSaveItem;
}): Promise<void> {
  const { timetable_label, ...input } = timetableMasterSaveItem;
  await timetableMasterApi.update({
    workplaceId: budgetGroup.workplace_id,
    data: {
      id: timetableMaster.id,
      timetable_label_id: timetable_label.id,
      ...input,
    },
  });
}

export function useUpdateTimetableMasterProvider(): void {
  const { notifySuccess } = useNotification();
  const errorBoundary = useErrorBoundary();
  const {
    context: timetableMaster,
    showsModal: showsUpdateModal,
    showModal: showUpdateModal,
    hideModal: hideUpdateModal,
  } = useModalWithContext<TimetableMaster>();
  const { fetchTimetableMasters, budgetGroup } = useTimetableMasters();
  const updateTimetableMaster = errorBoundary.wrap(
    async (timetableMasterSaveItem: TimetableMasterSaveItem) => {
      const unwrappedBudgetGroup = unref(budgetGroup);
      const unwrappedTimetableMaster = unref(timetableMaster);
      if (unwrappedBudgetGroup !== null && unwrappedTimetableMaster !== null) {
        await requestToUpdateTimetableMaster({
          budgetGroup: unwrappedBudgetGroup,
          timetableMaster: unwrappedTimetableMaster,
          timetableMasterSaveItem,
        });
        hideUpdateModal();
        notifySuccess('工程マスタを編集しました');
        await fetchTimetableMasters();
      }
    },
    {
      fallbackMessage: '工程マスタの編集に失敗しました。',
      shouldContactUs: true,
    },
  );

  provide({
    timetableMaster,
    showsUpdateModal,
    showUpdateModal,
    hideUpdateModal,
    updateTimetableMaster,
  });
}

export function useUpdateTimetableMaster(): InjectionValue {
  return inject();
}
