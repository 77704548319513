
import {
  defineComponent,
  reactive,
  SetupContext,
  onMounted,
  getCurrentInstance,
  computed, ComputedRef,
} from '@vue/composition-api';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import workplaceExtensionApi from 'src/apis/workplace_masters/workplace_extension';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { WorkplaceExtension } from 'src/models/workplaceExtension';
import { vvHasError, vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';

import Axios from 'axios';
import { UpdateOptParams } from 'src/models/api/workplaceExtensionRequest';
const displayPageName = 'センター設定 ロジボード向け';

type SaveCandidate = {
  week_start_day: number,
  timetable_start_hour: number,
  custom_skill_name1: string | null,
  custom_skill_name2: string | null,
  custom_skill_name3: string | null,
}

interface State {
  dayOfWeek: {key: number, name: string}[]
  workplaceExtension: WorkplaceExtension | {},
  saveCandidate: SaveCandidate,
  showSaveModal: boolean,
  validations: Record<string, Object>,
}

const initSaveCandidate: SaveCandidate = {
  week_start_day: 0,
  timetable_start_hour: 8,
  custom_skill_name1: '',
  custom_skill_name2: '',
  custom_skill_name3: '',
}

function getValidationMap(): Record<string, Object> {
  const ruleStr = { max: 10 }
  const ruleHour = { numeric: true, max: 2, max_value: 48 }
  const ruleHourReq = { ...ruleHour, required: true }
  return {
    timetable_start_hour: ruleHourReq,
    custom_skill_name1: ruleStr,
    custom_skill_name2: ruleStr,
    custom_skill_name3: ruleStr,
  }
}

function setupState(): State {
  return reactive({
    dayOfWeek: [],
    workplaceExtension: {},
    saveCandidate: initSaveCandidate,
    showSaveModal: false,
    validations: getValidationMap(),
  });
}

export default defineComponent({
  name: 'workplace_extension',
  setup(_props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    const state = setupState();

    const userId: ComputedRef<number> = wrappedMapGetters(
      vueInstance.$store,
      'user',
      ['id']
    ).id;
    const workplaceId = computed(() => {
      return vueInstance.$route.params.workplaceId;
    });

    const hasError = computed(() => {
      return vvHasError(vueInstance);
    });

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }
    function clearErrors() {
      vvReset(vueInstance);
    }

    // workplaceExtension取得
    async function getWorkplaceExtension(): Promise<void> {
      const workplaceExtension = await workplaceExtensionApi.show(workplaceId.value);
      state.workplaceExtension = workplaceExtension || state.workplaceExtension;
    }

    // 編集モーダルopen時処理
    function openSaveModal(): void {
      state.saveCandidate = convertToSaveCandidate(state.workplaceExtension as WorkplaceExtension)
      state.showSaveModal = true;
    }

    // 編集モーダルclose時処理
    function closeSaveModal(): void {
      state.saveCandidate = initSaveCandidate;
      state.showSaveModal = false;
      clearErrors();
    }

    // 保存時処理
    async function _saveItem(): Promise<void> {
      if (!state.saveCandidate) { return; }
      const isValid = await vvValidate(vueInstance);
      if (!isValid) { return }

      async function update(): Promise<void> {
        await workplaceExtensionApi.update(workplaceId.value, state.saveCandidate as UpdateOptParams);
      }

      try {
        await update();
        await getWorkplaceExtension();
        closeSaveModal();
        notifySuccess1(vueInstance, `${displayPageName}を更新しました`);
      } catch (err) {
        if (Axios.isAxiosError(err)) {
          const errStatus = err.response!.status;
          if (errStatus >= 400 && errStatus < 500) {
            const errRes = err.response!.data || {};
            const msg = Array.isArray(errRes) ? errRes[0] : Object.values(errRes)[0];
            notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
          } else {
            const msg = `${displayPageName}の更新に失敗しました。` +
              '管理者に連絡してください。' +
              `(ERR: ${displayPageName} ERR00002, user_id:${userId.value})`;
            notifyError1(vueInstance, msg, { err });
          }
        }
      }
    }

    // マウント時処理
    onMounted(async() => {
      setPageName(vueInstance, displayPageName);
      await getWorkplaceExtension();
      const { masters } = await ensureUserAndMasters(vueInstance)
      state.dayOfWeek = masters.lovs.day_of_week.vals.map(ent => ({ key: parseInt(ent.key), name: ent.name }))
    })

    const gatedFuncGenerator = getGatedFuncGenerator();
    return {
      displayPageName,
      state,
      // computed
      workplaceId,
      hasError,
      // methods
      getError,
      openSaveModal,
      closeSaveModal,
      saveItem: gatedFuncGenerator.makeAsyncFuncGated(_saveItem),
    }
  }
});

const convertToSaveCandidate = (workplaceExtension: WorkplaceExtension): SaveCandidate => {
  return {
    ...workplaceExtension
  }
}
