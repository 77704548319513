import Vue from 'vue'
import VueRouter from 'vue-router'
import VueRouterPrefetch from 'vue-router-prefetch'
import VueNotify from 'vue-notifyjs'
import VeeValidate from 'vee-validate'
import VueMoment from 'vue-moment'
import moment from 'src/util/moment-ja'
import lang from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import store from './store'
import filters from './filters'
import { custom_after1, custom_after2 } from './veeValidations'
import App from './App.vue'
import VueCompositionApi from '@vue/composition-api'
import * as Sentry from '@sentry/vue'

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import initProgress from './progressbar'
import VTooltip from 'v-tooltip'

// router setup
import routes from './routes'

// css imports
import './assets/sass/logiboard-pc.scss'

// plugin setup
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueRouterPrefetch)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents) // register global components
Vue.use(VueNotify)
Vue.use(VeeValidate)
Vue.use(VueMoment, { moment })
locale.use(lang)
Vue.use(VueCompositionApi)
Vue.use(VTooltip)

// register filters
Object.keys(filters).forEach(k => {
  Vue.filter(k, filters[k as keyof typeof filters])
})

// configure router
const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})
initProgress(router)

if (process.env.RAILS_ENV && ['production', 'staging', 'demo'].includes(process.env.RAILS_ENV)) {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN_VUE,
    environment: process.env.RAILS_ENV,
    tracesSampleRate: 1.0,
  })
}

// add custom validation rules
VeeValidate.Validator.extend('custom_after1', custom_after1)
VeeValidate.Validator.extend('custom_after2', custom_after2)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
