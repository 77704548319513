import PrintLayout from 'src/views/Dashboard/Layout/PrintLayout.vue';
import { withWorkplaceRoleGuard } from 'src/util/withWorkplaceRoleGuard';

export default [
  {
    path: 'workplace',
    component: withWorkplaceRoleGuard(PrintLayout),
    children: [
      {
        path: ':workplaceId/regular-shift',
        name: 'PrintWorkplaceRegularShift',
        component: require('src/views/Dashboard/Workplace/RegularShift/components/ShiftTableForPrint').default,
      },
    ],
  },
];
