import { timeDifferenceInSeconds, secondsToTimeInteger } from 'src/util/datetime'
import { type StrictTimeRange } from './StrictTimeRange'
import { isValidTimeRange } from './isValidTimeRange'

function normalizeTimeRanges(strictTimeRanges: StrictTimeRange[], strictTimeRange: StrictTimeRange): StrictTimeRange[] {
  const lastStrictTimeRange = strictTimeRanges[strictTimeRanges.length - 1]
  if (lastStrictTimeRange === undefined) {
    return [strictTimeRange]
  }

  if (lastStrictTimeRange.endTime <= strictTimeRange.startTime) {
    return [...strictTimeRanges, strictTimeRange]
  }

  if (strictTimeRange.startTime < lastStrictTimeRange.endTime && lastStrictTimeRange.endTime < strictTimeRange.endTime) {
    return [...strictTimeRanges, { ...strictTimeRange, startTime: lastStrictTimeRange.endTime }]
  }

  return strictTimeRanges
}

export function getTotalTime(strictTimeRanges: StrictTimeRange[]): number {
  const totalSeconds = strictTimeRanges
    .filter(isValidTimeRange)
    .sort((a, b) => a.startTime - b.startTime)
    .reduce(normalizeTimeRanges, [] as StrictTimeRange[])
    .map((strictTimeRange) => timeDifferenceInSeconds(strictTimeRange.startTime, strictTimeRange.endTime))
    .reduce((totalSeconds, seconds) => totalSeconds + seconds, 0)

  return secondsToTimeInteger(totalSeconds)
}
