import { ComputedRef, InjectionKey, Ref, reactive, computed, toRefs } from '@vue/composition-api';
import { LovEntry } from 'src/models/master';
import { TimeRange, TotalTime, BulkUpdateIndividualTargetState } from 'src/models/regularShift';
import { convertNumberToTime } from 'src/views/Dashboard/Workplace/RegularShift/libs/formatter';
import { isExist } from 'src/util/isExist';
import { generateEmptyTimeRange, generateEmptyTime } from 'src/views/Dashboard/Workplace/RegularShift/utils';

type BreakTime = {
  break1: TimeRange;
  break2: TimeRange;
};

type RateState = {
  overtimeExtraPercent: number | undefined;
  holidayExtraPercent: number | undefined;
  midnightExtraPercent: number | undefined;
  overSixtyHoursExtraPercent: number | undefined;
  standardWorkingHoursInt: number | undefined;
};

type State = {
  preShiftId: Ref<number>;
  preShiftAttendanceType: Ref<LovEntry | null | undefined>;
  preShiftWorkHours: Ref<TimeRange>;
  preShiftBreakTimes: Ref<BreakTime>;
  shiftId: Ref<number>;
  scheduledShiftAttendanceType: Ref<LovEntry | null | undefined>;
  scheduledShiftWorkHours: Ref<TimeRange>;
  scheduledShiftBreakTimes: Ref<BreakTime>;
  possibleOverTime: Ref<{ hour: string | null; min: string | null }>;
  actualShiftAttendanceType: Ref<LovEntry | null | undefined>;
  shiftAttendanceSupplement: Ref<LovEntry | null | undefined>;
  actualShiftWorkHours: Ref<TimeRange>;
  actualBreakTime: Ref<TotalTime>;
  hourlyCost: Ref<number | undefined>;
  isChangeAtNight: Ref<boolean | undefined>;
  isBeginner: Ref<boolean | undefined>;
  memo: Ref<string | null>;
  overtimeExtraRate: ComputedRef<string | undefined>;
  standardWorkingHours: ComputedRef<number | undefined>;
  holidayExtraRate: ComputedRef<string | undefined>;
  midnightExtraRate: ComputedRef<string | undefined>;
  overSixtyHoursExtraRate: ComputedRef<string | undefined>;
  midnightExtraTargetTime: Ref<TimeRange>;
};

type ShiftBulkEditHook = State & {
  overtimeExtraPercent: Ref<number | undefined>;
  holidayExtraPercent: Ref<number | undefined>;
  midnightExtraPercent: Ref<number | undefined>;
  overSixtyHoursExtraPercent: Ref<number | undefined>;
  standardWorkingHoursInt: Ref<number | undefined>;
  preShiftTotalBreakTime: ComputedRef<TotalTime>;
  scheduledShiftTotalBreakTime: ComputedRef<TotalTime>;
  resetState: () => void;
};

export const useShiftBulkEdit = () => {
  const state = reactive<BulkUpdateIndividualTargetState>({
    preShiftId: 0,
    preShiftAttendanceType: undefined,
    preShiftWorkHours: generateEmptyTimeRange(),
    preShiftBreakTimes: {
      break1: generateEmptyTimeRange(),
      break2: generateEmptyTimeRange(),
    },
    shiftId: 0,
    scheduledShiftAttendanceType: undefined,
    scheduledShiftWorkHours: generateEmptyTimeRange(),
    scheduledShiftBreakTimes: {
      break1: generateEmptyTimeRange(),
      break2: generateEmptyTimeRange(),
    },
    possibleOverTime: generateEmptyTime(),
    actualShiftAttendanceType: undefined,
    shiftAttendanceSupplement: undefined,
    actualShiftWorkHours: generateEmptyTimeRange(),
    actualBreakTime: generateEmptyTime(),
    hourlyCost: undefined,
    isChangeAtNight: undefined,
    isBeginner: undefined,
    memo: null,
    overtimeExtraRate: computed(() =>
      isExist(rateState.overtimeExtraPercent) ? (rateState.overtimeExtraPercent / 100).toString() : undefined,
    ),
    holidayExtraRate: computed(() =>
      isExist(rateState.holidayExtraPercent) ? (rateState.holidayExtraPercent / 100).toString() : undefined,
    ),
    midnightExtraRate: computed(() =>
      isExist(rateState.midnightExtraPercent) ? (rateState.midnightExtraPercent / 100).toString() : undefined,
    ),
    overSixtyHoursExtraRate: computed(() =>
      isExist(rateState.overSixtyHoursExtraPercent)
        ? (rateState.overSixtyHoursExtraPercent / 100).toString()
        : undefined,
    ),
    standardWorkingHours: computed(() =>
      isExist(rateState.standardWorkingHoursInt) ? convertNumberToTime(rateState.standardWorkingHoursInt) : undefined,
    ),
    midnightExtraTargetTime: generateEmptyTimeRange(),
  });

  const preShiftTotalBreakTime = computed(() =>
    _calcTotalBreak1AndBreak2(state.preShiftBreakTimes.break1, state.preShiftBreakTimes.break2),
  );
  const scheduledShiftTotalBreakTime = computed(() =>
    _calcTotalBreak1AndBreak2(state.scheduledShiftBreakTimes.break1, state.scheduledShiftBreakTimes.break2),
  );

  const rateState = reactive<RateState>({
    overtimeExtraPercent: undefined,
    holidayExtraPercent: undefined,
    midnightExtraPercent: undefined,
    overSixtyHoursExtraPercent: undefined,
    standardWorkingHoursInt: undefined,
  });

  // state及びrateStateのリセット
  const resetState = (): void => {
    state.preShiftId = 0;
    state.preShiftAttendanceType = undefined;
    state.preShiftWorkHours = generateEmptyTimeRange();
    state.preShiftBreakTimes.break1 = generateEmptyTimeRange();
    state.preShiftBreakTimes.break2 = generateEmptyTimeRange();
    state.shiftId = 0;
    state.scheduledShiftAttendanceType = undefined;
    state.scheduledShiftWorkHours = generateEmptyTimeRange();
    state.scheduledShiftBreakTimes.break1 = generateEmptyTimeRange();
    state.scheduledShiftBreakTimes.break2 = generateEmptyTimeRange();
    state.possibleOverTime = generateEmptyTime();
    state.actualShiftAttendanceType = undefined;
    state.shiftAttendanceSupplement = undefined;
    state.actualShiftWorkHours = generateEmptyTimeRange();
    state.actualBreakTime = generateEmptyTime();
    state.hourlyCost = undefined;
    state.isChangeAtNight = undefined;
    state.isBeginner = undefined;
    state.memo = null;
    state.midnightExtraTargetTime = generateEmptyTimeRange();

    rateState.overtimeExtraPercent = undefined;
    rateState.holidayExtraPercent = undefined;
    rateState.midnightExtraPercent = undefined;
    rateState.overSixtyHoursExtraPercent = undefined;
    rateState.standardWorkingHoursInt = undefined;
  };

  const _calcTotalBreak1AndBreak2 = (break1: TimeRange, break2: TimeRange): TotalTime => {
    const break1TotalTime = _calcTotalBreakTime(break1);
    const break2TotalTime = _calcTotalBreakTime(break2);

    return _mergeTotalBreakTime(break1TotalTime, break2TotalTime);
  };

  const _calcTotalBreakTime = ({ startHour, startMin, endHour, endMin }: TimeRange): TotalTime => {
    const totalBreakTime: TotalTime = generateEmptyTime();
    if (!startHour || !startMin || !endHour || !endMin) {
      return totalBreakTime;
    }

    const startHourNum = Number(startHour);
    const startMinNum = Number(startMin);
    const endHourNum = Number(endHour);
    const endMinNum = Number(endMin);

    const formated24HourEndHour = endHourNum < startHourNum ? endHourNum + 24 : endHourNum;
    const totalBreakTimeHour = formated24HourEndHour - startHourNum;
    const totalBreakTimeMin = endMinNum - startMinNum;

    totalBreakTime.min = totalBreakTimeMin < 0 ? (totalBreakTimeMin + 60).toString() : totalBreakTimeMin.toString();
    totalBreakTime.hour = totalBreakTimeMin < 0 ? (totalBreakTimeHour - 1).toString() : totalBreakTimeHour.toString();

    return totalBreakTime;
  };

  // 休憩時間が複数設定されている場合その合計休憩時間を取得
  const _mergeTotalBreakTime = (totalBreakTime1: TotalTime, totalBreakTime2: TotalTime): TotalTime => {
    if (!totalBreakTime2.hour || !totalBreakTime2.min) {
      return {
        hour: totalBreakTime1.hour,
        min: totalBreakTime1.min ? _formatMinTwoDigits(Number(totalBreakTime1.min)) : null,
      };
    }

    const totalBreakTime = {
      hour: (Number(totalBreakTime1.hour) + Number(totalBreakTime2.hour)).toString(),
      min: _formatMinTwoDigits(Number(totalBreakTime1.min) + Number(totalBreakTime2.min)),
    };

    if (Number(totalBreakTime.min) < 60) {
      return totalBreakTime;
    }

    totalBreakTime.hour = (Number(totalBreakTime.hour) + 1).toString();
    totalBreakTime.min = _formatMinTwoDigits(Number(totalBreakTime.min) - 60);

    return totalBreakTime;
  };

  const _formatMinTwoDigits = (min: number): string => `0${min}`.slice(-2);

  return {
    ...toRefs(state),
    ...toRefs(rateState),
    resetState,
    preShiftTotalBreakTime,
    scheduledShiftTotalBreakTime,
  };
};

export const ShiftBulkEditStateKey: InjectionKey<ShiftBulkEditHook> = Symbol('ShiftBulkEditState');
