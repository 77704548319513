




















import { defineComponent, type PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    align: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'center',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
    onClick: {
      type: Function as PropType<(() => unknown) | null>,
      default: null,
    },
  },
})
