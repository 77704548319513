import {
  ConversionProgramResponse
} from 'src/models/api/conversionProgramResponse'

export type ConversionProgram = ConversionProgramResponse

export function convertConversionProgramResponse(
  response: ConversionProgramResponse
): ConversionProgram {
  return { ...response }
}
