









import { defineComponent, ref } from '@vue/composition-api';
import { format } from 'date-fns';
import CsvDownloadNeutralButton from 'src/components/UIComponents/Buttons/CsvDownloadButton/CsvDownloadNeutralButton.vue';
import { CsvRow } from 'src/components/UIComponents/Buttons/CsvDownloadButton/types';
import { useMonthlyPlan } from '../composables/useMonthlyPlan';
import { buildCsvData } from './buildCsvData';

export default defineComponent({
  components: {
    CsvDownloadNeutralButton,
  },
  setup() {
    const { state } = useMonthlyPlan();
    const fileName = ref('');
    const data = ref<CsvRow[]>([]);
    const beforeDownload = () => {
      fileName.value = `monthly_board_${format(new Date(), 'yyyyMMddHHmmss')}.csv`;
      data.value = buildCsvData(state);
    };

    return {
      state,
      fileName,
      data,
      beforeDownload,
    };
  },
});
