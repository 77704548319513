import { ComputedRef, Ref, computed, ref } from '@vue/composition-api';
import { createInjection } from 'src/util/createInjection';
import staffExperienceApi from 'src/apis/staffExperienceOverview';
import { StaffExperienceOverviewParams } from 'src/models/api/staffExperienceOverviewRequest';
import { StaffWithTimetableMasterSummaries, TotalSummary } from 'src/models/staffExperienceOverview';
import { generateTotalSummary } from '../libs/totalSummaryHelper';
import { sortStaffsWithTimetableMasterSummaries } from '../libs/staffExperienceSorter';

type InjectionValue = {
  staffsWithTimetableMasterSummaries: Ref<StaffWithTimetableMasterSummaries[]>;
  totalSummary: ComputedRef<TotalSummary>;
  fetchStaffExperience: (params: StaffExperienceOverviewParams) => Promise<void>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffExperience');

export const useStaffExperienceProvider = () => {
  const staffsWithTimetableMasterSummaries = ref<StaffWithTimetableMasterSummaries[]>([]);

  const totalSummary = computed<TotalSummary>(() => {
    return generateTotalSummary(staffsWithTimetableMasterSummaries.value);
  });

  const fetchStaffExperience = async (params: StaffExperienceOverviewParams): Promise<void> => {
    const staffExperienceResponse = await staffExperienceApi.index(params);
    staffsWithTimetableMasterSummaries.value = sortStaffsWithTimetableMasterSummaries(
      staffExperienceResponse.staffs_with_timetable_master_summaries,
    );
  };

  provide({
    staffsWithTimetableMasterSummaries,
    totalSummary,
    fetchStaffExperience,
  });
};

export const useStaffExperience = (): InjectionValue => {
  return inject();
};
