




















import { defineComponent } from '@vue/composition-api';
import keyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import forkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import customFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import customFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import customFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import { CustomFlagItem } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/CustomFlagItem';
import { useStaffSkill } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/composables/useStaffSkill';
import { SKILL_MAP } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/consts';

export default defineComponent({
  components: {
    keyPlayerIcon,
    forkliftIcon,
    customFlag1Icon,
    customFlag2Icon,
    customFlag3Icon,
    CustomFlagItem,
  },
  setup() {
    const { skillOptions } = useStaffSkill();

    return {
      skillOptions,
      SKILL_MAP,
    };
  },
});
