import { computed, type Ref } from '@vue/composition-api'
import progressDetailApi from 'src/apis/progressDetail'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext'
import { type ProgressHeader, type ProgressDetail } from 'src/models/progressHeader'
import { createInjection } from 'src/util/createInjection'

type ModalContext = {
  progressHeader: ProgressHeader
  progressDetail: ProgressDetail
  fetchProgressHeader: () => Promise<unknown>
}

type InjectionValue = {
  progressHeader: Ref<ProgressHeader | null>
  showsProgressDetailDeleteConfirmationModal: ModalWithContext<ModalContext>['showsModal']
  showProgressDetailDeleteConfirmationModal: ModalWithContext<ModalContext>['showModal']
  hideProgressDetailDeleteConfirmationModal: ModalWithContext<ModalContext>['hideModal']
  deleteProgressDetail: () => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useDeleteProgressDetail')

async function requestToDeleteProgressDetail({
  progressHeader,
  progressDetail,
}: {
  progressHeader: ProgressHeader
  progressDetail: ProgressDetail
}): Promise<void> {
  await progressDetailApi.delete({
    workplace_id: progressHeader.workplace_id,
    progress_header_id: progressDetail.progress_header_id,
    id: progressDetail.id,
  })
}

export function useDeleteProgressDetailProvider(): void {
  const {
    context,
    showsModal: showsProgressDetailDeleteConfirmationModal,
    showModal: showProgressDetailDeleteConfirmationModal,
    hideModal: hideProgressDetailDeleteConfirmationModal,
  } = useModalWithContext<ModalContext>()
  const { notifySuccess } = useNotification()
  const errorBoundary = useErrorBoundary()
  const deleteProgressDetail = errorBoundary.wrap(
    async() => {
      const progressHeader = context.value?.progressHeader ?? null
      const progressDetail = context.value?.progressDetail ?? null
      if (progressHeader !== null && progressDetail !== null) {
        await requestToDeleteProgressDetail({
          progressHeader,
          progressDetail,
        })
        await context.value?.fetchProgressHeader()
        hideProgressDetailDeleteConfirmationModal()
        notifySuccess('進捗履歴を削除しました')
      }
    },
    {
      fallbackMessage: '進捗履歴の削除に失敗しました。',
      shouldContactUs: true,
    },
  )

  provide({
    progressHeader: computed(() => context.value?.progressHeader ?? null),
    showsProgressDetailDeleteConfirmationModal,
    showProgressDetailDeleteConfirmationModal,
    hideProgressDetailDeleteConfirmationModal,
    deleteProgressDetail,
  })
}

export function useDeleteProgressDetail(): InjectionValue {
  return inject()
}
