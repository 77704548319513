<template>
  <div :class="{'list-switch-panel': hasDropDown() && hasDisplayDisabledModel()}">
    <el-select
      v-if="hasDropDown()"
      class="select-primary"
      v-model="params.dropDownModel">
      <el-option class="select-primary"
        v-for="cand in cands"
        :label="cand.name"
        :value="cand.id"
        :key="cand.id">
      </el-option>
    </el-select>
    <div v-if="hasDisplayDisabledModel()" :class="{'ta-right': !hasDropDown() && hasDisplayDisabledModel()}">
      <span class="text-primary mr8">無効のものも表示する</span>
      <p-switch type="primary" @input="onDisplayDisabledChange"
        v-model="params.displayDisabledModel"
        on-text="ON" off-text="OFF">
      </p-switch>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paramDisplayDisabledModel: {
      type: Boolean,
      default: false,
    },
    paramDropDownModel: {
      type: [Number, Object],
      default: null,
    },
    cands: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    params: {
      handler(value) {
        this.$emit('changed', value)
      },
      deep: true,
    },
  },
  data() {
    return {
      params: {
        dropDownModel: null,
        displayDisabledModel: false,
      },
    }
  },
  mounted() {
    this.params.dropDownModel = this.paramDropDownModel
    this.params.displayDisabledModel = this.paramDisplayDisabledModel
  },
  methods: {
    hasDisplayDisabledModel() {
      return true
    },
    hasDropDown() {
      return this.cands.length !== 0
    },
    onDisplayDisabledChange() {
      // 利用する側で :param-display-disabled-model.sync="someProp" などと
      // 書けるように追加しました.
      this.$emit('update:param-display-disabled-model', this.params.displayDisabledModel)
    },
  }
}
</script>

<style lang="scss" scoped>
.list-switch-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}
</style>
