import axios, { AxiosResponse } from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'

import {
  IndexOptParams,
  UpdateOptParams,
  UpdateLogimeterActivitiesOptParams,
  PlanProgressBlocksUpdateOptParams,
} from 'src/models/api/planProgressRequest'
import {
  TimetableHeaderResponse,
  TimetableHeaderWithStaffsResponse
} from 'src/models/api/timetableHeaderResponse'

export default {
  index(workplaceId: StringOrNumber, params: IndexOptParams): Promise<AxiosResponse<TimetableHeaderResponse[]>> {
    return axios.get(utils.getApiUrl(`/workplaces/${workplaceId}/timetables`), {
      params: params || {}
    })
  },
  update(workplaceId: StringOrNumber, data: UpdateOptParams): Promise<AxiosResponse<TimetableHeaderResponse>> {
    return axios.put(utils.getApiUrl(`/workplaces/${workplaceId}/timetables/${data.id}`), data)
  },
  update_logimeter_activities(workplaceId: StringOrNumber, data: UpdateLogimeterActivitiesOptParams) {
    return axios.put(utils.getApiUrl(`/workplaces/${workplaceId}/timetables/update_logimeter_activities`), data)
  },
  plan_progress_blocks_update(workplaceId: StringOrNumber, data: PlanProgressBlocksUpdateOptParams): Promise<AxiosResponse<TimetableHeaderResponse>> {
    return axios.put(utils.getApiUrl(`/workplaces/${workplaceId}/timetables/${data.id}/plan_progress_blocks_update`), data)
  },
  indexWithStaff(workplaceId: StringOrNumber, params: IndexOptParams): Promise<AxiosResponse<TimetableHeaderWithStaffsResponse[]>> {
    return axios.get(utils.getApiUrl(`/workplaces/${workplaceId}/timetables/index_with_staff`), {
      params: params || {}
    })
  },
  create(workplaceId: StringOrNumber, params: IndexOptParams): Promise<AxiosResponse<TimetableHeaderResponse[]>> {
    return axios.post(utils.getApiUrl(`/workplaces/${workplaceId}/timetables`), params)
  },
}
