import { type Ref } from '@vue/composition-api';
import macroOperationApi from 'src/apis/macroOperation';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { type BudgetGroup } from 'src/models/budgetGroup';

type Params = {
  budgetGroup: Ref<BudgetGroup | null>;
};

type UploadMonthlyPlanCsv = {
  uploadMonthlyPlanCsv: (file: File) => Promise<boolean>;
};

export function useUploadMonthlyPlanCsv({ budgetGroup }: Params): UploadMonthlyPlanCsv {
  const errorBoundary = useErrorBoundary();
  const { notifySuccess } = useNotification();
  const uploadMonthlyPlanCsv = errorBoundary.wrap(async (csv: File) => {
    if (budgetGroup.value === null) {
      return;
    }

    await macroOperationApi.importCsv({
      workplace_id: budgetGroup.value.workplace_id,
      budget_group_id: budgetGroup.value.id,
      csv,
    });
    notifySuccess('CSVから数量を取込を開始しました');
  });

  return {
    uploadMonthlyPlanCsv,
  };
}
