import { createInjection } from 'src/util/createInjection'
import { useUrlParameters } from 'src/composables/useUrlParameters'

type UrlParameters = {
  budget_group_id: number
  show_mode: number
  headcount_mode: number
}

type InjectionValue = {
  getUrlParameters: () => Partial<UrlParameters>
  setUrlParameters: (params: UrlParameters) => Promise<void>
}

const { provide, inject } = createInjection<InjectionValue>('useMonthlyPlanUrlParameters')

export function useMonthlyPlanUrlParametersProvider(): void {
  provide(useUrlParameters<UrlParameters>())
}

export function useMonthlyPlanUrlParameters(): InjectionValue {
  return inject()
}
