import { parseYmdDate } from 'src/util/datetime';
import { CollectiveStaffShiftResponse } from 'src/models/api/collectiveStaffShiftResponse';
import { CollectiveStaff, convertCollectiveStaffResponse } from 'src/models/collectiveStaff';

export interface CollectiveStaffShift {
  id: number
  collective_staff_id: number
  collective_staff?: CollectiveStaff
  dt: Date,
  headcount: number | null
}

export function convertCollectiveStaffShiftResponse(response: CollectiveStaffShiftResponse): CollectiveStaffShift {
  const { collective_staff, ...rest } = response
  return {
    ...rest,
    ...(collective_staff ? { collective_staff: convertCollectiveStaffResponse(collective_staff) } : null),
    dt: parseYmdDate(response.dt),
  }
}
