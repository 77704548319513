















import { defineComponent } from '@vue/composition-api';
import { EditableTime } from 'src/components/UIComponents/Inputs/EditableTime';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { useUpdateActualCompletionTime } from '../../composables/useUpdateActualCompletionTime';
import { CardPanel } from '../CardPanel';

export default defineComponent({
  components: {
    CardPanel,
    EditableTime,
  },
  setup() {
    const { progressHeader } = useProgressHeader();
    const { updateActualCompletionTime } = useUpdateActualCompletionTime();

    return {
      progressHeader,
      updateActualCompletionTime,
    };
  },
});
