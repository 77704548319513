


































import { defineComponent, type PropType } from '@vue/composition-api'
import { CheckboxWithDescription } from '../CheckboxWithDescription'

type Value = {
  useInPrepPhase: boolean,
  useInDoPhase: boolean,
  useInReviewPhase: boolean,
}

export default defineComponent({
  components: {
    CheckboxWithDescription,
  },
  props: {
    value: {
      type: Object as PropType<Value>,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const handleInput = (value: Partial<Value>) => {
      context.emit('input', {
        ...props.value,
        ...value,
      })
    }

    return {
      handleInput,
    }
  },
})
