import axios, { type AxiosResponse } from 'axios';
import * as utils from 'src/apis/apiUtils';
import {
  type ProgressDetailCreateParams,
  type ProgressDetailUpdateParams,
  type ProgressDetailDeleteParams,
  type SubBudgetGroupBulkCreateParams,
  type SubBudgetGroupBulkUpdateManHoursParams,
} from 'src/models/api/progressDetailRequest';

export default {
  create({ workplace_id, progress_header_id, ...data }: ProgressDetailCreateParams): Promise<AxiosResponse<{}>> {
    return axios.post(
      utils.getApiUrl(`/workplaces/${workplace_id}/progress_headers/${progress_header_id}/progress_details`),
      data,
    );
  },
  update({ workplace_id, progress_header_id, id, ...data }: ProgressDetailUpdateParams): Promise<AxiosResponse<{}>> {
    return axios.put(
      utils.getApiUrl(`/workplaces/${workplace_id}/progress_headers/${progress_header_id}/progress_details/${id}`),
      data,
    );
  },
  delete({ workplace_id, progress_header_id, id }: ProgressDetailDeleteParams): Promise<AxiosResponse<{}>> {
    return axios.delete(
      utils.getApiUrl(`/workplaces/${workplace_id}/progress_headers/${progress_header_id}/progress_details/${id}`),
    );
  },
  subBudgetGroupBulkUpdateManHours({
    workplace_id,
    budget_group_id,
  }: SubBudgetGroupBulkUpdateManHoursParams): Promise<AxiosResponse<{}>> {
    return axios.post(
      utils.getApiUrl(
        `/workplaces/${workplace_id}/budget_groups/${budget_group_id}/progress_details/sub_budget_group_bulk_update_man_hours`,
      ),
    );
  },
  subBudgetGroupBulkCreate({
    workplace_id,
    budget_group_id,
    items,
  }: SubBudgetGroupBulkCreateParams): Promise<AxiosResponse<{}>> {
    return axios.post(
      utils.getApiUrl(
        `/workplaces/${workplace_id}/budget_groups/${budget_group_id}/progress_details/sub_budget_group_bulk_create`,
      ),
      { items },
    );
  },
};
