import { reactive, toRefs, InjectionKey, Ref } from '@vue/composition-api';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import { StaffAgency } from 'src/models/staffAgency';
import { SHIFT_PHASE } from 'src/consts';

const { PROVISIONAL } = SHIFT_PHASE;

export type UpDown = {
  value: boolean;
  name: string;
};

export type TimeRange = {
  hour: string | null;
  min: string | null;
};

type ShiftType = {
  value: number;
  name: string;
};

export type ConfigureAttendanceType = {
  value: number;
  name: string;
};

export type State = {
  staffName: string | null;
  macroOperationMaster: MacroOperationMaster | null;
  staffAgency: StaffAgency | null;
  overTimeAt7Days: number | null;
  isMoreOverTimeAt7Days: UpDown;
  overTimeAtMonth: number | null;
  isMoreOverTimeAtMonth: UpDown;
  wageAtMonth: number | null;
  isMoreWageAtMonth: UpDown;
  targetDate: Date | null;
  shiftType: ShiftType;
  attendanceType: ConfigureAttendanceType | null;
  startTime: TimeRange;
  endTime: TimeRange;
  workingTime: number | null;
  isMoreWorkingTime: UpDown;
};

export type DisplayCondition = {
  staffName: Ref<string | null>;
  macroOperationMaster: Ref<MacroOperationMaster | null>;
  staffAgency: Ref<StaffAgency | null>;
  overTimeAt7Days: Ref<number | null>;
  isMoreOverTimeAt7Days: Ref<UpDown>;
  overTimeAtMonth: Ref<number | null>;
  isMoreOverTimeAtMonth: Ref<UpDown>;
  wageAtMonth: Ref<number | null>;
  isMoreWageAtMonth: Ref<UpDown>;
  targetDate: Ref<Date | null>;
  shiftType: Ref<ShiftType>;
  attendanceType: Ref<ConfigureAttendanceType | null>;
  startTime: Ref<TimeRange>;
  endTime: Ref<TimeRange>;
  workingTime: Ref<number | null>;
  isMoreWorkingTime: Ref<UpDown>;
  resetDisplayCondition: () => void;
  checkIsSetDisplayCondition: () => boolean;
};

export const useDisplayCondition = (): DisplayCondition => {
  const state = reactive<State>({
    staffName: null,
    macroOperationMaster: null,
    staffAgency: null,
    overTimeAt7Days: null,
    isMoreOverTimeAt7Days: { value: true, name: '以上' },
    overTimeAtMonth: null,
    isMoreOverTimeAtMonth: { value: true, name: '以上' },
    wageAtMonth: null,
    isMoreWageAtMonth: { value: true, name: '以上' },
    targetDate: null,
    shiftType: { value: PROVISIONAL, name: '仮シフト' },
    attendanceType: null,
    startTime: { hour: null, min: null },
    endTime: { hour: null, min: null },
    workingTime: null,
    isMoreWorkingTime: { value: true, name: '以上' },
  });

  const resetDisplayCondition = (): void => {
    state.staffName = null;
    state.macroOperationMaster = null;
    state.staffAgency = null;
    state.overTimeAt7Days = null;
    state.isMoreOverTimeAt7Days = { value: true, name: '以上' };
    state.overTimeAtMonth = null;
    state.isMoreOverTimeAtMonth = { value: true, name: '以上' };
    state.wageAtMonth = null;
    state.isMoreWageAtMonth = { value: true, name: '以上' };
    state.targetDate = null;
    state.shiftType = { value: PROVISIONAL, name: '仮シフト' };
    state.attendanceType = null;
    state.startTime = { hour: null, min: null };
    state.endTime = { hour: null, min: null };
    state.workingTime = null;
    state.isMoreWorkingTime = { value: true, name: '以上' };
  };

  // 表示条件が設定されているかのチェック
  const checkIsSetDisplayCondition = (): boolean => {
    const displayCriteriaConfigurationList = [
      !!state.staffName,
      !!state.macroOperationMaster,
      !!state.staffAgency,
      !!state.overTimeAt7Days,
      !!state.overTimeAtMonth,
      !!state.wageAtMonth,
      !!state.attendanceType,
      !!Object.values(state.startTime).every((e) => e),
      !!Object.values(state.endTime).every((e) => e),
      !!state.workingTime,
    ];
    return displayCriteriaConfigurationList.some((e) => e);
  };

  return {
    ...toRefs(state),
    resetDisplayCondition,
    checkIsSetDisplayCondition,
  };
};

export const DisplayConditionStateKey: InjectionKey<DisplayCondition> = Symbol('FilterState');
