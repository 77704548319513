import { parseYmdDate } from 'src/util/datetime';
import { BudgetGroupShiftCountResponse } from 'src/models/api/budgetGroupShiftCountResponse';

export interface BudgetGroupShiftCount {
  budget_group_id: number
  dt: Date
  day_block1_shift_count: number
  day_block2_shift_count: number
}

export function convertBudgetGroupShiftCountResponse(response: BudgetGroupShiftCountResponse): BudgetGroupShiftCount {
  return {
    ...response,
    dt: parseYmdDate(response.dt),
  }
}
