import { type Ref } from '@vue/composition-api'
import { format } from 'date-fns'
import regularShiftApi from 'src/apis/staffShift'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { createInjection } from 'src/util/createInjection'

type Params = {
  budgetGroup: Ref<BudgetGroup>
}

type InjectionValue = {
  loadLogimeterStaffAttendances: (dt: Date, change_attendance_type: number) => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useLoadLogimeterStaffAttendances')

export function useLoadLogimeterStaffAttendancesProvider({ budgetGroup }: Params): void {
  const errorBoundary = useErrorBoundary()
  const { notifySuccess } = useNotification()
  const loadLogimeterStaffAttendances = errorBoundary.wrap(
    async(dt: Date, change_attendance_type: number) => {
      await regularShiftApi.loadLogimeterStaffAttendances({
        workplace_id: budgetGroup.value.workplace_id,
        budget_group_id: budgetGroup.value.id,
        dt: format(dt, 'yyyy-MM-dd'),
        change_attendance_type: change_attendance_type,
      })
      notifySuccess('出勤実績をLMから受信を開始しました')
    }
  )

  provide({
    loadLogimeterStaffAttendances,
  })
}

export function useLoadLogimeterStaffAttendances(): InjectionValue {
  return inject()
}
