










import { defineComponent, type PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    type: {
      type: String as PropType<'primary' | 'danger'>,
      default: 'primary',
    },
    icon: {
      type: String as PropType<'pen' | 'trash-alt'>,
      required: true,
    },
  },
})
