import { useProgressHeader } from '../../composables/useProgressHeader'
import { useProgressDetailListModal } from '../../composables/useProgressDetailListModal'

type ShowProgressDetails = {
  showProgressDetails: () => void
}

export function useShowProgressDetails(): ShowProgressDetails {
  const { progressHeader, fetchProgressHeader } = useProgressHeader()
  const { showProgressDetailListModal } = useProgressDetailListModal()

  const showProgressDetails = () => {
    showProgressDetailListModal({
      progressHeader,
      fetchProgressHeader,
    })
  }

  return {
    showProgressDetails,
  }
}
