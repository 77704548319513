import { type ChartDataSets } from 'chart.js';
import { type ProgressHeader } from 'src/models/progressHeader';
import { getProgressRate, getResultManHoursRate } from 'src/util/progressHeaderHelpers';

function getDataset(rate: number, color: string, weight: number): ChartDataSets {
  const normalizedRate = Math.min(Math.max(rate, 0), 100);
  return {
    data: [normalizedRate, 100 - normalizedRate],
    borderAlign: 'inner',
    borderWidth: 0,
    backgroundColor: [color, '#f2f2f2'],
    weight,
  };
}

function getBlankDataset(weight: number): ChartDataSets {
  return {
    data: [100],
    borderAlign: 'inner',
    borderWidth: 0,
    backgroundColor: '#fff',
    hoverBackgroundColor: '#fff',
    weight,
  };
}

function getProgressRateDataset(progressHeader: ProgressHeader): ChartDataSets {
  const progressRate = getProgressRate(progressHeader);
  const progressChartColor = '#8497b0';
  return getDataset(progressRate ?? 0, progressChartColor, 7);
}

function getResultManHoursRateDataset(progressHeader: ProgressHeader): ChartDataSets {
  const resultManHoursRate = getResultManHoursRate(progressHeader);
  const resultManHoursColor = '#b4c7e7';
  return resultManHoursRate !== null ? getDataset(resultManHoursRate, resultManHoursColor, 3) : getBlankDataset(3);
}

export function getProgressChartDataset(progressHeader: ProgressHeader): [ChartDataSets, ChartDataSets] {
  return [getProgressRateDataset(progressHeader), getResultManHoursRateDataset(progressHeader)];
}
