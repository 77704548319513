export const MONTHLY_PLAN_DISPLAY_TARGET = {
  INITIAL_QUANTITY: 1,
  FINAL_QUANTITY: 2,
  ACTUAL_QUANTITY: 3,
  PLANNED_PRODUCTIVITY: 4,
  ACTUAL_PRODUCTIVITY: 5,
  REQUIRED_MAN_HOURS: 6,
  PLANNED_MAN_HOURS: 7,
  ACTUAL_MAN_HOURS: 8,
  OVERTIME_WORK_HOURS: 9,
} as const;
