






import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { formatTime } from '../../utils/filters'
import { type TimeRange, getTotalTime, isStrictTimeRange } from 'src/values/TimeRange'

export default defineComponent({
  filters: {
    formatTime,
  },
  props: {
    timeRanges: {
      type: Array as PropType<(TimeRange | null)[]>,
      required: true,
    },
  },
  setup(props) {
    const totalBreakTime = computed(() => getTotalTime(props.timeRanges.filter(isStrictTimeRange)))

    return {
      totalBreakTime,
    }
  },
})
