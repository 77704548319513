import progressHeaderApi from 'src/apis/progressHeader'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { type ProgressHeader } from 'src/models/progressHeader'
import { useProgressHeader } from './useProgressHeader'

type UpdateTargetCompletionTime = {
  updateTargetCompletionTime: (targetCompletionTime: ProgressHeader['target_completion_time']) => Promise<boolean>
}

export function useUpdateTargetCompletionTime(): UpdateTargetCompletionTime {
  const { progressHeader, fetchProgressHeader } = useProgressHeader()
  const errorBoundary = useErrorBoundary()
  const { notifySuccess } = useNotification()
  const updateTargetCompletionTime = errorBoundary.wrap(
    async(targetCompletionTime: ProgressHeader['target_completion_time']) => {
      await progressHeaderApi.update({
        id: progressHeader.value.id,
        workplace_id: progressHeader.value.workplace_id,
        target_completion_time: targetCompletionTime,
      })
      notifySuccess('目標時刻を登録しました')
      await fetchProgressHeader()
    },
    {
      shouldContactUs: true,
    },
  )

  return {
    updateTargetCompletionTime,
  }
}
