




















import { defineComponent, type PropType } from '@vue/composition-api';
import { type BackgroundProcessStatus } from 'src/models/backgroundProcessStatus';
import { getStatusName } from '../../utils/getStatusName';

export default defineComponent({
  props: {
    value: {
      type: Number as PropType<BackgroundProcessStatus['process_type'] | null>,
      default: null,
    },
    disallowed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const options = ([10, 1, 99] as const).map((v) => ({ value: v, label: getStatusName(v) }));

    return {
      options,
    };
  },
});
