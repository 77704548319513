import budgetGroupPlanBoardMiscApi from 'src/apis/budgetGroupPlanBoardMisc';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { type BudgetGroup } from 'src/models/budgetGroup';

type Params = {
  budgetGroup: BudgetGroup;
  date: Date;
  memo: string;
};

type ReturnValue = {
  updateBudgetGroupPlanBoardMiscMemo: (params: Params) => Promise<boolean>;
};

export function useUpdateBudgetGroupPlanBoardMiscMemo(): ReturnValue {
  const errorBoundary = useErrorBoundary();
  const { notifySuccess } = useNotification();
  const updateBudgetGroupPlanBoardMiscMemo = errorBoundary.wrap(
    async ({ budgetGroup, date, memo }: Params) => {
      await budgetGroupPlanBoardMiscApi.upsert({
        workplace_id: budgetGroup.workplace_id,
        budget_group_id: budgetGroup.id,
        dt: date,
        memo,
      });
      notifySuccess('メモを更新しました');
    },
    {
      fallbackMessage: 'メモの更新に失敗しました。',
      shouldContactUs: true,
    },
  );

  return {
    updateBudgetGroupPlanBoardMiscMemo,
  };
}
