import { unpackTimeIntegerToString } from 'src/util/datetime';
import type { StaffGender, StaffType } from 'src/consts';
import type { TimeInteger } from 'src/models/common';
import type { StaffWorkScheduleSubset } from 'src/models/api/staffWorkScheduleRequest';
import type { ShiftPattern } from 'src/models/workplaceMasters/shiftPattern';
import { Staff } from 'src/models/staff';

export type WeekKey = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type WeekName = '月' | '火' | '水' | '木' | '金' | '土' | '日';
export type Week = { key: WeekKey; name: WeekName; value: number }[];

type NonNullablePropsRequireWithout<T, S extends keyof T> = Pick<T, S> & {
  [K in keyof Omit<T, S>]-?: NonNullable<T[K]>;
};

export type LabelName =
  | 'work_start_time'
  | 'work_end_time'
  | 'break1_start_time'
  | 'break1_end_time'
  | 'break2_start_time'
  | 'break2_end_time'
  | 'duty1_start_time'
  | 'duty1_end_time'
  | 'duty2_start_time'
  | 'duty2_end_time';

export type SaveCandidate = {
  staffId: number | null;
  isShiftManagementTarget: boolean | null;
  isAppropriationTarget: boolean | null;
  staffType: StaffType | null;
  gender: StaffGender | null;
  isKeyPlayer: boolean | null;
  isForkman: boolean | null;
  macroOperationMasterId: number | null;
  hasCustomSkill1: boolean | null;
  hasCustomSkill2: boolean | null;
  hasCustomSkill3: boolean | null;
  staffSkills: { timetableMasterId: number | null; priority: number }[];
  staffWorkSchedules: StaffWorkScheduleSubset[];
};

export type UpdateCandidate = NonNullablePropsRequireWithout<SaveCandidate, 'macroOperationMasterId'> & {
  memo: string;
};

export type BulkUpdateCandidate = SaveCandidate & {
  isCleared: WeekKey[];
  staffs: Staff[];
};

export interface StaffWorkScheduleFormValues {
  day_of_week: number;
  work_start_time_hour: string | null;
  work_start_time_min: string | null;
  work_end_time_hour: string | null;
  work_end_time_min: string | null;
  break1_start_time_hour: string | null;
  break1_start_time_min: string | null;
  break1_end_time_hour: string | null;
  break1_end_time_min: string | null;
  break2_start_time_hour: string | null;
  break2_start_time_min: string | null;
  break2_end_time_hour: string | null;
  break2_end_time_min: string | null;
  duty1_start_time_hour: string | null;
  duty1_start_time_min: string | null;
  duty1_end_time_hour: string | null;
  duty1_end_time_min: string | null;
  duty2_start_time_hour: string | null;
  duty2_start_time_min: string | null;
  duty2_end_time_hour: string | null;
  duty2_end_time_min: string | null;
  duty1_macro_operation_master_id: number | null;
  duty2_macro_operation_master_id: number | null;
}

// 0表示ではなく空表示になるようにwrap
const timeIntegerToString = (timeInteger: TimeInteger | null) => {
  if (timeInteger === null) {
    return ['', '', ''];
  }
  return unpackTimeIntegerToString(timeInteger);
};

// StaffWorkScheduleFormValuesへ変換
export const convertToStaffWorkScheduleFormValues = (
  staffWorkSchedules: StaffWorkScheduleSubset,
): StaffWorkScheduleFormValues => {
  return {
    day_of_week: staffWorkSchedules.day_of_week,
    work_start_time_hour: timeIntegerToString(staffWorkSchedules.work_start_time)[0],
    work_start_time_min: timeIntegerToString(staffWorkSchedules.work_start_time)[1],
    work_end_time_hour: timeIntegerToString(staffWorkSchedules.work_end_time)[0],
    work_end_time_min: timeIntegerToString(staffWorkSchedules.work_end_time)[1],
    break1_start_time_hour: timeIntegerToString(staffWorkSchedules.break1_start_time)[0],
    break1_start_time_min: timeIntegerToString(staffWorkSchedules.break1_start_time)[1],
    break1_end_time_hour: timeIntegerToString(staffWorkSchedules.break1_end_time)[0],
    break1_end_time_min: timeIntegerToString(staffWorkSchedules.break1_end_time)[1],
    break2_start_time_hour: timeIntegerToString(staffWorkSchedules.break2_start_time)[0],
    break2_start_time_min: timeIntegerToString(staffWorkSchedules.break2_start_time)[1],
    break2_end_time_hour: timeIntegerToString(staffWorkSchedules.break2_end_time)[0],
    break2_end_time_min: timeIntegerToString(staffWorkSchedules.break2_end_time)[1],
    duty1_start_time_hour: timeIntegerToString(staffWorkSchedules.duty1_start_time)[0],
    duty1_start_time_min: timeIntegerToString(staffWorkSchedules.duty1_start_time)[1],
    duty1_end_time_hour: timeIntegerToString(staffWorkSchedules.duty1_end_time)[0],
    duty1_end_time_min: timeIntegerToString(staffWorkSchedules.duty1_end_time)[1],
    duty2_start_time_hour: timeIntegerToString(staffWorkSchedules.duty2_start_time)[0],
    duty2_start_time_min: timeIntegerToString(staffWorkSchedules.duty2_start_time)[1],
    duty2_end_time_hour: timeIntegerToString(staffWorkSchedules.duty2_end_time)[0],
    duty2_end_time_min: timeIntegerToString(staffWorkSchedules.duty2_end_time)[1],
    duty1_macro_operation_master_id: staffWorkSchedules.duty1_macro_operation_master_id,
    duty2_macro_operation_master_id: staffWorkSchedules.duty2_macro_operation_master_id,
  };
};

export function getDayValue(name: string): number {
  const dayOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  return dayOfWeek.indexOf(name);
}

export function updateCandidateByShiftPattern(
  saveCandidate: SaveCandidate,
  staffWorkScheduleFormValues: StaffWorkScheduleFormValues[],
  selectedShifts: WeekKey[],
  shiftPattern: ShiftPattern,
) {
  const [startTimeHour, startTimeMin] = timeIntegerToString(shiftPattern.start_time);
  const [endTimeHour, endTimeMin] = timeIntegerToString(shiftPattern.end_time);
  const [break1StartTimeHour, break1StartTimeMin] = timeIntegerToString(shiftPattern.break1_start_time);
  const [break1EndTimeHour, break1EndTimeMin] = timeIntegerToString(shiftPattern.break1_end_time);
  const [break2StartTimeHour, break2StartTimeMin] = timeIntegerToString(shiftPattern.break2_start_time);
  const [break2EndTimeHour, break2EndTimeMin] = timeIntegerToString(shiftPattern.break2_end_time);
  selectedShifts.forEach((weekKey) => {
    const dayOfWeek = getDayValue(weekKey);
    const staffWorkSchedule = saveCandidate.staffWorkSchedules.find(
      (staffWorkSchedule) => staffWorkSchedule.day_of_week === dayOfWeek,
    );
    if (staffWorkSchedule === undefined) {
      return;
    }
    const staffWorkScheduleFromValue = staffWorkScheduleFormValues.find(
      (formValues) => formValues.day_of_week === dayOfWeek,
    );
    if (staffWorkScheduleFromValue === undefined) {
      return;
    }

    staffWorkSchedule.work_start_time = shiftPattern.start_time;
    staffWorkSchedule.work_end_time = shiftPattern.end_time;
    staffWorkSchedule.break1_start_time = shiftPattern.break1_start_time;
    staffWorkSchedule.break1_end_time = shiftPattern.break1_end_time;
    staffWorkSchedule.break2_start_time = shiftPattern.break2_start_time;
    staffWorkSchedule.break2_end_time = shiftPattern.break2_end_time;

    staffWorkScheduleFromValue.work_start_time_hour = startTimeHour;
    staffWorkScheduleFromValue.work_start_time_min = startTimeMin;
    staffWorkScheduleFromValue.work_end_time_hour = endTimeHour;
    staffWorkScheduleFromValue.work_end_time_min = endTimeMin;
    staffWorkScheduleFromValue.break1_start_time_hour = break1StartTimeHour;
    staffWorkScheduleFromValue.break1_start_time_min = break1StartTimeMin;
    staffWorkScheduleFromValue.break1_end_time_hour = break1EndTimeHour;
    staffWorkScheduleFromValue.break1_end_time_min = break1EndTimeMin;
    staffWorkScheduleFromValue.break2_start_time_hour = break2StartTimeHour;
    staffWorkScheduleFromValue.break2_start_time_min = break2StartTimeMin;
    staffWorkScheduleFromValue.break2_end_time_hour = break2EndTimeHour;
    staffWorkScheduleFromValue.break2_end_time_min = break2EndTimeMin;
  });
}

export const BREAK_NUMBERS = [1, 2] as const;
export const EXTRA_DUTY_NUMBERS = [1, 2] as const;
export const WEEK: { key: WeekKey; name: WeekName; value: number }[] = [
  { key: 'mon', name: '月', value: 1 },
  { key: 'tue', name: '火', value: 2 },
  { key: 'wed', name: '水', value: 3 },
  { key: 'thu', name: '木', value: 4 },
  { key: 'fri', name: '金', value: 5 },
  { key: 'sat', name: '土', value: 6 },
  { key: 'sun', name: '日', value: 0 },
];
