import {
  reactive,
  InjectionKey,
  toRefs,
  Ref,
} from '@vue/composition-api';

type State = {
  isDisplayConfirmModal: boolean
  confirmTitle: string
  confirmMessage: string
  runButtonLabel: string
  customClass: string
  confirmCallback: () => void
}

type ConfirmModalArgs = {
  title: string
  message?: string
  runButtonLabel?: string
  customClass?: string
  callback: () => void
}

type ConfirmModalDisplay = {
  openConfirmModal: (args: ConfirmModalArgs) => void
  closeConfirmModal: () => void
  isDisplayConfirmModal: Ref<boolean>
  confirmTitle: Ref<string>
  confirmMessage: Ref<string>
  runButtonLabel: Ref<string>
  customClass: Ref<string>
  confirmCallback: Ref<() => void>
}

export const useConfirmModal = (): ConfirmModalDisplay => {
  const state = reactive<State>({
    isDisplayConfirmModal: false,
    confirmTitle: '',
    confirmMessage: '',
    runButtonLabel: '',
    customClass: '',
    confirmCallback: () => {},
  })

  const openConfirmModal = ({ title, message, runButtonLabel, customClass, callback }: ConfirmModalArgs): void => {
    state.confirmCallback = async() => {
      await callback()
      closeConfirmModal()
    }
    state.confirmTitle = title
    state.confirmMessage = message ?? ''
    state.runButtonLabel = runButtonLabel ?? ''
    state.customClass = customClass ?? ''
    state.isDisplayConfirmModal = true;
  }

  const closeConfirmModal = (): void => {
    state.isDisplayConfirmModal = false;
  }

  return {
    ...toRefs(state),
    openConfirmModal,
    closeConfirmModal,
  }
}

export const ConfirmModalDisplayStateKey: InjectionKey<ConfirmModalDisplay> = Symbol('ConfirmModalDisplay');
