


















import { defineComponent, type PropType } from '@vue/composition-api'
import { type TimetableMaster } from 'src/models/timetableMaster'

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<TimetableMaster[]>,
      required: true,
    },
    timetableMasters: {
      type: Array as PropType<TimetableMaster[]>,
      required: true,
    },
  },
})
