import {
  type DisplayCondition,
  type UpDown,
  type ConfigureAttendanceType,
  type TimeRange,
} from 'src/views/Dashboard/Workplace/RegularShift/hooks/useDisplayCondition';
import {
  type StaffWithShifts,
  type Total7Days,
  type ShiftItem,
  type Shift,
  type PreShiftItem,
  type PreShift,
} from 'src/models/regularShift';
import { SHIFT_PHASE, ATTENDANCE_TYPE, type ShiftPhase } from 'src/consts';
import { formatDate, timeStrToTimeInteger } from 'src/util/datetime';

type FilterParams = {
  displayConditionState: DisplayCondition;
  staffWithShifts: StaffWithShifts;
  total7Days: Total7Days;
  displayMode: ShiftPhase;
  shouldShowDisabled: boolean;
};

type CheckShiftConditionParams = {
  attendanceType: number | null;
  startTime: number | null;
  endTime: number | null;
  hoursTotal: string | null;
};

type ShiftCriterias = {
  isAttendanceTypeMatched: boolean;
  isWorkTimeMatched: boolean;
  isWorkingTimeMatched: boolean;
};

const { ACTUAL, SCHEDULED, PROVISIONAL } = SHIFT_PHASE;

const { TBD, NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE } = ATTENDANCE_TYPE;

const ATTENDANCE_STATUSES: Array<number> = [NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE];

export const filterStaffForDisplay = (filterParams: FilterParams): boolean => {
  const { displayConditionState, staffWithShifts, displayMode, total7Days, shouldShowDisabled } = filterParams;
  // 無効のものを表示するかどうかのチェック
  if (!shouldShowDisabled && !staffWithShifts.is_enabled) {
    return false;
  }

  // スタッフの条件
  const isStaffConditionMatched = _checkStaffCondition(displayConditionState, staffWithShifts);
  if (!isStaffConditionMatched) {
    return false;
  }

  // 7日残業
  const total7DaysOvertime = total7Days ? total7Days[staffWithShifts.staff_id] : null;
  const is7daysOvertimeMatched = _checkStaffWorkHoursOrWageCondition(
    displayConditionState.overTimeAt7Days.value,
    displayConditionState.isMoreOverTimeAt7Days.value,
    total7DaysOvertime?.overtime,
  );
  if (!is7daysOvertimeMatched) {
    return false;
  }

  // displayModeから使用する月合計データを取得する
  const monthlySummary = staffWithShifts.staff_monthly_summary.find((e) => e.mode === displayMode);
  // 月残業
  const monthlySummaryOverTime = monthlySummary ? Number(monthlySummary.hours_overtime) : undefined;
  const isMonthlyOvertimeMatched = _checkStaffWorkHoursOrWageCondition(
    displayConditionState.overTimeAtMonth.value,
    displayConditionState.isMoreOverTimeAtMonth.value,
    monthlySummaryOverTime,
  );
  if (!isMonthlyOvertimeMatched) {
    return false;
  }

  // 月賃金
  const isMonthlyWageMatched = _checkStaffWorkHoursOrWageCondition(
    displayConditionState.wageAtMonth.value,
    displayConditionState.isMoreWageAtMonth.value,
    monthlySummary?.all_cost_sum,
  );
  if (!isMonthlyWageMatched) {
    return false;
  }

  // シフト条件
  const isShiftConditionMatched = _checkShiftCondition(displayConditionState, staffWithShifts);
  if (!isShiftConditionMatched) {
    return false;
  }

  return true;
};

const _checkStaffCondition = (displayConditionState: DisplayCondition, staff: StaffWithShifts): boolean => {
  // スタッフ名
  const isMatchStaffName =
    !displayConditionState.staffName.value ||
    `${staff.family_name}${staff.first_name}`.indexOf(displayConditionState.staffName.value) !== -1;
  // シフトグループ
  const isMatchMacroOperation =
    !displayConditionState.macroOperationMaster.value ||
    displayConditionState.macroOperationMaster.value.id === staff.staff_extension.macro_operation_master.id;
  // スタッフ所属会社
  const isMatchStaffAgency =
    !displayConditionState.staffAgency.value || displayConditionState.staffAgency.value.id === staff.staff_agency.id;
  // スタッフ名、シフトグループ、スタッフ所属会社全てがマッチしていた場合true、それ以外はfalse
  return isMatchStaffName && isMatchMacroOperation && isMatchStaffAgency;
};

const _checkStaffWorkHoursOrWageCondition = (
  inputValue: number | null,
  isMore: UpDown,
  actualStaffValue: number | undefined,
): boolean => {
  // 入力値がnullの場合は全件true
  if (inputValue === null) {
    return true;
  }
  // 出勤実績値がundefinedの場合はfalse
  if (actualStaffValue === undefined) {
    return false;
  }

  return isMore ? actualStaffValue >= inputValue : actualStaffValue <= inputValue;
};

const _checkShiftCondition = (displayConditionState: DisplayCondition, staff: StaffWithShifts): boolean => {
  // シフト条件
  const isInputStartTime = Object.values(displayConditionState.startTime.value).every((e) => e !== null);
  const isInputEndTime = Object.values(displayConditionState.endTime.value).every((e) => e !== null);
  const isNoneInput =
    !displayConditionState.attendanceType.value &&
    !isInputStartTime &&
    !isInputEndTime &&
    !displayConditionState.workingTime.value;

  if (isNoneInput) {
    return true;
  }
  // シフト条件の対象日付がnullの場合はチェックを行わずにtrue
  if (!displayConditionState.targetDate.value) {
    return true;
  }

  const shiftCriterias = {
    isAttendanceTypeMatched: true,
    isWorkTimeMatched: true,
    isWorkingTimeMatched: true,
  };
  // 各種シフトタイプの処理
  // 勤怠が出勤系以外の場合はworkTimeとworkingTimeの条件は無視する
  const checkShiftConditionsParams: CheckShiftConditionParams = {
    attendanceType: null,
    startTime: null,
    endTime: null,
    hoursTotal: null,
  };

  // シフトのインデックスを取得する処理
  switch (displayConditionState.shiftType.value.value) {
    case PROVISIONAL: {
      const preShift = _getPreShift(staff.orig_pre_shifts, displayConditionState.targetDate.value);
      if (preShift) {
        checkShiftConditionsParams.attendanceType = preShift.attendance_type;
        checkShiftConditionsParams.startTime = preShift.work_start_time;
        checkShiftConditionsParams.endTime = preShift.work_end_time;
        checkShiftConditionsParams.hoursTotal = preShift.hours_total;
      }
      Object.assign(shiftCriterias, _checkShiftConditions(checkShiftConditionsParams, displayConditionState));
      break;
    }
    case SCHEDULED: {
      const shift = _getShift(staff.orig_shifts, displayConditionState.targetDate.value);
      if (shift) {
        checkShiftConditionsParams.attendanceType = shift.scheduled_attendance_type;
        checkShiftConditionsParams.startTime = shift.scheduled_work_start_time;
        checkShiftConditionsParams.endTime = shift.scheduled_work_end_time;
        checkShiftConditionsParams.hoursTotal = shift.scheduled_hours_total;
      }
      Object.assign(shiftCriterias, _checkShiftConditions(checkShiftConditionsParams, displayConditionState));
      break;
    }
    case ACTUAL: {
      const shift = _getShift(staff.orig_shifts, displayConditionState.targetDate.value);
      if (shift) {
        checkShiftConditionsParams.attendanceType = shift.actual_attendance_type;
        checkShiftConditionsParams.startTime = shift.actual_work_start_time;
        checkShiftConditionsParams.endTime = shift.actual_work_end_time;
        checkShiftConditionsParams.hoursTotal = shift.actual_hours_total;
      }
      Object.assign(shiftCriterias, _checkShiftConditions(checkShiftConditionsParams, displayConditionState));
      break;
    }
    default:
      break;
  }

  // シフトの条件を全て満たす場合true
  const isMatchShift = Object.values(shiftCriterias).every((e) => e);
  return isMatchShift;
};

// シフト条件の対象日付のシフトのインデックスを取得
const _getShift = (shifts: Array<ShiftItem>, baseDate: Date): Shift | undefined => {
  const targetShift = shifts.find((e) => {
    return e.date === formatDate(baseDate, 'yyyy-MM-dd');
  });

  return targetShift?.data;
};

const _getPreShift = (preShifts: Array<PreShiftItem>, baseDate: Date): PreShift | undefined => {
  const targetDate = formatDate(baseDate, 'yyyy-MM-dd');
  const targetShift = preShifts.find((e) => e.date === targetDate);
  return targetShift?.data;
};

const _checkShiftConditions = (
  checkShiftConditionParams: CheckShiftConditionParams,
  displayConditionState: DisplayCondition,
): ShiftCriterias => {
  const shiftCriterias = {
    isAttendanceTypeMatched: true,
    isWorkTimeMatched: true,
    isWorkingTimeMatched: true,
  };

  if (displayConditionState.attendanceType.value) {
    shiftCriterias.isAttendanceTypeMatched = _checkAttendanceType(
      checkShiftConditionParams.attendanceType,
      displayConditionState.attendanceType.value,
    );
  }
  // 表示条件で設定中の勤怠が出勤系の場合、追加で処理を実行
  if (
    displayConditionState.attendanceType.value &&
    ATTENDANCE_STATUSES.includes(displayConditionState.attendanceType.value.value)
  ) {
    shiftCriterias.isWorkTimeMatched = _checkWorkTime(
      checkShiftConditionParams.startTime,
      checkShiftConditionParams.endTime,
      displayConditionState.startTime.value,
      displayConditionState.endTime.value,
    );
    if (displayConditionState.workingTime.value === null) {
      shiftCriterias.isWorkingTimeMatched = true;
    } else if (checkShiftConditionParams.hoursTotal === null) {
      shiftCriterias.isWorkingTimeMatched = false;
    } else {
      shiftCriterias.isWorkingTimeMatched = _checkWorkingTime(
        checkShiftConditionParams.hoursTotal,
        displayConditionState.workingTime.value,
        displayConditionState.isMoreWorkingTime.value,
      );
    }
  }

  return shiftCriterias;
};

const _checkAttendanceType = (
  attendanceTypeNumber: number | null,
  configureAttendanceType: ConfigureAttendanceType,
): boolean => {
  // 対象シフトにattendanceTypeが存在しない場合はTBDとして扱う
  const targetAttendanceType = attendanceTypeNumber || TBD;
  return targetAttendanceType === configureAttendanceType.value;
};

const _checkWorkTime = (
  startTimeNumber: number | null,
  endTimeNumber: number | null,
  startTime: TimeRange,
  endTime: TimeRange,
): boolean => {
  const isCompleteInputStartTime = Object.values(startTime).every((e) => e !== null);
  const isCompleteInputEndTime = Object.values(endTime).every((e) => e !== null);
  // 始業、終業ともに入力がない、または足りない場合trueを返す
  if (!isCompleteInputStartTime && !isCompleteInputEndTime) {
    return true;
  }

  // 表示条件モーダル内の入力要素で、分が二桁でない場合二桁に整形する
  const startTimeNum = timeStrToTimeInteger(`${startTime.hour}:${startTime.min}`);
  const endTimeNum = timeStrToTimeInteger(`${endTime.hour}:${endTime.min}`);

  if (isCompleteInputStartTime) {
    if (isCompleteInputEndTime) {
      // 始業終業どちらも入力のパターン
      return startTimeNumber === startTimeNum && endTimeNumber === endTimeNum;
    }
    // 始業のみ入力のパターン
    return startTimeNumber === startTimeNum;
  }
  // 終業のみ入力のパターン
  return endTimeNumber === endTimeNum;
};

const _checkWorkingTime = (hoursTotal: string, workingTime: number, isMoreWorkingTime: UpDown): boolean => {
  return isMoreWorkingTime.value ? Number(hoursTotal) >= workingTime : Number(hoursTotal) <= workingTime;
};
