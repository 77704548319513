import { computed, defineComponent, PropType, reactive, SetupContext } from '@vue/composition-api'

export default defineComponent({
  name: 'daily-board-prep-plus-minus-value',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    fixed: {
      type: Number,
      default: 1,
    }
  },
  setup(props) {
    const state = reactive({
      class: computed(() => props.value === 0 ? '' : props.value > 0 ? 'is-plus' : 'is-minus'),
      label: computed(() => props.value === 0 ? '' : props.labels[props.value > 0 ? 0 : 1] ?? ''),
    })
    return {
      state,
    }
  },
})
