import { ComputedRef, InjectionKey, Ref, reactive, computed, toRefs } from '@vue/composition-api';
import { LovEntry } from 'src/models/master';
import {
  Shift,
  PreShift,
  TimeRange,
  TotalTime,
  UpdateIndividualTargetState,
  StaffWithShifts,
} from 'src/models/regularShift';
import { ATTENDANCE_TYPE, SHIFT_PHASE } from 'src/consts';
import { generateEmptyTimeRange, generateEmptyTime } from 'src/views/Dashboard/Workplace/RegularShift/utils';
import { convertNumberToTime } from 'src/views/Dashboard/Workplace/RegularShift/libs/formatter';
import { parseYmdDate, unpackTimeIntegerToString } from 'src/util/datetime';
import { format } from 'date-fns';
import { searchShiftAndPreShift } from 'src/views/Dashboard/Workplace/RegularShift/libs/regularShiftHelper';

const { NO_SHIFT, NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE } = ATTENDANCE_TYPE;

const { PROVISIONAL, SCHEDULED, ACTUAL } = SHIFT_PHASE;

const ATTENDANCE_AT_WORK: number[] = [NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE];

export type BreakTime = {
  break1: TimeRange;
  break2: TimeRange;
};

type ShiftTimes = {
  startTime: number | null;
  endTime: number | null;
  break1Start: number | null;
  break1End: number | null;
  break2Start: number | null;
  break2End: number | null;
  shiftType: number;
};

type RateState = {
  overtimeExtraPercent: number;
  holidayExtraPercent: number;
  midnightExtraPercent: number;
  overSixtyHoursExtraPercent: number;
  standardWorkingHoursInt: number;
};

type State = {
  preShiftId: Ref<number>;
  preShiftAttendanceType: Ref<LovEntry | null>;
  preShiftWorkHours: Ref<TimeRange>;
  preShiftBreakTimes: Ref<BreakTime>;
  shiftId: Ref<number>;
  scheduledShiftAttendanceType: Ref<LovEntry | null>;
  scheduledShiftWorkHours: Ref<TimeRange>;
  scheduledShiftBreakTimes: Ref<BreakTime>;
  possibleOverTime: Ref<{ hour: string | null; min: string | null }>;
  actualShiftAttendanceType: Ref<LovEntry | null>;
  shiftAttendanceSupplement: Ref<LovEntry | null>;
  actualShiftWorkHours: Ref<TimeRange>;
  actualBreakTime: Ref<TotalTime>;
  hourlyCost: Ref<number>;
  isChangeAtNight: Ref<boolean>;
  isBeginner: Ref<boolean>;
  memo: Ref<string | null>;
  overtimeExtraRate: ComputedRef<string>;
  standardWorkingHours: ComputedRef<number>;
  holidayExtraRate: ComputedRef<string>;
  midnightExtraRate: ComputedRef<string>;
  overSixtyHoursExtraRate: ComputedRef<string>;
  date: Ref<string | null>;
  staffId: Ref<number | null>;
  midnightExtraTargetTime: Ref<TimeRange>;
};

type ShiftRegisterHook = State & {
  overtimeExtraPercent: Ref<number>;
  holidayExtraPercent: Ref<number>;
  midnightExtraPercent: Ref<number>;
  overSixtyHoursExtraPercent: Ref<number>;
  standardWorkingHoursInt: Ref<number>;
  preShiftTotalBreakTime: ComputedRef<TotalTime>;
  scheduledShiftTotalBreakTime: ComputedRef<TotalTime>;
  resetState: () => void;
  setPreShiftForDisplay: (targetPreShift: PreShift | undefined, attendanceTypes: LovEntry[]) => void;
  setExtraWageConfigurations: (targetShift: Shift | PreShift) => void;
  setExtraWageConfigurationsFromStaffMaster: (selectedStaff: StaffWithShifts | undefined) => void;
  setScheduledAndActualFromShift: (
    targetShift: Shift | undefined,
    attendanceTypes: LovEntry[],
    attendanceSupplements: LovEntry[],
  ) => void;
  setScheduledShiftTimeFromPreShift: (selectedStaff: StaffWithShifts, selectedDate: string) => void;
  setActualToScheduled: (selectedStaff: StaffWithShifts, selectedDate: string) => void;
  setPreShift: (selectedStaff: StaffWithShifts, selectedDate: string) => void;
};

export const useShiftRegister = () => {
  const state = reactive<UpdateIndividualTargetState>({
    preShiftId: 0,
    preShiftAttendanceType: null,
    preShiftWorkHours: generateEmptyTimeRange(),
    preShiftBreakTimes: {
      break1: generateEmptyTimeRange(),
      break2: generateEmptyTimeRange(),
    },
    shiftId: 0,
    scheduledShiftAttendanceType: null,
    scheduledShiftWorkHours: generateEmptyTimeRange(),
    scheduledShiftBreakTimes: {
      break1: generateEmptyTimeRange(),
      break2: generateEmptyTimeRange(),
    },
    possibleOverTime: generateEmptyTime(),
    actualShiftAttendanceType: null,
    shiftAttendanceSupplement: null,
    actualShiftWorkHours: generateEmptyTimeRange(),
    actualBreakTime: generateEmptyTime(),
    hourlyCost: 0,
    isChangeAtNight: false,
    isBeginner: false,
    memo: null,
    overtimeExtraRate: computed(() => (rateState.overtimeExtraPercent / 100).toString()),
    holidayExtraRate: computed(() => (rateState.holidayExtraPercent / 100).toString()),
    midnightExtraRate: computed(() => (rateState.midnightExtraPercent / 100).toString()),
    overSixtyHoursExtraRate: computed(() => (rateState.overSixtyHoursExtraPercent / 100).toString()),
    date: null,
    staffId: null,
    standardWorkingHours: computed(() => convertNumberToTime(rateState.standardWorkingHoursInt)),
    midnightExtraTargetTime: generateEmptyTimeRange(),
  });

  const preShiftTotalBreakTime = computed(() =>
    _calcTotalBreak1AndBreak2(state.preShiftBreakTimes.break1, state.preShiftBreakTimes.break2),
  );
  const scheduledShiftTotalBreakTime = computed(() =>
    _calcTotalBreak1AndBreak2(state.scheduledShiftBreakTimes.break1, state.scheduledShiftBreakTimes.break2),
  );

  const rateState = reactive<RateState>({
    overtimeExtraPercent: 0,
    holidayExtraPercent: 0,
    midnightExtraPercent: 0,
    overSixtyHoursExtraPercent: 50,
    standardWorkingHoursInt: 0,
  });

  // state及びrateStateのリセット
  const resetState = (): void => {
    state.preShiftId = 0;
    state.preShiftAttendanceType = null;
    state.preShiftWorkHours = generateEmptyTimeRange();
    state.preShiftBreakTimes.break1 = generateEmptyTimeRange();
    state.preShiftBreakTimes.break2 = generateEmptyTimeRange();
    state.shiftId = 0;
    state.scheduledShiftAttendanceType = null;
    state.scheduledShiftWorkHours = generateEmptyTimeRange();
    state.scheduledShiftBreakTimes.break1 = generateEmptyTimeRange();
    state.scheduledShiftBreakTimes.break2 = generateEmptyTimeRange();
    state.possibleOverTime = generateEmptyTime();
    state.actualShiftAttendanceType = null;
    state.shiftAttendanceSupplement = null;
    state.actualShiftWorkHours = generateEmptyTimeRange();
    state.actualBreakTime = generateEmptyTime();
    state.hourlyCost = 0;
    state.isChangeAtNight = false;
    state.isBeginner = false;
    state.memo = null;
    state.date = null;
    state.staffId = 0;
    state.midnightExtraTargetTime = generateEmptyTimeRange();

    rateState.overtimeExtraPercent = 0;
    rateState.holidayExtraPercent = 0;
    rateState.midnightExtraPercent = 0;
    rateState.overSixtyHoursExtraPercent = 50;
    rateState.standardWorkingHoursInt = 0;
  };

  // 仮シフトのデータのセット
  const setPreShiftForDisplay = (targetPreShift: PreShift | undefined, attendanceTypes: LovEntry[]): void => {
    // 仮シフトが存在しない場合、シフトなしをセットする
    if (!targetPreShift) {
      state.preShiftAttendanceType = _getAttendanceType(NO_SHIFT, attendanceTypes);
      return;
    }
    state.preShiftAttendanceType = _getAttendanceType(targetPreShift.attendance_type, attendanceTypes);
    state.preShiftWorkHours = _getTimeRange(targetPreShift.work_start_time, targetPreShift.work_end_time);
    state.preShiftBreakTimes.break1 = _getTimeRange(targetPreShift.break1_start_time, targetPreShift.break1_end_time);
    state.preShiftBreakTimes.break2 = _getTimeRange(targetPreShift.break2_start_time, targetPreShift.break2_end_time);
  };

  const setExtraWageConfigurations = (targetShift: Shift | PreShift): void => {
    state.hourlyCost = targetShift.hourly_cost;
    state.isChangeAtNight = targetShift.is_change_at_night;
    state.isBeginner = targetShift.is_beginner;
    state.memo = targetShift.memo;
    state.midnightExtraTargetTime = _getTimeRange(targetShift.midnight_time_start, targetShift.midnight_time_end);
    rateState.overtimeExtraPercent = Number(targetShift.overtime_extra_rate) * 100;
    rateState.holidayExtraPercent = Number(targetShift.holiday_extra_rate) * 100;
    rateState.midnightExtraPercent = Number(targetShift.midnight_extra_rate) * 100;
    rateState.overSixtyHoursExtraPercent = Number(targetShift.over_sixty_hours_extra_rate) * 100;
    rateState.standardWorkingHoursInt = _convertTimeToNumber(targetShift.standard_working_hours);
  };

  const setExtraWageConfigurationsFromStaffMaster = (selectedStaff: StaffWithShifts | undefined): void => {
    if (!selectedStaff) {
      return;
    }

    state.hourlyCost = selectedStaff.hourly_cost;
    rateState.overtimeExtraPercent = Number(selectedStaff.overtime_extra_rate) * 100;
    rateState.holidayExtraPercent = Number(selectedStaff.holiday_extra_rate) * 100;
    rateState.midnightExtraPercent = Number(selectedStaff.midnight_extra_rate) * 100;
    rateState.standardWorkingHoursInt = _convertTimeToNumber(selectedStaff.standard_working_hours);
    state.midnightExtraTargetTime = _getTimeRange(selectedStaff.midnight_time_start, selectedStaff.midnight_time_end);
  };

  const setScheduledAndActualFromShift = (
    targetShift: Shift | undefined,
    attendanceTypes: LovEntry[],
    attendanceSupplements: LovEntry[],
  ) => {
    if (!targetShift) {
      state.scheduledShiftAttendanceType = _getAttendanceType(NO_SHIFT, attendanceTypes);
      state.actualShiftAttendanceType = _getAttendanceType(NO_SHIFT, attendanceTypes);
      return;
    }
    // 確定シフト
    state.scheduledShiftAttendanceType = _getAttendanceType(targetShift.scheduled_attendance_type, attendanceTypes);
    state.scheduledShiftWorkHours = _getTimeRange(
      targetShift.scheduled_work_start_time,
      targetShift.scheduled_work_end_time,
    );
    state.scheduledShiftBreakTimes.break1 = _getTimeRange(
      targetShift.scheduled_break1_start_time,
      targetShift.scheduled_break1_end_time,
    );
    state.scheduledShiftBreakTimes.break2 = _getTimeRange(
      targetShift.scheduled_break2_start_time,
      targetShift.scheduled_break2_end_time,
    );
    const [possibleOverTimeHour, possibleOverTimeMin] = _getHourAndMin(targetShift.possible_over_time);
    state.possibleOverTime = { hour: possibleOverTimeHour, min: possibleOverTimeMin };
    // 出勤実績
    state.actualShiftAttendanceType = _getAttendanceType(targetShift.actual_attendance_type, attendanceTypes);
    state.actualShiftWorkHours = _getTimeRange(targetShift.actual_work_start_time, targetShift.actual_work_end_time);
    const [actualBreakHour, actualBreakMin] = _getHourAndMin(targetShift.actual_break_time);
    state.actualBreakTime = { hour: actualBreakHour, min: actualBreakMin };
    const attendanceSupplementKey = targetShift.attendance_supplement;
    const shiftAttendanceSupplement = targetShift
      ? attendanceSupplements.find((obj) => Number(obj.key) === attendanceSupplementKey)
      : null;
    state.shiftAttendanceSupplement = shiftAttendanceSupplement || null;
  };

  const setScheduledShiftTimeFromPreShift = (selectedStaff: StaffWithShifts, selectedDate: string) => {
    const { preShift, shift } = searchShiftAndPreShift(selectedStaff, selectedDate);
    if (!preShift || !shift) {
      return;
    }

    // 予実シフトレコードが存在し、確定シフトの開始終了時間がnullでない場合その時間をセットする
    if (shift.data && shift.data.scheduled_work_start_time !== null && shift.data.scheduled_work_end_time !== null) {
      state.scheduledShiftWorkHours = _getTimeRange(
        shift.data.scheduled_work_start_time,
        shift.data.scheduled_work_end_time,
      );
      state.scheduledShiftBreakTimes.break1 = _getTimeRange(
        shift.data.scheduled_break1_start_time,
        shift.data.scheduled_break1_end_time,
      );
      state.scheduledShiftBreakTimes.break2 = _getTimeRange(
        shift.data.scheduled_break2_start_time,
        shift.data.scheduled_break2_end_time,
      );
      return;
    }

    // 仮シフトが存在し、出勤系の場合仮シフトの時間をセットする
    if (preShift.data && ATTENDANCE_AT_WORK.indexOf(Number(preShift.data.attendance_type)) !== -1) {
      state.scheduledShiftWorkHours = _getTimeRange(preShift.data.work_start_time, preShift.data.work_end_time);
      state.scheduledShiftBreakTimes.break1 = _getTimeRange(
        preShift.data.break1_start_time,
        preShift.data.break1_end_time,
      );
      state.scheduledShiftBreakTimes.break2 = _getTimeRange(
        preShift.data.break2_start_time,
        preShift.data.break2_end_time,
      );
      return;
    }

    _setShiftTimeFromStaffMaster(selectedStaff, selectedDate, SCHEDULED);
  };

  const setActualToScheduled = (selectedStaff: StaffWithShifts, selectedDate: string): void => {
    const { shift } = searchShiftAndPreShift(selectedStaff, selectedDate);
    if (!shift) {
      return;
    }
    // shiftが存在しない場合、スタッフマスタから取得する
    if (!shift.data) {
      _setShiftTimeFromStaffMaster(selectedStaff, selectedDate, ACTUAL);
      return;
    }
    // 出勤実績のデータが存在する場合、そのままセット
    if (shift.data.actual_work_start_time !== null && shift.data.actual_work_end_time !== null) {
      state.actualShiftWorkHours = _getTimeRange(shift.data.actual_work_start_time, shift.data.actual_work_end_time);
      const [actualBreakHour, actualBreakMin] = _getHourAndMin(shift.data.actual_break_time);
      state.actualBreakTime = { hour: actualBreakHour, min: actualBreakMin };
      return;
    }
    // 確定シフトのシフトが出勤系の場合、確定シフトのデータをセット
    if (ATTENDANCE_AT_WORK.indexOf(Number(shift.data.scheduled_attendance_type)) !== -1) {
      state.actualShiftWorkHours = _getTimeRange(
        shift.data.scheduled_work_start_time,
        shift.data.scheduled_work_end_time,
      );
      state.actualBreakTime = _calcTotalBreak1AndBreak2(
        _getTimeRange(shift.data.scheduled_break1_start_time, shift.data.scheduled_break1_end_time),
        _getTimeRange(shift.data.scheduled_break2_start_time, shift.data.scheduled_break2_end_time),
      );
      return;
    }
    // 上記何にも引っかからない場合、スタッフマスタからセット
    _setShiftTimeFromStaffMaster(selectedStaff, selectedDate, ACTUAL);
  };

  const setPreShift = (selectedStaff: StaffWithShifts, selectedDate: string) => {
    const { preShift } = searchShiftAndPreShift(selectedStaff, selectedDate);
    if (!preShift) {
      return;
    }
    // 仮シフトのデータが存在しない場合スタッフマスタから取得する
    if (!preShift.data) {
      _setShiftTimeFromStaffMaster(selectedStaff, selectedDate, PROVISIONAL);
      return;
    }
    // 仮シフトの開始終了時間が存在する場合そのままセット
    if (preShift.data.work_start_time !== null && preShift.data.work_end_time !== null) {
      state.preShiftWorkHours = _getTimeRange(preShift.data.work_start_time, preShift.data.work_end_time);
      state.preShiftBreakTimes.break1 = _getTimeRange(preShift.data.break1_start_time, preShift.data.break1_end_time);
      state.preShiftBreakTimes.break2 = _getTimeRange(preShift.data.break2_start_time, preShift.data.break2_end_time);
      return;
    }
    // 上記何にも引っかからない場合、スタッフマスタからセット
    _setShiftTimeFromStaffMaster(selectedStaff, selectedDate, PROVISIONAL);
  };

  const _setShiftTimeFromStaffMaster = (
    selectedStaff: StaffWithShifts,
    selectedDate: string,
    shiftType: number,
  ): void => {
    if (!selectedDate) {
      return;
    }
    const date = parseYmdDate(selectedDate);
    const weekDay = format(date, 'E').toLowerCase();
    const days: string[] = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    const dayOfWeek = days.indexOf(weekDay);

    if (dayOfWeek === -1) {
      return;
    }

    const staff_work_schedule = selectedStaff.staff_extension.staff_work_schedules.find(
      (obj) => obj.day_of_week === dayOfWeek,
    );

    if (staff_work_schedule === undefined) {
      return;
    }

    _setStartAndEndTime({
      startTime: staff_work_schedule.work_start_time,
      endTime: staff_work_schedule.work_end_time,
      break1Start: staff_work_schedule.break1_start_time,
      break1End: staff_work_schedule.break1_end_time,
      break2Start: staff_work_schedule.break2_start_time,
      break2End: staff_work_schedule.break2_end_time,
      shiftType: shiftType,
    });
  };

  // TODO: スタッフのデフォルト勤務時間の設定が追加されたら、startTime, endTimeがnullの場合はデフォルト勤務時間を参照する処理を追加する
  const _setStartAndEndTime = ({
    startTime,
    endTime,
    break1Start,
    break1End,
    break2Start,
    break2End,
    shiftType,
  }: ShiftTimes): void => {
    // スタッフマスタからの設定
    if (shiftType === PROVISIONAL) {
      state.preShiftWorkHours = _getTimeRange(startTime, endTime);
      state.preShiftBreakTimes.break1 = _getTimeRange(break1Start, break1End);
      state.preShiftBreakTimes.break2 = _getTimeRange(break2Start, break2End);
    } else if (shiftType === SCHEDULED) {
      state.scheduledShiftWorkHours = _getTimeRange(startTime, endTime);
      state.scheduledShiftBreakTimes.break1 = _getTimeRange(break1Start, break1End);
      state.scheduledShiftBreakTimes.break2 = _getTimeRange(break2Start, break2End);
    } else if (shiftType === ACTUAL) {
      state.actualShiftWorkHours = _getTimeRange(startTime, endTime);
    }
  };

  const _calcTotalBreak1AndBreak2 = (break1: TimeRange, break2: TimeRange): TotalTime => {
    const break1TotalTime = _calcTotalBreakTime(break1);
    const break2TotalTime = _calcTotalBreakTime(break2);

    return _mergeTotalBreakTime(break1TotalTime, break2TotalTime);
  };

  const _calcTotalBreakTime = ({ startHour, startMin, endHour, endMin }: TimeRange): TotalTime => {
    const totalBreakTime: TotalTime = generateEmptyTime();
    if (!startHour || !startMin || !endHour || !endMin) {
      return totalBreakTime;
    }

    const startHourNum = Number(startHour);
    const startMinNum = Number(startMin);
    const endHourNum = Number(endHour);
    const endMinNum = Number(endMin);

    const formated24HourEndHour = endHourNum < startHourNum ? endHourNum + 24 : endHourNum;
    const totalBreakTimeHour = formated24HourEndHour - startHourNum;
    const totalBreakTimeMin = endMinNum - startMinNum;

    totalBreakTime.min = totalBreakTimeMin < 0 ? (totalBreakTimeMin + 60).toString() : totalBreakTimeMin.toString();
    totalBreakTime.hour = totalBreakTimeMin < 0 ? (totalBreakTimeHour - 1).toString() : totalBreakTimeHour.toString();

    return totalBreakTime;
  };

  // 休憩時間が複数設定されている場合その合計休憩時間を取得
  const _mergeTotalBreakTime = (totalBreakTime1: TotalTime, totalBreakTime2: TotalTime): TotalTime => {
    if (!totalBreakTime2.hour || !totalBreakTime2.min) {
      return {
        hour: totalBreakTime1.hour,
        min: totalBreakTime1.min ? _formatMinTwoDigits(Number(totalBreakTime1.min)) : null,
      };
    }

    const totalBreakTime = {
      hour: (Number(totalBreakTime1.hour) + Number(totalBreakTime2.hour)).toString(),
      min: _formatMinTwoDigits(Number(totalBreakTime1.min) + Number(totalBreakTime2.min)),
    };

    if (Number(totalBreakTime.min) < 60) {
      return totalBreakTime;
    }

    totalBreakTime.hour = (Number(totalBreakTime.hour) + 1).toString();
    totalBreakTime.min = _formatMinTwoDigits(Number(totalBreakTime.min) - 60);

    return totalBreakTime;
  };

  const _formatMinTwoDigits = (min: number): string => (min.toString().length >= 2 ? min.toString() : `0${min}`);

  const _getTimeRange = (startTime: number | null, endTime: number | null): TimeRange => {
    const [startHour, startMin] = startTime !== null ? unpackTimeIntegerToString(startTime) : [null, null, null];
    const [endHour, endMin] = endTime !== null ? unpackTimeIntegerToString(endTime) : [null, null, null];

    return {
      startHour: startHour,
      startMin: startMin,
      endHour: endHour,
      endMin: endMin,
    };
  };

  // [時間, 分] で取得
  const _getHourAndMin = (timeInteger: number | null): Array<string | null> => {
    return timeInteger ? unpackTimeIntegerToString(timeInteger) : [null, null, null];
  };

  // セレクトボックスのattendance_typeのLovのリストと形式を同じにするためshiftのattendanceTypeのキーからオブジェクトを取得している
  const _getAttendanceType = (attendanceType: number, attendanceTypes: LovEntry[]): LovEntry | null => {
    const attendanceTypeObj = attendanceTypes.find((obj) => Number(obj.key) === attendanceType);
    if (!attendanceTypeObj) {
      return null;
    }
    return attendanceTypeObj;
  };

  // hhmmssの数字をxx.xx形式に変換
  const _convertTimeToNumber = (timeNumber: number): number => {
    const hour = Math.floor(timeNumber / 10000);
    const min = Math.floor((timeNumber - hour * 10000) / 100);
    // 小数点第二位まで表示する
    const minNum = Math.floor((min / 60) * 100) / 100;

    return hour + minNum;
  };

  return {
    ...toRefs(state),
    ...toRefs(rateState),
    resetState,
    preShiftTotalBreakTime,
    scheduledShiftTotalBreakTime,
    setPreShiftForDisplay,
    setExtraWageConfigurations,
    setExtraWageConfigurationsFromStaffMaster,
    setScheduledAndActualFromShift,
    setScheduledShiftTimeFromPreShift,
    setActualToScheduled,
    setPreShift,
  };
};

export const ShiftRegisterStateKey: InjectionKey<ShiftRegisterHook> = Symbol('ShiftRegisterState');
