import { CollectiveStaffResponse } from 'src/models/api/collectiveStaffResponse';
import { CollectiveStaffSkill, convertCollectiveStaffSkillResponse } from 'src/models/collectiveStaffSkill';

export type CollectiveStaff = Omit<CollectiveStaffResponse, 'collective_staff_skills'> & {
  collective_staff_skills: CollectiveStaffSkill[]
}

export function convertCollectiveStaffResponse(response: CollectiveStaffResponse): CollectiveStaff {
  const { collective_staff_skills, ...rest } = response
  return {
    ...rest,
    collective_staff_skills: collective_staff_skills?.map(convertCollectiveStaffSkillResponse) ?? [],
  }
}
