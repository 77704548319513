




import moment from 'src/util/moment-ja'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    dateStr: {
      type: String as PropType<string>,
    },
  },
  setup(props) {
    function date() {
      return moment(props.dateStr)
    }
    function getWdColorClass() {
      return `wd-color-${date().weekday()}`
    }
    return {
      date,
      getWdColorClass,
    }
  }
})
