import { type ShiftPhase } from 'src/consts';
import { type MacroOperation, type MacroOperationMaster, type StaffWithShifts } from 'src/models/regularShift';
import { getGenericShift } from 'src/views/Dashboard/Workplace/RegularShift/libs/displayModeHelper';

export const getTotalBaseValue = (date: string, macroOperationMasters: MacroOperationMaster[]): number => {
  const dateMatchedMacroOperations = _getDateMatchedMacroOperation(date, macroOperationMasters);
  const totalBaseValue = dateMatchedMacroOperations.reduce((accu, data) => {
    return accu + data.required_working_hours;
  }, 0);

  return Math.round(totalBaseValue);
};

const _getDateMatchedMacroOperation = (
  date: string,
  macroOperationMasters: MacroOperationMaster[],
): MacroOperation[] => {
  const macroOperations = macroOperationMasters.flatMap((e) => e.macro_operations);
  return macroOperations.filter((e) => e.dt === date);
};

// 人時を取得 (人数でも使用<人時が0以外だったら人数が1>)
export const getManHours = (staff: StaffWithShifts, dateIndex: number, displayMode: ShiftPhase): number => {
  const preShift = staff.orig_pre_shifts[dateIndex];
  const shift = staff.orig_shifts[dateIndex];

  return getGenericShift(shift, preShift, displayMode).hoursTotal;
};
