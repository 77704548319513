import { InjectionKey, Ref, inject, ref } from '@vue/composition-api';
import budgetGroupPlanBoardMiscApi from 'src/apis/budgetGroupPlanBoardMisc';
import { CommonStateKey } from './useCommonState';
import { BudgetGroupPlanBoardMisc } from 'src/models/budgetGroupPlanBoardMisc';
import { addDays, format } from 'date-fns';
import { useNotification } from 'src/composables/useNotification';
import { isExist } from 'src/util/isExist';

const DISPLAY_PERIOD = 10;
type BudgetGroupPlanBoardMiscsState = {
  loadBudgetGroupPlanBoardMiscs: () => Promise<void>;
  budgetGroupPlanBoardMiscMemosByDate: Ref<string[]>;
  upsertMemo: (memo: string, dt: string) => Promise<void>;
};

export const useBudgetGroupPlanBoardMiscs = (): BudgetGroupPlanBoardMiscsState => {
  const commonState = inject(CommonStateKey);
  if (!commonState) {
    throw new Error('State error');
  }

  const { baseDate, workplaceId, budgetGroup } = commonState;
  const { notifyError, notifySuccess } = useNotification();

  const budgetGroupPlanBoardMiscs: Ref<BudgetGroupPlanBoardMisc[]> = ref([]);
  const budgetGroupPlanBoardMiscMemosByDate: Ref<string[]> = ref([]);
  const loadBudgetGroupPlanBoardMiscs = async (): Promise<void> => {
    budgetGroupPlanBoardMiscs.value = await budgetGroupPlanBoardMiscApi.index({
      workplace_id: workplaceId.value,
      budget_group_id: budgetGroup.value.id,
      start_date: baseDate.value,
      end_date: addDays(baseDate.value, DISPLAY_PERIOD),
    });
    const dates = Array.from({ length: DISPLAY_PERIOD }).map((x, i) => addDays(baseDate.value, i));
    const memoByDate: string[] = [];
    dates.forEach((date, index) => {
      const budgetGroupPlanBoardMisc = budgetGroupPlanBoardMiscs.value.find(
        (value) => value.dt.toDateString() === date.toDateString(),
      );
      memoByDate[index] = budgetGroupPlanBoardMisc?.memo ?? '';
    });
    budgetGroupPlanBoardMiscMemosByDate.value = memoByDate;
  };

  const upsertMemo = async (memo: string, dt: string): Promise<void> => {
    const budgetGroupPlanBoardMisc = budgetGroupPlanBoardMiscs.value.find(
      (value) => format(value.dt, 'yyyy-MM-dd') === dt,
    );
    if (budgetGroupPlanBoardMisc?.memo === memo || (!isExist(budgetGroupPlanBoardMisc) && memo === '')) {
      return;
    }

    try {
      await budgetGroupPlanBoardMiscApi.upsert({
        workplace_id: workplaceId.value,
        budget_group_id: budgetGroup.value.id,
        dt: new Date(dt),
        memo: memo,
      });
      await loadBudgetGroupPlanBoardMiscs();
      notifySuccess('当日メモを更新しました');
    } catch (err: any) {
      notifyError('当日メモの更新に失敗しました', { cause: err });
    }
  };
  return {
    budgetGroupPlanBoardMiscMemosByDate,
    loadBudgetGroupPlanBoardMiscs,
    upsertMemo,
  };
};

export const BudgetGroupPlanBoardMiscsStateKey: InjectionKey<BudgetGroupPlanBoardMiscsState> =
  Symbol('BudgetGroupPlanBoardMiscs');
