
















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { EditableTime } from 'src/components/UIComponents/Inputs/EditableTime';
import { type TimeRange, createTimeRange } from 'src/values/TimeRange';

export default defineComponent({
  components: {
    EditableTime,
  },
  props: {
    value: {
      type: Object as PropType<TimeRange | null>,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const startTime = computed({
      get: () => props.value?.startTime ?? null,
      set: (value) => {
        context.emit('input', createTimeRange(value, endTime.value));
      },
    });

    const endTime = computed({
      get: () => props.value?.endTime ?? null,
      set: (value) => {
        context.emit('input', createTimeRange(startTime.value, value));
      },
    });

    const handleStartTimeChange = (value: number | null) => {
      startTime.value = value;
      update();
    };

    const handleEndTimeChange = (value: number | null) => {
      endTime.value = value;
      update();
    };

    const update = () => {
      // inputによるhasErrorの更新を待つためにsetTimeoutを使用する
      // nextTickではうまくいかなかった
      setTimeout(() => {
        if (!props.hasError) {
          context.emit('update', props.value);
        }
      });
    };

    return {
      startTime,
      endTime,
      handleStartTimeChange,
      handleEndTimeChange,
    };
  },
});
