
import Vue from 'vue'
import { SetupContext, defineComponent, inject } from '@vue/composition-api'
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal'
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState'
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift'
import { StaffWithShifts } from 'src/models/regularShift'
import staffMonthlySummary from 'src/apis/staffMonthlySummary'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';

export default defineComponent({
  name: 'update-monthly-summary-modal',
  setup(_, context: SetupContext) {
    const root = context.root as Vue
    const modalState = inject(ModalDisplayStateKey);
    const commonState = inject(CommonStateKey);
    const staffWithShiftState = inject(StaffWithShiftsStateKey);
    if (!modalState || !commonState || !staffWithShiftState) {
      throw new Error('State Error');
    }

    const {
      isDisplayUpdateMonthlySummaryModal,
      closeUpdateMonthlySummaryModal,
    } = modalState

    const {
      budgetGroup,
      workplaceId,
      baseDate,
    } = commonState

    const {
      staffsWithShifts
    } = staffWithShiftState

    const executeMonthlyTotalUpdate = () => {
      const year = baseDate.value.getFullYear();
      const month = baseDate.value.getMonth() + 1;
      const staffIds = staffsWithShifts.value.map((staffWithShifts: StaffWithShifts) => {
        return staffWithShifts.staff_id
      })

      const params = {
        workplace_id: workplaceId.value,
        budget_group_id: budgetGroup.value.id,
        year,
        month,
        staff_ids: staffIds,
      }

      try {
        staffMonthlySummary.bulkUpsert(params);
        // モーダルを閉じる
        closeUpdateMonthlySummaryModal();
        // 実行ポップアップを表示
        notifySuccess1(root, '月合計の更新を開始しました')
      } catch {
        notifyError1(root, '月合計の更新に失敗しました')
      }
    }

    return {
      workplaceId,
      budgetGroup,
      isDisplayUpdateMonthlySummaryModal,
      closeUpdateMonthlySummaryModal,
      executeMonthlyTotalUpdate,
    }
  }
})

