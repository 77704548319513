
import { defineComponent, inject, watch } from '@vue/composition-api'
import { StaffMonthlySummary } from 'src/models/staffMonthlySummary';
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift'
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal'
import { YearSummaryStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useYearSummary'

export type YearSummaryModalInfo = {
  year: number | null
  staffName: string | null
  yearSummary: StaffMonthlySummary | null
  monthSummaries: StaffMonthlySummary[]
}

export default defineComponent({
  name: 'year-summary-modal',
  setup() {
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const modalDisplayState = inject(ModalDisplayStateKey);
    const yearSummaryState = inject(YearSummaryStateKey);
    if (!staffWithShiftsState || !modalDisplayState || !yearSummaryState) {
      throw new Error('State error');
    }

    const {
      showTargetStaff,
      showTargetYear,
    } = staffWithShiftsState

    const {
      monthlySummaries,
      totalSummary,
    } = yearSummaryState

    const {
      isDisplayYearSummaryModal,
      closeYearSummaryModal,
    } = modalDisplayState

    // 表示してそのままなのでreactiveでなくてよい
    const state: YearSummaryModalInfo = {
      year: null,
      staffName: null,
      yearSummary: null,
      monthSummaries: [],
    }

    watch([showTargetStaff, showTargetYear], () => {
      state.year = showTargetYear.value
      state.staffName = showTargetStaff.value
        ? `${showTargetStaff.value.first_name}${showTargetStaff.value.family_name}`
        : null
    })

    return {
      state,
      isDisplayYearSummaryModal,
      closeYearSummaryModal,
      monthlySummaries,
      totalSummary,
    }
  },
})

