import { StaffWithShifts } from 'src/models/regularShift'

type StaffWithShiftsCompareMap = Pick<StaffWithShifts, 'staff_extension'>

export const sortStaffsWithShifts = <T extends StaffWithShiftsCompareMap>(staffs: T[]): T[] => {
  return staffs.sort((a, b) => {
    const n1 = a.staff_extension.disp_order || Infinity
    const n2 = b.staff_extension.disp_order || Infinity
    return n1 < n2 ? -1 : (n1 > n2 ? 1 : 0)
  })
}
