






























import { defineComponent } from '@vue/composition-api';
import { CreateButton } from 'src/components/UIComponents/Buttons/CreateButton';
import { Draggable } from 'src/components/UIComponents/Draggable';
import { ActivityMappingCsvDownloadButton } from '../ActivityMappingCsvDownloadButton';
import { useTimetableMasters } from '../../composables/useTimetableMasters';
import { useCreateTimetableMaster } from '../../composables/useCreateTimetableMaster';
import { useUpdateTimetableMaster } from '../../composables/useUpdateTimetableMaster';
import { useDeleteTimetableMaster } from '../../composables/useDeleteTimetableMaster';
import { useUpdateTimetableMasterDispOrders } from '../../composables/useUpdateTimetableMasterDispOrders';
import { TimetableMasterList, TimetableMasterListItem } from '../TimetableMasterList';

export default defineComponent({
  components: {
    CreateButton,
    Draggable,
    ActivityMappingCsvDownloadButton,
    TimetableMasterList,
    TimetableMasterListItem,
  },
  setup() {
    const { filteredTimetableMasters } = useTimetableMasters();
    const { showCreateModal } = useCreateTimetableMaster();
    const { showUpdateModal } = useUpdateTimetableMaster();
    const { showDeleteConfirmationModal } = useDeleteTimetableMaster();
    const { updateTimetableMasterDispOrders } = useUpdateTimetableMasterDispOrders();

    return {
      filteredTimetableMasters,
      showCreateModal,
      showUpdateModal,
      showDeleteConfirmationModal,
      updateTimetableMasterDispOrders,
    };
  },
});
