





















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type TimeInteger } from 'src/models/common';
import { unpackTimeIntegerToString } from 'src/util/datetime';

export default defineComponent({
  props: {
    value: {
      type: Number as PropType<TimeInteger | null>,
      default: null,
    },
    border: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const parts = computed(() => {
      const [hours, minutes] = props.value !== null ? unpackTimeIntegerToString(props.value) : [];
      return { hours, minutes };
    });

    return {
      parts,
    };
  },
});
