
































import { defineComponent, PropType } from '@vue/composition-api';
import CsvDownload from 'src/components/UIComponents/Buttons/CsvDownloadButton/CsvDownload.vue';
import type { CsvRow, BeforeDownload } from './types';

export default defineComponent({
  name: 'csv-download-neutral-button',
  components: {
    CsvDownload,
  },
  props: {
    csvData: {
      type: Array as PropType<CsvRow[]>,
      required: true,
    },
    csvFileName: {
      type: String,
      required: true,
    },
    beforeDownload: {
      type: Function as PropType<BeforeDownload>,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'CSV出力',
    },
    enclosedByDoubleQuotation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
});
