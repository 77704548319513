import { render, staticRenderFns } from "./StaffLabelSelect.vue?vue&type=template&id=9c69f7e6&scoped=true"
import script from "./StaffLabelSelect.vue?vue&type=script&lang=ts"
export * from "./StaffLabelSelect.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c69f7e6",
  null
  
)

export default component.exports