






import {
  defineComponent,
  ref,
  SetupContext,
  PropType,
  provide,
  readonly,
  InjectionKey,
  watch,
  Ref,
} from '@vue/composition-api';

export interface SortSpec {
  key: string;
  asc: boolean;
  compareFunc?: (a: any, b: any) => number;
}

export const GET_CURRENT_SORT_SPECS_KEY: InjectionKey<() => Readonly<Ref<SortSpec[]>>> = Symbol(
  'injection key of currentSortSpec',
);
export const ON_SORT_FUNC_KEY: InjectionKey<(sortSpec: SortSpec[]) => void> = Symbol('injection key of onSort Func');

function compareFuncDefault(a: any, b: any): number {
  const aConv = isNaN(a) ? a : +a;
  const bConv = isNaN(b) ? b : +b;
  return aConv < bConv ? -1 : aConv > bConv ? 1 : 0;
}

function sortBy<T extends Record<string, any>>(srcArr: T[], sortSpecs: SortSpec[]): T[] {
  const resultArr = srcArr.slice();
  if (sortSpecs.length === 0) {
    return resultArr;
  }
  resultArr.sort((aObj, bObj): number => {
    for (const sortSpec of sortSpecs) {
      // convert dotted string to object
      const propertyChain = sortSpec.key.split('.').filter((e) => !!e); // allow empty key for identity
      const aValue = propertyChain.reduce(
        (obj, prop) => (obj !== null && obj !== undefined ? obj[prop] : null),
        aObj,
      ) as any;
      const bValue = propertyChain.reduce(
        (obj, prop) => (obj !== null && obj !== undefined ? obj[prop] : null),
        bObj,
      ) as any;
      const compareFunc = sortSpec.compareFunc ?? compareFuncDefault;
      const compResult = compareFunc(aValue, bValue) * (sortSpec.asc ? 1 : -1);
      // 0ではない値が出たらこの組み合わせについてそれ以降のソートを実施する必要はない
      if (compResult !== 0) {
        return compResult;
      }
    }
    return 0;
  });
  return resultArr;
}

// oldを元にしつつ、newで入ってきたものが優先となるようにする.
// oldとnewでkeyのかぶりがあればoldから取り除きnewを優先させる.
// 最終的に結果をmaxSortSpecsToKeep個で切り詰める.
const maxSortSpecsToKeep = 5;
function mergeSortSpecs(oldSpecs: SortSpec[], newSpecs: SortSpec[]): SortSpec[] {
  const ret: SortSpec[] = oldSpecs.slice().reverse();
  newSpecs
    .slice()
    .reverse()
    .forEach((newSpec) => {
      const duplicateIdx = ret.findIndex((e) => e.key === newSpec.key);
      if (duplicateIdx !== -1) {
        ret.splice(duplicateIdx, 1);
      }
      ret.push(newSpec);
    });
  return ret.reverse().slice(0, maxSortSpecsToKeep);
}

// https://logaretm.com/blog/generically-typed-vue-components/
// このコンポーネントが出力するソート済み配列の型をprops.listの型と同じにしたい.
// (まぁ少なくともWebStormだとtemplate.htmlでsortedListの型を見るとTになってしまっているのだが...)
class Sorter2ContainerFactory<T extends Record<string, any>> {
  define() {
    return defineComponent({
      name: 'Sorter2Container',
      props: {
        list: {
          type: Array as PropType<T[]>,
          required: true,
        },
        defaultSortSpec: {
          type: Array as PropType<SortSpec[]>,
          default: () => [],
        },
      },
      setup(props, context: SetupContext) {
        const currentSortSpecs = ref<SortSpec[]>(props.defaultSortSpec);
        const sortedList = ref<T[]>([]) as Ref<T[]>;

        const updateSortedList = (srcList: T[]): void => {
          sortedList.value = sortBy(srcList, currentSortSpecs.value);
        };

        const onReSort = (sortSpecs: SortSpec[]): void => {
          currentSortSpecs.value = mergeSortSpecs(currentSortSpecs.value, sortSpecs);
          updateSortedList(sortedList.value);
        };

        watch(
          () => props.list,
          (list) => {
            updateSortedList(list);
          },
          { immediate: true },
        );

        watch(
          () => props.defaultSortSpec,
          (defaultSortSpec) => {
            // 使う側は、defaultSortSpecを設定しなおすことでソート順のリセットができる
            currentSortSpecs.value = defaultSortSpec;
            updateSortedList(sortedList.value);
          },
        );

        provide(GET_CURRENT_SORT_SPECS_KEY, () => readonly(currentSortSpecs));
        provide(ON_SORT_FUNC_KEY, onReSort);

        return {
          sortedList,
        };
      },
    });
  }
}

// componentに必要なrender() は default export に付加されるようなので、一旦型指定なしで呼び出したものを何かに入れて
// export default しておく
const main = new Sorter2ContainerFactory().define();

export function useSorter2Container<T extends Record<string, any>>() {
  // 呼び出し側はこちらをimportして使う. 呼び出された時にその型で無理やりキャストしてやれば型情報付きで戻せる
  return main as ReturnType<Sorter2ContainerFactory<T>['define']>;
}

export default main;
