import { TimetableLabelResponse } from 'src/models/api/timetableLabelResponse';

export interface TimetableLabel {
  id: number
  workplace_id: number
  name: string
  is_enabled: boolean
  disp_order: number
}

export function convertTimetableLabelResponse(response: TimetableLabelResponse): TimetableLabel {
  return { ...response }
}
