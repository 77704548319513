import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import {
  BudgetGroupsOptParams,
  UpdateOptParams,
} from 'src/models/api/budgetGroupExtensionRequest'
import {
  BudgetGroupExtension,
  convertBudgetGroupExtensionResponse
} from 'src/models/budgetGroupExtension'
import { BudgetGroup, convertBudgetGroupResponse } from 'src/models/budgetGroup'

export default {
  async budgetGroups({ workplaceId, params }: { workplaceId: StringOrNumber, params: BudgetGroupsOptParams }): Promise<BudgetGroup[]> {
    const { data } = await axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_group_extension/budget_groups`), {
      params: params || {}
    })
    return data.map(convertBudgetGroupResponse)
  },
  async update({ workplaceId, data }: { workplaceId: StringOrNumber, data: UpdateOptParams }): Promise<BudgetGroupExtension> {
    const { data: reqData } = await axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_group_extension`), data)
    return convertBudgetGroupExtensionResponse(reqData)
  },
}
