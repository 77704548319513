
import Vue from 'vue'
import {
  defineComponent,
  SetupContext,
  toRefs,
  reactive,
  onMounted,
  computed,
} from '@vue/composition-api'
import regularShiftApi from 'src/apis/staffShift'
import { getWeekDay, unpackTimeIntegerToString } from 'src/util/datetime'
import { ATTENDANCE_TYPES_AT_WORK, ATTENDANCE_TYPE } from 'src/consts'
import { StaffWithShiftsForPrint, ShiftTableForPrintParams, MacroOperationForShift } from 'src/models/regularShift'
import { setStyleForPrint } from 'src/views/Dashboard/Layout/PrintLayout.vue'
import { sortStaffsWithShifts } from 'src/views/Dashboard/Workplace/RegularShift/libs/sortStaffsWithShifts'

const PAGE_HEIGHT = 1070.73
const ACTUAL_COLOR = '#F2F2F2'
const SCHEDULED_COLOR = '#FFFFFF'
const PROVISIONAL_COLOR = '#D6DCE5'

type Shift = {
  attendance_type: number | null
  dt: string
  end_time: number | null
  start_time: number | null
  break_time: number | null
  shift_type: number
}

type State = {
  days: string[]
  staffsWithShifts: StaffWithShiftsForPrint[]
  macroOperations: MacroOperationForShift[]
  sheetNum: number
  summariesHeight: number
  summariesNumPerPage: number
  summariesMarginBottom: number
  summariesPageCount: number
  summariesLastPageHeight: number
  pageMarginBottom: number
  staffsStartPageNum: number
  staffsHeight: number
  staffsNumPerPage: number
  staffsCountMargin: number
}

type Query = {
  base_date: string
  date_for_shift_search: string
  budget_group_id: string
  workplace_id: string
  display_mode: string
  should_show_disabled: string
  staff_name: string
  macro_operation_master_id: string
  staff_agency_id: string
  over_time_at_7days: string
  is_more_over_time_at_7days: string
  over_time_at_month: string
  is_more_over_time_at_month: string
  wage_at_month: string
  is_more_wage_at_month: string
  shift_type: string
  attendance_type: string
  start_time: string
  end_time: string
  working_time: string
  is_more_working_time: string
}

interface TotalItem {
  name: string
  data: Array<{
    date: string
    count: number
  }>
}

interface TotalPerMacroOperation extends TotalItem {
  id: number
}

type DisplayShift = {
  dt: string
  attendance_type: null | number
  topText: string
  bottomText: string | null
  color: string
}

type StaffWithShiftsForDisplay = {
  staffName: string
  macroOperation: {
    name: string
    color: string
  } | null
  agencyName: string
  staffNumber: string
  shifts: DisplayShift[]
}

type DisplayState = {
  totalPerMacroOperationArray: TotalPerMacroOperation[]
  totalForAllMacroOperations: TotalItem
  totalForUnsetMacroOperations: TotalItem
  dates: Array<{
    day: number
    wday: string
  }>
  staffsWithShiftsForDisplay: StaffWithShiftsForDisplay[]
}

export default defineComponent({
  name: 'shift-table-for-print',
  setup(props, context: SetupContext) {
    const root = context.root as Vue
    const state: State = reactive({
      staffsWithShifts: [],
      days: [],
      macroOperations: [],
      sheetNum: computed(() => Math.ceil((state.staffsWithShifts.length + state.staffsCountMargin) / state.staffsNumPerPage) + state.staffsStartPageNum - 1),
      summariesHeight: 25,
      summariesNumPerPage: computed(() => Math.floor(PAGE_HEIGHT / state.summariesHeight)),
      summariesMarginBottom: 20,
      summariesPageCount: computed(() => state.macroOperations.length ? Math.ceil(state.macroOperations.length / state.summariesNumPerPage) : 1),
      summariesLastPageHeight: computed(() => ((state.macroOperations.length - 1) % state.summariesNumPerPage + 1) * state.summariesHeight + state.summariesMarginBottom),
      staffsHeight: 42,
      pageMarginBottom: 26,
      staffsNumPerPage: computed(() => Math.floor((PAGE_HEIGHT - state.pageMarginBottom) / state.staffsHeight)),
      staffsStartPageNum: computed(() => state.summariesPageCount + Math.floor((state.summariesLastPageHeight + state.staffsHeight) / PAGE_HEIGHT)),
      staffsCountMargin: computed(() => {
        if (state.summariesPageCount !== state.staffsStartPageNum) {
          return 1
        }
        return Math.round(state.summariesLastPageHeight / state.staffsHeight) + 1
      }),
    })

    const displayState: DisplayState = reactive({
      totalPerMacroOperationArray: [],
      totalForAllMacroOperations: {
        name: '全体',
        data: []
      },
      totalForUnsetMacroOperations: {
        name: '-',
        data: []
      },
      dates: [],
      staffsWithShiftsForDisplay: []
    })

    const {
      base_date,
      date_for_shift_search,
      budget_group_id,
      workplace_id,
      display_mode,
      should_show_disabled,
      staff_name,
      macro_operation_master_id,
      staff_agency_id,
      over_time_at_7days,
      is_more_over_time_at_7days,
      over_time_at_month,
      is_more_over_time_at_month,
      wage_at_month,
      is_more_wage_at_month,
      shift_type,
      attendance_type,
      start_time,
      end_time,
      working_time,
      is_more_working_time,
    } = root.$route.query as unknown as Query

    onMounted(async() => {
      const params: ShiftTableForPrintParams = {
        budget_group_id: Number(budget_group_id),
        workplace_id: Number(workplace_id),
        display_mode: display_mode,
        should_show_disabled: should_show_disabled === 'true',
        staff_name: staff_name,
        macro_operation_master_id: Number(macro_operation_master_id) || null,
        staff_agency_id: Number(staff_agency_id) || null,
        over_time_at_7days: Number(over_time_at_7days) || null,
        is_more_over_time_at_7days: is_more_over_time_at_7days === 'true',
        over_time_at_month: Number(over_time_at_month) || null,
        is_more_over_time_at_month: is_more_over_time_at_month === 'true',
        wage_at_month: Number(wage_at_month) || null,
        is_more_wage_at_month: is_more_wage_at_month === 'true',
        base_date: base_date,
        date_for_shift_search: date_for_shift_search,
        shift_type: Number(shift_type),
        attendance_type: Number(attendance_type) || null,
        start_time: Number(start_time) || null,
        end_time: Number(end_time) || null,
        working_time: Number(working_time) || null,
        is_more_working_time: is_more_working_time === 'true',
      }

      const data = await regularShiftApi.shiftTableForPrint(params)
      state.staffsWithShifts = sortStaffsWithShifts(data.staffs_with_shifts)
      state.days = data.date_range

      _extractMacroOperations();
      // 全体合計とシフトグループなしに初期値を格納する
      displayState.totalForAllMacroOperations.data = state.days.map(e => ({ date: e, count: 0 }))
      displayState.totalForUnsetMacroOperations.data = state.days.map(e => ({ date: e, count: 0 }))
      // シフトグループごとの合計の初期値を格納する
      displayState.totalPerMacroOperationArray = state.macroOperations.map((macroOperation) => {
        return {
          id: macroOperation.id,
          name: macroOperation.name,
          data: state.days.map(e => ({ date: e, count: 0 }))
        }
      })
      _countTotalAndFormatStaffsWithShifts();
      // datesの生成
      displayState.dates = state.days.map((e) => {
        return {
          day: Number(e.split('-')[2]),
          wday: getWeekDay(e)
        }
      })
      setStyleForPrint({ size: 'A3 landscape' })
    })

    const getStaffsSliceStart = (n: number) => {
      return Math.max(0, state.staffsNumPerPage * (n - state.staffsStartPageNum) - state.staffsCountMargin)
    }

    const getStaffsSliceEnd = (n: number) => {
      return state.staffsNumPerPage * (n - state.staffsStartPageNum + 1) - state.staffsCountMargin
    }

    // 対象スタッフに該当するシフトグループを全種類取得する
    const _extractMacroOperations = () => {
      state.staffsWithShifts.forEach((staffWithShifts) => {
        const macroOperationMaster = staffWithShifts.staff_extension.macro_operation_master
        if (!macroOperationMaster || state.macroOperations.find(e => macroOperationMaster && e.id === macroOperationMaster.id)) { return }

        state.macroOperations.push(macroOperationMaster)
      })
    }

    const _getColor = (shiftType: number): string => {
      switch (shiftType) {
        case 1:
          return PROVISIONAL_COLOR
        case 2:
          return SCHEDULED_COLOR
        case 3:
          return ACTUAL_COLOR
        default:
          return ACTUAL_COLOR
      }
    }

    const _countTotalAndFormatStaffsWithShifts = () => {
      state.staffsWithShifts.forEach((staffWithShifts) => {
        const macroOperation = staffWithShifts.staff_extension.macro_operation_master
        const staffsWithShiftsDisplay: StaffWithShiftsForDisplay = {
          staffName: `${staffWithShifts.family_name}${staffWithShifts.first_name}`,
          macroOperation: macroOperation ? { name: macroOperation.name, color: macroOperation.disp_color } : null,
          agencyName: staffWithShifts.staff_agency.name,
          staffNumber: staffWithShifts.staff_number,
          shifts: [],
        }

        staffWithShifts.display_shifts.forEach((shift, i) => {
          const color = _getColor(shift.shift_type);
          const displayShift = _setShiftDisplayTextAndColor(shift, color)
          staffsWithShiftsDisplay.shifts.push(displayShift);

          // シフト合計の計算
          if (!shift.attendance_type || !(shift.attendance_type in ATTENDANCE_TYPES_AT_WORK)) { return }

          displayState.totalForAllMacroOperations.data[i].count += 1
          // シフトグループが設定されていないスタッフは全体のカウントを増やすのみ
          if (macroOperation) {
            const targetMacroOperation = displayState.totalPerMacroOperationArray.find(e => e.id === macroOperation.id)
            if (!targetMacroOperation) { return }

            targetMacroOperation.data[i].count += 1
          } else {
            displayState.totalForUnsetMacroOperations.data[i].count += 1
          }
        })

        displayState.staffsWithShiftsForDisplay.push(staffsWithShiftsDisplay)
      })
    }

    // シフトに表示するテキストを設置する
    const _setShiftDisplayTextAndColor = (targetShift: Shift, color: string): DisplayShift => {
      const shift: DisplayShift = {
        topText: '',
        bottomText: null,
        color,
        dt: targetShift.dt,
        attendance_type: targetShift.attendance_type,
      }
      const [startHour, startMin] = targetShift.start_time ? unpackTimeIntegerToString(targetShift.start_time) : [null, null, null];
      const [endHour, endMin] = targetShift.end_time ? unpackTimeIntegerToString(targetShift.end_time) : [null, null, null];

      switch (shift.attendance_type) {
        case ATTENDANCE_TYPE.NORMAL:
        case ATTENDANCE_TYPE.HOLIDAY_WORK:
        case ATTENDANCE_TYPE.SUBSTITUTE_ATTENDANCE:
          if ([startHour, startMin, endHour, endMin].some((e) => e === null)) {
            shift.topText = ''
            shift.bottomText = null
          } else {
            shift.topText = `${startHour}:${startMin}`
            shift.bottomText = `${endHour}:${endMin}`
          }
          break
        case ATTENDANCE_TYPE.PAID_VACATION:
          shift.topText = '有給'
          shift.bottomText = null
          break
        case ATTENDANCE_TYPE.SUBSTITUTE_HOLIDAY:
          shift.topText = '振休'
          shift.bottomText = null
          break
        case ATTENDANCE_TYPE.SPECIAL_HOLIDAY:
          shift.topText = '特休'
          shift.bottomText = null
          break
        case ATTENDANCE_TYPE.ABSENCE:
          shift.topText = '欠勤'
          shift.bottomText = null
          break
        case ATTENDANCE_TYPE.TBC:
          shift.topText = '要確認'
          shift.bottomText = null
          break
        default:
          shift.topText = '-'
          shift.bottomText = null
          break
      }

      return shift
    }

    return {
      ...toRefs(state),
      ...toRefs(displayState),
      getStaffsSliceStart,
      getStaffsSliceEnd,
    }
  },
})

