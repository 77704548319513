import {
  InjectionKey,
  Ref,
  reactive,
  toRefs,
  ComputedRef,
  computed,
} from '@vue/composition-api'
import { StaffMonthlySummary } from 'src/models/staffMonthlySummary';

type YearSummary = {
  days_worked: number
  paid_holidays: number
  substitute_days_worked: number
  substitute_holidays: number
  hours_total: number
  hours_normal: number
  hours_overtime: number
  hours_midnight: number
  hours_holiday: number
  all_cost_sum: number
  normal_cost_sum: number
  overtime_cost_sum: number
  midnight_cost_sum: number
  holiday_cost_sum: number
}

type YearSummaryHook = {
  monthlySummaries: Ref<StaffMonthlySummary[]>
  totalSummary: ComputedRef<YearSummary>
}

type State = {
  monthlySummaries: StaffMonthlySummary[]
  totalSummary: ComputedRef<YearSummary>
}

export const useYearSummary = () => {
  const state = reactive<State>({
    monthlySummaries: [],
    totalSummary: computed(() => _calcTotalSummary()),
  })

  const _calcTotalSummary = (): YearSummary => {
    const summary = {
      days_worked: 0,
      paid_holidays: 0,
      substitute_days_worked: 0,
      substitute_holidays: 0,
      hours_total: 0,
      hours_normal: 0,
      hours_overtime: 0,
      hours_midnight: 0,
      hours_holiday: 0,
      all_cost_sum: 0,
      normal_cost_sum: 0,
      overtime_cost_sum: 0,
      midnight_cost_sum: 0,
      holiday_cost_sum: 0,
    }

    state.monthlySummaries.forEach((monthlySummary) => {
      summary.days_worked += monthlySummary.days_worked
      summary.paid_holidays += monthlySummary.paid_holidays
      summary.substitute_days_worked += monthlySummary.substitute_days_worked
      summary.substitute_holidays += monthlySummary.substitute_holidays
      summary.hours_total += monthlySummary.hours_total
      summary.hours_normal += monthlySummary.hours_normal
      summary.hours_overtime += monthlySummary.hours_overtime
      summary.hours_midnight += monthlySummary.hours_midnight
      summary.hours_holiday += monthlySummary.hours_holiday
      summary.all_cost_sum += monthlySummary.all_cost_sum
      summary.normal_cost_sum += monthlySummary.normal_cost_sum
      summary.overtime_cost_sum += monthlySummary.overtime_cost_sum + monthlySummary.over_sixty_in_month_cost_sum
      summary.midnight_cost_sum += monthlySummary.midnight_cost_sum
      summary.holiday_cost_sum += monthlySummary.holiday_cost_sum
    })

    return summary
  }

  return {
    ...toRefs(state),
  }
}
export const YearSummaryStateKey: InjectionKey<YearSummaryHook> = Symbol('YearSummary');