



























import { computed, defineComponent, type PropType } from '@vue/composition-api';
import CardPanel from './CardPanel.vue';
import EndTime from './EndTime.vue';
import StatusBoard from './StatusBoard.vue';
import ManHours from './ManHours.vue';
import { TimetableGroupMasterWithProgressHeaders } from 'src/models/timetableGroupMaster';

export default defineComponent({
  components: {
    CardPanel,
    EndTime,
    StatusBoard,
    ManHours,
  },
  props: {
    timetableGroupMaster: {
      type: Object as PropType<TimetableGroupMasterWithProgressHeaders>,
      required: true,
    },
  },
  setup(props) {
    const sumRemainingManHours = computed(() =>
      props.timetableGroupMaster.timetable_masters.reduce((sum, timetable_master) => {
        return (
          sum +
          timetable_master.progress_headers.reduce((acc, cur) => {
            if (cur.scheduled_quantity === null) {
              return acc;
            }

            return acc + (cur.scheduled_man_hours ?? 0) * (1 - (cur.result_quantity ?? 0) / cur.scheduled_quantity);
          }, 0)
        );
      }, 0),
    );

    const sumScheduledManHours = computed(() =>
      props.timetableGroupMaster.timetable_masters.reduce((sum, timetable_master) => {
        return (
          sum +
          timetable_master.progress_headers.reduce((acc, cur) => {
            return acc + (cur.scheduled_man_hours ?? 0);
          }, 0)
        );
      }, 0),
    );

    const sumResultManHours = computed(() =>
      props.timetableGroupMaster.timetable_masters.reduce((sum, timetable_master) => {
        return (
          sum +
          timetable_master.progress_headers.reduce((acc, cur) => {
            return acc + (cur.result_man_hours ?? 0);
          }, 0)
        );
      }, 0),
    );

    return {
      sumRemainingManHours,
      sumScheduledManHours,
      sumResultManHours,
    };
  },
});
