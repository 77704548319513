import { ref, type Ref } from '@vue/composition-api'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { BudgetGroup } from 'src/models/budgetGroup'
import { createInjection } from 'src/util/createInjection'
import { isExist } from 'src/util/isExist'
import macroOperationApi from 'src/apis/macroOperation'
import { format } from 'date-fns'
import { useNotification } from 'src/composables/useNotification'
import { useSearchConditions } from './useSearchConditions'
import { useMonthlyPlan } from './useMonthlyPlan'
import { useModal } from 'src/composables/useModal'

const DATE_FORMAT = 'yyyy-MM-dd'

type InjectionValue = {
  isLoading: Ref<boolean>
  showsActualDataFetchModal: ReturnType<typeof useModal>['showsModal']
  showActualDataFetchModal: ReturnType<typeof useModal>['showModal']
  hideActualDataFetchModal: ReturnType<typeof useModal>['hideModal']
  fetchActualData: (startDate: Date, endDate: Date, budgetGroup: BudgetGroup) => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useActualDataFetchModal')

export function useActualDataFetchModalProvider(): void {
  const errorBoundary = useErrorBoundary()
  const { notifySuccess } = useNotification()
  const {
    showsModal: showsActualDataFetchModal,
    showModal: showActualDataFetchModal,
    hideModal: hideActualDataFetchModal,
  } = useModal()
  const { budgetGroup } = useSearchConditions()
  const { refreshMonthlyPlan } = useMonthlyPlan()

  const isLoading = ref(false)

  const fetchActualData = errorBoundary.wrap(
    async(startDate: Date, endDate: Date) => {
      if (!isExist(budgetGroup.value)) { return }
      isLoading.value = true
      try {
        await macroOperationApi.updateFromLogimeter({
          workplace_id: budgetGroup.value.workplace_id,
          budget_group_id: budgetGroup.value.id,
          start_date: format(startDate, DATE_FORMAT),
          end_date: format(endDate, DATE_FORMAT),
        })
        await refreshMonthlyPlan()
        notifySuccess('実績を取り込みました')
        hideActualDataFetchModal()
      } finally {
        isLoading.value = false
      }
    },
    {
      fallbackMessage: '実績の取込に失敗しました。期間を短くして再度取得してください。解決しない場合は管理者に連絡してください。',
    }
  )

  provide({
    fetchActualData,
    isLoading,
    showsActualDataFetchModal,
    showActualDataFetchModal,
    hideActualDataFetchModal,
  })
}

export function useActualDataFetchModal(): InjectionValue {
  return inject()
}
