











import Vue from 'vue'
import { defineComponent } from '@vue/composition-api'
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { useShiftPatternsProvider } from '../../composables/useShiftPatterns'
import { useCreateShiftPatternProvider } from '../../composables/useCreateShiftPattern'
import { useUpdateShiftPatternProvider } from '../../composables/useUpdateShiftPattern'
import { useDeleteShiftPatternProvider } from '../../composables/useDeleteShiftPattern'
import { Content } from '../Content'
import { SearchPanel } from '../SearchPanel'
import { MainPanel } from '../MainPanel'
import { ShiftPatternCreateModal } from '../ShiftPatternCreateModal'
import { ShiftPatternUpdateModal } from '../ShiftPatternUpdateModal'
import { ShiftPatternDeleteConfirmationModal } from '../ShiftPatternDeleteConfirmationModal'

export default defineComponent({
  components: {
    Content,
    SearchPanel,
    MainPanel,
    ShiftPatternCreateModal,
    ShiftPatternUpdateModal,
    ShiftPatternDeleteConfirmationModal,
  },
  setup(_, context) {
    const root = context.root as Vue
    const workplaceId = Number(root.$route.params.workplaceId)

    setPageName(root, 'シフトパターン')
    useBudgetGroupsProvider({ workplaceId })
    useShiftPatternsProvider()
    useCreateShiftPatternProvider()
    useUpdateShiftPatternProvider()
    useDeleteShiftPatternProvider()
  },
})
