import { getCurrentInstance } from '@vue/composition-api'
import {
  QueryParameters,
  getUrlStoredParameters,
  setUrlStoredParameters,
} from 'src/util/storedParameters';

type UrlParameters<T extends QueryParameters> = {
  getUrlParameters: () => Partial<T>
  setUrlParameters: (params: T) => Promise<void>
}

export const useUrlParameters = <T extends QueryParameters>(): UrlParameters<T> => {
  const vueInstance = getCurrentInstance()?.proxy!
  const getUrlParameters = (): Partial<T> => {
    return getUrlStoredParameters<T>(vueInstance)
  }
  const setUrlParameters = async(params: T): Promise<void> => {
    await setUrlStoredParameters(vueInstance, params)
  }

  return {
    getUrlParameters,
    setUrlParameters,
  }
}
