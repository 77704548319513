










import { defineComponent, ref, watch } from '@vue/composition-api'
import Draggable from 'vuedraggable'

export default defineComponent({
  components: {
    Draggable,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const valueRef = ref(props.value)

    watch([() => props.value], () => {
      valueRef.value = props.value
    })

    return {
      valueRef,
    }
  },
})
