import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import { UpdateOptParams } from 'src/models/api/workplaceExtensionRequest'
import { WorkplaceExtension, convertWorkplaceExtensionResponse } from 'src/models/workplaceExtension'

export default {
  async show(workplaceId: StringOrNumber): Promise<WorkplaceExtension> {
    const { data } = await axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/workplace_extension`))
    return convertWorkplaceExtensionResponse(data)
  },
  async update(workplaceId: StringOrNumber, data: UpdateOptParams): Promise<WorkplaceExtension> {
    const { data: reqData } = await axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/workplace_extension`), data)
    return convertWorkplaceExtensionResponse(reqData)
  },
}
