import { ref, type Ref } from '@vue/composition-api';
import { useBudgetGroupPlanBoardMiscs } from '../../composables/useBudgetGroupPlanBoardMiscs';
import { useSearchConditions } from '../../composables/useSearchConditions';
import { useStaffShiftOverview } from '../../composables/useStaffShiftOverview';

export function useSearch(): { isLoading: Ref<boolean>; handleSearch: () => Promise<void> } {
  const isLoading = ref(false);
  const {
    dates,
    budgetGroup,
    shiftStatus,
    macroOperationMasters,
    staffLabels,
    isKeyPlayer,
    isForkman,
    hasCustomSkill1,
    hasCustomSkill2,
    hasCustomSkill3,
  } = useSearchConditions();
  const { fetchStaffShiftOverview } = useStaffShiftOverview();
  const { fetchBudgetGroupPlanBoardMiscs } = useBudgetGroupPlanBoardMiscs();
  const handleSearch = async () => {
    if (budgetGroup.value === null || dates.value.length === 0) {
      return;
    }

    try {
      isLoading.value = true;
      await Promise.all([
        fetchStaffShiftOverview({
          budgetGroup: budgetGroup.value,
          dates: dates.value,
          shiftStatus: shiftStatus.value,
          macroOperationMasters: macroOperationMasters.value,
          staffLabels: staffLabels.value,
          isKeyPlayer: isKeyPlayer.value,
          isForkman: isForkman.value,
          hasCustomSkill1: hasCustomSkill1.value,
          hasCustomSkill2: hasCustomSkill2.value,
          hasCustomSkill3: hasCustomSkill3.value,
        }),
        fetchBudgetGroupPlanBoardMiscs({
          budgetGroup: budgetGroup.value,
          dates: dates.value,
        }),
      ]);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    handleSearch,
  };
}
