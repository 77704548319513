import { TimelineIndexResponse } from 'src/models/api/timelineResponse';
import { ProgressHeader, convertProgressHeaderResponse } from './progressHeader';

export interface TimelineIndexResult {
  // workplace_extensionはstoreから取る
  // plan_board_blocksが入っている想定
  progress_headers: ProgressHeader[]
}

export function convertTimelineIndexResult(data: TimelineIndexResponse): TimelineIndexResult {
  return {
    progress_headers: data.progress_headers.map(convertProgressHeaderResponse),
  }
}
