















import { defineComponent, type PropType } from '@vue/composition-api'
import { type ProgressDetail } from 'src/models/ProgressHeader'
import { orDefault } from 'src/util/filters'
import { formatTime, formatNumber, formatFixedDecimal } from '../../utils/filters'
import ProgressDetailListRow from './ProgressDetailListRow.vue'
import ProgressDetailListCell from './ProgressDetailListCell.vue'
import ProgressDetailListControls from './ProgressDetailListControls.vue'

export default defineComponent({
  filters: {
    orDefault,
    formatTime,
    formatNumber,
    formatFixedDecimal,
  },
  components: {
    ProgressDetailListRow,
    ProgressDetailListCell,
    ProgressDetailListControls,
  },
  props: {
    progressDetail: {
      type: Object as PropType<ProgressDetail>,
      required: true,
    },
    cumulativeQuantity: {
      type: Number,
      required: true,
    },
    onEdit: {
      type: Function as PropType<(progressDetail: ProgressDetail) => unknown>,
      required: true,
    },
    onDelete: {
      type: Function as PropType<(progressDetail: ProgressDetail) => unknown>,
      required: true,
    },
  },
})
