import { reloadOpener as reloadOpenerHook, loadOnOpener as loadOnOpenerHook } from 'src/hooks/windowHook';

export default {
  methods: {
    reloadOpener() {
      reloadOpenerHook();
    },
    loadOnOpener(url) {
      loadOnOpenerHook(url);
    },
  },
};
