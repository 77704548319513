
import Draggable from 'vuedraggable'
import timetableLabelApi from 'src/apis/workplace_masters/timetable_label'
import ListSwitchPanel from '../ListSwitchPanel.vue'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { computed, ComputedRef, defineComponent, getCurrentInstance, onMounted, reactive } from '@vue/composition-api'
import { vvGetError, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { TimetableLabel } from 'src/models/timetableLabel'
import { isExist } from 'src/util/isExist'
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { CreateOptParams as _CreateOptParams, UpdateOptParams as _UpdateOptParams } from 'src/models/api/timetableLabelRequest'

const displayPageName = '工程ラベル'
const msgVars = { create: '作成', update: '編集', delete: '削除', update_disp_order: '表示順変更' }

type PaginationProps = {
  perPage: number
  currentPage: number
  total: number
}
type CreateOptParams = _CreateOptParams & { isNew: true }
type UpdateOptParams = _UpdateOptParams & { isNew: false }
type SaveCandidate =
  CreateOptParams |
  UpdateOptParams
type DeleteCandidate = TimetableLabel

type State = {
  list: TimetableLabel[],
  saveCandidate: SaveCandidate,
  deleteCandidate: DeleteCandidate | null,
  showSaveModal: boolean,
  showDeleteModal: boolean,
  pagination: PaginationProps,
  displayListDisabledItem: boolean,
}

const initialSaveCandidate: CreateOptParams = {
  isNew: true,
  name: '',
  is_enabled: true,
}

function setupState(): State {
  return reactive({
    list: [],
    saveCandidate: initialSaveCandidate,
    deleteCandidate: null,
    showSaveModal: false,
    showDeleteModal: false,
    pagination: {
      perPage: 50,
      currentPage: 1,
      total: 0,
    },
    displayListDisabledItem: false,
  });
}

const TimetaleLabel = defineComponent({
  components: { Draggable, ListSwitchPanel },
  setup() {
    const vueInstance = getCurrentInstance()?.proxy.$root!
    const state = setupState()

    const userId: ComputedRef<number> = wrappedMapGetters(
      vueInstance.$store,
      'user',
      ['id']
    ).id

    const workplaceId = computed(() => {
      return vueInstance.$route.params.workplaceId
    })

    const hasList = computed(() => {
      return state.list.length > 0
    })

    const paginatedList = computed(() => {
      const startIdx = state.pagination.perPage * Math.max((state.pagination.currentPage - 1), 0)
      return state.list.slice(startIdx, startIdx + state.pagination.perPage)
    })

    const shouldPaginate = computed(() => {
      return state.pagination.total > state.pagination.perPage
    })

    const hasError = computed(() => {
      return vvHasError(vueInstance)
    })

    const validations = computed(() => {
      const ruleStr = { required: true, max: 30 }
      return {
        name: ruleStr,
      }
    })

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName)
    }

    function clearErrors() {
      vvReset(vueInstance)
    }

    async function getList() {
      const timetableLabels = await timetableLabelApi.index({
        workplaceId: workplaceId.value,
        params: {
          is_enabled: state.displayListDisabledItem ? undefined : true
        },
      })

      state.list = timetableLabels
      state.pagination.total = timetableLabels.length
    }

    async function getListWithUpdatedSearchParams(params: {displayDisabledModel: boolean}) {
      state.displayListDisabledItem = params.displayDisabledModel
      await getList()
    }

    async function openSaveModal(item?: TimetableLabel) {
      let saveCandidate: SaveCandidate
      if (!isExist(item)) {
        saveCandidate = initialSaveCandidate
      } else {
        saveCandidate = JSON.parse(JSON.stringify(item))
      }

      state.saveCandidate = saveCandidate
      state.showSaveModal = true
    }

    function closeSaveModal() {
      state.saveCandidate = initialSaveCandidate
      state.showSaveModal = false
      clearErrors()
    }

    async function _saveItem() {
      if (!state.saveCandidate) { return; }
      const isValid = await vvValidate(vueInstance);
      if (!isValid) { return }

      const opType = state.saveCandidate.isNew ? 'create' : 'update'
      try {
        if (state.saveCandidate.isNew) {
          await timetableLabelApi.create({
            workplaceId: workplaceId.value,
            data: state.saveCandidate,
          })
        } else {
          await timetableLabelApi.update({
            workplaceId: workplaceId.value,
            data: state.saveCandidate,
          })
        }
        getList()
        closeSaveModal()
        notifySuccess1(vueInstance, `${displayPageName}を${msgVars[opType]}しました`)
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'dup_name') {
          const msg = 'そのラベル名は既に使用されています。'
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 })
        } else {
          const errId = state.saveCandidate.isNew ? 'ERR00001' : 'ERR00002'
          const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            '管理者に連絡してください。' +
            `(ERR: ${displayPageName} ${errId}, user_id:${userId.value})`
          notifyError1(vueInstance, msg, { err })
        }
      }
    }

    function openDeleteModal(item: TimetableLabel) {
      state.deleteCandidate = item
      state.showDeleteModal = true
    }

    function closeDeleteModal() {
      state.deleteCandidate = null
      state.showDeleteModal = false
    }

    async function _deleteItem() {
      if (!state.deleteCandidate) { return; }
      const opType = 'delete'
      try {
        const reqObj = {
          workplaceId: workplaceId.value,
          itemId: state.deleteCandidate.id,
        }
        await timetableLabelApi[opType](reqObj)
        await getList()
        closeDeleteModal()
        notifySuccess1(vueInstance, `${displayPageName}を${msgVars[opType]}しました`)
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'すでに使われているマスタです。削除できません。' +
            '無効化をおすすめします。'
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00003'
          const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
            '管理者に連絡してください。' +
            `(ERR: ${displayPageName} ${errId}, user_id:${userId.value})`
          notifyError1(vueInstance, msg, { err })
        }
      }
    }

    function isDraggable() {
      return true
    }

    async function _updateDispOrder() {
      const opType = 'update_disp_order'
      try {
        state.list.forEach((e, i) => {
          e.disp_order = i + 1
        })
        const reqObj = {
          workplaceId: workplaceId.value,
          items: state.list,
        }
        await timetableLabelApi.bulkUpdateDispOrder(reqObj)
        await getList()
      } catch (err: any) {
        const errId = 'ERR00004'
        const msg = `${displayPageName}の${msgVars[opType]}に失敗しました。` +
          '管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${userId.value})`
        notifyError1(vueInstance, msg, { err })
      }
    }

    onMounted(async() => {
      setPageName(vueInstance, displayPageName)
      await getList()
    })

    const gatedFuncGenerator = getGatedFuncGenerator();
    return {
      displayPageName: displayPageName,
      searchParams: {
        selectedGroup: null,
        displayListDisabledItem: false,
      },
      state,
      hasList,
      paginatedList,
      shouldPaginate,
      hasError,
      validations,
      // methods
      getError,
      getListWithUpdatedSearchParams,
      openSaveModal,
      closeSaveModal,
      isDraggable,
      openDeleteModal,
      closeDeleteModal,
      saveItem: gatedFuncGenerator.makeAsyncFuncGated(_saveItem),
      deleteItem: gatedFuncGenerator.makeAsyncFuncGated(_deleteItem),
      updateDispOrder: gatedFuncGenerator.makeAsyncFuncGated(_updateDispOrder),

      pagination: {
        perPage: 50,
        currentPage: 1,
        total: 0,
      },

      dragOptions: {
        handle: '.grabbable',
        animation: 300,
      },
    }
  },
})
export default TimetaleLabel
