
import { defineComponent, inject } from '@vue/composition-api'
import { ConfirmModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/components/modal/ConfirmModal/useConfirmModal'
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift'

export default defineComponent({
  name: 'confirm-modal',
  setup() {
    const modalState = inject(ConfirmModalDisplayStateKey);
    const staffWithShiftState = inject(StaffWithShiftsStateKey);
    if (!modalState || !staffWithShiftState) {
      throw new Error('State Error');
    }

    const {
      isDisplayConfirmModal,
      closeConfirmModal,
      confirmCallback,
      confirmTitle,
      confirmMessage,
      runButtonLabel,
      customClass,
    } = modalState

    const execute = () => {
      try {
        confirmCallback.value();
      } catch {
      }
    }

    return {
      confirmTitle,
      confirmMessage,
      runButtonLabel,
      customClass,
      isDisplayConfirmModal,
      close: closeConfirmModal,
      execute,
    }
  }
})
