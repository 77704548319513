








import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups';
import { useTimetableLabelsProvider } from 'src/composables/useTimetableLabels';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { Content } from '../Content';
import { ControlPanel } from '../ControlPanel';
import { MainPanel } from '../MainPanel';
import { useProgressHeadersProvider } from '../../composables/useProgressHeaders';
import { useCurrentStaffWorkProvider } from '../../composables/useCurrentStaffWork';
import { useSearchConditionsProvider, useSearchConditions } from '../../composables/useSearchConditions';
import { useMemoProvider } from '../../composables/useMemo';

export default defineComponent({
  components: {
    Content,
    ControlPanel,
    MainPanel,
  },
  setup(_, context) {
    const root = context.root as Vue;
    const workplaceId = Number(root.$route.params.workplaceId);

    useSearchConditionsProvider();
    const { dt, budgetGroup, timetableLabels } = useSearchConditions();

    setPageName(root, 'パフォーマンスボード 一覧');
    useBudgetGroupsProvider({ workplaceId });
    useTimetableLabelsProvider({ workplaceId });
    useProgressHeadersProvider({ workplaceId, dt, budgetGroup, timetableLabels });
    useCurrentStaffWorkProvider({ workplaceId, dt, budgetGroup });
    useMemoProvider({ workplaceId, dt, budgetGroup });
  },
});
