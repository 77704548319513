












import Vue from 'vue'
import { defineComponent } from '@vue/composition-api'
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups'
import { useTimetableLabelsProvider } from 'src/composables/useTimetableLabels'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { useProgressHeadersProvider } from '../../composables/useProgressHeaders'
import { useSearchConditionsProvider } from '../../composables/useSearchConditions'
import { useTimetableMastersProvider } from '../../composables/useTimetableMasters'
import { useProgressDetailListModalProvider } from '../../composables/useProgressDetailListModal'
import { useCreateProgressDetailProvider } from '../../composables/useCreateProgressDetail'
import { useUpdateProgressDetailProvider } from '../../composables/useUpdateProgressDetail'
import { useDeleteProgressDetailProvider } from '../../composables/useDeleteProgressDetail'
import { Content } from '../Content'
import { ControlPanel } from '../ControlPanel'
import { MainPanel } from '../MainPanel'
import { ProgressDetailListModal } from '../ProgressDetailListModal'
import { ProgressDetailCreateModal } from '../ProgressDetailCreateModal'
import { ProgressDetailUpdateModal } from '../ProgressDetailUpdateModal'
import { ProgressDetailDeleteConfirmationModal } from '../ProgressDetailDeleteConfirmationModal'

export default defineComponent({
  components: {
    Content,
    ControlPanel,
    MainPanel,
    ProgressDetailListModal,
    ProgressDetailCreateModal,
    ProgressDetailUpdateModal,
    ProgressDetailDeleteConfirmationModal,
  },
  setup(_, context) {
    const root = context.root as Vue
    const workplaceId = Number(root.$route.params.workplaceId)

    setPageName(root, 'パフォーマンスボード 詳細')
    useBudgetGroupsProvider({ workplaceId })
    useTimetableLabelsProvider({ workplaceId })
    useTimetableMastersProvider({ workplaceId })
    useSearchConditionsProvider({ workplaceId })
    useProgressHeadersProvider()
    useProgressDetailListModalProvider()
    useCreateProgressDetailProvider()
    useUpdateProgressDetailProvider()
    useDeleteProgressDetailProvider()
  },
})
