import { PlanProgressBlock } from 'src/models/planProgressBlock'
import { isExist } from './isExist'
import { packToTimeInteger, unpackTimeInteger } from './datetime'

type History = {
  id: number
  timetable_header_id: number
  time: string
  type: string
  quantity: number
}

type HistoryEx = History & {
  diff: number
}

export type AmountData = {
  currentResultAmount: number | null
  currentScheduledAmount: number | null
  lastScheduledAmount: number | null
  lastScheduledAmountIndex: number | null
  progressRate: number | null
}

export const getAmountData = (budgets: PlanProgressBlock[], currentBudgetIndex: number): AmountData => {
  let currentResultAmount: AmountData['currentResultAmount'] = null
  let currentScheduledAmount: AmountData['currentScheduledAmount'] = null
  let lastScheduledAmount: AmountData['lastScheduledAmount'] = null
  let lastScheduledAmountIndex: AmountData['lastScheduledAmountIndex'] = -1

  budgets.forEach((budget, i) => {
    const resultAmount = budget.result_amount
    const scheduledAmount = budget.scheduled_amount
    if (isExist(resultAmount) && i <= currentBudgetIndex) {
      currentResultAmount = Number(resultAmount)
    }
    if (isExist(scheduledAmount)) {
      lastScheduledAmount = Number(scheduledAmount)
      lastScheduledAmountIndex = i
      if (i <= currentBudgetIndex) {
        currentScheduledAmount = Number(scheduledAmount)
      }
    }
  })

  return {
    currentResultAmount,
    currentScheduledAmount,
    lastScheduledAmount,
    lastScheduledAmountIndex,
    progressRate: (isExist(currentResultAmount) && isExist(lastScheduledAmount) && lastScheduledAmount > 0)
      ? currentResultAmount / lastScheduledAmount * 100
      : null,
  }
}

export type HeadCountData = {
  currentHeadCount: number | null
  sumResultHeadCount: number | null
  sumScheduledHeadCount: number | null
  sumScheduledHeadCountFromCurrent: number | null
  sumScheduledHeadCountToCurrent: number | null
}

export type PredictedCompleteData = {
  predictedCompleteTime: string
  predictedCompleteAmount: string
}

export const getHeadCountData = (budgets: PlanProgressBlock[], currentBudgetIndex: number): HeadCountData => {
  let currentHeadCount: HeadCountData['currentHeadCount'] = null
  let sumResultHeadCount: number = 0
  let sumScheduledHeadCount: number = 0
  let sumScheduledHeadCountFromCurrent: number = 0
  let sumScheduledHeadCountToCurrent: number = 0

  budgets.forEach((budget, i) => {
    const resultHeadCount = isExist(budget.result_headcount) ? Number(budget.result_headcount) : null
    const scheduledHeadCount = isExist(budget.scheduled_headcount) ? Number(budget.scheduled_headcount) : null
    if (isExist(resultHeadCount) && i <= currentBudgetIndex) {
      sumResultHeadCount += resultHeadCount
      currentHeadCount = resultHeadCount
    }
    if (isExist(scheduledHeadCount)) {
      sumScheduledHeadCount += scheduledHeadCount
      if (i > currentBudgetIndex) {
        sumScheduledHeadCountFromCurrent += scheduledHeadCount
      } else {
        sumScheduledHeadCountToCurrent += scheduledHeadCount
      }
    }
  })

  return {
    currentHeadCount,
    sumResultHeadCount: sumResultHeadCount === 0 ? null : sumResultHeadCount,
    sumScheduledHeadCount: sumScheduledHeadCount === 0 ? null : sumScheduledHeadCount,
    sumScheduledHeadCountFromCurrent: sumScheduledHeadCountFromCurrent === 0 ? null : sumScheduledHeadCountFromCurrent,
    sumScheduledHeadCountToCurrent: sumScheduledHeadCountToCurrent,
  }
}

export const getProductivity = (currentResultAmount: number | null, sumResultHeadCount: number | null, amountWorkBeforehand: number): number | null => {
  if (!isExist(currentResultAmount) || !isExist(sumResultHeadCount)) {
    return null
  }
  if (sumResultHeadCount === 0) {
    return null
  }
  return (currentResultAmount - amountWorkBeforehand) / sumResultHeadCount
}

export const getCurrentProductivity = (budgets: PlanProgressBlock[], currentBudgetIndex: number): number => {
  if (currentBudgetIndex < 0) { return 0 }

  const currentAmountData = getAmountData(budgets, currentBudgetIndex)
  const preAmountData = getAmountData(budgets, currentBudgetIndex - 1)
  const currentHeadCountData = getHeadCountData(budgets, currentBudgetIndex)
  if (isExist(currentAmountData.currentResultAmount) &&
      isExist(currentHeadCountData.currentHeadCount) &&
      currentHeadCountData.currentHeadCount !== 0 &&
      isExist(preAmountData.currentResultAmount) &&
      // 隣同士の数量が同じ場合は次の隣の生産性と同じ値になるため、再帰処理をコールする
      currentAmountData.currentResultAmount !== preAmountData.currentResultAmount
  ) {
    return (currentAmountData.currentResultAmount - preAmountData.currentResultAmount) / currentHeadCountData.currentHeadCount
  }
  return getCurrentProductivity(budgets, currentBudgetIndex - 1)
}

export type TimetableBudgetEx = PlanProgressBlock & {
  predictedAmount?: number | null
  predictedHeadCount?: string | null
  diffScheduledAmount?: number | null
  diffResultAmount?: number | null
  productivityByTime?: number | null
}

export const createTimetableBudgets = (_budgets: PlanProgressBlock[], amountWorkBeforehand: number): TimetableBudgetEx[] => {
  let lastScheduledAmount: number = amountWorkBeforehand
  let lastScheduledAmountIndex: number = -1
  let lastResultAmount: number = amountWorkBeforehand
  let lastResultAmountIndex: number = -1
  let lastScheduledHeadCountIndex: number = -1
  let lastScheduledHeadCount: number | null = null
  let lastResultHeadCountIndex: number = -1
  let lastResultHeadCount: number | null = null
  const currentBudgetIndex = _budgets.reduce((index, budget, i) => isExist(budget.result_amount) ? i : index, -1)
  const budgets = _budgets.concat().map<TimetableBudgetEx>((budget, i) => {
    const _scheduledAmount = budget.scheduled_amount
    const _resultAmount = budget.result_amount
    const _scheduledHeadCount = budget.scheduled_headcount
    const _resultHeadChount = budget.result_headcount
    let diffScheduledAmount: number | null = 0
    let diffResultAmount: number | null = 0
    if (isExist(_scheduledAmount)) {
      const scheduledAmount = Number(_scheduledAmount)
      diffScheduledAmount = scheduledAmount - lastScheduledAmount
      lastScheduledAmount = scheduledAmount
      lastScheduledAmountIndex = i
    }
    if (isExist(_resultAmount)) {
      const resultAmount = Number(_resultAmount)
      diffResultAmount = resultAmount - lastResultAmount
      lastResultAmount = resultAmount
      lastResultAmountIndex = i
    }
    if (isExist(_scheduledHeadCount)) {
      lastScheduledHeadCount = Number(_scheduledHeadCount)
      lastScheduledHeadCountIndex = i
    }
    if (isExist(_resultHeadChount)) {
      // ※ 投下工数の最後の実績値は、作業量の最後の実績値が入っていたところまでを計算に利用する
      // see: https://github.com/kurando-inc/logiboard-ap/issues/272
      if (i <= lastResultAmountIndex || lastResultAmountIndex === -1) {
        lastResultHeadCount = Number(_resultHeadChount)
        lastResultHeadCountIndex = i
      }
    }
    return {
      ...budget,
      diffScheduledAmount,
      diffResultAmount,
    }
  })

  createProductivityByTime(budgets)
  const productivityByTime = budgets[currentBudgetIndex]?.productivityByTime

  let isAlreadyReachedScheduledAmount:boolean = isExist(lastResultAmount) && isExist(lastScheduledAmount) && lastResultAmount >= lastScheduledAmount
  let lastPredictedAmount: number = lastResultAmount ?? amountWorkBeforehand
  let lastPredictedHeadCount: number | null = null
  return budgets.map((budget, i) => {
    if (i > lastScheduledAmountIndex) {
      budget.diffScheduledAmount = null
    }
    if (i > lastResultAmountIndex) {
      budget.diffResultAmount = null
    }
    // すでに目標数へ到達している場合は予測数値は表示しない
    if (isAlreadyReachedScheduledAmount) { return budget }
    // 目標数がない場合は予測が立てられないので予測値は表示しない
    if (!isExist(lastScheduledAmount) || lastScheduledAmount === 0) { return budget }
    // 生産性がない場合は予測が立てられないので予測値は表示しない
    if (!isExist(productivityByTime) || productivityByTime === 0) { return budget }

    // 投下工数の予測
    let predictedHeadCount
    const scheduledHeadCount: number | null = isExist(budget.scheduled_headcount) ? Number(budget.scheduled_headcount) : null
    const resultHeadCount: number | null = isExist(budget.result_headcount) ? Number(budget.result_headcount) : null
    // 最後の作業量の実績値までは null
    if (i <= lastResultAmountIndex) {
      predictedHeadCount = null
      // 最後の実績値までは、実績値があればその値を予測値とする
    } else if (i <= lastResultHeadCountIndex) {
      predictedHeadCount = resultHeadCount
      // 最後の計画値までは、計画値があればその値を予測値とする
    } else if (i <= lastScheduledHeadCountIndex) {
      predictedHeadCount = scheduledHeadCount
      // 最後の計画値以降は、最後の予測値 or 最後の実績値を予測値とする
    } else {
      if (isExist(lastPredictedHeadCount)) {
        predictedHeadCount = lastPredictedHeadCount
      } else if (isExist(lastScheduledHeadCount) && (!isExist(lastResultHeadCount) || lastScheduledHeadCountIndex > lastResultHeadCountIndex)) {
        predictedHeadCount = lastScheduledHeadCount
      } else {
        predictedHeadCount = lastResultHeadCount ?? resultHeadCount
      }
    }
    lastPredictedHeadCount = predictedHeadCount

    // 作業量の予測
    let predictedAmount
    let diffResultAmount: number | null | undefined = 0
    // 最後の実績値までは null
    if (i <= lastResultAmountIndex) {
      predictedAmount = null
      diffResultAmount = budget.diffResultAmount
      // 最後の実績値以降は、予測に必要な値が存在しない場合は null
    } else if (!isExist(predictedHeadCount)) {
      predictedAmount = null
    } else {
      // 前回の値 + 投下工数の予測値 * 生産性
      predictedAmount = Math.round(lastPredictedAmount + predictedHeadCount * productivityByTime)

      // 予測値が最終計画値を超えた場合
      if (isExist(lastScheduledAmount) && predictedAmount >= lastScheduledAmount) {
        isAlreadyReachedScheduledAmount = true
        // 作業量の最終計画時刻を越えている場合は必要最低限の人数になるように再計算する
        if (i > lastScheduledAmountIndex) {
          predictedAmount = lastScheduledAmount
          predictedHeadCount = (predictedAmount - lastPredictedAmount) / productivityByTime
        }
      }
      diffResultAmount = predictedAmount - lastPredictedAmount
      lastPredictedAmount = predictedAmount
    }
    return {
      ...budget,
      predictedAmount: predictedAmount ?? undefined,
      predictedHeadCount: predictedHeadCount?.toFixed(1),
      diffResultAmount,
    }
  })
}

const createProductivityByTime = (budgets: TimetableBudgetEx[]): void => {
  budgets.forEach((budget, i) => {
    // 作業量のみ、工数のみのセルは時間帯別生産性は-（null）とする
    if (!isExist(budget.diffResultAmount) || !isExist(budget.result_headcount)) {
      budget.productivityByTime = null
      return
    }
    if (budget.diffResultAmount === 0 || budget.result_headcount === 0) {
      budget.productivityByTime = 0
    } else {
      budget.productivityByTime = budget.diffResultAmount / budget.result_headcount
    }
  })
}

export const getPredictedCompleteData = (
  budgets: TimetableBudgetEx[],
  productivity: number | null,
  lastScheduledAmount: number | null,
  targetTime: number | null,
  currentBudgetIndex: number,
): PredictedCompleteData => {
  if (!isExist(productivity)) {
    return { predictedCompleteTime: '-', predictedCompleteAmount: '-' }
  }
  let lastResultHeadcount: number = 0
  let lastPredictedAmount: number = 0
  let lastBudgetIndex: number = -1
  budgets.forEach((budget, i) => {
    if (isExist(budget.result_headcount)) {
      lastResultHeadcount = Number(budget.result_headcount)
    }
    if (isExist(budget.predictedAmount)) {
      lastPredictedAmount = budget.predictedAmount
      lastBudgetIndex = i
    }
  })
  if (lastBudgetIndex < 0) {
    return { predictedCompleteTime: '-', predictedCompleteAmount: '-' }
  }
  const lastBudget = budgets[lastBudgetIndex]
  const secondFromTheLastBudget = budgets[lastBudgetIndex - 1]
  const headCount = Number(lastBudget.scheduled_headcount) || Number(secondFromTheLastBudget?.predictedHeadCount) || lastResultHeadcount || Math.max(Number(lastBudget.predictedHeadCount) || 0, Number(lastBudget.result_headcount) || 0)
  const predictedAmount = lastScheduledAmount! - lastBudget.predictedAmount! + lastBudget.diffResultAmount!
  const predictedMin = Math.ceil(predictedAmount / productivity / headCount * 60)
  const [targetTimeHour, targetTimeMin] = isExist(targetTime) ? unpackTimeInteger(targetTime) : [null, null]
  if (predictedMin >= 60) {
    return {
      predictedCompleteTime: `${lastBudget.hour + 1}:00`,
      predictedCompleteAmount: calculateRemainingAmount(budgets, lastScheduledAmount, targetTimeHour, targetTimeMin, currentBudgetIndex)
    }
  }

  return {
    predictedCompleteTime: `${lastBudget.hour}:${`00${predictedMin}`.slice(-2)}`,
    predictedCompleteAmount: calculateRemainingAmount(budgets, lastScheduledAmount, targetTimeHour, targetTimeMin, currentBudgetIndex)
  }
}

const calculateRemainingAmount = (
  budgets: TimetableBudgetEx[],
  lastScheduledAmount: number | null,
  targetTimeHour: number | null,
  targetTimeMin: number | null,
  currentBudgetIndex: number
): string => {
  // 完了目標がない場合は計算不可
  if (!isExist(targetTimeHour)) { return '-' }
  if (!isExist(targetTimeMin)) { return '-' }
  // 想定外の完了目標は計算不可
  if (targetTimeHour < 1 || targetTimeHour > 47) { return '-' }
  // 最後の予測数量がない場合は計算不可
  if (!isExist(lastScheduledAmount)) { return '-' }
  // 完了目標時刻を過ぎているか完了目標となっている時間のセルに実績が入った場合はハイフンとする
  if (
    // 現在時刻の時間部分がhourより大きい
    targetTimeMin === 0 && (targetTimeHour - 1) < currentBudgetIndex ||
    // 現在時刻の時間部分がhour以上の場合かつ目標時刻での実績が存在する場合
    targetTimeMin > 0 && (targetTimeHour - 1) <= currentBudgetIndex && budgets[targetTimeHour]?.result_amount
  ) {
    return '-'
  }

  const secondFromTheTargetBudget = budgets[targetTimeHour - 1]
  // 実績、予測の優先順で値があるものを使う
  const doneAmount: number =
    secondFromTheTargetBudget.result_amount ??
    secondFromTheTargetBudget.predictedAmount ??
    -1
  if (doneAmount === -1) { return '0' }

  let doneAmount2 = 0
  // 完了目標が00分以外の場合は、その時間帯で実施する作業量を分単位で按分する
  if (
    targetTimeMin > 0 &&
    isExist(secondFromTheTargetBudget.productivityByTime) &&
    isExist(secondFromTheTargetBudget.result_headcount)
  ) {
    // 直近生産性 * 直前の工数実績 * 時間按分により完了直前の予想作業量を計算する
    doneAmount2 = secondFromTheTargetBudget.productivityByTime * secondFromTheTargetBudget.result_headcount * (targetTimeMin / 60)
  }
  // 残数を返す
  return Math.max(lastScheduledAmount - doneAmount - doneAmount2, 0).toFixed(0)
}

export const createHistories = (_histories: History[]): HistoryEx[] => {
  let lastQuantity: number = 0
  let lastQuantityIndex: number = -1

  const histories: HistoryEx[] = _histories.concat().map((history, i) => {
    let diffQuantity: number | null = 0

    if (isExist(history.quantity)) {
      const Quantity = Number(history.quantity)
      diffQuantity = Quantity - lastQuantity
      lastQuantity = Quantity
      lastQuantityIndex = i
    }
    return {
      ...history,
      diff: diffQuantity
    }
  })

  return histories
}

export const colors = { blue: '#8497B0', green: '#70AD47', yellow: '#FFC000', red: '#ED7D31', gray: '#bfbfbf', black: '#252422' }

export const statusColor = (numerator: number | null, denominator: number | null) => {
  if (!isExist(numerator) || !isExist(denominator) || denominator <= 0) {
    return colors.black
  }
  const num = numerator / denominator
  if (num > 0.9) { // 90% 以上の時
    return colors.blue
  } else if (num > 0.8) { // 80% 以上の時
    return colors.yellow
  }
  // それ以下
  return colors.red
}

const productivityChartLiteralMap = {
  fast: { lowerBound: 110, text: '高速', color: colors.blue },
  mid1: { lowerBound: 90, text: '適速', color: colors.green },
  mid2: { lowerBound: 70, text: '中速', color: colors.yellow },
  slow: { lowerBound: 0, text: '低速', color: colors.red },
}
const productivityChartOrderedLiterals = [
  productivityChartLiteralMap.fast,
  productivityChartLiteralMap.mid1,
  productivityChartLiteralMap.mid2,
  productivityChartLiteralMap.slow,
]

export const currentProductivityRateColor = (rate: number | null) => {
  if (rate === 0 || rate === null) return colors.gray

  for (const ent of productivityChartOrderedLiterals) {
    if (rate >= ent.lowerBound) { return ent.color }
  }

  return colors.gray
}

export const currentProductivityRank = (rate: number | null) => {
  if (rate === 0 || rate === null) return '停止'

  for (const ent of productivityChartOrderedLiterals) {
    if (rate >= ent.lowerBound) { return ent.text }
  }

  return ''
}

export const getPredictedCompleteTimeColor = (predictedCompleteTimeStr: string, targetTime: number | null) => {
  if (predictedCompleteTimeStr === '-' || !isExist(targetTime)) {
    return colors.black
  }
  const [predictedCompleteTimeHour, predictedCompleteTimeMin] = predictedCompleteTimeStr.split(':')
  const predictedCompleteTime = packToTimeInteger(Number(predictedCompleteTimeHour), Number(predictedCompleteTimeMin), 0)
  return colors[targetTime < predictedCompleteTime ? 'red' : 'blue']
}
