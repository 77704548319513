
import { defineComponent, inject, reactive, toRefs, computed, ComputedRef, watch } from '@vue/composition-api';
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal';
import { DisplayConditionStateKey, State } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useDisplayCondition';
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState';
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift';
import { SlideYUpTransition } from 'vue2-transitions';
import { SHIFT_PHASE } from 'src/consts';
import { LovEntry } from 'src/models/master';
import { addDays, parse } from 'date-fns';
import {
  copyDisplayConditionToState,
  copyStateToDisplayCondition,
} from 'src/views/Dashboard/Workplace/RegularShift/libs/displayConditionHelper';
import { generateEmptyTime } from 'src/views/Dashboard/Workplace/RegularShift/utils';

const { PROVISIONAL, SCHEDULED, ACTUAL } = SHIFT_PHASE;

type ValidateState = {
  validOverTimeAt7Days: ComputedRef<boolean>;
  validOverTimeAtMonth: ComputedRef<boolean>;
  validWageAtMonth: ComputedRef<boolean>;
  validStartTime: ComputedRef<boolean>;
  validEndTime: ComputedRef<boolean>;
  validWorkingTime: ComputedRef<boolean>;
};

export default defineComponent({
  name: 'display-condition-setting-modal',
  components: {
    SlideYUpTransition,
  },
  setup() {
    const modalState = inject(ModalDisplayStateKey);
    const displayConditionState = inject(DisplayConditionStateKey);
    const commonState = inject(CommonStateKey);
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    if (!modalState || !displayConditionState || !commonState || !staffWithShiftsState) {
      throw new Error('State Error');
    }

    const { isDisplayConditionSettingModal, closeDisplayConditionSettingModal } = modalState;

    const { staffAgencies, attendanceTypes, baseDate, macroOperationMasters } = commonState;

    const { isDetailDisplayConditionValid, resetCheck } = staffWithShiftsState;

    const upDownSelector = [
      { value: true, name: '以上' },
      { value: false, name: '以下' },
    ];

    const shiftTypeSelector = [
      { value: PROVISIONAL, name: '仮シフト' },
      { value: SCHEDULED, name: '確定シフト' },
      { value: ACTUAL, name: '出勤実績' },
    ];

    const attendanceTypeSelector = computed(() =>
      attendanceTypes.value.map((attendanceType: LovEntry) => {
        return { value: Number(attendanceType.key), name: attendanceType.name };
      }),
    );

    const state = reactive<State>({
      targetDate: baseDate.value,
      staffName: null,
      macroOperationMaster: null,
      staffAgency: null,
      overTimeAt7Days: null,
      isMoreOverTimeAt7Days: { value: true, name: '以上' },
      overTimeAtMonth: null,
      isMoreOverTimeAtMonth: { value: true, name: '以上' },
      wageAtMonth: null,
      isMoreWageAtMonth: { value: true, name: '以上' },
      shiftType: { value: PROVISIONAL, name: '仮シフト' },
      attendanceType: null,
      startTime: generateEmptyTime(),
      endTime: generateEmptyTime(),
      workingTime: null,
      isMoreWorkingTime: { value: true, name: '以上' },
    });

    // 保存せずに戻った場合に途中の設定が残ってしまうので、displayConditionStateの値をstateに格納する
    // MEMO: 上の条件と合わせて、設定→未設定の場合に、以上以下や日付対象シフトは初期値があるのでそれを格納し直す
    watch([isDisplayConditionSettingModal], () =>
      copyDisplayConditionToState(state, displayConditionState, isDetailDisplayConditionValid.value, baseDate.value),
    );

    const clickActionButton = (): void => {
      // 表示条件を反映する
      copyStateToDisplayCondition(displayConditionState, state);
      // 表示条件を設定済みかのチェック
      isDetailDisplayConditionValid.value = displayConditionState.checkIsSetDisplayCondition();
      // チェックボックスを全て外す
      resetCheck();
      // モーダルをとじる
      closeDisplayConditionSettingModal();
    };

    // シフト条件のdate-pickerの対象範囲に無い日付をdisabledにする
    const datePickerOptions = {
      disabledDate(date: Date) {
        const year = baseDate.value.getFullYear().toString();
        const month = (baseDate.value.getMonth() + 1).toString();
        const day = baseDate.value.getDate().toString();
        const baseDateStr = `${year}-${month.length === 1 ? `0${month}` : month}-${day.length === 1 ? `0${day}` : day}`;
        const startDate = parse(baseDateStr, 'yyyy-MM-dd', new Date());
        const endDate = addDays(startDate, 10);

        return !(date >= startDate && date < endDate);
      },
    };

    // validation
    // true: 正常 false: 異常
    const validateState = reactive<ValidateState>({
      validOverTimeAt7Days: computed(() => determineValidHours(state.overTimeAt7Days)),
      validOverTimeAtMonth: computed(() => determineValidHours(state.overTimeAtMonth)),
      validWageAtMonth: computed(() => determineValidHours(state.wageAtMonth)),
      validStartTime: computed(() => determineValidTime(state.startTime.hour, state.startTime.min)),
      validEndTime: computed(() => determineValidTime(state.endTime.hour, state.endTime.min)),
      validWorkingTime: computed(() => determineValidHours(state.workingTime)),
    });
    const isClickableSettingButton = computed(() => Object.values(validateState).every((e) => e));

    const determineValidHours = (hour: number | null): boolean => {
      if (hour === null) {
        return true;
      }
      return hour >= 0;
    };

    const determineValidTime = (hour: string | null, min: string | null): boolean => {
      // start, minがどちらもnullの場合はtrue
      if (!hour && !min) {
        return true;
      }
      // start, minのどちらかがnullの場合はfalse
      if (!hour || !min) {
        return false;
      }

      // 時間が48:00以上が入力されていないかのチェック
      if (Number(hour) > 48) {
        return false;
      }
      if (Number(hour) === 48 && Number(min) !== 0) {
        return false;
      }

      // 値が整数かのチェック
      const isNumHour = Number(hour) % 1 === 0;
      const isNumMin = Number(min) % 1 === 0;
      if (!isNumHour || !isNumMin) {
        return false;
      }

      const isNormalHour = Number(hour) >= 0;
      const isNormalMin = Number(min) >= 0 && Number(min) < 60;

      return isNormalHour && isNormalMin;
    };

    return {
      isDisplayConditionSettingModal,
      closeDisplayConditionSettingModal,
      upDownSelector,
      clickActionButton,
      staffAgencies,
      shiftTypeSelector,
      attendanceTypeSelector,
      datePickerOptions,
      macroOperationMasters,
      ...toRefs(validateState),
      isClickableSettingButton,
      ...toRefs(state),
    };
  },
});
