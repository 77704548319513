import { onMounted, ref, getCurrentInstance, type Ref } from '@vue/composition-api'
import { ensureUserAndMasters } from 'src/hooks/masterHook'
import { type LovEntry } from 'src/models/master'

export function useDarkColorSet(): { darkColorSet: Ref<LovEntry[]> } {
  const root = getCurrentInstance()?.proxy!
  const darkColorSet = ref<LovEntry[]>([])

  onMounted(async() => {
    const { masters } = await ensureUserAndMasters(root)
    darkColorSet.value = masters.lovs.color_set.vals.filter(v => v.group === 'dark')
  })

  return {
    darkColorSet,
  }
}
