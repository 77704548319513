












import { defineComponent, PropType } from '@vue/composition-api';
import Sorter2 from 'src/components/Workplace/Sorter2.vue';
import { TimetableMasterSummary } from 'src/models/staffExperienceOverview';

export default defineComponent({
  components: {
    Sorter2,
  },
  props: {
    timetableMasterSummary: {
      type: Object as PropType<TimetableMasterSummary>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const style = { backgroundColor: '#' + props.timetableMasterSummary.timetable_master.disp_color };

    return {
      style,
    };
  },
});
