










import { defineComponent } from '@vue/composition-api'
import { useProgressHeaders } from '../../composables/useProgressHeaders'
import { ProgressHeaderPanelsList, ProgressHeaderPanelsListItem } from '../ProgressHeaderPanelsList'
import { ProgressHeaderPanels } from '../ProgressHeaderPanels'

export default defineComponent({
  components: {
    ProgressHeaderPanelsList,
    ProgressHeaderPanelsListItem,
    ProgressHeaderPanels,
  },
  setup() {
    const { progressHeaders } = useProgressHeaders()

    return {
      progressHeaders,
    }
  },
})
