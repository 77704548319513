import { TimeInteger } from 'src/models/common';
import { unpackTimeInteger } from 'src/util/datetime';

export const isValid15StepMinutes = (time: TimeInteger | null) => {
  if (time === null) {
    return false;
  }
  const [_, minutes] = unpackTimeInteger(time);
  return [0, 15, 30, 45].includes(minutes);
};
