






















import { computed, defineComponent, PropType } from '@vue/composition-api';
import { STAFF_GENDER, StaffGender, StaffGenderSelectOptions } from 'src/consts';

export default defineComponent({
  props: {
    value: {
      type: Number as PropType<StaffGender | null>,
      default: null,
    },
  },
  setup() {
    const staffGenderSelectOptions = computed(() => {
      return StaffGenderSelectOptions.filter((e) => e.id !== STAFF_GENDER.NO_ANSWER);
    });

    return {
      staffGenderSelectOptions,
    };
  },
});
