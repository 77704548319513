













import { defineComponent } from '@vue/composition-api'
import ProgressDetailListRow from './ProgressDetailListRow.vue'
import ProgressDetailListCell from './ProgressDetailListCell.vue'

export default defineComponent({
  components: {
    ProgressDetailListRow,
    ProgressDetailListCell,
  },
})
