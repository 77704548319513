
import Vue from 'vue';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import staffApi from 'src/apis/workplace_masters/staff';
import staffAgencyApi from 'src/apis/workplace_masters/staff_agency';
import staffLabelApi from 'src/apis/workplace_masters/staff_label';
import budgetGroupApi from 'src/apis/workplace_masters/budget_group';
import ListSwitchPanel from 'src/views/Dashboard/Workplace/Settings/ListSwitchPanel.vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { userHasWorkplaceRoleGteLeaderSync } from 'src/hooks/appRoleHook';
import { defineComponent, SetupContext, computed, reactive, onMounted, ref } from '@vue/composition-api';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { BudgetGroup } from 'src/models/budgetGroup';
import { StaffLabel } from 'src/models/staffLabel';
import { StaffAgency } from 'src/models/staffAgency';
import { Staff } from 'src/models/workplaceMasters/staff';
import { vvHasError, vvGetError, vvReset, vvValidate } from 'src/util/vee_validate';
import {
  packToTimeInteger,
  unpackTimeInteger,
  timeIntegerToSeconds,
  secondsToTimeInteger,
  unpackTimeIntegerToString,
} from 'src/util/datetime';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { Collapse, CollapseItem, Tabs, TabPane, Card } from 'src/components/UIComponents';
import * as C from 'src/consts';
import NonEditableCard from 'src/views/Dashboard/Workplace/Data/NonEditableCard.vue';
import { TimeInteger } from 'src/models/common';
import { setupStartEndValidator } from 'src/hooks/customValidatorHook';
import moment from 'src/util/moment-ja';
import CsvDownloadLargeButton from 'src/components/UIComponents/Buttons/CsvDownloadButton/CsvDownloadLargeButton.vue';
import Sorter2 from 'src/components/Workplace/Sorter2.vue';
import { SortSpec, useSorter2Container } from 'src/components/Workplace/Sorter2Container.vue';
import PaginationContainer from 'src/components/UIComponents/PaginationContainer.vue';
import { CsvLine } from 'src/util/type_util';
import { StaffImportResponse } from 'src/models/api/workplaceMasters/staffImportResponse';

interface StaffDisp extends Staff {
  fullName: string;
  fullNameKana: string;
  standardWorkingHoursInHours: number;
  overtimeExtraRatePercent: number;
  midnightExtraRatePercent: number;
  holidayExtraRatePercent: number;
  holidaysList: number[];
}

interface SaveCandidate {
  saveType: string;
  staff_number: string | null;
  staff_agency_id: number | null;
  staff_label_id: number | null;
  budget_group_id: number | null;
  hourly_cost: number | null;
  is_enabled: boolean | null;
  regular_start_time?: number | null;
  regular_start_time_hour?: string | null;
  regular_start_time_min?: string | null;
  regular_end_time?: number | null;
  regular_end_time_hour?: string | null;
  regular_end_time_min?: string | null;
  break_time?: number | null;
  break_time_hour?: string | null;
  break_time_min?: string | null;
  work_start_time_input_type: string | null;
  work_start_time_input_step: string | null;
  work_end_time_input_type: string | null;
  work_end_time_input_step: string | null;
  activity_time_input_type: string | null;
  activity_time_input_step: string | null;
  overtime_extra_rate?: number | null;
  standard_working_hours?: number | null;
  holiday_extra_rate?: number | null;
  holidays?: number[] | null;
  midnight_extra_rate?: number | null;
  midnight_time_start?: number | null;
  midnight_time_start_hour?: string | null;
  midnight_time_start_min?: string | null;
  midnight_time_end?: number | null;
  midnight_time_end_hour?: string | null;
  midnight_time_end_min?: string | null;
  items: {
    id: number;
  }[];
}

interface DeleteCandidate {
  id: number | null;
  staff_number: number | null;
  family_name: string | null;
  first_name: string | null;
}

interface WorkStartTimeInputStep {
  name: string;
  value: string;
}

interface WorkEndTimeInputStep {
  name: string;
  value: string;
}

interface ActivityTimeInputStep {
  name: string;
  value: string;
}

interface Pagination {
  perPage: number;
  currentPage: number;
  total: number;
}

interface State {
  pageName: string | null;
  userId: number | null;
  workplaceId: string;
  useBudgetGroup: boolean;
  useStaffLabel: boolean;
  useStaffAgency: boolean;
  useCreateAndUpdateInfo: boolean;
  msgVars: Record<string, string>;
  searchParams: {
    displayListDisabledItem: boolean;
  };
  search: {
    candidates: {
      staffs: Staff[];
      budgetGroups: BudgetGroup[];
      staffAgencies: StaffAgency[];
      staffLabels: StaffLabel[];
    };
    selected: {
      staff: Staff | null;
      staffNumber: string | null;
      budgetGroup: BudgetGroup | null;
      staffAgency: StaffAgency | null;
      staffLabel: StaffLabel | null;
    };
    query: {
      staff_number: string | null;
      staff_id: number | null;
      budget_group_id: number | null;
      staff_agency: number | null;
      staff_label: number | null;
    };
  };
  noBudgetUnitsMessage: string;
  opType: string;
  isGteLeader: boolean;
  list: StaffDisp[];
  saveCandidate: SaveCandidate;
  saveCandidateItemsCount: number;
  hasSaveCandidateItems: boolean;
  allSelectedSaveCandidateItems: boolean;
  deleteCandidate: DeleteCandidate;
  showSaveModal: boolean;
  showDeleteModal: boolean;
  isOpened: boolean;
  isClosed: boolean;
  staffAgencyCandidates: StaffAgency[];
  enabledStaffAgencies: StaffAgency[];
  staffLabelCandidates: StaffLabel[];
  enabledStaffLabels: StaffLabel[];
  budgetGroupCandidates: BudgetGroup[];
  budgetGroups: BudgetGroup[];

  validations: any;
  hasError: boolean;

  showCsvImportResultModal: boolean;
  csvImportResult: {
    successCount: number | null;
    failCount: number | null;
    failReasons: string[];
  };
  isLoadCsv: boolean;
  apiParams: {
    staff_number: string | null;
    staff_id: number | null;
    budget_group_id: number | null;
    staff_agency_id: number | null;
    staff_label_id: number | null;
    is_enabled: boolean | null;
  };
  workStartTimeInputStepCandidates: WorkStartTimeInputStep[];
  workEndTimeInputStepCandidates: WorkEndTimeInputStep[];
  activityTimeInputStepCandidates: ActivityTimeInputStep[];
  holidaysList?: number[] | null;
  standardWorkingHoursInHours?: number | string | null;
  overtimeExtraRatePercent?: number | string | null;
  holidayExtraRatePercent?: number | string | null;
  midnightExtraRatePercent?: number | string | null;
  showDetailColumns: boolean;
  isFormEditable: boolean;
  csvData: CsvLine[];
  csvFileName: string;
  hasList: boolean;
  rowWidth: number;
  fixTable: boolean;
}

const timeInputTypeValueMap = {
  workStartTimeInputTypeNotChanged: null, // 変更なし
  workStartTimeInputTypeNotRounded: C.WORK_START_TIME_INPUT_TYPE_NOT_ROUNDED,
  workStartTimeInputTypeRoundedUp: C.WORK_START_TIME_INPUT_TYPE_ROUNDED_UP,
  workStartTimeInputTypeRoundedDown: C.WORK_START_TIME_INPUT_TYPE_ROUNDED_DOWN,
  workEndTimeInputTypeNotChanged: null, // 変更なし
  workEndTimeInputTypeNotRounded: C.WORK_END_TIME_INPUT_TYPE_NOT_ROUNDED,
  workEndTimeInputTypeRoundedUp: C.WORK_END_TIME_INPUT_TYPE_ROUNDED_UP,
  workEndTimeInputTypeRoundedDown: C.WORK_END_TIME_INPUT_TYPE_ROUNDED_DOWN,
  activityTimeInputTypeNotChanged: null, // 変更なし
  activityTimeInputTypeNotRounded: C.ACTIVITY_TIME_INPUT_TYPE_NOT_ROUNDED,
  activityTimeInputTypeRoundedUp: C.ACTIVITY_TIME_INPUT_TYPE_ROUNDED_UP,
  activityTimeInputTypeRoundedDown: C.ACTIVITY_TIME_INPUT_TYPE_ROUNDED_DOWN,
};

function initialSaveCandidateState() {
  return {
    saveType: 'create',
    staff_number: null,
    staff_agency_id: null,
    staff_label_id: null,
    budget_group_id: null,
    hourly_cost: null,
    is_enabled: true,
    regular_start_time: null,
    regular_start_time_hour: null,
    regular_start_time_min: null,
    regular_end_time: null,
    regular_end_time_hour: null,
    regular_end_time_min: null,
    break_time: null,
    break_time_hour: null,
    break_time_min: null,
    work_start_time_input_type: C.WORK_START_TIME_INPUT_TYPE_NOT_ROUNDED,
    work_start_time_input_step: '0',
    work_end_time_input_type: C.WORK_END_TIME_INPUT_TYPE_NOT_ROUNDED,
    work_end_time_input_step: '0',
    activity_time_input_type: C.ACTIVITY_TIME_INPUT_TYPE_NOT_ROUNDED,
    activity_time_input_step: '0',
    overtime_extra_rate: 0,
    standard_working_hours: null,
    holidays: [],
    midnight_time_start: null,
    midnight_time_start_hour: null,
    midnight_time_start_min: null,
    midnight_time_end_hour: null,
    midnight_time_end_min: null,
    midnight_time_end: null,
    items: [],
  };
}

function initialDeleteCandidateState() {
  return {
    id: null,
    staff_number: null,
    family_name: null,
    first_name: null,
  };
}

function setupState(context: SetupContext): State {
  const root = context.root as Vue;
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    msgVars: { create: '作成', update: '編集', delete: '削除', csv_import: '取込み', bulkUpdate: '一括編集' },
    searchParams: {
      displayListDisabledItem: false,
    },
    search: {
      candidates: {
        staffs: [],
        budgetGroups: [],
        staffAgencies: [],
        staffLabels: [],
      },
      selected: {
        staff: null,
        staffNumber: null,
        budgetGroup: null,
        staffAgency: null,
        staffLabel: null,
      },
      query: {
        budget_group_id: computed(() => {
          return state.search.selected.budgetGroup ? state.search.selected.budgetGroup.id : null;
        }),
        staff_number: computed(() => {
          return state.search.selected.staffNumber ? state.search.selected.staffNumber : null;
        }),
        staff_id: computed(() => {
          return state.search.selected.staff ? state.search.selected.staff.id : null;
        }),
        staff_agency: computed(() => {
          return state.search.selected.staffAgency ? state.search.selected.staffAgency.id : null;
        }),
        staff_label: computed(() => {
          return state.search.selected.staffLabel ? state.search.selected.staffLabel.id : null;
        }),
      },
    },
    noBudgetUnitsMessage: '管理グループまたは荷主の割当が必要です。オーナーに依頼ください。',
    opType: 'create',
    isGteLeader: false,
    list: [],
    saveCandidate: initialSaveCandidateState(),
    saveCandidateItemsCount: computed(() => {
      return state.saveCandidate.items.length;
    }),
    hasSaveCandidateItems: computed(() => {
      return state.saveCandidateItemsCount > 0;
    }),
    allSelectedSaveCandidateItems: false,
    deleteCandidate: initialDeleteCandidateState(),
    showSaveModal: false,
    showDeleteModal: false,
    isOpened: false,
    isClosed: false,
    staffAgencyCandidates: [],
    enabledStaffAgencies: [],
    staffLabelCandidates: [],
    enabledStaffLabels: [],
    budgetGroupCandidates: [],
    budgetGroups: [],

    validations: getValidationMap(),

    showCsvImportResultModal: false,
    csvImportResult: {
      successCount: 0,
      failCount: 0,
      failReasons: [],
    },
    isLoadCsv: false,
    workplaceId: computed(() => {
      return root.$route.params.workplaceId;
    }),
    ...wrappedMapGetters(root.$store, 'workplace', [
      'useBudgetGroup',
      'useStaffLabel',
      'useStaffAgency',
      'useCreateAndUpdateInfo',
    ]),
    hasList: computed(() => {
      return state.list.length > 0;
    }),
    hasError: computed(() => {
      return vvHasError(root);
    }),
    apiParams: {
      staff_number: null,
      staff_id: null,
      budget_group_id: null,
      staff_agency_id: null,
      staff_label_id: null,
      is_enabled: null,
    },
    workStartTimeInputStepCandidates: [],
    workEndTimeInputStepCandidates: [],
    activityTimeInputStepCandidates: [],
    holidaysList: [],
    standardWorkingHoursInHours: null,
    overtimeExtraRatePercent: null,
    holidayExtraRatePercent: null,
    midnightExtraRatePercent: null,
    showDetailColumns: true,
    isFormEditable: computed(() => {
      if (state.saveCandidate.saveType !== 'bulkUpdate') {
        return true;
      }
      return C.DATA_MAX_BULK_UPDATE_ITEM_COUNT >= state.saveCandidateItemsCount;
    }),
    csvData: [],
    csvFileName: '',
    rowWidth: computed(() => {
      return (
        706 +
        Number(state.useBudgetGroup) * 200 +
        Number(state.useStaffAgency) * 200 +
        Number(state.useStaffLabel) * 170 +
        Number(state.isGteLeader) * 100 +
        Number(state.showDetailColumns) * 600 +
        Number(state.isGteLeader) * Number(state.showDetailColumns) * 770 +
        Number(state.useCreateAndUpdateInfo) * 660
      );
    }),
    fixTable: true,
  });
  return state;
}

function getValidationMap() {
  const ruleStr = { required: true, max: 255 };
  const ruleInt = { required: true, numeric: true, min_value: 0, max_value: 99999 };
  const ruleIntOption = { required: false, numeric: true, min_value: 0, max_value: 99999 };
  const rulePercentage = {
    required: true,
    numeric: true,
    min_value: 0,
    max_value: 100,
  };
  const rulePercentageOption = {
    numeric: true,
    min_value: 0,
    max_value: 100,
  };
  return {
    staff_number: {
      max: 16, // 桁数は適当
      required: true,
      alpha_num: true,
    },
    staff_id: { numeric: true, min_value: 0 },
    family_name: { max: 255, required: true },
    first_name: { max: 255 },
    family_name_kana: { max: 255 },
    first_name_kana: { max: 255 },
    full_name: ruleStr,
    hourly_cost: ruleInt,
    start_time_hour: { numeric: true, max: 2, max_value: 47 },
    start_time_min: { numeric: true, max: 2, max_value: 59 },
    end_time_hour: { numeric: true, max: 2, max_value: 47 },
    end_time_min: { numeric: true, max: 2, max_value: 59 },
    break_time_hour: { numeric: true, max: 2, max_value: 47 },
    break_time_min: { numeric: true, max: 2, max_value: 59 },
    midnight_time_start_hour: { required: true, numeric: true, max: 2, max_value: 47 },
    midnight_time_start_min: { required: true, numeric: true, max: 2, max_value: 59 },
    midnight_time_end_hour: { required: true, numeric: true, max: 2, max_value: 47 },
    midnight_time_end_min: { required: true, numeric: true, max: 2, max_value: 59 },
    standard_working_hours_in_hours: { decimal: 1, max_value: 24, required: true },
    overtime_extra_rate_percent: rulePercentage,
    midnight_extra_rate_percent: rulePercentage,
    holiday_extra_rate_percent: rulePercentage,
    bulk_update: {
      family_name: { max: 255 },
      hourly_cost: ruleIntOption,
      midnight_time_start_hour: { numeric: true, max: 2, max_value: 47 },
      midnight_time_start_min: { numeric: true, max: 2, max_value: 59 },
      midnight_time_end_hour: { numeric: true, max: 2, max_value: 47 },
      midnight_time_end_min: { numeric: true, max: 2, max_value: 59 },
      standard_working_hours_in_hours: { decimal: 1, max_value: 24 },
      overtime_extra_rate_percent: rulePercentageOption,
      midnight_extra_rate_percent: rulePercentageOption,
      holiday_extra_rate_percent: rulePercentageOption,
    },
  };
}

export default defineComponent({
  components: {
    ListSwitchPanel,
    TabPane,
    Tabs,
    CollapseItem,
    Collapse,
    Card,
    NonEditableCard,
    CsvDownloadLargeButton,
    Sorter2,
    Sorter2Container: useSorter2Container<StaffDisp>(),
    PaginationContainer,
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, 'スタッフ');
    const state = setupState(context);
    setupRegularStartEndTimeValidator(root, state);
    setupMidnightStartEndTimeValidator(root, state);
    const pagination = reactive<Pagination>({
      perPage: 50,
      currentPage: 1,
      total: 0,
    });

    const defaultSortSpec = ref<SortSpec[]>([]);
    function resetToDefaultSortOrder(): void {
      defaultSortSpec.value = [
        { key: 'sortFullNameKana', asc: true },
        { key: 'sortHolidays', asc: true },
        { key: 'sortMidnightTimeStart', asc: true },
      ];
    }
    resetToDefaultSortOrder();

    function getError(fieldName: string): string | null {
      return vvGetError(root, fieldName);
    }

    function getApiParams() {
      state.apiParams.staff_number = state.search.selected.staffNumber;
      state.apiParams.staff_id = state.search.selected.staff ? state.search.selected.staff.id : null;
      state.apiParams.budget_group_id = state.search.selected.budgetGroup ? state.search.selected.budgetGroup.id : null;
      state.apiParams.staff_agency_id = state.search.selected.staffAgency ? state.search.selected.staffAgency.id : null;
      state.apiParams.staff_label_id = state.search.selected.staffLabel ? state.search.selected.staffLabel.id : null;
      state.apiParams.is_enabled = state.searchParams.displayListDisabledItem ? null : true;
      return state.apiParams;
    }

    function clearErrors() {
      vvReset(root);
    }

    async function getList() {
      const { data: budgetGroups } = await budgetGroupApi.index({
        workplaceId: state.workplaceId,
        params: { is_enabled: true },
      });
      if (!state.useBudgetGroup) {
        state.search.selected.budgetGroup = budgetGroups[0];
      }
      const p1 = staffApi.index({
        workplaceId: state.workplaceId,
        params: getApiParams(),
      });
      const p2 = staffAgencyApi.index({
        workplaceId: state.workplaceId,
        params: { is_enabled: true },
      });
      const p3 = staffLabelApi.index({
        workplaceId: state.workplaceId,
        params: { is_enabled: true },
      });
      const [{ data: staffs }, { data: staffAgencies }, { data: staffLabels }] = await Promise.all([p1, p2, p3]);

      state.list = addExtraItems(staffs);
      pagination.total = state.list.length;
      pagination.currentPage = 1;

      state.enabledStaffAgencies = staffAgencies;
      state.enabledStaffLabels = staffLabels;
      state.budgetGroups = budgetGroups;
    }

    async function getListWithUpdatedSearchParams(params: { displayDisabledModel: boolean }) {
      state.searchParams.displayListDisabledItem = params.displayDisabledModel;
      await getList();
    }

    function addExtraItems(staffs: Staff[]): StaffDisp[] {
      return staffs.map((e: Staff) => ({
        ...e,
        fullName: `${e.family_name} ${e.first_name}`,
        fullNameKana: `${e.family_name_kana || ''} ${e.first_name_kana || ''}`,
        // 半角スペースソートのため鸞を入れる cf). src/assets/src/hooks/staffSortHook.ts
        sortFullNameKana: `${e.family_name_kana || '鸞'} ${e.first_name_kana || '鸞'}`,
        sortHolidays: `${e.holidays || '鸞'}`,
        sortMidnightTime: `${e.midnight_time_start || '99:00'} - ${e.midnight_time_end || '99:00'}`,
        standardWorkingHoursInHours: timeIntegerToSeconds(e.standard_working_hours) / 3600,
        overtimeExtraRatePercent: e.overtime_extra_rate * 100,
        midnightExtraRatePercent: e.midnight_extra_rate * 100,
        holidayExtraRatePercent: e.holiday_extra_rate * 100,
        holidaysList: e.holidays !== null ? e.holidays.slice() : [],
      }));
    }

    async function setSearchCandidates() {
      const staffs = await staffApi.index({ workplaceId: state.workplaceId, params: {} });
      state.search.candidates.staffs = addExtraItems(staffs.data);
      const bgs: BudgetGroup[] = [];
      const sas: StaffAgency[] = [];
      const sls: StaffLabel[] = [];
      state.search.candidates.staffs.forEach((staff: Staff) => {
        bgs.push(staff.budget_group);
        sas.push(staff.staff_agency);
        sls.push(staff.staff_label);
      });
      state.search.candidates.budgetGroups = bgs.filter(
        (element, index, self) => self.findIndex((e) => e.id === element.id) === index,
      );
      state.search.candidates.staffAgencies = sas.filter(
        (element, index, self) => self.findIndex((e) => e.id === element.id) === index,
      );
      state.search.candidates.staffLabels = sls.filter(
        (element, index, self) => self.findIndex((e) => e.id === element.id) === index,
      );
    }
    function openSaveModal(saveType: string, item: Staff) {
      clearErrors();
      // initialize staffAgencyCandidates
      state.staffAgencyCandidates = state.enabledStaffAgencies;
      // initialize staffLabelCandidates
      state.staffLabelCandidates = state.enabledStaffLabels;
      // initialize budgetGroupCandidates
      state.budgetGroupCandidates = state.budgetGroups;
      const errItems: string[] = [];
      if (state.staffAgencyCandidates.length === 0) {
        errItems.push('先にスタッフ所属会社を作成してください。');
      }
      if (state.staffLabelCandidates.length === 0) {
        errItems.push('先にラベルを作成してください。');
      }
      if (state.budgetGroupCandidates.length === 0) {
        errItems.push(state.noBudgetUnitsMessage);
      }
      if (errItems.length > 0) {
        errItems.forEach((item) => {
          notifyError1(root, item);
        });
        return;
      }
      if (saveType === 'bulkUpdate') {
        state.saveCandidate.saveType = 'bulkUpdate';
        state.saveCandidate.work_start_time_input_type = null; // 変更なし
        state.saveCandidate.work_end_time_input_type = null; // 変更なし
        state.saveCandidate.activity_time_input_type = null; // 変更なし
        state.saveCandidate.is_enabled = null; // 変更なし
        state.holidaysList = [];
        state.standardWorkingHoursInHours = null;
        state.overtimeExtraRatePercent = null;
        state.holidayExtraRatePercent = null;
        state.midnightExtraRatePercent = null;
      } else if (saveType === 'create') {
        state.saveCandidate.saveType = 'create';
        state.saveCandidate.staff_agency_id = state.staffAgencyCandidates[0].id;
        state.saveCandidate.staff_label_id = state.staffLabelCandidates[0].id;
        state.saveCandidate.budget_group_id = state.budgetGroupCandidates[0].id;
        state.saveCandidate.hourly_cost = 0;
        state.saveCandidate.is_enabled = true;
        state.saveCandidate.regular_start_time = null;
        state.saveCandidate.regular_start_time_hour = null;
        state.saveCandidate.regular_start_time_min = null;
        state.saveCandidate.regular_end_time = null;
        state.saveCandidate.regular_end_time_hour = null;
        state.saveCandidate.regular_end_time_min = null;
        state.saveCandidate.break_time = null;
        state.saveCandidate.break_time_hour = null;
        state.saveCandidate.break_time_min = null;
        state.saveCandidate.midnight_time_start = null;
        state.saveCandidate.midnight_time_start_hour = null;
        state.saveCandidate.midnight_time_start_min = null;
        state.saveCandidate.midnight_time_end = null;
        state.saveCandidate.midnight_time_end_hour = null;
        state.saveCandidate.midnight_time_end_min = null;
        state.saveCandidate.items = [];
        onChangeStaffAgency();
      } else {
        const saveCandidate: SaveCandidate = {
          ...item,
          saveType: 'update',
          items: [],
        };
        if (saveCandidate.regular_start_time !== null && saveCandidate.regular_start_time !== undefined) {
          const [startHr, startMin] = unpackTimeInteger(saveCandidate.regular_start_time);
          saveCandidate.regular_start_time_hour = '' + startHr;
          saveCandidate.regular_start_time_min = ('0' + startMin).slice(-2);
        }
        if (saveCandidate.regular_end_time !== null && saveCandidate.regular_end_time !== undefined) {
          const [endHr, endMin] = unpackTimeInteger(saveCandidate.regular_end_time);
          saveCandidate.regular_end_time_hour = '' + endHr;
          saveCandidate.regular_end_time_min = ('0' + endMin).slice(-2);
        }
        if (saveCandidate.break_time !== null && saveCandidate.break_time !== undefined) {
          const [brkHr, brkMin] = unpackTimeInteger(saveCandidate.break_time);
          saveCandidate.break_time_hour = '' + brkHr;
          saveCandidate.break_time_min = ('0' + brkMin).slice(-2);
        }
        // *_rate
        state.overtimeExtraRatePercent = saveCandidate.overtime_extra_rate
          ? Number((saveCandidate.overtime_extra_rate * 100).toFixed())
          : null;
        state.midnightExtraRatePercent = saveCandidate.midnight_extra_rate
          ? Number((saveCandidate.midnight_extra_rate * 100).toFixed())
          : null;
        state.holidayExtraRatePercent = saveCandidate.holiday_extra_rate
          ? Number((saveCandidate.holiday_extra_rate * 100).toFixed())
          : null;

        state.holidaysList =
          saveCandidate.holidays !== null && saveCandidate.holidays !== undefined ? saveCandidate.holidays.slice() : [];

        if (saveCandidate.midnight_time_start !== null && saveCandidate.midnight_time_start !== undefined) {
          const [startHour, startMin] = unpackTimeInteger(saveCandidate.midnight_time_start);
          saveCandidate.midnight_time_start_hour = '' + startHour;
          saveCandidate.midnight_time_start_min = ('0' + startMin).slice(-2);
        }
        if (saveCandidate.midnight_time_end !== null && saveCandidate.midnight_time_end !== undefined) {
          const [endHour, endMin] = unpackTimeInteger(saveCandidate.midnight_time_end);
          saveCandidate.midnight_time_end_hour = '' + endHour;
          saveCandidate.midnight_time_end_min = ('0' + endMin).slice(-2);
        }
        // standard_working_hours
        state.standardWorkingHoursInHours =
          saveCandidate.standard_working_hours != null
            ? Number((timeIntegerToSeconds(saveCandidate.standard_working_hours) / 3600).toFixed(1))
            : null;

        // check whether saved staff_agency is in staffAgencyCandidates or not.
        // If not then add it.
        const hasStaffAgencyInCandidates = state.staffAgencyCandidates.find(
          (e) => e.id === saveCandidate.staff_agency_id,
        );
        if (!hasStaffAgencyInCandidates) {
          state.staffAgencyCandidates = [item.staff_agency].concat(state.staffAgencyCandidates);
        }
        // check whether saved staff_label is in staffLabelCandidates or not.
        // If not then add it.
        const hasStaffLabelInCandidates = state.staffLabelCandidates.find((e) => e.id === saveCandidate.staff_label_id);
        if (!hasStaffLabelInCandidates) {
          state.staffLabelCandidates = [item.staff_label].concat(state.staffLabelCandidates);
        }
        // check whether saved budget_group is in budgetGroupCandidates or not.
        // If not then add it.
        const hasBudgetGroupInCandidates = state.budgetGroupCandidates.find(
          (e) => e.id === saveCandidate.budget_group_id,
        );
        if (!hasBudgetGroupInCandidates) {
          state.budgetGroupCandidates = [item.budget_group].concat(state.budgetGroupCandidates);
        }
        saveCandidate.items = [];
        state.saveCandidate = saveCandidate;
      }
      state.showSaveModal = true;
    }
    function closeSaveModal() {
      state.saveCandidate = initialSaveCandidateState();
      clearErrors();
      state.showSaveModal = false;
      state.allSelectedSaveCandidateItems = false;
    }
    function onStaffTimeChange(prop: string) {
      const staff = state.saveCandidate;
      if (prop === 'regular_start_time') {
        if (!staff.regular_start_time_hour || !staff.regular_start_time_min) {
          staff.regular_start_time = null;
        }
        if (
          isNaN(parseInt(String(staff.regular_start_time_hour))) ||
          isNaN(parseInt(String(staff.regular_start_time_min)))
        ) {
          staff.regular_start_time = null;
        }
        const time = packToTimeInteger(
          parseInt(String(staff.regular_start_time_hour)),
          parseInt(String(staff.regular_start_time_min)),
          0,
        );
        staff.regular_start_time = time;
        vvValidate(root, 'regularStartEndTime');
      } else if (prop === 'regular_end_time') {
        if (!staff.regular_end_time_hour || !staff.regular_end_time_min) {
          staff.regular_end_time = null;
        }
        if (
          isNaN(parseInt(String(staff.regular_end_time_hour))) ||
          isNaN(parseInt(String(staff.regular_end_time_min)))
        ) {
          staff.regular_end_time = null;
        }
        const time = packToTimeInteger(
          parseInt(String(staff.regular_end_time_hour)),
          parseInt(String(staff.regular_end_time_min)),
          0,
        );
        staff.regular_end_time = time;
        vvValidate(root, 'regularStartEndTime');
      } else if (prop === 'midnight_time_start') {
        if (!staff.midnight_time_start_hour || !staff.midnight_time_start_min) {
          staff.midnight_time_start = null;
        }
        if (
          isNaN(parseInt(String(staff.midnight_time_start_hour))) ||
          isNaN(parseInt(String(staff.midnight_time_start_min)))
        ) {
          staff.midnight_time_start = null;
        }
        const time = packToTimeInteger(
          parseInt(String(staff.midnight_time_start_hour)),
          parseInt(String(staff.midnight_time_start_min)),
          0,
        );
        staff.midnight_time_start = time;
        vvValidate(root, 'midnightStartEndTime');
      } else if (prop === 'midnight_time_end') {
        if (!staff.midnight_time_end_hour || !staff.midnight_time_end_min) {
          staff.midnight_time_end = null;
        }
        if (
          isNaN(parseInt(String(staff.midnight_time_end_hour))) ||
          isNaN(parseInt(String(staff.midnight_time_end_min)))
        ) {
          staff.midnight_time_end = null;
        }
        const time = packToTimeInteger(
          parseInt(String(staff.midnight_time_end_hour)),
          parseInt(String(staff.midnight_time_end_min)),
          0,
        );
        staff.midnight_time_end = time;
        vvValidate(root, 'midnightStartEndTime');
      } else {
        if (!staff.break_time_hour || !staff.break_time_min) {
          staff.break_time = null;
        }
        if (isNaN(parseInt(String(staff.break_time_hour))) || isNaN(parseInt(String(staff.break_time_min)))) {
          staff.break_time = null;
        }
        const time = packToTimeInteger(
          parseInt(String(staff.break_time_hour)),
          parseInt(String(staff.break_time_min)),
          0,
        );
        staff.break_time = time;
        vvValidate(root);
      }
    }
    function openFileDialog() {
      // this.$refs.csvInput.$el.children[0].click()
      const element: HTMLElement = document.getElementById('csvInput') as HTMLElement;
      element.click();
    }
    async function saveItem() {
      if (!(await vvValidate(root))) {
        return;
      }

      state.opType = state.saveCandidate.saveType;
      if (!state.saveCandidate.regular_start_time_hour && !state.saveCandidate.regular_start_time_min) {
        state.saveCandidate.regular_start_time = null;
      }
      if (!state.saveCandidate.regular_end_time_hour && !state.saveCandidate.regular_end_time_min) {
        state.saveCandidate.regular_end_time = null;
      }
      if (!state.saveCandidate.break_time_hour && !state.saveCandidate.break_time_min) {
        state.saveCandidate.break_time = null;
      }
      if (!state.saveCandidate.midnight_time_start_hour && !state.saveCandidate.midnight_time_start_min) {
        state.saveCandidate.midnight_time_start = null;
      }
      if (!state.saveCandidate.midnight_time_end_hour && !state.saveCandidate.midnight_time_end_min) {
        state.saveCandidate.midnight_time_end = null;
      }
      if (state.saveCandidate.saveType === 'bulkUpdate') {
        state.saveCandidate.holidays = state.holidaysList ? state.holidaysList.slice().sort() : null;
        if (!state.saveCandidate.work_start_time_input_type) {
          state.saveCandidate.work_start_time_input_step = null;
        }
        if (!state.saveCandidate.work_end_time_input_type) {
          state.saveCandidate.work_end_time_input_step = null;
        }
        if (!state.saveCandidate.activity_time_input_type) {
          state.saveCandidate.activity_time_input_step = null;
        }
      } else {
        state.saveCandidate.holidays = state.holidaysList ? state.holidaysList.slice().sort() : null;
      }
      state.saveCandidate.standard_working_hours =
        state.standardWorkingHoursInHours != null && state.standardWorkingHoursInHours !== ''
          ? secondsToTimeInteger(Number(state.standardWorkingHoursInHours) * 3600)
          : null;
      state.saveCandidate.overtime_extra_rate =
        state.overtimeExtraRatePercent !== null &&
        state.overtimeExtraRatePercent !== undefined &&
        state.overtimeExtraRatePercent !== ''
          ? Number((Number(state.overtimeExtraRatePercent) / 100).toFixed(2))
          : null;
      state.saveCandidate.midnight_extra_rate =
        state.midnightExtraRatePercent !== null &&
        state.midnightExtraRatePercent !== undefined &&
        state.midnightExtraRatePercent !== ''
          ? Number((Number(state.midnightExtraRatePercent) / 100).toFixed(2))
          : null;
      state.saveCandidate.holiday_extra_rate =
        state.holidayExtraRatePercent !== null &&
        state.holidayExtraRatePercent !== undefined &&
        state.holidayExtraRatePercent !== ''
          ? Number((Number(state.holidayExtraRatePercent) / 100).toFixed(2))
          : null;
      try {
        const reqObj = {
          workplaceId: state.workplaceId,
          data: state.saveCandidate,
        };

        const bulkUpdateCount = state.saveCandidateItemsCount;
        if (state.saveCandidate.saveType === 'create') {
          await staffApi.create(reqObj);
        } else if (state.saveCandidate.saveType === 'update') {
          await staffApi.update(reqObj);
        } else if (state.saveCandidate.saveType === 'bulkUpdate') {
          await staffApi.bulkUpdate(reqObj);
        }
        await getList();
        setSearchCandidates();
        closeSaveModal();
        if (state.opType === 'bulkUpdate') {
          notifySuccess1(root, `${state.pageName}を${bulkUpdateCount}件${state.msgVars[state.opType]}しました`);
        } else {
          notifySuccess1(root, `${state.pageName}を${state.msgVars[state.opType]}しました`);
        }
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'dup_staff_number') {
          const msg = 'その社員番号は既に使用されています。';
          notifyError1(root, msg, { timeout: 5 * 1000 });
        } else {
          let errId: string;
          if (state.saveCandidate.saveType === 'create') {
            errId = 'ERR00001';
          } else if (state.saveCandidate.saveType === 'update') {
            errId = 'ERR00002';
          } else {
            errId = 'ERR00003';
          }
          const msg =
            `${state.pageName}の${state.msgVars[state.opType]}に失敗しました。` +
            '管理者に連絡してください。' +
            `(ERR: ${state.pageName} ${errId}, user_id:${state.userId})`;
          notifyError1(root, msg, { err });
        }
      }
    }
    function openDeleteModal(item: DeleteCandidate) {
      state.deleteCandidate = item;
      state.showDeleteModal = true;
    }
    function closeDeleteModal() {
      state.showDeleteModal = false;
    }
    async function deleteItem() {
      state.opType = 'delete';
      try {
        const reqObj = {
          workplaceId: state.workplaceId,
          itemId: state.deleteCandidate.id,
        };
        await staffApi.delete(reqObj);
        await getList();
        await setSearchCandidates();
        closeDeleteModal();
        notifySuccess1(root, `${state.pageName}を${state.msgVars[state.opType]}しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'is_undeletable') {
          const msg = '一定時間が経過したため削除できません。無効化をおすすめします。';
          notifyError1(root, msg, { timeout: 5 * 1000 });
        } else if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'すでに使われているマスタです。削除できません。無効化をおすすめします。';
          notifyError1(root, msg, { timeout: 5 * 1000 });
        } else {
          const errId = 'ERR00003';
          const msg =
            `${state.pageName}の${state.msgVars[state.opType]}に失敗しました。` +
            '管理者に連絡してください。' +
            `(ERR: ${state.pageName} ${errId}, user_id:${state.userId})`;
          notifyError1(root, msg, { err });
        }
      }
    }

    async function onCsvUpload(e: any) {
      state.isLoadCsv = true;
      try {
        const data = new FormData();
        data.append('csv', e.target.files[0]);
        const reqObj = {
          workplaceId: state.workplaceId,
          data: data,
          headers: { 'content-type': 'multipart/form-data' },
        };
        const result = (await staffApi.importCsv(reqObj)).data as StaffImportResponse;
        await getList();
        await setSearchCandidates();
        openCsvImportResultModal(result);
      } catch (err: any) {
        state.isLoadCsv = false;
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        let msg = '';
        switch (true) {
          case errStatus === 400 && errRes.reason === 'invalid_data':
            msg = 'ファイル形式、またはCSVフォーマットが間違っています。';
            break;
          case errStatus === 400 && errRes.reason === 'import_limit_flag':
            msg = '取込み行数は最大500行です。';
            break;
          case errStatus === 400 && errRes.reason === 'invalid_contents':
            msg = 'ファイル内容が不正です。';
            break;
        }
        if (msg) {
          notifyError1(root, msg, { timeout: 5 * 1000 });
        }
      }
    }
    function openCsvImportResultModal(result: StaffImportResponse) {
      state.isLoadCsv = false;
      state.csvImportResult.successCount = result.success_count;
      state.csvImportResult.failCount = result.fail_count;
      state.csvImportResult.failReasons = result.fail_reasons;
      state.showCsvImportResultModal = true;
    }
    function closeCsvImportResultModal() {
      state.showCsvImportResultModal = false;
    }
    function isFailedImport() {
      return state.csvImportResult.failReasons.length > 0;
    }

    function toggleAllSelectedSaveCandidateItems() {
      state.allSelectedSaveCandidateItems = state.saveCandidateItemsCount === state.list.length;
    }

    function toggleSelectedSaveCandidateItems(currentPageElements: StaffDisp[]) {
      // reset
      state.saveCandidate = initialSaveCandidateState();
      if (state.allSelectedSaveCandidateItems) {
        // check all
        currentPageElements.forEach((e) => {
          state.saveCandidate.items.push({ id: e.id });
        });
      }
    }

    function toggleSaveCandidateItems(id: number) {
      const index = state.saveCandidate.items.findIndex((e) => e.id === id);
      if (index < 0) {
        state.saveCandidate.items.push({ id: id });
      } else {
        state.saveCandidate.items.splice(index, 1);
      }
      toggleAllSelectedSaveCandidateItems();
    }

    function isSaveCandidateSelected(id: number) {
      return state.saveCandidate.items.findIndex((e) => e.id === id) >= 0;
    }

    function accordionToggle() {
      state.isOpened = !state.isOpened;
    }

    function toggleMenu() {
      state.isClosed = !state.isClosed;
    }

    function onChangeWorkStartTimeInputTypeNotChanged() {
      state.saveCandidate.work_start_time_input_step = '0';
    }

    function onChangeWorkStartTimeInputTypeNotRounded() {
      state.saveCandidate.work_start_time_input_step = '0';
    }

    function onChangeWorkEndTimeInputTypeNotChanged() {
      state.saveCandidate.work_end_time_input_step = '0';
    }

    function onChangeWorkEndTimeInputTypeNotRounded() {
      state.saveCandidate.work_end_time_input_step = '0';
    }

    function onChangeActivityTimeInputTypeNotChanged() {
      state.saveCandidate.activity_time_input_step = '0';
    }

    function onChangeActivityTimeInputTypeNotRounded() {
      state.saveCandidate.activity_time_input_step = '0';
    }

    function onChangeStaffAgency() {
      const staffAgency = state.staffAgencyCandidates.find((e) => e.id === state.saveCandidate.staff_agency_id);
      if (staffAgency) {
        state.saveCandidate.work_start_time_input_type = staffAgency.work_start_time_input_type;
        state.saveCandidate.work_start_time_input_step = staffAgency.work_start_time_input_step;
        state.saveCandidate.work_end_time_input_type = staffAgency.work_end_time_input_type;
        state.saveCandidate.work_end_time_input_step = staffAgency.work_end_time_input_step;
        state.saveCandidate.activity_time_input_type = staffAgency.activity_time_input_type;
        state.saveCandidate.activity_time_input_step = staffAgency.activity_time_input_step;
        state.saveCandidate.overtime_extra_rate = staffAgency.overtime_extra_rate;
        state.saveCandidate.midnight_extra_rate = staffAgency.midnight_extra_rate;
        state.saveCandidate.holiday_extra_rate = staffAgency.holiday_extra_rate;
        state.saveCandidate.standard_working_hours = staffAgency.standard_working_hours;
        state.saveCandidate.midnight_time_start = staffAgency.midnight_time_start;
        state.saveCandidate.midnight_time_end = staffAgency.midnight_time_end;
        state.saveCandidate.holidays = staffAgency.holidays;
        if (state.saveCandidate.midnight_time_start) {
          const [startHour, startMin] = unpackTimeInteger(state.saveCandidate.midnight_time_start);
          state.saveCandidate.midnight_time_start_hour = '' + startHour;
          state.saveCandidate.midnight_time_start_min = ('0' + startMin).slice(-2);
        } else {
          state.saveCandidate.midnight_time_start_hour = '0';
          state.saveCandidate.midnight_time_start_min = '00';
        }
        if (state.saveCandidate.midnight_time_end) {
          const [endHour, endMin] = unpackTimeInteger(state.saveCandidate.midnight_time_end);
          state.saveCandidate.midnight_time_end_hour = '' + endHour;
          state.saveCandidate.midnight_time_end_min = ('0' + endMin).slice(-2);
        } else {
          state.saveCandidate.midnight_time_end_hour = '0';
          state.saveCandidate.midnight_time_end_min = '00';
        }
        state.overtimeExtraRatePercent = state.saveCandidate.overtime_extra_rate
          ? Number((state.saveCandidate.overtime_extra_rate * 100).toFixed())
          : null;
        state.midnightExtraRatePercent = state.saveCandidate.midnight_extra_rate
          ? Number((state.saveCandidate.midnight_extra_rate * 100).toFixed())
          : null;
        state.holidayExtraRatePercent = state.saveCandidate.holiday_extra_rate
          ? Number((state.saveCandidate.holiday_extra_rate * 100).toFixed())
          : null;
        state.standardWorkingHoursInHours = Number(
          (timeIntegerToSeconds(state.saveCandidate.standard_working_hours) / 3600).toFixed(1),
        );
        state.holidaysList = state.saveCandidate.holidays !== null ? state.saveCandidate.holidays.slice() : [];
        onStaffTimeChange('midnight_time_start');
        onStaffTimeChange('midnight_time_end');
      } else {
        if (state.saveCandidate.saveType === 'bulkUpdate') {
          state.saveCandidate.work_start_time_input_type = timeInputTypeValueMap.workStartTimeInputTypeNotChanged;
          state.saveCandidate.work_end_time_input_type = timeInputTypeValueMap.workEndTimeInputTypeNotChanged;
          state.saveCandidate.activity_time_input_type = timeInputTypeValueMap.activityTimeInputTypeNotChanged;
          state.standardWorkingHoursInHours = null;
          state.overtimeExtraRatePercent = null;
          state.holidayExtraRatePercent = null;
          state.midnightExtraRatePercent = null;
          state.holidaysList = [];
          state.saveCandidate.midnight_time_start_hour = null;
          state.saveCandidate.midnight_time_start_min = null;
          state.saveCandidate.midnight_time_end_hour = null;
          state.saveCandidate.midnight_time_end_min = null;
        } else {
          state.saveCandidate.work_start_time_input_type = C.WORK_START_TIME_INPUT_TYPE_NOT_ROUNDED;
          state.saveCandidate.work_end_time_input_type = C.WORK_END_TIME_INPUT_TYPE_NOT_ROUNDED;
          state.saveCandidate.activity_time_input_type = C.ACTIVITY_TIME_INPUT_TYPE_NOT_ROUNDED;
          state.saveCandidate.midnight_time_start_hour = '0';
          state.saveCandidate.midnight_time_start_min = '00';
          state.saveCandidate.midnight_time_end_hour = '0';
          state.saveCandidate.midnight_time_end_min = '00';
        }
        state.saveCandidate.work_start_time_input_step = '0';
        state.saveCandidate.work_end_time_input_step = '0';
        state.saveCandidate.activity_time_input_step = '0';
        state.saveCandidate.holidays = [];
        state.saveCandidate.standard_working_hours = null;
        state.saveCandidate.overtime_extra_rate = null;
        state.saveCandidate.midnight_extra_rate = null;
        state.saveCandidate.holiday_extra_rate = null;
      }
    }

    function setupRegularStartEndTimeValidator(root: Vue, state: State) {
      setupStartEndValidator(root, 'regularStartEndTime', () => {
        let startDate: Date | null = null;
        let endDate: Date | null = null;
        let startTime: TimeInteger | null = null;
        let endTime: TimeInteger | null = null;

        const cand = state.saveCandidate;
        if (!cand) {
          return { startDate, endDate, startTime, endTime };
        }
        if (cand.regular_start_time !== null && !isNaN(cand.regular_start_time!)) {
          startDate = moment().toDate();
          startTime = cand.regular_start_time!;
        }
        if (cand.regular_end_time !== null && !isNaN(cand.regular_end_time!)) {
          endDate = moment().toDate();
          endTime = cand.regular_end_time!;
        }
        return { startDate, endDate, startTime, endTime };
      });
    }

    function setupMidnightStartEndTimeValidator(root: Vue, state: State) {
      setupStartEndValidator(root, 'midnightStartEndTime', () => {
        let startDate: Date | null = null;
        let endDate: Date | null = null;
        let startTime: TimeInteger | null = null;
        let endTime: TimeInteger | null = null;
        const maxDurationSeconds: number | null = 86400;

        const cand = state.saveCandidate;
        if (!cand) {
          return { startDate, endDate, startTime, endTime };
        }
        if (cand.midnight_time_start !== null && !isNaN(cand.midnight_time_start!)) {
          startDate = moment().toDate();
          startTime = cand.midnight_time_start!;
        }
        if (cand.midnight_time_end !== null && !isNaN(cand.midnight_time_end!)) {
          endDate = moment().toDate();
          endTime = cand.midnight_time_end!;
        }
        return { startDate, endDate, startTime, endTime, maxDurationSeconds };
      });
    }

    onMounted(async () => {
      await ensureUserAndMasters(root);
      state.isGteLeader = userHasWorkplaceRoleGteLeaderSync(root, parseInt(state.workplaceId));
      await getList();
      setSearchCandidates();
      state.workStartTimeInputStepCandidates = window.master.lovs.work_start_time_input_step.vals.map((e) => {
        return { name: e.name, value: e.key };
      });
      state.workEndTimeInputStepCandidates = window.master.lovs.work_end_time_input_step.vals.map((e) => {
        return { name: e.name, value: e.key };
      });
      state.activityTimeInputStepCandidates = window.master.lovs.activity_time_input_step.vals.map((e) => {
        return { name: e.name, value: e.key };
      });
    });

    async function prepareCsvData(list: StaffDisp[]): Promise<void> {
      if (!state.hasList) {
        return;
      }
      // CSV file name
      state.csvFileName = `staffs_${moment().format('YYYYMMDDHHmmss')}.csv`;

      // CSV header
      const csvHeaders: string[] = [
        'id',
        '社員番号',
        '姓',
        '名',
        'せい',
        'めい',
        '所属会社',
        'スタッフラベル',
        '所属管理グループ',
        '有効',
        '時給',
        'シフト 出勤時刻',
        'シフト 退勤時刻',
        'シフト 休憩時間',
        '出勤 入力時刻刻みタイプ',
        '出勤 入力時刻刻み単位（分）',
        '退勤 入力時刻刻みタイプ',
        '退勤 入力時刻刻み単位（分）',
        '作業 入力時刻刻みタイプ',
        '作業 入力時刻刻み単位（分）',
        '時間外割増率（％）',
        '時間外割増適用時間',
        '休日割増率（％）',
        '休日割増 日',
        '休日割増 月',
        '休日割増 火',
        '休日割増 水',
        '休日割増 木',
        '休日割増 金',
        '休日割増 土',
        '深夜割増率（％）',
        '深夜割増開始時刻',
        '深夜割増終了時刻',
      ];
      // initialize data  state.csvData = [] won't work
      state.csvData.splice(-state.csvData.length);
      state.csvData[0] = csvHeaders;
      const staffAgency = (item: Staff): string => {
        return item.staff_agency?.name ? item.staff_agency.name : '';
      };
      const staffLabel = (item: Staff): string => {
        return item.staff_label?.label_name ? item.staff_label.label_name : '';
      };
      const budgetGroup = (item: Staff): string => {
        return item.budget_group?.name ? item.budget_group.name : '';
      };
      const hourlyCost = (item: Staff): string => {
        return state.isGteLeader ? String(item.hourly_cost) : '';
      };
      list.forEach((e) => {
        const timeIntegerToStringRemoveSeconds = (timeInteger: TimeInteger | null): string => {
          if (timeInteger === null) {
            return '';
          }
          if (isNaN(timeInteger)) {
            return '';
          }
          const [hour, min] = unpackTimeIntegerToString(timeInteger);
          return `${hour}:${min}`;
        };
        state.csvData.push([
          e.id, // 'id',
          e.staff_number, // '社員番号',
          e.family_name, // '姓',
          e.first_name, // '名',
          e.family_name_kana, // 'せい',
          e.first_name_kana, // 'めい',
          staffAgency(e), // '所属会社',
          staffLabel(e), // 'スタッフラベル',
          budgetGroup(e), // '所属管理グループ',
          e.is_enabled ? '1' : '0', // '有効',
          hourlyCost(e), // '時給',
          timeIntegerToStringRemoveSeconds(e.regular_start_time).substr(0, 5), // 'シフト 出勤時刻',
          timeIntegerToStringRemoveSeconds(e.regular_end_time).substr(0, 5), // 'シフト 退勤時刻',
          timeIntegerToStringRemoveSeconds(e.break_time).substr(0, 5), // 'シフト 休憩時間',
          e.work_start_time_input_type, // '出勤 入力時刻刻みタイプ',
          e.work_start_time_input_step, // '出勤 入力時刻刻み単位（分）',
          e.work_end_time_input_type, // '退勤 入力時刻刻みタイプ',
          e.work_end_time_input_step, // '退勤 入力時刻刻み単位（分）',
          e.activity_time_input_type, // '作業 入力時刻刻みタイプ',
          e.activity_time_input_step, // '作業 入力時刻刻み単位（分）',
          state.isGteLeader ? e.overtime_extra_rate * 100 : '', // '時間外割増率（％）',
          state.isGteLeader ? timeIntegerToSeconds(e.standard_working_hours) / 3600 : '', // '時間外割増適用時間',
          state.isGteLeader ? e.holiday_extra_rate * 100 : '', // '休日割増率（％）',
          state.isGteLeader ? (e.holidays ? (e.holidays.slice().some((v) => v === 0) ? 1 : 0) : 0) : '', // '休日割増 日',
          state.isGteLeader ? (e.holidays ? (e.holidays.slice().some((v) => v === 1) ? 1 : 0) : 0) : '', // '休日割増 月',
          state.isGteLeader ? (e.holidays ? (e.holidays.slice().some((v) => v === 2) ? 1 : 0) : 0) : '', // '休日割増 火',
          state.isGteLeader ? (e.holidays ? (e.holidays.slice().some((v) => v === 3) ? 1 : 0) : 0) : '', // '休日割増 水',
          state.isGteLeader ? (e.holidays ? (e.holidays.slice().some((v) => v === 4) ? 1 : 0) : 0) : '', // '休日割増 木',
          state.isGteLeader ? (e.holidays ? (e.holidays.slice().some((v) => v === 5) ? 1 : 0) : 0) : '', // '休日割増 金',
          state.isGteLeader ? (e.holidays ? (e.holidays.slice().some((v) => v === 6) ? 1 : 0) : 0) : '', // '休日割増 土',
          state.isGteLeader ? e.midnight_extra_rate * 100 : '', // '深夜割増率（％）',
          state.isGteLeader ? timeIntegerToStringRemoveSeconds(e.midnight_time_start).substr(0, 5) : '', // '深夜割増開始時刻',
          state.isGteLeader ? timeIntegerToStringRemoveSeconds(e.midnight_time_end).substr(0, 5) : '', // '深夜割増終了時刻',
        ]);
      });
    }

    return {
      state,
      pagination,
      getError,
      getApiParams,
      clearErrors,
      getList,
      getListWithUpdatedSearchParams,
      addExtraItems,
      openSaveModal,
      closeSaveModal,
      onStaffTimeChange,
      openFileDialog,
      saveItem,
      deleteItem,
      openDeleteModal,
      closeDeleteModal,
      onCsvUpload,
      openCsvImportResultModal,
      closeCsvImportResultModal,
      isFailedImport,
      toggleAllSelectedSaveCandidateItems,
      toggleSelectedSaveCandidateItems,
      toggleSaveCandidateItems,
      isSaveCandidateSelected,
      accordionToggle,
      toggleMenu,
      onChangeWorkStartTimeInputTypeNotChanged,
      onChangeWorkStartTimeInputTypeNotRounded,
      onChangeWorkEndTimeInputTypeNotChanged,
      onChangeWorkEndTimeInputTypeNotRounded,
      onChangeActivityTimeInputTypeNotChanged,
      onChangeActivityTimeInputTypeNotRounded,
      onChangeStaffAgency,
      prepareCsvData,
      timeInputTypeValueMap,
      defaultSortSpec,
    };
  },
});
