import { unref } from '@vue/composition-api';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { useModal } from 'src/composables/useModal';
import { useTimetableLabels } from 'src/composables/useTimetableLabels';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { createInjection } from 'src/util/createInjection';
import { type TimetableMasterSaveItem } from '../types/TimetableMasterSaveItem';
import { useTimetableMasters } from './useTimetableMasters';

type InjectionValue = {
  showsCreateModal: ReturnType<typeof useModal>['showsModal'];
  showCreateModal: ReturnType<typeof useModal>['showModal'];
  hideCreateModal: ReturnType<typeof useModal>['hideModal'];
  createTimetableMaster: (timetableMasterSaveItem: TimetableMasterSaveItem) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useCreateTimetableMaster');

async function requestToCreateTimetableMaster({
  budgetGroup,
  timetableMasterSaveItem,
}: {
  budgetGroup: BudgetGroup;
  timetableMasterSaveItem: TimetableMasterSaveItem;
}): Promise<void> {
  const { timetable_label, ...input } = timetableMasterSaveItem;
  await timetableMasterApi.create({
    workplaceId: budgetGroup.workplace_id,
    data: {
      budget_group_id: budgetGroup.id,
      timetable_label_id: timetable_label.id,
      ...input,
    },
  });
}

export function useCreateTimetableMasterProvider(): void {
  const { notifySuccess, notifyError } = useNotification();
  const errorBoundary = useErrorBoundary();
  const { showsModal: showsCreateModal, showModal, hideModal: hideCreateModal } = useModal();
  const { timetableLabels } = useTimetableLabels();
  const { fetchTimetableMasters, budgetGroup } = useTimetableMasters();
  const createTimetableMaster = errorBoundary.wrap(
    async (timetableMasterSaveItem: TimetableMasterSaveItem) => {
      const unwrappedBudgetGroup = unref(budgetGroup);
      if (unwrappedBudgetGroup !== null) {
        await requestToCreateTimetableMaster({
          budgetGroup: unwrappedBudgetGroup,
          timetableMasterSaveItem,
        });
        hideCreateModal();
        notifySuccess('工程マスタを作成しました');
        await fetchTimetableMasters();
      }
    },
    {
      fallbackMessage: '工程マスタの作成に失敗しました。',
      shouldContactUs: true,
    },
  );
  const showCreateModal = () => {
    if (timetableLabels.value.length === 0) {
      notifyError('工程ラベルがありません。工程ラベルを作成してください。');
      return;
    }
    showModal();
  };

  provide({
    showsCreateModal,
    showCreateModal,
    hideCreateModal,
    createTimetableMaster,
  });
}

export function useCreateTimetableMaster(): InjectionValue {
  return inject();
}
