import { parseYmdDate } from 'src/util/datetime';
import { BudgetGroup, convertBudgetGroupResponse } from 'src/models/budgetGroup';
import { RescueDemandResponse } from 'src/models/api/rescueDemandResponse';

export interface RescueDemand {
  id: number
  workplace_id: number
  budget_group_id: number
  budget_group?: BudgetGroup,
  dt: Date
  day_block1_demand: number
  day_block2_demand: number
}

export function convertRescueDemandResponse(response: RescueDemandResponse): RescueDemand {
  const { budget_group, ...rest } = response
  return {
    ...rest,
    dt: parseYmdDate(response.dt),
    ...(budget_group ? { budget_group: convertBudgetGroupResponse(response.budget_group) } : null),
  }
}
