import { isExist } from 'src/util/isExist'

export const convertToManHours = (value: number | null) => {
  if (!isExist(value)) { return null }
  return value * 8
}

export const convertToHeadcount = (value?: number | null) => {
  if (!isExist(value)) { return null }
  return Math.round(value / 8 * 10) / 10
}
