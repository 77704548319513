import { type Validator } from 'src/composables/useCustomValidator'
import { type TimeInteger } from 'src/models/common'
import { unpackTimeInteger } from 'src/util/datetime'
import { type StrictTimeRange, isStrictTimeRange, isValidTimeRange } from 'src/values/TimeRange'

function isValidMinutes(time: TimeInteger): boolean {
  const [_, minutes] = unpackTimeInteger(time)
  return [0, 15, 30, 45].includes(minutes)
}

function isValidInputTimeRange(value: unknown): value is StrictTimeRange {
  return isStrictTimeRange(value) &&
    isValidTimeRange(value) &&
    isValidMinutes(value.startTime) &&
    isValidMinutes(value.endTime)
}

export const timeRangeValidator: Validator = {
  name: 'timeRange',
  validate(value: unknown) {
    return value === null || isValidInputTimeRange(value)
  },
}

export const timeValidator: Validator = {
  name: 'time',
  validate(value: unknown) {
    return value === null || (typeof value === 'number' && isValidMinutes(value))
  },
}

export const phasesValidator: Validator = {
  name: 'phases',
  validate(value: unknown) {
    return value !== null &&
      typeof value === 'object' &&
      Object.values(value).some((v) => v === true)
  },
}
