import axios from 'axios'
import { StringOrNumber } from 'src/models/common'
import { getApiUrl } from 'src/apis/apiUtils';
import { RescueAdjustmentIndexParams, RescueAdjustmentBulkUpdateParams } from 'src/models/api/rescueAdjustmentRequest';
import { RescueAdjustmentIndexResponse, RescueAdjustmentUpdateResponse } from 'src/models/api/rescueAdjustmentResponse';
import { convertRescueAgencyOrderResponse } from 'src/models/rescueAgencyOrder';
import { convertRescueDemandResponse } from 'src/models/rescueDemand';
import { convertRescueAdjustmentMemoResponse } from 'src/models/rescueAdjustmentMemo';
import { convertRescueAdjustmentStatusResponse } from 'src/models/rescueAdjustmentStatus';
import { convertBudgetGroupShiftCountResponse } from 'src/models/budgetGroupShiftCount';
import { RescueAdjustmentIndexResult, RescueAdjustmentBulkUpdateResult } from 'src/models/rescueAdjustment';

function getResourceBaseUrl(workplaceId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/rescue_adjustments`)
}

export default {
  async rescueAdjustmentIndex(params: RescueAdjustmentIndexParams): Promise<RescueAdjustmentIndexResult> {
    const { workplace_id: workplaceId, ...paramsRest } = params
    const { data } = await axios.get<RescueAdjustmentIndexResponse>(`${getResourceBaseUrl(workplaceId)}`, {
      params: paramsRest,
    })
    return {
      // メモは最初レコードがないのでなければ適宜空表示
      rescue_adjustment_memos: data.rescue_adjustment_memos.map(convertRescueAdjustmentMemoResponse),
      rescue_adjustment_statuses: data.rescue_adjustment_statuses.map(convertRescueAdjustmentStatusResponse),
      // 過不足はその管理グループが何かしら入力しない限りレコードがないのでなければ適宜0表示
      rescue_demands: data.rescue_demands.map(convertRescueDemandResponse),
      // 表示すべき管理グループの全量はここから判別できる
      budget_group_shift_counts: data.budget_group_shift_counts.map(convertBudgetGroupShiftCountResponse),
      // 派遣会社に対する発注情報は最初レコードがないのでなければ適宜0表示
      rescue_agency_orders: data.rescue_agency_orders.map(convertRescueAgencyOrderResponse),
      // 表示すべき派遣会社の全量は、別途staffAgencyを取得して判別する
    }
  },
  async rescueAdjustmentBulkUpdate(params: RescueAdjustmentBulkUpdateParams): Promise<RescueAdjustmentBulkUpdateResult> {
    const { workplace_id: workplaceId, ...paramsRest } = params
    const { data } = await axios.post<RescueAdjustmentUpdateResponse>(`${getResourceBaseUrl(workplaceId)}/bulk_update`, paramsRest)
    return data
  },
}
