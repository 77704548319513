import { TimelineIndexResult } from 'src/models/timeline'
import { formatTimeInteger, packToTimeInteger, unpackTimeInteger } from 'src/util/datetime'
import { isExist } from 'src/util/isExist'
import {
  ProgressHistory,
  TimelineHeadcountData,
  TimelineHeadcountHourBlocks,
  TimetableTrendHeader,
  TimelineHourBlock,
  TimelineTimetable,
  SearchedParamsState
} from './types'
import { reactive } from '@vue/composition-api'
import { ProgressHeader } from 'src/models/progressHeader'
import { TimetableTrendIndexResult } from 'src/models/timetableTrend'
import { ProgressDetail } from 'src/models/progressDetail'
import { ProgressHeadcountDetail } from 'src/models/progressHeadcountDetail'

//  工程の概要情報と人時情報を更新する
export const updateHeaderAndBoardHourBlocks = (
  header: TimetableTrendHeader,
  headcountHourBlocks: TimelineHeadcountHourBlocks) => {
  let headerTotalHeadcount = 0
  const boardHourBlocks: TimelineHourBlock[] = headcountHourBlocks.map((currentHeadcountList) => {
    const headcountList = currentHeadcountList.map((headcountData) => {
      return {
        ...headcountData,
        backgroundColor: getHeadcountBackgroundColor(headcountData.headcount),
      }
    })
    const totalHeadcount = headcountList.reduce((total, headcountData) => total + headcountData.headcount, 0)
    const quantity = 0
    headerTotalHeadcount += totalHeadcount
    return {
      totalHeadcount,
      quantity,
      completionRatio: Math.min(100, Math.round(quantity / (header.resultQuantity ?? 0) * 100)),
      headcountList,
    }
  })

  return { header, boardHourBlocks }
}
// API のレスポンスから 合計値を計算
export const createTotalValueFromApiResponse = (
  timetableTrends: TimetableTrendIndexResult
) => {
  let totalQuantity = 0
  let totalManHours = 0
  let totalProductivity = null
  timetableTrends.progress_headers.forEach((progressHeader) => {
    totalQuantity += progressHeader.result_quantity ?? 0
    totalManHours += progressHeader.result_man_hours ?? 0
  })
  totalProductivity = totalManHours !== 0 ? totalQuantity / totalManHours : null
  return { quantity: totalQuantity, manHours: totalManHours, productivity: totalProductivity }
}
// API のレスポンスから作業量の平均・中央値・最大・最小を算出
export const createQuantityCalcInputFromApiResponse = (timetableTrends: TimetableTrendIndexResult) => {
  const arr = timetableTrends.progress_headers
    .filter((obj) => obj.result_quantity !== null)
    .map((obj) => obj.result_quantity ?? 0)
  const sum = arr.reduce((prev, current) => prev + current, 0)
  const average =
   isExist(timetableTrends.progress_headers) && timetableTrends.progress_headers.length !== 0 ? sum / timetableTrends.progress_headers.length : 0

  let maximum = 0
  let minimum = 0
  if (arr.length > 0) {
    maximum = arr.reduce((prev, current) => Math.max(prev, current), 0)
    minimum = arr.reduce((prev, current) => Math.min(prev, current), 999999999999999)// 想定される最大値を初期値でセット
  }
  const sortedArr = arr.slice().sort(function(a, b) { return a - b; })
  const median = findMedian(sortedArr)

  return { average: average, median: median, max: maximum, min: minimum }
}
// API のレスポンスから生産性の最大・最小を算出
export const createProductivityCalcInputFromApiResponse = (timetableTrends: TimetableTrendIndexResult) => {
  const arr = timetableTrends.progress_headers
    .filter((obj) => obj.result_man_hours)
    .map((obj) => (obj.result_quantity ?? 0) / (obj.result_man_hours ?? 0))
  const maximum = arr.reduce((prev, current) => Math.max(prev, current), 0)
  const minimum = arr.reduce((prev, current) => Math.min(prev, current), 999999999999999)

  return { max: maximum, min: minimum }
}
// API のレスポンスから総日数・開始終了の遅れのカウントを取る
export const createQuantityCountInputFromApiResponse = (
  timetableTrends: TimetableTrendIndexResult,
  searchedParamsState: SearchedParamsState) => {
  const total = timetableTrends.progress_headers.length
  let countStartDelay = 0
  let countEndDelay = 0
  for (const header of timetableTrends.progress_headers) {
    if (searchedParamsState.timetableMaster) {
      if (header.start_time && searchedParamsState.timetableMaster.start_time < header.start_time) {
        countStartDelay++
      }
      if (header.end_time && searchedParamsState.timetableMaster.end_time < header.end_time) {
        countEndDelay++
      }
    }
  }

  return { total: total, startDelay: countStartDelay, endDelay: countEndDelay }
}
// API のレスポンスから　最早・最遅を出す
export const createResultTimeCalcInputFromApiResponse = (
  timetableTrends: TimetableTrendIndexResult,
  searchedParamsState: SearchedParamsState) => {
  const arrStartTime = timetableTrends.progress_headers
    .filter((obj) => obj.start_time != null)
    .map((obj) => obj.start_time ?? 0)
  const arrEndTime = timetableTrends.progress_headers
    .filter((obj) => obj.end_time != null)
    .map((obj) => obj.end_time ?? 0)
  const scheduleStart = searchedParamsState.timetableMaster ? searchedParamsState.timetableMaster.start_time : 0
  const scheduleEnd = searchedParamsState.timetableMaster ? searchedParamsState.timetableMaster.end_time : 0

  let resultAlreadyStart = null
  let resultLatestStart = null
  let resultAlreadyEnd = null
  let resultLatestEnd = null
  if (arrStartTime.length > 0) {
    resultLatestStart = formatTimeInteger(arrStartTime.reduce((prev, current) => Math.max(prev, current), 0), 'HH : MM')
    resultAlreadyStart = formatTimeInteger(arrStartTime.reduce((prev, current) => Math.min(prev, current), 480000), 'HH : MM') // 想定される最大値を初期値でセット
  }
  if (arrEndTime.length > 0) {
    resultLatestEnd = formatTimeInteger(arrEndTime.reduce((prev, current) => Math.max(prev, current), 0), 'HH : MM')
    resultAlreadyEnd = formatTimeInteger(arrEndTime.reduce((prev, current) => Math.min(prev, current), 480000), 'HH : MM') // 想定される最大値を初期値でセット
  }

  return {
    scheduleStart: formatTimeInteger(scheduleStart, 'HH : MM') || '-- : --',
    scheduleEnd: formatTimeInteger(scheduleEnd, 'HH : MM') || '-- : --',
    resultAlreadyStart: resultAlreadyStart || '-- : --',
    resultAlreadyEnd: resultAlreadyEnd || '-- : --',
    resultLatestStart: resultLatestStart || '-- : --',
    resultLatestEnd: resultLatestEnd || '-- : --'
  }
}
// 人時情報からその背景色を計算して返す
const getHeadcountBackgroundColor = (headcount: number) => {
  if (headcount === 0) {
    return '#fff';
  }
  // headcount が大きくなるにつれて青系の色で濃くなるよう計算を行う
  // マジックナンバーは KURANDO ブルー系の色で濃淡が変わるように調整した結果の数値
  const r = Math.max(34, 203 - 6 * headcount);
  const g = Math.max(40, 227 - 6 * headcount);
  const b = Math.max(53, 254 - 6 * headcount);
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`
}
// API のレスポンスから工程の概要情報を生成する
const createHeaderFromApiResponse = (
  progressHeader: ProgressHeader): TimetableTrendHeader => {
  const header: TimetableTrendHeader = {
    resultQuantity: progressHeader.result_quantity ?? 0,
    startTime: isExist(progressHeader.start_time) ? formatTimeInteger(progressHeader.start_time, 'HH : MM') : '-- : --',
    endTime: isExist(progressHeader.end_time) ? formatTimeInteger(progressHeader.end_time, 'HH : MM') : '-- : --',
    totalProductivity: progressHeader.total_productivity,
    resultManHours: progressHeader.result_man_hours ?? 0,
    dt: progressHeader.dt,
    memo: progressHeader.memo
  }
  return header;
}
// API のレスポンスから工程の人時ブロック情報を生成する
const createHeadcountHourBlocksFromApiResponse = (
  planBoardlocks: ProgressHeadcountDetail[],
  blockLengthPerHour: number,
  displayHourPeriod: number) => {
  const headcountHourBlocks: TimelineHeadcountHourBlocks = []
  for (let hour = 0; hour < displayHourPeriod; hour++) {
    const headcountList: TimelineHeadcountData[] = []
    for (let blockIndex = 0; blockIndex < blockLengthPerHour; blockIndex++) {
      const targetStartTime = packToTimeInteger(hour, Math.round(60 / blockLengthPerHour * blockIndex), 0)
      const targetBlock = planBoardlocks.find(planBoardBlock => planBoardBlock.start_time === targetStartTime)
      const headcount = targetBlock?.headcount ?? 0
      headcountList.push({
        headcount,
        backgroundColor: getHeadcountBackgroundColor(headcount),
      })
    }
    headcountHourBlocks.push(headcountList)
  }
  return headcountHourBlocks
}
// API のレスポンスから進捗履歴情報を生成する
const createProgressHistoriesFromApiResponse = (progressDetails: ProgressDetail[]) => {
  const progressHistories: ProgressHistory[] = []
  const gridTemplateColumns: number[] = []
  let totalQuantity = 0

  progressDetails.forEach((data) => {
    const [sh, sm] = unpackTimeInteger(data.start_time)
    const [eh, em] = unpackTimeInteger(data.end_time)
    const startHour = (sh + sm / 60)
    const endHour = (eh + em / 60)
    const diffHour = endHour - startHour
    if (gridTemplateColumns.length === 0) {
      gridTemplateColumns.push(100 * startHour)
    }
    gridTemplateColumns.push(100 * diffHour)

    totalQuantity += data?.quantity ?? 0
    progressHistories.push({
      totalQuantity,
      productivity: data.quantity / data.man_hours,
      isHiddenInCell: diffHour < 0.5,
    })
  })
  return {
    progressHistories,
    historiesStyle: {
      display: 'grid',
      gridTemplateColumns: gridTemplateColumns.map((item) => `${item}px`).join(' ') + ' auto',
    },
  }
}
// API のレスポンスから工程情報を生成する
export const createTimetablesFromApiResponse = (
  { progress_headers }: TimelineIndexResult,
  blockLengthPerHour: number,
  displayHourPeriod: number): TimelineTimetable[] => {
  const timetables = progress_headers
    .map(progressHeader => {
      const { header, boardHourBlocks } = updateHeaderAndBoardHourBlocks(
        createHeaderFromApiResponse(progressHeader),
        createHeadcountHourBlocksFromApiResponse(
          progressHeader.progress_headcount_details,
          blockLengthPerHour,
          displayHourPeriod)
      )
      const { progressHistories, historiesStyle } =
        createProgressHistoriesFromApiResponse(progressHeader.progress_details)
      const timetable: TimelineTimetable = reactive({
        id: progressHeader.id,
        masterId: progressHeader.timetable_master_id,
        header,
        progressHistories,
        historiesStyle,
        boardHourBlocks,
        isChanged: false,
      })
      return timetable
    })
    .sort(({ header: a }, { header: b }) => a.dt.getTime() - b.dt.getTime())
  return timetables
}
// 中央値を算出するメソッド
const findMedian = (arr: number[]): number => {
  if (arr.length === 0) { return 0 }
  const middleIndex = (arr.length - 1) / 2
  return (arr[Math.floor(middleIndex)] + arr[Math.ceil(middleIndex)]) / 2
}
