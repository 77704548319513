import { ref, onBeforeUnmount, type Ref } from '@vue/composition-api';
import { format } from 'date-fns';
import currentStaffWorkApi from 'src/apis/currentStaffWork';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { convertCurrentStaffWorkSumResponse, type CurrentStaffWork } from 'src/models/currentStaffWork';
import { createInjection } from 'src/util/createInjection';
import { createTimer } from 'src/util/createTimer';

type Params = {
  workplaceId: number;
  dt: Ref<Date>;
  budgetGroup: Ref<BudgetGroup | null>;
};

type InjectionValue = {
  currentStaffWork: Ref<CurrentStaffWork | null>;
  dt: Ref<Date>;
  budgetGroup: Ref<BudgetGroup | null>;
  fetchCurrentStaffWork: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useCurrentStaffWork');

async function getCurrentStaffWork({
  workplaceId,
  dt,
  budgetGroup,
}: {
  workplaceId: number;
  dt: Date;
  budgetGroup: BudgetGroup | null;
}): Promise<CurrentStaffWork> {
  const { data } = await currentStaffWorkApi.getSum({
    workplace_id: workplaceId,
    dt: format(dt, 'yyyy-MM-dd'),
    budget_group_id: budgetGroup?.id ?? null,
  });

  return convertCurrentStaffWorkSumResponse(data);
}

export function useCurrentStaffWorkProvider({ workplaceId, dt, budgetGroup }: Params): void {
  const errorBoundary = useErrorBoundary();
  const currentStaffWork = ref<CurrentStaffWork | null>(null);
  const fetchCurrentStaffWork = errorBoundary.wrap(
    async () => {
      const fetchedCurrentStaffWork = await getCurrentStaffWork({
        workplaceId,
        dt: dt.value,
        budgetGroup: budgetGroup.value,
      });
      currentStaffWork.value = fetchedCurrentStaffWork;
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );
  const autoReloadTimer = createTimer(fetchCurrentStaffWork, 5 * 60 * 1000);

  onBeforeUnmount(() => {
    autoReloadTimer.stop();
  });

  provide({
    currentStaffWork,
    dt,
    budgetGroup,
    fetchCurrentStaffWork,
  });
}

export function useCurrentStaffWork(): InjectionValue {
  return inject();
}
