


















































































































































































































































































































































































































import Vue from 'vue';
import {
  defineComponent,
  type SetupContext,
  type PropType,
  reactive,
  type ComputedRef,
  computed,
} from '@vue/composition-api';
import type { StaffDisp } from '../../types';
import { WEEK } from '../../consts';
import type { WeekKey } from '../../setting';
import { isExist } from 'src/util/isExist';
import { StaffGenderSelectOptions, StaffTypeSelectOptions, type StaffType, type StaffGender } from 'src/consts';
import { formatTimeInteger } from 'src/util/datetime';
import Sorter2 from 'src/components/Workplace/Sorter2.vue';
import keyPlayerIcon from 'src/components/Staffs/keyPlayerIcon.vue';
import forkliftIcon from 'src/components/Staffs/forkliftIcon.vue';
import customFlag1Icon from 'src/components/Staffs/customFlag1Icon.vue';
import customFlag2Icon from 'src/components/Staffs/customFlag2Icon.vue';
import customFlag3Icon from 'src/components/Staffs/customFlag3Icon.vue';
import keyPlayerGrayIcon from 'src/components/Staffs/keyPlayerGrayIcon.vue';
import forkliftGrayIcon from 'src/components/Staffs/forkliftGrayIcon.vue';
import customFlagGrayIcon from 'src/components/Staffs/customFlagGrayIcon.vue';
import type { StaffWorkSchedule } from 'src/models/staffWorkSchedule';
import type { WorkplaceExtension } from 'src/models/workplaceExtension';

type PaginationContainerSlotProps = {
  perPage: number;
  total: number;
  currentPage: number;
  listSliceIndices: [number, number];
};

type SkillKey = 'skill1' | 'skill2' | 'skill3' | 'skill4' | 'skill5';
type SkillNumber = 1 | 2 | 3 | 4 | 5;
type WorkScheduleKey = 'duty1' | 'duty2' | 'break1' | 'break2' | 'regular';

type State = {
  customSkillStyle: ComputedRef<string>;
  isAllChecked: ComputedRef<boolean>;
};

export default defineComponent({
  components: {
    Sorter2,
    keyPlayerIcon,
    forkliftIcon,
    customFlag1Icon,
    customFlag2Icon,
    customFlag3Icon,
    keyPlayerGrayIcon,
    forkliftGrayIcon,
    customFlagGrayIcon,
  },
  props: {
    sorter2ContainerSlotProps: {
      type: Object as PropType<Record<string, StaffDisp[]>>,
      required: true,
    },
    paginationContainerSlotProps: {
      type: Object as PropType<PaginationContainerSlotProps>,
      required: true,
    },
    workplaceExtension: {
      type: Object as PropType<WorkplaceExtension>,
      required: true,
    },
    checkedStaffs: {
      type: Array as PropType<StaffDisp[]>,
      required: true,
    },
    updateCheckedStaffs: {
      type: Function as PropType<(staffs: StaffDisp[]) => void>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue;
    const state = reactive<State>({
      customSkillStyle: computed(() => {
        const customSkillCount = [
          props.workplaceExtension.custom_skill_name1,
          props.workplaceExtension.custom_skill_name2,
          props.workplaceExtension.custom_skill_name3,
        ].filter((value) => value !== null && value !== '').length;
        return `custom-skill-setting${customSkillCount}`;
      }),
      isAllChecked: computed(() => {
        return props.checkedStaffs.length === staffs.value.length;
      }),
    });

    const staffs = computed(() =>
      props.sorter2ContainerSlotProps.sortedList.slice(...props.paginationContainerSlotProps.listSliceIndices),
    );

    const uncheckAll = (): void => {
      props.updateCheckedStaffs([]);
    };

    const checkAll = (): void => {
      props.updateCheckedStaffs(staffs.value);
    };

    const updateCheckedStaff = (staff: StaffDisp, isAdd: boolean): void => {
      if (isAdd) {
        props.updateCheckedStaffs([...props.checkedStaffs, staff]);
      } else {
        props.updateCheckedStaffs(props.checkedStaffs.filter((e) => e.id !== staff.id));
      }
    };

    // 曜日の頭文字を大文字に変更して、曜日別出退勤時間のソートKeyを作成
    const buildDowWorkTimeSortKey = (dow: WeekKey): string => {
      return `sort${dow.charAt(0).toUpperCase() + dow.slice(1).toLowerCase()}WorkTime`;
    };

    const goToStaffOverview = (item: StaffDisp): void => {
      root.$router.push({
        name: 'WorkplaceStaffOverview',
        query: { budget_group_id: String(item.budget_group_id), staff_id: String(item.id) },
      });
    };

    // 性別名表示
    const getGenderName = (genderNum: StaffGender) => {
      return StaffGenderSelectOptions.find((gender) => gender.id === genderNum)?.name ?? '';
    };

    // スタッフタイプ名表示
    const getStaffTypeName = (staffTypeNum: StaffType) => {
      return StaffTypeSelectOptions.find((staffType) => staffType.id === staffTypeNum)?.name ?? '';
    };

    const getSkillBackGroundColor = (staff: StaffDisp, skillNumber: number): string => {
      const skillKey: SkillKey = `skill${skillNumber as SkillNumber}`;
      return `#${staff[skillKey].color}`;
    };

    const getSkillName = (staff: StaffDisp, skillNumber: number): string => {
      const skillKey: SkillKey = `skill${skillNumber as SkillNumber}`;
      return staff[skillKey].name;
    };

    const getTimeRangeString = (staffWorkSchedule: StaffWorkSchedule | null, key: WorkScheduleKey): string => {
      if (!staffWorkSchedule) {
        return '';
      }
      const [startTime, endTime] = getStartAndEndTime(staffWorkSchedule, key);

      return isExist(startTime) && isExist(endTime)
        ? formatTimeInteger(startTime, 'HH : MM') + ' - ' + formatTimeInteger(endTime, 'HH : MM')
        : '';
    };

    const getStartAndEndTime = (staffWorkSchedule: StaffWorkSchedule, key: WorkScheduleKey): (number | null)[] => {
      if (key === 'duty1') {
        return [staffWorkSchedule.duty1_start_time, staffWorkSchedule.duty1_end_time];
      } else if (key === 'duty2') {
        return [staffWorkSchedule.duty2_start_time, staffWorkSchedule.duty2_end_time];
      } else if (key === 'break1') {
        return [staffWorkSchedule.break1_start_time, staffWorkSchedule.break1_end_time];
      } else if (key === 'break2') {
        return [staffWorkSchedule.break2_start_time, staffWorkSchedule.break2_end_time];
      } else {
        return [staffWorkSchedule.work_start_time, staffWorkSchedule.work_end_time];
      }
    };

    const getMacroOperationMasterName = (staffWorkSchedule: StaffWorkSchedule | null, dutyNumber: number): string => {
      if (!staffWorkSchedule) {
        return '';
      }

      if (dutyNumber === 1) {
        return staffWorkSchedule.duty1_macro_operation_master
          ? staffWorkSchedule.duty1_macro_operation_master.name
          : '';
      } else {
        return staffWorkSchedule.duty2_macro_operation_master
          ? staffWorkSchedule.duty2_macro_operation_master.name
          : '';
      }
    };

    const openUpdateModal = (staff: StaffDisp) => {
      context.emit('open-update-modal', staff);
    };

    return {
      state,
      uncheckAll,
      checkAll,
      WEEK,
      buildDowWorkTimeSortKey,
      goToStaffOverview,
      isExist,
      getGenderName,
      getStaffTypeName,
      formatTimeInteger,
      getSkillBackGroundColor,
      getSkillName,
      getTimeRangeString,
      getMacroOperationMasterName,
      openUpdateModal,
      updateCheckedStaff,
      staffs,
    };
  },
});
