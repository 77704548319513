























import { defineComponent, type PropType } from '@vue/composition-api';
import { AppropriationDateDiffAnchor } from '../../types/AppropriationDateDiffAnchor';

export default defineComponent({
  props: {
    value: {
      type: [Number, String] as PropType<number | string | null>,
      required: false,
      default: null,
    },
    appropriationDateDiffAnchorOptions: {
      type: Array as PropType<{ value: AppropriationDateDiffAnchor; label: string }[]>,
      required: true,
    },
  },
});
