
import { defineComponent, inject, watch, ref } from '@vue/composition-api'
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal'
import { ATTENDANCE_TYPE } from 'src/consts';
import { useLoadLogimeterStaffAttendances } from '../../../hooks/useLoadLogimeterStaffAttendances'

export default defineComponent({
  name: 'reception-shift-modal',
  setup() {
    const modalState = inject(ModalDisplayStateKey);
    if (!modalState) {
      throw new Error('State Error');
    }

    const {
      isDisplayReceptionShiftModal,
      closeReceptionShiftModal,
    } = modalState

    const dt = ref(new Date())
    const change_attendance_type = ref<number>(0)
    const { loadLogimeterStaffAttendances } = useLoadLogimeterStaffAttendances()
    const handleSubmit = async() => {
      await loadLogimeterStaffAttendances(dt.value, change_attendance_type.value)
      closeReceptionShiftModal()
    }
    watch(isDisplayReceptionShiftModal, (isDisplayReceptionShiftModal) => {
      if (isDisplayReceptionShiftModal) {
        dt.value = new Date()
      }
    })

    return {
      isDisplayReceptionShiftModal,
      closeReceptionShiftModal,
      dt,
      change_attendance_type,
      handleSubmit,
      ATTENDANCE_TYPE,
    }
  },
})

