import axios from 'axios'
import { StringOrNumber } from 'src/models/common'
import { getApiUrl } from 'src/apis/apiUtils';
import { PlanBoardBlockBulkUpdateParams, PlanBoardIndexParams } from 'src/models/api/planBoardRequest';
import { PlanBoardBulkUpdateResponse, PlanBoardIndexResponse } from 'src/models/api/planBoardResponse';
import { PlanBoardBlockBulkUpdateResult, PlanBoardIndexResult, convertPlanBoardIndexResult } from 'src/models/planBoard';

function getResourceBaseUrl1(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/plan_boards`)
}

export default {
  async planBoardIndex(params: PlanBoardIndexParams): Promise<PlanBoardIndexResult> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.get<PlanBoardIndexResponse>(`${getResourceBaseUrl1(workplaceId, budgetGroupId)}`, {
      params: paramsRest,
    })
    return convertPlanBoardIndexResult(data)
  },
  async planBoardBlockBulkUpdate(params: PlanBoardBlockBulkUpdateParams): Promise<PlanBoardBlockBulkUpdateResult> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.post<PlanBoardBulkUpdateResponse>(`${getResourceBaseUrl1(workplaceId, budgetGroupId)}/bulk_update`, paramsRest)
    return data
  },
  async create(params: PlanBoardIndexParams): Promise<PlanBoardIndexResult> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.post<PlanBoardIndexResponse>(`${getResourceBaseUrl1(workplaceId, budgetGroupId)}`, paramsRest);
    return convertPlanBoardIndexResult(data)
  },
}
