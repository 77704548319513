import { type CsvRow } from 'src/composables/useDownloadCsv';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { type TimetableActivityMaster } from 'src/models/timetableActivityMaster';
import { getAppropriationDate } from '../../utils/getAppropriationDate';

function sortTimetableMasters(timetableMasters: TimetableMaster[]): TimetableMaster[] {
  return [...timetableMasters].sort((a, b) => a.disp_order - b.disp_order);
}

function sortTimetableActivityMasters(timetableActivityMasters: TimetableActivityMaster[]): TimetableActivityMaster[] {
  return [...timetableActivityMasters].sort(
    (a, b) => a.budget_unit_id - b.budget_unit_id || a.disp_order - b.disp_order,
  );
}

type TimetableMastersMapByActivityMasterId = {
  [K in number]?: TimetableMaster[];
};

function getTimetableMastersMapByActivityMasterId(
  timetableMasters: TimetableMaster[],
): TimetableMastersMapByActivityMasterId {
  return timetableMasters.reduce<TimetableMastersMapByActivityMasterId>((acc, cur) => {
    cur.timetable_master_logimeter_activities.forEach(({ activity_master_id: key }) => {
      acc[key] = [...(acc[key] ?? []), cur];
    });
    return acc;
  }, {});
}

function createMissingActivityMasterFilter(
  timetableActivityMasters: TimetableActivityMaster[],
): (timetableMaster: TimetableMaster) => boolean {
  const activityMasterIds = timetableActivityMasters.map((v) => v.id);
  return (timetableMaster: TimetableMaster) =>
    timetableMaster.timetable_master_logimeter_activities.every(
      (v) => !activityMasterIds.includes(v.activity_master_id),
    );
}

function formatSafely<T, U>(value: T | null | undefined, format: (value: T) => U): U | null {
  if (value === null || value === undefined) {
    return null;
  }
  return format(value);
}

function formatFlag(value: boolean): 1 | 0 {
  return value ? 1 : 0;
}

export function buildCsvData(
  timetableMasters: TimetableMaster[],
  timetableActivityMasters: TimetableActivityMaster[],
): CsvRow[] {
  const sortedTimetableMasters = sortTimetableMasters(timetableMasters);
  const sortedTimetableActivityMasters = sortTimetableActivityMasters(timetableActivityMasters);
  const timetableMastersMap = getTimetableMastersMapByActivityMasterId(sortedTimetableMasters);
  const missingActivityMasterFilter = createMissingActivityMasterFilter(timetableActivityMasters);

  return [
    ['LM荷主', 'LMカテゴリ', 'LM作業', 'LM有効', 'LB工程', '特定の計上日に絞り込み', 'LB有効'],
    ...sortedTimetableActivityMasters.flatMap((timetableActivityMaster) => {
      const timetableMasters = timetableMastersMap[timetableActivityMaster.id] ?? [null];
      return timetableMasters.map((timetableMaster) => [
        timetableActivityMaster.budget_unit.name,
        timetableActivityMaster.category_master?.name ?? '',
        timetableActivityMaster.name,
        formatFlag(timetableActivityMaster.is_enabled),
        timetableMaster?.name ?? '',
        formatSafely(timetableMaster, getAppropriationDate) ?? '',
        formatSafely(timetableMaster?.is_enabled, formatFlag) ?? '',
      ]);
    }),
    ...sortedTimetableMasters
      .filter(missingActivityMasterFilter)
      .map((timetableMaster) => [
        '',
        '',
        '',
        '',
        timetableMaster.name,
        getAppropriationDate(timetableMaster),
        formatFlag(timetableMaster.is_enabled),
      ]),
  ];
}
