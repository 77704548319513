import {
  reactive,
  toRefs,
  InjectionKey,
  ref,
  Ref,
  computed,
  inject,
} from '@vue/composition-api'
import regularShiftApi from 'src/apis/staffShift'

import {
  OrigDateItem,
  DateItem,
  OrigStaffWithShifts,
  StaffWithShifts,
  RegularShift,
  RegularShiftBulkCreateParams,
  CheckedItem,
  MacroOperationMaster,
  UpdateShiftParam,
  ShiftDisplayContent,
  BulkUpdateShiftParam,
  UpdatedShiftsResponse,
} from 'src/models/regularShift'

import { ConfirmModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/components/modal/ConfirmModal/useConfirmModal'
import {
  searchDisplayShift,
  searchShiftAndPreShift,
  searchStaff,
  countDisplayShiftRecord,
} from 'src/views/Dashboard/Workplace/RegularShift/libs/regularShiftHelper'
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { getShiftDisplayContent } from 'src/views/Dashboard/Workplace/RegularShift/libs/shiftDisplayHelper'
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState'
import { sortStaffsWithShifts } from 'src/views/Dashboard/Workplace/RegularShift/libs/sortStaffsWithShifts'
import { SHIFT_CHECK_LIMIT } from 'src/consts'

type State = {
  staffsWithShifts: StaffWithShifts[]
  dateList: DateItem[]
  checkedCount: number
  recordCount: number
  isDetailDisplayConditionValid: boolean
  macroOperationMasters: MacroOperationMaster[]
  shiftChangeFlag: boolean
}

type UpdTargetState = {
  selectedStaffId: number | null
  selectedDate: string | null
}

type ShowYearSummaryTargetState = {
  showTargetStaff: StaffWithShifts | null
  showTargetYear: number | null
}

type ShiftHook = {
  shouldShowDisabled: Ref<boolean>
  staffsWithShifts: Ref<StaffWithShifts[]>
  dateList: Ref<DateItem[]>
  checkedCount: Ref<number>
  recordCount: Ref<number>
  isDetailDisplayConditionValid: Ref<boolean>
  macroOperationMasters: Ref<MacroOperationMaster[]>
  selectedStaffId: Ref<number> | Ref<null>
  selectedDate: Ref<string> | Ref<null>
  showTargetStaff: Ref<StaffWithShifts | null>
  showTargetYear: Ref<number | null>
  shiftChangeFlag: Ref<boolean>
  clickStaffCheckBox: (staffId: number) => void
  clickShiftCellCheckBox: (e: Event, staffId: number, dateStr: string) => void
  clickWeekDayCheckBox: (dateStr: string, staffsWithShifts: StaffWithShifts[]) => void
  clickReflectScheduleButton: (workplace_id: number, budget_group_id: number) => void
  clickReflectProvisionalButton: (workplace_id: number, budget_group_id: number) => void
  clickScheduledToNoShift: (workplace_id: number, budget_group_id: number) => void
  clickChangedToAbsentButton: (workplace_id: number, budget_group_id: number) => void
  clickBulkDestroyButton: (workplace_id: number, budget_group_id: number) => void
  onClickShiftDisplayButton: (workplaceId: number, startDate: Date, budgetGroupId: number) => Promise<void>
  storeTargetShiftForIndividualUpdate: (staff_id: number, date: string) => void
  bulkCreate: ({ workplace_id, budget_group_id, ...params_rest }: RegularShiftBulkCreateParams) => Promise<void>
  updateShift: (params: UpdateShiftParam) => Promise<void>
  bulkUpdate: (params: BulkUpdateShiftParam) => void
  resetCheck: () => void
}

export const useShift = (root: Vue): ShiftHook => {
  const state: State = reactive({
    staffsWithShifts: [],
    dateList: [],
    checkedCount: computed(() => _countCheck()),
    recordCount: computed(() => countDisplayShiftRecord(state.staffsWithShifts)),
    isDetailDisplayConditionValid: false,
    macroOperationMasters: [],
    shiftChangeFlag: true,
  })
  const updTargetState = reactive<UpdTargetState>({
    selectedStaffId: null,
    selectedDate: null
  })
  const showYearSummaryTargetState = reactive<ShowYearSummaryTargetState>({
    showTargetStaff: null,
    showTargetYear: null
  })
  const shouldShowDisabled = ref(false);
  const commonState = inject(CommonStateKey);
  const modalState = inject(ConfirmModalDisplayStateKey);
  if (!commonState || !modalState) {
    throw new Error('State error');
  }
  const { openConfirmModal } = modalState
  const { displayMode } = commonState

  // get
  const onClickShiftDisplayButton = async(workplaceId: number, startDate: Date, budgetGroupId: number): Promise<void> => {
    const params = {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      start_date: startDate,
    }
    const response: RegularShift = await regularShiftApi.regularShiftIndex(params);
    state.staffsWithShifts = _addCheckedAndDisplayContentToShift(sortStaffsWithShifts(response.shifts));
    state.dateList = _addCheckedToDateList(response.dates);
    state.macroOperationMasters = response.macro_operation_masters;

    state.shiftChangeFlag = !state.shiftChangeFlag
  }

  // スタッフのチェックボックスのクリックアクション
  const clickStaffCheckBox = (staffId: number): void => {
    const staffWithShifts = searchStaff(state.staffsWithShifts, staffId);
    if (!staffWithShifts) { return }

    const shouldSelect = !staffWithShifts.checked;
    staffWithShifts.checked = shouldSelect;

    for (const data of staffWithShifts.shifts) {
      if (state.checkedCount >= SHIFT_CHECK_LIMIT && !data.checked && shouldSelect) {
        notifyCheckedLimitError()
        staffWithShifts.checked = false;
        return;
      }
      data.checked = shouldSelect;
    }
  }

  // 日付ヘッダーのチェックボックスのクリックアクション
  const clickWeekDayCheckBox = (dateStr: string): void => {
    const checkedDate = state.dateList.find((e) => e.date === dateStr);
    if (!checkedDate) { return }

    const shouldSelect = !checkedDate.checked;
    checkedDate.checked = shouldSelect;

    const newStaffsWithShifts = state.staffsWithShifts.map(staffWithShifts => {
      return {
        ...staffWithShifts,
        shifts: staffWithShifts.shifts.map((shift) => ({ ...shift })),
      }
    });

    let currentCheckedCount = 0;

    const isOverShiftSelectLimit = () =>
      state.checkedCount + currentCheckedCount > SHIFT_CHECK_LIMIT;

    for (const staffWithShifts of newStaffsWithShifts.filter(el => el.should_show)) {
      if (shouldSelect && isOverShiftSelectLimit()) { break }

      const shiftToChange = staffWithShifts.shifts.find((shift) => shift.date === dateStr)
      if (shiftToChange === undefined) { return }

      if (shiftToChange.checked !== shouldSelect) {
        // 対象のシフトのチェックを変更する
        shiftToChange.checked = shouldSelect;
        currentCheckedCount++;
      }
    }

    if (shouldSelect && isOverShiftSelectLimit()) {
      notifyCheckedLimitError();
      checkedDate.checked = false;
    }

    state.staffsWithShifts = newStaffsWithShifts;
  }

  // シフトテーブルのチェックボックスのクリックアクション
  const clickShiftCellCheckBox = (e: Event, staffId: number, dateStr: string): void => {
    e.stopPropagation();

    const staff = searchStaff(state.staffsWithShifts, staffId);
    if (!staff) { return }
    const shift = searchDisplayShift(staff, dateStr);
    if (!shift) { return }

    if (state.checkedCount >= SHIFT_CHECK_LIMIT && !shift.checked) {
      notifyCheckedLimitError()
      return;
    }
    shift.checked = !shift.checked;

    if (shift.checked) {
      _checkedTrueAction(staff, dateStr);
    } else {
      _checkedFalseAction(staff, dateStr);
    }
  }

  // 対象セルがチェックされた場合の処理
  const _checkedTrueAction = (staff: StaffWithShifts, dateStr: string): void => {
    // 対象セルのスタッフの全てのセルが全てチェックされている場合、スタッフにチェックを入れる
    const isRowOfSameStaffAllChecked = staff.shifts.every((val) => val.checked);
    staff.checked = isRowOfSameStaffAllChecked;

    // 対象セルの日付の全てのセルが全てチェックされている場合、日付にチェックを入れる
    const isColumnOfSameDateAllChecked = state.staffsWithShifts.every((val) => {
      const shift = searchDisplayShift(val, dateStr);
      if (!shift) { return false }

      return shift.checked
    });

    const targetDate = state.dateList.find((e) => e.date === dateStr);
    if (!targetDate) { return }
    targetDate.checked = isColumnOfSameDateAllChecked;
  }

  // 対象セルのチェックが外された場合、対象セルのスタッフと日付のチェックを外す
  const _checkedFalseAction = (staff: StaffWithShifts, dateStr: string) => {
    staff.checked = false;

    const targetDate = state.dateList.find((e) => e.date === dateStr);
    if (!targetDate) { return }
    targetDate.checked = false
  }

  // 個別アップデートの対象シフトデータのstate格納
  const storeTargetShiftForIndividualUpdate = (staff_id: number, date: string): void => {
    updTargetState.selectedStaffId = staff_id
    updTargetState.selectedDate = date
  }

  // 仮シフトを反映するボタンのクリックアクション
  const clickReflectProvisionalButton = async(workplace_id: number, budget_group_id: number): Promise<void> => {
    openConfirmModal({
      title: '仮シフトを反映',
      callback: async() => {
        const reqParams = _getCheckedShift()
        resetCheck();
        const params = {
          workplace_id,
          budget_group_id,
          shifts: reqParams,
        }

        const response = await regularShiftApi.reflectProvisionalShiftToScheduledShift(params)
        notifySuccess1(root, '仮シフトを反映しました')
        _setUpdatedShift(reqParams, response)
      },
    })
  }

  // 確定シフトをシフトなしにする
  const clickScheduledToNoShift = async(workplace_id: number, budget_group_id: number): Promise<void> => {
    openConfirmModal({
      title: 'シフトなしにする',
      callback: async() => {
        const reqParams = _getCheckedShift();
        resetCheck();
        const params = {
          workplace_id,
          budget_group_id,
          shifts: reqParams,
        }
        const response = await regularShiftApi.scheduledToNoShift(params)
        notifySuccess1(root, 'シフトなしに変更しました')
        _setUpdatedShift(reqParams, response)
      },
    })
  }

  // 確定シフトを反映ボタンのクリックアクション
  const clickReflectScheduleButton = async(workplace_id: number, budget_group_id: number): Promise<void> => {
    openConfirmModal({
      title: '確定シフトを反映',
      callback: async() => {
        const reqParams = _getCheckedShift();
        resetCheck();
        const params = {
          workplace_id,
          budget_group_id,
          shifts: reqParams,
        }
        const response = await regularShiftApi.reflectScheduledShiftToActualShift(params)
        notifySuccess1(root, '確定シフトを反映しました')
        _setUpdatedShift(reqParams, response)
      },
    })
  }

  // 欠勤に変更するボタンのクリックアクション
  const clickChangedToAbsentButton = async(workplace_id: number, budget_group_id: number): Promise<void> => {
    openConfirmModal({
      title: '欠勤にする',
      callback: async() => {
        const reqParams = _getCheckedShift();
        resetCheck();
        const params = {
          workplace_id,
          budget_group_id,
          shifts: reqParams,
        }
        const response = await regularShiftApi.actualToAbsent(params)
        notifySuccess1(root, '欠勤に変更しました')
        _setUpdatedShift(reqParams, response)
      },
    })
  }

  // シフトの削除ボタンのクリックアクション
  const clickBulkDestroyButton = async(workplace_id: number, budget_group_id: number): Promise<void> => {
    openConfirmModal({
      title: 'シフトの削除',
      message: '選択されたシフトを削除します。',
      runButtonLabel: '削除',
      customClass: 'is-delete',
      callback: async() => {
        const reqParams = _getCheckedShift();
        resetCheck();
        const params = {
          workplace_id,
          budget_group_id,
          shifts: reqParams,
        }
        await regularShiftApi.bulkDestroy(params)
        notifySuccess1(root, 'シフトを削除しました')
        _setDeletedShift(reqParams)
      },
    })
  }

  // シフトの個別アップデート
  const updateShift = async(params: UpdateShiftParam): Promise<void> => {
    const response = await regularShiftApi.update(params)

    if (!params.staffId || !params.date) { return }
    const staff = searchStaff(state.staffsWithShifts, params.staffId);

    if (!staff) { return }
    const { preShift, shift } = searchShiftAndPreShift(staff, params.date);

    if (!shift || !preShift) { return }
    shift.data = response.shift
    preShift.data = response.pre_shift

    staff.shifts = getShiftDisplayContent(staff.orig_shifts, staff.orig_pre_shifts, displayMode.value);
  }

  // シフトの一括アップデート
  const bulkUpdate = async(_params: BulkUpdateShiftParam): Promise<void> => {
    const reqParams = _getCheckedShift();
    const params = {
      ..._params,
      shifts: reqParams,
    }

    return regularShiftApi.bulkUpdate(params).then((response) => {
      resetCheck();
      _setUpdatedShift(reqParams, response)
    })
  }

  // 更新したシフトデータをシフトステートに格納する
  const _setUpdatedShift = (checkedCellList: CheckedItem[], updatedResponse: Partial<UpdatedShiftsResponse>) => {
    checkedCellList.forEach(checkedCell => {
      const targetStaffId = checkedCell.staff_id;
      const targetDate = checkedCell.date
      const createdPreShift = updatedResponse.created_pre_shifts?.find(e => e.staff_id === targetStaffId && e.dt === targetDate)
      const updatedPreShift = updatedResponse.updated_pre_shifts?.find(e => e.staff_id === targetStaffId && e.dt === targetDate)
      const createdShift = updatedResponse.created_shifts?.find(e => e.staff_id === targetStaffId && e.dt === targetDate)
      const updatedShift = updatedResponse.updated_shifts?.find(e => e.staff_id === targetStaffId && e.dt === targetDate)
      const originStaff = searchStaff(state.staffsWithShifts, targetStaffId);

      if (!originStaff) { return }
      const { preShift, shift } = searchShiftAndPreShift(originStaff, targetDate);

      if (createdPreShift) {
        if (preShift) {
          preShift.data = createdPreShift;
        }
      }

      if (updatedPreShift) {
        if (preShift) {
          preShift.data = updatedPreShift;
        }
      }

      if (createdShift) {
        if (shift) {
          shift.data = createdShift;
        }
      }

      if (updatedShift) {
        if (shift) {
          shift.data = updatedShift;
        }
      }

      originStaff.shifts = getShiftDisplayContent(originStaff.orig_shifts, originStaff.orig_pre_shifts, displayMode.value);
    });
  }

  // 削除したシフトデータをシフトステートに格納する
  const _setDeletedShift = (checkedCellList: CheckedItem[]) => {
    checkedCellList.forEach(checkedCell => {
      const targetStaffId = checkedCell.staff_id;
      const targetDate = checkedCell.date
      const originStaff = searchStaff(state.staffsWithShifts, targetStaffId);

      if (!originStaff) { return }
      const { preShift, shift } = searchShiftAndPreShift(originStaff, targetDate);

      if (preShift) {
        preShift.data = undefined;
      }
      if (shift) {
        shift.data = undefined;
      }

      originStaff.shifts = getShiftDisplayContent(originStaff.orig_shifts, originStaff.orig_pre_shifts, displayMode.value);
    });
  }

  // チェックしたシフトを取得
  const _getCheckedShift = (): CheckedItem[] => {
    const reqParams: CheckedItem[] = []
    state.staffsWithShifts.forEach((staffWithShifts: StaffWithShifts) => {
      staffWithShifts.shifts.forEach((shift: ShiftDisplayContent) => {
        if (shift.checked) {
          const req = {
            date: shift.date,
            staff_id: staffWithShifts.staff_id,
          }
          reqParams.push(req);
        }
      })
    })

    return reqParams
  }

  // チェック数をカウントする
  const _countCheck = (): number => {
    const checkedCount = state.staffsWithShifts
      .map((data) => { return data.shifts })
      .flat()
      .reduce((count, data) => {
        return data.checked ? count + 1 : count;
      }, 0)

    return checkedCount;
  }

  // チェックをリセットする
  const resetCheck = (): void => {
    state.staffsWithShifts.forEach((e) => {
      e.checked = false
      e.shifts.forEach((f) => {
        f.checked = false
      });
    })

    state.dateList.forEach((e) => {
      e.checked = false;
    })
  }

  // 一括作成
  const bulkCreate = async({ workplace_id, budget_group_id, ...params_rest }: RegularShiftBulkCreateParams): Promise<void> => {
    const params = {
      workplace_id,
      budget_group_id,
      ...params_rest,
    }

    await regularShiftApi.bulkCreate(params);
  }

  const _addCheckedAndDisplayContentToShift = (staffWithShifts: OrigStaffWithShifts[]): StaffWithShifts[] => {
    const response: StaffWithShifts[] = staffWithShifts.map((shift) => {
      const displayShifts = getShiftDisplayContent(shift.shifts, shift.pre_shifts, displayMode.value);

      const newShiftData = {
        ...shift,
        checked: false,
        orig_shifts: shift.shifts,
        orig_pre_shifts: shift.pre_shifts,
        should_show: true,
        shifts: displayShifts
      };

      return newShiftData
    })

    return response
  }

  const _addCheckedToDateList = (dateList: OrigDateItem[]): DateItem[] => {
    const response = dateList.map((dateData) => ({ ...dateData, checked: false }))
    return response
  }

  const notifyCheckedLimitError = () => notifyError1(root, `チェック数の上限は ${SHIFT_CHECK_LIMIT} までです`)

  return {
    ...toRefs(state),
    ...toRefs(updTargetState),
    ...toRefs(showYearSummaryTargetState),
    shouldShowDisabled,
    clickStaffCheckBox,
    clickShiftCellCheckBox,
    clickWeekDayCheckBox,
    clickReflectScheduleButton,
    clickScheduledToNoShift,
    clickChangedToAbsentButton,
    clickBulkDestroyButton,
    onClickShiftDisplayButton,
    storeTargetShiftForIndividualUpdate,
    bulkCreate,
    updateShift,
    bulkUpdate,
    resetCheck,
    clickReflectProvisionalButton,
  }
}

export const StaffWithShiftsStateKey: InjectionKey<ShiftHook> = Symbol('StaffWithShifts');
