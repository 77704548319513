import { createInjection } from 'src/util/createInjection';
import { type BudgetGroup } from 'src/models/budgetGroup';
import budgetGroupPlanBoardMiscApi from 'src/apis/budgetGroupPlanBoardMisc';
import { useNotification } from 'src/composables/useNotification';
import { ref, type Ref } from '@vue/composition-api';

type Params = {
  workplaceId: number;
  dt: Ref<Date>;
  budgetGroup: Ref<BudgetGroup | null>;
};

type InjectionValue = {
  memo: Ref<string>;
  lastMemo: Ref<string>;
  dt: Ref<Date>;
  budgetGroup: Ref<BudgetGroup | null>;
  loadMemo: () => Promise<void>;
  updateMemo: () => Promise<void>;
};

const { provide, inject } = createInjection<InjectionValue>('useMemo');

export function useMemoProvider({ workplaceId, dt, budgetGroup }: Params): void {
  const { notifyError, notifySuccess } = useNotification();

  const memo = ref('');
  const lastMemo = ref('');

  const loadMemo = async (): Promise<void> => {
    if (budgetGroup.value === null) {
      return;
    }

    memo.value =
      (
        await budgetGroupPlanBoardMiscApi.index({
          workplace_id: workplaceId,
          budget_group_id: budgetGroup.value.id,
          start_date: dt.value,
          end_date: dt.value,
        })
      )[0]?.memo ?? '';
    lastMemo.value = memo.value;
  };
  /**
   * メモの更新
   */
  const updateMemo = async (): Promise<void> => {
    if (budgetGroup.value === null) {
      return;
    }
    if (memo.value === lastMemo.value) {
      return;
    }
    try {
      await budgetGroupPlanBoardMiscApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: dt.value,
        memo: memo.value,
      });
      lastMemo.value = memo.value;
      notifySuccess('メモを更新しました');
    } catch (err: any) {
      notifyError('メモの更新に失敗しました', { cause: err });
    }
  };

  provide({
    memo,
    lastMemo,
    loadMemo,
    updateMemo,
    dt,
    budgetGroup,
  });
}

export function useMemo(): InjectionValue {
  return inject();
}
