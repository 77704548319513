
















































import { defineComponent } from '@vue/composition-api';
import CsvUploadButton from 'src/components/UIComponents/Buttons/CsvUploadButton/CsvUploadButton.vue';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { useUploadStaffShiftCsv } from '../../../hooks/useUploadStaffShiftCsv';

export default defineComponent({
  components: {
    CsvUploadButton,
    NormalButton,
  },
  setup() {
    const { target, showsStaffShiftCsvUploadModal, hideStaffShiftCsvUploadModal, uploadStaffShiftCsv } =
      useUploadStaffShiftCsv();

    return {
      target,
      showsStaffShiftCsvUploadModal,
      hideStaffShiftCsvUploadModal,
      uploadStaffShiftCsv,
    };
  },
});
