













import { defineComponent } from '@vue/composition-api'
import { orDefault } from 'src/util/filters'
import { useProgressHeader } from '../../composables/useProgressHeader'
import { formatFixedDecimal } from '../../utils/filters'
import HeaderItem from './HeaderItem.vue'

export default defineComponent({
  filters: {
    orDefault,
    formatFixedDecimal,
  },
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader()

    return {
      progressHeader,
    }
  },
})
