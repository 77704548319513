






















import { defineComponent, watch, type PropType } from '@vue/composition-api'
import { type BudgetGroup } from 'src/models/budgetGroup'

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<BudgetGroup | null>,
      default: null,
    },
    budgetGroups: {
      type: Array as PropType<BudgetGroup[]>,
      required: true,
    },
    // el-selectのdisabledを使うと、変更時に右側部分がちらつく。
    // ローディング時など入力内容を変更させない目的でdisabledを使うとこのちらつきが気になるため、
    // 代わりにinputイベントを無視するこのフラグを使用する。
    disallowed: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // 選択可能な管理グループに選択中の管理グループが含まれていなければ、選択可能な管理グループの先頭のものを選択中にする
    watch(
      () => props.budgetGroups,
      (budgetGroups) => {
        const hasValue = budgetGroups.some((budgetGroup) => budgetGroup.id === props.value?.id)
        const newValue = props.required ? (budgetGroups[0] ?? null) : null
        if (!hasValue && newValue !== props.value) { // newValue !== props.value は双方nullの場合を除外するため
          context.emit('input', newValue)
        }
      },
      {
        immediate: true,
      }
    )
  },
})
