
import { defineComponent, inject } from '@vue/composition-api'

import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift'
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal'
import { PageDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/usePageDisplay'
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState'

export default defineComponent({
  name: 'shift-table-header',

  setup() {
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const modalState = inject(ModalDisplayStateKey);
    const pageDisplayState = inject(PageDisplayStateKey);
    const commonState = inject(CommonStateKey);
    if (!staffWithShiftsState || !modalState || !pageDisplayState || !commonState) {
      throw new Error('State error');
    }
    const {
      clickReflectScheduleButton,
      clickChangedToAbsentButton,
      clickScheduledToNoShift,
      checkedCount,
      clickReflectProvisionalButton,
      clickBulkDestroyButton,
      isDetailDisplayConditionValid,
      staffsWithShifts,
      recordCount,
    } = staffWithShiftsState

    const {
      openSettingDisplayItemModal,
      openDisplayConditionSettingModal,
      openShiftBulkEditModal,
    } = modalState

    const {
      isStartEnd,
      changeStartEndAndTime,
    } = pageDisplayState

    const {
      workplaceId,
      budgetGroup,
    } = commonState

    return {
      openSettingDisplayItemModal,
      openDisplayConditionSettingModal,
      openShiftBulkEditModal,
      isStartEnd,
      changeStartEndAndTime,
      clickReflectScheduleButton,
      clickChangedToAbsentButton,
      checkedCount,
      isDetailDisplayConditionValid,
      clickScheduledToNoShift,
      workplaceId,
      budgetGroup,
      clickReflectProvisionalButton,
      clickBulkDestroyButton,
      staffsWithShifts,
      recordCount,
    }
  },
})

