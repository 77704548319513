import { defineComponent, PropType, reactive, SetupContext } from '@vue/composition-api'

type MoveHeadcountFunction = (headcount: number) => void

export type HeadcountMoveModalState = {
  isVisible: boolean
  headcount: number
  moveHeadcount: MoveHeadcountFunction
}

export const headcountMoveModalHooks = ({ moveHeadcount }: {moveHeadcount: MoveHeadcountFunction}) => {
  const state: HeadcountMoveModalState = reactive({
    isVisible: false,
    headcount: 0,
    moveHeadcount,
  })

  const openHeadcountMoveModal = (): void => {
    state.headcount = 0
    state.isVisible = true
  }

  const closeHeadcountMoveModal = (): void => {
    state.isVisible = false
  }

  return {
    state,
    openHeadcountMoveModal,
    closeHeadcountMoveModal,
  }
}

export default defineComponent({
  name: 'rescue-adjustment-headcount-move-modal',
  props: {
    state: {
      type: Object as PropType<HeadcountMoveModalState>,
      default: null,
    },
  },
  setup(props, context: SetupContext) {
    const closeHeadcountMoveModal = (): void => {
      context.emit('close')
    }
    const onMoveButtonClick = () => {
      const { headcount } = props.state
      if (isNaN(Number(headcount))) { return };
      props.state.moveHeadcount(Number(headcount))
    }
    return {
      closeHeadcountMoveModal,
      onMoveButtonClick,
    }
  },
})
