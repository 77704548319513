import { type ChartDataSets } from 'chart.js'
import { type TimetableHeader } from 'src/models/timetableHeader'
import {
  getProgressRate,
  getProgressColor,
  getHeadCountRate,
  getHeadCountColor,
} from '../../utils/timetableHeaderHelpers'

function getDataset(rate: number, color: string, weight: number): ChartDataSets {
  const normalizedRate = Math.min(Math.max(rate, 0), 100)
  return {
    data: [normalizedRate, 100 - normalizedRate],
    borderAlign: 'inner',
    borderWidth: 0,
    backgroundColor: [color, '#f2f2f2'],
    weight,
  }
}

function getBlankDataset(weight: number): ChartDataSets {
  return {
    data: [100],
    borderAlign: 'inner',
    borderWidth: 0,
    backgroundColor: '#fff',
    hoverBackgroundColor: '#fff',
    weight,
  }
}

function getProgressRateDataset(timetableHeader: TimetableHeader): ChartDataSets {
  const progressRate = getProgressRate(timetableHeader)
  const progressColor = getProgressColor(timetableHeader)
  return getDataset(progressRate ?? 0, progressColor, 7)
}

function getHeadCountRateDataset(timetableHeader: TimetableHeader): ChartDataSets {
  const headCountRate = getHeadCountRate(timetableHeader)
  const headCountColor = getHeadCountColor(timetableHeader)
  return headCountRate !== null ? getDataset(headCountRate, headCountColor, 3) : getBlankDataset(3)
}

export function getProgressChartDataset(timetableHeader: TimetableHeader): [ChartDataSets, ChartDataSets] {
  return [getProgressRateDataset(timetableHeader), getHeadCountRateDataset(timetableHeader)]
}
