



















import { defineComponent, ref, type PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'csv-upload',
  props: {
    upload: {
      type: Function as PropType<(file: File) => unknown>,
      required: true,
    },
  },
  setup(props) {
    const fileInput = ref<HTMLInputElement | null>(null);
    const isUploading = ref(false);
    const handleClick = () => {
      if (fileInput.value !== null) {
        fileInput.value.click();
      }
    };
    const handleChange = async (e: Event) => {
      const target = e.target as HTMLInputElement;
      const files = target.files;
      if (files && files.length > 0) {
        try {
          isUploading.value = true;
          await props.upload(files[0]);
        } finally {
          target.value = '';
          isUploading.value = false;
        }
      }
    };

    return {
      fileInput,
      isUploading,
      handleClick,
      handleChange,
    };
  },
});
