
import Vue from 'vue';
import {
  defineComponent,
  inject,
  reactive,
  watch,
  computed,
  ComputedRef,
  toRefs,
  SetupContext,
} from '@vue/composition-api';
import { SlideYUpTransition } from 'vue2-transitions';
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal';
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState';
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift';
import { ShiftRegisterStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShiftRegister';
import { generateEmptyTimeRange, generateEmptyTime } from 'src/views/Dashboard/Workplace/RegularShift/utils';
import {
  formatDateForRegularShift,
  getWeekDay,
  packToTimeIntegerWithGuard,
  timeDifferenceInSeconds,
} from 'src/util/datetime';
import { StaffWithShifts, TimeRange, TotalTime, UpdateShiftParam, Shift, PreShift } from 'src/models/regularShift';
import { LovEntry } from 'src/models/master';
import { ATTENDANCE_TYPE } from 'src/consts';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { Collapse, CollapseItem } from 'src/components/UIComponents';
import { isExist } from 'src/util/isExist';
import { ManHoursStateKey } from '../../../hooks/useManHours';
import { convertBreakTimesEmptyStringToNull } from '../../../libs/convertBreakTimesEmptyStringToNull';

const { NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE } = ATTENDANCE_TYPE;

const ATTENDANCE_AT_WORK: number[] = [NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE];

type ValidateState = {
  isPreShiftDuringAttendanceInvalid: ComputedRef<boolean>;
  isScheduledShiftDuringAttendanceInvalid: ComputedRef<boolean>;
  isActualShiftDuringAttendanceInvalid: ComputedRef<boolean>;
  isPreShiftWorkHoursInvalid: ComputedRef<boolean>;
  isPreShiftBreak1TimesInvalid: ComputedRef<boolean>;
  isPreShiftBreak2TimesInvalid: ComputedRef<boolean>;
  isScheduledShiftWorkHoursInvalid: ComputedRef<boolean>;
  isScheduledShiftBreak1TimesInvalid: ComputedRef<boolean>;
  isScheduledShiftBreak2TimesInvalid: ComputedRef<boolean>;
  isActualShiftWorkHoursInvalid: ComputedRef<boolean>;
  isPossibleOverTimeInvalid: ComputedRef<boolean>;
  isActualBreakTimeInvalid: ComputedRef<boolean>;
  isSelectedAttendanceTypeInvalid: ComputedRef<boolean>;
  isOvertimeExtraPercentInvalid: ComputedRef<boolean>;
  isStandardWorkingHoursInvalid: ComputedRef<boolean>;
  isHolidayExtraPercentInvalid: ComputedRef<boolean>;
  isMidnightExtraPercentInvalid: ComputedRef<boolean>;
  isMidnightExtraTargetTimeInvalid: ComputedRef<boolean>;
  isOverSixtyHoursExtraPercentInvalid: ComputedRef<boolean>;
  isHourlyCostInvalid: ComputedRef<boolean>;
  isMemoInvalid: ComputedRef<boolean>;
};

type InputState = {
  isInputPreShift: ComputedRef<boolean>;
  isInputScheduledShift: ComputedRef<boolean>;
  isInputActualShift: ComputedRef<boolean>;
};

export default defineComponent({
  name: 'shift-register-modal',
  components: {
    SlideYUpTransition,
    Collapse,
    CollapseItem,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    const modalState = inject(ModalDisplayStateKey);
    const commonState = inject(CommonStateKey);
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const shiftRegisterState = inject(ShiftRegisterStateKey);
    const manHoursState = inject(ManHoursStateKey);
    if (!modalState || !commonState || !staffWithShiftsState || !shiftRegisterState || !manHoursState) {
      throw new Error('State Error');
    }

    const { isDisplayShiftRegisterModal, closeShiftRegisterModal } = modalState;

    const { loadManHoursByMacroOperation } = manHoursState;

    const { attendanceTypes, attendanceSupplements, workplaceId, budgetGroup } = commonState;

    const { selectedStaffId, selectedDate, updateShift, staffsWithShifts } = staffWithShiftsState;

    const {
      preShiftId,
      preShiftAttendanceType,
      preShiftWorkHours,
      preShiftBreakTimes,
      shiftId,
      scheduledShiftAttendanceType,
      scheduledShiftWorkHours,
      scheduledShiftBreakTimes,
      possibleOverTime,
      actualShiftAttendanceType,
      shiftAttendanceSupplement,
      actualShiftWorkHours,
      actualBreakTime,
      hourlyCost,
      isChangeAtNight,
      isBeginner,
      memo,
      overtimeExtraRate,
      standardWorkingHours,
      holidayExtraRate,
      midnightExtraRate,
      overSixtyHoursExtraRate,
      midnightExtraTargetTime,
      standardWorkingHoursInt,
      overtimeExtraPercent,
      holidayExtraPercent,
      midnightExtraPercent,
      overSixtyHoursExtraPercent,
      resetState,
      setPreShiftForDisplay,
      setExtraWageConfigurations,
      setExtraWageConfigurationsFromStaffMaster,
      setScheduledAndActualFromShift,
      setPreShift,
      setScheduledShiftTimeFromPreShift,
      setActualToScheduled,
      preShiftTotalBreakTime,
      scheduledShiftTotalBreakTime,
    } = shiftRegisterState;

    const targetStaff: ComputedRef<StaffWithShifts | undefined> = computed(() => {
      const staff = staffsWithShifts.value.find((e) => e.staff_id === selectedStaffId.value);
      return staff;
    });
    const targetPreShift: ComputedRef<PreShift | undefined> = computed(() => {
      const targetPreShift = targetStaff.value?.orig_pre_shifts.find((e) => e.date === selectedDate.value) ?? undefined;
      return targetPreShift?.data ?? undefined;
    });
    const targetShift: ComputedRef<Shift | undefined> = computed(() => {
      const targetShift = targetStaff.value?.orig_shifts.find((e) => e.date === selectedDate.value) ?? undefined;
      return targetShift?.data ?? undefined;
    });

    // MEMO: modalの表示状態が変更されるたびに実行、modalが非表示になった場合はresetだけしてreturn
    watch([isDisplayShiftRegisterModal], () => {
      isDisplayShiftRegisterModal.value ? setShiftInfoToState() : resetState();
    });

    const setShiftInfoToState = () => {
      shiftId.value = targetShift.value?.id ?? 0;
      preShiftId.value = targetPreShift.value?.id ?? 0;

      setPreShiftForDisplay(targetPreShift.value, attendanceTypes.value);
      setScheduledAndActualFromShift(targetShift.value, attendanceTypes.value, attendanceSupplements.value);

      // 賃金設定
      if (targetShift.value) {
        setExtraWageConfigurations(targetShift.value);
      } else if (targetPreShift.value) {
        setExtraWageConfigurations(targetPreShift.value);
      } else {
        setExtraWageConfigurationsFromStaffMaster(targetStaff.value);
      }
    };

    // MEMO: 出勤系の勤怠が選択された場合に、staffマスタからデータをセットする
    watch([preShiftAttendanceType], () => {
      if (!selectedDate.value) {
        return;
      }
      if (
        isExist(preShiftAttendanceType.value) &&
        ATTENDANCE_AT_WORK.includes(Number(preShiftAttendanceType.value.key))
      ) {
        if (targetStaff.value) {
          setPreShift(targetStaff.value, selectedDate.value);
        }
      } else {
        preShiftWorkHours.value = generateEmptyTimeRange();
        preShiftBreakTimes.value = { break1: generateEmptyTimeRange(), break2: generateEmptyTimeRange() };
      }
    });

    watch([scheduledShiftAttendanceType], () => {
      if (!selectedDate.value) {
        return;
      }
      if (
        isExist(scheduledShiftAttendanceType.value) &&
        ATTENDANCE_AT_WORK.includes(Number(scheduledShiftAttendanceType.value.key))
      ) {
        if (targetStaff.value) {
          setScheduledShiftTimeFromPreShift(targetStaff.value, selectedDate.value);
        }
      } else {
        scheduledShiftWorkHours.value = generateEmptyTimeRange();
        scheduledShiftBreakTimes.value = { break1: generateEmptyTimeRange(), break2: generateEmptyTimeRange() };
        possibleOverTime.value = generateEmptyTime();
      }
    });

    watch([actualShiftAttendanceType], () => {
      if (!selectedDate.value) {
        return;
      }
      if (
        isExist(actualShiftAttendanceType.value) &&
        ATTENDANCE_AT_WORK.includes(Number(actualShiftAttendanceType.value.key))
      ) {
        if (targetStaff.value) {
          setActualToScheduled(targetStaff.value, selectedDate.value);
        }
      } else {
        actualShiftWorkHours.value = generateEmptyTimeRange();
        actualBreakTime.value = generateEmptyTime();
      }
    });

    const formatMinTwoDigits = (min: number): string => (min.toString().length >= 2 ? min.toString() : `0${min}`);

    const updateTargetShift = async () => {
      const params: UpdateShiftParam = {
        workplace_id: workplaceId.value,
        budget_group_id: budgetGroup.value.id,
        preShiftId: preShiftId.value,
        preShiftAttendanceType: preShiftAttendanceType.value,
        preShiftWorkHours: preShiftWorkHours.value,
        preShiftBreakTimes: convertBreakTimesEmptyStringToNull(preShiftBreakTimes.value),
        shiftId: shiftId.value,
        scheduledShiftAttendanceType: scheduledShiftAttendanceType.value,
        scheduledShiftWorkHours: scheduledShiftWorkHours.value,
        scheduledShiftBreakTimes: convertBreakTimesEmptyStringToNull(scheduledShiftBreakTimes.value),
        possibleOverTime: possibleOverTime.value,
        actualShiftAttendanceType: actualShiftAttendanceType.value,
        shiftAttendanceSupplement: shiftAttendanceSupplement.value,
        actualShiftWorkHours: actualShiftWorkHours.value,
        actualBreakTime: actualBreakTime.value,
        hourlyCost: hourlyCost.value,
        isChangeAtNight: isChangeAtNight.value,
        isBeginner: isBeginner.value,
        memo: memo.value,
        overtimeExtraRate: overtimeExtraRate.value,
        standardWorkingHours: standardWorkingHours.value,
        holidayExtraRate: holidayExtraRate.value,
        midnightExtraRate: midnightExtraRate.value,
        overSixtyHoursExtraRate: overSixtyHoursExtraRate.value,
        date: selectedDate.value,
        staffId: selectedStaffId.value,
        midnightExtraTargetTime: midnightExtraTargetTime.value,
        preShiftTotalBreakTime: preShiftTotalBreakTime.value,
        scheduledShiftTotalBreakTime: scheduledShiftTotalBreakTime.value,
      };

      try {
        await updateShift(params);
        await loadManHoursByMacroOperation();
        // モーダルをとじる
        closeShiftRegisterModal();
        selectedStaffId.value = null;
        notifySuccess1(root, 'シフトを変更しました。');
      } catch {
        notifyError1(root, 'シフトの変更に失敗しました。');
      }
    };

    // validate
    // 出勤系が選択された時に勤務時間を必須項目にする
    // true: validateに引っかかった false: validateに引っかかってない
    const validateState = reactive<ValidateState>({
      isPreShiftDuringAttendanceInvalid: computed(() => {
        return !validateDuringAttendance(preShiftAttendanceType.value, preShiftWorkHours.value);
      }),
      isScheduledShiftDuringAttendanceInvalid: computed(() => {
        return !validateDuringAttendance(scheduledShiftAttendanceType.value, scheduledShiftWorkHours.value);
      }),
      isActualShiftDuringAttendanceInvalid: computed(() => {
        return !validateDuringAttendance(actualShiftAttendanceType.value, actualShiftWorkHours.value);
      }),
      isPreShiftWorkHoursInvalid: computed(() => {
        return !validateInputTime(preShiftWorkHours.value);
      }),
      isPreShiftBreak1TimesInvalid: computed(() => {
        return !validateBreakTime(preShiftBreakTimes.value, 'break1', preShiftWorkHours.value);
      }),
      isPreShiftBreak2TimesInvalid: computed(() => {
        return !validateBreakTime(preShiftBreakTimes.value, 'break2', preShiftWorkHours.value);
      }),
      isScheduledShiftWorkHoursInvalid: computed(() => {
        return !validateInputTime(scheduledShiftWorkHours.value);
      }),
      isScheduledShiftBreak1TimesInvalid: computed(() => {
        return !validateBreakTime(scheduledShiftBreakTimes.value, 'break1', scheduledShiftWorkHours.value);
      }),
      isScheduledShiftBreak2TimesInvalid: computed(() => {
        return !validateBreakTime(scheduledShiftBreakTimes.value, 'break2', scheduledShiftWorkHours.value);
      }),
      isActualShiftWorkHoursInvalid: computed(() => {
        return !validateInputTime(actualShiftWorkHours.value, true);
      }),
      isPossibleOverTimeInvalid: computed(() => {
        return !validateInputHourAndMin(possibleOverTime.value);
      }),
      isActualBreakTimeInvalid: computed(() => {
        return !validateInputHourAndMin(actualBreakTime.value);
      }),
      isSelectedAttendanceTypeInvalid: computed(() => {
        const isNoSelected =
          !preShiftAttendanceType.value && !scheduledShiftAttendanceType.value && !actualShiftAttendanceType.value;
        return isNoSelected;
      }),
      isOvertimeExtraPercentInvalid: computed(() => {
        return !validateInputPercentage(overtimeExtraPercent.value);
      }),
      isStandardWorkingHoursInvalid: computed(() => {
        const workingHours = standardWorkingHoursInt.value;
        // 値が存在しない場合は true を返す
        if (!isExist(workingHours)) {
          return true;
        }

        const workingHoursNumber = Number(workingHours);

        // 値が数値変換不可の場合は true を返す
        if (isNaN(workingHoursNumber)) {
          return true;
        }

        // 値が 0~24 の範囲外の場合は true を返す
        if (workingHoursNumber < 0 || workingHoursNumber > 24) {
          return true;
        }

        // 小数点がある場合、小数点以下 2 桁以上の場合は true を返す
        const decimalPlaces = workingHours.toString().split('.')[1];
        return decimalPlaces ? decimalPlaces.length >= 2 : false;
      }),
      isHolidayExtraPercentInvalid: computed(() => {
        return !validateInputPercentage(holidayExtraPercent.value);
      }),
      isMidnightExtraPercentInvalid: computed(() => {
        return !validateInputPercentage(midnightExtraPercent.value);
      }),
      isMidnightExtraTargetTimeInvalid: computed(() => {
        return !validateInputTime(midnightExtraTargetTime.value, true);
      }),
      isOverSixtyHoursExtraPercentInvalid: computed(() => {
        return !validateInputPercentage(overSixtyHoursExtraPercent.value);
      }),
      isHourlyCostInvalid: computed(() => {
        return hourlyCost.value < 0 || hourlyCost.value >= 100000;
      }),
      isMemoInvalid: computed(() => {
        return memo.value !== null && memo.value.length > 255;
      }),
    });

    // 勤怠が出勤系の場合のバリデーション
    const validateDuringAttendance = (attendanceType: LovEntry | null, workHours: TimeRange): boolean => {
      if (!attendanceType) {
        return true;
      }
      if (!Number(attendanceType.key)) {
        return true;
      }
      const isAttendance = Number(attendanceType.key) in [NORMAL, HOLIDAY_WORK, SUBSTITUTE_ATTENDANCE];
      if (!isAttendance) {
        return true;
      }
      const isWorkHoursUninputted = Object.values(workHours).some((e) => !isExist(e));
      if (!isWorkHoursUninputted) {
        return true;
      }

      return false;
    };

    // { hour, min }形式の入力値のバリデーション
    const validateInputHourAndMin = (HourAndMin: TotalTime): boolean => {
      // 全て null の場合は true を返す
      if (Object.values(HourAndMin).every((e) => !isExist(e))) {
        return true;
      }

      // 入力値一箇所ごとのバリデーション
      if (!validInputValueConfiguration(HourAndMin)) {
        return false;
      }
      // 分が60以上の場合
      if (Number(HourAndMin.min) >= 60) {
        return false;
      }

      return true;
    };

    // 時間入力部分のバリデーション
    const validateInputTime = (workHours: TimeRange, isActual = false): boolean => {
      // 全て null の場合は true を返す
      if (Object.values(workHours).every((e) => !isExist(e))) {
        return true;
      }

      // 入力値一箇所ごとのバリデーション
      if (!validInputValueConfiguration(workHours)) {
        return false;
      }
      // 分の部分が 60 以上の場合と、15 分刻みでない場合
      if (!validMinConfiguration(workHours, isActual)) {
        return false;
      }
      // workHours 内の開始時間が終了時間以降の場合
      if (!validTimeConfiguration(workHours)) {
        return false;
      }
      // 終了時間が 48:00 以上の場合
      if (!valid48HourConfiguration(workHours)) {
        return false;
      }

      return true;
    };

    // 休憩時間のバリデーション
    const validateBreakTime = (
      { break1, break2 }: { break1: TimeRange; break2: TimeRange },
      targetBreak: 'break1' | 'break2',
      workHours: TimeRange,
    ): boolean => {
      const targetBreakTime = targetBreak === 'break1' ? break1 : break2;

      // 全て null or "" の場合は true を返す
      if (Object.values(targetBreakTime).every((e) => !isExist(e) || e === '')) {
        return true;
      }

      // 入力項目で未入力のものがある場合false
      if (Object.values(targetBreakTime).some((e) => !isExist(e) || e === '')) {
        return false;
      }

      // 時間入力のバリデーションに引っかかる場合
      if (!validateInputTime(targetBreakTime)) {
        return false;
      }
      // 勤務時間がない場合
      if (Object.values(workHours).some((e) => !isExist(e))) {
        return false;
      }
      // 勤務開始時間よりも休憩開始時間が前の場合
      const workStart = packToTimeIntegerWithGuard(workHours.startHour, workHours.startMin, 0);
      const breakStart = packToTimeIntegerWithGuard(targetBreakTime.startHour, targetBreakTime.startMin, 0);
      if (timeDifferenceInSeconds(workStart, breakStart) < 0) {
        return false;
      }
      // 休憩終了時間が勤務開始終了よりも後の場合
      const breakEnd = packToTimeIntegerWithGuard(targetBreakTime.endHour, targetBreakTime.endMin, 0);
      const workEnd = packToTimeIntegerWithGuard(workHours.endHour, workHours.endMin, 0);
      if (timeDifferenceInSeconds(breakEnd, workEnd) < 0) {
        return false;
      }
      // 対象が休憩 1 の場合はここまで
      if (targetBreak === 'break1') {
        return true;
      }

      // 休憩 2 はあるが 1 がない場合
      if (Object.values(break1).some((e) => !isExist(e) || e === '')) {
        return false;
      }
      // 休憩 1 の終了時間よりも、休憩 2 の開始時間が前の場合
      const break1End = packToTimeIntegerWithGuard(break1.endHour, break1.endMin, 0);
      const break2Start = packToTimeIntegerWithGuard(break2.startHour, break2.startMin, 0);
      if (timeDifferenceInSeconds(break1End, break2Start) < 0) {
        return false;
      }

      return true;
    };

    // 入力値一箇所ごとにバリデーション
    // バリデーションに引っかかった場合は false を返す
    const validInputValueConfiguration = (workHours: TimeRange | TotalTime): boolean => {
      // true: 正常, false: 異常
      return Object.values(workHours).every((e) => {
        // 値が null の場合は false を返す
        if (!isExist(e)) {
          return false;
        }
        // 値が 3 文字以上の場合は false を返す
        if (e.length >= 3) {
          return false;
        }
        // 値が数値変換不可の場合は false を返す
        if (isNaN(Number(e))) {
          return false;
        }
        return true;
      });
    };

    // workHours 内の開始時間が終了時間以降の場合は false を返す
    const validTimeConfiguration = (workHours: TimeRange): boolean => {
      // null が存在する場合 false を返す
      if (Object.values(workHours).some((e) => !isExist(e))) {
        return false;
      }
      const startTimeNum = Number(`${workHours.startHour}${formatMinTwoDigits(Number(workHours.startMin))}`);
      const endTimeNum = Number(`${workHours.endHour}${formatMinTwoDigits(Number(workHours.endMin))}`);

      return startTimeNum < endTimeNum;
    };

    // 分の部分の入力バリデーション
    const validMinConfiguration = (workHours: TimeRange, isActual: boolean): boolean => {
      // null が存在する場合 false を返す
      if (Object.values(workHours).some((e) => !isExist(e))) {
        return false;
      }
      // 分の部分が 60 以上の場合に false を返す
      if (Number(workHours.startMin) >= 60 || Number(workHours.endMin) >= 60) {
        return false;
      }
      // 出勤実績の場合は 15 分刻みチェックを行わずにここで終了
      if (isActual) {
        return true;
      }

      // 分の部分が 15 分刻みかのチェック
      if (Number(workHours.startMin) % 15 !== 0 || Number(workHours.endMin) % 15 !== 0) {
        return false;
      }

      return true;
    };

    // % の入力バリデーション
    const validateInputPercentage = (percentage: number): boolean => {
      // null の場合は false を返す
      if (!isExist(percentage)) {
        return false;
      }

      const percentageNumber = Number(percentage);

      // 値が数値変換不可の場合は false を返す
      if (isNaN(percentageNumber)) {
        return false;
      }
      // 小数点がある場合は false を返す
      if (percentage.toString().indexOf('.') > 0) {
        return false;
      }
      // 0~100 の範囲外の場合は false を返す
      if (percentageNumber < 0) {
        return false;
      }
      if (percentageNumber > 100) {
        return false;
      }

      return true;
    };

    // 48:00 以上の場合は false を返す
    const valid48HourConfiguration = (workHours: TimeRange): boolean => {
      // null が存在する場合 false を返す
      if (Object.values(workHours).some((e) => !isExist(e))) {
        return false;
      }
      // 48:00 以上の場合は false を返す
      if (Number(workHours.endHour) >= 48) {
        return false;
      }

      return true;
    };

    // 実行ボタンをdisabledか否かの判定をする
    const unclickableActionButton = computed(() => {
      return Object.values(validateState).some((e) => e);
    });

    // チェックボックスの変更
    const changeIsChangeAtNight = (): void => {
      isChangeAtNight.value = !isChangeAtNight.value;
    };

    const changeIsBeginner = (): void => {
      isBeginner.value = !isBeginner.value;
    };

    // 時刻入力の活性の状態
    const inputState = reactive<InputState>({
      isInputPreShift: computed(() => {
        return ATTENDANCE_AT_WORK.indexOf(Number(preShiftAttendanceType.value?.key)) !== -1;
      }),
      isInputScheduledShift: computed(() => {
        return ATTENDANCE_AT_WORK.indexOf(Number(scheduledShiftAttendanceType.value?.key)) !== -1;
      }),
      isInputActualShift: computed(() => {
        return ATTENDANCE_AT_WORK.indexOf(Number(actualShiftAttendanceType.value?.key)) !== -1;
      }),
    });

    return {
      isDisplayShiftRegisterModal,
      closeShiftRegisterModal,
      attendanceTypes,
      attendanceSupplements,
      targetStaff,
      updateTargetShift,
      preShiftTotalBreakTime,
      scheduledShiftTotalBreakTime,
      ...toRefs(validateState),
      unclickableActionButton,
      formatDateForRegularShift,
      getWeekDay,
      changeIsChangeAtNight,
      changeIsBeginner,
      preShiftId,
      preShiftAttendanceType,
      preShiftWorkHours,
      preShiftBreakTimes,
      shiftId,
      scheduledShiftAttendanceType,
      scheduledShiftWorkHours,
      scheduledShiftBreakTimes,
      possibleOverTime,
      actualShiftAttendanceType,
      shiftAttendanceSupplement,
      actualShiftWorkHours,
      actualBreakTime,
      hourlyCost,
      isChangeAtNight,
      isBeginner,
      memo,
      overtimeExtraRate,
      standardWorkingHours,
      holidayExtraRate,
      midnightExtraRate,
      overSixtyHoursExtraRate,
      selectedDate,
      midnightExtraTargetTime,
      standardWorkingHoursInt,
      overtimeExtraPercent,
      holidayExtraPercent,
      midnightExtraPercent,
      overSixtyHoursExtraPercent,
      ...toRefs(inputState),
    };
  },
});
