



import { computed, defineComponent, type PropType } from '@vue/composition-api';
import { orDefault } from 'src/util/filters';
import { formatTime } from '../../utils/filters';
import { TimetableGroupMasterWithProgressHeaders } from 'src/models/timetableGroupMaster';

export default defineComponent({
  filters: {
    orDefault,
    formatTime,
  },
  props: {
    timetableGroupMaster: {
      type: Object as PropType<TimetableGroupMasterWithProgressHeaders>,
      required: true,
    },
  },
  setup(props) {
    const endTime = computed(() =>
      props.timetableGroupMaster.timetable_masters.reduce((endTime: number | null, timetableMaster) => {
        const eachEndTime = timetableMaster.progress_headers.reduce((endTime: number | null, progressHeader) => {
          return progressHeader.end_time === null ? endTime : Math.max(endTime ?? 0, progressHeader.end_time);
        }, null);

        return eachEndTime === null ? endTime : Math.max(endTime ?? 0, eachEndTime);
      }, null),
    );

    return {
      endTime,
    };
  },
});
