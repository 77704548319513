import { StaffShift } from 'src/models/staffShift';
import {
  absenceTypes,
  atWorkTypes,
  DisplayTimeMap,
  InitialShiftInfo,
  ListState,
  StaffShiftConv,
  WORKING_TIME_DISPLAY_TYPE,
} from 'src/views/Dashboard/Workplace/RegularShift/types';
import { ATTENDANCE_TYPE, SCHED_ACTUAL_MODE, SchedActualMode } from 'src/consts';
import { isExist } from 'src/util/isExist';
import { LovEntry } from 'src/models/api/masterResponse';
import { computed, reactive } from '@vue/composition-api';
import { formatTimeInteger, timeIntegerAdd, timeIntegerDiff } from 'src/util/datetime';
import { Staff } from 'src/models/staff';
import { StaffMonthlySummary } from 'src/models/staffMonthlySummary';
import { TimeRange, TotalTime } from 'src/models/regularShift';

export function getDisplayTimeMap(shift: StaffShift): DisplayTimeMap {
  const scheduled = {
    mode: SCHED_ACTUAL_MODE.SCHEDULED,
    startingTime: shift.scheduled_work_start_time,
    closingTime: shift.scheduled_work_end_time,
    breakTime: shift.scheduled_break_time,
    possibleOverTime: shift.possible_over_time,
    workingTimeTotal: shift.scheduled_hours_total,
    overTimeTotal: shift.scheduled_hours_overtime,
  };
  const actual = {
    mode: SCHED_ACTUAL_MODE.ACTUAL,
    startingTime: shift.actual_work_start_time,
    closingTime: shift.actual_work_end_time,
    breakTime: shift.actual_break_time,
    possibleOverTime: null,
    workingTimeTotal: shift.actual_hours_total,
    overTimeTotal: shift.actual_hours_overtime,
  };
  return {
    [SCHED_ACTUAL_MODE.SCHEDULED]: scheduled,
    [SCHED_ACTUAL_MODE.ACTUAL]: actual,
    [SCHED_ACTUAL_MODE.EITHER]: isExist(actual.startingTime) && isExist(actual.closingTime) ? actual : scheduled,
  };
}

// 曜日ごとのシフト情報初期値を取得するやつ
export function getInitialShiftInfo(staff: Staff, dt: Date): InitialShiftInfo {
  const dayOfWeek = dt.getDay();
  const staffWorkSchedule = staff.staff_extension?.staff_work_schedules?.find((e) => e.day_of_week === dayOfWeek);
  const regularStartingTime = staffWorkSchedule?.work_start_time ?? null;
  const regularClosingTime = staffWorkSchedule?.work_end_time ?? null;
  // 日曜は休日出勤でよいが、土曜はどうすればいいかわからないのでとりあえず通常出勤にしておく.
  const attendanceType = dayOfWeek === 0 ? ATTENDANCE_TYPE.HOLIDAY_WORK : ATTENDANCE_TYPE.NORMAL;
  const ret: InitialShiftInfo = {
    attendanceType: attendanceType,
    scheduledStartingTime: regularStartingTime,
    scheduledClosingTime: regularClosingTime,
    scheduledBreak1StartTime: staffWorkSchedule?.break1_start_time ?? null,
    scheduledBreak1EndTime: staffWorkSchedule?.break1_end_time ?? null,
    scheduledBreak2StartTime: staffWorkSchedule?.break2_start_time ?? null,
    scheduledBreak2EndTime: staffWorkSchedule?.break2_end_time ?? null,
    scheduledBreakTime: null, // このあと
  };
  const break1Time =
    ret.scheduledBreak1StartTime !== null && ret.scheduledBreak1EndTime !== null
      ? timeIntegerDiff(ret.scheduledBreak1StartTime, ret.scheduledBreak1EndTime)
      : 0;
  const break2Time =
    ret.scheduledBreak2StartTime !== null && ret.scheduledBreak2EndTime !== null
      ? timeIntegerDiff(ret.scheduledBreak2StartTime, ret.scheduledBreak2EndTime)
      : 0;
  ret.scheduledBreakTime = timeIntegerAdd(break1Time, break2Time);
  return ret;
}

// 年合計のデフォルトobj
export function getInitialMonthlySummary(
  staffId: number,
  year: number,
  month: number,
  mode: SchedActualMode,
): StaffMonthlySummary {
  return {
    id: -1,
    staff_id: staffId,
    year: year,
    month: month, // 1-12
    mode: mode,
    days_worked: 0,
    paid_holidays: 0,
    substitute_days_worked: 0,
    substitute_holidays: 0,
    hours_normal: 0,
    hours_overtime: 0,
    hours_midnight: 0,
    hours_holiday: 0,
    hours_over_forty_in_week: 0,
    hours_over_sixty_in_month: 0,
    hours_total: 0,
    normal_cost_sum: 0,
    overtime_cost_sum: 0,
    midnight_cost_sum: 0,
    holiday_cost_sum: 0,
    over_forty_in_week_cost_sum: 0,
    over_sixty_in_month_cost_sum: 0,
    all_cost_sum: 0,
  };
}

export const generateEmptyTimeRange = (): TimeRange => ({
  startHour: null,
  startMin: null,
  endHour: null,
  endMin: null,
});
export const generateEmptyTime = (): TotalTime => ({ hour: null, min: null });
