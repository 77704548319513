



















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    leftLabel: {
      type: String,
      default: '',
    },
    rightLabel: {
      type: String,
      default: '',
    },
    hasSeparator: {
      type: Boolean,
      default: false,
    },
  }
})
