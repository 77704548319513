import { type Validator } from 'src/composables/useCustomValidator'
import { type TimeInteger } from 'src/models/common'
import { unpackTimeInteger } from 'src/util/datetime'
import {
  type TimeRange,
  type StrictTimeRange,
  isTimeRange,
  isStrictTimeRange,
  isValidTimeRange,
  containsTimeRange,
  overlapsWithTimeRange,
} from 'src/values/TimeRange'

function isObject(value: unknown): value is Record<keyof any, unknown> {
  return typeof value === 'object' && value !== null
}

function hasTimeRange(value: unknown): value is { timeRange: unknown } {
  return isObject(value) && 'timeRange' in value
}

function getTimeRange(value: unknown): TimeRange | null | undefined {
  if (hasTimeRange(value) && (isTimeRange(value.timeRange) || value.timeRange === null)) {
    return value.timeRange
  }
  return undefined
}

function isValidMinutes(time: TimeInteger): boolean {
  const [_, minutes] = unpackTimeInteger(time)
  return [0, 15, 30, 45].includes(minutes)
}

function isValidInputTimeRange(value: unknown): value is StrictTimeRange {
  return isStrictTimeRange(value) &&
    isValidTimeRange(value) &&
    isValidMinutes(value.startTime) &&
    isValidMinutes(value.endTime)
}

export const timeRangeValidator: Validator = {
  name: 'timeRange',
  validate(value: unknown) {
    return value === null || isValidInputTimeRange(value)
  },
}

export const containedValidator: Validator = {
  name: 'contained',
  validate(value: unknown, args: unknown) {
    const timeRange = getTimeRange(args)

    if (timeRange === undefined) {
      return false // 設定ミスで取得できない場合はエラーにする
    }

    if (!isValidInputTimeRange(timeRange) || !isValidInputTimeRange(value)) {
      return true // 他のバリデーションでチェックするのでエラーにしない
    }

    return containsTimeRange(timeRange, value)
  },
  options: {
    hasTarget: true,
    paramNames: ['timeRange'],
  },
}

export const noOverlapValidator: Validator = {
  name: 'noOverlap',
  validate(value: unknown, args: unknown) {
    const timeRange = getTimeRange(args)

    if (timeRange === undefined) {
      return false // 設定ミスで取得できない場合はエラーにする
    }

    if (!isValidInputTimeRange(timeRange) || !isValidInputTimeRange(value)) {
      return true // 他のバリデーションでチェックするのでエラーにしない
    }

    return !overlapsWithTimeRange(timeRange, value)
  },
  options: {
    hasTarget: true,
    paramNames: ['timeRange'],
  },
}
