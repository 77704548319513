





import { defineComponent } from '@vue/composition-api'
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton'

export default defineComponent({
  components: {
    NormalButton,
  },
})
