import { SetupContext } from '@vue/composition-api'
import { Location, Route } from 'vue-router'
import { SidebarLink, UnresolvedSidebarLink } from 'src/components/UIComponents/Sidebar/createSidebarStore'

export function isGuestPage(routeName?: string | null): boolean {
  if (!routeName) { return false }
  const arr = ['Login', 'PasswordReset', 'ManualLogin']
  return arr.indexOf(routeName) !== -1
}

export function getSidebarLinkGroupNameFromRoute(route: Route) {
  const routePath = route.path
  const routeName = route.name
  let ret = 'top'
  // 先頭がworkplaceだったら大体Workplaceグループ
  if (routePath.startsWith('/workplace')) {
    if (routeName === 'WorkplaceSelect') {
      // センター選択はトップグループ
      ret = 'top'
    } else {
      ret = 'workplace'
    }
  }
  return ret
}

export function resolveLinkGroupData(linkGroup: UnresolvedSidebarLink[], routeTo: Route): SidebarLink[] {
  return linkGroup.map((sbl: UnresolvedSidebarLink) => {
    const ret: SidebarLink = Object.assign({ path: '', activePath: '' }, sbl)
    // 候補の中からpathを決定する
    ret.path = sbl.pathCandMap.default
    if (routeTo.name && sbl.pathCandMap[routeTo.name]) {
      ret.path = sbl.pathCandMap[routeTo.name]
    }
    // あとで使うかもしれないのでdefaultPathもついでに置換.
    let defaultPath = sbl.pathCandMap.default

    // 動的項目を抽出、置換.
    const dynamicPathParams = ret.path.split('/')
      .filter(e => e.match(/^:(\w|\d)+$/))
      .map(e => e.slice(1))
    dynamicPathParams.forEach(param => {
      if (routeTo.params[param]) {
        ret.path = ret.path!.replace(`:${param}`, routeTo.params[param])
        defaultPath = defaultPath.replace(`:${param}`, routeTo.params[param])
      } else if (param === 'date') {
        // dateについては、なかったらtodayにしといてやる.
        ret.path = ret.path!.replace(`:${param}`, 'today')
        defaultPath = defaultPath.replace(`:${param}`, 'today')
      }
    })
    // 矢印は出しておきたいがリンク先は常に固定したいケースがあったので、
    // リンク先(path)とアクティブ判定(activePath)を分けた.
    ret.activePath = ret.path
    if (ret.alwaysLinkToDefault) {
      // この機能を使用する場合、同一pathCandMap内の
      // defaultPath以外のpathは、defaultPathに設定されているparamを全て
      // 含んでいなければならない.
      // (そうしないと置換されてないパラメータが残ってしまう)
      ret.path = defaultPath
    }
    return ret
  })
}

export async function routerShowNextPage(context: SetupContext, evt: MouseEvent | KeyboardEvent, routeObj: Location) {
  const root = context.root as Vue
  if (evt.ctrlKey || evt.metaKey) {
    const obj = root.$router.resolve(routeObj)
    window.open(obj.href, '_blank')
  } else {
    const currentRoute = root.$router.currentRoute
    if (currentRoute.name === routeObj.name) {
      // prevent 'Navigation Duplicated' error.
      const nextRoute = root.$router.resolve(routeObj).route
      const areAllParamsSame = Object.entries(currentRoute.params).every(([k, v]) => {
        return v === nextRoute.params[k]
      })
      const areAllQueriesSame = Object.entries(currentRoute.query).every(([k, v]) => {
        return v === nextRoute.query[k]
      })
      if (areAllParamsSame && areAllQueriesSame) { return }
    }
    await root.$router.push(routeObj)
  }
}
