















import { defineComponent, SetupContext, computed, PropOptions, PropType } from '@vue/composition-api'
const props: Record<string, PropType<any> | PropOptions> = {
  tag: {
    type: String,
    default: 'button',
  },
  type: {
    type: String,
    default: 'default',
  },
  nativeType: {
    type: String,
    default: 'button',
  },
  round: Boolean,
  outline: Boolean,
  block: Boolean,
  social: Boolean,
  link: Boolean,
  icon: Boolean,
  wide: Boolean,
  size: String
}

export default defineComponent({
  name: 'p-button',
  props: props,
  setup(props, context: SetupContext) {
    const classes = computed(() => {
      const btnClasses = [
        { [`btn-outline-${props.type}`]: props.outline },
        { 'btn-icon': props.icon },
        { 'btn-round': props.round },
        { 'btn-block': props.block },
        { 'btn-social': props.social },
        { 'btn-link': props.link },
        { 'btn-wd': props.wide },
        `btn-${props.type}`
      ]
      if (props.size) {
        btnClasses.push(`btn-${props.size}`)
      }
      return btnClasses
    })
    function handleClick(evt: any): void {
      context.emit('click', evt)
    }
    return {
      classes,
      handleClick,
    }
  }
})
