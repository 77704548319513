import { createFilter, orDefault } from 'src/util/filters'
import { unpackTimeIntegerToStringFormat } from 'src/util/datetime'
import { addCommaFraction } from 'src/filters/number_filters'

export { orDefault }

export const formatTime = createFilter(unpackTimeIntegerToStringFormat)

export const formatInteger = createFilter((v: number) => addCommaFraction(v, 0))

export const formatDecimal = createFilter(
  (v: number) => v.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 })
)
