import { BudgetGroupPlanBoardMisc, convertBudgetGroupPlanBoardMiscResponse } from 'src/models/budgetGroupPlanBoardMisc';
import { RescueDemand, convertRescueDemandResponse } from 'src/models/rescueDemand';
import { RescueAdjustmentStatus, convertRescueAdjustmentStatusResponse } from 'src/models/rescueAdjustmentStatus';
import { StaffShift, convertStaffShiftResponse } from 'src/models/staffShift';
import { CollectiveStaffShift, convertCollectiveStaffShiftResponse } from 'src/models/collectiveStaffShift';
import { TimetableHeader, convertTimetableHeaderResponse } from 'src/models/timetableHeader';
import { PlanBoardBulkUpdateResponse, PlanBoardIndexResponse } from 'src/models/api/planBoardResponse';

export interface PlanBoardIndexResult {
  // workplace_extensionはstoreから取る
  budget_group_plan_board_misc: BudgetGroupPlanBoardMisc | null
  rescue_demand: RescueDemand | null
  rescue_adjustment_statuses: RescueAdjustmentStatus[]
  // staff, staff_skillsが入っている想定
  staff_shifts: StaffShift[]
  // collective_staff, collective_staff_skillsが入っている想定
  collective_staff_shifts: CollectiveStaffShift[]
  // plan_board_blocksが入っている想定
  timetable_headers: TimetableHeader[]
}

export type PlanBoardBlockBulkUpdateResult = PlanBoardBulkUpdateResponse

export function convertPlanBoardIndexResult(data: PlanBoardIndexResponse): PlanBoardIndexResult {
  return {
    budget_group_plan_board_misc: data.budget_group_plan_board_misc
      ? convertBudgetGroupPlanBoardMiscResponse(data.budget_group_plan_board_misc)
      : null,
    rescue_demand: data.rescue_demand
      ? convertRescueDemandResponse(data.rescue_demand)
      : null,
    rescue_adjustment_statuses: data.rescue_adjustment_statuses.map(convertRescueAdjustmentStatusResponse),
    staff_shifts: data.staff_shifts.map(convertStaffShiftResponse),
    collective_staff_shifts: data.collective_staff_shifts.map(convertCollectiveStaffShiftResponse),
    timetable_headers: data.timetable_headers.map(convertTimetableHeaderResponse),
  }
}
