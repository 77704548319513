







import { defineComponent, ref, watch, type PropType } from '@vue/composition-api'
import { TimeInput } from 'src/components/UIComponents/Inputs/TimeInput'
import { type TimeRange, createTimeRange } from 'src/values/TimeRange'

export default defineComponent({
  components: {
    TimeInput,
  },
  props: {
    value: {
      type: Object as PropType<TimeRange | null>,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const startTime = ref(props.value?.startTime ?? null)
    const endTime = ref(props.value?.endTime ?? null)

    watch([startTime, endTime], ([startTime, endTime]) => {
      context.emit('input', createTimeRange(startTime, endTime))
    })

    return {
      startTime,
      endTime,
    }
  },
})
