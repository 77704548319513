


































import { defineComponent } from '@vue/composition-api';
import TimetableMasterListRow from './TimetableMasterListRow.vue';
import TimetableMasterListCell from './TimetableMasterListCell.vue';

export default defineComponent({
  components: {
    TimetableMasterListRow,
    TimetableMasterListCell,
  },
});
