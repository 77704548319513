import { BudgetGroupExtensionResponse } from 'src/models/api/budgetGroupExtensionResponse';

export type BudgetGroupExtension = BudgetGroupExtensionResponse

export const constructEmptyBudgetGroupExtension = (): BudgetGroupExtension => {
  return {
    id: 0,
    budget_group_id: 0,
    use_regular_shift: false,
    is_revenue_save: false,
    is_cost_save: false,
    is_indirect_cost_save: false,
  }
}

export function convertBudgetGroupExtensionResponse(response: BudgetGroupExtensionResponse): BudgetGroupExtension {
  return { ...response }
}
