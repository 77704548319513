import Vue from 'vue';
import { NotifyOptions } from 'vue-notifyjs';

function doNotify(vue: Vue, msg: string, opts: NotifyOptions = {}) {
  const notifyObj: NotifyOptions = {
    message: msg,
    verticalAlign: opts.verticalAlign || 'top', // top | bottom
    horizontalAlign: opts.horizontalAlign || 'center', // right | center | left
    type: opts.type || 'info', // info | warning | danger | success | primary
    timeout: opts.timeout || 5 * 1000,
  };
  if (opts.title) {
    notifyObj.title = opts.title;
  }
  if (opts.icon) {
    notifyObj.icon = opts.icon;
  }
  if (opts.component) {
    notifyObj.component = opts.component;
  }

  vue.$notify(notifyObj);
}

export function notifySuccess1(vue: Vue, msg: string, opts: NotifyOptions = {}) {
  opts.timeout = opts.timeout || 3 * 1000;
  doNotify(vue, msg, opts);
}

export function notifyError1(vue: Vue, msg: string, opts: NotifyOptions = {}) {
  console.error(msg);
  if (opts.err) {
    console.error(opts.err);
  }
  opts.type = opts.type || 'danger';
  opts.timeout = opts.timeout || 60 * 1000;
  opts.icon = opts.icon || 'fas fa-exclamation-triangle';
  doNotify(vue, msg, opts);
}

export function notifyError2(vue: Vue, msg: string, opts: NotifyOptions = {}) {
  opts.verticalAlign = opts.verticalAlign || 'bottom';
  notifyError1(vue, msg, opts);
}
