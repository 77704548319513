

























































import Vue from 'vue';
import { computed, defineComponent, reactive, SetupContext } from '@vue/composition-api';
import { Route } from 'vue-router';
import { Navbar } from 'src/components/UIComponents';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { Workplace } from 'src/models/workplace';
import { useLogiAuth } from 'src/util/logibase';

interface State {
  showNavbar: boolean;
  workplace: Workplace | null;
  workplaceId: string;
  fullName: string;
  env: string;
  pageName: string;
  isDev: boolean;
  isStg: boolean;
  isDemo: boolean;
  isQA: boolean;
}

function setupState(context: SetupContext): State {
  const root = context.root as Vue;
  const state: State = reactive({
    showNavbar: false,
    workplace: null,
    workplaceId: computed(() => root.$route.params.workplaceId),
    ...wrappedMapGetters(root.$store, 'user', ['fullName', 'env']),
    ...wrappedMapGetters(root.$store, 'displayPageName', ['pageName']),
    isDev: computed(() => state.env === 'development'),
    isStg: computed(() => state.env === 'staging' || state.env === 'stg'),
    isDemo: computed(() => state.env.startsWith('demo')),
    isQA: computed(() => state.env.startsWith('qa')),
  });
  return state;
}

export default defineComponent({
  components: { Navbar },
  props: {
    sidebarStore: {
      type: Object,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue;
    const state = setupState(context);

    // function toggleSidebar() {
    //   props.sidebarStore.displaySidebar(!props.sidebarStore.showSidebar)
    // }
    // function hideSidebar() {
    //   props.sidebarStore.displaySidebar(false)
    // }
    // function minimizeSidebar() {
    //   props.sidebarStore.toggleMinimize()
    // }
    // function toggleNavbar() {
    //   state.showNavbar = !state.showNavbar
    // }
    async function logout() {
      // When using LogiAuth,
      // SP's logging out process will be triggered from IDP anyway but,
      // just in case of when IDP session is dead, going to run log-out process as usual.
      await root.$store.dispatch('user/logout');
      if (useLogiAuth()) {
        location.href = '/users/saml/idp_logout';
      } else {
        // want to explicitly reload
        location.href = '/login';
      }
    }

    async function updateInternalWorkplaceInfo() {
      if (!state.workplaceId) {
        state.workplace = null;
      } else if (!state.workplace || state.workplace.id !== parseInt(state.workplaceId)) {
        state.workplace = await root.$store.dispatch('workplace/fetchWorkplaceById', {
          workplaceId: state.workplaceId,
        });
      }
    }

    async function afterRouteChange(_toRoute: Route) {
      await updateInternalWorkplaceInfo();
    }

    ensureUserAndMasters(context).then(() => {
      afterRouteChange(root.$route);
      root.$router.afterEach((to, _from) => {
        afterRouteChange(to);
      });
    });

    return {
      state,
      logout,
    };
  },
});
