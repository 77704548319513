







import { defineComponent, type PropType } from '@vue/composition-api'
import { BackgroundProcessStatus } from 'src/models/backgroundProcessStatus'

export default defineComponent({
  props: {
    status: {
      type: Number as PropType<BackgroundProcessStatus['status']>,
      required: true,
    },
  },
})
