import { getCurrentInstance, computed, onMounted, type ComputedRef } from '@vue/composition-api';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { type WorkplaceExtension } from 'src/models/workplaceExtension';

type ReturnValue = {
  hasCustomSkillName1: ComputedRef<boolean>;
  hasCustomSkillName2: ComputedRef<boolean>;
  hasCustomSkillName3: ComputedRef<boolean>;
};

function hasValidName(name: string | null | undefined): boolean {
  return (name ?? '') !== '';
}

export function useWorkplaceExtension(): ReturnValue {
  const root = getCurrentInstance()?.proxy!;
  const workplaceExtension = computed<WorkplaceExtension | null>(
    () => root.$store.getters['workplace/workplaceExtension'],
  );
  const hasCustomSkillName1 = computed(() => hasValidName(workplaceExtension.value?.custom_skill_name1));
  const hasCustomSkillName2 = computed(() => hasValidName(workplaceExtension.value?.custom_skill_name2));
  const hasCustomSkillName3 = computed(() => hasValidName(workplaceExtension.value?.custom_skill_name3));

  onMounted(async () => {
    await ensureUserAndMasters(root);
  });

  return {
    hasCustomSkillName1,
    hasCustomSkillName2,
    hasCustomSkillName3,
  };
}
