



















import { defineComponent, toRef, type PropType } from '@vue/composition-api'
import { type ProgressHeader } from 'src/models/progressHeader'
import { useProgressHeaderProvider } from '../../composables/useProgressHeader'
import { TimetableNamePanel } from '../TimetableNamePanel'
import { HeaderPanel } from '../HeaderPanel'
import { GraphPanel } from '../GraphPanel'
import { QuickRegisterPanel } from '../QuickRegisterPanel'
import { ActualCompletionTimeRegisterPanel } from '../ActualCompletionTimeRegisterPanel'

export default defineComponent({
  components: {
    TimetableNamePanel,
    HeaderPanel,
    GraphPanel,
    QuickRegisterPanel,
    ActualCompletionTimeRegisterPanel,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup(props) {
    useProgressHeaderProvider({
      progressHeader: toRef(props, 'progressHeader'),
    })
  },
})
