import backgroundProcessStatusApi from 'src/apis/backgroundProcessStatus'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type BackgroundProcessStatus } from 'src/models/backgroundProcessStatus'

type DownloadCsv = {
  downloadCsv: (backgroundProcessStatus: BackgroundProcessStatus) => Promise<boolean>
}

export function useDownloadCsv(): DownloadCsv {
  const errorBoundary = useErrorBoundary()
  const downloadCsv = errorBoundary.wrap(
    async(backgroundProcessStatus: BackgroundProcessStatus) => {
      if (backgroundProcessStatus.csv === null) {
        throw new Error('csv is not set')
      }
      const { data } = await backgroundProcessStatusApi.download({
        workplace_id: backgroundProcessStatus.workplace_id,
        id: backgroundProcessStatus.id,
      })
      const blob = new Blob([data], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = backgroundProcessStatus.csv
      link.click()
      window.URL.revokeObjectURL(url)
    },
  )

  return {
    downloadCsv,
  }
}
