<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex';
import workplaceApi from 'src/apis/workplace';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { userHasWorkplaceRoleGteOwner, userHasWorkplaceRoleGteLeader } from 'src/hooks/appRoleHook';
import { useLogiCoredata } from 'src/util/logibase';
import { LogiCoreDataLinkButton } from './components/LogiCoreDataLinkButton';
import { SettingLinkCard } from './components/SettingLinkCard';

export default {
  components: {
    LogiCoreDataLinkButton,
    SettingLinkCard,
  },
  data() {
    return {
      workplace: null,
      isReady: false,
      isGteLeader: false,
      isGteOwner: false,
    };
  },
  computed: {
    workplaceId() {
      return Number(this.$route.params.workplaceId);
    },
    useLogiCoredata,
    ...mapGetters('workplace', {
      useStaffAgency: 'useStaffAgency',
      useStaffLabel: 'useStaffLabel',
    }),
  },
  async mounted() {
    setPageName(this, '設定一覧');
    const { data } = await workplaceApi.show(this.workplaceId);
    this.workplace = data;
    this.isGteLeader = await userHasWorkplaceRoleGteLeader(this, this.workplaceId);
    this.isGteOwner = await userHasWorkplaceRoleGteOwner(this, this.workplaceId);
    this.isReady = true;
  },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
