import axios from 'axios';
import * as utils from 'src/apis/apiUtils';
import { StringOrNumber } from 'src/models/common';
import {
  BulkUpdateOptParams,
  StaffOptParams,
  UpdateOptParams,
  ImportCsvParams,
} from 'src/models/api/staffExtensionRequest';
import { ImportCsvResponse } from 'src/models/api/staffExtensionResponse';
import { convertStaffResponse, Staff } from 'src/models/staff';

export default {
  async staffs({ workplaceId, params }: { workplaceId: StringOrNumber; params: StaffOptParams }): Promise<Staff[]> {
    const { data } = await axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_extension/staffs`), {
      params,
    });
    return data.map(convertStaffResponse);
  },
  async update({ workplaceId, data }: { workplaceId: StringOrNumber; data: UpdateOptParams }): Promise<Staff> {
    const { data: reqData } = await axios.put(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_extension`),
      data,
    );
    return convertStaffResponse(reqData);
  },
  async bulkUpdate({
    workplaceId,
    data,
  }: {
    workplaceId: StringOrNumber;
    data: BulkUpdateOptParams;
  }): Promise<Staff[]> {
    const { data: reqData } = await axios.post(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_extension/bulk_update`),
      data,
    );
    return reqData.map(convertStaffResponse);
  },
  async importCsv({ workplaceId, csv }: ImportCsvParams): Promise<ImportCsvResponse> {
    const data = new FormData();
    data.append('csv', csv);
    const response = await axios.post(`/masters/workplaces/${workplaceId}/staff_extension/import_csv`, data);
    return response.data;
  },
};
