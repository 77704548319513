




















import { defineComponent, type PropType } from '@vue/composition-api';
import { type BackgroundProcessStatus } from 'src/models/backgroundProcessStatus';
import { getProcessName } from '../../utils/getProcessName';

export default defineComponent({
  props: {
    value: {
      type: Number as PropType<BackgroundProcessStatus['process_type'] | null>,
      default: null,
    },
    disallowed: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const options = ([13, 14, 5, 1, 2, 6, 4, 8, 9, 12, 7] as const).map((value) => ({
      value,
      label: getProcessName(value),
    }));

    return {
      options,
    };
  },
});
