







import { defineComponent, ref, type PropType } from '@vue/composition-api'
import { ValidationObserver, type ValidationObserverInstance } from 'vee-validate'

export default defineComponent({
  components: {
    ValidationObserver,
  },
  props: {
    onSubmit: {
      type: Function as PropType<() => Promise<unknown>>,
      required: true,
    },
  },
  setup(props) {
    const observer = ref<ValidationObserverInstance | null>(null)
    const handleSubmit = async() => {
      if (observer.value !== null) {
        const isValid = await observer.value.validate()
        if (isValid) {
          await props.onSubmit()
        }
      }
    }

    return {
      observer,
      handleSubmit,
    }
  },
})
