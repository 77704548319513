import { TimeInteger } from 'src/models/common'
import { StaffWorkScheduleResponse } from './api/staffWorkScheduleResponse'
import { MacroOperationMaster, convertMacroOperationMasterResponse } from './macroOperationMaster'

export interface StaffWorkSchedule {
  id: number
  staff_extension_id: number
  day_of_week: number
  work_start_time: TimeInteger | null
  work_end_time: TimeInteger | null
  break1_start_time: TimeInteger | null
  break1_end_time: TimeInteger | null
  break2_start_time: TimeInteger | null
  break2_end_time: TimeInteger | null
  duty1_macro_operation_master_id: number | null
  duty1_start_time: TimeInteger | null
  duty1_end_time: TimeInteger | null
  duty2_macro_operation_master_id: number | null
  duty2_start_time: TimeInteger | null
  duty2_end_time: TimeInteger | null
  duty1_macro_operation_master?: MacroOperationMaster | null
  duty2_macro_operation_master?: MacroOperationMaster | null
}

export function convertStaffWorkScheduleResponse(response: StaffWorkScheduleResponse): StaffWorkSchedule {
  const { duty1_macro_operation_master, duty2_macro_operation_master, ...rest } = response

  if (duty1_macro_operation_master) {
    Object.assign(rest, { duty1_macro_operation_master: convertMacroOperationMasterResponse(duty1_macro_operation_master) })
  }
  if (duty2_macro_operation_master) {
    Object.assign(rest, { duty2_macro_operation_master: convertMacroOperationMasterResponse(duty2_macro_operation_master) })
  }

  return rest
}
