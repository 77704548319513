
import Draggable, { MoveEvent } from 'vuedraggable';
import budgetGroupApi from 'src/apis/workplace_masters/budget_group';
import ListSwitchPanel from '../ListSwitchPanel.vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import {
  computed,
  ComputedRef,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  SetupContext,
} from '@vue/composition-api';
import { vvGetError, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { isExist } from 'src/util/isExist';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import TimetableMasterContent from './TimetableMaster/index.vue';
import { TimetableGroupMaster } from 'src/models/timetableGroupMaster';
import timetableGroupMasterApi from 'src/apis/workplace_masters/timetable_group_master';
import { TimetableGroupMasterResponse } from 'src/models/api/timetableGroupMasterResponse';
import { BudgetGroup } from 'src/models/budgetGroup';
import { LovEntry } from 'src/models/api/masterResponse';

const displayPageName = '工程グループマスタ';
const msgVars = { create: '作成', update: '編集', delete: '削除', update_disp_order: '表示順変更' };
const errIds = { create: 'ERR00001', update: 'ERR00002', delete: 'ERR00003', update_disp_order: 'ERR00004' };
type Operation = 'create' | 'update' | 'delete' | 'update_disp_order';

type CreateOrUpdateCandidateBase = Pick<TimetableGroupMaster, 'name' | 'disp_color' | 'is_enabled'>;

export type CreateCandidate = CreateOrUpdateCandidateBase;
export type UpdateCandidate = CreateOrUpdateCandidateBase & {
  id: number;
};
export type SaveCandidate = CreateCandidate | UpdateCandidate;

export type DeleteCandidate = TimetableGroupMaster;

type PaginationProps = {
  perPage: number;
  currentPage: number;
  total: number;
};

export type State = {
  isNew: Boolean;
  list: TimetableGroupMaster[];
  selectedTimetableGroupMasterId: number | null;
  saveCandidate: SaveCandidate;
  deleteCandidate: DeleteCandidate | null;
  showSaveModal: boolean;
  showDeleteModal: boolean;
  pagination: PaginationProps;
  budgetGroups: BudgetGroup[];
  dispColorCandidates: LovEntry[];
};

export type SearchParams = {
  selectedBudgetGroupId: number | null;
  selectedTimetableGroupMasterId: number | null;
  displayListDisabledItem: boolean;
};

function updateCandidate(item: TimetableGroupMaster): UpdateCandidate {
  return reactive({
    isNew: false,
    ...item,
  });
}

function setupState(state?: State): State {
  return reactive({
    isNew: false,
    list: [],
    saveCandidate: buildInitialSaveCandidate(state),
    selectedTimetableGroupMasterId: null,
    deleteCandidate: null,
    showSaveModal: false,
    showDeleteModal: false,
    pagination: {
      perPage: 50,
      currentPage: 1,
      total: 0,
    },
    budgetGroups: [],
    dispColorCandidates: [],
  });
}

function setupSearchParams(): SearchParams {
  return reactive({
    selectedBudgetGroupId: null,
    selectedTimetableGroupMasterId: null,
    displayListDisabledItem: false,
  });
}

function buildInitialSaveCandidate(state?: State): SaveCandidate {
  return reactive({
    name: '',
    disp_color: state ? state.dispColorCandidates[0].key : '',
    is_enabled: true,
  });
}

export default defineComponent({
  components: {
    TimetableMasterContent,
    Draggable,
    ListSwitchPanel,
  },
  setup(_props, context: SetupContext) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    setPageName(vueInstance, displayPageName);
    const state = setupState();
    const searchParams = setupSearchParams();
    const userId: ComputedRef<number> = wrappedMapGetters(vueInstance.$store, 'user', ['id']).id;

    const workplaceId = vueInstance.$route.params.workplaceId;

    const hasList = computed(() => {
      return state.list.length > 0;
    });

    const paginatedList = computed(() => {
      const startIdx = state.pagination.perPage * Math.max(state.pagination.currentPage - 1, 0);
      return state.list.slice(startIdx, startIdx + state.pagination.perPage);
    });

    const shouldPaginate = computed(() => {
      return state.pagination.total > state.pagination.perPage;
    });

    const hasError = computed(() => {
      return vvHasError(vueInstance);
    });

    const validations = computed(() => {
      return {
        timetable_group_master_name: { required: true, max: 30 },
      };
    });

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }

    function clearErrors() {
      vvReset(vueInstance);
    }

    async function getList() {
      const timetableGroupMasters = await timetableGroupMasterApi.index({
        workplace_id: workplaceId,
        budget_group_id: searchParams.selectedBudgetGroupId!,
        ...(searchParams.displayListDisabledItem ? null : { is_enabled: true }),
      });
      state.list = timetableGroupMasters;
      state.pagination.total = state.list.length;
      searchParams.selectedTimetableGroupMasterId = state.list.length > 0 ? state.list[0].id : null;
    }

    async function openCreateModal() {
      const saveCandidate = buildInitialSaveCandidate(state);
      state.isNew = true;
      state.saveCandidate = saveCandidate;
      state.showSaveModal = true;
      clearErrors();
    }

    async function openUpdateModal(timetableGroupMaster: TimetableGroupMaster) {
      if (!isExist(timetableGroupMaster)) {
        return;
      }

      const saveCandidate = updateCandidate(timetableGroupMaster);
      state.selectedTimetableGroupMasterId = timetableGroupMaster.id;
      state.isNew = false;
      state.saveCandidate = saveCandidate;
      state.showSaveModal = true;
      clearErrors();
    }

    function closeSaveModal() {
      state.saveCandidate = buildInitialSaveCandidate(state);
      state.showSaveModal = false;
      clearErrors();
    }

    async function _saveItem() {
      const isValid = await vvValidate(vueInstance);
      if (!isValid) {
        return;
      }

      if (state.isNew) {
        await doCreate();
      } else {
        await doUpdate();
      }
    }

    function onSaveItemError(err: any, Operation: Operation): void {
      const errStatus = err.response.status;
      const errRes = err.response.data || {};
      if (errStatus === 400 && errRes.reason === 'dup_name') {
        const msg = 'その工程グループマスタ名は既に使用されています。';
        notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
      } else {
        handleError(Operation, err as Error);
      }
    }

    async function doCreate(): Promise<void> {
      try {
        await timetableGroupMasterApi.create({
          workplace_id: workplaceId,
          budget_group_id: searchParams.selectedBudgetGroupId!,
          name: state.saveCandidate.name,
          disp_color: state.saveCandidate.disp_color,
          is_enabled: state.saveCandidate.is_enabled,
        });
        await getList();
        closeSaveModal();
        notifySuccess1(vueInstance, `${displayPageName}を作成しました`);
      } catch (err: any) {
        onSaveItemError(err, 'create');
      }
    }

    async function doUpdate(): Promise<void> {
      if (state.selectedTimetableGroupMasterId === null) {
        return;
      }

      try {
        await timetableGroupMasterApi.update({
          workplace_id: workplaceId,
          budget_group_id: searchParams.selectedBudgetGroupId!,
          id: state.selectedTimetableGroupMasterId,
          name: state.saveCandidate.name,
          disp_color: state.saveCandidate.disp_color,
          is_enabled: state.saveCandidate.is_enabled,
        });
        await getList();
        closeSaveModal();
        notifySuccess1(vueInstance, `${displayPageName}を更新しました`);
      } catch (err: any) {
        onSaveItemError(err, 'update');
      }
    }

    function openDeleteModal(item: TimetableGroupMaster) {
      state.deleteCandidate = item;
      state.showDeleteModal = true;
    }

    function closeDeleteModal() {
      state.deleteCandidate = null;
      state.showDeleteModal = false;
    }

    async function _deleteItem() {
      if (!isExist(state.deleteCandidate)) {
        return;
      }

      try {
        await timetableGroupMasterApi.delete({
          workplace_id: workplaceId,
          budget_group_id: searchParams.selectedBudgetGroupId!,
          timetable_group_master_id: state.deleteCandidate.id,
        });
        await getList();
        closeDeleteModal();
        notifySuccess1(vueInstance, `${displayPageName}を削除しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'グループに含む工程が存在するため削除できません';
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          handleError('delete', err as Error);
        }
      }
    }

    function isDraggable(e: MoveEvent<TimetableGroupMasterResponse>) {
      const dragged = e.draggedContext.element;
      const target = e.relatedContext.element;
      return dragged.budget_group_id === target.budget_group_id;
    }

    async function _updateDispOrder() {
      state.list = state.list.map((el, index) => ({ ...el, disp_order: index + 1 }));

      try {
        await timetableGroupMasterApi.bulkUpdateDispOrder({
          workplace_id: workplaceId,
          budget_group_id: searchParams.selectedBudgetGroupId!,
          items: state.list,
        });
        await getList();
      } catch (err) {
        handleError('update_disp_order', err as Error);
      }
    }

    function handleError(Operation: Operation, err?: Error) {
      const msg =
        `${displayPageName}の${msgVars[Operation]}に失敗しました。` +
        '管理者に連絡してください。' +
        `(ERR: ${displayPageName} ${errIds[Operation]}, user_id:${userId.value})`;
      notifyError1(vueInstance, msg, { err });
    }
    function selectItem(id: number) {
      searchParams.selectedTimetableGroupMasterId = id;
    }
    function isSelectedItem(item: TimetableGroupMaster) {
      return searchParams.selectedTimetableGroupMasterId === item.id;
    }

    onMounted(async () => {
      const [{ data: budgetGroups }] = await Promise.all([
        budgetGroupApi.index({
          workplaceId,
          params: { is_enabled: true },
        }),
        ensureUserAndMasters(context),
      ]);

      state.budgetGroups = budgetGroups;
      searchParams.selectedBudgetGroupId = state.budgetGroups[0]?.id;
      state.dispColorCandidates = window.master.lovs.color_set.vals.filter((e) => e.group === 'dark');
      await getList();
    });

    const gatedFuncGenerator = getGatedFuncGenerator();
    return {
      displayPageName: displayPageName,
      searchParams,
      state,
      hasList,
      paginatedList,
      shouldPaginate,
      hasError,
      validations,
      // methods
      getError,
      getList,
      openCreateModal,
      openUpdateModal,
      closeSaveModal,
      isDraggable,
      openDeleteModal,
      closeDeleteModal,
      selectItem,
      isSelectedItem,
      saveItem: gatedFuncGenerator.makeAsyncFuncGated(_saveItem),
      deleteItem: gatedFuncGenerator.makeAsyncFuncGated(_deleteItem),
      updateDispOrder: gatedFuncGenerator.makeAsyncFuncGated(_updateDispOrder),
      dragOptions: {
        handle: '.grabbable',
        animation: 300,
      },
    };
  },
});
