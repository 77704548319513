



















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import DoughnutChart from 'src/components/UIComponents/Charts/DoughnutChart';
import { orDefault } from 'src/util/filters';
import { getProgressRate } from 'src/util/progressHeaderHelpers';
import { formatTime, formatRate } from '../../utils/filters';
import { getProgressChartDataset } from './getProgressChartDataset';

export default defineComponent({
  components: {
    DoughnutChart,
  },
  filters: {
    formatTime,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup(props) {
    const dataset = computed(() => getProgressChartDataset(props.progressHeader));
    const centerText = computed(() => `${orDefault(formatRate(getProgressRate(props.progressHeader)), '-')}%`);

    return {
      dataset,
      centerText,
    };
  },
});
