import { type ChartDataSets } from 'chart.js'
import { type ProgressHeader } from 'src/models/progressHeader'
import {
  getLatestProductivityColor,
  getLatestProductivityRate,
} from 'src/util/progressHeaderHelpers'

function getInnerDataset(): ChartDataSets {
  return {
    data: [70, 20, 20, 90],
    borderAlign: 'inner',
    borderWidth: 0,
    backgroundColor: [
      '#f8cbad',
      '#ffe699',
      '#c5e0b4',
      '#b4c7e7',
    ],
    weight: 3,
  }
}

function getProgressRateDataset(progressHeader: ProgressHeader): ChartDataSets {
  const PRODUCTIVITY_RATE_UPPER_BOUND = 200
  const latestProductivityRate = Math.min(getLatestProductivityRate(progressHeader) ?? 0, PRODUCTIVITY_RATE_UPPER_BOUND)
  const restProductivityRate = PRODUCTIVITY_RATE_UPPER_BOUND - latestProductivityRate

  return {
    backgroundColor: [
      getLatestProductivityColor(progressHeader),
      '#f2f2f2'
    ],
    borderAlign: 'inner',
    borderWidth: 0,
    data: [latestProductivityRate, restProductivityRate],
    weight: 7,
  }
}

export function getProductivityChartDataset(progressHeader: ProgressHeader): [ChartDataSets, ChartDataSets] {
  return [getProgressRateDataset(progressHeader), getInnerDataset()]
}
