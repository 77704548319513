import { reactive, InjectionKey, toRefs, Ref } from '@vue/composition-api';

type State = {
  isDisplayStaffMemo: boolean;
  isDisplayStaffMemberCompany: boolean;
  isDisplaySkill: boolean;
  isDisplayYearTotal: boolean;
  isDisplayMonthTotal: boolean;
  isDisplay7DaysTotal: boolean;
};

type SettingDisplayItem = {
  isDisplayStaffMemo: Ref<boolean>;
  isDisplayStaffMemberCompany: Ref<boolean>;
  isDisplaySkill: Ref<boolean>;
  isDisplayYearTotal: Ref<boolean>;
  isDisplayMonthTotal: Ref<boolean>;
  isDisplay7DaysTotal: Ref<boolean>;
};

export const useSettingDisplayItem = (): SettingDisplayItem => {
  const state = reactive<State>({
    isDisplayStaffMemo: false,
    isDisplayStaffMemberCompany: false,
    isDisplaySkill: false,
    isDisplayYearTotal: false,
    isDisplayMonthTotal: false,
    isDisplay7DaysTotal: false,
  });

  return {
    ...toRefs(state),
  };
};

export const SettingDisplayItemStateKey: InjectionKey<SettingDisplayItem> = Symbol('SettingDisplayItem');
