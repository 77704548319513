import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import {
  convertConversionProgramStatusResponse,
  ConversionProgramStatus
} from 'src/models/conversionProgramStatus'
import { StringOrNumber } from 'src/models/common'
import { type ConversionProgramStatusRequest } from 'src/models/api/conversionProgramStatusRequest'

export default {
  async index({ workplaceId, conversionProgramId, ...params }: ConversionProgramStatusRequest): Promise<ConversionProgramStatus[]> {
    const { data: resData } = await axios.get(
      utils.getApiUrl(`/workplaces/${workplaceId}/conversion_programs/${conversionProgramId}/conversion_program_statuses`),
      { params }
    )
    return resData.map(convertConversionProgramStatusResponse)
  },
  // 今のところファイルアップロード以外でレコードが作られるパターンがないので、
  // createとしている
  async create(workplaceId: StringOrNumber, conversionProgramId: StringOrNumber, data: FormData): Promise<Record<string, string>> {
    const { data: resData } = await axios.post(
      utils.getApiUrl(`/workplaces/${workplaceId}/conversion_programs/${conversionProgramId}/conversion_program_statuses`),
      data,
      { headers: { 'content-type': 'multipart/form-data' } }
    )
    return resData
  },
  // CSV取り込みボタン押下時
  async importCsv(workplaceId: StringOrNumber, conversionProgramId: StringOrNumber, conversionProgramStatusId: StringOrNumber): Promise<ConversionProgramStatus> {
    const { data: resData } = await axios.put(
      utils.getApiUrl(`/workplaces/${workplaceId}/conversion_programs/${conversionProgramId}/conversion_program_statuses/${conversionProgramStatusId}`),
    )
    return resData
  },
}
