import { State, DisplayCondition } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useDisplayCondition';
import { SHIFT_PHASE } from 'src/consts';

const { PROVISIONAL } = SHIFT_PHASE;

export const copyDisplayConditionToState = (
  state: State,
  displayConditionState: DisplayCondition,
  isDetailDisplayConditionValid: boolean,
  baseDate: Date,
) => {
  state.staffName = displayConditionState.staffName.value;
  state.macroOperationMaster = displayConditionState.macroOperationMaster.value;
  state.staffAgency = displayConditionState.staffAgency.value;
  state.overTimeAt7Days = displayConditionState.overTimeAt7Days.value;
  state.isMoreOverTimeAt7Days = isDetailDisplayConditionValid
    ? { ...displayConditionState.isMoreOverTimeAt7Days.value }
    : { value: true, name: '以上' };
  state.overTimeAtMonth = displayConditionState.overTimeAtMonth.value;
  state.isMoreOverTimeAtMonth = isDetailDisplayConditionValid
    ? { ...displayConditionState.isMoreOverTimeAtMonth.value }
    : { value: true, name: '以上' };
  state.wageAtMonth = displayConditionState.wageAtMonth.value;
  state.isMoreWageAtMonth = isDetailDisplayConditionValid
    ? { ...displayConditionState.isMoreWageAtMonth.value }
    : { value: true, name: '以上' };
  state.targetDate = isDetailDisplayConditionValid ? displayConditionState.targetDate.value : baseDate;
  state.shiftType = isDetailDisplayConditionValid
    ? { ...displayConditionState.shiftType.value }
    : { value: PROVISIONAL, name: '仮シフト' };
  state.attendanceType = displayConditionState.attendanceType.value;
  state.startTime = { ...displayConditionState.startTime.value };
  state.endTime = { ...displayConditionState.endTime.value };
  state.workingTime = displayConditionState.workingTime.value;
  state.isMoreWorkingTime = isDetailDisplayConditionValid
    ? { ...displayConditionState.isMoreWorkingTime.value }
    : { value: true, name: '以上' };
};

export const copyStateToDisplayCondition = (displayConditionState: DisplayCondition, state: State) => {
  displayConditionState.staffName.value = state.staffName;
  displayConditionState.macroOperationMaster.value = state.macroOperationMaster;
  displayConditionState.staffAgency.value = state.staffAgency;
  displayConditionState.overTimeAt7Days.value = state.overTimeAt7Days;
  displayConditionState.isMoreOverTimeAt7Days.value = { ...state.isMoreOverTimeAt7Days };
  displayConditionState.overTimeAtMonth.value = state.overTimeAtMonth;
  displayConditionState.isMoreOverTimeAtMonth.value = { ...state.isMoreOverTimeAtMonth };
  displayConditionState.wageAtMonth.value = state.wageAtMonth;
  displayConditionState.isMoreWageAtMonth.value = { ...state.isMoreWageAtMonth };
  displayConditionState.targetDate.value = state.targetDate;
  displayConditionState.shiftType.value = { ...state.shiftType };
  displayConditionState.attendanceType.value = state.attendanceType;
  displayConditionState.startTime.value = { ...state.startTime };
  displayConditionState.endTime.value = { ...state.endTime };
  displayConditionState.workingTime.value = state.workingTime;
  displayConditionState.isMoreWorkingTime.value = { ...state.isMoreWorkingTime };
};
