





import { defineComponent } from '@vue/composition-api'
import { NumberInput } from 'src/components/UIComponents/Inputs/NumberInput'

export default defineComponent({
  inheritAttrs: false,
  components: {
    NumberInput,
  },
})
