import { ref, onMounted, onUpdated, type Ref } from '@vue/composition-api'

type Params = {
  text: Ref<string>
}

function isOverflow(element: HTMLElement): boolean {
  return element.scrollWidth > element.clientWidth
}

function binarySearchLast<T>(array: T[], callback: (value: T) => boolean): NonNullable<T> | null {
  let low = 0
  let high = array.length - 1

  while (low <= high) {
    const mid = Math.trunc((low + high) / 2)
    if (callback(array[mid])) {
      low = mid + 1
    } else {
      high = mid - 1
    }
  }

  return array[low - 1] ?? null
}

function truncateMiddle(value: string, maxLength: number, ellipsis = '...'): string {
  if (value.length <= maxLength) {
    return value
  }

  const firstHalfLength = Math.ceil(maxLength / 2)
  const secondHalfLength = maxLength - firstHalfLength
  const firstHalf = value.slice(0, firstHalfLength)
  const secondHalf = secondHalfLength > 0 ? value.slice(-secondHalfLength) : ''

  return `${firstHalf}${ellipsis}${secondHalf}`
}

export function useTruncatedText({ text }: Params): { elementRef: Ref<HTMLElement | null> } {
  const elementRef = ref<HTMLElement | null>(null)
  const setTruncatedText = () => {
    const element = elementRef.value
    if (element === null) {
      return
    }

    element.textContent = text.value
    if (!isOverflow(element)) {
      return
    }

    const lengths = [...Array(text.value.length + 1).keys()]
    const length = binarySearchLast(lengths, (length) => {
      element.textContent = truncateMiddle(text.value, length)
      return !isOverflow(element)
    })

    element.textContent = length !== null ? truncateMiddle(text.value, length) : ''
  }

  onMounted(setTruncatedText)
  onUpdated(setTruncatedText)

  return {
    elementRef,
  }
}
