import axios from 'axios'
import { getApiUrl } from './apiUtils'
import { StaffAgency, convertStaffAgencyResponse } from 'src/models/staffAgency'
import {
  StaffAgencyIndexParams,
} from 'src/models/api/staffAgencyRequest'
import { StringOrNumber } from 'src/models/common';

function getResourceBaseUrl(workplaceId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}`)
}

export default {
  async index(params: StaffAgencyIndexParams): Promise<StaffAgency[]> {
    const { workplace_id: workplaceId, ...paramsRest } = params
    const { data } = await axios.get(getApiUrl(`${getResourceBaseUrl(workplaceId)}/staff_agencies`), {
      params: paramsRest
    })
    return data.map(convertStaffAgencyResponse)
  },
}
