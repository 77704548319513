


















import { defineComponent, type PropType } from '@vue/composition-api';
import { type StaffLabel } from 'src/models/staffLabel';

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<StaffLabel[]>,
      required: true,
    },
    staffLabels: {
      type: Array as PropType<StaffLabel[]>,
      required: true,
    },
  },
});
