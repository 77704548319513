


























import { defineComponent, type PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    height: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    paddingTop: {
      type: Number,
      required: true,
    },
    position: {
      type: String as PropType<'left' | 'right'>,
      required: true,
    },
    values: {
      type: Array as PropType<(string | number)[]>,
      required: true,
    },
  },
})
