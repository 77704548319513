import { unref } from '@vue/composition-api'
import shiftPatternApi from 'src/apis/workplace_masters/shiftPattern'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'
import { createInjection } from 'src/util/createInjection'
import { useModalWithShiftPattern } from './useModalWithShiftPattern'
import { useShiftPatterns } from './useShiftPatterns'

type InjectionValue = {
  shiftPattern: ReturnType<typeof useModalWithShiftPattern>['shiftPattern']
  showsUpdateModal: ReturnType<typeof useModalWithShiftPattern>['showsModal']
  showUpdateModal: ReturnType<typeof useModalWithShiftPattern>['showModal']
  hideUpdateModal: ReturnType<typeof useModalWithShiftPattern>['hideModal']
  updateShiftPattern: (shiftPattern: ShiftPattern) => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useUpdateShiftPattern')

async function requestToUpdateShiftPattern({
  budgetGroup,
  shiftPattern,
}: {
  budgetGroup: BudgetGroup
  shiftPattern: ShiftPattern
}): Promise<void> {
  await shiftPatternApi.update({
    workplace_id: budgetGroup.workplace_id,
    ...shiftPattern,
  })
}

export function useUpdateShiftPatternProvider(): void {
  const { notifySuccess } = useNotification()
  const errorBoundary = useErrorBoundary()
  const {
    shiftPattern,
    showsModal: showsUpdateModal,
    showModal: showUpdateModal,
    hideModal: hideUpdateModal,
  } = useModalWithShiftPattern()
  const { fetchShiftPatterns, budgetGroup } = useShiftPatterns()
  const updateShiftPattern = errorBoundary.wrap(
    async (shiftPattern: ShiftPattern) => {
      const unwrappedBudgetGroup = unref(budgetGroup)
      if (unwrappedBudgetGroup !== null) {
        await requestToUpdateShiftPattern({
          budgetGroup: unwrappedBudgetGroup,
          shiftPattern,
        })
        hideUpdateModal()
        notifySuccess('シフトパターンを編集しました')
        await fetchShiftPatterns()
      }
    },
    {
      fallbackMessage: 'シフトパターンの編集に失敗しました。',
      shouldContactUs: true,
    },
  )

  provide({
    shiftPattern,
    showsUpdateModal,
    showUpdateModal,
    hideUpdateModal,
    updateShiftPattern,
  })
}

export function useUpdateShiftPattern(): InjectionValue {
  return inject()
}
