import axios, { AxiosResponse } from 'axios';
import { StringOrNumber } from 'src/models/common';
import { getApiUrl } from 'src/apis/apiUtils';
import { convertBudgetGroupPlanBoardMiscResponse } from 'src/models/budgetGroupPlanBoardMisc';
import {
  MacroOperationIndexParams,
  MacroOperationUpdateFromLogimeterParams,
  MacroOperationUpsertParams,
  ImportCsvParams,
} from 'src/models/api/macroOperationRequest';
import { convertMacroOperationResponse, MacroOperation, MacroOperationIndexResult } from 'src/models/macroOperation';
import { convertMacroOperationSegmentResponse } from 'src/models/macroOperationSegment';
import { MacroOperationIndexResponse } from 'src/models/api/macroOperationResponse';

function getResourceBaseUrl(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/macro_operations`);
}

export default {
  async macroOperationIndex(params: MacroOperationIndexParams): Promise<MacroOperationIndexResult> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    const { data: dataList } = await axios.get<MacroOperationIndexResponse>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId)}`,
      { params: paramsRest },
    );
    return Object.entries(dataList).map(([key, data]) => {
      return {
        dt: new Date(key),
        budget_group_plan_board_misc: data.budget_group_plan_board_misc
          ? convertBudgetGroupPlanBoardMiscResponse(data.budget_group_plan_board_misc)
          : null,
        macro_operations: data.macro_operations.map((item) => convertMacroOperationResponse(item)),
        macro_operation_segments: data.macro_operation_segments.map((item) =>
          convertMacroOperationSegmentResponse(item),
        ),
        scheduled_hours_total: data.scheduled_hours_total,
      };
    });
  },
  async upsert(params: MacroOperationUpsertParams): Promise<MacroOperation> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    const { data } = await axios.post(`${getResourceBaseUrl(workplaceId, budgetGroupId)}/upsert`, paramsRest);
    return convertMacroOperationResponse(data);
  },
  async updateFromLogimeter(params: MacroOperationUpdateFromLogimeterParams): Promise<void> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    await axios.post(`${getResourceBaseUrl(workplaceId, budgetGroupId)}/update_from_logimeter`, paramsRest);
  },
  async importCsv({ workplace_id, budget_group_id, csv }: ImportCsvParams): Promise<AxiosResponse<{}>> {
    const data = new FormData();
    data.append('csv', csv);
    return await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/import_csv`, data);
  },
};
