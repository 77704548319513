









import { defineComponent } from '@vue/composition-api'
import { useCreateShiftPattern } from '../../composables/useCreateShiftPattern'
import { ShiftPatternInputModal } from '../ShiftPatternInputModal'

export default defineComponent({
  components: {
    ShiftPatternInputModal,
  },
  setup() {
    const { showsCreateModal, hideCreateModal, createShiftPattern } = useCreateShiftPattern()

    return {
      showsCreateModal,
      hideCreateModal,
      createShiftPattern,
    }
  },
})
