














import { defineComponent, ref, watch } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';

export default defineComponent({
  components: {
    ValidationProvider,
  },
  props: {
    rules: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const valueRef = ref(props.value);
    const handleBlur = (valid: boolean) => {
      if (valid && props.value !== valueRef.value) {
        context.emit('input', valueRef.value);
      }
    };

    watch(
      () => props.value,
      (value) => {
        valueRef.value = value;
      },
    );

    return {
      valueRef,
      handleBlur,
    };
  },
});
