<template>
  <div class="form-group"
       :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'input-group-focus': focused},
       {'has-label': label || $slots.label},
       {'has-success': hasSuccess}]">
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{label}}
        <span class="text-danger" v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
    </div>
    <slot>
      <input
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control"
        :required="required"
        :class="[
          {'is-valid': hasSuccess},
          inputClasses,
          {'is-invalid': error},
          {'ta-right': textAlignRight},
          {'ta-center': textAlignCenter},
          {'has-addon-left': hasIconLeft},
          {'has-addon-right': hasIconRight},
        ]"
        aria-describedby="addon-right addon-left"
        ref="input">
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
          <span class="input-group-text">
              <slot name="addonRight">
                  <i :class="addonRightIcon"></i>
              </slot>
          </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div class="invalid-feedback error-text" :class="{'mt-2': hasIcon}" v-if="showErrorMessage && error">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'fg-input',
  $_veeValidate: {
    value() {
      return this.$refs.input.value
    }
  },
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon'
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon'
    },
    showErrorMessage: {
      type: Boolean,
      description: 'Whether error message is visible'
    },
    textAlignRight: {
      type: Boolean,
    },
    textAlignCenter: {
      type: Boolean,
    },
    focusOnMount: {
      type: Boolean,
    },
  },
  data() {
    return {
      touched: false,
      focused: false,
      hadError: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur
      }
    },
    hasSuccess() {
      return this.hadError && this.touched && !this.error
    },
    hasIconLeft() {
      const { addonLeft } = this.$slots
      return addonLeft !== undefined || this.addonLeftIcon !== undefined
    },
    hasIconRight() {
      const { addonRight } = this.$slots
      return addonRight !== undefined || this.addonRightIcon !== undefined
    },
    hasIcon() {
      return this.hasIconLeft || this.hasIconRight
    }
  },
  methods: {
    updateValue(evt) {
      const value = evt.target.value
      if (!this.touched && value) {
        this.touched = true
      }
      this.$emit('input', value)
    },
    onFocus(value) {
      this.focused = true
      this.$emit('focus', value)
    },
    onBlur(value) {
      this.focused = false
      this.$emit('blur', value)
    }
  },
  created() {
    this.$watch('error', (newVal) => {
      if (newVal) {
        this.hadError = true
      }
    }, { immediate: true })
  },
  mounted() {
    if (this.focusOnMount) {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    }
  },
}
</script>
<style scoped>
.error-text {
  display: block;
}
.time-input-lg {
  width: 80px;
  height: 70px;
  font-size: 46px;
}
</style>
