import { type StaffWithShifts, type Total7Days, type Total7DaysPerStaff } from 'src/models/regularShift';
import { type ShiftPhase } from 'src/consts/index';
import { getGenericShift } from 'src/views/Dashboard/Workplace/RegularShift/libs/displayModeHelper';

export const calc7daysTotal = (staffsWithShifts: StaffWithShifts[], displayMode: ShiftPhase): Total7Days => {
  return staffsWithShifts.reduce((accu, staff) => {
    const staffId = staff.staff_id;
    const total7DaysPerStaff = _calc7daysTotalPerStaff(staff, displayMode);

    return { ...accu, [staffId]: total7DaysPerStaff };
  }, {});
};

// スタッフ毎の７日労働時間の計算
const _calc7daysTotalPerStaff = (staff: StaffWithShifts, displayMode: ShiftPhase): Total7DaysPerStaff => {
  const total7DaysMap = [...Array(7)].reduce(
    (accu, _, i) => {
      const preShift = staff.orig_pre_shifts[i];
      const shift = staff.orig_shifts[i];
      const formattedShift = getGenericShift(shift, preShift, displayMode);

      accu.totalTime += formattedShift.hoursTotal;
      accu.overtime += formattedShift.hoursOvertime;
      accu.accumHoursNormal += formattedShift.hoursNormal;

      return accu;
    },
    { totalTime: 0, overtime: 0, accumHoursNormal: 0 },
  );

  // 週40時間以上労働している場合は残業代にaccumHoursNormal - 40を追加
  const overtimeEligibleHours = total7DaysMap.accumHoursNormal - 40;
  if (overtimeEligibleHours > 0) {
    total7DaysMap.overtime += overtimeEligibleHours;
  }

  return {
    totalTime: total7DaysMap.totalTime,
    overtime: total7DaysMap.overtime,
  };
};
