import { createInjection } from 'src/util/createInjection';
import { DailyPlan, DailySegmentData, DailyTaskData, MapsForMonthlyPlan, MonthlyPlanState } from '../types';
import { SetupContext, computed, reactive, ref } from '@vue/composition-api';
import { useDisplayConditions } from './useDisplayConditions';
import { useSearchConditions } from './useSearchConditions';
import { addDays, endOfMonth, format, startOfMonth, subDays } from 'date-fns';
import { MacroOperationIndexResult, MacroOperationIndexResultItem } from 'src/models/macroOperation';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import macroOperationApi from 'src/apis/macroOperation';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import macroOperationSegmentApi from 'src/apis/macroOperationSegment';
import { useNotification } from 'src/composables/useNotification';
import { isExist } from 'src/util/isExist';
import { useMonthlyPlanOverview } from './useMonthlyPlanOverview';
import { convertToHeadcount, convertToManHours } from '../monthlyPlanHelper';

const DATE_FORMAT = 'yyyy-MM-dd';

type DailyPlanBase = Omit<
  DailyPlan,
  'requiredManHours' | 'plannedManHours' | 'totalActualManHours' | 'totalSpot' | 'actualProfit'
>;

type Props = {
  context: SetupContext;
};

type InitProps = {
  macroOperationIndex: (dt: Date, budgetGroupId: number) => Promise<MacroOperationIndexResult>;
};

type InjectionValue = {
  state: MonthlyPlanState;
  initMonthlyPlan: (props: InitProps) => void;
  refreshMonthlyPlan: () => Promise<void>;
  updateDaySegmentData: (segmentData: DailySegmentData, dayIndex: number, label: string) => Promise<void>;
  focusMemo: (dailyPlan: DailyPlan) => void;
  calculateDailyPlan: (dailyPlanBase: DailyPlanBase) => DailyPlan;
};

const { provide, inject } = createInjection<InjectionValue>('useMonthlyPlan');

export function useMonthlyPlanProvider({ context }: Props): void {
  const { budgetGroup, dt, workplaceId } = useSearchConditions();
  const { state: displayState } = useDisplayConditions();
  const { updateOverviewState } = useMonthlyPlanOverview();
  const { notifyError, notifySuccess } = useNotification();
  const macroOperationIndex = ref<(dt: Date, budgetGroupId: number) => Promise<MacroOperationIndexResult>>();

  const state: MonthlyPlanState = reactive({
    daysInMonth: 0,
    targetDates: [],
    actualDates: [],
    dailyColumnsStyle: computed(() => ({ gridTemplateColumns: `repeat(${state.daysInMonth}, 120px)` })),
    isReady: false,
    targetMonthlyRevenue: null,
    targetMonthlyCost: null,
    tasks: [],
    taskActualHeadcountKey: computed(() => (displayState.isDisplayedManHour ? 'actualManHours' : 'actualHeadcount')),
    taskOvertimeHeadcountKey: computed(() =>
      displayState.isDisplayedManHour ? 'overtimeWorkHours' : 'overtimeHeadcount',
    ),
    spotInputKeys: computed(() =>
      displayState.isDisplayedManHour
        ? ['spot1ManHours', 'spot2ManHours', 'spot3ManHours', 'spot4ManHours']
        : ['spot1Headcount', 'spot2Headcount', 'spot3Headcount', 'spot4Headcount'],
    ),
    regularActualInputKey: computed(() =>
      displayState.isDisplayedManHour ? 'regularActualManHours' : 'regularActualHeadcount',
    ),
    spotActualInputKey: computed(() =>
      displayState.isDisplayedManHour ? 'spotActualManHours' : 'spotActualHeadcount',
    ),
    dailyPlans: [],
    lastMemo: '',
    maps: {
      order: {},
    },
    isActualDataFetchModalVisible: false,
  });

  const initMonthlyPlan = ({ macroOperationIndex: _macroOperationIndex }: InitProps) => {
    macroOperationIndex.value = _macroOperationIndex;
  };

  /**
   * API の値で表をリセットする
   */
  const refreshMonthlyPlan = async (): Promise<void> => {
    if (!dt.value) {
      return;
    }
    const budgetGroupId = budgetGroup.value?.id;
    if (!budgetGroupId) {
      return;
    }
    if (!macroOperationIndex.value) {
      return;
    }
    state.isReady = false;
    const startDate = startOfMonth(dt.value);
    const endDate = endOfMonth(dt.value);
    state.daysInMonth = endDate.getDate();
    state.targetDates = new Array(state.daysInMonth).fill(0).map((_, i) => addDays(startDate, i));
    state.actualDates = new Array(state.daysInMonth).fill(0).map((_, i) => addDays(startDate, i));

    let response: MacroOperationIndexResult;
    let macroOperationMasters: MacroOperationMaster[];
    try {
      macroOperationMasters = await macroOperationMasterApi.index({
        workplaceId,
        budgetGroupId: budgetGroupId,
        params: {
          is_enabled: true,
        },
      });
      response = await macroOperationIndex.value(dt.value, budgetGroupId);
    } catch (err: any) {
      notifyError('表示情報の取得に失敗しました', { cause: err });
      return;
    }
    macroOperationMasters = macroOperationMasters.map((macroOperationMaster) => {
      return {
        ...macroOperationMaster,
        macro_operation_segment_masters: macroOperationMaster.macro_operation_segment_masters.filter(
          (segment_master) => segment_master.is_enabled === true,
        ),
      };
    });
    const maps = createMaps(macroOperationMasters);
    state.maps = maps;
    const { budget_target, cost_target } = response[0].budget_group_plan_board_misc ?? {};
    state.targetMonthlyRevenue = isExist(budget_target) ? budget_target / 1000 : null;
    state.targetMonthlyCost = isExist(cost_target) ? cost_target / 1000 : null;
    state.tasks = createTasks(macroOperationMasters);
    state.dailyPlans = createDailyPlans(macroOperationMasters, response, maps);
    updateOverviewState(state);
    state.isReady = true;
  };

  const createMaps = (macroOperationMasters: MacroOperationMaster[]) => {
    const maps: MapsForMonthlyPlan = {
      order: {},
    };
    macroOperationMasters.forEach((item) => {
      maps.order[item.id] = item.disp_order;
    });
    return maps;
  };

  const createTasks = (macroOperationMasters: MacroOperationMaster[]): MonthlyPlanState['tasks'] => {
    return macroOperationMasters.map((item) => {
      return {
        id: item.id,
        name: item.name,
        dispOrder: item.disp_order,
        dispColor: `#${item.disp_color}`,
        productivity: item.productivity ?? 0,
        isSegmentUsed: item.is_segment_used,
        isSegmentOpened: false,
        segments: item.macro_operation_segment_masters,
      };
    });
  };

  const createDailyPlans = (
    macroOperationMasters: MacroOperationMaster[],
    apiResponse: MacroOperationIndexResult,
    maps: MapsForMonthlyPlan,
  ): MonthlyPlanState['dailyPlans'] => {
    const dateResponseMap = apiResponse.reduce<Record<number, MacroOperationIndexResultItem>>(
      (responseMap, response) => {
        const date = response.dt.getDate();
        return {
          ...responseMap,
          [date]: response,
        };
      },
      {},
    );
    return state.targetDates.map((_date) => {
      const date = _date.getDate();
      const response = dateResponseMap[date];
      const defaultTasks = macroOperationMasters
        .concat()
        .sort((a, b) => maps.order[a.id] - maps.order[b.id])
        .map<DailyTaskData>((task) => {
          return {
            taskId: task.id,
            plannedInitialQuantity: null,
            plannedFinalQuantity: null,
            plannedProductivity: null,
            requiredManHours: 0,
            plannedManHours: 0,
            actualQuantity: null,
            actualProductivity: null,
            actualManHours: null,
            actualHeadcount: null,
            overtimeWorkHours: null,
            overtimeHeadcount: null,
            actualUsedHours: null,
            segments: task.macro_operation_segment_masters
              .sort((a, b) => a.disp_order - b.disp_order)
              .map((segment) => {
                return {
                  segmentId: segment.id,
                  plannedInitialQuantity: null,
                  plannedFinalQuantity: null,
                  actualQuantity: null,
                  plannedProductivity: null,
                  actualManHours: null,
                  actualHeadcount: null,
                  actualProductivity: null,
                  requiredManHours: 0,
                };
              }),
          };
        });
      if (!isExist(response)) {
        const defaultDailyPlan: DailyPlan = {
          dt: _date,
          tasks: defaultTasks,
          memo: null,
          requiredManHours: 0,
          plannedManHours: 0,
          totalActualManHours: 0,
          regularPlannedManHours: 0,
          regularActualManHours: null,
          regularActualHeadcount: null,
          spot1ManHours: null,
          spot2ManHours: null,
          spot3ManHours: null,
          spot4ManHours: null,
          spot1Headcount: null,
          spot2Headcount: null,
          spot3Headcount: null,
          spot4Headcount: null,
          totalSpot: 0,
          spotActualManHours: null,
          spotActualHeadcount: null,
          actualRevenue: null,
          actualCost: null,
          actualProfit: null,
          indirectBalance: null,
        };
        return defaultDailyPlan;
      }
      const {
        memo,
        planned_spot1_headcount: spot1ManHours,
        planned_spot2_headcount: spot2ManHours,
        planned_spot3_headcount: spot3ManHours,
        planned_spot4_headcount: spot4ManHours,
      } = response.budget_group_plan_board_misc ?? {};
      const dailyPlanBase: DailyPlanBase = {
        dt: _date,
        memo: memo ?? null,
        spot1ManHours: spot1ManHours ?? null,
        spot2ManHours: spot2ManHours ?? null,
        spot3ManHours: spot3ManHours ?? null,
        spot4ManHours: spot4ManHours ?? null,
        spot1Headcount: convertToHeadcount(spot1ManHours),
        spot2Headcount: convertToHeadcount(spot2ManHours),
        spot3Headcount: convertToHeadcount(spot3ManHours),
        spot4Headcount: convertToHeadcount(spot4ManHours),
        regularPlannedManHours: response.scheduled_hours_total,
        ...convertActualDataFromAPIResponse(response),
        tasks: defaultTasks.map<DailyTaskData>((task) => {
          const targetTask = response.macro_operations.find((item) => item.macro_operation_master_id === task.taskId);
          if (!isExist(targetTask)) {
            return task;
          }
          const {
            macro_operation_master_id,
            planned_initial_quantity,
            planned_final_quantity,
            planned_productivity,
            scheduled_attendance_hour,
            actual_quantity,
            actual_working_hours,
            overtime_work_hours,
            actual_productivity,
            actual_used_hours,
            required_working_hours,
          } = targetTask;
          return {
            taskId: macro_operation_master_id,
            plannedInitialQuantity: planned_initial_quantity,
            plannedFinalQuantity: planned_final_quantity,
            plannedProductivity: planned_productivity,
            requiredManHours: required_working_hours ?? 0,
            plannedManHours: Number(scheduled_attendance_hour),
            actualQuantity: actual_quantity,
            actualProductivity: actual_productivity,
            actualManHours: actual_working_hours,
            actualHeadcount: convertToHeadcount(actual_working_hours),
            overtimeWorkHours: overtime_work_hours,
            overtimeHeadcount: convertToHeadcount(overtime_work_hours),
            actualUsedHours: actual_used_hours,
            segments: task.segments.map((defaultSegment) => {
              const targetSegment = response.macro_operation_segments.find(
                (segment) => segment.macro_operation_segment_master_id === defaultSegment.segmentId,
              );
              if (!isExist(targetSegment)) {
                return defaultSegment;
              }
              const {
                macro_operation_segment_master_id,
                planned_initial_quantity,
                planned_final_quantity,
                planned_productivity,
                actual_quantity,
                actual_working_hours,
                actual_productivity,
                required_working_hours,
              } = targetSegment;
              return {
                segmentId: macro_operation_segment_master_id,
                plannedInitialQuantity: planned_initial_quantity,
                plannedFinalQuantity: planned_final_quantity,
                actualQuantity: actual_quantity,
                plannedProductivity: planned_productivity,
                actualManHours: actual_working_hours,
                actualHeadcount: convertToHeadcount(actual_working_hours),
                actualProductivity: actual_quantity !== null ? actual_productivity ?? 0 : null,
                requiredManHours: required_working_hours ?? 0,
              };
            }),
          };
        }),
      };
      return calculateDailyPlan(dailyPlanBase);
    });
  };

  /**
   * オーダーの更新
   */
  const updateDaySegmentData = async (segmentData: DailySegmentData, dayIndex: number, label: string) => {
    if (!budgetGroup.value) {
      return;
    }
    if (!isExist(state.isReady)) {
      return;
    }
    if (!isExist(segmentData.segmentId)) {
      return;
    }
    let segmentIndex = -1;
    const taskIndex = state.tasks.findIndex((item) => {
      segmentIndex = item.segments.findIndex((segment) => segment.id === segmentData.segmentId);
      return segmentIndex >= 0;
    });
    const macroOperationSegmentMaster = state.tasks[taskIndex].segments[segmentIndex];
    if (!isExist(macroOperationSegmentMaster)) {
      return;
    }

    if (displayState.isDisplayedManHour) {
      state.dailyPlans[dayIndex].tasks[taskIndex].segments[segmentIndex].actualHeadcount = convertToHeadcount(
        state.dailyPlans[dayIndex].tasks[taskIndex].segments[segmentIndex].actualManHours,
      );
    } else {
      state.dailyPlans[dayIndex].tasks[taskIndex].segments[segmentIndex].actualManHours = convertToManHours(
        state.dailyPlans[dayIndex].tasks[taskIndex].segments[segmentIndex].actualHeadcount,
      );
    }

    try {
      const planned_productivity = segmentData.plannedProductivity ?? macroOperationSegmentMaster.productivity;
      const macroOperationSegmentUpsertResponse = await macroOperationSegmentApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: state.targetDates[dayIndex],
        planned_initial_quantity: segmentData.plannedInitialQuantity,
        planned_final_quantity: segmentData.plannedFinalQuantity,
        actual_quantity: segmentData.actualQuantity,
        planned_productivity: planned_productivity,
        macro_operation_segment_master_id: macroOperationSegmentMaster.id,
        macro_operation_master_id: state.tasks[taskIndex].id,
        actual_working_hours: segmentData.actualManHours,
      });
      // 更新した日付分のレコードのみ取得
      const macroOperationIndexResponse = await macroOperationApi.macroOperationIndex({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        start_date: format(state.targetDates[dayIndex], DATE_FORMAT),
        end_date: format(state.targetDates[dayIndex], DATE_FORMAT),
      });
      const macro_operation = macroOperationIndexResponse[0].macro_operations.find(
        (macro_operation) => macro_operation.macro_operation_master_id === state.tasks[taskIndex].id,
      );
      if (macro_operation === undefined) {
        return;
      }
      state.dailyPlans[dayIndex].tasks[taskIndex] = {
        taskId: macro_operation.macro_operation_master_id,
        plannedInitialQuantity: macro_operation.planned_initial_quantity,
        plannedFinalQuantity: macro_operation.planned_final_quantity,
        plannedProductivity: macro_operation.planned_productivity,
        requiredManHours: macro_operation.required_working_hours ?? 0,
        plannedManHours: Number(macro_operation.scheduled_attendance_hour),
        actualQuantity: macro_operation.actual_quantity,
        actualProductivity: macro_operation.actual_productivity,
        actualManHours: macro_operation.actual_working_hours,
        actualHeadcount: convertToHeadcount(macro_operation.actual_working_hours),
        overtimeWorkHours: macro_operation.overtime_work_hours,
        overtimeHeadcount: convertToHeadcount(macro_operation.overtime_work_hours),
        actualUsedHours: macro_operation.actual_used_hours,
        segments: state.dailyPlans[dayIndex].tasks[taskIndex].segments,
      };
      const segment = state.dailyPlans[dayIndex].tasks[taskIndex].segments[segmentIndex];
      segment.plannedProductivity = macroOperationSegmentUpsertResponse.planned_productivity;
      segment.actualProductivity =
        macroOperationSegmentUpsertResponse.actual_quantity !== null
          ? macroOperationSegmentUpsertResponse.actual_productivity ?? 0
          : null;
      segment.requiredManHours = macroOperationSegmentUpsertResponse.required_working_hours ?? 0;
      const calculated = calculateDailyPlan(state.dailyPlans[dayIndex]);
      state.dailyPlans[dayIndex].requiredManHours = calculated.requiredManHours;
      state.dailyPlans[dayIndex].plannedManHours = calculated.plannedManHours;
      state.dailyPlans[dayIndex].totalSpot = calculated.totalSpot;
      updateOverviewState(state);
      notifySuccess(`${label}を更新しました`);
    } catch (err: any) {
      notifyError(`${label}の更新に失敗しました`, { cause: err });
    }
  };

  const convertActualDataFromAPIResponse = (response: MacroOperationIndexResultItem) => {
    const {
      actual_regular_man_seconds,
      actual_spot_man_seconds,
      actual_revenue: actualRevenue,
      actual_cost: actualCost,
      actual_indirect_cost: indirectBalance,
    } = response.budget_group_plan_board_misc ?? {};

    const regularActualManHours = isExist(actual_regular_man_seconds) ? actual_regular_man_seconds / 3600 : null;
    const spotActualManHours = isExist(actual_spot_man_seconds) ? actual_spot_man_seconds / 3600 : null;

    return {
      regularActualManHours,
      regularActualHeadcount: convertToHeadcount(regularActualManHours),
      spotActualManHours,
      spotActualHeadcount: convertToHeadcount(spotActualManHours),
      actualRevenue: actualRevenue ?? null,
      actualCost: actualCost ?? null,
      indirectBalance: indirectBalance ?? null,
    };
  };

  const calculateDailyPlan = (dailyPlanBase: DailyPlanBase): DailyPlan => {
    const {
      tasks,
      spot1ManHours,
      spot2ManHours,
      spot3ManHours,
      spot4ManHours,
      actualRevenue,
      actualCost,
      regularPlannedManHours,
      regularActualManHours,
      spotActualManHours,
    } = dailyPlanBase;
    const requiredManHours = tasks.reduce((acc, task) => acc + task.requiredManHours, 0);
    const totalSpot = [spot1ManHours, spot2ManHours, spot3ManHours, spot4ManHours].reduce(
      (sum: number, spot) => sum + (spot ?? 0),
      0,
    );
    const plannedManHours = regularPlannedManHours + totalSpot;
    const totalActualManHours = (regularActualManHours ?? 0) + (spotActualManHours ?? 0);

    return {
      ...dailyPlanBase,
      requiredManHours,
      plannedManHours,
      totalActualManHours,
      totalSpot,
      actualProfit: [actualRevenue, actualCost].every((e) => e === null)
        ? null
        : (actualRevenue ?? 0) - (actualCost ?? 0),
    };
  };

  /**
   * メモにフォーカス
   */
  const focusMemo = (dailyPlan: DailyPlan) => {
    state.lastMemo = dailyPlan.memo;
  };

  provide({
    state,
    initMonthlyPlan,
    refreshMonthlyPlan,
    updateDaySegmentData,
    focusMemo,
    calculateDailyPlan,
  });
}

export function useMonthlyPlan(): InjectionValue {
  return inject();
}
