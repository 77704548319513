import { ref, unref, watch, computed, type Ref } from '@vue/composition-api';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  timetableMasters: Ref<TimetableMaster[]>;
  filteredTimetableMasters: Ref<TimetableMaster[]>;
  budgetGroup: Ref<BudgetGroup | null>;
  useInPrepPhase: Ref<boolean>;
  useInDoPhase: Ref<boolean>;
  useInReviewPhase: Ref<boolean>;
  includesDisabled: Ref<boolean>;
  isLoading: Ref<boolean>;
  fetchTimetableMasters: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useTimetableMasters');

async function getTimetableMasters({ budgetGroup }: { budgetGroup: BudgetGroup }): Promise<TimetableMaster[]> {
  return await timetableMasterApi.index({
    workplaceId: budgetGroup.workplace_id,
    params: {
      budget_group_id: budgetGroup.id,
    },
  });
}

export function useTimetableMastersProvider(): void {
  const errorBoundary = useErrorBoundary();
  const timetableMasters = ref<TimetableMaster[]>([]);
  const budgetGroup = ref<BudgetGroup | null>(null);
  const useInPrepPhase = ref(false);
  const useInDoPhase = ref(false);
  const useInReviewPhase = ref(false);
  const includesDisabled = ref(false);
  const isLoading = ref(false);
  const fetchTimetableMasters = errorBoundary.wrap(
    async () => {
      const unwrappedBudgetGroup = unref(budgetGroup);
      if (unwrappedBudgetGroup !== null) {
        isLoading.value = true;
        try {
          timetableMasters.value = await getTimetableMasters({
            budgetGroup: unwrappedBudgetGroup,
          });
        } finally {
          isLoading.value = false;
        }
      }
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );
  const filteredTimetableMasters = computed(() =>
    timetableMasters.value.filter(
      (timetableMaster) =>
        (!useInPrepPhase.value || timetableMaster.use_in_prep_phase) &&
        (!useInDoPhase.value || timetableMaster.use_in_do_phase) &&
        (!useInReviewPhase.value || timetableMaster.use_in_review_phase) &&
        (includesDisabled.value || timetableMaster.is_enabled),
    ),
  );

  watch([budgetGroup], fetchTimetableMasters);

  provide({
    timetableMasters,
    filteredTimetableMasters,
    budgetGroup,
    useInPrepPhase,
    useInDoPhase,
    useInReviewPhase,
    includesDisabled,
    isLoading,
    fetchTimetableMasters,
  });
}

export function useTimetableMasters(): InjectionValue {
  return inject();
}
