import progressHeaderApi from 'src/apis/progressHeader'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { type ProgressHeader } from 'src/models/progressHeader'
import { useProgressHeader } from './useProgressHeader'

type UpdateScheduledQuantity = {
  updateScheduledQuantity: (scheduledQuantity: ProgressHeader['scheduled_quantity']) => Promise<boolean>
}

export function useUpdateScheduledQuantity(): UpdateScheduledQuantity {
  const { progressHeader, fetchProgressHeader } = useProgressHeader()
  const errorBoundary = useErrorBoundary()
  const { notifySuccess } = useNotification()
  const updateScheduledQuantity = errorBoundary.wrap(
    async(scheduledQuantity: ProgressHeader['scheduled_quantity']) => {
      await progressHeaderApi.update({
        id: progressHeader.value.id,
        workplace_id: progressHeader.value.workplace_id,
        scheduled_quantity: scheduledQuantity,
      })
      notifySuccess('予定作業量を登録しました')
      await fetchProgressHeader()
    },
    {
      shouldContactUs: true,
    },
  )

  return {
    updateScheduledQuantity,
  }
}
