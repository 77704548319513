import axios from 'axios';
import * as utils from 'src/apis/apiUtils';
import { StringOrNumber } from 'src/models/common';
import {
  IndexOptParams,
  CreateOptParams,
  UpdateOptParams,
  BulkUpdateOptParams,
} from 'src/models/api/timetableMasterRequest';
import { convertTimetableMasterResponse, TimetableMaster } from 'src/models/timetableMaster';
import { ActivityMastersOptParams } from 'src/models/api/planProgressRequest';
import { TimetableActivityMasterResponse } from 'src/models/api/timetableActivityMasterResponse';

export default {
  async index({
    workplaceId,
    params,
  }: {
    workplaceId: StringOrNumber;
    params: IndexOptParams;
  }): Promise<TimetableMaster[]> {
    const { data } = await axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_masters`), {
      params: params || {},
    });
    return data.map(convertTimetableMasterResponse);
  },
  async create({ workplaceId, data }: { workplaceId: StringOrNumber; data: CreateOptParams }): Promise<{}> {
    const { data: resData } = await axios.post(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_masters`),
      data,
    );
    return convertTimetableMasterResponse(resData);
  },
  async update({ workplaceId, data }: { workplaceId: StringOrNumber; data: UpdateOptParams }): Promise<{}> {
    const { data: resData } = await axios.put(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_masters/${data.id}`),
      data,
    );
    return convertTimetableMasterResponse(resData);
  },
  async delete({ workplaceId, itemId }: { workplaceId: StringOrNumber; itemId: StringOrNumber }): Promise<{}> {
    const { data } = await axios.delete(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_masters/${itemId}`),
    );
    return data;
  },
  async bulkUpdateDispOrder({ workplaceId, items }: { workplaceId: StringOrNumber; items: BulkUpdateOptParams[] }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_masters/bulk_update_disp_order`), {
      items: items,
    });
  },
  async getActivityMasters(
    workplaceId: StringOrNumber,
    params: ActivityMastersOptParams,
  ): Promise<TimetableActivityMasterResponse[]> {
    const { data } = await axios.get(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_masters/activity_masters`),
      { params },
    );
    return data;
  },
};
