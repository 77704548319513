import { type BudgetGroup, convertBudgetGroupResponse } from 'src/models/budgetGroup';
import { type BackgroundProcessStatusIndexResponse } from 'src/models/api/backgroundProcessStatusResponse';
import { parseDatetime } from 'src/util/datetime';

export type BackgroundProcessStatus = {
  id: number;
  workplace_id: number;
  budget_group_id: number | null;
  budget_group: BudgetGroup | null;
  user_id: number;
  process_type:
    | 1 // シフト：マスタから登録
    | 2 // シフト：月合計 更新
    | 3 // シフト：シフトをLMに送信
    | 4 // シフト：出勤実績をLMから受信
    | 5 // シフト：シフトパターンCSVから登録
    | 6 // シフト：シフトパターン雛形を出力
    | 7 // 旧進捗：外部データ取込
    | 8 // パフォーマンスボード：外部データ取込（予定）
    | 9 // パフォーマンスボード：外部データ取込（実績）
    | 12 // パフォーマンスボード：数量なし工程の工数をLMから取得
    | 13 // 月間ボード：CSVから数量を入力
    | 14; // シフト：CSVから登録
  status:
    | 1 // 実行中
    | 10 // 完了
    | 99; // 失敗
  csv: string | null;
  start_time: Date;
  end_time: Date | null;
  system_message: string | null;
  orig_params: string;
  can_download: boolean;
};

export function convertBackgroundProcessStatusIndexResponse(
  response: BackgroundProcessStatusIndexResponse,
): BackgroundProcessStatus[] {
  return response.map((v) => ({
    ...v,
    budget_group: v.budget_group ? convertBudgetGroupResponse(v.budget_group) : null,
    start_time: parseDatetime(v.start_time),
    end_time: v.end_time ? parseDatetime(v.end_time) : null,
  }));
}
