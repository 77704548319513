import { ref, readonly, type Ref, type DeepReadonly } from '@vue/composition-api'
import { useModal } from 'src/composables/useModal'
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'

type ModalWithShiftPattern = {
  shiftPattern: DeepReadonly<Ref<ShiftPattern | null>>
  showsModal: ReturnType<typeof useModal>['showsModal']
  showModal: (shiftPattern: ShiftPattern) => void
  hideModal: () => void
}

export function useModalWithShiftPattern(): ModalWithShiftPattern {
  const shiftPattern = ref<ShiftPattern | null>(null)
  const modal = useModal()
  const showModal = (targetShiftPattern: ShiftPattern) => {
    shiftPattern.value = targetShiftPattern
    modal.showModal()
  }
  const hideModal = () => {
    shiftPattern.value = null
    modal.hideModal()
  }

  return {
    shiftPattern: readonly(shiftPattern),
    showsModal: modal.showsModal,
    showModal,
    hideModal,
  }
}
