import { ref, type Ref } from '@vue/composition-api';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type TimetableLabel } from 'src/models/timetableLabel';
import { createInjection } from 'src/util/createInjection';

type InjectionValue = {
  dt: Ref<Date>;
  budgetGroup: Ref<BudgetGroup | null>;
  timetableLabels: Ref<TimetableLabel[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useSearchConditions');

export function useSearchConditionsProvider(): void {
  const dt = ref(new Date());
  const budgetGroup = ref<BudgetGroup | null>(null);
  const timetableLabels = ref<TimetableLabel[]>([]);

  provide({
    dt,
    budgetGroup,
    timetableLabels,
  });
}

export function useSearchConditions(): InjectionValue {
  return inject();
}
