import { type Ref } from '@vue/composition-api';
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext';
import { createInjection } from 'src/util/createInjection';

type Result = {
  successCount: number;
  failureCount: number;
  errorMessages: string[];
};

type InjectionValue = {
  result: Ref<Result | null>;
  showsCsvUploadResultModal: ModalWithContext<Result>['showsModal'];
  showCsvUploadResultModal: ModalWithContext<Result>['showModal'];
  hideCsvUploadResultModal: ModalWithContext<Result>['hideModal'];
};

const { provide, inject } = createInjection<InjectionValue>('useCsvUploadResultModal');

export function useCsvUploadResultModalProvider(): void {
  const {
    context: result,
    showsModal: showsCsvUploadResultModal,
    showModal: showCsvUploadResultModal,
    hideModal: hideCsvUploadResultModal,
  } = useModalWithContext<Result>();

  provide({
    result,
    showsCsvUploadResultModal,
    showCsvUploadResultModal,
    hideCsvUploadResultModal,
  });
}

export function useCsvUploadResultModal(): InjectionValue {
  return inject();
}
