import regularShiftApi from 'src/apis/staffShift'
import { addDays } from 'date-fns'
import { ComputedRef, InjectionKey, Ref, computed, inject, ref } from '@vue/composition-api'
import { CommonStateKey } from './useCommonState'
import { WorkTimeSummaryViewResponse } from 'src/models/api/staffShiftResponse'
import { ManHoursByDate } from 'src/models/regularShift'

type ManHours = {
  shiftManHoursByMacroOperation: Ref<WorkTimeSummaryViewResponse>
  loadManHoursByMacroOperation: () => Promise<void>
  totalShiftManHoursByDate: ComputedRef<ManHoursByDate>
}

export const useManHours = (): ManHours => {
  const commonState = inject(CommonStateKey);
  if (!commonState) { throw new Error('State error') }

  const {
    displayMode,
    baseDate,
    workplaceId,
    budgetGroup,
  } = commonState

  const shiftManHoursByMacroOperation: Ref<WorkTimeSummaryViewResponse> = ref({ others: {} })
  const totalShiftManHoursByDate: ComputedRef<ManHoursByDate> = computed(() => {
    const manHoursByDate: ManHoursByDate = {}
    Object.values(shiftManHoursByMacroOperation.value).forEach((e: ManHoursByDate) => {
      Object.keys(e).forEach((date) => {
        manHoursByDate[date] = (parseFloat(manHoursByDate[date] || '0') + parseFloat(e[date])).toString()
      })
    })

    return manHoursByDate
  })

  const loadManHoursByMacroOperation = async(): Promise<void> => {
    shiftManHoursByMacroOperation.value = await regularShiftApi.workTimeSummaryView({
      workplace_id: workplaceId.value,
      budget_group_id: budgetGroup.value.id,
      start_date: baseDate.value,
      end_date: addDays(baseDate.value, 10),
      shift_display_mode: displayMode.value,
    })
  }

  return {
    shiftManHoursByMacroOperation,
    loadManHoursByMacroOperation,
    totalShiftManHoursByDate,
  }
}

export const ManHoursStateKey: InjectionKey<ManHours> = Symbol('ManHours');
