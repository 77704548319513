import { unref } from '@vue/composition-api'
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type TimetableMaster } from 'src/models/timetableMaster'
import { useTimetableMasters } from './useTimetableMasters'

type UpdateTimetableMasterDispOrders = {
  updateTimetableMasterDispOrders: (sortedTimetableMasters: TimetableMaster[]) => Promise<boolean>
}

async function requestToUpdateTimetableMasterDispOrders({
  budgetGroup,
  timetableMasters, // APIで取得した工程マスタ全体
  sortedTimetableMasters, // フィルタリング後の画面に表示されている工程マスタ
}: {
  budgetGroup: BudgetGroup
  timetableMasters: TimetableMaster[]
  sortedTimetableMasters: TimetableMaster[]
}): Promise<void> {
  const dispOrderMin = Math.min(...sortedTimetableMasters.map((v) => v.disp_order))
  const dispOrderMap = Object.fromEntries(
    sortedTimetableMasters.map((v, i) => [v.id, dispOrderMin + i])
  )
  const items = [...timetableMasters]
    .sort((a, b) => (dispOrderMap[a.id] ?? a.disp_order) - (dispOrderMap[b.id] ?? b.disp_order))
    .map((timetableMaster, i) => ({
      id: timetableMaster.id,
      disp_order: i + 1,
    }))

  await timetableMasterApi.bulkUpdateDispOrder({
    workplaceId: budgetGroup.workplace_id,
    items,
  })
}

export function useUpdateTimetableMasterDispOrders(): UpdateTimetableMasterDispOrders {
  const errorBoundary = useErrorBoundary()
  const { fetchTimetableMasters, budgetGroup, timetableMasters } = useTimetableMasters()
  const updateTimetableMasterDispOrders = errorBoundary.wrap(
    async(sortedTimetableMasters: TimetableMaster[]) => {
      const unwrappedBudgetGroup = unref(budgetGroup)
      const unwrappedTimetableMasters = unref(timetableMasters)
      if (unwrappedBudgetGroup !== null) {
        await requestToUpdateTimetableMasterDispOrders({
          budgetGroup: unwrappedBudgetGroup,
          timetableMasters: unwrappedTimetableMasters,
          sortedTimetableMasters,
        })
        await fetchTimetableMasters()
      }
    },
    {
      fallbackMessage: '工程マスタの表示順変更に失敗しました。',
      shouldContactUs: true,
    }
  )

  return {
    updateTimetableMasterDispOrders,
  }
}
