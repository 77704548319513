import Vue from 'vue'
import { type PluginServiceRegistrationOptions } from 'chart.js'

export function createVueEmitterPlugin(vue: Vue): PluginServiceRegistrationOptions {
  /**
   * 使うものだけを定義
   * @see {@link https://www.chartjs.org/docs/2.9.4/developers/plugins.html#plugin-core-api}
   */
  return {
    beforeRender: (...args) => {
      vue.$emit('before-render', ...args)
    },
  }
}
