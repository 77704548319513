


















import { defineComponent, type PropType } from '@vue/composition-api';
import { AppropriationDateRange } from '../../types/AppropriationDateRange';

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    appropriationDateRangeOptions: {
      type: Array as PropType<{ value: AppropriationDateRange; label: string }[]>,
      required: true,
    },
    isAppropriationDateRangeOptionDisabled: {
      type: Boolean,
      required: true,
    },
  },
});
