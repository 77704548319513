import { computed, DeepReadonly, ref, type Ref } from '@vue/composition-api';
import { type TimetableLabel } from 'src/models/timetableLabel';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { createInjection } from 'src/util/createInjection';
import type { TimetableRow } from '../types';
import { useTimetableMasters } from 'src/composables/useTimetableMasters';

type InjectionValue = {
  isShiftGroupDisplayed: Ref<boolean>;
  selectedTimetableLabels: Ref<TimetableLabel[]>;
  selectedTimetableMasters: Ref<TimetableMaster[]>;
  filteredTimetableMasterIds: Ref<number[]>;
  filterTimetable: (targetTimetable: DeepReadonly<TimetableRow[]>) => void;
};

const { provide, inject } = createInjection<InjectionValue>('useDisplayConditions');

export function useDisplayConditionsProvider(): void {
  const isShiftGroupDisplayed = ref<boolean>(false);
  const selectedTimetableLabels = ref<TimetableLabel[]>([]);
  const selectedTimetableMasters = ref<TimetableMaster[]>([]);
  const filteredTimetableMasterIds = ref<number[]>([]);

  const selectedTimetableMasterIds = computed<number[]>(() => {
    return selectedTimetableMasters.value.map((timetableMaster) => timetableMaster.id);
  });
  const selectedTimetablesLabelIds = computed<number[]>(() => {
    return selectedTimetableLabels.value.map((timetableLabel) => timetableLabel.id);
  });
  const { timetableMasterMap } = useTimetableMasters();

  const filterTimetable = (targetTimetable: DeepReadonly<TimetableRow[]>) => {
    if (selectedTimetableMasterIds.value.length === 0 && selectedTimetablesLabelIds.value.length === 0) {
      filteredTimetableMasterIds.value = targetTimetable.map((timetableRow) => timetableRow.header.masterId);
      return;
    }
    filteredTimetableMasterIds.value = targetTimetable
      .filter((timetableRow) => {
        if (selectedTimetableMasterIds.value.includes(timetableRow.header.masterId ?? NaN)) {
          return true;
        }
        if (
          selectedTimetablesLabelIds.value.includes(
            timetableMasterMap.value[timetableRow.header.masterId ?? NaN]?.timetable_label_id,
          )
        ) {
          return true;
        }
        return false;
      })
      .map((timetableRow) => timetableRow.header.masterId);
  };

  provide({
    isShiftGroupDisplayed,
    selectedTimetableLabels,
    selectedTimetableMasters,
    filteredTimetableMasterIds,
    filterTimetable,
  });
}

export function useDisplayConditions(): InjectionValue {
  return inject();
}
