







import { computed, defineComponent } from '@vue/composition-api'
import { formatDateForRegularShift, getWeekDay } from 'src/util/datetime'
import { format } from 'date-fns'

export default defineComponent({
  props: {
    dateStr: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const dateString = computed((): string => {
      return formatDateForRegularShift(props.dateStr);
    })

    const weekDay = computed((): string => {
      return getWeekDay(props.dateStr);
    })

    const isToday = computed((): boolean => {
      return format((new Date()), 'yyyy-MM-dd') === props.dateStr
    })

    return {
      dateString,
      weekDay,
      isToday,
    }
  }
})
