import { type TimeRange } from './TimeRange'
import { type StrictTimeRange } from './StrictTimeRange'

export function isValidTimeRange(timeRange: TimeRange | StrictTimeRange): boolean {
  if (Number.isNaN(timeRange.startTime) || Number.isNaN(timeRange.endTime)) {
    return false
  }

  if (timeRange.startTime === null && timeRange.endTime === null) {
    return false
  }

  return timeRange.startTime === null || timeRange.endTime === null || timeRange.startTime < timeRange.endTime
}
