











































import { defineComponent } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { DateRangeSelect } from '../DateRangeSelect';
import { ProcessTypeSelect } from '../ProcessTypeSelect';
import { StatusSelect } from '../StatusSelect';
import { SearchButton } from '../SearchButton';
import { useBackgroundProcessStatuses } from '../../composables/useBackgroundProcessStatuses';

export default defineComponent({
  components: {
    DateRangeSelect,
    BudgetGroupSelect,
    ProcessTypeSelect,
    StatusSelect,
    SearchButton,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups();
    const { dateRange, budgetGroup, processType, status, isLoading, fetchBackgroundProcessStatuses } =
      useBackgroundProcessStatuses();

    return {
      budgetGroups,
      dateRange,
      budgetGroup,
      processType,
      status,
      isLoading,
      fetchBackgroundProcessStatuses,
    };
  },
});
