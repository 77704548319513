
























import { defineComponent } from '@vue/composition-api'
import { CardPanel } from '../CardPanel'
import ForecastedCompletionTime from './ForecastedCompletionTime.vue'
import ForecastedRemainingQuantity from './ForecastedRemainingQuantity.vue'
import Progress from './Progress.vue'
import ManHours from './ManHours.vue'
import Productivity from './Productivity.vue'
import Headcount from './Headcount.vue'

export default defineComponent({
  components: {
    CardPanel,
    ForecastedCompletionTime,
    ForecastedRemainingQuantity,
    Progress,
    ManHours,
    Productivity,
    Headcount,
  },
})
