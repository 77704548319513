import { type TimeInteger } from 'src/models/common';
import { type StaffShiftOverviewResponse } from 'src/models/api/staffShiftResponse';

type DateKey = string; // YYYY-MM-DD
type HeadcountMapByTime = { [key in TimeInteger]?: number };
type HeadcountNestedMapByDate = { [key in DateKey]?: HeadcountMapByTime };
export type StaffShiftOverview = {
  exceeds_limit: boolean;
  aggregation_result: HeadcountNestedMapByDate;
};

export function convertStaffShiftOverviewResponse(response: StaffShiftOverviewResponse): StaffShiftOverview {
  return response;
}
