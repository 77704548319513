









import { defineComponent, ref, watch, type PropType } from '@vue/composition-api';
import { isSameDay } from 'date-fns';
import { type BudgetGroupPlanBoardMisc } from 'src/models/budgetGroupPlanBoardMisc';
import { useSearchConditions } from '../../composables/useSearchConditions';
import { useUpdateBudgetGroupPlanBoardMiscMemo } from '../../composables/useUpdateBudgetGroupPlanBoardMiscMemo';
import { Textarea } from '../Textarea';

export default defineComponent({
  components: {
    Textarea,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
    budgetGroupPlanBoardMiscs: {
      type: Array as PropType<BudgetGroupPlanBoardMisc[]>,
      required: true,
    },
  },
  setup(props) {
    const memo = ref('');
    const { budgetGroup } = useSearchConditions();
    const { updateBudgetGroupPlanBoardMiscMemo } = useUpdateBudgetGroupPlanBoardMiscMemo();
    const handleInput = async (newMemo: string) => {
      if (budgetGroup.value === null) {
        return;
      }

      const result = await updateBudgetGroupPlanBoardMiscMemo({
        budgetGroup: budgetGroup.value,
        date: props.date,
        memo: newMemo,
      });

      if (result) {
        memo.value = newMemo;
      }
    };

    watch(
      () => [props.date, props.budgetGroupPlanBoardMiscs] as const,
      ([date, budgetGroupPlanBoardMiscs]) => {
        memo.value = budgetGroupPlanBoardMiscs.find((v) => isSameDay(v.dt, date))?.memo ?? '';
      },
      {
        immediate: true,
      },
    );

    return {
      memo,
      handleInput,
    };
  },
});
