import { computed, defineComponent, getCurrentInstance, onMounted, onUnmounted, PropType, reactive, SetupContext, watch } from '@vue/composition-api'
import { vvGetError, vvValidate } from 'src/util/vee_validate'
import { packToTimeIntegerWithGuard, unpackTimeIntegerToString } from 'src/util/datetime'

export default defineComponent({
  name: 'time-range-input',
  props: {
    startTime: {
      type: Number,
      required: true,
    },
    endTime: {
      type: Number,
      required: true,
    },
    onBlur: {
      type: Function as PropType<(args: { startTime: number, endTime: number }) => void>,
      required: true,
    },
  },
  setup(props) {
    const vueInstance = getCurrentInstance()?.proxy.$root!

    const state = reactive({
      startTime: 0,
      endTime: 0,
      startTimeHour: '00',
      startTimeMin: '00',
      endTimeHour: '00',
      endTimeMin: '00',
    })

    const setStartTime = (startTime: number) => {
      state.startTime = startTime
      const [h, m] = unpackTimeIntegerToString(startTime)
      state.startTimeHour = h
      state.startTimeMin = m
    }
    const setEndTime = (endTime: number) => {
      state.endTime = endTime
      const [h, m] = unpackTimeIntegerToString(endTime)
      state.endTimeHour = h
      state.endTimeMin = m
    }
    setStartTime(props.startTime);
    setEndTime(props.endTime);

    watch(() => props.startTime, setStartTime)
    watch(() => props.endTime, setEndTime)

    const validations = computed(() => ({
      startTimeHour: { required: true, numeric: true, max: 2, max_value: 47 },
      startTimeMin: { required: true, numeric: true, max: 2, regex: /^(00|15|30|45)$/ },
      endTimeHour: { required: true, numeric: true, max: 2, max_value: 47 },
      endTimeMin: { required: true, numeric: true, max: 2, regex: /^(00|15|30|45)$/ },
    }))

    const getError = (fieldName: string) => {
      return vvGetError(vueInstance, fieldName)
    }

    const onTimeInputChange = () => {
      state.startTime = packToTimeIntegerWithGuard(state.startTimeHour, state.startTimeMin, 0) || 0
      state.endTime = packToTimeIntegerWithGuard(state.endTimeHour, state.endTimeMin, 0) || 0
      vvValidate(vueInstance, 'startToEndCheck')
    }

    const onTimeInputBlur = () => {
      props.onBlur({
        startTime: state.startTime,
        endTime: state.endTime,
      })
    }

    onMounted(() => {
      vueInstance.$validator.attach({
        name: 'startToEndCheck',
        rules: 'custom_after1',
        getter: () => {
          return {
            start_time: state.startTime,
            end_time: state.endTime,
            disallow_equal: true
          }
        },
      })
    })

    onUnmounted(() => {
      vueInstance.$validator.detach('startToEndCheck')
    })

    return {
      state,
      validations,
      getError,
      onTimeInputChange,
      onTimeInputBlur,
    }
  },
})
