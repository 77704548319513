















import { defineComponent } from '@vue/composition-api'
import { Checkbox } from '../Checkbox'

export default defineComponent({
  components: {
    Checkbox,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
  },
})
