






import { defineComponent, SetupContext } from '@vue/composition-api'
import { isExist } from 'src/util/isExist'

const STYLE_FOR_PRINT_ID = 'style-for-print'

/**
 * 印刷用の @page スタイルは、各ページの scss に書いても排他的に管理できない。（例えば size の landscape がどこかのページで定義されると、他のページも landscape な印刷結果になってしまう）
 * 上記を回避するために印刷用の style タグを 1 つ用意し、印刷前に適宜その内容を書き換えることで対応する。
 *
 * （もっと手軽ないい方法あればそれに乗り換えたい）
 */
export const setStyleForPrint = ({ size, margin }: { size?: string, margin?: string } = {}) => {
  let styleForPrintElement = document.querySelector(`${STYLE_FOR_PRINT_ID}`)

  if (!isExist(styleForPrintElement)) {
    styleForPrintElement = document.createElement('style')
    styleForPrintElement.id = STYLE_FOR_PRINT_ID
    document.head.appendChild(styleForPrintElement)
  }

  styleForPrintElement.innerHTML = `@page {
    margin: ${margin ?? '0'};
  }
  .PrintLayout {
    margin: ${margin ?? '0'};
  }
  `
  const printLayoutElement = document.querySelector('.PrintLayout')
  if (isExist(printLayoutElement)) {
    printLayoutElement.classList.remove('A3', 'A4', 'A5', 'landscape');
    printLayoutElement.classList.add(...size?.split(' ') ?? 'A3');
  }
}

export default defineComponent({
  components: {
  },
  setup(_props, context: SetupContext) {
    return {
    }
  },
})
