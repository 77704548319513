import axios from 'axios';
import { getApiUrl } from 'src/apis/apiUtils';
import { StringOrNumber } from 'src/models/common';
import {
  IndexParams,
  CreateParams,
  UpdateParams,
  DeleteParams,
  AssignParams,
  UnassignParams,
  BulkUpdateParams,
  PerformanceBoardListIndexParams,
} from 'src/models/api/timetableGroupMasterRequest';
import { TimetableGroupMaster, TimetableGroupMasterWithProgressHeaders } from 'src/models/timetableGroupMaster';
import { TimetableGroupMasterResponse } from 'src/models/api/timetableGroupMasterResponse';

function getResourceBaseUrl(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/timetable_group_masters`);
}

export default {
  async index(params: IndexParams): Promise<TimetableGroupMaster[]> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    const { data } = await axios.get<TimetableGroupMasterResponse[]>(getResourceBaseUrl(workplaceId, budgetGroupId), {
      params: paramsRest,
    });
    return data;
  },
  async create(params: CreateParams): Promise<TimetableGroupMaster> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    const { data } = await axios.post<TimetableGroupMasterResponse>(
      getResourceBaseUrl(workplaceId, budgetGroupId),
      paramsRest,
    );

    return data;
  },
  async update(params: UpdateParams): Promise<TimetableGroupMaster> {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      id: timetableGroupMasterId,
      ...paramsRest
    } = params;

    const { data } = await axios.put<TimetableGroupMasterResponse>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId)}/${timetableGroupMasterId}`,
      paramsRest,
    );
    return data;
  },
  async delete(params: DeleteParams) {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      timetable_group_master_id: timetableGroupMasterId,
    } = params;

    await axios.delete(`${getResourceBaseUrl(workplaceId, budgetGroupId)}/${timetableGroupMasterId}`);
  },
  async bulkUpdateDispOrder(params: BulkUpdateParams): Promise<TimetableGroupMaster[]> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;

    const { data } = await axios.post<TimetableGroupMasterResponse[]>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId)}/bulk_update_disp_order`,
      paramsRest,
    );
    return data;
  },
  async assignTimetableMasters(params: AssignParams): Promise<TimetableGroupMaster> {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      timetable_group_master_id: timetableGroupMasterId,
      ...paramsRest
    } = params;
    const { data } = await axios.post<TimetableGroupMasterResponse>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId)}/${timetableGroupMasterId}/assign_timetable_master`,
      paramsRest,
    );
    return data;
  },
  async unassignTimetableMaster(params: UnassignParams) {
    const {
      workplace_id: workplaceId,
      budget_group_id: budgetGroupId,
      timetable_group_master_id: timetableGroupMasterId,
      ...paramsRest
    } = params;

    const { data } = await axios.post<TimetableGroupMasterResponse>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId)}/${timetableGroupMasterId}/unassign_timetable_master`,
      paramsRest,
    );
  },
  async performanceBoardListIndex(
    params: PerformanceBoardListIndexParams,
  ): Promise<TimetableGroupMasterWithProgressHeaders[]> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    const { data } = await axios.get<TimetableGroupMasterWithProgressHeaders[]>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId)}/get_performance_board_list`,
      { params: paramsRest },
    );

    return data;
  },
};
