import { ref, onMounted, type Ref } from '@vue/composition-api'
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { createInjection } from 'src/util/createInjection'

type Params = {
  workplaceId: number
}

type InjectionValue = {
  budgetGroups: Ref<BudgetGroup[]>
}

const { provide, inject } = createInjection<InjectionValue>('useBudgetGroups')

export function useBudgetGroupsProvider({ workplaceId }: Params): void {
  const errorBoundary = useErrorBoundary()
  const budgetGroups = ref<BudgetGroup[]>([])
  const fetchBudgetGroups = errorBoundary.wrap(
    async () => {
      budgetGroups.value = await budgetGroupApi.index(workplaceId)
    },
    {
      fallbackMessage: '管理グループの取得に失敗しました',
    }
  )

  onMounted(fetchBudgetGroups)

  provide({
    budgetGroups,
  })
}

export function useBudgetGroups(): InjectionValue {
  return inject()
}
