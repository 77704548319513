import { format } from 'date-fns';
import staffShiftApi from 'src/apis/staffShift';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { useModal } from 'src/composables/useModal';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { createInjection } from 'src/util/createInjection';

type Params = {
  budgetGroup: BudgetGroup;
  date: Date;
  changeAttendanceType: number;
};

type InjectionValue = {
  showsLogimeterStaffAttendancesLoadModal: ReturnType<typeof useModal>['showsModal'];
  showLogimeterStaffAttendancesLoadModal: ReturnType<typeof useModal>['showModal'];
  hideLogimeterStaffAttendancesLoadModal: ReturnType<typeof useModal>['hideModal'];
  loadLogimeterStaffAttendances: (params: Params) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useLoadLogimeterStaffAttendances');

export function useLoadLogimeterStaffAttendancesProvider(): void {
  const errorBoundary = useErrorBoundary();
  const {
    showsModal: showsLogimeterStaffAttendancesLoadModal,
    showModal: showLogimeterStaffAttendancesLoadModal,
    hideModal: hideLogimeterStaffAttendancesLoadModal,
  } = useModal();
  const { notifySuccess } = useNotification();
  const loadLogimeterStaffAttendances = errorBoundary.wrap(
    async ({ budgetGroup, date, changeAttendanceType }: Params) => {
      await staffShiftApi.loadLogimeterStaffAttendances({
        workplace_id: budgetGroup.workplace_id,
        budget_group_id: budgetGroup.id,
        dt: format(date, 'yyyy-MM-dd'),
        change_attendance_type: changeAttendanceType,
      });
      hideLogimeterStaffAttendancesLoadModal();
      notifySuccess('出勤実績をLMから受信を開始しました');
    },
    {
      fallbackMessage: '出勤実績をLMから受信の開始に失敗しました。',
      shouldContactUs: true,
    },
  );

  provide({
    showsLogimeterStaffAttendancesLoadModal,
    showLogimeterStaffAttendancesLoadModal,
    hideLogimeterStaffAttendancesLoadModal,
    loadLogimeterStaffAttendances,
  });
}

export function useLoadLogimeterStaffAttendances(): InjectionValue {
  return inject();
}
