





















































import { defineComponent, computed, ref } from '@vue/composition-api'
import { CreateButton } from 'src/components/UIComponents/Buttons/CreateButton'
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton'
import PaginationContainer from 'src/components/UIComponents/PaginationContainer.vue'
import { type ProgressDetail } from 'src/models/progressHeader'
import { ProgressDetailList, ProgressDetailListItem } from '../../components/ProgressDetailList'
import { useProgressDetailListModal } from '../../composables/useProgressDetailListModal'
import { useCreateProgressDetail } from '../../composables/useCreateProgressDetail'
import { useUpdateProgressDetail } from '../../composables/useUpdateProgressDetail'
import { useDeleteProgressDetail } from '../../composables/useDeleteProgressDetail'
import { sortProgressDetails } from './sortProgressDetails'
import { getCumulativeQuantity } from './getCumulativeQuantity'

export default defineComponent({
  components: {
    PaginationContainer,
    ProgressDetailList,
    ProgressDetailListItem,
    CreateButton,
    NormalButton,
  },
  setup() {
    const currentPage = ref(1)
    const {
      progressHeader,
      fetchProgressHeader,
      showsProgressDetailListModal,
      hideProgressDetailListModal,
    } = useProgressDetailListModal()
    const sortedProgressDetails = computed(() => sortProgressDetails(progressHeader.value?.progress_details ?? []))
    const { showProgressDetailCreateModal } = useCreateProgressDetail()
    const { showProgressDetailUpdateModal } = useUpdateProgressDetail()
    const { showProgressDetailDeleteConfirmationModal } = useDeleteProgressDetail()
    const handleClose = () => {
      currentPage.value = 1
      hideProgressDetailListModal()
    }
    const handleCreate = () => {
      if (progressHeader.value !== null && fetchProgressHeader.value !== null) {
        showProgressDetailCreateModal({
          progressHeader: progressHeader.value,
          fetchProgressHeader: fetchProgressHeader.value,
        })
      }
    }
    const handleEdit = (progressDetail: ProgressDetail) => {
      if (progressHeader.value !== null && fetchProgressHeader.value !== null) {
        showProgressDetailUpdateModal({
          progressHeader: progressHeader.value,
          progressDetail,
          fetchProgressHeader: fetchProgressHeader.value,
        })
      }
    }
    const handleDelete = (progressDetail: ProgressDetail) => {
      if (progressHeader.value !== null && fetchProgressHeader.value !== null) {
        showProgressDetailDeleteConfirmationModal({
          progressHeader: progressHeader.value,
          progressDetail,
          fetchProgressHeader: fetchProgressHeader.value,
        })
      }
    }

    return {
      currentPage,
      progressHeader,
      showsProgressDetailListModal,
      sortedProgressDetails,
      handleClose,
      handleCreate,
      handleEdit,
      handleDelete,
      getCumulativeQuantity,
    }
  },
})
