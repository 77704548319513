import { Staff } from 'src/models/staff';

type StaffCompareMap = Pick<
  Staff,
  'family_name_kana' | 'first_name_kana' | 'family_name' | 'first_name' | 'staff_number'
>;
type CompareResult = -1 | 0 | 1;
type StaffComparator = <T extends StaffCompareMap>(staff1: T, staff2: T) => CompareResult;

export function compareStaff<T extends Staff>(a: T, b: T, ignoreBudgetGroupOrder: boolean): number {
  if (!ignoreBudgetGroupOrder) {
    const n1 = a.budget_group ? a.budget_group.disp_order : 99999;
    const n2 = b.budget_group ? b.budget_group.disp_order : 99999;
    if (n1 < n2) {
      return -1;
    }
    if (n1 > n2) {
      return 1;
    }
  }

  // かな順→スタッフ名のソート
  return compareByKanaAndName(a, b);
}

export function sortStaffs<T extends Staff>(staffs: T[], ignoreBudgetGroupOrder: boolean = false): T[] {
  return staffs.sort((a, b) => {
    return compareStaff(a, b, ignoreBudgetGroupOrder);
  });
}

// かな順のソート用比較処理
export function compareByKana<T extends StaffCompareMap>(staff1: T, staff2: T): CompareResult {
  // 半角スペースは全角よりも文字コード的に先頭側である.
  // 親鸞の鸞は文字コード的に相当末尾側である.
  const s1 = (staff1.family_name_kana || '鸞') + ' ' + (staff1.first_name_kana || '鸞');
  const s2 = (staff2.family_name_kana || '鸞') + ' ' + (staff2.first_name_kana || '鸞');
  return s1 < s2 ? -1 : s1 > s2 ? 1 : 0;
}

// 従業員名順のソート用比較処理
export function compareByName<T extends StaffCompareMap>(staff1: T, staff2: T): CompareResult {
  const s1 = staff1.family_name + ' ' + staff1.first_name;
  const s2 = staff2.family_name + ' ' + staff2.first_name;
  return s1 < s2 ? -1 : s1 > s2 ? 1 : 0;
}

// 従業員番号順のソート用比較処理
export function compareByStaffNumber<T extends StaffCompareMap>(staff1: T, staff2: T): CompareResult {
  const s1 = staff1.staff_number;
  const s2 = staff2.staff_number;
  return s1 < s2 ? -1 : s1 > s2 ? 1 : 0;
}

function createCompareFunc(comparators: StaffComparator[]): StaffComparator {
  return <T extends StaffCompareMap>(staff1: T, staff2: T): CompareResult => {
    let result: CompareResult = 0;
    for (const comparator of comparators) {
      result = comparator(staff1, staff2);
      if (result !== 0) {
        return result;
      }
    }
    return result;
  };
}

export const compareByKanaAndName = createCompareFunc([compareByKana, compareByName]);
export const compareByKanaAndStaffNumber = createCompareFunc([compareByKana, compareByStaffNumber]);
