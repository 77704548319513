type TimeRange = {
  start: number | null
  end: number | null
}
type NonNullableTimeRange = {
  start: number
  end: number
}

export const custom_after1 = {
  getMessage: () => 'end_time must be after start_time',
  validate: (value: any) => {
    const disallowEqual = !!value.disallow_equal
    if (!disallowEqual && hasTime(value.start_time) && hasTime(value.end_time)) {
      return value.start_time <= value.end_time
    }

    if (disallowEqual && hasTime(value.start_time) && hasTime(value.end_time)) {
      return value.start_time < value.end_time
    }

    return true
  }
}

export const validateCustomAfter2 = (value: any) => {
  const workTimeRange: TimeRange = { start: value.start_time, end: value.end_time }
  const break1TimeRange: TimeRange = { start: value.break1_start_time, end: value.break1_end_time }
  const break2TimeRange: TimeRange = { start: value.break2_start_time, end: value.break2_end_time }

  if (!isTimeRangeValidOrEmpty(workTimeRange)) {
    return false
  }
  if (!isTimeRangeValidOrEmpty(break1TimeRange)) {
    return false
  }
  if (!isTimeRangeValidOrEmpty(break2TimeRange)) {
    return false
  }

  if (isTimeRangeEmpty(workTimeRange)) {
    if (isTimeRangePresent(break1TimeRange)) {
      return false
    }
    if (isTimeRangePresent(break2TimeRange)) {
      return false
    }
  }

  if (isTimeRangeEmpty(break1TimeRange)) {
    if (isTimeRangePresent(break2TimeRange)) {
      return false
    }
  }

  if (isTimeRangePresent(workTimeRange) && isTimeRangePresent(break1TimeRange)) {
    if (!isTimeRangeContain(workTimeRange, break1TimeRange)) {
      return false
    }
  }

  if (isTimeRangePresent(workTimeRange) && isTimeRangePresent(break2TimeRange)) {
    if (!isTimeRangeContain(workTimeRange, break2TimeRange)) {
      return false
    }
  }

  if (isTimeRangePresent(break1TimeRange) && isTimeRangePresent(break2TimeRange)) {
    if (break2TimeRange.start < break1TimeRange.end) {
      return false
    }
  }

  return true
}

export const custom_after2 = {
  getMessage: () => 'break1_time and break2_time must be between start_time to end_time and must not be equal',
  validate: validateCustomAfter2,
}

const hasTime = (time: number | null): boolean => {
  return !!(time || time === 0)
}

const isTimeRangeEmpty = (range: TimeRange): boolean => {
  return !hasTime(range.start) && !hasTime(range.end)
}

const isTimeRangePresent = (range: TimeRange): range is NonNullableTimeRange => {
  return hasTime(range.start) && hasTime(range.end)
}

const isTimeRangeValid = (range: TimeRange): boolean => {
  return isTimeRangePresent(range) && range.start < range.end
}

const isTimeRangeValidOrEmpty = (range: TimeRange): boolean => {
  return isTimeRangeValid(range) || isTimeRangeEmpty(range)
}

const isTimeRangeContain = (rangeA: NonNullableTimeRange, rangeB: NonNullableTimeRange) => {
  return rangeA.start <= rangeB.start && rangeB.end <= rangeA.end
}
