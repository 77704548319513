import { render, staticRenderFns } from "./HeadCountsByLabel.vue?vue&type=template&id=baa3e634&scoped=true"
import script from "./HeadCountsByLabel.vue?vue&type=script&lang=ts"
export * from "./HeadCountsByLabel.vue?vue&type=script&lang=ts"
import style0 from "./HeadCountsByLabel.vue?vue&type=style&index=0&id=baa3e634&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa3e634",
  null
  
)

export default component.exports