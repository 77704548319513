import { ref, type Ref } from '@vue/composition-api';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { createInjection } from 'src/util/createInjection';
import { useModal } from 'src/composables/useModal';
import ProgressDetailApi from 'src/apis/progressDetail';
import { useSearchConditions } from './useSearchConditions';
import { isExist } from 'src/util/isExist';
import { ProgressDetailBulkCreateItem } from 'src/models/api/progressDetailRequest';
import { useDailySimulation } from './useDailySimulation';

type InjectionValue = {
  isLoading: Ref<boolean>;
  showsProgressDetailBulkCreateFetchModal: ReturnType<typeof useModal>['showsModal'];
  showProgressDetailBulkCreateFetchModal: ReturnType<typeof useModal>['showModal'];
  hideProgressDetailBulkCreateFetchModal: ReturnType<typeof useModal>['hideModal'];
  progressDetailBulkCreate: (data: ProgressDetailBulkCreateItem[]) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useProgressDetailBulkCreateFetchModal');

export function useProgressDetailBulkCreateFetchModalProvider(): void {
  const errorBoundary = useErrorBoundary();
  const {
    showsModal: showsProgressDetailBulkCreateFetchModal,
    showModal: showProgressDetailBulkCreateFetchModal,
    hideModal: hideProgressDetailBulkCreateFetchModal,
  } = useModal();
  const { budgetGroup } = useSearchConditions();
  const isLoading = ref(false);

  const { fetchAndSimulate } = useDailySimulation();

  const progressDetailBulkCreate = errorBoundary.wrap(
    async (data: ProgressDetailBulkCreateItem[]) => {
      if (!isExist(budgetGroup.value)) {
        return;
      }

      isLoading.value = true;
      try {
        await ProgressDetailApi.subBudgetGroupBulkCreate({
          workplace_id: budgetGroup.value.workplace_id,
          budget_group_id: budgetGroup.value.id,
          items: data,
        });
        fetchAndSimulate({
          isResetHeader: false,
          isResetHeadcount: true,
        });
        hideProgressDetailBulkCreateFetchModal();
      } finally {
        isLoading.value = false;
      }
    },
    {
      fallbackMessage: '進捗履歴の作成に失敗しました。',
    },
  );

  provide({
    progressDetailBulkCreate,
    isLoading,
    showsProgressDetailBulkCreateFetchModal,
    showProgressDetailBulkCreateFetchModal,
    hideProgressDetailBulkCreateFetchModal,
  });
}

export function useProgressDetailBulkCreateFetchModal(): InjectionValue {
  return inject();
}
