






























import { defineComponent } from '@vue/composition-api'
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect'
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch'
import { useBudgetGroups } from 'src/composables/useBudgetGroups'
import { Checkbox } from '../Checkbox'
import { useTimetableMasters } from '../../composables/useTimetableMasters'

export default defineComponent({
  components: {
    BudgetGroupSelect,
    ToggleSwitch,
    Checkbox,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups()
    const {
      budgetGroup,
      useInPrepPhase,
      useInDoPhase,
      useInReviewPhase,
      includesDisabled,
      isLoading,
    } = useTimetableMasters()

    return {
      budgetGroups,
      budgetGroup,
      useInPrepPhase,
      useInDoPhase,
      useInReviewPhase,
      includesDisabled,
      isLoading,
    }
  },
})
