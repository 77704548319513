import {
  StaffWithTimetableMasterSummaries,
  TimetableMasterSummary,
  TotalSummary,
} from 'src/models/staffExperienceOverview';

export const generateTotalSummary = (
  staffsWithTimetableMasterSummaries: StaffWithTimetableMasterSummaries[],
): TotalSummary => {
  const totalSummary = generateInitialTotalSummary(staffsWithTimetableMasterSummaries[0].timetable_master_summaries);

  staffsWithTimetableMasterSummaries.forEach((e) => {
    totalSummary.staffCount += 1;
    totalSummary.keyPlayerCount += e.staff_extension.is_key_player ? 1 : 0;
    totalSummary.forkmanCount += e.staff_extension.is_forkman ? 1 : 0;
    totalSummary.customSkill1Count += e.staff_extension.has_custom_skill1 ? 1 : 0;
    totalSummary.customSkill2Count += e.staff_extension.has_custom_skill2 ? 1 : 0;
    totalSummary.customSkill3Count += e.staff_extension.has_custom_skill3 ? 1 : 0;

    e.timetable_master_summaries.forEach((timetableMasterSummary) => {
      const totalTimetableMasterSummary = totalSummary.timetableMasterSummaries.find((f) => {
        return f.timetable_master.id === timetableMasterSummary.timetable_master.id;
      });

      if (totalTimetableMasterSummary === undefined) {
        return;
      }

      totalTimetableMasterSummary.experience_count += Math.round(timetableMasterSummary.work_hours) > 0 ? 1 : 0;
    });
  });

  return totalSummary;
};

const generateInitialTotalSummary = (timetableMasterSummaries: TimetableMasterSummary[]): TotalSummary => {
  const totalTimetableMasterSummaries = timetableMasterSummaries.map((e) => {
    return {
      timetable_master: e.timetable_master,
      experience_count: 0,
    };
  });

  return {
    staffCount: 0,
    keyPlayerCount: 0,
    forkmanCount: 0,
    customSkill1Count: 0,
    customSkill2Count: 0,
    customSkill3Count: 0,
    timetableMasterSummaries: totalTimetableMasterSummaries,
  };
};
