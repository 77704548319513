












import { defineComponent } from '@vue/composition-api'
import ShiftPatternListRow from './ShiftPatternListRow.vue'
import ShiftPatternListCell from './ShiftPatternListCell.vue'

export default defineComponent({
  components: {
    ShiftPatternListRow,
    ShiftPatternListCell,
  },
})
