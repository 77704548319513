
































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  props: {
    withSpacing: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const style = computed(() => {
      return {
        rowGap: props.withSpacing ? '4px' : '0px',
      };
    });
    return {
      style,
    };
  },
});
