import { unref } from '@vue/composition-api'
import shiftPatternApi from 'src/apis/workplace_masters/shiftPattern'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'
import { createInjection } from 'src/util/createInjection'
import { useModalWithShiftPattern } from './useModalWithShiftPattern'
import { useShiftPatterns } from './useShiftPatterns'

type InjectionValue = {
  shiftPattern: ReturnType<typeof useModalWithShiftPattern>['shiftPattern']
  showsDeleteConfirmationModal: ReturnType<typeof useModalWithShiftPattern>['showsModal']
  showDeleteConfirmationModal: ReturnType<typeof useModalWithShiftPattern>['showModal']
  hideDeleteConfirmationModal: ReturnType<typeof useModalWithShiftPattern>['hideModal']
  deleteShiftPattern: () => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useDeleteShiftPattern')

async function requestToDeleteShiftPattern({
  budgetGroup,
  shiftPattern,
}: {
  budgetGroup: BudgetGroup
  shiftPattern: ShiftPattern
}): Promise<void> {
  await shiftPatternApi.delete({
    workplace_id: budgetGroup.workplace_id,
    budget_group_id: budgetGroup.id,
    id: shiftPattern.id,
  })
}

export function useDeleteShiftPatternProvider(): void {
  const { notifySuccess } = useNotification()
  const errorBoundary = useErrorBoundary()
  const {
    shiftPattern,
    showsModal: showsDeleteConfirmationModal,
    showModal: showDeleteConfirmationModal,
    hideModal: hideDeleteConfirmationModal,
  } = useModalWithShiftPattern()
  const { fetchShiftPatterns, budgetGroup } = useShiftPatterns()
  const deleteShiftPattern = errorBoundary.wrap(
    async () => {
      const unwrappedBudgetGroup = unref(budgetGroup)
      const unwrappedShiftPattern = unref(shiftPattern)
      if (unwrappedBudgetGroup !== null && unwrappedShiftPattern !== null) {
        await requestToDeleteShiftPattern({
          budgetGroup: unwrappedBudgetGroup,
          shiftPattern: unwrappedShiftPattern,
        })
        hideDeleteConfirmationModal()
        notifySuccess('シフトパターンを削除しました')
        await fetchShiftPatterns()
      }
    },
    {
      fallbackMessage: 'シフトパターンの削除に失敗しました。',
      shouldContactUs: true,
    }
  )

  provide({
    shiftPattern,
    showsDeleteConfirmationModal,
    showDeleteConfirmationModal,
    hideDeleteConfirmationModal,
    deleteShiftPattern,
  })
}

export function useDeleteShiftPattern(): InjectionValue {
  return inject()
}
