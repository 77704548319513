


import {
  defineComponent,
  onMounted,
  reactive,
  SetupContext
} from '@vue/composition-api'
import Vue from 'vue'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { ensureUserAndMasters } from 'src/hooks/masterHook'
import { navigateToLogiCoredata } from 'src/util/logibase'

interface State {
  logiCoredataUrl: string
}

export default defineComponent({
  setup(_props, context: SetupContext) {
    const root = context.root as Vue
    const state: State = reactive({
      ...wrappedMapGetters(root.$store, 'user', ['logiCoredataUrl']),
    })

    onMounted(async() => {
      await ensureUserAndMasters(context)
      // Redirect to LogiCoredata if query parameter 'path' is defined,
      // otherwise do nothing.
      if (root.$route.query.path && typeof root.$route.query.path === 'string') {
        navigateToLogiCoredata(state.logiCoredataUrl, root.$route.query.path)
      }
    })

    return {
      state
    }
  },
})
