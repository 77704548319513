// throttleとかdebounceが実装してあってもいいよね的な
type GatedFuncResult<R> = [false, undefined] | [true, R]

interface GatedFuncGenerator {
  makeAsyncFuncGated: <A extends any[] = any[], R extends any = any>(
    asyncFunc: (...args: A) => Promise<R>,
    tag?: string
  ) => (...args: A) => Promise<GatedFuncResult<R>>,
}

export function getGatedFuncGenerator(): GatedFuncGenerator {
  const tagMap: Record<string, boolean> = {}
  return {
    makeAsyncFuncGated<A extends any[] = any[], R extends any = any>(
      asyncFunc: (...args: A) => Promise<R>,
      tag: string = 'isLoading'
    ): (...args: A) => Promise<GatedFuncResult<R>> {
      return async(...args: A) => {
        if (tagMap[tag]) { return [false, undefined] }
        tagMap[tag] = true

        let succeeded = false
        let funcResult: R
        try {
          funcResult = await asyncFunc(...args)
          succeeded = true
        } finally {
          tagMap[tag] = false
        }

        return succeeded ? [true, funcResult] : [false, undefined]
      }
    },
  }
}
