import { ref, watch, type Ref } from '@vue/composition-api'
import progressHeaderApi from 'src/apis/progressHeader'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { convertProgressHeaderPerformanceBoardDetailShowResponse, type ProgressHeader } from 'src/models/progressHeader'
import { createInjection } from 'src/util/createInjection'

type Params = {
  progressHeader: Ref<ProgressHeader>
}

type InjectionValue = {
  progressHeader: Ref<ProgressHeader>
  fetchProgressHeader: () => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useProgressHeader')

async function getProgressHeader(progressHeader: ProgressHeader): Promise<ProgressHeader> {
  const { data } = await progressHeaderApi.performanceBoardDetailShow({
    id: progressHeader.id,
    workplace_id: progressHeader.workplace_id,
  })
  return convertProgressHeaderPerformanceBoardDetailShowResponse(data)
}

export function useProgressHeaderProvider({ progressHeader: initialProgressHeader }: Params): void {
  const progressHeader = ref<ProgressHeader>(initialProgressHeader.value)
  const errorBoundary = useErrorBoundary()
  const fetchProgressHeader = errorBoundary.wrap(
    async() => {
      progressHeader.value = await getProgressHeader(progressHeader.value)
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  )

  watch(initialProgressHeader, (newProgressHeader) => {
    progressHeader.value = newProgressHeader
  })

  provide({
    progressHeader,
    fetchProgressHeader,
  })
}

export function useProgressHeader(): InjectionValue {
  return inject()
}
