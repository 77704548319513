import axios from 'axios'
import { StringOrNumber } from 'src/models/common'
import { getApiUrl } from 'src/apis/apiUtils';
import { TimelineIndexParams } from 'src/models/api/timelineRequest';
import { TimelineIndexResponse } from 'src/models/api/timelineResponse';
import { TimelineIndexResult, convertTimelineIndexResult } from 'src/models/timeline';

function getResourceBaseUrl1(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/timelines`)
}

export default {
  async timelineIndex(params: TimelineIndexParams): Promise<TimelineIndexResult> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.get<TimelineIndexResponse>(`${getResourceBaseUrl1(workplaceId, budgetGroupId)}`, {
      params: paramsRest,
    })
    return convertTimelineIndexResult(data)
  },
}
