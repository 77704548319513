



import { defineComponent } from '@vue/composition-api';
import { format } from 'date-fns';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { useDownloadCsv } from 'src/composables/useDownloadCsv';
import { useTimetableMasters } from '../../composables/useTimetableMasters';
import { useTimetableActivityMasters } from '../../composables/useTimetableActivityMasters';
import { buildCsvData } from './buildCsvData';

export default defineComponent({
  components: {
    NormalButton,
  },
  setup() {
    const { timetableMasters } = useTimetableMasters();
    const { budgetGroupTimetableActivityMastersIncludingDisabled } = useTimetableActivityMasters();
    const { downloadCsv } = useDownloadCsv({
      prepare: () => ({
        fileName: `activity_mapping_${format(new Date(), 'yyyyMMddHHmmss')}.csv`,
        data: buildCsvData(timetableMasters.value, budgetGroupTimetableActivityMastersIncludingDisabled.value),
      }),
    });

    return {
      downloadCsv,
    };
  },
});
