import axios from 'axios'
import { StringOrNumber } from 'src/models/common'
import { getApiUrl } from 'src/apis/apiUtils';
import { CollectiveStaffShift, convertCollectiveStaffShiftResponse } from 'src/models/collectiveStaffShift';
import { CollectiveStaffShiftUpsertParams } from 'src/models/api/collectiveStaffShiftRequest';

function getResourceBaseUrl(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/collective_staff_shifts`)
}

export default {
  async upsert(params: CollectiveStaffShiftUpsertParams): Promise<CollectiveStaffShift> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.post(`${getResourceBaseUrl(workplaceId, budgetGroupId)}/upsert`, paramsRest)
    return convertCollectiveStaffShiftResponse(data)
  },
}
