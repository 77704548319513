

















import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { type ProgressHeader } from 'src/models/progressHeader'
import DoughnutChart from 'src/components/UIComponents/Charts/DoughnutChart'
import { orDefault } from 'src/util/filters'
import {
  getLatestProductivityRank,
  getLatestProductivityRate,
  getLatestProductivityRankColor,
} from 'src/util/progressHeaderHelpers'
import { formatRate } from '../../utils/filters'
import { getProductivityChartDataset } from './getProductivityChartDataset'

export default defineComponent({
  components: {
    DoughnutChart,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup(props) {
    const productivityRankColor = computed(() => getLatestProductivityRankColor(props.progressHeader))
    const dataset = computed(() => getProductivityChartDataset(props.progressHeader))
    const centerText = computed(() => {
      const rank = getLatestProductivityRank(props.progressHeader)
      const rate = `${orDefault(formatRate(getLatestProductivityRate(props.progressHeader)), '-')}%`
      return `${rank}\n${rate}`
    })
    const options = {
      cutoutPercentage: 60,
      rotation: Math.PI * 0.875,
      circumference: Math.PI * 1.25,
    }

    return {
      productivityRankColor,
      dataset,
      centerText,
      options,
    }
  },
})
