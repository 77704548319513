import {
  reactive,
  InjectionKey,
  toRefs,
  Ref,
} from '@vue/composition-api';
import { SHIFT_TOTAL_MODE } from 'src/consts';

type State = {
  isDisplayShiftTotalTable: boolean,
  shiftTotalMode: number,
  isStartEnd: boolean,
}

// TODO: (別PR)shiftTotalModeの型をつける
//       shiftTotalModeの名称を再考する
type PageDisplay = {
  changeDisplayShiftTotalTable: () => void,
  changeShiftTotalMode: (shiftTotalMode: number) => void,
  changeStartEndAndTime: (isStartEnd: boolean) => void,
  isDisplayShiftTotalTable: Ref<boolean>,
  shiftTotalMode: Ref<number>,
  isStartEnd: Ref<boolean>,
}

export const usePageDisplay = (): PageDisplay => {
  const state = reactive<State>({
    isDisplayShiftTotalTable: true,
    shiftTotalMode: SHIFT_TOTAL_MODE.MAN_HOUR,
    isStartEnd: true,
  });

  // 合計テーブルの表示切り替え
  const changeDisplayShiftTotalTable = (): void => {
    state.isDisplayShiftTotalTable = !state.isDisplayShiftTotalTable;
  }

  // 人数と人時と人時差分の切り替え
  const changeShiftTotalMode = (shiftTotalMode: number): void => {
    state.shiftTotalMode = shiftTotalMode;
  }

  // 開始終了と時間の切り替え
  const changeStartEndAndTime = (isStartEnd: boolean): void => {
    state.isStartEnd = isStartEnd;
  }

  return {
    ...toRefs(state),
    changeDisplayShiftTotalTable,
    changeShiftTotalMode,
    changeStartEndAndTime,
  }
}

export const PageDisplayStateKey: InjectionKey<PageDisplay> = Symbol('PageDisplay');
