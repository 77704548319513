












import { defineComponent, computed } from '@vue/composition-api'
import { ModalForm } from 'src/components/UIComponents/Form'
import { useDeleteTimetableMaster } from '../../composables/useDeleteTimetableMaster'

export default defineComponent({
  components: {
    ModalForm,
  },
  setup() {
    const {
      timetableMaster,
      showsDeleteConfirmationModal,
      hideDeleteConfirmationModal,
      deleteTimetableMaster,
    } = useDeleteTimetableMaster()

    return {
      timetableMasterName: computed(() => timetableMaster.value?.name),
      showsDeleteConfirmationModal,
      hideDeleteConfirmationModal,
      deleteTimetableMaster,
    }
  },
})
