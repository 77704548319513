import { ref, unref, readonly, watch, onBeforeUnmount, type Ref, type DeepReadonly } from '@vue/composition-api'
import { format } from 'date-fns'
import progressHeaderApi from 'src/apis/progressHeader'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type TimetableLabel } from 'src/models/timetableLabel'
import { type TimetableMaster } from 'src/models/timetableMaster'
import { convertProgressHeaderPerformanceBoardDetailIndexResponse, type ProgressHeader } from 'src/models/progressHeader'
import { createInjection } from 'src/util/createInjection'
import { createTimer } from 'src/util/createTimer'
import { useSearchConditions } from './useSearchConditions'

type InjectionValue = {
  progressHeaders: DeepReadonly<Ref<ProgressHeader[]>>
  canSearch: Ref<boolean>
  fetchProgressHeaders: () => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useProgressHeaders')

async function getProgressHeaders({
  workplaceId,
  dt,
  budgetGroup,
  timetableLabels,
  timetableMasters,
}: {
  workplaceId: number
  dt: Date
  budgetGroup: BudgetGroup | null
  timetableLabels: TimetableLabel[]
  timetableMasters: TimetableMaster[]
}): Promise<ProgressHeader[]> {
  const { data } = await progressHeaderApi.performanceBoardDetailIndex({
    workplace_id: workplaceId,
    dt: format(dt, 'yyyy-MM-dd'),
    budget_group_id: budgetGroup?.id ?? null,
    timetable_label_ids: timetableLabels.map(({ id }) => id),
    timetable_master_ids: timetableMasters.map(({ id }) => id),
  })
  return convertProgressHeaderPerformanceBoardDetailIndexResponse(data)
}

export function useProgressHeadersProvider(): void {
  const errorBoundary = useErrorBoundary()
  const progressHeaders = ref<ProgressHeader[]>([])
  const canSearch = ref(true)
  const shouldReload = ref(false)
  const {
    workplaceId,
    dt,
    budgetGroup,
    timetableLabels,
    timetableMasters,
  } = useSearchConditions()
  const fetchProgressHeaders = errorBoundary.wrap(
    async() => {
      try {
        canSearch.value = false
        shouldReload.value = false
        progressHeaders.value = await getProgressHeaders({
          workplaceId,
          dt: unref(dt),
          budgetGroup: unref(budgetGroup),
          timetableLabels: unref(timetableLabels),
          timetableMasters: unref(timetableMasters),
        })
        shouldReload.value = true
      } finally {
        canSearch.value = true
      }
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    }
  )

  const autoReloadTimer = createTimer(fetchProgressHeaders, 5 * 60 * 1000)

  watch([dt, budgetGroup, timetableLabels, timetableMasters], () => {
    shouldReload.value = false
  })

  watch([shouldReload], () => {
    if (shouldReload.value) {
      autoReloadTimer.start()
    } else {
      autoReloadTimer.stop()
    }
  })

  onBeforeUnmount(() => {
    autoReloadTimer.stop()
  })

  provide({
    progressHeaders: readonly(progressHeaders),
    canSearch,
    fetchProgressHeaders,
  })
}

export function useProgressHeaders(): InjectionValue {
  return inject()
}
