import { SetupContext } from '@vue/composition-api'
import masterApi from 'src/apis/master'
import { User } from 'src/models/user'
import { Master, Lov, LovEntryMap } from 'src/models/master'

function loadUser_(context: SetupContext): Promise<any> {
  return context.root.$store.dispatch('user/getMe')
}

function loadMasters_(userPromise: Promise<any>): void {
  const prms = masterApi.getAll()
    .then(({ data }) => {
      const now = new Date()
      const tmpLovArr = Object.entries(data.lovs)
        .map(([k, origLov]): [string, Lov] => {
          // 期限内のもののみ抽出したもの
          const valsInUse = origLov.vals.filter(e => {
            return new Date(e.start_use) <= now && now < new Date(e.end_use)
          })
          const map = origLov.vals.reduce<LovEntryMap>((acc, e) => {
            acc[e.key] = e; return acc
          }, {})
          const mapInUse = valsInUse.reduce<LovEntryMap>((acc, e) => {
            acc[e.key] = e; return acc
          }, {})
          return [k, { ...origLov, map, vals_inuse: valsInUse, map_inuse: mapInUse }]
        })

      window.master = {
        $promise: window.master.$promise,
        lovs: Object.fromEntries(tmpLovArr)
      }
      // wait for user info
      return userPromise
    })
    .then(() => {
      return window.master
    })
  window.master = { $promise: prms, lovs: {} }
}

export function loadUserAndMasters(context: SetupContext): void {
  const userPromise = loadUser_(context)
  loadMasters_(userPromise)
}

export async function ensureUserAndMasters(context: SetupContext | Vue): Promise<{ user: User, masters: Master}> {
  const root: Vue = 'root' in context ? context.root : context
  const promises = [
    root.$store.dispatch('user/getMe'),
    window.master.$promise,
  ]
  if (
    root.$route.params.workplaceId &&
    !root.$store.getters['workplace/isWorkplaceSet']
  ) {
    const prms = root.$store.dispatch(
      'workplace/fetchWorkplaceById',
      { workplaceId: root.$route.params.workplaceId },
    )
    promises.push(prms)
  }
  const [user, masters] = await Promise.all(promises)
  return { user, masters }
}
