
import Draggable, { MoveEvent } from 'vuedraggable';
import activityMasterApi from 'src/apis/activityMaster';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import budgetGroupApi from 'src/apis/workplace_masters/budget_group';
import ListSwitchPanel from '../ListSwitchPanel.vue';
import { setPageName } from 'src/hooks/displayPageNameHook';
import {
  computed,
  ComputedRef,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  SetupContext,
} from '@vue/composition-api';
import { vvGetError, vvGetErrorObj, vvHasError, vvReset, vvValidate } from 'src/util/vee_validate';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import { isExist } from 'src/util/isExist';
import MacroOperationSegmentMasterContent from './MacroOperationSegmentMaster/index.vue';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { MacroOperationMasterResponse } from 'src/models/api/macroOperationMasterResponse';
import { ActivityMaster, CreateCandidate, SearchParams, State, UpdateCandidate } from './types';
import { CreateOptParams, UpdateOptParams } from 'src/models/api/macroOperationMasterRequest';
import { MacroOperationMasterLogimeterActivityMaster } from 'src/models/api/macroOperationMasterLogimeterActivityMasterResponse';

const displayPageName = 'シフトグループ (月間ボード向け)';
type Operation = 'create' | 'update' | 'delete' | 'update_disp_order';
const msgVars = { create: '作成', update: '編集', delete: '削除', update_disp_order: '表示順変更' };
const errIds = { create: 'ERR00001', update: 'ERR00002', delete: 'ERR00003', update_disp_order: 'ERR00004' };
const ValidationTargetNames = ['macro_operation_master_name', 'macro_operation_master_productivity'];
function initialSaveCandidate(state?: State): CreateCandidate {
  return reactive({
    isNew: true,
    budget_group_id: state ? state.budgetGroups[0].id : 0,
    name: '',
    quantity: undefined,
    productivity: undefined,
    disp_color: state ? state.dispColorCandidates[0].key : '',
    is_enabled: true,
    activity_master_ids: [],
    is_segment_used: false,
    is_working_hours_save: true,
  });
}

function updateCandidate(item: MacroOperationMaster): UpdateCandidate {
  return reactive({
    isNew: false,
    ...item,
    quantity: isExist(item.quantity) ? `${item.quantity}` : undefined,
    productivity: isExist(item.productivity) ? `${item.productivity}` : undefined,
    activity_master_ids: item.macro_operation_master_logimeter_activity_masters.map(
      (activity) => activity.logimeter_activitiy_master_id,
    ),
  });
}

function setupState(): State {
  return reactive({
    list: [],
    saveCandidate: initialSaveCandidate(),
    deleteCandidate: null,
    showSaveModal: false,
    showDeleteModal: false,
    budgetGroups: [],
    dispColorCandidates: [],
    activitiesForSaveModal: [],
  });
}

function setupSearchParams(): SearchParams {
  return reactive({
    selectedBudgetGroupId: null,
    selectedMacroOperationMasterId: null,
    displayListDisabledItem: false,
  });
}

export default defineComponent({
  components: {
    MacroOperationSegmentMasterContent,
    Draggable,
    ListSwitchPanel,
  },
  setup(_props, context: SetupContext) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    const state = setupState();
    const searchParams = setupSearchParams();
    const userId: ComputedRef<number> = wrappedMapGetters(vueInstance.$store, 'user', ['id']).id;

    const workplaceId = vueInstance.$route.params.workplaceId;

    const hasList = computed(() => {
      return state.list.length > 0;
    });

    const hasError = computed(() => {
      return vvHasError(vueInstance);
    });

    function getValidationMaps(state: State) {
      return {
        macro_operation_master_name: { required: true, max: 30 },
        quantity: { numeric: true, max_value: 9999999999 },
        macro_operation_master_productivity: {
          required: !state.saveCandidate.is_segment_used,
          decimal: 1,
          max_value: 99999.9,
          min_value: 0,
        },
      };
    }

    const errorMsg = computed(() => {
      const error = vvGetErrorObj(vueInstance, '*');
      if (!isExist(error)) {
        return '';
      }
      return '入力に誤りがあります';
    });

    function getError(fieldName: string): string | null {
      return vvGetError(vueInstance, fieldName);
    }

    function clearErrors() {
      vvReset(vueInstance);
    }

    async function getList() {
      searchParams.selectedMacroOperationMasterId = null;
      const macroOperationMasters = await macroOperationMasterApi.index({
        workplaceId,
        budgetGroupId: searchParams.selectedBudgetGroupId!,
        params: {
          ...(searchParams.displayListDisabledItem ? null : { is_enabled: true }),
        },
      });
      state.list = macroOperationMasters.map((macroOperationMaster) => {
        return {
          ...macroOperationMaster,
        };
      });

      state.activitiesForSaveModal = await fetchActivityMasters(searchParams.selectedBudgetGroupId!);
      searchParams.selectedMacroOperationMasterId = state.list.length > 0 ? state.list[0].id : null;
    }

    async function fetchActivityMasters(budgetGroupId: number): Promise<ActivityMaster[]> {
      const activityMastersResponse = await activityMasterApi.index(workplaceId, budgetGroupId);
      if (!isExist(activityMastersResponse)) {
        return [];
      }

      return activityMastersResponse
        .filter((activityMaster) => activityMaster.is_editable)
        .reduce<ActivityMaster[]>((activityMasters, activityMaster) => {
          const label = `${activityMaster.budget_unit.name} ${activityMaster.name}`;
          return activityMasters.concat({
            ...activityMaster,
            label,
            key: activityMaster.id,
          });
        }, []);
    }

    async function openSaveModal(item?: MacroOperationMaster) {
      clearErrors();
      state.activitiesForSaveModal = await fetchActivityMasters(searchParams.selectedBudgetGroupId!);
      if (isExist(item)) {
        state.saveCandidate = updateCandidate(item);
      } else {
        state.saveCandidate = initialSaveCandidate(state);
        state.saveCandidate.budget_group_id = searchParams.selectedBudgetGroupId!;
        state.saveCandidate.isNew = true;
      }
      state.showSaveModal = true;
    }

    function closeSaveModal() {
      state.saveCandidate = initialSaveCandidate(state);
      state.showSaveModal = false;
      clearErrors();
    }

    async function _saveItem() {
      const isValid = await Promise.all(ValidationTargetNames.map((name) => vvValidate(vueInstance, name)));
      if (isValid.some((value) => !value)) {
        return;
      }
      if (!isValid) {
        return;
      }
      const { productivity } = state.saveCandidate;

      const params: CreateOptParams | UpdateOptParams = {
        ...state.saveCandidate,
        productivity: productivity ?? null,
        is_quantity_save: state.saveCandidate.activity_master_ids.length > 0,
      };

      if (params.isNew) {
        await doCreate(params);
      } else {
        await doUpdate(params);
      }
    }

    function onSaveItemError(err: any, Operation: Operation): void {
      const errStatus = err.response.status;
      const errRes = err.response.data || {};
      if (errStatus === 400 && errRes.reason === 'dup_name') {
        const msg = 'そのシフトグループ名は既に使用されています。';
        notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
      } else {
        notifyCommonError(Operation, err as Error);
      }
    }

    async function doCreate(createCandidate: CreateOptParams): Promise<void> {
      const Operation: Operation = 'create';

      try {
        await macroOperationMasterApi.create({
          workplaceId,
          data: createCandidate,
        });
        await getList();
        closeSaveModal();
        notifySuccess1(vueInstance, `${displayPageName}を${msgVars[Operation]}しました`);
      } catch (err: any) {
        onSaveItemError(err, Operation);
      }
    }

    async function doUpdate(updateCandidate: UpdateOptParams): Promise<void> {
      const Operation: Operation = 'update';

      try {
        await macroOperationMasterApi.update({
          workplaceId,
          data: updateCandidate,
        });
        await getList();
        closeSaveModal();
        notifySuccess1(vueInstance, `${displayPageName}を${msgVars[Operation]}しました`);
      } catch (err: any) {
        onSaveItemError(err, Operation);
      }
    }

    function openDeleteModal(item: MacroOperationMaster) {
      state.deleteCandidate = item;
      state.showDeleteModal = true;
    }

    function closeDeleteModal() {
      state.deleteCandidate = null;
      state.showDeleteModal = false;
    }

    async function _deleteItem() {
      if (!state.deleteCandidate) {
        return;
      }
      const Operation = 'delete';
      try {
        const reqObj = {
          workplaceId,
          budgetGroupId: state.deleteCandidate.budget_group_id,
          itemId: state.deleteCandidate.id,
        };
        await macroOperationMasterApi[Operation](reqObj);
        await getList();
        closeDeleteModal();
        notifySuccess1(vueInstance, `${displayPageName}を${msgVars[Operation]}しました`);
      } catch (err: any) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 400 && errRes.reason === 'in_use') {
          const msg = 'すでに使われているマスタです。削除できません。' + '無効化をおすすめします。';
          notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
        } else {
          notifyCommonError(Operation, err as Error);
        }
      }
    }

    function isDraggable(e: MoveEvent<MacroOperationMasterResponse>) {
      const dragged = e.draggedContext.element;
      const target = e.relatedContext.element;
      return dragged.budget_group_id === target.budget_group_id;
    }

    async function _updateDispOrder() {
      try {
        state.list.forEach((e, i) => {
          e.disp_order = i + 1;
        });
        const reqObj = {
          workplaceId,
          budgetGroupId: searchParams.selectedBudgetGroupId!,
          items: state.list,
        };
        await macroOperationMasterApi.bulkUpdateDispOrder(reqObj);
        await getList();
      } catch (err) {
        notifyCommonError('update_disp_order', err as Error);
      }
    }

    function notifyCommonError(Operation: Operation, err?: Error) {
      const msg =
        `${displayPageName}の${msgVars[Operation]}に失敗しました。` +
        '管理者に連絡してください。' +
        `(ERR: ${displayPageName} ${errIds[Operation]}, user_id:${userId.value})`;
      notifyError1(vueInstance, msg, { err });
    }
    function findActivityMaster(logimeter_activitiy_master_id: number): ActivityMaster | null {
      return state.activitiesForSaveModal.find((activity) => activity.id === logimeter_activitiy_master_id) ?? null;
    }
    function selectItem(id: number) {
      searchParams.selectedMacroOperationMasterId = id;
    }
    function isSelectedItem(item: MacroOperationMaster) {
      if (searchParams.selectedMacroOperationMasterId !== item.id) {
        return false;
      }
      return true;
    }

    function onChangeIsSegmentUsed() {
      state.saveCandidate.activity_master_ids = [];
      state.saveCandidate.productivity = undefined;
      vvReset(vueInstance, 'macro_operation_master_productivity');
    }

    const getActivityMasterLabel = (items: MacroOperationMasterLogimeterActivityMaster[]): string => {
      return items
        .map(
          (item: MacroOperationMasterLogimeterActivityMaster) =>
            findActivityMaster(item.logimeter_activitiy_master_id)?.label ?? item.logimeter_activitiy_master_id,
        )
        .join(', ');
    };

    onMounted(async () => {
      setPageName(vueInstance, displayPageName);
      state.budgetGroups = (
        await budgetGroupApi.index({
          workplaceId,
          params: { is_enabled: true },
        })
      ).data;
      searchParams.selectedBudgetGroupId = state.budgetGroups[0]?.id;

      await ensureUserAndMasters(context);
      state.dispColorCandidates = window.master.lovs.color_set.vals.filter((e) => e.group === 'dark');
      await getList();
    });

    const toFixed = (num: number | null | undefined, digits: number): string => {
      if (!isExist(num)) {
        return '';
      }
      return num.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      });
    };
    const gatedFuncGenerator = getGatedFuncGenerator();
    return {
      displayPageName: displayPageName,
      searchParams,
      state,
      hasList,
      hasError,
      errorMsg,
      validations: computed(() => getValidationMaps(state)),
      // methods
      getActivityMasterLabel,
      onChangeIsSegmentUsed,
      toFixed,
      getError,
      getList,
      openSaveModal,
      closeSaveModal,
      isDraggable,
      openDeleteModal,
      closeDeleteModal,
      findActivityMaster,
      selectItem,
      isSelectedItem,
      saveItem: gatedFuncGenerator.makeAsyncFuncGated(_saveItem),
      deleteItem: gatedFuncGenerator.makeAsyncFuncGated(_deleteItem),
      updateDispOrder: gatedFuncGenerator.makeAsyncFuncGated(_updateDispOrder),
      dragOptions: {
        handle: '.grabbable',
        animation: 300,
      },
    };
  },
});
