import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from 'src/apis/apiUtils';
import { StringOrNumber } from 'src/models/common'
import { ActivityMasterResponse } from 'src/models/api/activityMasterResponse';

function getResourceBaseUrl(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/activity_masters`)
}

export default {
  async index(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): Promise<ActivityMasterResponse[]> {
    const { data } = await axios.get<ActivityMasterResponse[]>(`${getResourceBaseUrl(workplaceId, budgetGroupId)}`)
    return data
  },
}
