









import { defineComponent, type PropType } from '@vue/composition-api'
import { formatNumber } from '../../utils/filters'

export default defineComponent({
  filters: {
    formatNumber,
  },
  props: {
    value: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
})
