import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import {
  IndexOptParams,
  CreateOptParams,
  UpdateOptParams,
  BulkUpdateOptParams,
  GetSubWorkplaceListOptParams
} from 'src/models/api/macroOperationMasterRequest'
import { convertMacroOperationMasterResponse, MacroOperationMaster } from 'src/models/macroOperationMaster'
import { MacroOperationMasterResponse } from 'src/models/api/macroOperationMasterResponse'

export default {
  async index({ workplaceId, budgetGroupId, params }: { workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, params: IndexOptParams }): Promise<MacroOperationMaster[]> {
    const { data } = await axios.get<MacroOperationMasterResponse[]>(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/macro_operation_masters`),
      { params: params || {} }
    )
    return data.map(convertMacroOperationMasterResponse)
  },
  async create({ workplaceId, data }: { workplaceId: StringOrNumber, data: CreateOptParams }): Promise<MacroOperationMaster> {
    const { data: reqData } = await axios.post<MacroOperationMasterResponse>(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${data.budget_group_id}/macro_operation_masters`),
      data
    )
    return convertMacroOperationMasterResponse(reqData)
  },
  async update({ workplaceId, data }: { workplaceId: StringOrNumber, data: UpdateOptParams }): Promise<MacroOperationMaster> {
    const { data: reqData } = await axios.put<MacroOperationMasterResponse>(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${data.budget_group_id}/macro_operation_masters/${data.id}`),
      data
    )
    return convertMacroOperationMasterResponse(reqData)
  },
  async delete({ workplaceId, budgetGroupId, itemId }: { workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, itemId: StringOrNumber }): Promise<MacroOperationMaster> {
    const { data } = await axios.delete<MacroOperationMasterResponse>(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/macro_operation_masters/${itemId}`)
    )
    return convertMacroOperationMasterResponse(data)
  },
  async bulkUpdateDispOrder({ workplaceId, budgetGroupId, items }: { workplaceId: StringOrNumber, budgetGroupId: StringOrNumber, items: BulkUpdateOptParams[] }): Promise<MacroOperationMaster[]> {
    const { data } = await axios.post<MacroOperationMasterResponse[]>(
      utils.getApiUrl(`/masters/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/macro_operation_masters/bulk_update_disp_order`),
      { items: items }
    )
    return data.map(convertMacroOperationMasterResponse)
  },
  async getSubWorkplaceList({ workplaceId, params }: { workplaceId: StringOrNumber, params: GetSubWorkplaceListOptParams }): Promise<MacroOperationMaster[]> {
    const { data } = await axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/macro_operation_masters/sub_workplace_list`), {
      params: params || {}
    })
    return data.map(convertMacroOperationMasterResponse)
  },
}
