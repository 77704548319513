


















import { defineComponent, SetupContext, reactive, ref } from '@vue/composition-api'
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import { createSidebarStore, SidebarLink, SidebarStore } from 'src/components/UIComponents/Sidebar/createSidebarStore'
import { resolvedSidebarLinksMap, unresolvedSidebarLinksMap } from './sidebarLinkGroups'
import { requestTimeout } from 'src/util/request_animation_frame'
import { Vue } from 'vue/types/vue'
import { ensureUserAndMasters } from 'src/hooks/masterHook'
import { getSidebarLinkGroupNameFromRoute, resolveLinkGroupData } from 'src/hooks/routeHook'
import { Route } from 'vue-router'
import { userHasWorkplaceRoleGteLeaderSync } from 'src/hooks/appRoleHook'

function deepCopy<T>(x: T): T {
  return JSON.parse(JSON.stringify(x))
}

interface State {
  sidebarStore: SidebarStore
  appTitle: string
  appLogo: string
  homeRouteInfo: {
    name: string
  },
}

function setupState(): State {
  const sidebarStore = createSidebarStore()
  const initialSbLinkGroupName = 'top'
  sidebarStore.sidebarLinks = deepCopy(resolvedSidebarLinksMap[initialSbLinkGroupName])
  return reactive({
    sidebarStore: sidebarStore,
    appTitle: 'Logiboard',
    appLogo: '/static/img/logiboard/logo.png',
    homeRouteInfo: {
      name: 'WorkplaceSelect',
    },
  })
}

export default defineComponent({
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  setup(_props, context: SetupContext) {
    const root = context.root as Vue
    const state = setupState()
    const refMainWrapper = ref<HTMLElement>()

    const updateSidebarItems = (to: Route, _from?: Route) => {
      state.sidebarStore.sidebarLinks = []
      ensureUserAndMasters(context).then(() => {
        // サイドバー表示切替
        const linkGroupName = getSidebarLinkGroupNameFromRoute(to)
        let sidebarLinks: SidebarLink[] = resolvedSidebarLinksMap[linkGroupName]
        if (!sidebarLinks) {
          sidebarLinks = resolveLinkGroupData(deepCopy(unresolvedSidebarLinksMap[linkGroupName]), to)
        }
        sidebarLinks = sidebarLinks.filter(ent => {
          if (ent.requireWorkplaceRoleGteLeader) {
            return to.params.workplaceId && userHasWorkplaceRoleGteLeaderSync(
              context.root, parseInt(to.params.workplaceId))
          }
          return true
        })
        sidebarLinks = sidebarLinks.filter(ent => {
          if (ent.requireWorkplaceIsWmsCsv) {
            return root.$store.getters['workplace/isWmsCsv']
          }
          return true
        })

        state.sidebarStore.sidebarLinks = sidebarLinks

        // スーパーアドミンだったらおまけをつける
        if (root.$store.getters['user/hasRoleSuperAdmin']) {
          state.sidebarStore.sidebarLinks.push(...resolvedSidebarLinksMap.superAdmin)
        }
      })
    }
    const scrollToTop = (_to: Route, _from: Route) => {
      // 250msはヒューリスティックな値なので、完璧ではない.
      // 前の画面がfadeoutした後且つ次の画面がfadeinしてくる前のタイミングを
      // うまく狙おうとした値.
      requestTimeout(() => {
        if (refMainWrapper.value) {
          refMainWrapper.value.scrollTop = 0
        }
      }, 250)
    }
    root.$router.afterEach(updateSidebarItems)
    root.$router.afterEach(scrollToTop)
    updateSidebarItems(root.$route)

    function toggleSidebar() {
      if (state.sidebarStore.showSidebar) {
        state.sidebarStore.displaySidebar(false)
      }
    }

    return {
      state,
      toggleSidebar,
      refMainWrapper,
    }
  },
})
