



































































import { defineComponent, ref, onMounted, type PropType } from '@vue/composition-api';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { timeInteger } from 'src/filters/datetime_filters';
import { type BudgetGroupPlanBoardMisc } from 'src/models/budgetGroupPlanBoardMisc';
import { type StaffShiftOverview } from 'src/models/staffShiftOverview';
import HeadcountBlockContainer from './HeadcountBlockContainer.vue';
import HeadcountBlock from './HeadcountBlock.vue';
import BudgetGroupPlanBoardMiscMemo from './BudgetGroupPlanBoardMiscMemo.vue';

export default defineComponent({
  components: {
    HeadcountBlockContainer,
    HeadcountBlock,
    BudgetGroupPlanBoardMiscMemo,
  },
  filters: {
    formatTime: (v: number) => timeInteger(v, 'HH : mm'),
    formatDate: (v: Date) => format(v, 'MM / dd'),
    formatWeekDay: (v: Date) => format(v, 'E', { locale: ja }),
  },
  props: {
    dates: {
      type: Array as PropType<Date[]>,
      required: true,
    },
    staffShiftOverview: {
      type: Object as PropType<StaffShiftOverview>,
      required: true,
    },
    budgetGroupPlanBoardMiscs: {
      type: Array as PropType<BudgetGroupPlanBoardMisc[]>,
      required: true,
    },
  },
  setup() {
    const tableContainer = ref<HTMLDivElement | null>(null);
    const hourlySlots = [...Array(48)].map((_, i) => i * 10000);

    onMounted(() => {
      if (tableContainer.value === null) {
        return;
      }

      const slot0 = tableContainer.value.querySelector<HTMLElement>('.StaffShiftOverviewTable-time[data-slot="0"]');
      const slot8 = tableContainer.value.querySelector<HTMLElement>('.StaffShiftOverviewTable-time[data-slot="80000"]');
      if (slot0 === null || slot8 === null) {
        return;
      }

      tableContainer.value.scrollLeft = slot8.offsetLeft - slot0.offsetLeft;
    });

    return {
      tableContainer,
      hourlySlots,
    };
  },
});
