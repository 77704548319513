import { DecimalString } from 'src/models/common'

export const addComma = (val: number | null | undefined): string => {
  if (val === null || val === undefined) { return '' }
  return val.toLocaleString()
}

export const currency = (val: number | string | null | undefined): string => {
  if (val === null || val === undefined) { return '' }
  if (typeof val === 'string') {
    return parseFloat(val).toLocaleString('ja-JP', { style: 'currency', currency: 'JPY', maximumFractionDigits: 1 })
  }
  return val.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY', maximumFractionDigits: 1 })
}

export const addCommaFraction = (val: number | null | undefined, digit = 4): string => {
  if (val === null || val === undefined) { return '' }
  return val.toLocaleString(undefined, { maximumFractionDigits: digit })
}

export const percent = (val: number | DecimalString | null | undefined, digit = 0, addPercentageMark = true): string => {
  const percentageMark = addPercentageMark ? '%' : ''
  if (val === null || val === undefined) { return '' + percentageMark }
  if (typeof val === 'string') val = parseFloat(val)
  return (val * 100).toFixed(digit) + percentageMark
}
