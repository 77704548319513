


























import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api';
import { FormItem } from 'src/components/UIComponents/Form';
import { addComma } from 'src/filters/number_filters';
import { isExist } from 'src/util/isExist';
import { formatCommaValue } from '../dailySimulationHelper';

export default defineComponent({
  components: {
    FormItem,
  },
  props: {
    value: {
      type: Number,
    },
    decimalPlaces: {
      type: Number,
    },
    rules: {
      type: [String, Object],
      default: undefined,
    },
  },
  setup(props, context) {
    const toString = (value?: number | null) => String(value == null ? '' : value);
    const valueRef = ref<string>(toString(props.value));
    const lastValue = ref<string>(toString(props.value));
    const isInvalid = ref<boolean>(false);

    watch(
      () => props.value,
      (newValue?: number) => {
        valueRef.value = toString(newValue);
        lastValue.value = toString(newValue);
      },
    );

    const input = ref<{ $el: HTMLInputElement } | null>(null);
    const state = reactive({
      isFocus: false,
    });

    const unfocusedValue = computed(() => {
      if (valueRef.value.length === 0) {
        return '';
      }
      const value = Number(valueRef.value);
      if (isNaN(value)) {
        return valueRef.value;
      }
      if (isInvalid.value) {
        return valueRef.value;
      }
      if (!isExist(props.decimalPlaces)) {
        return addComma(value);
      }
      return formatCommaValue(value, props.decimalPlaces);
    });

    const onFocus = () => {
      state.isFocus = true;
    };

    const onBlur = (event: FocusEvent) => {
      state.isFocus = false;
      if (event.target instanceof HTMLInputElement) {
        isInvalid.value = event.target.className.includes('is-invalid');
        if (!isInvalid.value && valueRef.value !== lastValue.value) {
          const returnValue = valueRef.value.length === 0 ? null : Number(valueRef.value);
          context.emit('input', returnValue);
          context.emit('update', returnValue);
        }
      }
    };

    return {
      valueRef,
      input,
      state,
      unfocusedValue,
      isInvalid,
      addComma,
      onFocus,
      onBlur,
    };
  },
});
