
import Vue from 'vue';
import { PropType, defineComponent, inject, onMounted, reactive, toRefs } from '@vue/composition-api';
import moment from 'src/util/moment-ja';
import { format } from 'date-fns';
import Draggable from 'vuedraggable';

import regularShiftApi from 'src/apis/staffShift';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';

import { SettingDisplayItemStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useSettingDisplayItem';
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift';
import { CommonStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useCommonState';
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal';
import { YearSummaryStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useYearSummary';

import { StaffWithShifts, Total7Days } from 'src/models/regularShift';
import { scrollLinkage } from 'src/views/Dashboard/Workplace/RegularShift/libs/scrollLinkage';
import { formatDateForRegularShift } from 'src/util/datetime';
import { PageDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/usePageDisplay';
import { SHIFT_PHASE } from 'src/consts/index';
import staffMonthlySummary from 'src/apis/staffMonthlySummary';
import { ManHoursStateKey } from '../../../hooks/useManHours';
import { useNotification } from 'src/composables/useNotification';

const DISPLAY_PERIOD = 7;
const MEMO_MAX_LENGTH = 255;

const { ACTUAL, SCHEDULED, PROVISIONAL } = SHIFT_PHASE;

type State = {
  hasCustomSkill1: boolean;
  hasCustomSkill2: boolean;
  hasCustomSkill3: boolean;
  lastMemo: string | null;
};

type MonthlySummary = {
  hours_total: number;
  hours_overtime: number;
  all_cost_sum: number;
};

export default defineComponent({
  name: 'shift-table-main',
  props: {
    total7Days: {
      type: Object as PropType<Total7Days>,
      required: true,
    },
  },
  components: {
    Draggable,
  },
  setup(props, context) {
    const root = context.root as Vue;
    const settingDisplayItemState = inject(SettingDisplayItemStateKey);
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const commonState = inject(CommonStateKey);
    const modalState = inject(ModalDisplayStateKey);
    const pageDisplayState = inject(PageDisplayStateKey);
    const yearSummaryState = inject(YearSummaryStateKey);
    const ManHoursState = inject(ManHoursStateKey);
    if (
      !settingDisplayItemState ||
      !staffWithShiftsState ||
      !commonState ||
      !modalState ||
      !pageDisplayState ||
      !yearSummaryState ||
      !ManHoursState
    ) {
      throw new Error('State error');
    }

    const {
      isDisplayStaffMemo,
      isDisplayStaffMemberCompany,
      isDisplaySkill,
      isDisplayYearTotal,
      isDisplayMonthTotal,
      isDisplay7DaysTotal,
    } = settingDisplayItemState;

    const { monthlySummaries } = yearSummaryState;
    const { notifyError, notifySuccess } = useNotification();

    const {
      clickStaffCheckBox,
      dateList,
      onClickShiftDisplayButton,
      clickShiftCellCheckBox,
      clickWeekDayCheckBox,
      storeTargetShiftForIndividualUpdate,
      showTargetStaff,
      showTargetYear,
      shouldShowDisabled,
      staffsWithShifts,
    } = staffWithShiftsState;

    const { baseDate, changeBaseDate, workplaceId, budgetGroup, displayMode } = commonState;

    const { openShiftRegisterModal, openYearSummaryModal } = modalState;

    const { isStartEnd } = pageDisplayState;

    const { loadManHoursByMacroOperation } = ManHoursState;

    const state = reactive<State>({
      hasCustomSkill1: false,
      hasCustomSkill2: false,
      hasCustomSkill3: false,
      lastMemo: '',
    });

    onMounted(() => {
      state.hasCustomSkill1 = !!root.$store.getters['workplace/workplaceExtension'].custom_skill_name1;
      state.hasCustomSkill2 = !!root.$store.getters['workplace/workplaceExtension'].custom_skill_name2;
      state.hasCustomSkill3 = !!root.$store.getters['workplace/workplaceExtension'].custom_skill_name3;
    });

    const changeShiftData = async (isPrev: boolean) => {
      const plusDateValue = isPrev ? -DISPLAY_PERIOD : DISPLAY_PERIOD;
      const targetDate = moment(baseDate.value).add(plusDateValue, 'd').toDate();

      changeBaseDate(targetDate);
      await onClickShiftDisplayButton(workplaceId.value, targetDate, budgetGroup.value.id);
      await loadManHoursByMacroOperation();
    };

    const clickShiftCell = (staffId: number, date: string) => {
      openShiftRegisterModal();
      storeTargetShiftForIndividualUpdate(staffId, date);
    };

    const todayStr = format(new Date(), 'yyyy-MM-dd');

    const clickYearSummary = async (staff: StaffWithShifts) => {
      showTargetStaff.value = staff;
      showTargetYear.value = Number(staff.shifts[0].date.split('-')[0]);
      openYearSummaryModal();

      const params = {
        workplace_id: workplaceId.value,
        budget_group_id: budgetGroup.value.id,
        year: Number(staff.shifts[0].date.split('-')[0]),
        staff_id: staff.staff_id,
        display_mode: displayMode.value,
      };
      const data = await staffMonthlySummary.index(params);
      monthlySummaries.value = data;
    };

    const getMonthlySummary = (staff: StaffWithShifts): MonthlySummary => {
      const monthlySummaryByMode = staff.staff_monthly_summary.find((monthlySummary) => {
        return monthlySummary.mode === displayMode.value;
      });

      if (monthlySummaryByMode === undefined) {
        return { hours_total: 0, hours_overtime: 0, all_cost_sum: 0 };
      }

      return {
        hours_total: Number(monthlySummaryByMode.hours_total),
        hours_overtime: Number(monthlySummaryByMode.hours_overtime),
        all_cost_sum: Number(monthlySummaryByMode.all_cost_sum),
      };
    };

    const isDraggable = () => {
      return true;
    };

    // MEMO: スタッフが多い場合は負荷がそれなりに大きいかもしれない
    const updateDispOrder = async () => {
      const staffExtensionIds: number[] = [];
      staffsWithShifts.value.forEach((e) => {
        staffExtensionIds.push(e.staff_extension.id);
      });
      const params = {
        staff_extension_ids: staffExtensionIds,
        workplace_id: workplaceId.value,
        budget_group_id: budgetGroup.value.id,
      };
      await regularShiftApi.bulkUpdateDispOrder(params);
    };

    const isMemoInvalid = (memo: string | null): boolean => {
      return (memo?.length ?? 0) > MEMO_MAX_LENGTH;
    };
    const handleFocusMemo = (memo: string | null) => {
      state.lastMemo = memo;
    };

    /**
     * メモの更新
     */
    const handleBlurMemo = async (staffWithShifts: StaffWithShifts): Promise<void> => {
      const staffExtension = staffWithShifts.staff_extension;
      if (isMemoInvalid(staffExtension.memo)) {
        return;
      }
      if (staffExtension.memo === state.lastMemo) {
        return;
      }
      try {
        await staffExtensionApi.update({
          workplaceId: workplaceId.value,
          data: {
            staff_id: staffWithShifts.staff_id,
            memo: staffExtension.memo,
          },
        });
        notifySuccess('メモを更新しました');
      } catch (err: any) {
        notifyError('メモの更新に失敗しました', { cause: err });
      }
    };

    return {
      isDisplayStaffMemo,
      isDisplayStaffMemberCompany,
      isDisplaySkill,
      isDisplayYearTotal,
      isDisplayMonthTotal,
      isDisplay7DaysTotal,
      scrollLinkage,
      clickStaffCheckBox,
      dateList,
      changeShiftData,
      clickShiftCell,
      clickShiftCellCheckBox,
      formatDateForRegularShift,
      isStartEnd,
      ACTUAL,
      PROVISIONAL,
      SCHEDULED,
      todayStr,
      clickYearSummary,
      ...toRefs(state),
      getMonthlySummary,
      shouldShowDisabled,
      clickWeekDayCheckBox,
      staffsWithShifts,
      isDraggable,
      dragOptions: {
        handle: '.grabbable',
        animation: 300,
      },
      updateDispOrder,
      workplaceId,
      budgetGroup,
      handleFocusMemo,
      handleBlurMemo,
      isMemoInvalid,
    };
  },
});
