
































import { defineComponent } from '@vue/composition-api';
import { orDefault } from 'src/util/filters';
import { formatFixedDecimal } from '../../utils/filters';

export default defineComponent({
  filters: {
    orDefault,
    formatFixedDecimal,
  },
  props: {
    remainingManHours: {
      type: Number,
      required: true,
    },
    scheduledManHours: {
      type: Number,
      required: true,
    },
    resultManHours: {
      type: Number,
      required: true,
    },
  },
});
