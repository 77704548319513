import { ref, onMounted, type Ref } from '@vue/composition-api';
import staffLabelApi from 'src/apis/workplace_masters/staff_label';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type StaffLabel } from 'src/models/staffLabel';
import { createInjection } from 'src/util/createInjection';

type Params = {
  workplaceId: number;
};

type InjectionValue = {
  staffLabels: Ref<StaffLabel[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffLabels');

export function useStaffLabelsProvider({ workplaceId }: Params): void {
  const errorBoundary = useErrorBoundary();
  const staffLabels = ref<StaffLabel[]>([]);
  const fetchStaffLabels = errorBoundary.wrap(
    async () => {
      const { data } = await staffLabelApi.index({
        workplaceId,
        params: { is_enabled: true },
      });
      staffLabels.value = data;
    },
    {
      fallbackMessage: 'スタッフラベルの取得に失敗しました',
    },
  );

  onMounted(fetchStaffLabels);

  provide({
    staffLabels,
  });
}

export function useStaffLabels(): InjectionValue {
  return inject();
}
