import { ref, readonly, type Ref, type DeepReadonly } from '@vue/composition-api'

type Modal = {
  showsModal: DeepReadonly<Ref<boolean>>
  showModal: () => void
  hideModal: () => void
}

export function useModal(): Modal {
  const showsModal = ref(false)
  const showModal = () => {
    showsModal.value = true
  }
  const hideModal = () => {
    showsModal.value = false
  }

  return {
    showsModal: readonly(showsModal),
    showModal,
    hideModal,
  }
}
