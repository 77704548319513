









































import Vue from 'vue';
import { defineComponent, reactive, SetupContext, PropType, computed } from '@vue/composition-api';
import { SEARCH_DATE_RANGE_MAX } from 'src/consts';
import { notifyError1 } from 'src/hooks/notificationHook';
import * as DateFns from 'date-fns';

interface DateOptionType {
  key: string;
  label: string;
}

type Period =
  | 'today'
  | 'this_week'
  | 'this_month'
  | 'yesterday'
  | 'last_week'
  | 'last_month'
  | 'tomorrow'
  | 'next_week'
  | 'next_month';

type DatePickerOptions = {
  disabledDate?: (date: Date) => boolean;
};

interface State {
  dateStart: Date;
  dateEnd: Date;
  minDate: Date;
  maxDate: Date;
  datePickerOptions: DatePickerOptions;
  dateOptionCandidates: DateOptionType[];
  selectedPeriod: Period | null;
  displayDateOption: boolean;
}

export type DateRange = {
  startDate: Date;
  endDate: Date;
};

type PeriodRangeMap = Record<Period, DateRange>;

const buildPeriodRangeMap = (today: Date): PeriodRangeMap => {
  const startOfWeek = DateFns.startOfISOWeek(today);
  const endOfWeek = DateFns.endOfISOWeek(today);
  return {
    today: { startDate: today, endDate: today },
    this_week: { startDate: startOfWeek, endDate: endOfWeek },
    this_month: { startDate: DateFns.startOfMonth(today), endDate: DateFns.endOfMonth(today) },
    yesterday: { startDate: DateFns.subDays(today, 1), endDate: DateFns.subDays(today, 1) },
    last_week: { startDate: DateFns.subWeeks(startOfWeek, 1), endDate: DateFns.subWeeks(endOfWeek, 1) },
    last_month: {
      startDate: DateFns.startOfMonth(DateFns.subMonths(today, 1)),
      endDate: DateFns.endOfMonth(DateFns.subMonths(today, 1)),
    },
    tomorrow: { startDate: DateFns.addDays(today, 1), endDate: DateFns.addDays(today, 1) },
    next_week: { startDate: DateFns.addWeeks(startOfWeek, 1), endDate: DateFns.addWeeks(endOfWeek, 1) },
    next_month: {
      startDate: DateFns.startOfMonth(DateFns.addMonths(today, 1)),
      endDate: DateFns.endOfMonth(DateFns.addMonths(today, 1)),
    },
  };
};

export default defineComponent({
  props: {
    dateStart: {
      type: Date,
      required: false,
    },
    dateEnd: {
      type: Date,
      required: false,
    },
    displayDateOption: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    dateRangeMax: {
      type: Number,
      default: SEARCH_DATE_RANGE_MAX,
    },
    excludeFuture: {
      type: Boolean,
      required: false,
      default: false,
    },
    availableOldestDate: {
      type: Date,
      required: false,
    },
  },
  setup(props, context: SetupContext) {
    const root = context.root as Vue;
    const today = DateFns.startOfToday();
    const state: State = reactive({
      dateStart: props.dateStart ?? today,
      dateEnd: props.dateEnd ?? today,
      dateOptionCandidates: [
        { key: 'today', label: '今日' },
        { key: 'this_week', label: '今週' },
        { key: 'this_month', label: '今月' },
        { key: 'yesterday', label: '昨日' },
        { key: 'last_week', label: '先週' },
        { key: 'last_month', label: '先月' },
        { key: 'tomorrow', label: '明日' },
        { key: 'next_week', label: '来週' },
        { key: 'next_month', label: '来月' },
      ],
      minDate: computed(() => props.availableOldestDate ?? new Date(0, 1, 1)),
      maxDate: computed(() => (props.excludeFuture ? DateFns.endOfToday() : new Date(9999, 12, 31))),
      datePickerOptions: computed(() => {
        return { disabledDate: (date: Date) => date < state.minDate || date > state.maxDate };
      }),
      selectedPeriod: null,
      displayDateOption: props.displayDateOption,
    });

    function onDateStartChange() {
      state.selectedPeriod = null;
      if (state.dateEnd < state.dateStart) {
        notifyError1(root, '開始日を終了日よりも前の日に指定してください');
      } else if (isDateRangeMaxExceeded()) {
        // if date range exceeds the specified range,
        // move the dateEnd to fit the range
        state.dateEnd = DateFns.addDays(state.dateStart, props.dateRangeMax);
        syncDateEnd();
      }
      syncDateStart();
      syncDate();
    }

    function onDateEndChange() {
      state.selectedPeriod = null;
      if (state.dateEnd < state.dateStart) {
        notifyError1(root, '開始日を終了日よりも前の日に指定してください');
      } else if (isDateRangeMaxExceeded()) {
        // if date range exceeds the specified range,
        // move the dateStart to fit the range
        state.dateStart = DateFns.subDays(state.dateEnd, props.dateRangeMax);
        syncDateStart();
      }
      syncDateEnd();
      syncDate();
    }

    function isDateRangeMaxExceeded() {
      return DateFns.differenceInDays(state.dateStart, state.dateEnd) >= props.dateRangeMax;
    }

    function syncDateStart() {
      context.emit('on-date-start-change', DateFns.format(state.dateStart, 'yyyy-MM-dd'));
    }

    function syncDateEnd() {
      context.emit('on-date-end-change', DateFns.format(state.dateEnd, 'yyyy-MM-dd'));
    }

    function syncDate() {
      context.emit(
        'on-date-change',
        DateFns.format(state.dateStart, 'yyyy-MM-dd'),
        DateFns.format(state.dateEnd, 'yyyy-MM-dd'),
      );
    }
    const periodRangeMap = buildPeriodRangeMap(today);

    function onDateOptionChange() {
      if (state.selectedPeriod === null) {
        return;
      }
      const periodRange = periodRangeMap[state.selectedPeriod];

      state.dateStart = DateFns.max([periodRange.startDate, state.minDate]);
      // sync to parent component
      syncDateStart();
      syncDateEnd();
      syncDate();
    }

    return {
      state,
      onDateStartChange,
      onDateEndChange,
      onDateOptionChange,
      syncDateStart,
      syncDateEnd,
    };
  },
});
