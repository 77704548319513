








import { defineComponent, type SetupContext, type PropType } from '@vue/composition-api';
import { DatePicker as ElDatePicker } from 'element-ui';
import { DatePickerType, DatePickerOptions } from 'element-ui/types/date-picker';
import { ElementUIComponentSize, ElementUIHorizontalAlignment } from 'element-ui/types/component';

export default defineComponent({
  name: 'DatePicker',
  components: {
    ElDatePicker,
  },
  props: {
    value: {
      type: Date,
      required: true,
    },
    readonly: Boolean,
    disabled: Boolean,
    size: String as PropType<ElementUIComponentSize>,
    editable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    placeholder: String,
    startPlaceholder: String,
    endPlaceholder: String,
    type: {
      type: String as PropType<DatePickerType>,
      default: 'date',
    },
    format: {
      type: String,
      default: 'yyyy/MM/dd',
    },
    align: String as PropType<ElementUIHorizontalAlignment>,
    popperClass: String,
    pickerOptions: Object as PropType<DatePickerOptions>,
    rangeSeparator: String,
    defaultValue: Date,
    valueFormat: String,
    name: String,
  },
  setup(props, context: SetupContext) {
    const handleInput = (value: Date | null) => {
      context.emit('input', value);
    };
    const handleChange = (value?: Date | null) => {
      context.emit('change', value);
    };

    return {
      props,
      handleInput,
      handleChange,
    };
  },
});
