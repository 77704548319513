
import {
  defineComponent,
  reactive,
  SetupContext,
  onMounted,
  getCurrentInstance,
  computed, ComputedRef,
} from '@vue/composition-api';
import { wrappedMapGetters } from 'src/hooks/storeHook';
import staffAgencyExtensionApi from 'src/apis/workplace_masters/staff_agency_extension';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { getGatedFuncGenerator } from 'src/util/timingControlUtil';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';
import { StaffAgency } from 'src/models/staffAgency';
import { StaffAgencyExtension } from 'src/models/staffAgencyExtension';
import { UpdateOptParams } from 'src/models/api/staffAgencyExtensionRequest';

import Axios from 'axios';

const displayPageName = 'スタッフ所属会社 ロジボード向け';

interface State {
  staffAgencies: StaffAgency[],
  saveStaffAgencyExtensionCandidate: Partial<StaffAgencyExtension>,
  showStaffAgencyModal: boolean,
}

function setupState(): State {
  return reactive({
    staffAgencies: [],
    saveStaffAgencyExtensionCandidate: {},
    showStaffAgencyModal: false
  });
}

export default defineComponent({
  name: 'staff-agency-extension',
  setup(_props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    const state = setupState();

    const userId: ComputedRef<number> = wrappedMapGetters(
      vueInstance.$store,
      'user',
      ['id']
    ).id;
    const workplaceId = computed(() => {
      return vueInstance.$route.params.workplaceId;
    });

    // リスト取得
    async function getList(): Promise<void> {
      // 管理グループ取得
      state.staffAgencies = (await staffAgencyExtensionApi.staff_agencies({
        workplaceId: workplaceId.value
      }))
    }

    // 管理グループモーダルopen
    function openStaffAgencySaveModal(item: StaffAgencyExtension): void {
      state.saveStaffAgencyExtensionCandidate = { ...item };
      state.showStaffAgencyModal = true;
    }

    // 管理グループモーダルclose
    function closeStaffAgencySaveModal(): void {
      state.saveStaffAgencyExtensionCandidate = {};
      state.showStaffAgencyModal = false;
    }

    // 管理グループ保存
    async function _saveStaffAgency(): Promise<void> {
      if (!state.saveStaffAgencyExtensionCandidate) { return; }

      const data: UpdateOptParams = {
        staff_agency_id: state.saveStaffAgencyExtensionCandidate.staff_agency_id!,
        is_dispatch_agency: state.saveStaffAgencyExtensionCandidate.is_dispatch_agency!
      }
      try {
        await staffAgencyExtensionApi.update({ workplaceId: workplaceId.value, data: data });
        await getList();
        closeStaffAgencySaveModal();
        notifySuccess1(vueInstance, `${displayPageName}を更新しました`);
      } catch (err) {
        if (Axios.isAxiosError(err)) {
          const errStatus = err.response!.status;
          if (Axios.isAxiosError(err) && errStatus >= 400 && errStatus < 500) {
            const errRes = err.response!.data || {};
            const msg = Array.isArray(errRes) ? errRes[0] : Object.values(errRes)[0];
            notifyError1(vueInstance, msg, { timeout: 5 * 1000 });
          } else {
            const msg = `${displayPageName}の更新に失敗しました。` +
              '管理者に連絡してください。' +
              `(ERR: ${displayPageName} ERR00002, user_id:${userId.value})`;
            notifyError1(vueInstance, msg, { err });
          }
        }
      }
    }

    onMounted(async() => {
      setPageName(vueInstance, displayPageName);
      await getList();
    })

    const gatedFuncGenerator = getGatedFuncGenerator();
    return {
      displayPageName,
      state,
      // computed
      workplaceId,
      openStaffAgencySaveModal,
      closeStaffAgencySaveModal,
      saveStaffAgency: gatedFuncGenerator.makeAsyncFuncGated(_saveStaffAgency),
      getList,
    }
  }
});
