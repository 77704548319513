import { ref, onMounted, type Ref, computed } from '@vue/composition-api';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { createInjection } from 'src/util/createInjection';

type OptionParams = {
  budget_group_id?: number;
  includes_disabled?: boolean;
  use_in_plan_board?: boolean;
  use_in_review_phase?: boolean;
  includes_resting?: boolean;
};

type Params = {
  workplaceId: number;
  options?: OptionParams;
};

export type TimetableMasterMap = Record<number, TimetableMaster>;

type InjectionValue = {
  timetableMasters: Ref<TimetableMaster[]>;
  timetableMasterMap: Ref<TimetableMasterMap>;
};

const { provide, inject } = createInjection<InjectionValue>('useTimetableMasters');

export function useTimetableMastersProvider({ workplaceId, options = {} }: Params): void {
  const errorBoundary = useErrorBoundary();
  const timetableMasters = ref<TimetableMaster[]>([]);
  const fetchTimetableMasters = errorBoundary.wrap(
    async () => {
      timetableMasters.value = await timetableMasterApi.index({
        workplaceId,
        params: {
          ...options,
          // includes_disabled が true の場合は、無効なものも含ませるため is_enabled の値を undefined にする。（false にすると無効なものしかヒットしないため）
          is_enabled: options.includes_disabled ?? false ? undefined : true,
        },
      });
    },
    {
      fallbackMessage: '工程マスタの取得に失敗しました',
    },
  );

  const timetableMasterMap = computed(() =>
    timetableMasters.value.reduce<TimetableMasterMap>((map, master) => {
      map[master.id] = master;
      return map;
    }, {}),
  );

  onMounted(fetchTimetableMasters);

  provide({
    timetableMasters,
    timetableMasterMap,
  });
}

export function useTimetableMasters(): InjectionValue {
  return inject();
}
