import axios from 'axios'
import { StringOrNumber } from 'src/models/common'
import { getApiUrl } from 'src/apis/apiUtils';
import { TimetableTrendIndexParams, ProgressHeaderUpdateParams } from 'src/models/api/timetableTrendRequest';
import { TimetableTrendIndexResponse } from 'src/models/api/timetableTrendResponse';
import { TimetableTrendIndexResult, convertTimetableTrendIndexResult } from 'src/models/timetableTrend';
import { ProgressHeader, convertProgressHeaderResponse } from 'src/models/progressHeader';

function getResourceBaseUrl1(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/timetable_trends`)
}

export default {
  async timetableTrendIndex(params: TimetableTrendIndexParams): Promise<TimetableTrendIndexResult> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.get<TimetableTrendIndexResponse>(`${getResourceBaseUrl1(workplaceId, budgetGroupId)}`, {
      params: paramsRest,
    })
    return convertTimetableTrendIndexResult(data)
  },
  async upsert(params: ProgressHeaderUpdateParams): Promise<ProgressHeader> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params
    const { data } = await axios.post(`${getResourceBaseUrl1(workplaceId, budgetGroupId)}/upsert`, paramsRest)
    return convertProgressHeaderResponse(data)
  },
}
