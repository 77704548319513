import { type Ref } from '@vue/composition-api';
import staffShiftApi from 'src/apis/staffShift';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { type BudgetGroup } from 'src/models/budgetGroup';

type Params = {
  budgetGroup: Ref<BudgetGroup>;
  dt: Ref<Date>;
};

type UploadShiftPatternCsv = {
  uploadShiftPatternCsv: (file: File) => Promise<boolean>;
};

export function useUploadShiftPatternCsv({ budgetGroup, dt }: Params): UploadShiftPatternCsv {
  const errorBoundary = useErrorBoundary();
  const { notifySuccess } = useNotification();
  const uploadShiftPatternCsv = errorBoundary.wrap(async (csv: File) => {
    await staffShiftApi.importShiftPatternCsv({
      workplace_id: budgetGroup.value.workplace_id,
      budget_group_id: budgetGroup.value.id,
      dt: dt.value,
      csv,
    });
    notifySuccess('シフトパターンCSVから登録を開始しました');
  });

  return {
    uploadShiftPatternCsv,
  };
}
