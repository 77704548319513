import { format } from 'date-fns';
import { unpackTimeIntegerToString } from 'src/util/datetime';
import type { TimeInteger } from 'src/models/common';
import type { StaffWorkSchedule } from 'src/models/staffWorkSchedule';

export type WeekKey = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type WeekName = '月' | '火' | '水' | '木' | '金' | '土' | '日';
export type WeekValue = 1 | 2 | 3 | 4 | 5 | 6 | 0;
export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const SUNDAY = 0;

export type Week = { key: WeekKey; name: WeekName; value: number }[];

export type LabelName =
  | 'work_start_time'
  | 'work_end_time'
  | 'break1_start_time'
  | 'break1_end_time'
  | 'break2_start_time'
  | 'break2_end_time'
  | 'duty1_start_time'
  | 'duty1_end_time'
  | 'duty2_start_time'
  | 'duty2_end_time';

export type StaffWorkScheduleSubset = Pick<
  StaffWorkSchedule,
  | 'day_of_week'
  | 'work_start_time'
  | 'work_end_time'
  | 'break1_start_time'
  | 'break1_end_time'
  | 'break2_start_time'
  | 'break2_end_time'
  | 'duty1_macro_operation_master_id'
  | 'duty1_start_time'
  | 'duty1_end_time'
  | 'duty2_macro_operation_master_id'
  | 'duty2_start_time'
  | 'duty2_end_time'
>;

export interface StaffWorkScheduleFormValues {
  day_of_week: number;
  work_start_time_hour: string | null;
  work_start_time_min: string | null;
  work_end_time_hour: string | null;
  work_end_time_min: string | null;
  break1_start_time_hour: string | null;
  break1_start_time_min: string | null;
  break1_end_time_hour: string | null;
  break1_end_time_min: string | null;
  break2_start_time_hour: string | null;
  break2_start_time_min: string | null;
  break2_end_time_hour: string | null;
  break2_end_time_min: string | null;
  duty1_start_time_hour: string | null;
  duty1_start_time_min: string | null;
  duty1_end_time_hour: string | null;
  duty1_end_time_min: string | null;
  duty2_start_time_hour: string | null;
  duty2_start_time_min: string | null;
  duty2_end_time_hour: string | null;
  duty2_end_time_min: string | null;
  duty1_macro_operation_master_id: number | null;
  duty2_macro_operation_master_id: number | null;
}

// StaffWorkScheduleFormValuesへ変換
export const convertToStaffWorkScheduleFormValues = (
  staffWorkSchedules: StaffWorkScheduleSubset,
): StaffWorkScheduleFormValues => {
  return {
    day_of_week: staffWorkSchedules.day_of_week,
    work_start_time_hour: timeIntegerToString(staffWorkSchedules.work_start_time)[0],
    work_start_time_min: timeIntegerToString(staffWorkSchedules.work_start_time)[1],
    work_end_time_hour: timeIntegerToString(staffWorkSchedules.work_end_time)[0],
    work_end_time_min: timeIntegerToString(staffWorkSchedules.work_end_time)[1],
    break1_start_time_hour: timeIntegerToString(staffWorkSchedules.break1_start_time)[0],
    break1_start_time_min: timeIntegerToString(staffWorkSchedules.break1_start_time)[1],
    break1_end_time_hour: timeIntegerToString(staffWorkSchedules.break1_end_time)[0],
    break1_end_time_min: timeIntegerToString(staffWorkSchedules.break1_end_time)[1],
    break2_start_time_hour: timeIntegerToString(staffWorkSchedules.break2_start_time)[0],
    break2_start_time_min: timeIntegerToString(staffWorkSchedules.break2_start_time)[1],
    break2_end_time_hour: timeIntegerToString(staffWorkSchedules.break2_end_time)[0],
    break2_end_time_min: timeIntegerToString(staffWorkSchedules.break2_end_time)[1],
    duty1_start_time_hour: timeIntegerToString(staffWorkSchedules.duty1_start_time)[0],
    duty1_start_time_min: timeIntegerToString(staffWorkSchedules.duty1_start_time)[1],
    duty1_end_time_hour: timeIntegerToString(staffWorkSchedules.duty1_end_time)[0],
    duty1_end_time_min: timeIntegerToString(staffWorkSchedules.duty1_end_time)[1],
    duty2_start_time_hour: timeIntegerToString(staffWorkSchedules.duty2_start_time)[0],
    duty2_start_time_min: timeIntegerToString(staffWorkSchedules.duty2_start_time)[1],
    duty2_end_time_hour: timeIntegerToString(staffWorkSchedules.duty2_end_time)[0],
    duty2_end_time_min: timeIntegerToString(staffWorkSchedules.duty2_end_time)[1],
    duty1_macro_operation_master_id: staffWorkSchedules.duty1_macro_operation_master_id,
    duty2_macro_operation_master_id: staffWorkSchedules.duty2_macro_operation_master_id,
  };
};

export const formatDate = (date: Date | null): string | null => {
  if (!date) {
    return null;
  }
  return format(date, 'yyyy/MM/dd');
};

// 0表示ではなく空表示になるようにwrap
export const timeIntegerToString = (timeInteger: TimeInteger | null) => {
  if (timeInteger === null) {
    return ['', '', ''];
  }
  return unpackTimeIntegerToString(timeInteger);
};
// 曜日ごとのスケジュールを検索
export function findDayOfWeekSchedule(
  workSchedules: StaffWorkSchedule[] | undefined,
  num: number,
): StaffWorkSchedule | null {
  return workSchedules?.find((workSchedule) => workSchedule.day_of_week === num) ?? null;
}
