<template src="./template.html"></template>

<script>
import { mapGetters } from 'vuex'
import masterMixin from 'src/mixins/masterMixin'
import notificationMixin from 'src/mixins/notificationMixin'
import dateTimeMixin from 'src/mixins/dateTimeMixin'
import timingControlMixin from 'src/mixins/timingControlMixin'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { ensureWorkplaceRoleGteOwner } from 'src/hooks/appRoleHook'
// この画面はworkplace_masters以下ではなくアプリで普通に使うほうのapiを使っとく
import workplaceApi from 'src/apis/workplace'

const displayPageName = 'センター設定'

export default {
  data() {
    return {
      displayPageName: displayPageName,
      isSuperAdmin: false,
      workplace: {},
      saveCandidate: {},
      showSaveModal: false,
      showDeleteModal: false,

      validations: this.getValidationMap(),
    }
  },
  computed: {
    ...mapGetters('user', {
      userId: 'id',
    }),
    workplaceId() {
      return this.$route.params.workplaceId
    },
    isDataReady() {
      return !!this.workplace.id
    },
    hasError() {
      return this.errors.any()
    },
  },
  mixins: [masterMixin, notificationMixin, dateTimeMixin, timingControlMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    getValidationMap() {
      const ruleStr = { required: true, max: 255 }
      const ruleStrOpt = { max: 255 }
      // const ruleInt = { required: true, numeric: true, min_value: 0 }
      const ruleTimeIntHour = {
        required: true,
        numeric: true,
        min_value: 0,
        max_value: 47,
      }
      const ruleTimeIntMin = {
        required: true,
        numeric: true,
        min_value: 0,
        max_value: 59,
      }
      return {
        name: ruleStr,
        address: ruleStrOpt,
        startTimeHour: ruleTimeIntHour,
        startTimeMin: ruleTimeIntMin,
        endTimeHour: ruleTimeIntHour,
        endTimeMin: ruleTimeIntMin,
      }
    },
    clearErrors() {
      this.$validator.reset()
    },
    async getWorkplace() {
      const { data } = await workplaceApi.show(this.workplaceId)
      this.workplace = data
    },
    openSaveModal() {
      const saveCandidate = JSON.parse(JSON.stringify(this.workplace))
      this.saveCandidate = saveCandidate
      this.showSaveModal = true
    },
    closeSaveModal() {
      this.saveCandidate = {}
      this.clearErrors()
      this.showSaveModal = false
    },
    onWorkplaceTimeChange(prop) {
      const workplace = this.saveCandidate
      if (!workplace[`${prop}_hour`] || !workplace[`${prop}_min`]) {
        workplace[prop] = null
        return
      }
      if (isNaN(workplace[`${prop}_hour`]) || isNaN(workplace[`${prop}_min`])) {
        workplace[prop] = null
        return
      }
      const time = this.packToTimeInteger(workplace[`${prop}_hour`], workplace[`${prop}_min`], 0)
      workplace[prop] = time

      this.$validator.validate('endTime')
    },
    async saveWorkplace() {
      const isValid = await this.$validator.validate()
      if (!isValid) { return }

      try {
        await workplaceApi.update(this.workplaceId, this.saveCandidate)
        await this.getWorkplace()
        await this.$store.dispatch('workplace/setWorkplace', this.workplace)
        this.closeSaveModal()
        this.notifySuccess1('センターを編集しました')
      } catch (err) {
        const errId = 'ERR00001'
        const msg = 'センターの編集に失敗しました。管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
        this.notifyError1(msg, { err })
      }
    },
    openDeleteModal() {
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.showDeleteModal = false
    },
    async deleteWorkplace() {
      try {
        await workplaceApi.delete(this.workplaceId)
        this.closeDeleteModal()
        this.notifySuccess1('センターを削除しました')
        // センター選択画面へ
        this.$router.push({ name: 'WorkplaceSelect' })
      } catch (err) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400 && errRes.reason === 'is_undeletable') {
          const msg = '一定時間が経過したため削除できません。'
          this.notifyError1(msg, { timeout: 5 * 1000 })
        } else {
          const errId = 'ERR00002'
          const msg = 'センターの削除に失敗しました。管理者に連絡してください。' +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`
          this.notifyError1(msg, { err })
        }
      }
    },
    makeFuncGated() {
      this.saveWorkplace = this.makeAsyncFuncGated(this.saveWorkplace)
      this.deleteWorkplace = this.makeAsyncFuncGated(this.deleteWorkplace)
    },
  },
  created() {
    this.makeFuncGated()
  },
  async mounted() {
    setPageName(this, displayPageName)
    await this.ensureUserAndMasters()
    if (this.$store.getters['user/hasRoleSuperAdmin']) {
      this.isSuperAdmin = true
    }
    await ensureWorkplaceRoleGteOwner(this, this.workplaceId)
    await this.getWorkplace()
    this.$validator.attach({
      name: 'endTime',
      rules: 'custom_after1',
      getter: () => {
        return {
          start_time: this.saveCandidate.midnight_time_start,
          end_time: this.saveCandidate.midnight_time_end,
        }
      },
    })
    this.$validator.attach({
      name: 'endTime',
      rules: 'custom_after1',
      getter: () => {
        return {
          start_time: this.saveCandidate.midnight_time_start,
          end_time: this.saveCandidate.midnight_time_end,
          max_duration_seconds: 86400,
        }
      },
    })
  },
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
