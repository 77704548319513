import { computed, type ComputedRef } from '@vue/composition-api'
import { getInitialProgressDetailStartTime } from 'src/util/progressHeaderHelpers'
import { useProgressHeader } from '../../composables/useProgressHeader'

type ValidationParams = {
  minResultQuantity: ComputedRef<number>
  minEndTime: ComputedRef<number>
}

export function useValidationParams(): ValidationParams {
  const { progressHeader } = useProgressHeader()
  return {
    minResultQuantity: computed(() => (progressHeader.value.result_quantity ?? 0)),
    minEndTime: computed(() => getInitialProgressDetailStartTime(progressHeader.value) + 1),
  }
}
