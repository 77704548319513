import Vue from 'vue'
import { FieldError } from 'vee-validate'

// fieldNameをscopeの正規表現とnameの正規表現に分けて返す
function fieldNameToScopeAndNameRegExp(fieldName_: string): [RegExp | null, RegExp] {
  const replaceStrForReg = (v: string) => {
    // アスタとドットをケア
    return v
      .replace(/\./, '\\.')
      .replace(/\*/, '.+')
  }
  const toReg = (v: string) => {
    return new RegExp(`^${replaceStrForReg(v)}$`)
  }

  let scope: RegExp | null = null
  let fieldName: RegExp = toReg(fieldName_)
  const tmpParts = fieldName_.split('.')
  // ドットがついてる場合、一つ目のドットの前をscope、後ろをnameと見做す.
  if (tmpParts.length > 1) {
    scope = toReg(tmpParts[0])
    fieldName = toReg(tmpParts.slice(1).join('.'))
  }
  return [scope, fieldName]
}

function getErrorItemsFilterFunc(fieldName: string): (item: FieldError) => boolean {
  const [scopeReg, nameReg] = fieldNameToScopeAndNameRegExp(fieldName)
  return (item: FieldError) => {
    const scopeMatches = scopeReg && item.scope ? scopeReg.test(item.scope) : true
    const fieldMatches = nameReg.test(item.field)
    return scopeMatches && fieldMatches
  }
}

export function vvGetErrorObj(vue: Vue, fieldName: string): FieldError | null {
  const filterFunc = getErrorItemsFilterFunc(fieldName)
  const items = vue.$validator.errors.items.filter(filterFunc)
  return items.length > 0 ? items[0] : null
}

// $validator.errors.first(fieldName)の代替
export function vvGetError(vue: Vue, fieldName: string): string | null {
  const vvErrorObj = vvGetErrorObj(vue, fieldName)
  return vvErrorObj?.msg ?? null
}

// $validator.errors.any() の代替
export function vvHasError(vue: Vue): boolean {
  return vue.$validator.errors.items.length !== 0
}

// $validator.reset()の代替
export async function vvReset(vue: Vue, fieldName_?: string): Promise<void> {
  // https://github.com/logaretm/vee-validate/blob/v2/src/core/validator.js#L259-L270
  await vue.$nextTick().then(() => {
    return vue.$nextTick()
  }).then(() => {
    const fieldName: string = fieldName_ || '*'
    const filterFunc = getErrorItemsFilterFunc(fieldName)
    const errorIds = vue.$validator.errors.items
      .filter(filterFunc)
      .map(e => e.id)
      .filter(id => id !== null && id !== undefined) as string[]
    vue.$validator.errors.removeById(errorIds)
  })
}

// $validator.validate()の代替
export async function vvValidate(vue: Vue, fieldDescriptor_?: string): Promise<boolean> {
  // setupで作成したvueインスタンスとvee-validateのインスタンスとでvmIdが一致しない関係で
  // 素朴に$validator.validate()を呼んでも正常に動作しないため、vmIdを無視するように仕向ける
  const fieldDescriptor = fieldDescriptor_ || '*'
  return vue.$validator.validate(
    fieldDescriptor,
    undefined, // <= https://github.com/logaretm/vee-validate/blob/v2/src/core/validator.js#L319
    { vmId: null } as any
  )
}
