import { ref, onMounted, type Ref } from '@vue/composition-api'
import timetableLabelApi from 'src/apis/workplace_masters/timetable_label'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type TimetableLabel } from 'src/models/timetableLabel'
import { createInjection } from 'src/util/createInjection'

type Params = {
  workplaceId: number
}

type InjectionValue = {
  timetableLabels: Ref<TimetableLabel[]>
}

const { provide, inject } = createInjection<InjectionValue>('useTimetableLabels')

export function useTimetableLabelsProvider({ workplaceId }: Params): void {
  const errorBoundary = useErrorBoundary()
  const timetableLabels = ref<TimetableLabel[]>([])
  const fetchTimetableLabels = errorBoundary.wrap(
    async() => {
      timetableLabels.value = await timetableLabelApi.index({ workplaceId, params: { is_enabled: true } })
    },
    {
      fallbackMessage: '工程ラベルの取得に失敗しました',
    }
  )

  onMounted(fetchTimetableLabels)

  provide({
    timetableLabels,
  })
}

export function useTimetableLabels(): InjectionValue {
  return inject()
}
