import { TimeRange } from 'src/models/regularShift'
import { BreakTime } from '../hooks/useShiftRegister'

export const convertBreakTimesEmptyStringToNull = (breakTimes: BreakTime): BreakTime => {
  for (const keyOfBreakTimes in breakTimes) {
    const timeRange = breakTimes[keyOfBreakTimes as keyof BreakTime]
    for (const keyOfTimeRange in timeRange) {
      if (timeRange[keyOfTimeRange as keyof TimeRange] === '') {
        timeRange[keyOfTimeRange as keyof TimeRange] = null
      }
    }
  }
  return breakTimes
}
