









import { defineComponent } from '@vue/composition-api'
import { useCreateTimetableMaster } from '../../composables/useCreateTimetableMaster'
import { TimetableMasterInputModal } from '../TimetableMasterInputModal'

export default defineComponent({
  components: {
    TimetableMasterInputModal,
  },
  setup() {
    const { showsCreateModal, hideCreateModal, createTimetableMaster } = useCreateTimetableMaster()

    return {
      showsCreateModal,
      hideCreateModal,
      createTimetableMaster,
    }
  },
})
