


















import { defineComponent, type PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    label: {
      type: String,
      require: true,
    },
    hasSpacing: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<'switch'>,
      default: '',
    },
  },
})
