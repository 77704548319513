import { PlanBoardBlockResponse } from 'src/models/api/planBoardBlockResponse';

export interface PlanBoardBlock {
  id: number
  timetable_header_id: number
  start_time: number
  end_time: number
  headcount: number
}

export function convertPlanBoardBlockResponse(response: PlanBoardBlockResponse): PlanBoardBlock {
  return { ...response }
}
