











import { defineComponent } from '@vue/composition-api';
import { useMemo } from '../../composables/useMemo';
const MEMO_MAX_LENGTH = 255;
export default defineComponent({
  setup() {
    const { memo, updateMemo } = useMemo();
    const handleBlur = async () => {
      if (memo.value.length > MEMO_MAX_LENGTH) {
        return;
      }

      await updateMemo();
    };
    return {
      memo,
      handleBlur,
      MEMO_MAX_LENGTH,
    };
  },
});
