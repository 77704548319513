import axios, { type AxiosResponse } from 'axios'
import { getApiUrl } from 'src/apis/apiUtils'
import {
  type BackgroundProcessStatusIndexParams,
  type BackgroundProcessStatusDownloadParams,
} from 'src/models/api/backgroundProcessStatusRequest'
import {
  type BackgroundProcessStatusIndexResponse,
  type BackgroundProcessStatusDownloadResponse,
} from 'src/models/api/backgroundProcessStatusResponse'

export default {
  async index({ workplace_id, ...params }: BackgroundProcessStatusIndexParams): Promise<AxiosResponse<BackgroundProcessStatusIndexResponse>> {
    return await axios.get(getApiUrl(`/workplaces/${workplace_id}/background_process_statuses`), {
      params,
    })
  },
  async download({ workplace_id, id }: BackgroundProcessStatusDownloadParams): Promise<AxiosResponse<BackgroundProcessStatusDownloadResponse>> {
    return await axios.get(getApiUrl(`/workplaces/${workplace_id}/background_process_statuses/${id}/download`), {
      responseType: 'blob',
    })
  },
}
