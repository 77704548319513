import {
  StaffAgencyResponse
} from 'src/models/api/staffAgencyResponse'
import { convertStaffAgencyExtensionResponse, StaffAgencyExtension } from 'src/models/staffAgencyExtension';

export type StaffAgency = Omit<
  StaffAgencyResponse,
  'staff_agency_extension'
> & {
  staff_agency_extension?: StaffAgencyExtension
}

export function convertStaffAgencyResponse(response: StaffAgencyResponse): StaffAgency {
  const { staff_agency_extension, ...rest } = response
  return {
    ...rest,
    ...(staff_agency_extension
      ? { staff_agency_extension: convertStaffAgencyExtensionResponse(staff_agency_extension) }
      : null),
  }
}
