














import { defineComponent, PropType, ref, toRefs, watch, computed } from '@vue/composition-api';
import { useHeadcountUpdatePopover } from '../composables/useHeadcountUpdatePopover';
import { type TimeBlock, isTimetableTimeBlock, isRestingTimetableTimeBlock, isSupportTimeBlock } from '../types';

export default defineComponent({
  props: {
    timeBlock: {
      type: Object as PropType<TimeBlock>,
      required: true,
    },
    timetableName: {
      type: String as PropType<string | null>,
      default: null,
    },
    isHeadcountChangeable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const headcountElement = ref<HTMLDivElement>();
    const { timeBlock } = toRefs(props);
    const { timeBlock: activeTimeBlock, showHeadcountUpdatePopover } = useHeadcountUpdatePopover();

    const isHeadcountChangeable = computed(() => {
      return props.isHeadcountChangeable && timeBlock.value.isSimulated;
    });

    const isClickable = computed(() => {
      return (
        isHeadcountChangeable.value &&
        ((isTimetableTimeBlock(timeBlock.value) && !timeBlock.value.isBreak) || isSupportTimeBlock(timeBlock.value))
      );
    });

    const isDblClickable = computed(() => {
      return (
        isHeadcountChangeable.value &&
        ((isTimetableTimeBlock(timeBlock.value) && timeBlock.value.isBreak) ||
          isRestingTimetableTimeBlock(timeBlock.value))
      );
    });

    const onClick = (event: MouseEvent) => {
      if (!isClickable.value) {
        return;
      }
      showPopover(event);
    };

    const onDblClick = (event: MouseEvent) => {
      if (!isDblClickable.value) {
        return;
      }
      showPopover(event);
    };

    const showPopover = (event: MouseEvent) => {
      showHeadcountUpdatePopover({
        event,
        timeBlock: timeBlock.value,
        timetableName: props.timetableName,
      });
      headcountElement.value?.classList.add('is-target');
    };

    watch(
      () => activeTimeBlock.value,
      () => {
        if (
          activeTimeBlock.value?.timetableMasterId !== timeBlock.value.timetableMasterId ||
          activeTimeBlock.value?.displayTime !== timeBlock.value.displayTime
        ) {
          headcountElement.value?.classList.remove('is-target');
        }
      },
    );

    return {
      headcountElement,
      onClick,
      onDblClick,
    };
  },
});
