export const scrollLinkage = (event: { currentTarget: HTMLElement}) => {
  const scrollCount = event.currentTarget.scrollLeft;
  const shiftTotalTable = document.querySelector('#shiftTotalScroll');
  const staffTotalTable = document.querySelector('#staffTotalScroll');

  if (shiftTotalTable) {
    shiftTotalTable.scrollLeft = scrollCount;
  }

  if (staffTotalTable) {
    staffTotalTable.scrollLeft = scrollCount;
  }
}