import Vue from 'vue'
import { unpackTimeInteger } from 'src/util/datetime'
import { onUnmounted } from '@vue/composition-api'
import { FieldOptions } from 'vee-validate/types/vee-validate'
import { TimeInteger } from 'src/models/common'

export type StartEndValidatorParamsGetter = () => {
  startDate: Date | null
  endDate: Date | null
  startTime: TimeInteger | null
  endTime: TimeInteger | null
}

/*
 * NOTE: This function needs to be called somewhere inside setup(), since
 * it calls onUnmounted() in order to deregister the attached validator.
 */
export function setupStartEndValidator(
  root: Vue,
  origName: string,
  paramsGetter: StartEndValidatorParamsGetter
): void {
  const nameParts = origName.split('.')
  const [scope, name] = nameParts.length > 1
    ? [nameParts[0], nameParts.slice(1).join('.')]
    : [null, origName]

  const validatorFieldOptions = {} as Partial<FieldOptions>
  if (scope) {
    validatorFieldOptions.scope = scope
  }
  validatorFieldOptions.name = name
  validatorFieldOptions.rules = 'custom_after1' // see main.ts
  validatorFieldOptions.getter = () => {
    const { startDate, endDate, startTime, endTime } = paramsGetter()
    if (startDate === null || endDate === null) {
      return { start_time: null, end_time: null }
    }
    const startDateTime = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      0, 0, 0
    )
    const endDateTime = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      0, 0, 0
    )
    if (startTime !== null) {
      const [h, m] = unpackTimeInteger(startTime)
      startDateTime.setHours(h)
      startDateTime.setMinutes(m)
    }
    if (endTime !== null) {
      const [h, m] = unpackTimeInteger(endTime)
      endDateTime.setHours(h)
      endDateTime.setMinutes(m)
    }
    return { start_time: startDateTime, end_time: endDateTime }
  }
  root.$validator.attach(validatorFieldOptions as FieldOptions)

  onUnmounted(() => {
    root.$validator.detach(origName)
  })
}
