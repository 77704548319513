import { ref, readonly, type Ref, type DeepReadonly, reactive } from '@vue/composition-api';
import staffShiftApi from 'src/apis/staffShift';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { createInjection } from 'src/util/createInjection';
import { useSearchConditions } from './useSearchConditions';
import { isExist } from 'src/util/isExist';
import { MacroOperationMaster } from 'src/models/macroOperationMaster';
import { packToTimeInteger, unpackTimeIntegerToStringFormat } from 'src/util/datetime';
import { StaffShift } from 'src/models/staffShift';
import { useMacroOperationMasters } from './useMacroOperationMasters';
import { Staff } from 'src/models/staff';

const BLOCK_LENGTH_PER_HOUR = 4;
const DISPLAY_HOUR_PERIOD = 48;

/**
 * 1 ブロックあたりのシフト人数情報
 */
type StaffShiftTimeBlock = {
  displayTime: string;
  count: number;
  macroOperationMasterId: number;
};
/**
 * 1 時間ごとの表示に必要な情報
 */
type StaffShiftHourBlock = {
  unitTimeBlocks: StaffShiftTimeBlock[];
};
type TotalHourBlocks = {
  header: DeepReadonly<MacroOperationMaster>;
  totalHourBlocks: StaffShiftHourBlock[];
};
type StaffShiftsState = {
  staffShiftTimelineRow: TotalHourBlocks[];
};
type InjectionValue = {
  state: DeepReadonly<StaffShiftsState>;
  StaffShiftTimelines: DeepReadonly<Ref<MacroOperationMaster[]>>;
  fetchStaffShiftTimelines: () => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffShiftTimelines');

export function useStaffShiftTimelinesProvider(): void {
  const errorBoundary = useErrorBoundary();
  const StaffShiftTimelines = ref<MacroOperationMaster[]>([]);
  const { workplaceId, dt, budgetGroup } = useSearchConditions();
  const { macroOperationMasters, fetchMacroOperationMasters } = useMacroOperationMasters();
  const state: StaffShiftsState = reactive({
    staffShiftTimelineRow: [],
  });
  /**
   * 1 時間毎に人時情報の配列をまとめた配列を生成する。
   */
  const createHourBlocks = (
    staffShifts: DeepReadonly<StaffShift[]>,
    staffs: Staff[],
    targetMacroOperationMasterId: number,
  ): StaffShiftHourBlock[] => {
    const hourBlocks: StaffShiftHourBlock[] = [];
    const preProcessedStaffShifts = staffShifts
      .filter((staffShift) => {
        return staffShift.scheduled_work_start_time !== null && staffShift.scheduled_work_end_time !== null;
      })
      .map((staffShift) => {
        return {
          ...staffShift,
          staff: staffs.find((staff) => staff.id === staffShift.staff_id),
        };
      });

    for (let hour = 0; hour < DISPLAY_HOUR_PERIOD; hour++) {
      const unitTimeBlocks: StaffShiftTimeBlock[] = [];
      for (let blockIndex = 0; blockIndex < BLOCK_LENGTH_PER_HOUR; blockIndex++) {
        const targetStartTime = packToTimeInteger(hour, Math.round((60 / BLOCK_LENGTH_PER_HOUR) * blockIndex), 0);
        const targetStaffShifts = preProcessedStaffShifts.filter((staffShift) => {
          if (staffShift.staff === undefined) {
            return false;
          }
          if (staffShift.scheduled_work_start_time === null || staffShift.scheduled_work_end_time === null) {
            return false;
          }
          // 休憩時間は考慮しない
          if (
            staffShift.scheduled_break1_start_time !== null &&
            staffShift.scheduled_break1_end_time !== null &&
            staffShift.scheduled_break1_start_time <= targetStartTime &&
            targetStartTime < staffShift.scheduled_break1_end_time
          ) {
            return false;
          }

          if (
            staffShift.scheduled_break2_start_time !== null &&
            staffShift.scheduled_break2_end_time !== null &&
            staffShift.scheduled_break2_start_time <= targetStartTime &&
            targetStartTime < staffShift.scheduled_break2_end_time
          ) {
            return false;
          }

          const macroOperationMasterId = getMacroOperationMasterId(dt.value, targetStartTime, staffShift.staff);
          return (
            staffShift.scheduled_work_start_time <= targetStartTime &&
            targetStartTime < staffShift.scheduled_work_end_time &&
            macroOperationMasterId === targetMacroOperationMasterId
          );
        });

        unitTimeBlocks.push({
          displayTime: unpackTimeIntegerToStringFormat(targetStartTime),
          count: targetStaffShifts.length,
          macroOperationMasterId: targetMacroOperationMasterId,
        });
      }
      hourBlocks.push({
        unitTimeBlocks,
      });
    }

    return hourBlocks;
  };

  function getMacroOperationMasterId(dt: Date, targetStartTime: number, staff: Staff): number | null {
    if (staff.staff_extension === undefined) {
      return null;
    }

    const staffWorkSchedule = staff.staff_extension.staff_work_schedules.find(
      (staff_work_schedule) => staff_work_schedule.day_of_week === dt.getDay(),
    );
    if (staffWorkSchedule === undefined) {
      return null;
    }

    if (
      staffWorkSchedule.duty1_macro_operation_master_id !== null &&
      staffWorkSchedule.duty1_start_time !== null &&
      staffWorkSchedule.duty1_end_time !== null &&
      staffWorkSchedule.duty1_start_time <= targetStartTime &&
      targetStartTime < staffWorkSchedule.duty1_end_time
    ) {
      return staffWorkSchedule.duty1_macro_operation_master_id;
    } else if (
      staffWorkSchedule.duty2_macro_operation_master_id !== null &&
      staffWorkSchedule.duty2_start_time !== null &&
      staffWorkSchedule.duty2_end_time !== null &&
      staffWorkSchedule.duty2_start_time <= targetStartTime &&
      targetStartTime < staffWorkSchedule.duty2_end_time
    ) {
      return staffWorkSchedule.duty2_macro_operation_master_id;
    } else if (staff.staff_extension.macro_operation_master_id !== null) {
      return staff.staff_extension.macro_operation_master_id;
    } else {
      return null;
    }
  }

  const fetchStaffShiftTimelines = errorBoundary.wrap(
    async () => {
      if (!isExist(budgetGroup.value)) {
        return;
      }
      const [staffs, staffShifts]: [Staff[], StaffShift[]] = await Promise.all([
        staffExtensionApi.staffs({
          workplaceId: workplaceId,
          params: {
            staff_number: null,
            staff_id: null,
            budget_group_id: budgetGroup.value.id,
            staff_agency_id: null,
            staff_label_id: null,
            is_enabled: true,
          },
        }),
        staffShiftApi.sub_budget_group_list({
          workplace_id: workplaceId,
          budget_group_id: budgetGroup.value.id,
          dt: dt.value,
        }),
      ]);
      await fetchMacroOperationMasters();
      state.staffShiftTimelineRow = macroOperationMasters.value.map((macroOperationMaster) => {
        return {
          header: macroOperationMaster,
          totalHourBlocks: createHourBlocks(staffShifts, staffs, macroOperationMaster.id),
        };
      });
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    },
  );

  provide({
    state,
    StaffShiftTimelines: readonly(StaffShiftTimelines),
    fetchStaffShiftTimelines,
  });
}

export function useStaffShiftTimelines(): InjectionValue {
  return inject();
}
