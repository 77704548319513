import { render, staticRenderFns } from "./ScrollableMatrix.vue?vue&type=template&id=97ca0700&scoped=true"
import script from "./ScrollableMatrix.vue?vue&type=script&lang=ts"
export * from "./ScrollableMatrix.vue?vue&type=script&lang=ts"
import style0 from "./ScrollableMatrix.vue?vue&type=style&index=0&id=97ca0700&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ca0700",
  null
  
)

export default component.exports