import progressDetailApi from 'src/apis/progressDetail'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { type ProgressHeader, type ProgressDetail } from 'src/models/progressHeader'
import { getInitialProgressDetailStartTime } from 'src/util/progressHeaderHelpers'
import { useProgressHeader } from './useProgressHeader'

type QuickCreateProgressDetailParams = {
  resultQuantity: number
  endTime: ProgressDetail['end_time']
}

type UseQuickCreateProgressDetail = {
  quickCreateProgressDetail: (params: QuickCreateProgressDetailParams) => Promise<boolean>
}

async function requestToCreateProgressDetail({
  progressHeader,
  resultQuantity,
  endTime,
}: {
  progressHeader: ProgressHeader
  resultQuantity: number
  endTime: ProgressDetail['end_time']
}): Promise<void> {
  await progressDetailApi.create({
    workplace_id: progressHeader.workplace_id,
    progress_header_id: progressHeader.id,
    start_time: getInitialProgressDetailStartTime(progressHeader),
    end_time: endTime,
    quantity: resultQuantity - (progressHeader.result_quantity ?? 0),
    man_hours: 0,
  })
}

export function useQuickCreateProgressDetail(): UseQuickCreateProgressDetail {
  const { progressHeader, fetchProgressHeader } = useProgressHeader()
  const { notifySuccess } = useNotification()
  const errorBoundary = useErrorBoundary()
  const quickCreateProgressDetail = errorBoundary.wrap(
    async(params: QuickCreateProgressDetailParams) => {
      await requestToCreateProgressDetail({
        progressHeader: progressHeader.value,
        ...params,
      })
      notifySuccess('進捗履歴を作成しました')
      await fetchProgressHeader()
    },
    {
      fallbackMessage: '進捗履歴の作成に失敗しました。',
      shouldContactUs: true,
    },
  )

  return {
    quickCreateProgressDetail,
  }
}
