








































import { defineComponent, ref, watch } from '@vue/composition-api';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import DatePicker from 'src/components/UIComponents/Inputs/DatePicker.vue';
import { useLoadLogimeterStaffAttendances } from '../../composables/useLoadLogimeterStaffAttendances';
import { useSearchConditions } from '../../composables/useSearchConditions';
import ChangeAttendanceTypeRadioGroup from './ChangeAttendanceTypeRadioGroup.vue';

export default defineComponent({
  components: {
    NormalButton,
    PrimaryButton,
    DatePicker,
    ChangeAttendanceTypeRadioGroup,
  },
  setup() {
    const {
      showsLogimeterStaffAttendancesLoadModal,
      hideLogimeterStaffAttendancesLoadModal,
      loadLogimeterStaffAttendances,
    } = useLoadLogimeterStaffAttendances();
    const { budgetGroup } = useSearchConditions();
    const date = ref(new Date());
    const changeAttendanceType = ref<number>(0);
    const isSubmitting = ref(false);
    const handleSubmit = async () => {
      if (isSubmitting.value || budgetGroup.value === null) {
        return;
      }

      try {
        isSubmitting.value = true;
        await loadLogimeterStaffAttendances({
          budgetGroup: budgetGroup.value,
          date: date.value,
          changeAttendanceType: changeAttendanceType.value,
        });
      } finally {
        isSubmitting.value = false;
      }
    };

    watch(showsLogimeterStaffAttendancesLoadModal, (showsLogimeterStaffAttendancesLoadModal) => {
      if (showsLogimeterStaffAttendancesLoadModal) {
        date.value = new Date();
        changeAttendanceType.value = 0;
      }
    });

    return {
      showsLogimeterStaffAttendancesLoadModal,
      hideLogimeterStaffAttendancesLoadModal,
      date,
      changeAttendanceType,
      isSubmitting,
      handleSubmit,
    };
  },
});
