













































import { defineComponent } from '@vue/composition-api';
import { StaffExperienceMatrixOperationItem } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/components/StaffExperienceMatrixOperationItem';
import { useStaffExperience } from 'src/views/Dashboard/Workplace/StaffExperienceOverview/composables/useStaffExperience';
import { useStaffSkill } from '../../composables/useStaffSkill';

export default defineComponent({
  components: {
    StaffExperienceMatrixOperationItem,
  },
  setup() {
    const { totalSummary } = useStaffExperience();
    const { enabledCustomSkill1, enabledCustomSkill2, enabledCustomSkill3 } = useStaffSkill();

    return {
      totalSummary,
      enabledCustomSkill1,
      enabledCustomSkill2,
      enabledCustomSkill3,
    };
  },
});
