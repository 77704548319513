import { requestTimeout, clearRequestTimeout, type TimeoutID } from 'src/util/request_animation_frame'

type Timer = {
  start: () => void
  stop: () => void
}

/**
 * @param callback
 * @param delay ミリ秒
 */
export function createTimer(callback: () => Promise<unknown> | unknown, delay: number): Timer {
  let timeoutId: TimeoutID | null = null
  const start = () => {
    stop()
    timeoutId = requestTimeout(
      async() => {
        await callback()
        start()
      },
      delay
    )
  }
  const stop = () => {
    clearRequestTimeout(timeoutId)
    timeoutId = null
  }

  return {
    start,
    stop,
  }
}
