import Vue from 'vue';
import {
  defineComponent,
  SetupContext,
  computed,
  reactive,
  onMounted,
  nextTick,
  onBeforeUnmount,
  watch,
} from '@vue/composition-api';
import moment from 'src/util/moment-ja';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group';
import timetableLabelApi from 'src/apis/workplace_masters/timetable_label';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import planProgressApi from 'src/apis/planProgress';
import allocationHistoryApi from 'src/apis/planProgessAllocationHistory';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { UpdateOptParams } from 'src/models/api/planProgressRequest';
import { PlanProgressBlock } from 'src/models/planProgressBlock';
import { BudgetGroup } from 'src/models/budgetGroup';
import { isExist } from 'src/util/isExist';
import { DecimalString } from 'src/models/common';
import {
  packToTimeInteger,
  unpackTimeInteger,
  unpackTimeIntegerToString,
  unpackTimeIntegerToStringFormat,
} from 'src/util/datetime';
import {
  createHistories,
  createTimetableBudgets,
  getAmountData,
  getCurrentProductivity,
  getHeadCountData,
  getPredictedCompleteData,
  getProductivity,
  TimetableBudgetEx,
  colors,
  statusColor,
  getPredictedCompleteTimeColor,
} from 'src/util/planProgressHelper';
import { PlanProgressAllocationHistoryResponse } from 'src/models/api/planProgressAllocationHistoryResponse';
import { CreateOptParams } from 'src/models/api/planProgressAllocationHistoryRequest';
import { convertTimetableHeaderResponse, TimetableHeader } from 'src/models/timetableHeader';
import { TimetableLabelResponse } from 'src/models/api/timetableLabelResponse';
import { toNumberOrNull } from 'src/util/numbers';
import { TimeoutID, clearRequestTimeout, requestTimeout } from 'src/util/request_animation_frame';
import { TimetableMaster } from 'src/models/timetableMaster';
import UnFocusOutlineButton from 'src/components/UnFocusOutlineButton.vue';

const INPUT_TYPE = {
  SCHEDULED_AMOUNT: 'scheduled_amount',
  RESULT_AMOUNT: 'result_amount',
  SCHEDULED_HEADCOUNT: 'scheduled_headcount',
  RESULT_HEADCOUNT: 'result_headcount',
  TARGET_TIME: 'target_time',
  COMPLETION_TIME: 'completed_time',
  AMOUNT_WORK_BEFOREHAND: 'amount_work_beforehand',
  HEADCOUNT_WORK_BEFOREHAND: 'headcount_work_beforehand',
} as const;
type INPUT_TYPE_TYPE = (typeof INPUT_TYPE)[keyof typeof INPUT_TYPE];

const msgVars = {
  [INPUT_TYPE.SCHEDULED_AMOUNT]: '作業量 予定',
  [INPUT_TYPE.RESULT_AMOUNT]: '作業量 実績',
  [INPUT_TYPE.SCHEDULED_HEADCOUNT]: '投下工数 予定',
  [INPUT_TYPE.RESULT_HEADCOUNT]: '投下工数 実績',
  [INPUT_TYPE.TARGET_TIME]: '目標時刻',
  [INPUT_TYPE.COMPLETION_TIME]: '完了時刻',
  [INPUT_TYPE.AMOUNT_WORK_BEFOREHAND]: '累計作業量 事前実績',
  [INPUT_TYPE.HEADCOUNT_WORK_BEFOREHAND]: '投下工数 事前実績',
};

const ALLOCATION_TYPE_TEXTS = ['作業割合から設定', '投下工数から設定'];

const ALLOCATION_TYPES = {
  amountRate: 0,
  headcount: 1,
};
const MODAL_TYPE = {
  AMOUNT_RATE: 'amountRate',
  HEADCOUNT: 'headcount',
} as const;

const TIMETABLE_CELL_SIZE = 108;

type MODAL_TYPE_TYPE = (typeof MODAL_TYPE)[keyof typeof MODAL_TYPE];

type PlanProgressHeader = Omit<TimetableHeader, 'productivity'> & {
  displayedTime: string;
  timetableBudgets: TimetableBudgetEx[];
  targetTimeHour: string;
  targetTimeMin: string;
  completionTimeHour: string;
  completionTimeMin: string;
  isLogimeterConnected: boolean;
  currentBudgetIndex: number;
  lastScheduledAmount: string | number;
  currentResultAmount: string;
  currentHeadCount: string;
  progressRate: string;
  plannedProductivity: string;
  sumResultHeadCount: string;
  sumScheduledHeadCount: string;
  productivity: string;
  currentProductivity: string;
  needHeadCount: string;
  progressColor: string;
  headCountColor: string;
  productivityColor: string;
  currentProductivityColor: string;
  needHeadCountColor: string;
  predictedCompleteTime: string;
  predictedCompleteAmount: string;
  predictedCompleteTimeColor: string;
  currentTime: string;
};
type History = {
  id: number;
  timetable_header_id: number;
  time: string;
  type: string;
  quantity: number;
};

type State = {
  workplaceId: string;
  dt: string;
  groupId: number | null;
  lastUpdatedAt: string;
  budgetGroups: BudgetGroup[];
  timetables: PlanProgressHeader[];
  timetableLabels: TimetableLabelResponse[];
  timetableMasters: TimetableMaster[];
  selectTimetableMasters: TimetableMaster[];
  timetableLabelIds: number[];
  timetableMasterId: number | null;
  saveCandidate: UpdateOptParams;
  inputType: INPUT_TYPE_TYPE;
  activeTimetableBudgetId: number | null;
  lastInputValue: string;
  isUpdating: boolean;
  isFetching: boolean;
  isOpenHistory: boolean;
  isSetHeadCount: boolean;
  isSetFromCurrentTime: boolean;
  allocation_histories: PlanProgressAllocationHistoryResponse[];
  histories: History[];
  loop: TimeoutID | null;
  startLoop: boolean;
};

type AutoSettingModalState = {
  isShow: boolean;
  isSaving: boolean;
  modalType: MODAL_TYPE_TYPE;
  targetTimetable: PlanProgressHeader | null;
  startTimeHour: number | null;
  targetAmount: number | null;
  amountWorkBeforehand: string | null;
  amountRates: DecimalString[];
  headcounts: DecimalString[];
  activeCellId: null;
  lastRateSetHour: number;
  totalAmountRate: string;
  totalHeadcount: string;
  canSave: boolean;
};

const setupState = (context: SetupContext): State => {
  const root = context.root as Vue;
  const workplaceId = root.$route.params.workplaceId;
  const state: State = reactive({
    workplaceId: computed(() => workplaceId),
    dt: moment().format('YYYY-MM-DD'),
    groups: [],
    groupId: null,
    lastUpdatedAt: '',
    budgetGroups: [],
    timetables: [],
    timetableLabels: [],
    timetableMasters: [],
    selectTimetableMasters: [],
    timetableLabelIds: [],
    timetableMasterId: null,
    saveCandidate: {
      id: 0,
      plan_progress_block: {
        id: 0,
        timetable_header_id: 0,
        hour: 0,
        scheduled_amount: null,
        result_amount: null,
        scheduled_headcount: null,
        result_headcount: null,
      },
      target_time: null,
      completed_time: null,
      amount_work_beforehand: null,
      headcount_work_beforehand: null,
    },
    inputType: INPUT_TYPE.SCHEDULED_AMOUNT,
    activeTimetableBudgetId: null,
    lastInputValue: '',
    isUpdating: false,
    isFetching: false,
    isOpenHistory: false,
    isSetHeadCount: false,
    isSetFromCurrentTime: false,
    allocation_histories: [],
    histories: [],
    loop: null,
    startLoop: false,
  });
  return state;
};

const setupAutoSettingModalState = (): AutoSettingModalState => {
  const state: AutoSettingModalState = reactive({
    isShow: false,
    isSaving: false,
    modalType: MODAL_TYPE.AMOUNT_RATE,
    targetTimetable: null,
    startTimeHour: null,
    targetAmount: null,
    amountWorkBeforehand: null,
    amountRates: [],
    headcounts: [],
    activeCellId: null,
    lastRateSetHour: computed(
      () => 0 + state.amountRates.reduce((index, rate, i) => (isExist(rate) && rate.length > 0 ? i : index), 0),
    ),
    totalAmountRate: computed(() =>
      roundNumber(
        state.amountRates.reduce((total, rate) => total + Number(rate), 0),
        10,
      ).toFixed(1),
    ),
    totalHeadcount: computed(() =>
      roundNumber(
        state.headcounts.reduce((total, headcount) => total + Number(headcount), 0),
        10,
      ).toFixed(1),
    ),
    canSave: computed(() =>
      state.modalType === MODAL_TYPE.AMOUNT_RATE
        ? isExist(state.targetAmount) && state.targetAmount > 0 && state.totalAmountRate === '100.0'
        : Number(state.totalHeadcount) > 0,
    ),
  });
  return state;
};

const createTimetables = (data: TimetableHeader[]): PlanProgressHeader[] => {
  return data.map<PlanProgressHeader>((timetableHeader) => {
    const allBudgets = timetableHeader.plan_progress_blocks.slice();
    const currentBudgetIndex = allBudgets.reduce((index, budget, i) => (isExist(budget.result_amount) ? i : index), -1);
    const plannedProductivity = isExist(timetableHeader.timetable_master.planned_productivity)
      ? Number(timetableHeader.timetable_master.planned_productivity)
      : null;
    const { currentResultAmount, currentScheduledAmount, lastScheduledAmount, progressRate } = getAmountData(
      allBudgets,
      currentBudgetIndex,
    );
    const { currentHeadCount, sumResultHeadCount, sumScheduledHeadCount, sumScheduledHeadCountToCurrent } =
      getHeadCountData(allBudgets, currentBudgetIndex);
    const amountWorkBeforehand = timetableHeader.amount_work_beforehand ?? 0;
    const headcountWorkBeforehand = isExist(timetableHeader.headcount_work_beforehand)
      ? Number(timetableHeader.headcount_work_beforehand)
      : 0;
    const productivity = getProductivity(currentResultAmount, sumResultHeadCount, amountWorkBeforehand);
    const currentProductivity = getCurrentProductivity(allBudgets, currentBudgetIndex);
    const needHeadCount = getNeedHeadCount(
      allBudgets,
      currentBudgetIndex,
      Number(productivity),
      timetableHeader.target_time,
    );
    const timetableBudgets = createTimetableBudgets(allBudgets, amountWorkBeforehand);
    const productivityByTime = timetableBudgets[currentBudgetIndex]?.productivityByTime;
    const predictedComplete = getPredictedCompleteData(
      timetableBudgets,
      productivityByTime ?? null,
      lastScheduledAmount,
      timetableHeader.target_time,
      currentBudgetIndex,
    );
    const isLogimeterConnected = !!timetableHeader.timetable_master.timetable_master_logimeter_activities?.length;
    let targetTimeHour = '';
    let targetTimeMin = '';
    let completionTimeHour = '';
    let completionTimeMin = '';
    if (isExist(timetableHeader.target_time)) {
      [targetTimeHour, targetTimeMin] = unpackTimeIntegerToString(timetableHeader.target_time);
    }
    if (isExist(timetableHeader.completed_time)) {
      [completionTimeHour, completionTimeMin] = unpackTimeIntegerToString(timetableHeader.completed_time);
    }
    return {
      ...timetableHeader,
      currentResultAmount: currentResultAmount?.toString() ?? '-',
      currentHeadCount: isLogimeterConnected ? currentHeadCount?.toString() ?? '-' : '-',
      lastScheduledAmount: lastScheduledAmount?.toString() ?? '-',
      progressRate: progressRate?.toFixed(1) ?? '-',
      plannedProductivity: plannedProductivity?.toFixed(1) ?? '-',
      sumResultHeadCount: isExist(sumResultHeadCount) ? (sumResultHeadCount + headcountWorkBeforehand).toFixed(1) : '-',
      sumScheduledHeadCount: isExist(sumScheduledHeadCount)
        ? (sumScheduledHeadCount + headcountWorkBeforehand).toFixed(1)
        : '-',
      productivity: productivity?.toFixed(1) ?? '-',
      currentProductivity: currentProductivity.toFixed(1),
      needHeadCount: isExist(needHeadCount) ? signedNumber(needHeadCount) : '-',
      progressColor: statusColor(currentResultAmount, currentScheduledAmount),
      headCountColor: statusColor(sumResultHeadCount, sumScheduledHeadCountToCurrent),
      productivityColor: statusColor(productivity, plannedProductivity),
      currentProductivityColor: statusColor(productivityByTime ?? null, plannedProductivity),
      needHeadCountColor: !isExist(needHeadCount) ? colors.black : colors[needHeadCount < 0 ? 'red' : 'blue'],
      plan_progress_blocks: allBudgets,
      timetableBudgets,
      predictedCompleteTime: predictedComplete.predictedCompleteTime,
      predictedCompleteAmount: predictedComplete.predictedCompleteAmount,
      predictedCompleteTimeColor: getPredictedCompleteTimeColor(
        predictedComplete.predictedCompleteTime,
        timetableHeader.target_time,
      ),
      currentBudgetIndex,
      currentTime: allBudgets[currentBudgetIndex]
        ? `${allBudgets[currentBudgetIndex].hour + 1}:00`
        : `${timetableHeader.timetable_master.display_start_time}:00`,
      displayedTime: allBudgets[currentBudgetIndex] ? `${allBudgets[currentBudgetIndex].hour + 1}:00 時点` : '-',
      targetTimeHour,
      targetTimeMin,
      completionTimeHour,
      completionTimeMin,
      isLogimeterConnected,
    };
  });
};

const getNeedHeadCount = (
  allBudgets: PlanProgressBlock[],
  currentBudgetIndex: number,
  productivity: number | null,
  targetTime: number | null,
) => {
  if (!isExist(targetTime)) {
    return null;
  }
  let remainingHeadCount = 0;
  let lastScheduledAmount = 0;
  let currentResultAmount = 0;
  const [targetTimeHour, targetTimeMin] = unpackTimeInteger(targetTime);
  const targetTimeMinRate = Math.min(targetTimeMin / 60, 1);
  allBudgets.forEach((budget, i) => {
    if (i > currentBudgetIndex && budget.hour <= targetTimeHour) {
      remainingHeadCount +=
        Number(budget.scheduled_headcount) * (budget.hour === targetTimeHour ? targetTimeMinRate : 1);
    }
    if (isExist(budget.scheduled_amount)) {
      lastScheduledAmount = Number(budget.scheduled_amount);
    }
    if (isExist(budget.result_amount) && i <= currentBudgetIndex) {
      currentResultAmount = Number(budget.result_amount);
    }
  });
  const remainingAmount = Math.max(lastScheduledAmount - currentResultAmount, 0);
  if (!isExist(productivity) || productivity <= 0) {
    return null;
  }
  return remainingHeadCount - remainingAmount / productivity;
};

const signedNumber = (val: number) => {
  return `${val > 0 ? '+' : val === 0 ? '±' : ''}${val.toFixed(1)}`;
};

const roundNumber = (val: number, roundBase: number) => {
  return Math.round(val * roundBase) / roundBase;
};

export default defineComponent({
  components: {
    UnFocusOutlineButton,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue;
    setPageName(root, '現場進捗');

    const state = setupState(context);
    const autoSettingModalState = setupAutoSettingModalState();

    onMounted(async () => {
      state.budgetGroups = await budgetGroupApi.index(state.workplaceId);

      const timetableLabels = await timetableLabelApi.index({
        workplaceId: state.workplaceId,
        params: { budget_group_id: state.groupId || undefined, is_enabled: true },
      });
      const timetableMasters = await timetableMasterApi.index({
        workplaceId: state.workplaceId,
        params: { budget_group_id: state.groupId || undefined, is_enabled: true, use_in_do_phase: true },
      });
      state.timetableLabels = timetableLabels;
      state.timetableMasters = timetableMasters;
      setTimetableMasters();

      watch(
        () => state.startLoop,
        () => {
          state.startLoop
            ? requestTimeout(() => updateLogimeterActivities(), 60 * 5000)
            : clearRequestTimeout(state.loop);
        },
      );
    });

    onBeforeUnmount(async () => {
      clearRequestTimeout(state.loop);
    });

    const openSaveInput = (
      type: INPUT_TYPE_TYPE,
      timetable: TimetableHeader,
      _timetable_budget?: PlanProgressBlock,
    ) => {
      const timetable_budget = _timetable_budget ?? timetable.plan_progress_blocks[0];
      const saveCandidate: UpdateOptParams = {
        id: timetable.id,
        plan_progress_block: timetable_budget,
        target_time: timetable.target_time,
        completed_time: timetable.completed_time,
        amount_work_beforehand: timetable.amount_work_beforehand,
        headcount_work_beforehand: timetable.headcount_work_beforehand,
      };
      switch (type) {
        case INPUT_TYPE.SCHEDULED_AMOUNT:
          state.lastInputValue = timetable_budget.scheduled_amount?.toString() ?? '';
          break;
        case INPUT_TYPE.RESULT_AMOUNT:
          state.lastInputValue = timetable_budget.result_amount?.toString() ?? '';
          break;
        case INPUT_TYPE.SCHEDULED_HEADCOUNT:
          state.lastInputValue = timetable_budget.scheduled_headcount?.toString() ?? '';
          break;
        case INPUT_TYPE.RESULT_HEADCOUNT:
          state.lastInputValue = timetable_budget.result_headcount?.toString() ?? '';
          break;
        case INPUT_TYPE.TARGET_TIME:
          state.lastInputValue = timetable.target_time?.toString() ?? '';
          break;
        case INPUT_TYPE.COMPLETION_TIME:
          state.lastInputValue = timetable.completed_time?.toString() ?? '';
          break;
        case INPUT_TYPE.AMOUNT_WORK_BEFOREHAND:
          state.lastInputValue = timetable.amount_work_beforehand?.toString() ?? '';
          break;
        case INPUT_TYPE.HEADCOUNT_WORK_BEFOREHAND:
          state.lastInputValue = timetable.headcount_work_beforehand?.toString() ?? '';
          break;
      }
      state.activeTimetableBudgetId = timetable_budget.id;
      state.inputType = type;
      state.saveCandidate = saveCandidate;
    };

    const clearInput = () => {
      state.saveCandidate = {
        id: 0,
        plan_progress_block: {
          id: 0,
          timetable_header_id: 0,
          hour: 0,
          scheduled_amount: null,
          result_amount: null,
          scheduled_headcount: null,
          result_headcount: null,
        },
        target_time: null,
        completed_time: null,
        amount_work_beforehand: null,
        headcount_work_beforehand: null,
      };
      state.lastInputValue = '';
      state.activeTimetableBudgetId = null;
    };

    const saveItem = async (value: number | string | null) => {
      const isValid = await root.$validator.validate();
      if (!isValid) {
        return;
      }
      if (state.lastInputValue === value) {
        clearInput();
        return;
      }
      const timetable = state.timetables.find((timetable) => timetable.id === state.saveCandidate.id);
      switch (state.inputType) {
        case INPUT_TYPE.SCHEDULED_AMOUNT:
          state.saveCandidate.plan_progress_block.scheduled_amount = toNumberOrNull(value);
          break;
        case INPUT_TYPE.RESULT_AMOUNT:
          state.saveCandidate.plan_progress_block.result_amount = toNumberOrNull(value);
          break;
        case INPUT_TYPE.SCHEDULED_HEADCOUNT:
          state.saveCandidate.plan_progress_block.scheduled_headcount = toNumberOrNull(value);
          break;
        case INPUT_TYPE.RESULT_HEADCOUNT:
          state.saveCandidate.plan_progress_block.result_headcount = toNumberOrNull(value);
          break;
        case INPUT_TYPE.TARGET_TIME:
          // eslint-disable-next-line no-case-declarations
          const targetTime = isExist(timetable)
            ? packToTimeInteger(Number(timetable.targetTimeHour ?? 0), Number(timetable.targetTimeMin ?? 0), 0)
            : null;
          if (state.lastInputValue === (isExist(targetTime) ? targetTime.toString() : '')) {
            return;
          }
          state.saveCandidate.target_time = targetTime;
          break;
        case INPUT_TYPE.COMPLETION_TIME:
          // eslint-disable-next-line no-case-declarations
          const completedTime = isExist(timetable)
            ? packToTimeInteger(Number(timetable.completionTimeHour ?? 0), Number(timetable.completionTimeMin ?? 0), 0)
            : null;
          if (state.lastInputValue === (isExist(completedTime) ? completedTime.toString() : '')) {
            return;
          }
          state.saveCandidate.completed_time = completedTime;
          break;
        case INPUT_TYPE.AMOUNT_WORK_BEFOREHAND:
          state.saveCandidate.amount_work_beforehand = toNumberOrNull(value);
          break;
        case INPUT_TYPE.HEADCOUNT_WORK_BEFOREHAND:
          state.saveCandidate.headcount_work_beforehand = toNumberOrNull(value);
          break;
      }

      planProgressApi
        .update(state.workplaceId, state.saveCandidate)
        .then(() => {
          notifySuccess1(root, `${msgVars[state.inputType]}を登録しました`);
          update();
        })
        .catch((err: any) => {
          notifyError1(root, `${msgVars[state.inputType]}の登録に失敗しました。`, { err });
        });
      clearInput();
    };

    // 目標の自動割り当てモーダルopen
    const openAutoSettingModal = async (timetable: PlanProgressHeader) => {
      state.isSetHeadCount = false;
      state.isSetFromCurrentTime = false;
      autoSettingModalState.isShow = true;
      autoSettingModalState.targetTimetable = timetable;
      autoSettingModalState.targetAmount = Number(timetable.lastScheduledAmount) || 0;
      autoSettingModalState.startTimeHour = timetable.timetable_master.display_start_time;
      autoSettingModalState.amountWorkBeforehand = isExist(timetable.amount_work_beforehand)
        ? roundNumber(timetable.amount_work_beforehand, 10).toFixed()
        : null;
      autoSettingModalState.amountRates = timetable.timetable_master.plan_progress_block_masters.map((e) => {
        return e.scheduled_amount_rate?.toString() ?? '';
      });
      autoSettingModalState.headcounts = timetable.timetable_master.plan_progress_block_masters.map((e) => {
        return e.scheduled_headcount?.toString() ?? '';
      });

      await nextTick();
      if (autoSettingModalState.isShow && autoSettingModalState.startTimeHour > 0) {
        const timetable = document.querySelector('.timetable-scroller');
        // 時刻一つあたりのセルが108px
        if (timetable) {
          timetable.scrollLeft = autoSettingModalState.startTimeHour * TIMETABLE_CELL_SIZE;
        }
      }
    };

    // 目標の自動割り当てモーダルclose
    const closeAutoSettingModal = () => {
      autoSettingModalState.isShow = false;
    };

    // 目標の自動割り当て登録
    const saveAutoSetting = async () => {
      if (!autoSettingModalState.canSave) {
        return;
      }

      const targetTimetable =
        autoSettingModalState.modalType === MODAL_TYPE.AMOUNT_RATE
          ? createTargetTimetableFromAmountRate()
          : createTargetTimetableFromHeadcount();

      if (!isExist(targetTimetable)) {
        return;
      }

      const { lastScheduledAmount } = getAmountData(
        targetTimetable.plan_progress_blocks,
        targetTimetable.currentBudgetIndex,
      );

      autoSettingModalState.isSaving = true;
      const allocationHistoryData: CreateOptParams = {
        registration_time: Number(moment().format('HHmmss')),
        quantity: Number(lastScheduledAmount) || 0,
        allocation_type: ALLOCATION_TYPES[autoSettingModalState.modalType],
      };
      try {
        await planProgressApi.plan_progress_blocks_update(state.workplaceId, {
          id: targetTimetable.id,
          amount_work_beforehand: targetTimetable.amount_work_beforehand,
          plan_progress_blocks: targetTimetable.plan_progress_blocks,
        });
        await allocationHistoryApi.create({
          workplaceId: state.workplaceId,
          timetableId: targetTimetable.id,
          data: allocationHistoryData,
        });
        closeAutoSettingModal();
        notifySuccess1(root, `${msgVars.scheduled_amount}を登録しました`);
        await update();
      } catch (err: any) {
        notifyError1(root, `${msgVars.scheduled_amount}の登録に失敗しました。`, { err });
      }
      autoSettingModalState.isSaving = false;
    };

    // 実績投下工数のみ更新する(LMの投下工数取得)
    const fetchResultHeadcount = async (timetable: PlanProgressHeader) => {
      if (!isExist(timetable)) {
        return;
      }
      try {
        await planProgressApi.update_logimeter_activities(state.workplaceId, {
          dt: state.dt,
          budget_group_ids: isExist(state.groupId) ? [state.groupId] : null,
          timetable_label_ids: state.timetableLabelIds.length > 0 ? state.timetableLabelIds : null,
          timetable_master_ids: [timetable.timetable_master_id],
        });
        await update();
        notifySuccess1(root, `${msgVars.result_headcount}を更新しました`);
      } catch (err: any) {
        notifyError1(root, `${msgVars.result_headcount}の更新に失敗しました。`, { err });
      }
    };

    // 作業割合から設定
    const createTargetTimetableFromAmountRate = () => {
      if (!isExist(autoSettingModalState.targetTimetable)) {
        return;
      }
      if (!isExist(autoSettingModalState.targetAmount)) {
        return;
      }

      const targetTimetable = autoSettingModalState.targetTimetable;
      const amountWorkBeforehand = Number(autoSettingModalState.amountWorkBeforehand);
      let targetAmount = autoSettingModalState.targetAmount - (isNaN(amountWorkBeforehand) ? 0 : amountWorkBeforehand);

      const currentIndex = autoSettingModalState.targetTimetable.currentBudgetIndex;
      if (state.isSetFromCurrentTime) {
        targetAmount =
          autoSettingModalState.targetAmount -
          (autoSettingModalState.targetTimetable.timetableBudgets[currentIndex].result_amount || 0);
      }
      let currentAmount: number | null = null;
      let lastAmount: number = !isNaN(amountWorkBeforehand) ? amountWorkBeforehand : 0;
      let totalRatio: number = 0;
      const plannedProductivity = targetTimetable.timetable_master.planned_productivity;
      targetTimetable.plan_progress_blocks = targetTimetable.plan_progress_blocks.map((budget, i) => {
        if (state.isSetFromCurrentTime && i <= currentIndex) {
          lastAmount = budget.result_amount || 0;
          return budget;
        }
        const amountRate = parseInt(autoSettingModalState.amountRates[i]);
        if (!isNaN(amountRate)) {
          totalRatio += Number(amountRate);
          if (totalRatio >= 100) {
            currentAmount = autoSettingModalState.targetAmount;
          } else {
            currentAmount = lastAmount + Math.floor((targetAmount * Number(amountRate)) / 100);
          }
        } else {
          currentAmount = null;
        }
        budget.scheduled_amount = currentAmount;
        if (state.isSetHeadCount) {
          budget.scheduled_headcount =
            isExist(currentAmount) && isExist(plannedProductivity) && plannedProductivity > 0
              ? Number(roundNumber((currentAmount - lastAmount) / plannedProductivity, 10).toFixed(1))
              : null;
        }
        lastAmount = currentAmount ?? lastAmount;
        return budget;
      });
      if (!isNaN(amountWorkBeforehand) && !state.isSetFromCurrentTime) {
        targetTimetable.amount_work_beforehand = amountWorkBeforehand;
      }
      return targetTimetable;
    };

    // 投下工数から設定
    const createTargetTimetableFromHeadcount = () => {
      const targetTimetable = autoSettingModalState.targetTimetable;
      if (!isExist(targetTimetable)) {
        return;
      }

      const amountWorkBeforehand = Number(autoSettingModalState.amountWorkBeforehand);
      let totalHeadcount: number = 0;
      const currentIndex = autoSettingModalState.targetTimetable!.currentBudgetIndex;

      let lastResultAmount: number = 0;
      if (state.isSetFromCurrentTime) {
        lastResultAmount = autoSettingModalState.targetTimetable!.timetableBudgets[currentIndex].result_amount || 0;
      }
      const plannedProductivity = targetTimetable.timetable_master.planned_productivity;
      targetTimetable.plan_progress_blocks = targetTimetable.plan_progress_blocks.map((budget, i) => {
        if (state.isSetFromCurrentTime && i <= autoSettingModalState.targetTimetable!.currentBudgetIndex) {
          return budget;
        }
        const headcount =
          autoSettingModalState.headcounts[i] === '' ? null : Number(autoSettingModalState.headcounts[i]);
        budget.scheduled_headcount = headcount;
        totalHeadcount += isExist(headcount) ? Number(headcount) : 0;
        budget.scheduled_amount =
          isExist(headcount) && isExist(plannedProductivity)
            ? roundNumber(totalHeadcount * plannedProductivity, 10) + amountWorkBeforehand + lastResultAmount
            : null;
        return budget;
      });
      if (!isNaN(amountWorkBeforehand) && !state.isSetFromCurrentTime) {
        targetTimetable.amount_work_beforehand = amountWorkBeforehand;
      }
      return targetTimetable;
    };

    const _onUpdateFunc = async (state: State, _context: SetupContext) => {
      state.isFetching = true;
      try {
        const { data } = await planProgressApi.index(state.workplaceId, {
          dt: state.dt,
          budget_group_ids: isExist(state.groupId) ? [state.groupId] : null,
          timetable_label_ids: state.timetableLabelIds,
          timetable_master_ids: isExist(state.timetableMasterId) ? [state.timetableMasterId] : null,
        });
        state.timetables = createTimetables(data.map(convertTimetableHeaderResponse));
      } catch (err: any) {
        notifyError1(root, '表示情報の取得に失敗しました', { err });
      }
      state.isFetching = false;
    };

    const showTimetables = async () => {
      await update();
      state.timetables.forEach((timetable, _i) => {
        if (timetable.timetable_master.display_start_time > 0) {
          const timetableNode = document.querySelector(`.plan-progress-scroller-${timetable.id}`);
          if (timetableNode) {
            timetableNode.scrollLeft = timetable.timetable_master.display_start_time * TIMETABLE_CELL_SIZE;
          }
        }
      });
      // 現場進捗が表示されたら自動更新をONにする
      state.startLoop = true;
    };

    const update = async () => {
      await _onUpdateFunc(state, context);
      state.lastUpdatedAt = moment().format('HH:mm:ss');
    };

    const updateLogimeterActivities = async () => {
      clearRequestTimeout(state.loop);
      state.isUpdating = true;
      state.isFetching = true;
      try {
        await planProgressApi.update_logimeter_activities(state.workplaceId, {
          dt: state.dt,
          budget_group_ids: isExist(state.groupId) ? [state.groupId] : null,
          timetable_label_ids: state.timetableLabelIds.length > 0 ? state.timetableLabelIds : null,
          timetable_master_ids: isExist(state.timetableMasterId) ? [state.timetableMasterId] : null,
        });
        await update();
        state.loop = requestTimeout(() => updateLogimeterActivities(), 60 * 5000);
      } catch (err: any) {
        notifyError1(root, '更新に失敗しました', { err });
      }
      state.isUpdating = false;
      state.isFetching = false;
    };

    const formatAmount = (amount: number | null) => {
      if (!isExist(amount)) {
        return null;
      }
      return roundNumber(amount, 10);
    };

    const formatDiff = (_diff: number | null) => {
      if (!isExist(_diff)) {
        return null;
      }
      const diff = roundNumber(_diff, 10);
      return diff >= 0 ? '+' + String(diff) : String(diff);
    };

    const selectModalType = (type: MODAL_TYPE_TYPE) => {
      autoSettingModalState.modalType = type;
    };

    const toggleSetHeadCountStatus = () => {
      state.isSetHeadCount = !state.isSetHeadCount;
    };

    const toggleSetFromCurrentTimeStatus = () => {
      state.isSetFromCurrentTime = !state.isSetFromCurrentTime;
      if (state.isSetFromCurrentTime) {
        autoSettingModalState.amountWorkBeforehand = null;
        autoSettingModalState.amountRates = Array(autoSettingModalState.amountRates.length).map((i) => '');
      } else {
        autoSettingModalState.amountWorkBeforehand = isExist(
          autoSettingModalState.targetTimetable!.amount_work_beforehand,
        )
          ? roundNumber(autoSettingModalState.targetTimetable!.amount_work_beforehand, 10).toFixed()
          : null;
        autoSettingModalState.amountRates =
          autoSettingModalState.targetTimetable!.timetable_master.plan_progress_block_masters.map((e) => {
            return e.scheduled_amount_rate?.toString() ?? '';
          });
      }
    };

    const openHistoryModal = async (timetable_id: number) => {
      state.histories = await histories(timetable_id);
      state.isOpenHistory = !state.isOpenHistory;
    };

    const closeHistoryModal = () => {
      state.isOpenHistory = false;
    };

    const histories = async (timetable_id: number) => {
      const { data } = await allocationHistoryApi.index({ workplaceId: state.workplaceId, timetableId: timetable_id });
      return createHistories(
        data.map((history) => {
          return {
            id: history.id,
            timetable_header_id: history.timetable_header_id,
            time: unpackTimeIntegerToStringFormat(history.registration_time),
            quantity: history.quantity,
            type: ALLOCATION_TYPE_TEXTS[history.allocation_type],
          };
        }),
      ).reverse();
    };

    const delete_history = async (history: History) => {
      try {
        await allocationHistoryApi.delete({
          workplaceId: state.workplaceId,
          timetableId: history.timetable_header_id,
          itemId: history.id,
        });
        notifySuccess1(root, '自動割り当て履歴を削除しました');
        state.histories = await histories(history.timetable_header_id);
      } catch (err: any) {
        notifyError1(root, '自動割り当て履歴削除に失敗しました', { err });
      }
    };

    const setTimetableMasters = () => {
      state.timetableMasterId = null;
      if (!state.groupId) {
        state.selectTimetableMasters = state.timetableMasters;
      } else {
        state.selectTimetableMasters = state.timetableMasters.filter(
          (timetableMaster) => timetableMaster.budget_group_id === state.groupId,
        );
      }
    };

    const createTimetableHeader = async () => {
      try {
        const { data } = await planProgressApi.create(state.workplaceId, {
          dt: state.dt,
          budget_group_ids: isExist(state.groupId) ? [state.groupId] : null,
          timetable_label_ids: state.timetableLabelIds,
          timetable_master_ids: isExist(state.timetableMasterId) ? [state.timetableMasterId] : null,
        });
        state.timetables = createTimetables(data.map(convertTimetableHeaderResponse));
        notifySuccess1(root, '入力準備ができました');
      } catch {
        notifyError1(root, 'システムエラーが発生しました。管理者に連絡してください');
      }
    };

    return {
      state,
      autoSettingModalState,
      update,
      updateLogimeterActivities,
      openSaveInput,
      openAutoSettingModal,
      closeAutoSettingModal,
      saveItem,
      saveAutoSetting,
      isExist,
      formatAmount,
      formatDiff,
      selectModalType,
      INPUT_TYPE,
      MODAL_TYPE,
      toggleSetHeadCountStatus,
      toggleSetFromCurrentTimeStatus,
      openHistoryModal,
      closeHistoryModal,
      delete_history,
      setTimetableMasters,
      fetchResultHeadcount,
      showTimetables,
      createTimetableHeader,
    };
  },
});
