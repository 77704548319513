import { WorkplaceResponse } from 'src/models/api/workplaceResponse'
import { convertWorkplaceExtensionResponse, WorkplaceExtension } from 'src/models/workplaceExtension';

export type Workplace = Omit<
  WorkplaceResponse,
  'workplace_extension'
> & {
  workplace_extension?: WorkplaceExtension
}

export function convertWorkplaceResponse(response: WorkplaceResponse): Workplace {
  return {
    ...response,
    ...(response.workplace_extension
      ? { workplace_extension: convertWorkplaceExtensionResponse(response.workplace_extension) }
      : null),
  }
}
