





import { defineComponent } from '@vue/composition-api'
import { useProgressHeader } from '../../composables/useProgressHeader'
import { CardPanel } from '../CardPanel'
import ProgressChart from './ProgressChart.vue'

export default defineComponent({
  components: {
    CardPanel,
    ProgressChart,
  },
  setup() {
    const { progressHeader } = useProgressHeader()

    return {
      progressHeader,
    }
  },
})
