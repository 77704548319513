import { unref } from '@vue/composition-api'
import shiftPatternApi from 'src/apis/workplace_masters/shiftPattern'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'
import { useShiftPatterns } from './useShiftPatterns'

type UpdateShiftPatternDispOrders = {
  updateShiftPatternDispOrders: (shiftPatterns: ShiftPattern[]) => Promise<boolean>
}

async function requestToUpdateShiftPatternDispOrders({
  budgetGroup,
  shiftPatterns,
}: {
  budgetGroup: BudgetGroup
  shiftPatterns: ShiftPattern[]
}): Promise<void> {
  await shiftPatternApi.bulkUpdateDispOrder({
    workplace_id: budgetGroup.workplace_id,
    budget_group_id: budgetGroup.id,
    disp_orders: shiftPatterns.map((shiftPattern, i) => ({
      id: shiftPattern.id,
      disp_order: i + 1,
    })),
  })
}

export function useUpdateShiftPatternDispOrders(): UpdateShiftPatternDispOrders {
  const errorBoundary = useErrorBoundary()
  const { fetchShiftPatterns, budgetGroup } = useShiftPatterns()
  const updateShiftPatternDispOrders = errorBoundary.wrap(
    async (shiftPatterns: ShiftPattern[]) => {
      const unwrappedBudgetGroup = unref(budgetGroup)
      if (unwrappedBudgetGroup !== null) {
        await requestToUpdateShiftPatternDispOrders({
          budgetGroup: unwrappedBudgetGroup,
          shiftPatterns,
        })
        await fetchShiftPatterns()
      }
    },
    {
      fallbackMessage: 'シフトパターンの表示順変更に失敗しました。',
      shouldContactUs: true,
    }
  )

  return {
    updateShiftPatternDispOrders,
  }
}
