
import { defineComponent, inject, reactive, toRefs } from '@vue/composition-api';
import { ModalDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useModal';
import { SettingDisplayItemStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useSettingDisplayItem';

type State = {
  isDisplayStaffMemo: boolean;
  isDisplayStaffMemberCompany: boolean;
  isDisplaySkill: boolean;
  isDisplayYearTotal: boolean;
  isDisplayMonthTotal: boolean;
  isDisplay7DaysTotal: boolean;
};

export default defineComponent({
  name: 'setting-display-item-modal',
  setup() {
    const modalState = inject(ModalDisplayStateKey);
    const settingDisplayItemState = inject(SettingDisplayItemStateKey);
    if (!modalState || !settingDisplayItemState) {
      throw new Error('State Error');
    }

    const { isDisplaySettingDisplayItemModal, closeSettingDisplayItemModal } = modalState;

    const {
      isDisplayStaffMemo,
      isDisplayStaffMemberCompany,
      isDisplaySkill,
      isDisplayYearTotal,
      isDisplayMonthTotal,
      isDisplay7DaysTotal,
    } = settingDisplayItemState;

    const state = reactive<State>({
      isDisplayStaffMemo: isDisplayStaffMemo.value,
      isDisplayStaffMemberCompany: isDisplayStaffMemberCompany.value,
      isDisplaySkill: isDisplaySkill.value,
      isDisplayYearTotal: isDisplayYearTotal.value,
      isDisplayMonthTotal: isDisplayMonthTotal.value,
      isDisplay7DaysTotal: isDisplay7DaysTotal.value,
    });

    const clickActionButton = () => {
      isDisplayStaffMemo.value = state.isDisplayStaffMemo;
      isDisplayStaffMemberCompany.value = state.isDisplayStaffMemberCompany;
      isDisplaySkill.value = state.isDisplaySkill;
      isDisplayYearTotal.value = state.isDisplayYearTotal;
      isDisplayMonthTotal.value = state.isDisplayMonthTotal;
      isDisplay7DaysTotal.value = state.isDisplay7DaysTotal;

      closeSettingDisplayItemModal();
    };

    return {
      isDisplaySettingDisplayItemModal,
      closeSettingDisplayItemModal,
      ...toRefs(state),
      clickActionButton,
    };
  },
});
