



import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { type TimetableHeader } from 'src/models/timetableHeader'
import { formatTime, orDefault } from '../../utils/filters'
import { getDisplayedTime } from '../../utils/timetableHeaderHelpers'

export default defineComponent({
  filters: {
    formatTime,
    orDefault,
  },
  props: {
    timetableHeader: {
      type: Object as PropType<TimetableHeader>,
      required: true,
    },
  },
  setup(props) {
    const displayedTime = computed(() => getDisplayedTime(props.timetableHeader))

    return {
      displayedTime,
    }
  },
})
