
import { defineComponent, inject, computed } from '@vue/composition-api'
import { StaffWithShiftsStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/useShift'
import { PageDisplayStateKey } from 'src/views/Dashboard/Workplace/RegularShift/hooks/usePageDisplay';
import { SHIFT_TOTAL_MODE, STANDARD_WORK_HOURS_PAR_DAY } from 'src/consts'
import { getTotalBaseValue } from 'src/views/Dashboard/Workplace/RegularShift/components/ShiftTotalTable/libs/getTotalBaseValueHelper'
import { ManHoursStateKey } from '../../../hooks/useManHours';

export default defineComponent({
  name: 'shift-total-cell',
  props: {
    dt: {
      type: String,
      required: true,
    },
    macroOperationMasterId: {
      type: Number,
      required: false,
    },
  },

  setup(props, context) {
    const staffWithShiftsState = inject(StaffWithShiftsStateKey);
    const pageDisplayState = inject(PageDisplayStateKey);
    const manHoursState = inject(ManHoursStateKey)
    if (!staffWithShiftsState || !pageDisplayState || !manHoursState) {
      throw new Error('State error');
    }

    const {
      macroOperationMasters,
    } = staffWithShiftsState

    const {
      shiftManHoursByMacroOperation,
      totalShiftManHoursByDate,
    } = manHoursState

    const {
      shiftTotalMode
    } = pageDisplayState

    const manHours = computed(() => {
      const totalManHoursMapByDate = props.macroOperationMasterId === undefined
        ? totalShiftManHoursByDate.value
        : shiftManHoursByMacroOperation.value[props.macroOperationMasterId]

      if (totalManHoursMapByDate === undefined) { return 0 }

      return Math.round(Number(totalManHoursMapByDate[props.dt]))
    })

    const shiftBaseValue = computed(() => {
      const idMatchedMacroOperationMasters = props.macroOperationMasterId
        ? macroOperationMasters.value.filter(e => e.id === props.macroOperationMasterId)
        : macroOperationMasters.value.slice()
      return getTotalBaseValue(props.dt, idMatchedMacroOperationMasters);
    })

    const manHoursDifference = computed(() => {
      return manHours.value - shiftBaseValue.value;
    })

    return {
      manHoursDifference,
      shiftTotalMode,
      shiftBaseValue,
      SHIFT_TOTAL_MODE,
      STANDARD_WORK_HOURS_PAR_DAY,
      manHours,
    }
  }
})
