import { render, staticRenderFns } from "./Nl2br.vue?vue&type=template&id=deaf1784"
import script from "./Nl2br.vue?vue&type=script&lang=ts"
export * from "./Nl2br.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports