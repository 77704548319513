import { StaffSkillResponse } from 'src/models/api/staffSkillResponse';
import { convertTimetableMasterResponse, TimetableMaster } from 'src/models/timetableMaster';

export type StaffSkill = {
  id: number
  staff_id: number
  timetable_master_id: number
  timetable_master?: TimetableMaster
  priority: number
}

export function convertStaffSkillResponse(response: StaffSkillResponse): StaffSkill {
  const { timetable_master, ...rest } = response
  return {
    ...rest,
    ...(timetable_master ? { timetable_master: convertTimetableMasterResponse(timetable_master) } : null),
  }
}
