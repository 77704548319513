import { createInjection } from 'src/util/createInjection';
import { useSearchConditions } from './useSearchConditions';
import budgetGroupPlanBoardMiscApi from 'src/apis/budgetGroupPlanBoardMisc';
import { useNotification } from 'src/composables/useNotification';
import { reactive } from '@vue/composition-api';

export type BudgetGroupPlanBoardMiscState = {
  memo: string;
  lastMemo: string;
};

type InjectionValue = {
  state: BudgetGroupPlanBoardMiscState;
  setBudgetGroupPlanBoardMisc: () => Promise<void>;
  updateMemo: () => Promise<void>;
};

const { provide, inject } = createInjection<InjectionValue>('useBudgetGroupPlanBoardMisc');

export function useBudgetGroupPlanBoardMiscProvider(): void {
  const { workplaceId, dt, budgetGroup } = useSearchConditions();
  const { notifyError, notifySuccess } = useNotification();

  const state: BudgetGroupPlanBoardMiscState = reactive({
    memo: '',
    lastMemo: '',
  });

  const setBudgetGroupPlanBoardMisc = async (): Promise<void> => {
    if (budgetGroup.value === null) {
      return;
    }

    state.memo =
      (
        await budgetGroupPlanBoardMiscApi.index({
          workplace_id: workplaceId,
          budget_group_id: budgetGroup.value.id,
          start_date: dt.value,
          end_date: dt.value,
        })
      )[0]?.memo ?? '';
    state.lastMemo = state.memo;
  };
  /**
   * メモの更新
   */
  const updateMemo = async (): Promise<void> => {
    if (budgetGroup.value === null || budgetGroup.value.id === undefined) {
      return;
    }
    if (state.memo === state.lastMemo) {
      return;
    }
    try {
      const budget_group_plan_board_misc = await budgetGroupPlanBoardMiscApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: dt.value,
        memo: state.memo,
      });
      state.lastMemo = state.memo;
      notifySuccess('メモを更新しました');
    } catch (err: any) {
      notifyError('メモの更新に失敗しました', { cause: err });
    }
  };

  provide({
    state,
    setBudgetGroupPlanBoardMisc,
    updateMemo,
  });
}

export function useBudgetGroupPlanBoardMisc(): InjectionValue {
  return inject();
}
