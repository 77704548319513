import { parseYmdDate } from 'src/util/datetime';
import { MacroOperationSegmentResponse } from './api/macroOperationSegmentResponse';

export type MacroOperationSegment = {
  id: number;
  macro_operation_segment_master_id: number;
  dt: Date;
  planned_initial_quantity: number | null;
  planned_final_quantity: number | null;
  actual_quantity: number | null;
  planned_productivity: number | null;
  actual_working_hours: number | null;
  actual_productivity: number | null;
  required_working_hours: number | null;
};

export function convertMacroOperationSegmentResponse(response: MacroOperationSegmentResponse): MacroOperationSegment {
  return {
    ...response,
    dt: parseYmdDate(response.dt),
  };
}
