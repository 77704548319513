import moment from 'src/util/moment-ja';
import { DateString, TimeInteger } from 'src/models/common';
import { TIME_INTEGER_MAX_VALUE } from 'src/consts';
import { format, parse, subDays, subMonths, subYears } from 'date-fns';
import ja from 'date-fns/locale/ja';

// 参考: 年-月-日 時:分:秒 => yyyy-MM-dd HH:mm:ss
export function formatDate(dt: Date, formatString: string): string {
  return format(dt, formatString, { locale: ja });
}

// レギュラーシフト用のdateの表示形式にフォーマットする
export function formatDateForRegularShift(str: DateString): string {
  return format(parseYmdDate(str), 'MM/dd');
}

// 曜日の取得
export function getWeekDay(str: DateString): string {
  const date = parseYmdDate(str);
  return format(date, 'E', { locale: ja });
}

// railsが返却したDate型をパースする時に利用する.
export function parseYmdDate(str: DateString): Date {
  return parse(str, 'yyyy-MM-dd', new Date());
}

// railsが返却したDatetime型をパースする時に利用する.
// formatStringのデフォルトは、railsがdatetimeを返却する時のデフォルトである iso8601
export function parseDatetime(str: DateString, formatString = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"): Date {
  return parse(str, formatString, new Date());
}

export function dateToTimeInteger(dt: Date): TimeInteger {
  const h = dt.getHours();
  const m = dt.getMinutes();
  const s = dt.getSeconds();
  return h * 10000 + m * 100 + s;
}

export function ensureTimeInteger(t: any): TimeInteger {
  if (t instanceof Date) {
    t = dateToTimeInteger(t);
  }
  if (typeof t === 'string') {
    t = timeStrToTimeInteger(t);
  }
  if (isNaN(t)) {
    return NaN;
  }
  return t;
}

export function packToTimeInteger(h: number, m: number, s: number): TimeInteger {
  const ret = Math.floor(h) * 10000 + Math.floor(m) * 100 + Math.floor(s);
  return Math.min(ret, TIME_INTEGER_MAX_VALUE);
}

export function packToTimeIntegerWithGuard(_h: any, _m: any, _s: any): TimeInteger | null {
  function toValidNumberOrNull(x: any): number | null {
    if (x === null || x === undefined) {
      return null;
    }
    const num = parseInt(x);
    return isNaN(num) ? null : num;
  }
  const h = toValidNumberOrNull(_h);
  const m = toValidNumberOrNull(_m);
  const s = toValidNumberOrNull(_s);
  if (h === null || m === null || s === null) {
    return null;
  }
  return packToTimeInteger(h, m, s);
}

export function unpackTimeIntegerToString(timeInt: TimeInteger): string[] {
  const [h, m, s] = unpackTimeInteger(timeInt);
  const hStr = ('0' + h).slice(-2);
  const mStr = ('0' + m).slice(-2);
  const sStr = ('0' + s).slice(-2);
  return [hStr, mStr, sStr];
}

export function unpackTimeInteger(timeInt: TimeInteger): number[] {
  let tmp = Math.floor(timeInt);
  const h = Math.floor(timeInt / 10000);
  tmp -= h * 10000;
  const m = Math.floor(tmp / 100);
  const s = tmp % 100;
  return [h, m, s];
}

export function unpackTimeIntegerToStringFormat(timeInt: TimeInteger, formatType: number | null = null): string {
  const time = unpackTimeInteger(timeInt);

  if (formatType === 1) {
    return `${('0' + time[0]).slice(-2)} : ${('0' + time[1]).slice(-2)}`;
  } else {
    return `${time[0]}:${('0' + time[1]).slice(-2)}`;
  }
}

export function timeDifferenceInSeconds(t1_: any, t2_: any): number {
  const t1 = ensureTimeInteger(t1_);
  const t2 = ensureTimeInteger(t2_);
  const [h1, m1, s1] = unpackTimeInteger(t1);
  const [h2, m2, s2] = unpackTimeInteger(t2);
  const sumSec1 = h1 * 3600 + m1 * 60 + s1;
  const sumSec2 = h2 * 3600 + m2 * 60 + s2;
  return sumSec2 - sumSec1;
}

export function secondsToTimeInteger(seconds: number, noDigitGuard: boolean = false): TimeInteger {
  // 一旦正に戻してから計算
  const sign = seconds < 0 ? -1 : 1;
  seconds *= sign;

  let h = Math.floor(seconds / 3600);
  if (!noDigitGuard) {
    h = Math.min(h, 99); // digit guard
  }
  const tmp = seconds % 3600;
  const m = Math.floor(tmp / 60);
  const s = tmp % 60;

  const mStr = ('0' + m).slice(-2);
  const sStr = ('0' + s).slice(-2);
  return parseInt(`${h}${mStr}${sStr}`) * sign;
}

export function secondsToTimeIntegerWithNoDigitGuard(seconds: number): TimeInteger {
  return secondsToTimeInteger(seconds, true);
}

export function timeIntegerDiff(tm1: TimeInteger, tm2: TimeInteger): TimeInteger {
  return secondsToTimeIntegerWithNoDigitGuard(timeDifferenceInSeconds(tm1, tm2));
}

export function timeIntegerToSeconds(tm: TimeInteger): number {
  // 一旦正に戻してから計算
  const sign = tm < 0 ? -1 : 1;
  tm *= sign;

  const h = Math.floor(tm / 10000);
  const tmp = tm % 10000;
  const m = Math.floor(tmp / 100);
  const s = tmp % 100;

  return (h * 3600 + m * 60 + s) * sign;
}

export function timeIntegerToHours(tm: TimeInteger): number {
  const seconds = timeIntegerToSeconds(tm);
  return seconds / 3600.0;
}

export function timeIntegerToFiveMinutes(tm: TimeInteger): number {
  const time = Math.ceil(timeIntegerToSeconds(tm) / 60 / 5) * 60 * 5;
  return time;
}

// export function timeIntegerToFiveMinutesString(tm: TimeInteger): string {
//   const time = timeIntegerToFiveMinutes(tm)
//   const timeArr = unpackTimeIntegerToString(secondsToTimeInteger(time, true))
//   return timeArr[0] + ':' + timeArr[1]
// }

export function timeIntegerAdd(tm1: TimeInteger, tm2: TimeInteger): TimeInteger {
  const sec1 = timeIntegerToSeconds(tm1);
  const sec2 = timeIntegerToSeconds(tm2);
  return secondsToTimeInteger(sec1 + sec2);
}

export function timeIntegerToMoment(referenceMoment: moment.Moment, tm: TimeInteger): moment.Moment {
  const ret = referenceMoment.clone();
  const [h, m, s] = unpackTimeInteger(tm);
  ret.hours(h);
  ret.minutes(m);
  ret.seconds(s);
  ret.millisecond(0);
  return ret;
}

// 時: H, HH
// 分: M, MM
// 秒: S, SS
export function formatTimeInteger(tm: TimeInteger, formatString = 'HH:MM'): string {
  const [h, m, s] = unpackTimeIntegerToString(tm);
  return formatString
    .replace('HH', h)
    .replace('H', parseInt(h).toString())
    .replace('MM', m)
    .replace('M', parseInt(m).toString())
    .replace('SS', s)
    .replace('S', parseInt(s).toString());
}

/**
 * 時間文字列を TimeInteger へ変換する
 * 例) 13:00 -> 130000
 */
export function timeStrToTimeInteger(timeStr: string): TimeInteger {
  const [h, m, s] = timeStr.split(':');
  const seconds = parseInt(h, 10) * 3600 + parseInt(m ?? '0', 10) * 60 + parseInt(s ?? '0', 10);
  if (isNaN(seconds)) {
    return NaN;
  }
  return secondsToTimeIntegerWithNoDigitGuard(seconds);
}

export function rewindDate(dt: Date, rewindValue: number, rewindUnit: 'years' | 'months' | 'days'): Date {
  switch (rewindUnit) {
    case 'years':
      return subYears(dt, rewindValue);
    case 'months':
      return subMonths(dt, rewindValue);
    case 'days':
      return subDays(dt, rewindValue);
  }
}

export const startOfDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const endOfDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};
