


















import { defineComponent, type PropType } from '@vue/composition-api'
import { type TimetableLabel } from 'src/models/timetableLabel'

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<TimetableLabel[]>,
      required: true,
    },
    timetableLabels: {
      type: Array as PropType<TimetableLabel[]>,
      required: true,
    },
  },
})
