





















import { defineComponent, computed } from '@vue/composition-api'
import { orDefault } from 'src/util/filters'
import { useProgressHeader } from '../../composables/useProgressHeader'
import { formatNumber } from '../../utils/filters'
import HeaderItem from './HeaderItem.vue'

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
  },
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader()
    const headcount = computed(() => progressHeader.value.latest_progress_headcount_detail?.headcount ?? null)
    const headcountsByLabel = computed(() =>
      (progressHeader.value.latest_progress_headcount_detail?.headcount_by_label ?? []).filter((v) => v.count > 0)
    )

    return {
      headcount,
      headcountsByLabel,
    }
  },
})
