





















import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { type BackgroundProcessStatus } from 'src/models/backgroundProcessStatus'
import { StatusBadge } from '../StatusBadge'
import { DownloadButton } from '../DownloadButton'
import { Nl2br } from '../Nl2br'
import { formatDatetime } from '../../utils/filters'
import { getProcessName } from '../../utils/getProcessName'
import BackgroundProcessStatusListRow from './BackgroundProcessStatusListRow.vue'
import BackgroundProcessStatusListCell from './BackgroundProcessStatusListCell.vue'
import { getTruncatedValue } from './getTruncatedValue'

export default defineComponent({
  filters: {
    formatDatetime,
  },
  components: {
    BackgroundProcessStatusListRow,
    BackgroundProcessStatusListCell,
    StatusBadge,
    DownloadButton,
    Nl2br,
  },
  props: {
    backgroundProcessStatus: {
      type: Object as PropType<BackgroundProcessStatus>,
      required: true,
    },
    onDownload: {
      type: Function as PropType<(backgroundProcessStatus: BackgroundProcessStatus) => unknown>,
      required: true,
    },
  },
  setup(props) {
    const processName = computed(() => getProcessName(props.backgroundProcessStatus.process_type))
    const origParams = computed(() => getTruncatedValue(props.backgroundProcessStatus.orig_params, 80))

    return {
      processName,
      origParams,
    }
  },
})
