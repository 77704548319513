import { PropType, computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { isExist } from 'src/util/isExist'
import { isHiddenByDisplayNone } from 'src/util/isHiddenByDisplayNone'
import { toNumberOrNull } from 'src/util/numbers'

export default defineComponent({
  name: 'monthly-board-number-input',
  props: {
    value: {
      type: Number,
    },
    tabIndex: {
      type: Number,
    },
    tabIndexBase: {
      type: Number,
      default: 0,
    },
    verticalMoveOffsetUnit: {
      type: Number,
      default: 0,
    },
    onInput: {
      type: Function as PropType<(value: string) => string>,
    }
  },
  setup(props, context) {
    const toString = (value?: number | null) => String(value == null ? '' : value)
    const valueRef = ref<string>(toString(props.value))
    const lastValue = ref<number | undefined>(props.value)

    watch(valueRef, (newValue) => {
      const convertedValue = props.onInput ? props.onInput(newValue) : newValue
      valueRef.value = convertedValue

      const numberValue = toNumberOrNull(convertedValue)
      if (props.value === numberValue) { return }
      context.emit('input', numberValue)
    })

    watch(() => props.value, (newValue?: number) => {
      valueRef.value = toString(newValue)
    })
    const input = ref<{ $el: HTMLInputElement } | null>(null)
    const state = reactive({
      isFocus: false,
    })
    const onFocus = () => {
      state.isFocus = true
      valueRef.value = toString(props.value)
      lastValue.value = props.value
    }
    const onBlur = () => {
      state.isFocus = false
      if (props.value !== lastValue.value) {
        context.emit('update')
      }
    }

    /**
     * 現在画面内に表示されている NumberInput 要素の内、自身が何番目かを管理する変数。
     * Enter キーや Tab キー押下時に、入力フォーカスを移動するのに利用する。
     */
    const numberInputIndex = ref(0)
    onMounted(() => {
      const el = input.value?.$el
      if (!isExist(el)) { return }
      // マウント時に現在表示されている NumberInput 要素の内、自身が何番目かを取得する。
      numberInputIndex.value = Array.from(document.querySelectorAll<HTMLInputElement>('.NumberInput .NumberInput-input')).indexOf(el)
    })
    const computedTabindex = computed(() => {
      if (isExist(props.tabIndex)) {
        return props.tabIndex
      }
      return props.tabIndexBase + numberInputIndex.value
    })

    const onKeydown = (event: KeyboardEvent) => {
      if (!['Enter', 'Tab'].includes(event.key)) { return }
      event.preventDefault()

      const target = event.target
      if (!(target instanceof HTMLInputElement)) {
        return;
      }

      const tabIndex = target.tabIndex
      const moveOffsetUnit = (event.key === 'Enter' ? props.verticalMoveOffsetUnit : 1) * (event.shiftKey ? -1 : 1)
      let moveCount = 1
      let nextElement: HTMLInputElement | null = null
      do {
        const nextTabindex = tabIndex + moveOffsetUnit * moveCount
        nextElement = window.document.querySelector(`[tabindex="${nextTabindex}"]`)
        moveCount++
        // 特定の行が非表示になるなどを考慮して、表示されている要素が見つかるまで、最大で 200 要素先まで検索する
      } while (nextElement && isHiddenByDisplayNone(nextElement) && moveCount < 200)

      if (!isExist(nextElement) || isHiddenByDisplayNone(nextElement)) {
        if (event.key === 'Tab') {
          target.blur()
        }
        return
      }
      nextElement.select()
      nextElement.scrollIntoView({ block: 'nearest', inline: 'nearest' })
    }

    return {
      valueRef,
      input,
      state,
      computedTabindex,
      onFocus,
      onBlur,
      onKeydown,
    }
  },
})
