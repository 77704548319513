import { computed, type Ref } from '@vue/composition-api'
import progressDetailApi from 'src/apis/progressDetail'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext'
import { type ProgressHeader, type ProgressDetail } from 'src/models/progressHeader'
import { createInjection } from 'src/util/createInjection'

type ProgressDetailSubset = Pick<ProgressDetail,
  | 'start_time'
  | 'end_time'
  | 'quantity'
  | 'man_hours'
>

type ModalContext = {
  progressHeader: ProgressHeader
  fetchProgressHeader: () => Promise<unknown>
}

type InjectionValue = {
  progressHeader: Ref<ProgressHeader | null>
  showsProgressDetailCreateModal: ModalWithContext<ModalContext>['showsModal']
  showProgressDetailCreateModal: ModalWithContext<ModalContext>['showModal']
  hideProgressDetailCreateModal: ModalWithContext<ModalContext>['hideModal']
  createProgressDetail: (progressDetailSubset: ProgressDetailSubset) => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useCreateProgressDetail')

async function requestToCreateProgressDetail({
  progressHeader,
  progressDetailSubset,
}: {
  progressHeader: ProgressHeader
  progressDetailSubset: ProgressDetailSubset
}): Promise<void> {
  await progressDetailApi.create({
    workplace_id: progressHeader.workplace_id,
    progress_header_id: progressHeader.id,
    ...progressDetailSubset,
  })
}

export function useCreateProgressDetailProvider(): void {
  const {
    context,
    showsModal: showsProgressDetailCreateModal,
    showModal: showProgressDetailCreateModal,
    hideModal: hideProgressDetailCreateModal,
  } = useModalWithContext<ModalContext>()
  const { notifySuccess } = useNotification()
  const errorBoundary = useErrorBoundary()
  const createProgressDetail = errorBoundary.wrap(
    async(progressDetailSubset: ProgressDetailSubset) => {
      const progressHeader = context.value?.progressHeader ?? null
      if (progressHeader !== null) {
        await requestToCreateProgressDetail({
          progressHeader,
          progressDetailSubset,
        })
        await context.value?.fetchProgressHeader()
        hideProgressDetailCreateModal()
        notifySuccess('進捗履歴を作成しました')
      }
    },
    {
      fallbackMessage: '進捗履歴の作成に失敗しました。',
      shouldContactUs: true,
    },
  )

  provide({
    progressHeader: computed(() => context.value?.progressHeader ?? null),
    showsProgressDetailCreateModal,
    showProgressDetailCreateModal,
    hideProgressDetailCreateModal,
    createProgressDetail,
  })
}

export function useCreateProgressDetail(): InjectionValue {
  return inject()
}
