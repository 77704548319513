





















import { defineComponent, SetupContext, onBeforeUnmount, reactive } from '@vue/composition-api';
import Vue from 'vue';
import mitt from 'src/util/mitt';
import { isGuestPage } from 'src/hooks/routeHook';
import { setupAxios } from 'src/hooks/axiosHook';
import { loadUserAndMasters } from 'src/hooks/masterHook';
// Loading some plugin css asynchronously
import 'sweetalert2/dist/sweetalert2.css';
import 'vue-notifyjs/themes/default.css';

interface UpdateShowSideBarEvent {
  showSidebar: boolean;
}

export default defineComponent({
  name: 'app',
  setup(_props, context: SetupContext) {
    const root = context.root as Vue;
    const state = reactive({
      showSidebar: true,
    });
    const sidebarEventBus = mitt();
    const updateShowSidebarFunc = (obj?: UpdateShowSideBarEvent) => {
      if (!obj) {
        return;
      }
      state.showSidebar = obj.showSidebar;
    };
    sidebarEventBus.on<UpdateShowSideBarEvent>('updateShowSidebar', updateShowSidebarFunc);
    Vue.prototype.$sidebarEventBus = sidebarEventBus;

    setupAxios(context);
    loadUserAndMasters(context);

    if (!isGuestPage(root.$route.name)) {
      root.$store.dispatch('user/getMe').catch(() => {
        if (root.$route.name !== 'Login') {
          // NavigationDuplicatedエラーになるのを防ぐ
          root.$router.push({ name: 'Login' });
        }
      });
    }

    onBeforeUnmount(() => {
      sidebarEventBus.off<UpdateShowSideBarEvent>('updateShowSidebar', updateShowSidebarFunc);
    });

    return {
      state,
    };
  },
});
