
































import { defineComponent, type PropType } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import CardPanel from './CardPanel.vue';
import EndTime from './EndTime.vue';
import Prediction from './Prediction.vue';
import ProductivityChart from './ProductivityChart.vue';
import ProgressChart from './ProgressChart.vue';
import ProgressStatus from './ProgressStatus.vue';
import Headcounts from './Headcounts.vue';
import CubeIcon from './CubeIcon.vue';

export default defineComponent({
  components: {
    CardPanel,
    EndTime,
    Prediction,
    ProductivityChart,
    ProgressChart,
    ProgressStatus,
    Headcounts,
    CubeIcon,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
    productivityGraph: {
      type: Boolean,
      required: true,
    },
  },
});
