




































import { defineComponent } from '@vue/composition-api';
import { useBackgroundProcessStatuses } from '../../composables/useBackgroundProcessStatuses';
import { useDownloadCsv } from '../../composables/useDownloadCsv';
import { BackgroundProcessStatusList, BackgroundProcessStatusListItem } from '../BackgroundProcessStatusList';
import PaginationContainer from 'src/components/UIComponents/PaginationContainer.vue';

export default defineComponent({
  components: {
    BackgroundProcessStatusList,
    BackgroundProcessStatusListItem,
    PaginationContainer,
  },
  setup() {
    const { backgroundProcessStatuses, pagination } = useBackgroundProcessStatuses();
    const { downloadCsv } = useDownloadCsv();

    return {
      backgroundProcessStatuses,
      downloadCsv,
      pagination,
    };
  },
});
