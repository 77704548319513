import { DayBlockNumber, RescueAdjustmentStatusFromType } from 'src/consts';
import { RescueAdjustmentStatusResponse } from 'src/models/api/rescueAdjustmentStatusResponse';
import { parseYmdDate } from 'src/util/datetime';

export interface RescueAdjustmentStatus {
  id: number
  workplace_id: number
  dt: Date
  from_type: RescueAdjustmentStatusFromType
  from_id: number
  to_id: number
  day_block_number: DayBlockNumber
  headcount: number
}

export function convertRescueAdjustmentStatusResponse(response: RescueAdjustmentStatusResponse): RescueAdjustmentStatus {
  return {
    ...response,
    dt: parseYmdDate(response.dt),
  }
}
