import { TimetableTrendIndexResponse } from 'src/models/api/timetableTrendResponse';
import { ProgressHeader, convertProgressHeaderResponse } from './progressHeader';

export interface TimetableTrendIndexResult {
  progress_headers: ProgressHeader[]
}

export function convertTimetableTrendIndexResult(data: TimetableTrendIndexResponse): TimetableTrendIndexResult {
  return {
    progress_headers: data.progress_headers.map(convertProgressHeaderResponse),
  }
}
