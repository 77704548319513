



















































































































import Vue from 'vue';
import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type TimetableMaster } from 'src/models/timetableMaster';
import { useTimetableActivityMasters } from '../../composables/useTimetableActivityMasters';
import { formatTime, formatNumber, formatFixedDecimal } from '../../utils/filters';
import TimetableMasterListRow from './TimetableMasterListRow.vue';
import TimetableMasterListCell from './TimetableMasterListCell.vue';
import TimetableMasterListControls from './TimetableMasterListControls.vue';
import { getTimetableMasterLogimeterActivitiesString } from './getTimetableMasterLogimeterActivitiesString';
import { getAppropriationDate } from '../../utils/getAppropriationDate';

export default defineComponent({
  filters: {
    formatTime,
    formatNumber,
    formatFixedDecimal,
  },
  components: {
    TimetableMasterListRow,
    TimetableMasterListCell,
    TimetableMasterListControls,
  },
  props: {
    timetableMaster: {
      type: Object as PropType<TimetableMaster>,
      required: true,
    },
    onEdit: {
      type: Function as PropType<(timetableMaster: TimetableMaster) => unknown>,
      required: true,
    },
    onDelete: {
      type: Function as PropType<(timetableMaster: TimetableMaster) => unknown>,
      required: true,
    },
  },
  setup(props, context) {
    const root = context.root as Vue;
    const getDisplayTime = (startTime: number | null, endTime: number | null) => {
      if (startTime === null || endTime === null) {
        return '';
      }
      return `${formatTime(startTime)} - ${formatTime(endTime)}`;
    };
    const break1Time = computed(() => {
      return getDisplayTime(props.timetableMaster.break1_start_time, props.timetableMaster.break1_end_time);
    });
    const break2Time = computed(() => {
      return getDisplayTime(props.timetableMaster.break2_start_time, props.timetableMaster.break2_end_time);
    });
    const timetableLabelName = computed(() => props.timetableMaster.timetable_label?.name ?? '');
    const { timetableActivityMasters } = useTimetableActivityMasters();
    const timetableMasterLogimeterActivitiesString = computed(() =>
      getTimetableMasterLogimeterActivitiesString(props.timetableMaster, timetableActivityMasters.value),
    );
    const handleClick = () => {
      root.$router.push({
        name: 'WorkplaceOperationOverview',
        params: {
          date: 'today',
        },
        query: {
          timetable_master_id: String(props.timetableMaster.id),
          display: '1',
        },
      });
    };

    const appropriationDate = computed(() => getAppropriationDate(props.timetableMaster));

    return {
      break1Time,
      break2Time,
      timetableLabelName,
      timetableMasterLogimeterActivitiesString,
      handleClick,
      appropriationDate,
    };
  },
});
