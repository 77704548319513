











import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups';
import { useTimetableLabelsProvider } from 'src/composables/useTimetableLabels';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { useTimetableMastersProvider } from '../../composables/useTimetableMasters';
import { useTimetableActivityMastersProvider } from '../../composables/useTimetableActivityMasters';
import { useCreateTimetableMasterProvider } from '../../composables/useCreateTimetableMaster';
import { useUpdateTimetableMasterProvider } from '../../composables/useUpdateTimetableMaster';
import { useDeleteTimetableMasterProvider } from '../../composables/useDeleteTimetableMaster';
import { Content } from '../Content';
import { ControlPanel } from '../ControlPanel';
import { MainPanel } from '../MainPanel';
import { TimetableMasterCreateModal } from '../TimetableMasterCreateModal';
import { TimetableMasterUpdateModal } from '../TimetableMasterUpdateModal';
import { TimetableMasterDeleteConfirmationModal } from '../TimetableMasterDeleteConfirmationModal';

export default defineComponent({
  components: {
    Content,
    ControlPanel,
    MainPanel,
    TimetableMasterCreateModal,
    TimetableMasterUpdateModal,
    TimetableMasterDeleteConfirmationModal,
  },
  setup(_, context) {
    const root = context.root as Vue;
    const workplaceId = Number(root.$route.params.workplaceId);

    setPageName(root, '工程マスタ (当日ボード向け)');
    useBudgetGroupsProvider({ workplaceId });
    useTimetableLabelsProvider({ workplaceId });
    useTimetableMastersProvider();
    useTimetableActivityMastersProvider();
    useCreateTimetableMasterProvider();
    useUpdateTimetableMasterProvider();
    useDeleteTimetableMasterProvider();
  },
});
