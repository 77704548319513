import { toNumber, toNumberOrNull } from 'src/util/numbers';
import { TimetableHeaderResponse } from 'src/models/api/timetableHeaderResponse';
import { Workplace, convertWorkplaceResponse } from 'src/models/workplace';
import { BudgetGroup, convertBudgetGroupResponse } from 'src/models/budgetGroup';
import { convertTimetableMasterResponse, TimetableMaster } from 'src/models/timetableMaster';
import { convertPlanProgressBlockResponse, PlanProgressBlock } from 'src/models/planProgressBlock';
import { convertPlanBoardBlockResponse, PlanBoardBlock } from 'src/models/planBoardBlock';
import { parseYmdDate } from 'src/util/datetime';
import { Staff } from 'src/models/staff';

export interface TimetableHeader {
  id: number
  workplace_id: number
  workplace?: Workplace
  budget_group_id: number
  budget_group?: BudgetGroup
  timetable_master_id: number
  timetable_master: TimetableMaster
  dt: Date
  start_time: number
  end_time: number
  quantity: number
  first_half_target_ratio: number
  productivity: number
  max_allocations: number
  target_time: number | null
  completed_time: number | null
  amount_work_beforehand: number | null
  headcount_work_beforehand: number | null
  current_worker_count: number | null
  plan_progress_blocks: PlanProgressBlock[]
  plan_board_blocks: PlanBoardBlock[]
}

// TODO 個別の画面に移動したい
export type TimetableHeaderWithStaffs = TimetableHeader & {
  staffs: Staff[]
}

export function convertTimetableHeaderResponse(response: TimetableHeaderResponse): TimetableHeader {
  const ret: TimetableHeader = {
    ...response,
    dt: parseYmdDate(response.dt),
    productivity: toNumber(response.productivity),
    headcount_work_beforehand: toNumberOrNull(response.headcount_work_beforehand),
    timetable_master: convertTimetableMasterResponse(response.timetable_master),
    plan_progress_blocks: response.plan_progress_blocks
      ?.map(convertPlanProgressBlockResponse)
      .sort((a, b) => a.hour - b.hour) ?? [],
    plan_board_blocks: response.plan_board_blocks
      ?.map(convertPlanBoardBlockResponse)
      .sort((a, b) => a.start_time - b.start_time) ?? [],
  }
  if (response.workplace) {
    ret.workplace = convertWorkplaceResponse(response.workplace)
  }
  if (response.budget_group) {
    ret.budget_group = convertBudgetGroupResponse(response.budget_group)
  }
  return ret
}
