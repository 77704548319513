










import { defineComponent } from '@vue/composition-api'
import { useUpdateTimetableMaster } from '../../composables/useUpdateTimetableMaster'
import { TimetableMasterInputModal } from '../TimetableMasterInputModal'

export default defineComponent({
  components: {
    TimetableMasterInputModal,
  },
  setup() {
    const {
      timetableMaster,
      showsUpdateModal,
      hideUpdateModal,
      updateTimetableMaster,
    } = useUpdateTimetableMaster()

    return {
      timetableMaster,
      showsUpdateModal,
      hideUpdateModal,
      updateTimetableMaster,
    }
  },
})
