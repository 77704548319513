








import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { format } from 'date-fns';
import { type StaffShiftOverview } from 'src/models/staffShiftOverview';

export default defineComponent({
  props: {
    date: {
      type: Date as PropType<Date>,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    staffShiftOverview: {
      type: Object as PropType<StaffShiftOverview>,
      required: true,
    },
  },
  setup(props) {
    const headcount = computed(() => {
      const date = format(props.date, 'yyyy-MM-dd');
      return props.staffShiftOverview.aggregation_result[date]?.[props.time] || null; // 0もnullとして扱う
    });
    const color = computed(() => {
      if (headcount.value === null) {
        return '#fff';
      }
      const r = Math.max(34, 203 - 6 * headcount.value);
      const g = Math.max(40, 227 - 6 * headcount.value);
      const b = Math.max(53, 254 - 6 * headcount.value);
      return `rgb(${r}, ${g}, ${b})`;
    });

    return {
      headcount,
      color,
    };
  },
});
