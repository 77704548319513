import { ref, type Ref } from '@vue/composition-api';
import type { CsvLine } from 'src/util/type_util';
import type { TimeInteger } from 'src/models/common';
import { unpackTimeIntegerToString } from 'src/util/datetime';
import type { StaffSkill } from 'src/models/staffSkill';
import type { StaffDisp } from '../types';
import moment from 'src/util/moment-ja';
import { CSV_HEADERS } from '../consts';

type UseStaffCsvExport = {
  csvData: Ref<CsvLine[]>;
  csvFileName: Ref<string>;
  prepareExport: (staffs: StaffDisp[]) => void;
};

export const useStaffCsvExport = (): UseStaffCsvExport => {
  const csvData = ref<CsvLine[]>([]);
  const csvFileName = ref<string>('');

  const timeIntegerToStringRemoveSeconds = (timeInteger: TimeInteger | null): string => {
    if (timeInteger === null) {
      return '';
    }
    if (isNaN(timeInteger)) {
      return '';
    }
    const [hour, min] = unpackTimeIntegerToString(timeInteger);
    return `${hour}:${min}`;
  };

  const findStaffSkillByPriority = (
    staffSkills: StaffSkill[] | undefined,
    priority: number,
  ): StaffSkill | undefined => {
    return staffSkills?.find((staffSkill) => staffSkill.priority === priority);
  };

  const prepareExport = (staffs: StaffDisp[]): void => {
    if (staffs.length === 0) {
      return;
    }

    csvFileName.value = `staff_extensions_${moment().format('YYYYMMDDHHmmss')}.csv`;

    // initialize data  state.csvData = [] won't work
    csvData.value.splice(-csvData.value.length);
    csvData.value[0] = CSV_HEADERS;

    staffs.forEach((staff) => {
      csvData.value.push([
        staff.staff_number, // '社員番号',
        staff.family_name, // '姓',
        staff.first_name, // '名',
        staff.staff_extension?.gender ?? 0, // 性別
        staff.staff_extension?.is_shift_management_target ? 1 : 0, // シフト管理対象
        staff.staff_extension?.is_appropriation_target ? 1 : 0, // 計上対象
        staff.staff_extension?.memo ?? '', // メモ
        staff.staff_extension?.is_key_player ? 1 : 0, // キープレイヤー
        staff.staff_extension?.is_forkman ? 1 : 0, // フォークマン
        staff.staff_extension?.has_custom_skill1 ? 1 : 0, // カスタムスキル1
        staff.staff_extension?.has_custom_skill2 ? 1 : 0, // カスタムスキル2
        staff.staff_extension?.has_custom_skill3 ? 1 : 0, // カスタムスキル3
        staff.staff_extension?.macro_operation_master?.name ?? '', // シフトグループ
        findStaffSkillByPriority(staff.staff_skills, 1)?.timetable_master?.name ?? '', // 工程順序1
        findStaffSkillByPriority(staff.staff_skills, 2)?.timetable_master?.name ?? '', // 工程順序2
        findStaffSkillByPriority(staff.staff_skills, 3)?.timetable_master?.name ?? '', // 工程順序3
        findStaffSkillByPriority(staff.staff_skills, 4)?.timetable_master?.name ?? '', // 工程順序4
        findStaffSkillByPriority(staff.staff_skills, 5)?.timetable_master?.name ?? '', // 工程順序5
        timeIntegerToStringRemoveSeconds(staff.mon?.work_start_time ?? null), // 月曜出勤時間
        timeIntegerToStringRemoveSeconds(staff.mon?.work_end_time ?? null), // 月曜退勤時間
        timeIntegerToStringRemoveSeconds(staff.mon?.break1_start_time ?? null), // 月曜休憩1開始時間
        timeIntegerToStringRemoveSeconds(staff.mon?.break1_end_time ?? null), // 月曜休憩1終了時間
        timeIntegerToStringRemoveSeconds(staff.mon?.break2_start_time ?? null), // 月曜休憩2開始時間
        timeIntegerToStringRemoveSeconds(staff.mon?.break2_end_time ?? null), // 月曜休憩2終了時間
        staff.mon?.duty1_macro_operation_master?.name ?? '', // 月曜日 優先1 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.mon?.duty1_start_time ?? null), // 月曜日 優先1 開始時間
        timeIntegerToStringRemoveSeconds(staff.mon?.duty1_end_time ?? null), // 月曜日 優先1 終了時間
        staff.mon?.duty2_macro_operation_master?.name ?? '', // 月曜日 優先2 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.mon?.duty2_start_time ?? null), // 月曜日 優先2 開始時間
        timeIntegerToStringRemoveSeconds(staff.mon?.duty2_end_time ?? null), // 月曜日 優先2 終了時間
        timeIntegerToStringRemoveSeconds(staff.tue?.work_start_time ?? null), // 火曜出勤時間
        timeIntegerToStringRemoveSeconds(staff.tue?.work_end_time ?? null), // 火曜退勤時間
        timeIntegerToStringRemoveSeconds(staff.tue?.break1_start_time ?? null), // 火曜休憩1開始時間
        timeIntegerToStringRemoveSeconds(staff.tue?.break1_end_time ?? null), // 火曜休憩1終了時間
        timeIntegerToStringRemoveSeconds(staff.tue?.break2_start_time ?? null), // 火曜休憩2開始時間
        timeIntegerToStringRemoveSeconds(staff.tue?.break2_end_time ?? null), // 火曜休憩2終了時間
        staff.tue?.duty1_macro_operation_master?.name ?? '', // 火曜日 優先1 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.tue?.duty1_start_time ?? null), // 火曜日 優先1 開始時間
        timeIntegerToStringRemoveSeconds(staff.tue?.duty1_end_time ?? null), // 火曜日 優先1 終了時間
        staff.tue?.duty2_macro_operation_master?.name ?? '', // 火曜日 優先2 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.tue?.duty2_start_time ?? null), // 火曜日 優先2 開始時間
        timeIntegerToStringRemoveSeconds(staff.tue?.duty2_end_time ?? null), // 火曜日 優先2 終了時間
        timeIntegerToStringRemoveSeconds(staff.wed?.work_start_time ?? null), // 水曜出勤時間
        timeIntegerToStringRemoveSeconds(staff.wed?.work_end_time ?? null), // 水曜退勤時間
        timeIntegerToStringRemoveSeconds(staff.wed?.break1_start_time ?? null), // 水曜休憩1開始時間
        timeIntegerToStringRemoveSeconds(staff.wed?.break1_end_time ?? null), // 水曜休憩1終了時間
        timeIntegerToStringRemoveSeconds(staff.wed?.break2_start_time ?? null), // 水曜休憩2開始時間
        timeIntegerToStringRemoveSeconds(staff.wed?.break2_end_time ?? null), // 水曜休憩2終了時間
        staff.wed?.duty1_macro_operation_master?.name ?? '', // 水曜日 優先1 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.wed?.duty1_start_time ?? null), // 水曜日 優先1 開始時間
        timeIntegerToStringRemoveSeconds(staff.wed?.duty1_end_time ?? null), // 水曜日 優先1 終了時間
        staff.wed?.duty2_macro_operation_master?.name ?? '', // 水曜日 優先2 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.wed?.duty2_start_time ?? null), // 水曜日 優先2 開始時間
        timeIntegerToStringRemoveSeconds(staff.wed?.duty2_end_time ?? null), // 水曜日 優先2 終了時間
        timeIntegerToStringRemoveSeconds(staff.thu?.work_start_time ?? null), // 木曜出勤時間
        timeIntegerToStringRemoveSeconds(staff.thu?.work_end_time ?? null), // 木曜退勤時間
        timeIntegerToStringRemoveSeconds(staff.thu?.break1_start_time ?? null), // 木曜休憩1開始時間
        timeIntegerToStringRemoveSeconds(staff.thu?.break1_end_time ?? null), // 木曜休憩1終了時間
        timeIntegerToStringRemoveSeconds(staff.thu?.break2_start_time ?? null), // 木曜休憩2開始時間
        timeIntegerToStringRemoveSeconds(staff.thu?.break2_end_time ?? null), // 木曜休憩2終了時間
        staff.thu?.duty1_macro_operation_master?.name ?? '', // 木曜日 優先1 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.thu?.duty1_start_time ?? null), // 木曜日 優先1 開始時間
        timeIntegerToStringRemoveSeconds(staff.thu?.duty1_end_time ?? null), // 木曜日 優先1 終了時間
        staff.thu?.duty2_macro_operation_master?.name ?? '', // 木曜日 優先2 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.thu?.duty2_start_time ?? null), // 木曜日 優先2 開始時間
        timeIntegerToStringRemoveSeconds(staff.thu?.duty2_end_time ?? null), // 木曜日 優先2 終了時間
        timeIntegerToStringRemoveSeconds(staff.fri?.work_start_time ?? null), // 金曜出勤時間
        timeIntegerToStringRemoveSeconds(staff.fri?.work_end_time ?? null), // 金曜退勤時間
        timeIntegerToStringRemoveSeconds(staff.fri?.break1_start_time ?? null), // 金曜休憩1開始時間
        timeIntegerToStringRemoveSeconds(staff.fri?.break1_end_time ?? null), // 金曜休憩1終了時間
        timeIntegerToStringRemoveSeconds(staff.fri?.break2_start_time ?? null), // 金曜休憩2開始時間
        timeIntegerToStringRemoveSeconds(staff.fri?.break2_end_time ?? null), // 金曜休憩2終了時間
        staff.fri?.duty1_macro_operation_master?.name ?? '', // 金曜日 優先1 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.fri?.duty1_start_time ?? null), // 金曜日 優先1 開始時間
        timeIntegerToStringRemoveSeconds(staff.fri?.duty1_end_time ?? null), // 金曜日 優先1 終了時間
        staff.fri?.duty2_macro_operation_master?.name ?? '', // 金曜日 優先2 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.fri?.duty2_start_time ?? null), // 金曜日 優先2 開始時間
        timeIntegerToStringRemoveSeconds(staff.fri?.duty2_end_time ?? null), // 金曜日 優先2 終了時間
        timeIntegerToStringRemoveSeconds(staff.sat?.work_start_time ?? null), // 土曜出勤時間
        timeIntegerToStringRemoveSeconds(staff.sat?.work_end_time ?? null), // 土曜退勤時間
        timeIntegerToStringRemoveSeconds(staff.sat?.break1_start_time ?? null), // 土曜休憩1開始時間
        timeIntegerToStringRemoveSeconds(staff.sat?.break1_end_time ?? null), // 土曜休憩1終了時間
        timeIntegerToStringRemoveSeconds(staff.sat?.break2_start_time ?? null), // 土曜休憩2開始時間
        timeIntegerToStringRemoveSeconds(staff.sat?.break2_end_time ?? null), // 土曜休憩2終了時間
        staff.sat?.duty1_macro_operation_master?.name ?? '', // 土曜日 優先1 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.sat?.duty1_start_time ?? null), // 土曜日 優先1 開始時間
        timeIntegerToStringRemoveSeconds(staff.sat?.duty1_end_time ?? null), // 土曜日 優先1 終了時間
        staff.sat?.duty2_macro_operation_master?.name ?? '', // 土曜日 優先2 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.sat?.duty2_start_time ?? null), // 土曜日 優先2 開始時間
        timeIntegerToStringRemoveSeconds(staff.sat?.duty2_end_time ?? null), // 土曜日 優先2 終了時間
        timeIntegerToStringRemoveSeconds(staff.sun?.work_start_time ?? null), // 日曜出勤時間
        timeIntegerToStringRemoveSeconds(staff.sun?.work_end_time ?? null), // 日曜退勤時間
        timeIntegerToStringRemoveSeconds(staff.sun?.break1_start_time ?? null), // 日曜休憩1開始時間
        timeIntegerToStringRemoveSeconds(staff.sun?.break1_end_time ?? null), // 日曜休憩1終了時間
        timeIntegerToStringRemoveSeconds(staff.sun?.break2_start_time ?? null), // 日曜休憩2開始時間
        timeIntegerToStringRemoveSeconds(staff.sun?.break2_end_time ?? null), // 日曜休憩2終了時間
        staff.sun?.duty1_macro_operation_master?.name ?? '', // 日曜日 優先1 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.sun?.duty1_start_time ?? null), // 日曜日 優先1 開始時間
        timeIntegerToStringRemoveSeconds(staff.sun?.duty1_end_time ?? null), // 日曜日 優先1 終了時間
        staff.sun?.duty2_macro_operation_master?.name ?? '', // 日曜日 優先2 シフトグループ
        timeIntegerToStringRemoveSeconds(staff.sun?.duty2_start_time ?? null), // 日曜日 優先2 開始時間
        timeIntegerToStringRemoveSeconds(staff.sun?.duty2_end_time ?? null), // 日曜日 優先2 終了時間
      ]);
    });
  };

  return {
    csvData,
    csvFileName,
    prepareExport,
  };
};
