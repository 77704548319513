





























import { defineComponent, computed } from '@vue/composition-api'
import { orDefault } from 'src/util/filters'
import { getProgressRate, getProgressColor } from 'src/util/progressHeaderHelpers'
import { EditableNumber } from '../../components/EditableNumber'
import { useProgressHeader } from '../../composables/useProgressHeader'
import { useUpdateScheduledQuantity } from '../../composables/useUpdateScheduledQuantity'
import { formatNumber, formatFixedDecimal } from '../../utils/filters'
import HeaderItem from './HeaderItem.vue'

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
    formatFixedDecimal,
  },
  components: {
    EditableNumber,
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader()
    const progressRate = computed(() => getProgressRate(progressHeader.value))
    const progressColor = computed(() => getProgressColor(progressHeader.value))
    const { updateScheduledQuantity } = useUpdateScheduledQuantity()

    return {
      progressHeader,
      progressRate,
      progressColor,
      updateScheduledQuantity,
    }
  },
})
