import axios from 'axios';
import { getApiUrl } from './apiUtils';
import { StringOrNumber } from 'src/models/common';
import { StaffExperienceOverviewParams } from 'src/models/api/staffExperienceOverviewRequest';
import { StaffExperienceOverview, convertStaffExperienceOverviewResponse } from 'src/models/staffExperienceOverview';

const getResourceBaseUrl = (workplaceId: StringOrNumber, budget_group_id: StringOrNumber): string => {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budget_group_id}`);
};

export default {
  async index(params: StaffExperienceOverviewParams): Promise<StaffExperienceOverview> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.get(
      `${getResourceBaseUrl(
        workplace_id,
        budget_group_id,
      )}/staff_daily_summary_by_timetable_masters/staff_experience_overview_view`,
      {
        params: paramsRest,
      },
    );

    return convertStaffExperienceOverviewResponse(data);
  },
};
