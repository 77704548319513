

















import { defineComponent, computed, type PropType } from '@vue/composition-api';
import { type TimetableActivityMaster } from 'src/models/timetableActivityMaster';

type Option = {
  label: string;
  value: number;
};

function getOption(timetableActivityMaster: TimetableActivityMaster): Option {
  const label = `${timetableActivityMaster.budget_unit.name} ${timetableActivityMaster.name}`;
  return {
    label,
    value: timetableActivityMaster.id,
  };
}

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<number[]>,
      required: true,
    },
    timetableActivityMasters: {
      type: Array as PropType<TimetableActivityMaster[]>,
      required: true,
    },
  },
  setup(props, context) {
    const options = computed(() =>
      props.timetableActivityMasters.flatMap((timetableActivityMaster) => [getOption(timetableActivityMaster)]),
    );
    const values = computed(() => props.value.toSorted());
    const handleInput = (values: number[]) => {
      context.emit('input', values);
    };

    return {
      values,
      options,
      handleInput,
    };
  },
});
