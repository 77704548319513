import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import {
  UpdateOptParams
} from 'src/models/api/staffAgencyExtensionRequest'
import {
  StaffAgencyExtension,
  convertStaffAgencyExtensionResponse
} from 'src/models/staffAgencyExtension'
import { convertStaffAgencyResponse, StaffAgency } from 'src/models/staffAgency'

export default {
  async staff_agencies({ workplaceId }: { workplaceId: StringOrNumber }): Promise<StaffAgency[]> {
    const { data } = await axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_agency_extensions/staff_agencies`))
    return data.map(convertStaffAgencyResponse)
  },
  async update({ workplaceId, data }: { workplaceId: StringOrNumber, data: UpdateOptParams }): Promise<StaffAgencyExtension> {
    const { data: reqData } = await axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_agency_extensions`), data)
    return convertStaffAgencyExtensionResponse(reqData)
  }
}
