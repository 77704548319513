import { type ShiftPatternIndexResponse } from 'src/models/api/workplaceMasters/shiftPatternResponse'

export type ShiftPattern = {
  id: number
  budget_group_id: number
  name: string
  disp_order: number
  is_enabled: boolean
  start_time: number
  end_time: number
  break1_start_time: number | null
  break1_end_time: number | null
  break2_start_time: number | null
  break2_end_time: number | null
}

export function convertShiftPatternIndexResponse(response: ShiftPatternIndexResponse): ShiftPattern[] {
  return response
}
