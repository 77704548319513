






















import { defineComponent } from '@vue/composition-api'
import { IconButton } from 'src/components/UIComponents/Buttons/IconButton'

export default defineComponent({
  components: {
    IconButton,
  },
  props: {
    onEdit: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
  },
})
