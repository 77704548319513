


























import { defineComponent, computed } from '@vue/composition-api';
import { EditableTime } from 'src/components/UIComponents/Inputs/EditableTime';
import { orDefault } from 'src/util/filters';
import { getProgressColor } from 'src/util/progressHeaderHelpers';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { useUpdateTargetCompletionTime } from '../../composables/useUpdateTargetCompletionTime';
import { formatNumber } from '../../utils/filters';
import HeaderItem from './HeaderItem.vue';

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
  },
  components: {
    EditableTime,
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader();
    const progressColor = computed(() => getProgressColor(progressHeader.value));
    const { updateTargetCompletionTime } = useUpdateTargetCompletionTime();

    return {
      progressHeader,
      progressColor,
      updateTargetCompletionTime,
    };
  },
});
