























import { defineComponent } from '@vue/composition-api'
import { PanelList, PanelListItem } from '../PanelList'
import { ActivityStatusPanel } from '../ActivityStatusPanel'
import { AttendanceStatusPanel } from '../AttendanceStatusPanel'
import { TimetablePanel } from '../TimetablePanel'
import { useProgressBoard } from '../../composables/useProgressBoard'

export default defineComponent({
  components: {
    PanelList,
    PanelListItem,
    ActivityStatusPanel,
    AttendanceStatusPanel,
    TimetablePanel,
  },
  setup() {
    const { progressBoard, filterTimetableHeaders } = useProgressBoard()

    return {
      progressBoard,
      filterTimetableHeaders,
    }
  },
})
