






















import { defineComponent, PropType } from '@vue/composition-api';
import { StaffLabel } from 'src/models/staffLabel';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<StaffLabel | null>,
      default: null,
    },
    staffLabels: {
      type: Array as PropType<StaffLabel[]>,
      required: true,
    },
  },
});
