


















import { defineComponent, ref, computed, onMounted, type PropType, type Ref } from '@vue/composition-api'
import type Chart from 'chart.js'
import { type ProgressHeader } from 'src/models/progressHeader'
import { type ComponentProps } from 'src/util/type_util'
import { formatNumber } from '../../utils/filters'
import { LineChart } from '../LineChart'
import YAxisTicks from './YAxisTicks.vue'
import { buildProgressChartConfiguration } from './buildProgressChartConfiguration'

type YAxisTicksProps = ComponentProps<typeof YAxisTicks>
type ExtendedChart = Chart & {
  // 型が足りないので使うものだけ追加
  scales: Record<string, {
    height: number,
    paddingTop: number,
    ticksAsNumbers: number[],
  }>
}

function buildInitialYAxisTicksProps({ label, position }: Pick<YAxisTicksProps, 'label' | 'position'>): YAxisTicksProps {
  return {
    height: 0,
    label,
    paddingTop: 0,
    position,
    values: [],
  }
}

function formatYAxisTicksValue(value: number): string {
  return value !== 0 ? (formatNumber(value) ?? '') : ''
}

function updateYAxisTicksProps(props: Ref<YAxisTicksProps>, chart: ExtendedChart): void {
  const scaleId = chart.options.scales?.yAxes?.find((v) => v.position === props.value.position)?.id ?? ''
  const scale = chart.scales[scaleId]

  if (scale) {
    props.value = {
      ...props.value,
      height: scale.height + 1, // +1は表示に基づく調整。下の-0.5も同様。表示がずれる原因は未確認
      paddingTop: scale.paddingTop - 0.5,
      // ある範囲の値は表示しない
      // buildProgressChartConfiguration.tsのcalculateScaleBoundsを参照
      values: scale.ticksAsNumbers.filter((v) => !(v > 0 && v < 0.1)).map(formatYAxisTicksValue),
    }
  }
}

export default defineComponent({
  components: {
    LineChart,
    YAxisTicks,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup(props) {
    const chartContainer = ref<HTMLDivElement | null>(null)
    const chartConfig = computed(() => buildProgressChartConfiguration(props.progressHeader))
    const leftTicks = ref<YAxisTicksProps>(buildInitialYAxisTicksProps({ label: '（個）', position: 'left' }))
    const rightTicks = ref<YAxisTicksProps>(buildInitialYAxisTicksProps({ label: '（個/人時）', position: 'right' }))
    const handleBeforeRender = (chart: ExtendedChart) => {
      updateYAxisTicksProps(leftTicks, chart)
      updateYAxisTicksProps(rightTicks, chart)
    }

    onMounted(() => {
      if (chartContainer.value !== null) {
        const margin = 32 // チャートの左右の余白
        const widthPerHour = (chartContainer.value.scrollWidth - margin * 2) / 48
        chartContainer.value.scrollLeft = margin + widthPerHour * 7.5 // 8-20時が表示されるようにスクロール
      }
    })

    return {
      chartContainer,
      chartConfig,
      leftTicks,
      rightTicks,
      handleBeforeRender,
    }
  },
})
