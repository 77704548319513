import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import {
  IndexOptParams,
  CreateOptParams,
  UpdateOptParams,
  BulkUpdateOptParams
} from 'src/models/api/timetableLabelRequest'
import { convertTimetableLabelResponse, TimetableLabel } from 'src/models/timetableLabel'

export default {
  async index({ workplaceId, params }: { workplaceId: StringOrNumber, params: IndexOptParams }): Promise<TimetableLabel[]> {
    const { data } = await axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_labels`), {
      params: params || {}
    })
    return data.map(convertTimetableLabelResponse)
  },
  async create({ workplaceId, data }: { workplaceId: StringOrNumber, data: CreateOptParams }): Promise<TimetableLabel> {
    const { data: reqData } = await axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_labels`), data)
    return convertTimetableLabelResponse(reqData)
  },
  async update({ workplaceId, data }: { workplaceId: StringOrNumber, data: UpdateOptParams }): Promise<TimetableLabel> {
    const { data: reqData } = await axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_labels/${data.id}`), data)
    return convertTimetableLabelResponse(reqData)
  },
  async delete({ workplaceId, itemId }: { workplaceId: StringOrNumber, itemId: StringOrNumber }): Promise<TimetableLabel> {
    const { data } = await axios.delete(utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_labels/${itemId}`))
    return convertTimetableLabelResponse(data)
  },
  async bulkUpdateDispOrder({ workplaceId, items }: { workplaceId: StringOrNumber, items: BulkUpdateOptParams[] }): Promise<TimetableLabel[]> {
    const { data } = await axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/timetable_labels/bulk_update_disp_order`),
      { items: items }
    )
    return data.map(convertTimetableLabelResponse)
  },
}
