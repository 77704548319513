
import {
  defineComponent,
  reactive,
  SetupContext,
  onMounted,
  getCurrentInstance,
  computed,
} from '@vue/composition-api';
import ListSwitchPanel from '../ListSwitchPanel.vue'
import budgetGroupExtensionApi from 'src/apis/workplace_masters/budget_group_extension';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { BudgetGroup, constructEmptyBudgetGroup } from 'src/models/budgetGroup';
import { BudgetGroupExtension } from 'src/models/budgetGroupExtension';
import { BudgetGroupsOptParams } from 'src/models/api/budgetGroupExtensionRequest';
import StaffExtensionContent from './StaffExtension/index.vue';
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook';

const displayPageName = '管理グループ設定';

interface State {
  budgetGroups: BudgetGroup[],
  saveBudgetGroupExtensionCandidate: Partial<BudgetGroupExtension>,
  displayListDisabledItem: boolean,
  selectedBudgetGroupId: number | null,
  selectedBudgetGroup: BudgetGroup,
  showBudgetGroupeModal: boolean
}

function setupState(): State {
  return reactive({
    budgetGroups: [],
    saveBudgetGroupExtensionCandidate: {},
    displayListDisabledItem: false,
    selectedBudgetGroupId: null,
    selectedBudgetGroup: constructEmptyBudgetGroup(),
    showBudgetGroupeModal: false
  });
}

export default defineComponent({
  name: 'budget-group-extension',
  setup(_props, _context: SetupContext) {
    const vueInstance = getCurrentInstance()?.proxy.$root!;
    const state = setupState();

    const workplaceId = computed(() => {
      return vueInstance.$route.params.workplaceId;
    });

    // リスト取得
    async function getList(): Promise<void> {
      const params: BudgetGroupsOptParams = {}
      if (!state.displayListDisabledItem) {
        params.is_enabled = true
      }

      // 管理グループ取得
      state.budgetGroups = (await budgetGroupExtensionApi.budgetGroups({
        workplaceId: workplaceId.value,
        params: params
      }))
    }

    async function getListWithUpdatedSearchParams(params: { displayDisabledModel: boolean }): Promise<void> {
      state.displayListDisabledItem = params.displayDisabledModel
      await getList()
    }

    // 管理グループ編集モーダルopen
    function openBudgetGroupModal(item: BudgetGroup): void {
      state.selectedBudgetGroup = item;
      state.showBudgetGroupeModal = true;
    }

    // 管理グループ編集モーダルclose
    function closeBudgetGroupModal(): void {
      state.selectedBudgetGroup = constructEmptyBudgetGroup();
      state.showBudgetGroupeModal = false;
    }

    // 管理グループ更新
    async function updateBudgetGroup() {
      try {
        await budgetGroupExtensionApi.update({
          workplaceId: workplaceId.value,
          data: state.selectedBudgetGroup,
        })
        await getList()
        closeBudgetGroupModal()
        notifySuccess1(vueInstance, `${displayPageName}を更新しました`)
      } catch (err: any) {
        notifyError1(vueInstance, `${displayPageName}の更新に失敗しました`, { timeout: 5 * 1000 })
      }
    }

    onMounted(async() => {
      setPageName(vueInstance, displayPageName);
      await getList();
    })

    return {
      displayPageName,
      state,
      // methods
      getList,
      getListWithUpdatedSearchParams,
      updateBudgetGroup,
      openBudgetGroupModal,
      closeBudgetGroupModal
    }
  },
  components: { ListSwitchPanel, StaffExtensionContent },
});
