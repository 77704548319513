import axios, { AxiosResponse } from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'

import {
  CreateOptParams
} from 'src/models/api/planProgressAllocationHistoryRequest'
import {
  PlanProgressAllocationHistoryResponse
} from 'src/models/api/planProgressAllocationHistoryResponse'

export default {
  index({ workplaceId, timetableId } : {workplaceId : StringOrNumber, timetableId: StringOrNumber}): Promise<AxiosResponse<PlanProgressAllocationHistoryResponse[]>> {
    return axios.get(utils.getApiUrl(`/workplaces/${workplaceId}/timetables/${timetableId}/plan_progress_allocation_histories`))
  },
  create({ workplaceId, timetableId, data } : { workplaceId: StringOrNumber, timetableId: StringOrNumber, data: CreateOptParams }) {
    return axios.post(utils.getApiUrl(`/workplaces/${workplaceId}/timetables/${timetableId}/create_plan_progress_allocation_history`), data)
  },
  delete({ workplaceId, timetableId, itemId } : { workplaceId: StringOrNumber, timetableId: StringOrNumber, itemId: StringOrNumber }) {
    return axios.delete(utils.getApiUrl(`/workplaces/${workplaceId}/timetables/${timetableId}/destroy_plan_progress_allocation_history/${itemId}`))
  }
}
