import { Validator, useCustomValidator } from 'src/composables/useCustomValidator';

const NUMBER_STARTING_FROM_0 = /^(0{1}\d+)(\.\d*)?$/;

const scheduledQuantityValidator: Validator = {
  name: 'scheduledQuantityValidator',
  validate(value: string) {
    if (NUMBER_STARTING_FROM_0.test(value)) {
      return false;
    }
    return /^([0-9]{0,10})(\.\d)?$/.test(value);
  },
};

const planedProductivityValidator: Validator = {
  name: 'planedProductivityValidator',
  validate(value: string) {
    if (NUMBER_STARTING_FROM_0.test(value)) {
      return false;
    }
    return /^([0-9]{1,5})(\.\d)?$/.test(value);
  },
};
const headcountValidator: Validator = {
  name: 'headcountValidator',
  validate(value: string) {
    if (NUMBER_STARTING_FROM_0.test(value)) {
      return false;
    }
    return /^[0-9]{1,4}$/.test(value);
  },
};

export function useDailySimulationValidationRules() {
  return {
    scheduledQuantityValidationRule: useCustomValidator(scheduledQuantityValidator),
    planedProductivityValidationRule: `required|${useCustomValidator(planedProductivityValidator)}`,
    headcountValidationRule: `required|${useCustomValidator(headcountValidator)}`,
  };
}
