













import { defineComponent, toRef, type PropType } from '@vue/composition-api'
import { useTruncatedText } from '../../composables/useTruncatedText'

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true,
    },
    labelColor: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  setup(props) {
    const { elementRef } = useTruncatedText({ text: toRef(props, 'label') })

    return {
      elementRef,
    }
  },
})
