import { MacroOperationMasterResponse } from 'src/models/api/macroOperationMasterResponse';
import { MacroOperationMasterLogimeterActivityMaster } from './api/macroOperationMasterLogimeterActivityMasterResponse';
import { MacroOperationSegmentMaster } from './macroOperationSegmentMaster';
import { isExist } from 'src/util/isExist';

export type MacroOperationMaster = {
  id: number;
  budget_group_id: number;
  name: string;
  is_enabled: boolean;
  disp_order: number;
  disp_color: string;
  quantity: number;
  productivity: number | null;
  is_quantity_save: boolean;
  is_working_hours_save: boolean;
  is_segment_used: boolean;
  macro_operation_master_logimeter_activity_masters: MacroOperationMasterLogimeterActivityMaster[];
  macro_operation_segment_masters: MacroOperationSegmentMaster[];
};

export function convertMacroOperationMasterResponse(response: MacroOperationMasterResponse): MacroOperationMaster {
  return {
    ...response,
    productivity: isExist(response.productivity) ? Number(response.productivity) : null,
  };
}
