import { ref, onMounted, type Ref } from '@vue/composition-api';
import macroOperationMasterApi from 'src/apis/workplace_masters/macro_operation_master';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type MacroOperationMaster } from 'src/models/macroOperationMaster';
import { createInjection } from 'src/util/createInjection';

type Params = {
  workplaceId: number;
};

type InjectionValue = {
  macroOperationMasters: Ref<MacroOperationMaster[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useMacroOperationMasters');

export function useMacroOperationMastersProvider({ workplaceId }: Params): void {
  const errorBoundary = useErrorBoundary();
  const macroOperationMasters = ref<MacroOperationMaster[]>([]);
  const fetchMacroOperationMasters = errorBoundary.wrap(
    async () => {
      macroOperationMasters.value = await macroOperationMasterApi.getSubWorkplaceList({
        workplaceId,
        params: { is_enabled: true },
      });
    },
    {
      fallbackMessage: 'シフトグループマスタの取得に失敗しました',
    },
  );

  onMounted(fetchMacroOperationMasters);

  provide({
    macroOperationMasters,
  });
}

export function useMacroOperationMasters(): InjectionValue {
  return inject();
}
