import { ref, type Ref } from '@vue/composition-api';
import Encoding from 'encoding-japanese';

type CsvRow = (string | number)[];

type MaybePromise<T> = T | Promise<T>;

type Params = {
  prepare: () => MaybePromise<{
    fileName: string;
    data: CsvRow[];
  }>;
  useQuotes?: boolean;
};

type Result = {
  isLoading: Ref<boolean>;
  downloadCsv: () => Promise<void>;
};

export { CsvRow };

export function useDownloadCsv({ prepare, useQuotes = false }: Params): Result {
  const isLoading = ref(false);
  const downloadCsv = async () => {
    if (isLoading.value) {
      return;
    }

    try {
      isLoading.value = true;
      // not using emit to handle promise!
      const { fileName, data } = await prepare();
      if (data.length === 0) {
        return;
      }
      let csvContents: string = '';
      data.forEach((r) => {
        let line: string = '';
        r.forEach((c) => {
          line += useQuotes ? `"${c}",` : `${c},`;
        });
        // remove last ',' before adding line change code
        csvContents += line.slice(0, -1) + '\r\n';
      });
      // remove last line change
      csvContents = csvContents.trim();
      // https://note.com/nowbuilding/n/n05702862f575
      const convertedToUnicode = Encoding.stringToCode(csvContents);
      const convertedToSjis = Encoding.convert(convertedToUnicode, 'SJIS', 'UNICODE');
      const convertedToBinary = new Uint8Array(convertedToSjis);
      const blob = new Blob([convertedToBinary], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    downloadCsv,
  };
}
