

























import { defineComponent, type PropType } from '@vue/composition-api'
import { type TimetableHeader } from 'src/models/timetableHeader'
import { type ActivitySummary } from 'src/models/progressBoard'
import { CardPanel } from '../CardPanel'
import DisplayedTime from './DisplayedTime.vue'
import Prediction from './Prediction.vue'
import ProgressChart from './ProgressChart.vue'
import ProgressStatus from './ProgressStatus.vue'
import HeadCountsByLabel from './HeadCountsByLabel.vue'

export default defineComponent({
  components: {
    CardPanel,
    DisplayedTime,
    Prediction,
    ProgressChart,
    ProgressStatus,
    HeadCountsByLabel,
  },
  props: {
    timetableHeader: {
      type: Object as PropType<TimetableHeader>,
      required: true,
    },
    activitySummaries: {
      type: Array as PropType<ActivitySummary[]>,
      required: true,
    },
  },
})
