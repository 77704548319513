


















import { defineComponent, PropType, ref, SetupContext } from '@vue/composition-api';
import { MonthRange } from 'src/models/staffExperienceOverview';
import { getMonthRangeOnMonthChange } from '../../libs/dateGenerator';
import { formatDate } from 'src/util/datetime';

export default defineComponent({
  props: {
    value: {
      type: Object as PropType<MonthRange>,
      required: true,
    },
  },
  setup(props, context: SetupContext) {
    const startMonth = ref<string>(props.value.startMonth);
    const endMonth = ref<string>(props.value.endMonth);

    const updateStartDate = (newMonth: Date): void => {
      const newMonthRange = getMonthRangeOnMonthChange(formatDate(newMonth, 'yyyy-MM-dd'), endMonth.value, true);
      startMonth.value = newMonthRange.startMonth;
      endMonth.value = newMonthRange.endMonth;
      context.emit('input', { ...newMonthRange });
    };

    const updateEndDate = (newMonth: Date): void => {
      const newMonthRange = getMonthRangeOnMonthChange(formatDate(newMonth, 'yyyy-MM-dd'), startMonth.value, false);
      startMonth.value = newMonthRange.startMonth;
      endMonth.value = newMonthRange.endMonth;
      context.emit('input', { ...newMonthRange });
    };

    return {
      startMonth,
      endMonth,
      updateStartDate,
      updateEndDate,
    };
  },
});
