import axios, { type AxiosResponse } from 'axios'
import * as utils from 'src/apis/apiUtils'
import { type ProgressBoardIndexParams } from 'src/models/api/progressBoardRequest'
import { type ProgressBoardIndexResponse } from 'src/models/api/progressBoardResponse'

export default {
  index({ workplaceId, budgetGroupId, ...params }: ProgressBoardIndexParams): Promise<AxiosResponse<ProgressBoardIndexResponse>> {
    return axios.get(utils.getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/progress_board`), {
      params
    })
  }
}
