









































import { defineComponent } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useProgressBoard } from '../../composables/useProgressBoard';

export default defineComponent({
  components: {
    BudgetGroupSelect,
    PrimaryButton,
    ToggleSwitch,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups();
    const {
      dt,
      budgetGroup,
      canSearch,
      canUpdate,
      lastUpdatedAt,
      productivityGraph,
      hidesCompletedTimetableHeaders,
      fetchProgressBoard,
    } = useProgressBoard();

    return {
      budgetGroups,
      dt,
      budgetGroup,
      canSearch,
      canUpdate,
      lastUpdatedAt,
      productivityGraph,
      hidesCompletedTimetableHeaders,
      fetchProgressBoard,
    };
  },
});
