import { ref, unref, readonly, watch, type Ref, type DeepReadonly } from '@vue/composition-api'
import shiftPatternApi from 'src/apis/workplace_masters/shiftPattern'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { convertShiftPatternIndexResponse, type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'
import { createInjection } from 'src/util/createInjection'

type InjectionValue = {
  shiftPatterns: DeepReadonly<Ref<ShiftPattern[]>>
  budgetGroup: Ref<BudgetGroup | null>
  includesDisabled: Ref<boolean>
  isLoading: Ref<boolean>
  fetchShiftPatterns: () => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useShiftPatterns')

async function getShiftPatterns({
  budgetGroup,
  includesDisabled,
}: {
  budgetGroup: BudgetGroup
  includesDisabled: boolean
}): Promise<ShiftPattern[]> {
  const { data } = await shiftPatternApi.index({
    workplace_id: budgetGroup.workplace_id,
    budget_group_id: budgetGroup.id,
    ...(includesDisabled ? {} : { is_enabled: true }),
  })
  return convertShiftPatternIndexResponse(data)
}

export function useShiftPatternsProvider(): void {
  const errorBoundary = useErrorBoundary()
  const shiftPatterns = ref<ShiftPattern[]>([])
  const budgetGroup = ref<BudgetGroup | null>(null)
  const includesDisabled = ref(false)
  const isLoading = ref(false)
  const fetchShiftPatterns = errorBoundary.wrap(
    async() => {
      const unwrappedBudgetGroup = unref(budgetGroup)
      const unwrappedIncludesDisabled = unref(includesDisabled)
      if (unwrappedBudgetGroup !== null) {
        isLoading.value = true
        try {
          shiftPatterns.value = await getShiftPatterns({
            budgetGroup: unwrappedBudgetGroup,
            includesDisabled: unwrappedIncludesDisabled,
          })
        } finally {
          isLoading.value = false
        }
      }
    },
    {
      fallbackMessage: '表示情報の取得に失敗しました',
    }
  )

  watch([budgetGroup, includesDisabled], fetchShiftPatterns)

  provide({
    shiftPatterns: readonly(shiftPatterns),
    budgetGroup,
    includesDisabled,
    isLoading: readonly(isLoading),
    fetchShiftPatterns,
  })
}

export function useShiftPatterns(): InjectionValue {
  return inject()
}
