import Vue from 'vue'
import Draggable from 'vuedraggable'
import { defineComponent, SetupContext, reactive, onMounted } from '@vue/composition-api'
import { apisWithTransformedData as budgetGroupApi } from 'src/apis/budget_group'
import staffAgencyApi from 'src/apis/staffAgency'
import rescueAdjustmentApi from 'src/apis/rescueAdjustment'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { ensureUserAndMasters } from 'src/hooks/masterHook';
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook';
import { wrappedMapGetters } from 'src/hooks/storeHook'
import ShiftDate from 'src/components/Workplace/ShiftDate.vue';
import HeadcountMoveModal from './HeadcountMoveModal/index.vue'
import PlusMinusValue from './PlusMinusValue/index.vue'
import { headcountMoveModalHooks } from './HeadcountMoveModal/script'
import { RescueAdjustmentBudgetGroup, RescueAdjustmentData, RescueAdjustmentDragState, RescueAdjustmentStaffAgency, RescueAdjustmentState, ResuceAdjustmentTotalData } from './types'
import { formatDate, parseYmdDate } from 'src/util/datetime'
import { isExist } from 'src/util/isExist'
import { RescueAdjustmentIndexResult } from 'src/models/rescueAdjustment'
import { addDays, differenceInDays } from 'date-fns'
import { DayBlockNumber, RescueAdjustmentStatusFromType, RESCUE_ADJUSTMENT_MEMO_OWNER_TYPE, RESCUE_ADJUSTMENT_STATUS_FROM_TYPE } from 'src/consts'
import { WorkplaceExtension } from 'src/models/workplaceExtension'
import {
  RescueAdjustmentMemoForBulkUpdate, RescueAdjustmentStatusForBulkUpdate, RescueAgencyOrderForBulkUpdate
} from 'src/models/api/rescueAdjustmentRequest'

const DISPLAY_DATE_LENGTH = 7

const today = new Date()
today.setHours(0, 0, 0, 0)

const setupState = (context: SetupContext): RescueAdjustmentState => {
  const root = context.root as Vue
  const userId = wrappedMapGetters(root.$store, 'user', ['id']).id;
  const state: RescueAdjustmentState = reactive({
    ...wrappedMapGetters(root.$store, 'workplace', [
      'workplaceExtension',
    ]),
    userId,
    workplaceId: root.$route.params.workplaceId,
    targetDate: today,
    targetDates: [],
    totalData: createTotalData(),
    budgetGroups: [],
    staffAgencies: [],
    lastMemo: '',
    lastOrder: 0,
    lastConfirm: 0,
    isChanged: false,
  })
  return state
}

const setupDragState = () => {
  const dragState: RescueAdjustmentDragState = reactive({
    draggedType: RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP,
    draggedIndex: 0,
    rescueAdjustmentIndex: 0,
    blockNum: 1,
    targetBudgetGroupIndex: 0,
    isToStaffAgency: false,
  })
  return dragState
}

export default defineComponent({
  components: {
    Draggable,
    HeadcountMoveModal,
    PlusMinusValue,
    ShiftDate,
  },
  setup(_, context: SetupContext) {
    const root = context.root as Vue
    setPageName(root, '応援調整')
    const state = setupState(context)
    const dragState = setupDragState()

    onMounted(async() => {
      await ensureUserAndMasters(context)
      const workplace_id = state.workplaceId
      try {
        const budgetGroups = await budgetGroupApi.index(workplace_id)
        state.budgetGroups = budgetGroups.map(budgetGroup => createBudgetGroup(budgetGroup))
      } catch (err: any) {
        notifyError1(root, '管理グループの取得に失敗しました', { err })
        return
      }
      try {
        const staffAgencies = await staffAgencyApi.index({ workplace_id })
        state.staffAgencies = staffAgencies.map(staffAgency => createStaffAgency(staffAgency))
      } catch (err: any) {
        notifyError1(root, '派遣会社の取得に失敗しました', { err })
        return
      }

      resetRescueAdjustment()
    })

    const resetRescueAdjustment = async() => {
      let rescueAdjustmentResponse: RescueAdjustmentIndexResult
      try {
        rescueAdjustmentResponse = await rescueAdjustmentApi.rescueAdjustmentIndex({
          workplace_id: state.workplaceId,
          start_date: state.targetDate,
          end_date: addDays(state.targetDate, 6),
        })
      } catch (err: any) {
        notifyError1(root, '表示情報の取得に失敗しました', { err })
        return
      }
      state.targetDates = new Array(DISPLAY_DATE_LENGTH).fill('').map((_, i) => `${formatDate(addDays(state.targetDate, i), 'yyyy-MM-dd')}`)
      state.staffAgencies = createStaffAgenciesFromApiResponse(rescueAdjustmentResponse, state)
      state.budgetGroups = createBudgetGroupsFromApiResponse(rescueAdjustmentResponse, state)
      state.totalData = createTotalDataFromApiResponse(rescueAdjustmentResponse, state)
      updateTotalData()
      state.isChanged = false
    }

    /**
     * 「全体」情報の更新を行う
     */
    const updateTotalData = () => {
      const { headcounts, rescueDemands, rescueAdjustments } = createTotalData()
      state.totalData.headcounts = headcounts
      state.totalData.rescueDemands = rescueDemands
      state.totalData.rescueAdjustments = rescueAdjustments
      state.budgetGroups.forEach(budgetGroup => {
        for (let i = 0; i < DISPLAY_DATE_LENGTH; i++) {
          state.totalData.headcounts[i].block1 += budgetGroup.headcounts[i].block1
          state.totalData.headcounts[i].block2 += budgetGroup.headcounts[i].block2
          state.totalData.rescueDemands[i].block1 += budgetGroup.rescueDemands[i].block1
          state.totalData.rescueDemands[i].block2 += budgetGroup.rescueDemands[i].block2
          state.totalData.rescueAdjustments[i].block1 += budgetGroup.rescueDemands[i].block1 - budgetGroup.rescueAdjustments[i].block1
          state.totalData.rescueAdjustments[i].block2 += budgetGroup.rescueDemands[i].block2 - budgetGroup.rescueAdjustments[i].block2
        }
      })
    }

    const buildRescueAdjustmentMemoSaveParams = (): RescueAdjustmentMemoForBulkUpdate[] => {
      return state.totalData.memos.reduce<RescueAdjustmentMemoForBulkUpdate[]>((memos, memo, i) => {
        if (memo == null) { return memos }
        memos.push({
          dt: addDays(state.targetDate, i),
          memo_owner_type: RESCUE_ADJUSTMENT_MEMO_OWNER_TYPE.WORKPLACE,
          memo_owner_id: Number(state.workplaceId),
          memo: memo,
        })
        return memos
      }, [])
    }

    const getRescueAdjustmentStatusUniqueStr = (e: RescueAdjustmentStatusForBulkUpdate): string => {
      return `${formatDate(e.dt, 'yyyyMMdd')}#${e.day_block_number}#${e.from_type}#${e.from_id}#${e.to_id}`
    }

    const getRescueAdjustmentStatusMergeOperationSet = (e: RescueAdjustmentStatusForBulkUpdate): [
      string, RescueAdjustmentStatusForBulkUpdate, string, RescueAdjustmentStatusForBulkUpdate
    ] => {
      const yyyymmdd = formatDate(e.dt, 'yyyy-MM-dd')
      const fromType = e.from_type
      const fromId = e.from_id
      const toType = RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP // budgetGroupへの移動しか許可されていない
      const toId = e.to_id

      const key1 = `${yyyymmdd}#${e.day_block_number}#${fromType}#${fromId}#${toType}#${toId}`
      const elem1 = { ...e }

      // fromとtoをひっくり返したものも作る
      const key2 = `${yyyymmdd}#${e.day_block_number}#${toType}#${toId}#${fromType}#${fromId}`
      const elem2 = {
        ...e,
        from_type: RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP, // toTypeは常にbudgetGroup
        from_id: e.to_id,
        to_id: e.from_id,
        headcount: -e.headcount,
      }

      return [key1, elem1, key2, elem2]
    }

    const mergeRescueAdjustmentStatuses = (input: Record<string, RescueAdjustmentStatusForBulkUpdate[]>): RescueAdjustmentStatusForBulkUpdate[] => {
      const ret: RescueAdjustmentStatusForBulkUpdate[] = []
      Object.entries(input).forEach(([key, statuses]) => {
        const [yyyymmdd, dayBlockNumber, fromType, fromId, toType, toId] = key.split('#')
        const headcount = statuses.reduce((acc, e) => acc + e.headcount, 0)
        let elem1: RescueAdjustmentStatusForBulkUpdate
        if (headcount >= 0) {
          elem1 = {
            dt: parseYmdDate(yyyymmdd),
            from_type: Number(fromType) as RescueAdjustmentStatusFromType,
            from_id: Number(fromId),
            to_id: Number(toId),
            day_block_number: Number(dayBlockNumber) as DayBlockNumber,
            headcount: headcount,
          }
        } else {
          elem1 = {
            dt: parseYmdDate(yyyymmdd),
            from_type: Number(toType) as RescueAdjustmentStatusFromType,
            from_id: Number(toId),
            to_id: Number(fromId),
            day_block_number: Number(dayBlockNumber) as DayBlockNumber,
            headcount: -headcount,
          }
        }
        ret.push(elem1)
        // fromType が管理グループの場合のみ、逆方向の移動を 0 へリセットする
        // (派遣会社の場合は無視しないとサーバ側チェックで弾かれる)
        if (Number(fromType) === RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP) {
          const elem2: RescueAdjustmentStatusForBulkUpdate = {
            ...elem1,
            from_id: elem1.to_id,
            to_id: elem1.from_id,
            headcount: 0,
          }
          ret.push(elem2)
        }
      })
      return ret
    }

    const buildRescueAdjustmentStatusSaveParams = (): RescueAdjustmentStatusForBulkUpdate[] => {
      // 双方向で渡し合っているレコードを相殺するなど、マージする.
      const statusesMapForMerge: Record<string, RescueAdjustmentStatusForBulkUpdate[]> = {}
      // 画面表示の都合上、複数budgetGroup間で同じ内容のレコードが作成されているので、それを除く.
      const usedMap: Record<string, number> = {}
      state.budgetGroups.forEach(budgetGroup => {
        budgetGroup.rescueAdjustmentData.forEach((adjustmentDataMap, i) => {
          Object.entries(adjustmentDataMap).forEach(([key, adjustmentData]) => {
            const [fromType, block, fromId, toId] = key.split('_').map((value) => Number(value))
            const dt = addDays(state.targetDate, i)
            const elem: RescueAdjustmentStatusForBulkUpdate = {
              dt: dt,
              from_type: fromType as RescueAdjustmentStatusFromType,
              from_id: fromId,
              to_id: toId,
              day_block_number: block as DayBlockNumber,
              headcount: adjustmentData.headcount,
            }
            const uniqueStr = getRescueAdjustmentStatusUniqueStr(elem)
            if (usedMap[uniqueStr]) { return }
            usedMap[uniqueStr] = 1

            // 同じ縦列で順方向に複数回のD&Dがあったり、順方向と逆方向のD&Dがあった場合、同じハコに入るようにする
            const [mergeKey1, elemForMerge1, mergeKey2, elemForMerge2] = getRescueAdjustmentStatusMergeOperationSet(elem)
            if (statusesMapForMerge[mergeKey2]) {
              statusesMapForMerge[mergeKey2].push(elemForMerge2)
            } else {
              if (!statusesMapForMerge[mergeKey1]) { statusesMapForMerge[mergeKey1] = [] }
              statusesMapForMerge[mergeKey1].push(elemForMerge1)
            }
          })
        })
      })
      return mergeRescueAdjustmentStatuses(statusesMapForMerge)
    }

    const buildRescueAgencyOrderSaveParams = (): RescueAgencyOrderForBulkUpdate[] => {
      return state.staffAgencies.reduce<RescueAgencyOrderForBulkUpdate[]>((orders, staffAgency) => {
        return orders.concat(staffAgency.orderedList.reduce<RescueAgencyOrderForBulkUpdate[]>((orders, ordered, i) => {
          if (!ordered.isChanged) { return orders }
          const confirmed = staffAgency.confirmedList[i]
          orders.push({
            staff_agency_id: staffAgency.id,
            dt: addDays(state.targetDate, i),
            day_block1_num_ordered: ordered.block1,
            day_block1_num_confirmed: confirmed.block1,
            day_block2_num_ordered: ordered.block2,
            day_block2_num_confirmed: confirmed.block2,
          })
          return orders
        }, []))
      }, [])
    }

    const save = async() => {
      state.isChanged = false
      try {
        await rescueAdjustmentApi.rescueAdjustmentBulkUpdate({
          workplace_id: state.workplaceId,
          rescue_adjustment_memos: buildRescueAdjustmentMemoSaveParams(),
          rescue_adjustment_statuses: buildRescueAdjustmentStatusSaveParams(),
          rescue_agency_orders: buildRescueAgencyOrderSaveParams(),
        })
        notifySuccess1(root, '応援調整情報を保存しました')
      } catch (err: any) {
        state.isChanged = true
        notifyError1(root, '応援調整情報の保存に失敗しました', { err })
      }
    }

    /**
     * 人時の移動を行う
     */
    const moveHeadcount = async(_movedHeadcount: number) => {
      const targetBudgetGroup = state.budgetGroups[dragState.targetBudgetGroupIndex]
      if (!isExist(targetBudgetGroup)) { return }
      let draggedAdjustmentStatus: RescueAdjustmentBudgetGroup | RescueAdjustmentStaffAgency
      if (dragState.draggedType === RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP) {
        draggedAdjustmentStatus = state.budgetGroups[dragState.draggedIndex]
      } else {
        draggedAdjustmentStatus = state.staffAgencies[dragState.draggedIndex]
      }
      if (!isExist(draggedAdjustmentStatus)) { return }

      const key = createRescueAdjustmentKey(
        dragState.draggedType,
        draggedAdjustmentStatus.id,
        targetBudgetGroup.id,
        dragState.blockNum,
      )
      const reverseKey = createRescueAdjustmentKey(
        RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP,
        targetBudgetGroup.id,
        draggedAdjustmentStatus.id,
        dragState.blockNum,
      )
      const diff = !dragState.isToStaffAgency ? 0 : Math.min(0, (draggedAdjustmentStatus.rescueAdjustmentData[dragState.rescueAdjustmentIndex][key]?.headcount ?? 0) - _movedHeadcount)
      const movedHeadcount = (dragState.isToStaffAgency ? -1 : 1) * _movedHeadcount - diff

      const headcount = movedHeadcount + (targetBudgetGroup.rescueAdjustmentData[dragState.rescueAdjustmentIndex][key]?.headcount ?? 0)
      const reverseHeadcount = targetBudgetGroup.rescueAdjustmentData[dragState.rescueAdjustmentIndex][reverseKey]?.headcount ?? 0
      const [_, fromData, toData] = createRescueAdjustmentData(
        dragState.draggedType,
        draggedAdjustmentStatus.id,
        targetBudgetGroup.id,
        dragState.blockNum,
        Math.max(headcount - reverseHeadcount, 0),
        state.budgetGroups,
        state.staffAgencies,
        state.workplaceExtension,
      )
      targetBudgetGroup.rescueAdjustmentData[dragState.rescueAdjustmentIndex][key] = toData
      draggedAdjustmentStatus.rescueAdjustmentData[dragState.rescueAdjustmentIndex][key] = fromData
      targetBudgetGroup.rescueAdjustments[dragState.rescueAdjustmentIndex][`block${dragState.blockNum}`] -= movedHeadcount
      draggedAdjustmentStatus.rescueAdjustments[dragState.rescueAdjustmentIndex][`block${dragState.blockNum}`] += movedHeadcount
      if (dragState.draggedType === RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP) {
        const [__, reverseFromData, reverseToData] = createRescueAdjustmentData(
          RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP,
          targetBudgetGroup.id,
          draggedAdjustmentStatus.id,
          dragState.blockNum,
          Math.max(reverseHeadcount - headcount, 0),
          state.budgetGroups,
          state.staffAgencies,
          state.workplaceExtension,
        )
        targetBudgetGroup.rescueAdjustmentData[dragState.rescueAdjustmentIndex][reverseKey] = reverseFromData
        draggedAdjustmentStatus.rescueAdjustmentData[dragState.rescueAdjustmentIndex][reverseKey] = reverseToData
      }
      updateTotalData();
      state.isChanged = true
      closeHeadcountMoveModal();
    }
    const { state: headcountMoveModalState, openHeadcountMoveModal, closeHeadcountMoveModal } = headcountMoveModalHooks({ moveHeadcount });

    const focusMemo = (targetIndex: number) => {
      state.lastMemo = state.totalData.memos[targetIndex]
    }

    const updateMemo = async(targetIndex: number) => {
      if (!state.targetDate) { return }
      if (state.totalData.memos[targetIndex] === state.lastMemo) { return }
      state.isChanged = true
    }

    const inputNumberOnly = (target: Record<string, number>, key: string) => {
      if (!isExist(target[key])) { return }
      target[key] = Number(target[key].toString().replace(/\D/g, ''))
    }

    const focusOrder = (staffAgencyIndex: number, targetIndex: number, blockKey: 'block1' | 'block2') => {
      state.lastOrder = state.staffAgencies[staffAgencyIndex].orderedList[targetIndex][blockKey]
      state.lastConfirm = state.staffAgencies[staffAgencyIndex].confirmedList[targetIndex][blockKey]
    }

    const updateOrder = async(staffAgencyIndex: number, targetIndex: number, blockKey: 'block1' | 'block2') => {
      if (!state.targetDate) { return }
      const staffAgency = state.staffAgencies[staffAgencyIndex]
      if (
        staffAgency.orderedList[targetIndex][blockKey] === state.lastOrder &&
        staffAgency.confirmedList[targetIndex][blockKey] === state.lastConfirm
      ) { return }
      staffAgency.orderedList[targetIndex].isChanged = true
      staffAgency.confirmedList[targetIndex].isChanged = true
      state.isChanged = true
    }

    const prevDate = () => {
      state.targetDate = addDays(state.targetDate, -1)
      resetRescueAdjustment()
    }

    const nextDate = () => {
      state.targetDate = addDays(state.targetDate, 1)
      resetRescueAdjustment()
    }

    const onDrop = (targetBudgetGroupIndex: number) => {
      if (
        dragState.draggedType === RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP &&
        targetBudgetGroupIndex === dragState.draggedIndex
      ) { return }
      dragState.targetBudgetGroupIndex = targetBudgetGroupIndex
      openHeadcountMoveModal()
    }

    const onDropToStaffAgency = (targetStaffAgencyIndex: number) => {
      if (dragState.draggedType === RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.STAFF_AGENCY) { return }
      dragState.draggedType = RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.STAFF_AGENCY
      dragState.targetBudgetGroupIndex = dragState.draggedIndex
      dragState.draggedIndex = targetStaffAgencyIndex
      dragState.isToStaffAgency = true
      openHeadcountMoveModal()
    }

    const onDragstart = (draggedType: RescueAdjustmentStatusFromType, draggedIndex: number, rescueAdjustmentIndex: number, blockNum: DayBlockNumber) => {
      dragState.draggedType = draggedType
      dragState.draggedIndex = draggedIndex
      dragState.rescueAdjustmentIndex = rescueAdjustmentIndex
      dragState.blockNum = blockNum
      dragState.isToStaffAgency = false
    }

    return {
      state,
      headcountMoveModalState,
      save,
      isExist,
      focusMemo,
      focusOrder,
      updateMemo,
      updateOrder,
      resetRescueAdjustment,
      inputNumberOnly,
      prevDate,
      nextDate,
      onDrop,
      onDropToStaffAgency,
      onDragstart,
      DISPLAY_DATE_LENGTH,
    }
  },
})

const createStaffAgency = ({ id, name }: {id: number, name: string }): RescueAdjustmentStaffAgency => {
  return {
    id,
    name,
    orderedList: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0, isChanged: false })),
    confirmedList: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0, isChanged: false })),
    rescueAdjustments: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0 })),
    rescueAdjustmentData: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({})),
  }
}

const createStaffAgenciesFromApiResponse = (
  { rescue_agency_orders, rescue_adjustment_statuses }: RescueAdjustmentIndexResult,
  { targetDate, staffAgencies: _staffAgencies, budgetGroups, workplaceExtension }: RescueAdjustmentState
): RescueAdjustmentStaffAgency[] => {
  const staffAgencies = _staffAgencies.map(staffAgency => createStaffAgency(staffAgency))
  rescue_agency_orders.forEach(order => {
    const staffAgency = staffAgencies.find(staffAgency => staffAgency.id === order.staff_agency_id)
    if (!isExist(staffAgency)) { return }
    const targetIndex = differenceInDays(order.dt, targetDate)
    if (targetIndex < 0 || targetIndex > 6) { return }
    staffAgency.orderedList[targetIndex] = {
      block1: order.day_block1_num_ordered,
      block2: order.day_block2_num_ordered,
      isChanged: false,
    }
    staffAgency.confirmedList[targetIndex] = {
      block1: order.day_block1_num_confirmed,
      block2: order.day_block2_num_confirmed,
      isChanged: false,
    }
  })
  rescue_adjustment_statuses.forEach(({ dt, to_id, from_id, from_type, day_block_number: blockNum, headcount }) => {
    if (from_type !== RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.STAFF_AGENCY) { return }
    const targetIndex = differenceInDays(dt, targetDate)
    if (targetIndex < 0 || targetIndex > 6) { return }
    const fromStaffAgency = staffAgencies.find(staffAgency => staffAgency.id === from_id)
    if (!isExist(fromStaffAgency)) { return }
    const targetFromRescueAdjustment = fromStaffAgency.rescueAdjustments[targetIndex]
    targetFromRescueAdjustment[`block${blockNum}`] += headcount
    const [key, data] = createRescueAdjustmentData(
      from_type,
      from_id,
      to_id,
      blockNum,
      headcount,
      budgetGroups,
      staffAgencies,
      workplaceExtension,
    )
    fromStaffAgency.rescueAdjustmentData[targetIndex][key] = data
  })
  return staffAgencies
}

const createBudgetGroup = ({ id, name }: {id: number, name: string }): RescueAdjustmentBudgetGroup => {
  return {
    id,
    name,
    headcounts: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0 })),
    rescueDemands: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0 })),
    rescueAdjustments: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0 })),
    rescueAdjustmentData: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({})),
  }
}

const createRescueAdjustmentKey = (
  type: RescueAdjustmentStatusFromType,
  from: number,
  to: number,
  blockNum: DayBlockNumber,
): string => {
  return `${type}_${blockNum}_${from}_${to}`
}

const createRescueAdjustmentData = (
  type: RescueAdjustmentStatusFromType,
  from: number,
  to: number,
  blockNum: DayBlockNumber,
  headcount: number,
  budgetGroups: RescueAdjustmentBudgetGroup[],
  staffAgencies: RescueAdjustmentStaffAgency[],
  { day_block1_name, day_block2_name }: WorkplaceExtension,
  isChanged: boolean = false,
): [string, RescueAdjustmentData, RescueAdjustmentData] => {
  const blockName = blockNum === 1 ? day_block1_name : day_block2_name
  const toName = budgetGroups.find(budgetGroup => budgetGroup.id === to)?.name ?? ''
  let fromName = ''
  if (type === RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.STAFF_AGENCY) {
    fromName = staffAgencies.find(staffAgency => staffAgency.id === from)?.name ?? ''
  } else if (type === RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP) {
    fromName = budgetGroups.find(budgetGroup => budgetGroup.id === from)?.name ?? ''
  }
  return [
    createRescueAdjustmentKey(type, from, to, blockNum),
    {
      headcount,
      label: `${blockName} → ${toName}`,
      isChanged,
    },
    {
      headcount,
      label: `${blockName} ← ${fromName}`,
      isChanged,
    },
  ]
}

const createBudgetGroupsFromApiResponse = (
  { rescue_adjustment_statuses, rescue_demands, budget_group_shift_counts }: RescueAdjustmentIndexResult,
  { targetDate, staffAgencies, budgetGroups: _budgetGroups, workplaceExtension }: RescueAdjustmentState
): RescueAdjustmentBudgetGroup[] => {
  const budgetGroups = _budgetGroups.map(budgetGroup => createBudgetGroup(budgetGroup))
  budget_group_shift_counts.forEach(shiftCount => {
    const budgetGroup = budgetGroups.find(budgetGroup => budgetGroup.id === shiftCount.budget_group_id)
    if (!isExist(budgetGroup)) { return }
    const targetIndex = differenceInDays(shiftCount.dt, targetDate)
    if (targetIndex < 0 || targetIndex > 6) { return }
    budgetGroup.headcounts[targetIndex] = {
      block1: shiftCount.day_block1_shift_count,
      block2: shiftCount.day_block2_shift_count,
    }
  })
  rescue_demands.forEach(rescueDemand => {
    const budgetGroup = budgetGroups.find(budgetGroup => budgetGroup.id === rescueDemand.budget_group_id)
    if (!isExist(budgetGroup)) { return }
    const targetIndex = differenceInDays(rescueDemand.dt, targetDate)
    if (targetIndex < 0 || targetIndex > 6) { return }
    budgetGroup.rescueDemands[targetIndex] = {
      block1: rescueDemand.day_block1_demand,
      block2: rescueDemand.day_block2_demand,
    }
  })
  // AMのほうがPMより上に来てほしい
  rescue_adjustment_statuses.sort((a, b) => {
    return a.day_block_number - b.day_block_number
  })
  rescue_adjustment_statuses.forEach(({ dt, to_id, from_id, from_type, day_block_number: blockNum, headcount }) => {
    const toBudgetGroup = budgetGroups.find(budgetGroup => budgetGroup.id === to_id)
    if (!isExist(toBudgetGroup)) { return }
    const targetIndex = differenceInDays(dt, targetDate)
    if (targetIndex < 0 || targetIndex > 6) { return }
    const targetToRescueAdjustment = toBudgetGroup.rescueAdjustments[targetIndex]
    targetToRescueAdjustment[`block${blockNum}`] -= headcount
    const [key, fromData, toData] = createRescueAdjustmentData(
      from_type,
      from_id,
      to_id,
      blockNum,
      headcount,
      budgetGroups,
      staffAgencies,
      workplaceExtension,
    )
    toBudgetGroup.rescueAdjustmentData[targetIndex][key] = toData

    if (from_type !== RESCUE_ADJUSTMENT_STATUS_FROM_TYPE.BUDGET_GROUP) { return }
    const fromBudgetGroup = budgetGroups.find(budgetGroup => budgetGroup.id === from_id)
    if (!isExist(fromBudgetGroup)) { return }
    const targetFromRescueAdjustment = fromBudgetGroup.rescueAdjustments[targetIndex]
    targetFromRescueAdjustment[`block${blockNum}`] += headcount
    fromBudgetGroup.rescueAdjustmentData[targetIndex][key] = fromData
  })
  return budgetGroups
}

const createTotalData = (): ResuceAdjustmentTotalData => {
  return {
    headcounts: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0 })),
    rescueDemands: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0 })),
    rescueAdjustments: new Array(DISPLAY_DATE_LENGTH).fill('').map(() => ({ block1: 0, block2: 0 })),
    memos: new Array(DISPLAY_DATE_LENGTH).fill(null),
  }
}

const createTotalDataFromApiResponse = (
  { rescue_adjustment_memos }: RescueAdjustmentIndexResult,
  { targetDate }: RescueAdjustmentState
): ResuceAdjustmentTotalData => {
  const totalData = createTotalData()
  rescue_adjustment_memos.forEach(({ dt, memo }) => {
    const targetIndex = differenceInDays(dt, targetDate)
    if (targetIndex < 0 || targetIndex > 6) { return }
    totalData.memos[targetIndex] = memo
  })
  return totalData
}
