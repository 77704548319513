import { unref } from '@vue/composition-api';
import { format } from 'date-fns';
import progressHeaderApi from 'src/apis/progressHeader';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { useProgressHeaders } from './useProgressHeaders';
import { useSearchConditions } from './useSearchConditions';

type CreateProgressHeaders = {
  createProgressHeaders: () => Promise<boolean>;
};

async function requestToCreateProgressHeaders({ workplaceId, dt }: { workplaceId: number; dt: Date }): Promise<void> {
  await progressHeaderApi.bulkCreate({
    workplace_id: workplaceId,
    dt: format(dt, 'yyyy-MM-dd'),
  });
}

export function useCreateProgressHeaders(): CreateProgressHeaders {
  const { workplaceId, dt } = useSearchConditions();
  const errorBoundary = useErrorBoundary();
  const { notifySuccess } = useNotification();
  const { fetchProgressHeaders } = useProgressHeaders();
  const createProgressHeaders = errorBoundary.wrap(
    async () => {
      await requestToCreateProgressHeaders({
        workplaceId,
        dt: unref(dt),
      });
      await fetchProgressHeaders();
      notifySuccess('入力準備ができました');
    },
    {
      shouldContactUs: true,
    },
  );

  return {
    createProgressHeaders,
  };
}
