import {
  ConversionProgram,
  convertConversionProgramResponse
} from 'src/models/conversionProgram'
import { ConversionProgramStatusResponse } from 'src/models/api/conversionProgramStatusResponse'
import { parseDatetime, parseYmdDate } from 'src/util/datetime'

export const DATA_CONVERSION_STATUS_FAILED = 'failed'
export const DATA_CONVERSION_STATUS_IN_PROGRESS = 'in_progress'
export const DATA_CONVERSION_STATUS_COMPLETED = 'completed'
export type DATA_CONVERSION_STATUS = typeof DATA_CONVERSION_STATUS_FAILED | typeof DATA_CONVERSION_STATUS_IN_PROGRESS | typeof DATA_CONVERSION_STATUS_COMPLETED

export const DATA_CONVERSION_STATUS_COLOR_MAP = {
  [DATA_CONVERSION_STATUS_FAILED]: 'ed7d31',
  [DATA_CONVERSION_STATUS_IN_PROGRESS]: 'ffc000',
  [DATA_CONVERSION_STATUS_COMPLETED]: '70ad47',
}

export const REQUESTS_COMPLETION_STATUS_FAILED = 'failed'
export const REQUESTS_COMPLETION_STATUS_IN_PROGRESS = 'in_progress'
export const REQUESTS_COMPLETION_STATUS_COMPLETED = 'completed'
export type REQUESTS_COMPLETION_STATUS = typeof REQUESTS_COMPLETION_STATUS_FAILED | typeof REQUESTS_COMPLETION_STATUS_IN_PROGRESS | typeof REQUESTS_COMPLETION_STATUS_COMPLETED

// 特に画面上に表示していないので今のところREQUESTS_COMPLETION_STATUS_COLOR_MAPは不要

export interface ConversionProgramStatus {
  id: number
  conversion_program_id: number
  conversion_program: ConversionProgram
  dt: Date
  data_conversion_status: DATA_CONVERSION_STATUS
  data_conversion_status_text: string
  requests_completion_status: REQUESTS_COMPLETION_STATUS
  requests_completion_status_text: string
  error_message: string | null
  last_request_time: Date | null
}

export function getExecuteStatusColor(ConversionProgramStatus: Partial<ConversionProgramStatus>) {
  return DATA_CONVERSION_STATUS_COLOR_MAP[ConversionProgramStatus.data_conversion_status!]
}

export function convertConversionProgramStatusResponse(response: ConversionProgramStatusResponse): ConversionProgramStatus {
  const { conversion_program, ...rest } = response
  return {
    ...rest,
    conversion_program: convertConversionProgramResponse(conversion_program),
    dt: parseYmdDate(response.dt),
    last_request_time: response.last_request_time ? parseDatetime(response.last_request_time) : null,
  }
}
