





















import { defineComponent, PropType } from '@vue/composition-api';
import CsvUpload from './CsvUpload.vue';

export default defineComponent({
  name: 'csv-upload-neutral-button',
  components: {
    CsvUpload,
  },
  props: {
    upload: {
      type: Function as PropType<(file: File) => unknown>,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: 'CSV入力',
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
