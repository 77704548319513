import { computed, defineComponent, PropType, reactive, SetupContext, watch } from '@vue/composition-api'
import { startOfMonth, endOfMonth, addDays, differenceInDays } from 'date-fns'

export type ActualDataFetchModalState = {
  isVisible: boolean
  startDate: Date
  endDate: Date
  limitDate: Date
  isLoading: boolean
}

export default defineComponent({
  name: 'actual-data-fetch-modal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    defaultStartDate: {
      type: Date,
      default: null,
    },
    fetchActualData: {
      type: Function as PropType<(startDate: Date, endDate: Date) => void>,
      required: true,
    },
    onVisibleStateChange: {
      type: Function as PropType<(isVisible: boolean) => void>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state: ActualDataFetchModalState = reactive({
      isVisible: false,
      startDate: startOfMonth(props.defaultStartDate),
      endDate: endOfMonth(props.defaultStartDate),
      limitDate: computed(() => addDays(state.startDate, 30)),
      isLoading: false,
    })
    watch(() => props.show, show => {
      state.isVisible = show
      props.onVisibleStateChange(state.isVisible)
    })
    watch(() => props.defaultStartDate, defaultStartDate => {
      state.startDate = startOfMonth(defaultStartDate)
      state.endDate = endOfMonth(defaultStartDate)
    })
    watch(() => props.isLoading, isLoading => {
      state.isLoading = isLoading
    })
    const onCancel = (): void => {
      state.isVisible = false
      props.onVisibleStateChange(state.isVisible)
    }
    const onOK = () => {
      props.fetchActualData(state.startDate, state.endDate);
    }
    const onStartDateChange = () => {
      // 開始日が終了日を超える場合は終了日もその日に合わせる
      if (state.startDate > state.endDate) {
        state.endDate = new Date(state.startDate)
      }
      // 開始日が終了日の 30 日以前を超える場合は終了日をその 30 日後にする
      if (differenceInDays(state.endDate, state.startDate) > 30) {
        state.endDate = addDays(state.startDate, 30)
      }
    }
    return {
      state,
      onOK,
      onCancel,
      onStartDateChange,
    }
  },
})
