



















import { defineComponent } from '@vue/composition-api'
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect'
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch'
import { useBudgetGroups } from 'src/composables/useBudgetGroups'
import { useShiftPatterns } from '../../composables/useShiftPatterns'

export default defineComponent({
  components: {
    BudgetGroupSelect,
    ToggleSwitch,
  },
  setup() {
    const { budgetGroups } = useBudgetGroups()
    const { budgetGroup, includesDisabled, isLoading } = useShiftPatterns()

    return {
      budgetGroups,
      budgetGroup,
      includesDisabled,
      isLoading,
    }
  },
})
