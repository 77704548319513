import { SchedActualMode } from 'src/consts';
import { StaffMonthlySummaryResponse } from 'src/models/api/staffMonthlySummaryResponse';
import { toNumber } from 'src/util/numbers';

export interface StaffMonthlySummary {
  id: number
  staff_id: number
  year: number
  month: number
  mode: SchedActualMode
  days_worked: number
  paid_holidays: number
  substitute_days_worked: number
  substitute_holidays: number
  hours_normal: number
  hours_overtime: number
  hours_midnight: number
  hours_holiday: number
  hours_over_forty_in_week: number
  hours_over_sixty_in_month: number
  hours_total: number
  normal_cost_sum: number
  overtime_cost_sum: number
  midnight_cost_sum: number
  holiday_cost_sum: number
  over_forty_in_week_cost_sum: number
  over_sixty_in_month_cost_sum: number
  all_cost_sum: number
}

export function convertStaffMonthlySummaryResponse(response: StaffMonthlySummaryResponse): StaffMonthlySummary {
  return {
    ...response,
    hours_normal: toNumber(response.hours_normal),
    hours_overtime: toNumber(response.hours_overtime),
    hours_midnight: toNumber(response.hours_midnight),
    hours_holiday: toNumber(response.hours_holiday),
    hours_over_forty_in_week: toNumber(response.hours_over_forty_in_week),
    hours_over_sixty_in_month: toNumber(response.hours_over_sixty_in_month),
    hours_total: toNumber(response.hours_total),
  }
}
