import { ref, watch, computed, type ComputedRef } from '@vue/composition-api';
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import {
  convertTimetableActivityMasterResponse,
  type TimetableActivityMaster,
} from 'src/models/timetableActivityMaster';
import { createInjection } from 'src/util/createInjection';
import { useTimetableMasters } from './useTimetableMasters';

type InjectionValue = {
  timetableActivityMasters: ComputedRef<TimetableActivityMaster[]>;
  budgetGroupTimetableActivityMastersIncludingDisabled: ComputedRef<TimetableActivityMaster[]>;
  budgetGroupTimetableActivityMasters: ComputedRef<TimetableActivityMaster[]>;
};

const { provide, inject } = createInjection<InjectionValue>('useTimetableActivityMasters');

async function getTimetableActivityMasters({
  budgetGroup,
}: {
  budgetGroup: BudgetGroup;
}): Promise<TimetableActivityMaster[]> {
  const response = await timetableMasterApi.getActivityMasters(budgetGroup.workplace_id, {
    budget_group_id: budgetGroup.id,
  });
  return convertTimetableActivityMasterResponse(response);
}

export function useTimetableActivityMastersProvider(): void {
  const { budgetGroups } = useBudgetGroups();
  const { budgetGroup } = useTimetableMasters();
  const errorBoundary = useErrorBoundary();
  const timetableActivityMastersByBudgetGroup = ref<
    {
      budgetGroup: BudgetGroup;
      timetableActivityMasters: TimetableActivityMaster[];
    }[]
  >([]);
  const timetableActivityMasters = computed(() =>
    timetableActivityMastersByBudgetGroup.value.flatMap((v) => v.timetableActivityMasters).filter((v) => v.is_enabled),
  );
  const budgetGroupTimetableActivityMastersIncludingDisabled = computed(() => {
    return (
      timetableActivityMastersByBudgetGroup.value.find((v) => v.budgetGroup.id === budgetGroup.value?.id)
        ?.timetableActivityMasters ?? []
    );
  });
  const budgetGroupTimetableActivityMasters = computed(() =>
    budgetGroupTimetableActivityMastersIncludingDisabled.value.filter((v) => v.is_enabled),
  );
  const fetchTimetableActivityMasters = errorBoundary.wrap(
    async () => {
      timetableActivityMastersByBudgetGroup.value = await Promise.all(
        budgetGroups.value.map(async (budgetGroup) => {
          const timetableActivityMasters = await getTimetableActivityMasters({ budgetGroup });
          return {
            budgetGroup,
            timetableActivityMasters: timetableActivityMasters.filter((v) => v.is_editable),
          };
        }),
      );
    },
    {
      fallbackMessage: '作業マスタの取得に失敗しました',
    },
  );

  watch([budgetGroups], fetchTimetableActivityMasters);

  provide({
    timetableActivityMasters,
    budgetGroupTimetableActivityMastersIncludingDisabled,
    budgetGroupTimetableActivityMasters,
  });
}

export function useTimetableActivityMasters(): InjectionValue {
  return inject();
}
