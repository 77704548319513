




























































import { defineComponent, ref, watch, nextTick } from '@vue/composition-api';
import { ValidationObserver, ValidationProvider, type ValidationObserverInstance } from 'vee-validate';
import { NumberInput } from 'src/components/UIComponents/Inputs/NumberInput';
import { TimeInput } from 'src/components/UIComponents/Inputs/TimeInput';
import { SubmitButton } from 'src/components/UIComponents/Buttons/SubmitButton';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { type ProgressDetail } from 'src/models/progressHeader';
import { useQuickCreateProgressDetail } from '../../composables/useQuickCreateProgressDetail';
import { CardPanel } from '../CardPanel';
import { useShowProgressDetails } from './useShowProgressDetails';
import { useValidationParams } from './useValidationParams';

export default defineComponent({
  components: {
    ValidationObserver,
    ValidationProvider,
    NumberInput,
    TimeInput,
    SubmitButton,
    NormalButton,
    CardPanel,
  },
  setup() {
    const observer = ref<ValidationObserverInstance | null>(null);
    const isSubmitting = ref(false);
    const resultQuantity = ref<number | null>(null);
    const endTime = ref<ProgressDetail['end_time'] | null>(null);
    const { quickCreateProgressDetail } = useQuickCreateProgressDetail();
    const handleSubmit = async () => {
      if (isSubmitting.value || observer.value === null) {
        return;
      }

      const isValid = await observer.value.validate();
      if (!isValid) {
        return;
      }

      try {
        isSubmitting.value = true;

        await quickCreateProgressDetail({
          resultQuantity: resultQuantity.value!,
          endTime: endTime.value!,
        });

        resultQuantity.value = null;
        endTime.value = null;
      } finally {
        isSubmitting.value = false;
      }
    };
    const { showProgressDetails } = useShowProgressDetails();
    const { minResultQuantity, minEndTime } = useValidationParams();

    watch([resultQuantity, endTime], ([resultQuantity, endTime]) => {
      if (resultQuantity === null && endTime === null) {
        // nextTickを3回使用しないと登録後にresultQuantityとendTimeをnullにした際にバリデーションエラーが表示されたままになる
        nextTick(() => {
          nextTick(() => {
            nextTick(() => {
              observer.value?.reset();
            });
          });
        });
      }
    });

    return {
      observer,
      isSubmitting,
      resultQuantity,
      endTime,
      minResultQuantity,
      minEndTime,
      handleSubmit,
      showProgressDetails,
    };
  },
});
