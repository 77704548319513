import axios, { AxiosResponse } from 'axios';
import { getApiUrl } from './apiUtils';
import { StringOrNumber } from 'src/models/common';
// TODO: (別PR)現在models/regularShift実装されているリクエスト型はstaffShiftRequest, レスポンス型はstaffShiftResponseに移植する
import {
  RegularShift,
  RegularShiftBulkCreateParams,
  RegularShiftIndexParams,
  UpdateParams,
  UpdateResponse,
  BulkUpdateParams,
  UpdatedShiftsResponse,
  BulkCreateResponse,
  ScheduledToAttendanceParams,
  ScheduledToAttendanceResponse,
  BulkDestroyParams,
  ShiftTableForPrintParams,
  ShiftTableForPrintResponse,
  LoadLogimeterStaffAttendancesParams,
  bulkUpdateDispOrder,
} from 'src/models/regularShift';
import { StaffShift } from 'src/models/staffShift';
import {
  WorkTimeSummaryViewParams,
  StaffShiftSubBudgetGroupListParams,
  CreateOrUpdateShiftMemoParams,
  StaffShiftOverviewParams,
  ImportCsvParams,
  ImportShiftPatternCsvParams,
  CreateShiftPatternCsvTemplateParams,
} from 'src/models/api/staffShiftRequest';
import { WorkTimeSummaryViewResponse, StaffShiftOverviewResponse } from 'src/models/api/staffShiftResponse';

const getResourceBaseUrl = (workplaceId: StringOrNumber, budget_group_id: StringOrNumber): string => {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budget_group_id}/staff_shifts`);
};

export default {
  async sub_budget_group_list(params: StaffShiftSubBudgetGroupListParams): Promise<StaffShift[]> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.get<StaffShift[]>(
      `${getResourceBaseUrl(workplace_id, budget_group_id)}/sub_budget_group_list`,
      {
        params: paramsRest,
      },
    );
    return data;
  },

  // get
  async regularShiftIndex(params: RegularShiftIndexParams): Promise<RegularShift> {
    const { workplace_id, budget_group_id, start_date: startDate } = params;
    const { data } = await axios.get(`${getResourceBaseUrl(workplace_id, budget_group_id)}/regular_shift_index`, {
      params: { start_date: startDate },
    });
    // 戻ってくるのはスタッフ単位
    return data;
  },
  // 個別アップデート
  async update(params: UpdateParams): Promise<UpdateResponse> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/update`, {
      ...paramsRest,
    });

    return data;
  },
  // 一括アップデート
  async bulkUpdate(params: BulkUpdateParams): Promise<UpdatedShiftsResponse> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/bulk_update`, {
      ...paramsRest,
    });

    return data;
  },
  // 一括作成
  async bulkCreate(params: RegularShiftBulkCreateParams): Promise<BulkCreateResponse> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/bulk_create`, {
      ...paramsRest,
    });

    return data;
  },
  // 仮シフトを反映
  async reflectProvisionalShiftToScheduledShift(
    params: ScheduledToAttendanceParams,
  ): Promise<ScheduledToAttendanceResponse> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.post(
      `${getResourceBaseUrl(workplace_id, budget_group_id)}/reflect_provisional_shift_to_scheduled_shift`,
      {
        ...paramsRest,
      },
    );

    return data;
  },
  // 予定をシフトなしにする
  async scheduledToNoShift(params: ScheduledToAttendanceParams): Promise<ScheduledToAttendanceResponse> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/scheduled_to_no_shift`, {
      ...paramsRest,
    });

    return data;
  },
  // 実績に予定を反映する
  async reflectScheduledShiftToActualShift(
    params: ScheduledToAttendanceParams,
  ): Promise<ScheduledToAttendanceResponse> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.post(
      `${getResourceBaseUrl(workplace_id, budget_group_id)}/reflect_scheduled_shift_to_actual_shift`,
      {
        ...paramsRest,
      },
    );

    return data;
  },
  // 実績を欠勤にする
  async actualToAbsent(params: ScheduledToAttendanceParams): Promise<ScheduledToAttendanceResponse> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/actual_to_absent`, {
      ...paramsRest,
    });

    return data;
  },
  // シフトを削除する
  async bulkDestroy(params: BulkDestroyParams): Promise<AxiosResponse<{}>> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    return await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/bulk_destroy`, {
      ...paramsRest,
    });
  },
  async bulkUpdateDispOrder(params: bulkUpdateDispOrder): Promise<void> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/bulk_update_disp_order`, {
      ...paramsRest,
    });
  },
  // シフト印刷ページのデータ取得
  async shiftTableForPrint(params: ShiftTableForPrintParams): Promise<ShiftTableForPrintResponse> {
    const { workplace_id, budget_group_id, ...paramsRest } = params;
    const { data } = await axios.get(`${getResourceBaseUrl(workplace_id, budget_group_id)}/shift_table_for_print`, {
      params: { ...paramsRest },
    });

    return data;
  },
  async importShiftPatternCsv({
    workplace_id,
    budget_group_id,
    dt,
    csv,
  }: ImportShiftPatternCsvParams): Promise<AxiosResponse<{}>> {
    const data = new FormData();
    data.append('dt', dt.toISOString());
    data.append('csv', csv);
    return await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/import_shift_pattern_csv`, data);
  },
  async createShiftPatternCsvTemplate({
    workplace_id,
    budget_group_id,
    ...data
  }: CreateShiftPatternCsvTemplateParams): Promise<AxiosResponse<{}>> {
    return await axios.post(
      `${getResourceBaseUrl(workplace_id, budget_group_id)}/create_shift_pattern_csv_template`,
      data,
    );
  },
  async loadLogimeterStaffAttendances({
    workplace_id,
    budget_group_id,
    ...data
  }: LoadLogimeterStaffAttendancesParams): Promise<AxiosResponse<{}>> {
    return await axios.post(
      `${getResourceBaseUrl(workplace_id, budget_group_id)}/load_logimeter_staff_attendances`,
      data,
    );
  },
  async workTimeSummaryView({
    workplace_id,
    budget_group_id,
    ...paramsRest
  }: WorkTimeSummaryViewParams): Promise<WorkTimeSummaryViewResponse> {
    const { data } = await axios.get(`${getResourceBaseUrl(workplace_id, budget_group_id)}/work_time_summary_view`, {
      params: paramsRest,
    });

    return data;
  },
  async createOrUpdateShiftMemo({
    workplace_id,
    budget_group_id,
    ...paramsRest
  }: CreateOrUpdateShiftMemoParams): Promise<void> {
    await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/create_or_update_memo`, paramsRest);
  },
  async staffShiftOverview({
    workplace_id,
    budget_group_id,
    ...params
  }: StaffShiftOverviewParams): Promise<StaffShiftOverviewResponse> {
    const { data } = await axios.post(
      `${getResourceBaseUrl(workplace_id, budget_group_id)}/staff_shift_overview`,
      params,
    );
    return data;
  },
  async importCsv({ workplace_id, budget_group_id, target, csv }: ImportCsvParams): Promise<AxiosResponse<{}>> {
    const data = new FormData();
    data.append('target', target);
    data.append('csv', csv);
    return await axios.post(`${getResourceBaseUrl(workplace_id, budget_group_id)}/import_csv`, data);
  },
};
