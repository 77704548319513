










import { defineComponent, PropType } from '@vue/composition-api';
import { useDownloadCsv } from 'src/composables/useDownloadCsv';
import { CsvRow, BeforeDownload } from './types';

export default defineComponent({
  name: 'csv-download',
  props: {
    csvData: {
      type: Array as PropType<CsvRow[]>,
      required: true,
    },
    csvFileName: {
      type: String,
      required: true,
    },
    beforeDownload: {
      type: Function as PropType<BeforeDownload>,
      required: true,
    },
    enclosedByDoubleQuotation: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isLoading, downloadCsv } = useDownloadCsv({
      prepare: async () => {
        await props.beforeDownload();
        return {
          fileName: props.csvFileName,
          data: props.csvData,
        };
      },
      useQuotes: props.enclosedByDoubleQuotation,
    });

    return {
      isLoading,
      downloadCsv,
    };
  },
});
