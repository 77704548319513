









import { defineComponent } from '@vue/composition-api';
import { addComma } from 'src/filters/number_filters';

export default defineComponent({
  props: {
    value: {
      type: Number,
      required: true,
    },
    isMatrixTotal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const timetableMasterCount = addComma(Math.round(props.value));
    const style = {
      backgroundColor: props.isMatrixTotal || timetableMasterCount === '0' ? 'white' : '#8497b0',
      color:
        props.isMatrixTotal || timetableMasterCount === '0' ? (props.isMatrixTotal ? 'black' : '#bfbfbf') : 'white',
    };

    return {
      timetableMasterCount,
      style,
    };
  },
});
