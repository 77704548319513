import { shallowRef, type Ref } from '@vue/composition-api'
import { useModal } from 'src/composables/useModal'

export type ModalWithContext<Context> = {
  context: Ref<Context | null>
  showsModal: ReturnType<typeof useModal>['showsModal']
  showModal: (context: Context) => void
  hideModal: () => void
}

export function useModalWithContext<Context>(): ModalWithContext<Context> {
  const currentContext = shallowRef<Context | null>(null)
  const modal = useModal()
  const showModal = (context: Context) => {
    currentContext.value = context
    modal.showModal()
  }
  const hideModal = () => {
    currentContext.value = null
    modal.hideModal()
  }

  return {
    context: currentContext,
    showsModal: modal.showsModal,
    showModal,
    hideModal,
  }
}
