






































import { defineComponent, computed } from '@vue/composition-api';
import { orDefault } from 'src/util/filters';
import { getLatestProductivityColor, getTotalProductivityColor } from 'src/util/progressHeaderHelpers';
import { useProgressHeader } from '../../composables/useProgressHeader';
import { formatFixedDecimal } from '../../utils/filters';
import HeaderItem from './HeaderItem.vue';

export default defineComponent({
  filters: {
    orDefault,
    formatFixedDecimal,
  },
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader();
    const latestProductivityColor = computed(() => getLatestProductivityColor(progressHeader.value));
    const totalProductivityColor = computed(() => getTotalProductivityColor(progressHeader.value));

    return {
      progressHeader,
      latestProductivityColor,
      totalProductivityColor,
    };
  },
});
