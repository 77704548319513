import { type BackgroundProcessStatus } from 'src/models/backgroundProcessStatus';

const statusNameMap: Record<BackgroundProcessStatus['status'], string> = {
  1: '実行中',
  10: '完了',
  99: '失敗',
};

export function getStatusName(status: BackgroundProcessStatus['status']): string {
  return statusNameMap[status];
}
