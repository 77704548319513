























import { defineComponent, type PropType } from '@vue/composition-api';
import { type ShiftStatus } from '../../types/ShiftStatus';

export default defineComponent({
  props: {
    value: {
      type: String as PropType<ShiftStatus | null>,
      default: null,
    },
  },
});
