


























import { defineComponent, type PropType } from '@vue/composition-api'
import { type ActivityStatusMap } from 'src/models/progressBoard'
import { CardPanel } from '../CardPanel'
import { formatInteger } from '../../utils/filters'

export default defineComponent({
  components: {
    CardPanel,
  },
  filters: {
    formatInteger,
  },
  props: {
    activityStatusMap: {
      type: Object as PropType<ActivityStatusMap>,
      required: true,
    },
  },
})
