








import Vue from 'vue'
import { defineComponent } from '@vue/composition-api'
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { Content } from '../Content'
import { ControlPanel } from '../ControlPanel'
import { MainPanel } from '../MainPanel'
import { useProgressBoardProvider } from '../../composables/useProgressBoard'

export default defineComponent({
  components: {
    Content,
    ControlPanel,
    MainPanel,
  },
  setup(_, context) {
    const root = context.root as Vue
    const workplaceId = Number(root.$route.params.workplaceId)

    setPageName(root, '進捗一覧')
    useBudgetGroupsProvider({ workplaceId })
    useProgressBoardProvider()
  },
})
