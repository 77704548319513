import { MacroOperationSegmentMasterExternalSource } from './api/MacroOperationSegmentMasterExternalSourceResponse';
import { MacroOperationSegmentMasterResponse } from './api/macroOperationSegmentMasterResponse';

export type MacroOperationSegmentMaster = {
  id: number;
  macro_operation_master_id: number;
  name: string;
  is_enabled: boolean;
  disp_order: number;
  productivity: number;
  macro_operation_segment_master_external_sources: MacroOperationSegmentMasterExternalSource[];
};

export function convertMacroOperationSegmentResponse(
  response: MacroOperationSegmentMasterResponse,
): MacroOperationSegmentMaster {
  return {
    ...response,
    productivity: response.productivity ? Number(response.productivity) : 0,
    macro_operation_segment_master_external_sources: response.macro_operation_segment_master_external_sources,
  };
}
