/**
 * 以下をもとに作成
 * @see {@link https://github.com/kurando-inc/logiboard-ap/blob/97b03e0fe11ea64fd25e7d9adf9c122f15408aea/src/assets/src/views/Dashboard/Workplace/PlanProgress/script.ts#L219-L277}
 */
import { getHours, isToday, isYesterday } from 'date-fns'
import { type TimetableHeader } from 'src/models/timetableHeader'
import { type PlanProgressBlock } from 'src/models/planProgressBlock'
import * as helper from 'src/util/planProgressHelper'
import {
  type AmountData,
  type HeadCountData,
  type TimetableBudgetEx,
  type PredictedCompleteData,
} from 'src/util/planProgressHelper'
import { packToTimeInteger } from 'src/util/datetime'

// 現在のTypeScriptの設定だとArray.prototype.findLastIndexが使えないので自前で実装
function findLastIndex<T>(array: T[], callback: (element: T) => boolean): number {
  return array.reduce((index, element, i) => callback(element) ? i : index, -1)
}

// 現在のTypeScriptの設定だとArray.prototype.findLastが使えないので自前で実装
function findLast<T>(array: T[], callback: (element: T) => boolean): T | undefined {
  return array[findLastIndex(array, callback)]
}

function hasResultAmount(planProgressBlock: PlanProgressBlock): boolean {
  return planProgressBlock.result_amount !== null
}

function getCurrentPlanProgressBlockIndex(timetableHeader: TimetableHeader): number {
  return findLastIndex(timetableHeader.plan_progress_blocks, hasResultAmount)
}

function getCurrentPlanProgressBlock(timetableHeader: TimetableHeader): PlanProgressBlock | null {
  return findLast(timetableHeader.plan_progress_blocks, hasResultAmount) ?? null
}

function getCurrentTimePlanProgressBlock(timetableHeader: TimetableHeader): PlanProgressBlock | null {
  const planProgressBlocks = timetableHeader.plan_progress_blocks
  const currentHours = getHours(new Date())

  if (isToday(timetableHeader.dt)) {
    return planProgressBlocks.find(v => v.hour === currentHours) ?? null
  }

  if (isYesterday(timetableHeader.dt)) {
    return planProgressBlocks.find(v => v.hour === currentHours + 24) ?? null
  }

  return null
}

function getAmountData(timetableHeader: TimetableHeader): AmountData {
  const currentPlanProgressBlockIndex = getCurrentPlanProgressBlockIndex(timetableHeader)
  return helper.getAmountData(timetableHeader.plan_progress_blocks, currentPlanProgressBlockIndex)
}

function getHeadCountData(timetableHeader: TimetableHeader): HeadCountData {
  const currentPlanProgressBlockIndex = getCurrentPlanProgressBlockIndex(timetableHeader)
  return helper.getHeadCountData(timetableHeader.plan_progress_blocks, currentPlanProgressBlockIndex)
}

function getTimetableBudgets(timetableHeader: TimetableHeader): TimetableBudgetEx[] {
  const planProgressBlocks = timetableHeader.plan_progress_blocks
  const amountWorkBeforehand = timetableHeader.amount_work_beforehand ?? 0
  return helper.createTimetableBudgets(planProgressBlocks, amountWorkBeforehand)
}

function getPredictedCompleteData(timetableHeader: TimetableHeader): PredictedCompleteData {
  const timetableBudgets = getTimetableBudgets(timetableHeader)
  const currentPlanProgressBlockIndex = getCurrentPlanProgressBlockIndex(timetableHeader)
  const productivityByTime = timetableBudgets[currentPlanProgressBlockIndex]?.productivityByTime ?? null
  const { lastScheduledAmount } = getAmountData(timetableHeader)

  return helper.getPredictedCompleteData(
    timetableBudgets,
    productivityByTime,
    lastScheduledAmount,
    timetableHeader.target_time,
    currentPlanProgressBlockIndex,
  )
}

export function getDisplayedTime(timetableHeader: TimetableHeader): number | null {
  const planProgressBlock = getCurrentPlanProgressBlock(timetableHeader)
  return planProgressBlock ? packToTimeInteger(planProgressBlock.hour + 1, 0, 0) : null
}

export function getPredictedCompleteTime(timetableHeader: TimetableHeader): string {
  return getPredictedCompleteData(timetableHeader).predictedCompleteTime
}

export function getPredictedCompleteTimeColor(timetableHeader: TimetableHeader): string {
  return helper.getPredictedCompleteTimeColor(getPredictedCompleteTime(timetableHeader), timetableHeader.target_time)
}

export function getPredictedCompleteAmount(timetableHeader: TimetableHeader): number | null {
  const predictedCompleteAmount = getPredictedCompleteData(timetableHeader).predictedCompleteAmount
  return predictedCompleteAmount !== '-' ? Number(predictedCompleteAmount) : null
}

export function getProgressRate(timetableHeader: TimetableHeader): number | null {
  return getAmountData(timetableHeader).progressRate
}

export function getProgressColor(timetableHeader: TimetableHeader): string {
  const { currentResultAmount, currentScheduledAmount } = getAmountData(timetableHeader)
  return helper.statusColor(currentResultAmount, currentScheduledAmount)
}

export function getHeadCountRate(timetableHeader: TimetableHeader): number | null {
  const { sumScheduledHeadCount } = getHeadCountData(timetableHeader)
  // sumScheduledHeadCountが0より大きければ、getSumScheduledHeadCount()も0より大きいので、0の除算は起きない
  return (sumScheduledHeadCount ?? 0) > 0 ? (getSumResultHeadCount(timetableHeader) ?? 0) / (getSumScheduledHeadCount(timetableHeader) ?? 0) * 100 : null
}

export function getHeadCountColor(timetableHeader: TimetableHeader): string {
  const { sumResultHeadCount, sumScheduledHeadCountToCurrent } = getHeadCountData(timetableHeader)
  return helper.statusColor(sumResultHeadCount, sumScheduledHeadCountToCurrent)
}

export function getCurrentResultAmount(timetableHeader: TimetableHeader): number | null {
  return getAmountData(timetableHeader).currentResultAmount
}

export function getLastScheduledAmount(timetableHeader: TimetableHeader): number | null {
  return getAmountData(timetableHeader).lastScheduledAmount
}

export function getSumResultHeadCount(timetableHeader: TimetableHeader): number | null {
  const { sumResultHeadCount } = getHeadCountData(timetableHeader)
  return sumResultHeadCount !== null ? (sumResultHeadCount + (timetableHeader.headcount_work_beforehand ?? 0)) : null
}

export function getSumScheduledHeadCount(timetableHeader: TimetableHeader): number | null {
  const { sumScheduledHeadCount } = getHeadCountData(timetableHeader)
  return sumScheduledHeadCount !== null ? (sumScheduledHeadCount + (timetableHeader.headcount_work_beforehand ?? 0)) : null
}

export function getCurrentProductivity(timetableHeader: TimetableHeader): number | null {
  const timetableBudgets = getTimetableBudgets(timetableHeader)
  const currentPlanProgressBlockIndex = getCurrentPlanProgressBlockIndex(timetableHeader)
  return timetableBudgets[currentPlanProgressBlockIndex]?.productivityByTime ?? null
}

export function getCurrentProductivityColor(timetableHeader: TimetableHeader): string {
  return helper.statusColor(getCurrentProductivity(timetableHeader), timetableHeader.timetable_master.planned_productivity)
}

export function getCurrentProductivityRank(timetableHeader: TimetableHeader): string {
  return helper.currentProductivityRank(getCurrentProductivityRate(timetableHeader))
}

export function getCurrentProductivityRate(timetableHeader: TimetableHeader): number | null {
  const currentProductivity = getCurrentProductivity(timetableHeader)
  const plannedProductivity = timetableHeader.timetable_master.planned_productivity

  if (plannedProductivity === 0) return null

  return currentProductivity ? (currentProductivity / plannedProductivity * 100) : null
}

export function getCurrentProductivityRateColor(timetableHeader: TimetableHeader): string {
  return helper.currentProductivityRateColor(getCurrentProductivityRate(timetableHeader))
}

export function getCurrentTimeScheduledHeadCount(timetableHeader: TimetableHeader): number | null {
  return getCurrentTimePlanProgressBlock(timetableHeader)?.scheduled_headcount ?? null
}
