import { provide, inject, type InjectionKey } from '@vue/composition-api'

type Injection<InjectionValue> = {
  provide: (value: InjectionValue) => void
  inject: () => InjectionValue
}

export function createInjection<InjectionValue>(name: string): Injection<InjectionValue> {
  const key = Symbol(name) as InjectionKey<InjectionValue>
  return {
    provide: (value: InjectionValue) => provide(key, value),
    inject: () => {
      const value = inject(key)
      if (value === undefined) {
        throw new Error(`"${key.toString()}" was not provided. Ensure "provide" is used before "inject".`)
      }
      return value
    },
  }
}
