








import Vue from 'vue'
import { defineComponent } from '@vue/composition-api'
import { useBudgetGroupsProvider } from 'src/composables/useBudgetGroups'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { Content } from '../Content'
import { ControlPanel } from '../ControlPanel'
import { MainPanel } from '../MainPanel'
import { useBackgroundProcessStatusesProvider } from '../../composables/useBackgroundProcessStatuses'

export default defineComponent({
  components: {
    Content,
    ControlPanel,
    MainPanel,
  },
  setup(_, context) {
    const root = context.root as Vue
    const workplaceId = Number(root.$route.params.workplaceId)
    const shouldFetchImmediately = root.$route.query.search === '1'

    setPageName(root, '作成状況')
    useBudgetGroupsProvider({ workplaceId })
    useBackgroundProcessStatusesProvider({ workplaceId, shouldFetchImmediately })
  },
})
