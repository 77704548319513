import Vue from 'vue'
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watch } from '@vue/composition-api'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { wrappedMapGetters } from 'src/hooks/storeHook'
import { ensureUserAndMasters } from 'src/hooks/masterHook'
import conversionProgramApi from 'src/apis/conversionProgram'
import ConversionProgramStatusApi from 'src/apis/conversionProgramStatus'
import { ConversionProgram } from 'src/models/conversionProgram'
import {
  ConversionProgramStatus,
  getExecuteStatusColor,
  DATA_CONVERSION_STATUS_IN_PROGRESS,
  DATA_CONVERSION_STATUS_COMPLETED,
  DATA_CONVERSION_STATUS_FAILED,
  REQUESTS_COMPLETION_STATUS_IN_PROGRESS,
  REQUESTS_COMPLETION_STATUS_COMPLETED,
  REQUESTS_COMPLETION_STATUS_FAILED,
} from 'src/models/conversionProgramStatus'
import { notifyError1, notifySuccess1 } from 'src/hooks/notificationHook'
import { formatDate, rewindDate } from 'src/util/datetime'
import { startOfDay } from 'date-fns'

const CONVERSION_PROGRAM_MESSAGE_UPLOAD_CSV = 'CSVデータのアップロードを開始しました'
const CONVERSION_PROGRAM_MESSAGE_IMPORT_CSV = 'CSVデータ取込を開始しました'

type ConversionProgramStatusEx = ConversionProgramStatus & {
  dtDisp: string
  lastRequestDate: string | null
  canRequest: boolean
  isInProgressExecuteStatus: boolean
  isCompletedExecuteStatus: boolean
  isFailedExecuteStatus: boolean
  isResultViewable: boolean
}

interface State {
  pageName: string | null
  userId: number | null
  baseDate: Date
  list: ConversionProgram[]
  statusList: ConversionProgramStatus[]
  hasList: boolean
  hasStatusList: boolean
  shouldPaginate: boolean
  selectedConversionProgram: ConversionProgram | null
  hasPageAccessRole: boolean
  pagination: {
    perPage: number
    currentPage: number
    total: number
  }
}

function setupState(root: Vue): State {
  const state: State = reactive({
    ...wrappedMapGetters(root.$store, 'displayPageName', [
      'pageName',
    ]),
    userId: wrappedMapGetters(root.$store, 'user', ['id']).id,
    baseDate: new Date(),
    list: [],
    statusList: [],
    hasList: computed(() => {
      return state.list.length > 0
    }),
    paginatedStatusList: computed(() => {
      const startIdx = state.pagination.perPage * Math.max((state.pagination.currentPage - 1), 0)
      return state.statusList.slice(startIdx, startIdx + state.pagination.perPage)
    }),
    hasStatusList: computed(() => {
      return state.statusList.length > 0
    }),
    shouldPaginate: computed(() => {
      return state.pagination.total > state.pagination.perPage
    }),
    selectedConversionProgram: null,
    hasPageAccessRole: true,
    pagination: {
      perPage: 50,
      currentPage: 1,
      total: 0,
    }
  })
  return state
}

export default defineComponent({
  setup() {
    const root = getCurrentInstance()!.proxy
    setPageName(root, 'データ変換')
    const state = setupState(root)

    async function getList(): Promise<void> {
      try {
        state.list = await conversionProgramApi.index(root.$route.params.workplaceId)
        state.statusList = []
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 403) {
          state.hasPageAccessRole = false
        } else {
          notifyError1(root, errRes, { err })
        }
      }
    }

    async function getStatusList(): Promise<void> {
      if (!state.selectedConversionProgram) {
        return
      }
      try {
        const response = await ConversionProgramStatusApi.index({
          workplaceId: root.$route.params.workplaceId,
          conversionProgramId: state.selectedConversionProgram.id,
          dt: state.baseDate,
        })
        state.statusList = response.map(convertConversionProgramStatus)
        state.pagination.total = state.statusList.length
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 403) {
          state.hasPageAccessRole = false
        } else {
          notifyError1(root, errRes, { err })
        }
      }
    }

    function convertConversionProgramStatus(elem: ConversionProgramStatus): ConversionProgramStatusEx {
      return {
        ...elem,
        dtDisp: formatDate(elem.dt, 'yyyy/MM/dd'),
        lastRequestDate: elem.last_request_time !== null ? formatDate(elem.last_request_time, 'yyyy-MM-dd') : null,
        canRequest: isRequestable(elem),
        isInProgressExecuteStatus: elem.data_conversion_status === DATA_CONVERSION_STATUS_IN_PROGRESS,
        isCompletedExecuteStatus: elem.data_conversion_status === DATA_CONVERSION_STATUS_COMPLETED,
        isFailedExecuteStatus: elem.data_conversion_status === DATA_CONVERSION_STATUS_FAILED,
        isResultViewable: hasRequested(elem)
      }
    }

    function isRequestable(elem: ConversionProgramStatus): boolean {
      const exceedPeriodDays = 8
      if (elem.dt <= rewindDate(startOfDay(new Date()), exceedPeriodDays, 'days')) {
        return false
      }
      return elem.data_conversion_status === DATA_CONVERSION_STATUS_COMPLETED &&
        ![REQUESTS_COMPLETION_STATUS_COMPLETED, REQUESTS_COMPLETION_STATUS_IN_PROGRESS].includes(elem.requests_completion_status)
    }

    function hasRequested(elem: ConversionProgramStatus): boolean {
      if (elem.requests_completion_status === REQUESTS_COMPLETION_STATUS_IN_PROGRESS ||
        elem.requests_completion_status === REQUESTS_COMPLETION_STATUS_COMPLETED ||
        elem.requests_completion_status === REQUESTS_COMPLETION_STATUS_FAILED) {
        return true
      }
      return false
    }

    async function selectConversionProgram(conversionProgram: ConversionProgram): Promise<void> {
      state.selectedConversionProgram = conversionProgram
      await getStatusList()
    }

    function openFileDialog(conversionProgramId: string): void {
      const element: HTMLElement = document.getElementById(`csvInput-${conversionProgramId}`) as HTMLElement
      element.click()
    }

    onMounted(async() => {
      await ensureUserAndMasters(root)
      await getList()
      if (state.hasList) {
        await selectConversionProgram(state.list[0])
      }
    })

    async function onCsvUpload(conversionProgram: ConversionProgram, e: any): Promise<void> {
      try {
        const data = new FormData()
        data.append('csv', e.target.files[0])
        await ConversionProgramStatusApi.create(root.$route.params.workplaceId, conversionProgram.id, data)
        notifySuccess1(root, CONVERSION_PROGRAM_MESSAGE_UPLOAD_CSV)
        await selectConversionProgram(conversionProgram)
      } catch (err: any) {
        const msg = `${state.pageName}の処理に失敗しました。管理者に連絡してください。` +
          `(ERR: ${state.pageName})`
        notifyError1(root, msg, { err })
      }
    }

    async function importCsv(conversionProgram: ConversionProgram, conversionProgramStatus: ConversionProgramStatusEx): Promise<void> {
      try {
        await ConversionProgramStatusApi.importCsv(root.$route.params.workplaceId, conversionProgram.id, conversionProgramStatus.id)
        notifySuccess1(root, CONVERSION_PROGRAM_MESSAGE_IMPORT_CSV)
        await getStatusList()
      } catch (err: any) {
        const errStatus = err.response.status
        const errRes = err.response.data || {}
        if (errStatus === 400) {
          notifyError1(root, errRes.message, { err })
        } else {
          notifyError1(root, errRes, { err })
        }
      }
    }

    watch(() => state.baseDate, () => {
      getStatusList()
    })

    return {
      getExecuteStatusColor,
      onCsvUpload,
      openFileDialog,
      state,
      selectConversionProgram,
      importCsv,
    }
  }
})
