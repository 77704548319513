import { ref, type Ref } from '@vue/composition-api';
import budgetGroupPlanBoardMiscApi from 'src/apis/budgetGroupPlanBoardMisc';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type BudgetGroupPlanBoardMisc } from 'src/models/budgetGroupPlanBoardMisc';
import { createInjection } from 'src/util/createInjection';

type Params = {
  budgetGroup: BudgetGroup;
  dates: Date[];
};

type InjectionValue = {
  budgetGroupPlanBoardMiscs: Ref<BudgetGroupPlanBoardMisc[] | null>;
  fetchBudgetGroupPlanBoardMiscs: (params: Params) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useBudgetGroupPlanBoardMiscs');

export function useBudgetGroupPlanBoardMiscsProvider(): void {
  const errorBoundary = useErrorBoundary();
  const budgetGroupPlanBoardMiscs = ref<BudgetGroupPlanBoardMisc[] | null>(null);
  const fetchBudgetGroupPlanBoardMiscs = errorBoundary.wrap(
    async ({ budgetGroup, dates }: Params) => {
      budgetGroupPlanBoardMiscs.value = await budgetGroupPlanBoardMiscApi.index({
        workplace_id: budgetGroup.workplace_id,
        budget_group_id: budgetGroup.id,
        dates,
      });
    },
    {
      fallbackMessage: '当日管理グループの取得に失敗しました',
    },
  );

  provide({
    budgetGroupPlanBoardMiscs,
    fetchBudgetGroupPlanBoardMiscs,
  });
}

export function useBudgetGroupPlanBoardMiscs(): InjectionValue {
  return inject();
}
