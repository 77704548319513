













import { defineComponent } from '@vue/composition-api'
import { ModalForm } from 'src/components/UIComponents/Form'
import { useDeleteProgressDetail } from '../../composables/useDeleteProgressDetail'

export default defineComponent({
  components: {
    ModalForm,
  },
  setup() {
    const {
      progressHeader,
      showsProgressDetailDeleteConfirmationModal,
      hideProgressDetailDeleteConfirmationModal,
      deleteProgressDetail,
    } = useDeleteProgressDetail()

    return {
      progressHeader,
      showsProgressDetailDeleteConfirmationModal,
      hideProgressDetailDeleteConfirmationModal,
      deleteProgressDetail,
    }
  },
})
