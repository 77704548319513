import { ref, type Ref } from '@vue/composition-api';
import staffShiftApi from 'src/apis/staffShift';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { type MacroOperationMaster } from 'src/models/macroOperationMaster';
import { type StaffLabel } from 'src/models/staffLabel';
import { type StaffShiftOverview, convertStaffShiftOverviewResponse } from 'src/models/staffShiftOverview';
import { createInjection } from 'src/util/createInjection';
import { type ShiftStatus } from '../types/ShiftStatus';

type Params = {
  budgetGroup: BudgetGroup;
  dates: Date[];
  shiftStatus: ShiftStatus;
  macroOperationMasters: MacroOperationMaster[];
  staffLabels: StaffLabel[];
  isKeyPlayer: boolean;
  isForkman: boolean;
  hasCustomSkill1: boolean;
  hasCustomSkill2: boolean;
  hasCustomSkill3: boolean;
};

type InjectionValue = {
  staffShiftOverview: Ref<StaffShiftOverview | null>;
  fetchStaffShiftOverview: (params: Params) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useStaffShiftOverview');

export function useStaffShiftOverviewProvider(): void {
  const errorBoundary = useErrorBoundary();
  const staffShiftOverview = ref<StaffShiftOverview | null>(null);
  const fetchStaffShiftOverview = errorBoundary.wrap(
    async (params: Params) => {
      const response = await staffShiftApi.staffShiftOverview({
        workplace_id: params.budgetGroup.workplace_id,
        budget_group_id: params.budgetGroup.id,
        dates: params.dates,
        shift_status: params.shiftStatus,
        macro_operation_master_ids: params.macroOperationMasters.map((v) => v.id),
        staff_label_ids: params.staffLabels.map((v) => v.id),
        is_key_player: params.isKeyPlayer,
        is_forkman: params.isForkman,
        has_custom_skill1: params.hasCustomSkill1,
        has_custom_skill2: params.hasCustomSkill2,
        has_custom_skill3: params.hasCustomSkill3,
      });
      staffShiftOverview.value = convertStaffShiftOverviewResponse(response);
    },
    {
      fallbackMessage: 'シフト特性の取得に失敗しました',
    },
  );

  provide({
    staffShiftOverview,
    fetchStaffShiftOverview,
  });
}

export function useStaffShiftOverview(): InjectionValue {
  return inject();
}
