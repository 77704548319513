













































import { defineComponent, type PropType } from '@vue/composition-api';
import { type WeekDay } from 'src/views/Dashboard/Workplace/types/WeekDay';

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<WeekDay[]>,
      required: true,
    },
  },
});
