

























import { defineComponent } from '@vue/composition-api'
import { CreateButton } from 'src/components/UIComponents/Buttons/CreateButton'
import { Draggable } from 'src/components/UIComponents/Draggable'
import { useShiftPatterns } from '../../composables/useShiftPatterns'
import { useCreateShiftPattern } from '../../composables/useCreateShiftPattern'
import { useUpdateShiftPattern } from '../../composables/useUpdateShiftPattern'
import { useUpdateShiftPatternDispOrders } from '../../composables/useUpdateShiftPatternDispOrders'
import { useDeleteShiftPattern } from '../../composables/useDeleteShiftPattern'
import { ShiftPatternList, ShiftPatternListItem } from '../ShiftPatternList'

export default defineComponent({
  components: {
    CreateButton,
    ShiftPatternList,
    ShiftPatternListItem,
    Draggable,
  },
  setup() {
    const { shiftPatterns } = useShiftPatterns()
    const { showCreateModal } = useCreateShiftPattern()
    const { showUpdateModal } = useUpdateShiftPattern()
    const { updateShiftPatternDispOrders } = useUpdateShiftPatternDispOrders()
    const { showDeleteConfirmationModal } = useDeleteShiftPattern()

    return {
      shiftPatterns,
      showCreateModal,
      showUpdateModal,
      updateShiftPatternDispOrders,
      showDeleteConfirmationModal,
    }
  },
})
