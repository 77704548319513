














import { defineComponent, type PropType } from '@vue/composition-api'
import { type ShiftPattern } from 'src/models/workplaceMasters/shiftPattern'
import { formatTime } from '../../utils/filters'
import ShiftPatternListRow from './ShiftPatternListRow.vue'
import ShiftPatternListCell from './ShiftPatternListCell.vue'
import ShiftPatternListControls from './ShiftPatternListControls.vue'

export default defineComponent({
  filters: {
    formatTime,
  },
  components: {
    ShiftPatternListRow,
    ShiftPatternListCell,
    ShiftPatternListControls,
  },
  props: {
    shiftPattern: {
      type: Object as PropType<ShiftPattern>,
      required: true,
    },
    onEdit: {
      type: Function as PropType<(shiftPattern: ShiftPattern) => unknown>,
      required: true,
    },
    onDelete: {
      type: Function as PropType<(shiftPattern: ShiftPattern) => unknown>,
      required: true,
    },
  },
})
