import { type ComputedRef } from '@vue/composition-api';
import staffExtensionApi from 'src/apis/workplace_masters/staff_extension';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useCsvUploadResultModal } from './useCsvUploadResultModal';

type Params = {
  workplaceId: ComputedRef<number>;
  fetchStaffs: () => Promise<unknown>;
};

type UploadStaffCsv = {
  uploadStaffCsv: (file: File) => Promise<boolean>;
};

export function useUploadStaffCsv({ workplaceId, fetchStaffs }: Params): UploadStaffCsv {
  const { showCsvUploadResultModal } = useCsvUploadResultModal();
  const errorBoundary = useErrorBoundary();
  const uploadStaffCsv = errorBoundary.wrap(async (csv: File) => {
    const result = await staffExtensionApi.importCsv({
      workplaceId: workplaceId.value,
      csv,
    });
    showCsvUploadResultModal({
      successCount: result.success_count,
      failureCount: result.failure_count,
      errorMessages: result.error_messages,
    });
    await fetchStaffs();
  });

  return {
    uploadStaffCsv,
  };
}
