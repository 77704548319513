import axios from 'axios';
import { StringOrNumber } from 'src/models/common';
import { getApiUrl } from 'src/apis/apiUtils';
import { BudgetGroupPlanBoardMisc, convertBudgetGroupPlanBoardMiscResponse } from 'src/models/budgetGroupPlanBoardMisc';
import {
  BudgetGroupPlanBoardMiscIndexParams,
  BudgetGroupPlanBoardMiscUpsertParams,
} from 'src/models/api/budgetGroupPlanBoardMiscRequest';
import { BudgetGroupPlanBoardMiscResponse } from 'src/models/api/budgetGroupPlanBoardMiscResponse';

function getResourceBaseUrl(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): string {
  return getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}/budget_group_plan_board_miscs`);
}

export default {
  async index(params: BudgetGroupPlanBoardMiscIndexParams): Promise<BudgetGroupPlanBoardMisc[]> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    const { data } = await axios.get<BudgetGroupPlanBoardMiscResponse[]>(
      `${getResourceBaseUrl(workplaceId, budgetGroupId)}`,
      { params: paramsRest },
    );
    return data.map(convertBudgetGroupPlanBoardMiscResponse);
  },
  async upsert(params: BudgetGroupPlanBoardMiscUpsertParams): Promise<BudgetGroupPlanBoardMisc> {
    const { workplace_id: workplaceId, budget_group_id: budgetGroupId, ...paramsRest } = params;
    const { data } = await axios.post(`${getResourceBaseUrl(workplaceId, budgetGroupId)}/upsert`, paramsRest);
    return convertBudgetGroupPlanBoardMiscResponse(data);
  },
};
