import progressHeaderApi from 'src/apis/progressHeader';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useNotification } from 'src/composables/useNotification';
import { type ProgressHeader } from 'src/models/progressHeader';

type updateProgressHeaderTargetCompletionTimeParams = {
  id: ProgressHeader['id'];
  workplaceId: ProgressHeader['workplace_id'];
  targetCompletionTime: ProgressHeader['target_completion_time'];
};

type updateProgressHeaderScheduledQuantityParams = {
  id: ProgressHeader['id'];
  workplaceId: ProgressHeader['workplace_id'];
  scheduledQuantity: ProgressHeader['scheduled_quantity'];
};

type UpdateProgressHeader = {
  updateProgressHeaderTargetCompletionTime: (
    params: updateProgressHeaderTargetCompletionTimeParams,
    onUpdate: () => unknown,
  ) => Promise<boolean>;
  updateProgressHeaderScheduledQuantity: (
    params: updateProgressHeaderScheduledQuantityParams,
    onUpdate: () => unknown,
  ) => Promise<boolean>;
};

export function useUpdateProgressHeader(): UpdateProgressHeader {
  const errorBoundary = useErrorBoundary();
  const { notifySuccess } = useNotification();

  const updateProgressHeaderTargetCompletionTime = errorBoundary.wrap(
    async (
      { id, workplaceId, targetCompletionTime }: updateProgressHeaderTargetCompletionTimeParams,
      onUpdate: () => unknown,
    ) => {
      await progressHeaderApi.update({
        id,
        workplace_id: workplaceId,
        target_completion_time: targetCompletionTime,
      });
      notifySuccess('目標時刻を更新しました');
      await onUpdate();
    },
    {
      shouldContactUs: true,
    },
  );

  const updateProgressHeaderScheduledQuantity = errorBoundary.wrap(
    async (
      { id, workplaceId, scheduledQuantity }: updateProgressHeaderScheduledQuantityParams,
      onUpdate: () => unknown,
    ) => {
      await progressHeaderApi.update({
        id,
        workplace_id: workplaceId,
        scheduled_quantity: scheduledQuantity,
      });
      notifySuccess('予定作業量を更新しました');
      await onUpdate();
    },
    {
      shouldContactUs: true,
    },
  );

  return {
    updateProgressHeaderTargetCompletionTime,
    updateProgressHeaderScheduledQuantity,
  };
}
