var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showsHeadcountUpdatePopover && _vm.isExist(_vm.timeBlock))?_c('div',{ref:"rootElement",staticClass:"HeadcountUpdatePopover-root"},[_c('div',{ref:"popoverElement",staticClass:"HeadcountUpdatePopover-popover",class:{
      'is-top': _vm.isTop,
    },style:({
      top: (_vm.top + "px"),
      left: (_vm.left + "px"),
    })},[_c('div',{staticClass:"HeadcountUpdatePopover-popover-content"},[_c('button',{staticClass:"HeadcountUpdatePopover-popover-content-close close",on:{"click":_vm.hideHeadcountUpdatePopover}},[_c('i',{staticClass:"nc-icon nc-simple-remove"})]),_c('div',{staticClass:"HeadcountUpdatePopover-popover-content-title"},[_vm._v(_vm._s(_vm.timeBlock.displayTime)+" -")]),_c('ValidationObserver',{staticClass:"HeadcountUpdatePopover-popover-content-main",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('div',{staticClass:"HeadcountUpdatePopover-popover-headcount"},[_c('FormItem',{staticClass:"HeadcountUpdatePopover-input",attrs:{"rules":_vm.headcountValidationRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hasError = ref.hasError;
return [_c('NumberInput',{ref:"input",staticClass:"NumberInput-input",attrs:{"has-error":hasError,"show-error-message":false,"with-arrows":true},model:{value:(_vm.headcountToUpdate),callback:function ($$v) {_vm.headcountToUpdate=$$v},expression:"headcountToUpdate"}})]}}],null,true)}),_c('span',[_vm._v("人")])],1),_c('div',{staticClass:"HeadcountUpdatePopover-buttons-container"},[_c('PrimaryButton',{directives:[{name:"close-popover",rawName:"v-close-popover"}],attrs:{"disabled":!_vm.isReadyToHeadcountUpdate || invalid},on:{"click":_vm.updateHeadcount}},[_vm._v(" 移動 ")]),(_vm.canMoveToResting)?_c('button',{staticClass:"HeadcountUpdatePopover-resting-button",attrs:{"disabled":!_vm.isReadyToHeadcountUpdate || invalid},on:{"click":_vm.moveToResting}},[_vm._v(" 休憩へ移動 ")]):_vm._e()],1)]}}],null,false,2513835753)})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }