import { getCurrentInstance } from '@vue/composition-api'
import { notifySuccess1, notifyError1 } from 'src/hooks/notificationHook'

type Options = {
  cause?: any
}

type Notification = {
  notifySuccess: (message: string) => void
  notifyError: (message: string, options?: Options) => void
}

export function useNotification(): Notification {
  const root = getCurrentInstance()?.proxy!

  const notifySuccess = (message: string) => {
    notifySuccess1(root, message)
  }

  const notifyError = (message: string, options: Options = {}) => {
    notifyError1(root, message, { err: options.cause })
  }

  return {
    notifySuccess,
    notifyError,
  }
}
