










import { defineComponent, computed } from '@vue/composition-api'
import { orDefault } from 'src/util/filters'
import { getProgressColor } from 'src/util/progressHeaderHelpers'
import { useProgressHeader } from '../../composables/useProgressHeader'
import { formatTime } from '../../utils/filters'
import HeaderItem from './HeaderItem.vue'

export default defineComponent({
  filters: {
    orDefault,
    formatTime,
  },
  components: {
    HeaderItem,
  },
  setup() {
    const { progressHeader } = useProgressHeader()
    const progressColor = computed(() => getProgressColor(progressHeader.value))

    return {
      progressHeader,
      progressColor,
    }
  },
})
