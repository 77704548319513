




































import { defineComponent } from '@vue/composition-api';
import { NormalButton } from 'src/components/UIComponents/Buttons/NormalButton';
import { useCsvUploadResultModal } from '../../composables/useCsvUploadResultModal';

export default defineComponent({
  components: {
    NormalButton,
  },
  setup() {
    const { result, showsCsvUploadResultModal, hideCsvUploadResultModal } = useCsvUploadResultModal();

    return {
      result,
      showsCsvUploadResultModal,
      hideCsvUploadResultModal,
    };
  },
});
