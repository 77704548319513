import { SkillOption } from 'src/models/staffExperienceOverview';
import { SKILLS, Skill } from '../consts';

export const generateInitialSkillOptions = (): SkillOption[] => {
  return SKILLS.map((skill) => {
    return {
      type: skill,
      checked: false,
      enabled: true,
    };
  });
};

export const generateCheckedSkillsList = (skillOptions: SkillOption[]): Skill[] => {
  return skillOptions.filter((skillOption) => skillOption.checked).map((skillOption) => skillOption.type);
};
