import { unref } from '@vue/composition-api'
import timetableMasterApi from 'src/apis/workplace_masters/timetable_master'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { useModalWithContext, type ModalWithContext } from 'src/composables/useModalWithContext'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type TimetableMaster } from 'src/models/timetableMaster'
import { createInjection } from 'src/util/createInjection'
import { useTimetableMasters } from './useTimetableMasters'

type InjectionValue = {
  timetableMaster: ModalWithContext<TimetableMaster>['context']
  showsDeleteConfirmationModal: ModalWithContext<TimetableMaster>['showsModal']
  showDeleteConfirmationModal: ModalWithContext<TimetableMaster>['showModal']
  hideDeleteConfirmationModal: ModalWithContext<TimetableMaster>['hideModal']
  deleteTimetableMaster: () => Promise<boolean>
}

const { provide, inject } = createInjection<InjectionValue>('useDeleteTimetableMaster')

async function requestToDeleteTimetableMaster({
  budgetGroup,
  timetableMaster,
}: {
  budgetGroup: BudgetGroup
  timetableMaster: TimetableMaster
}): Promise<void> {
  await timetableMasterApi.delete({
    workplaceId: budgetGroup.workplace_id,
    itemId: timetableMaster.id,
  })
}

export function useDeleteTimetableMasterProvider(): void {
  const { notifySuccess } = useNotification()
  const errorBoundary = useErrorBoundary()
  const {
    context: timetableMaster,
    showsModal: showsDeleteConfirmationModal,
    showModal: showDeleteConfirmationModal,
    hideModal: hideDeleteConfirmationModal,
  } = useModalWithContext<TimetableMaster>()
  const { fetchTimetableMasters, budgetGroup } = useTimetableMasters()
  const deleteTimetableMaster = errorBoundary.wrap(
    async() => {
      const unwrappedBudgetGroup = unref(budgetGroup)
      const unwrappedTimetableMaster = unref(timetableMaster)
      if (unwrappedBudgetGroup !== null && unwrappedTimetableMaster !== null) {
        await requestToDeleteTimetableMaster({
          budgetGroup: unwrappedBudgetGroup,
          timetableMaster: unwrappedTimetableMaster,
        })
        hideDeleteConfirmationModal()
        notifySuccess('工程マスタを削除しました')
        await fetchTimetableMasters()
      }
    },
    {
      fallbackMessage: '工程マスタの削除に失敗しました。',
      shouldContactUs: true,
    }
  )

  provide({
    timetableMaster,
    showsDeleteConfirmationModal,
    showDeleteConfirmationModal,
    hideDeleteConfirmationModal,
    deleteTimetableMaster,
  })
}

export function useDeleteTimetableMaster(): InjectionValue {
  return inject()
}
