import { BudgetGroupResponse } from 'src/models/api/budgetGroupResponse'
import { BudgetGroupExtension, constructEmptyBudgetGroupExtension, convertBudgetGroupExtensionResponse } from 'src/models/budgetGroupExtension';
import { convertWorkplaceResponse, Workplace } from 'src/models/workplace';

export type BudgetGroup = Omit<
  BudgetGroupResponse,
  'workplace' | 'budget_group_extension'
> & {
  workplace?: Workplace
  budget_group_extension?: BudgetGroupExtension
}

export const constructEmptyBudgetGroup = (): BudgetGroup => {
  return {
    id: 0,
    workplace_id: 0,
    workplace: undefined,
    name: '',
    disp_order: 0,
    is_enabled: true,
    budget_group_extension: constructEmptyBudgetGroupExtension(),
  }
}

export function convertBudgetGroupResponse(response: BudgetGroupResponse): BudgetGroup {
  return {
    ...response,
    ...(response.workplace
      ? { workplace: convertWorkplaceResponse(response.workplace) }
      : null),
    ...(response.budget_group_extension
      ? { budget_group_extension: convertBudgetGroupExtensionResponse(response.budget_group_extension) }
      : null),
  }
}
