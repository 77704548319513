import { ref, type Ref } from '@vue/composition-api'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { type TimetableLabel } from 'src/models/timetableLabel'
import { type TimetableMaster } from 'src/models/timetableMaster'
import { createInjection } from 'src/util/createInjection'

type Params = {
  workplaceId: number
}

type InjectionValue = {
  workplaceId: number
  dt: Ref<Date>
  budgetGroup: Ref<BudgetGroup | null>
  timetableLabels: Ref<TimetableLabel[]>
  timetableMasters: Ref<TimetableMaster[]>
}

const { provide, inject } = createInjection<InjectionValue>('useSearchConditions')

export function useSearchConditionsProvider({ workplaceId }: Params): void {
  const dt = ref(new Date())
  const budgetGroup = ref<BudgetGroup | null>(null)
  const timetableLabels = ref<TimetableLabel[]>([])
  const timetableMasters = ref<TimetableMaster[]>([])

  provide({
    workplaceId,
    dt,
    budgetGroup,
    timetableLabels,
    timetableMasters,
  })
}

export function useSearchConditions(): InjectionValue {
  return inject()
}
