































import { defineComponent, PropType } from '@vue/composition-api';
import { BudgetGroupSelect } from 'src/components/BudgetGroupSelect';
import { PrimaryButton } from 'src/components/UIComponents/Buttons/PrimaryButton';
import { useBudgetGroups } from 'src/composables/useBudgetGroups';
import { useSearchConditions } from '../composables/useSearchConditions';
import { useDailySimulation } from '../composables/useDailySimulation';
import { ToggleSwitch } from 'src/components/UIComponents/Inputs/ToggleSwitch';
import { useDisplayConditions } from '../composables/useDisplayConditions';

export default defineComponent({
  components: {
    BudgetGroupSelect,
    PrimaryButton,
    ToggleSwitch,
  },
  props: {
    onSearchButtonClick: {
      type: Function as PropType<() => unknown>,
      required: true,
    },
    clickCreateProgressHeadersButton: {
      type: Function as PropType<() => unknown>,
      required: true,
    },
    onDisplayShiftGroup: {
      type: Function as PropType<() => unknown>,
      required: true,
    },
  },
  setup(props) {
    const { budgetGroups } = useBudgetGroups();
    const { dt, budgetGroup } = useSearchConditions();
    const { state } = useDailySimulation();
    const { isShiftGroupDisplayed } = useDisplayConditions();

    const onToggleSwitchChange = () => {
      if (isShiftGroupDisplayed.value) {
        props.onDisplayShiftGroup();
      }
    };
    return {
      budgetGroups,
      dt,
      budgetGroup,
      isShiftGroupDisplayed,
      onToggleSwitchChange,
      state,
    };
  },
});
