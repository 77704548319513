export function useLogiAuth(): boolean {
  return process.env.VUE_APP_USE_LOGI_AUTH === 'true'
}

export function useLogiCoredata(): boolean {
  return process.env.VUE_APP_USE_LOGI_COREDATA === 'true'
}

export function navigateToLogiCoredata(
  logiCoredataUrl: string,
  path: string,
  openNewWindow: boolean = false
): void {
  // Only the permitted paths can reach LogiCoredata.
  // check src/util/logibase.ts of LogiCoredata
  const paramObject = {
    link: path,
    sp: 'SP_LB',
  }
  const param = new URLSearchParams(paramObject)
  const url = logiCoredataUrl + '/trans?' + param.toString()
  if (openNewWindow) {
    window.open(url, '_blank')
  } else {
    location.href = url
  }
}
