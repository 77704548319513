import axios from 'axios';
import * as utils from 'src/apis/apiUtils';

// 各関数の@paramは.tsファイルからimportした際に発生する「暗黙的なany型」のエラーを回避するためのもの
// FIXME このファイルをTypeScriptにして正しい型を指定する
export default {
  /**
   * @param {*} request
   */
  index({ workplaceId, params }) {
    return axios.get(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels`), {
      params: params || {},
    });
  },
  /**
   * @param {*} request
   */
  create({ workplaceId, data }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels`), data);
  },
  /**
   * @param {*} request
   */
  update({ workplaceId, data }) {
    return axios.put(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels/${data.id}`), data);
  },
  /**
   * @param {*} request
   */
  delete({ workplaceId, itemId }) {
    return axios.delete(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels/${itemId}`));
  },
  /**
   * @param {*} request
   */
  bulkUpdateDispOrder({ workplaceId, items }) {
    return axios.post(utils.getApiUrl(`/masters/workplaces/${workplaceId}/staff_labels/bulk_update_disp_order`), {
      items: items,
    });
  },
};
