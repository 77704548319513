



























import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { type TimetableHeader } from 'src/models/timetableHeader'
import DoughnutChart from 'src/components/UIComponents/Charts/DoughnutChart'
import { formatTime, formatInteger, orDefault } from '../../utils/filters'
import {
  getProgressRate,
  getProgressColor,
  getCurrentProductivityRank,
  getCurrentProductivityRate,
  getCurrentProductivityRateColor,
} from '../../utils/timetableHeaderHelpers'
import { getProductivityChartDataset } from './getProductivityChartDataset'
import { getProgressChartDataset } from './getProgressChartDataset'
import { useProgressBoard } from '../../composables/useProgressBoard'
import { type ChartDataSets } from 'chart.js'

export default defineComponent({
  components: {
    DoughnutChart,
  },
  filters: {
    formatTime,
  },
  props: {
    timetableHeader: {
      type: Object as PropType<TimetableHeader>,
      required: true,
    },
  },
  setup(props) {
    const { productivityGraph } = useProgressBoard()

    type ChartValueMap = {
      progressColor: string,
      dataset: ChartDataSets[],
      centerText: string,
      options: {
        cutoutPercentage: number,
        rotation?: number,
        circumference?: number,
      }
    }

    const chartValueMap = computed((): ChartValueMap => {
      if (productivityGraph.value) {
        return {
          progressColor: getCurrentProductivityRateColor(props.timetableHeader),
          dataset: getProductivityChartDataset(props.timetableHeader),
          centerText: `${getCurrentProductivityRank(props.timetableHeader)}
            ${orDefault(formatInteger(getCurrentProductivityRate(props.timetableHeader)), '-')}%`, // 改行を入れて2行で表示する
          options: { cutoutPercentage: 60, rotation: Math.PI * 0.875, circumference: Math.PI * 1.25 }
        }
      } else {
        return {
          progressColor: getProgressColor(props.timetableHeader),
          dataset: getProgressChartDataset(props.timetableHeader),
          centerText: `${orDefault(formatInteger(getProgressRate(props.timetableHeader)), '-')}%`,
          options: { cutoutPercentage: 60 }
        }
      }
    })

    const progressColor = computed(() => chartValueMap.value.progressColor)
    const dataset = computed(() => chartValueMap.value.dataset)
    const centerText = computed(() => chartValueMap.value.centerText)
    const options = computed(() => chartValueMap.value.options)

    return {
      progressColor,
      dataset,
      centerText,
      productivityGraph,
      options,
    }
  },
})
