




import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'custom-flag1-icon',
});
