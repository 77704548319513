import axios from 'axios'
import * as utils from 'src/apis/apiUtils'
import { StringOrNumber } from 'src/models/common'
import {
  ConversionProgram,
  convertConversionProgramResponse
} from 'src/models/conversionProgram'

export default {
  async index(workplaceId: StringOrNumber): Promise<ConversionProgram[]> {
    const { data: resData } = await axios.get(utils.getApiUrl(`/workplaces/${workplaceId}/conversion_programs`))
    return resData.map(convertConversionProgramResponse)
  },
}
