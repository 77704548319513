// 基準となる日付の差の最大値・最小値
export const APPROPRIATION_DATE_DIFF_MAX = 32;
export const APPROPRIATION_DATE_DIFF_MIN = -32;

// 基準となる日付の差
export const APPROPRIATION_DATE_DIFF_ANCHOR = {
  SAME_DAY: 0,
  NEXT_DAY: 1,
  DAY_AFTER_NEXT: 2,
  THREE_DAYS_LATER: 3,
  DAY_BEFORE: -1,
  TWO_DAYS_BEFORE: -2,
  THREE_DAYS_AGO: -3,
};
export const APPROPRIATION_DATE_DIFF_ANCHOR_OPTIONS = [
  { value: APPROPRIATION_DATE_DIFF_ANCHOR.SAME_DAY, label: '当日' },
  { value: APPROPRIATION_DATE_DIFF_ANCHOR.NEXT_DAY, label: '翌日' },
  { value: APPROPRIATION_DATE_DIFF_ANCHOR.DAY_AFTER_NEXT, label: '翌々日' },
  { value: APPROPRIATION_DATE_DIFF_ANCHOR.THREE_DAYS_LATER, label: '3日後' },
  { value: APPROPRIATION_DATE_DIFF_ANCHOR.DAY_BEFORE, label: '前日' },
  { value: APPROPRIATION_DATE_DIFF_ANCHOR.TWO_DAYS_BEFORE, label: '前々日' },
  { value: APPROPRIATION_DATE_DIFF_ANCHOR.THREE_DAYS_AGO, label: '3日前' },
];

// 基準日のみ/以前/以降
export const APPROPRIATION_DATE_RANGE = {
  ON: 0,
  ON_AND_BEFORE: 1,
  ON_AND_AFTER: 2,
};
export const APPROPRIATION_DATE_RANGE_OPTIONS = [
  { value: APPROPRIATION_DATE_RANGE.ON, label: '-' },
  { value: APPROPRIATION_DATE_RANGE.ON_AND_BEFORE, label: '以前' },
  { value: APPROPRIATION_DATE_RANGE.ON_AND_AFTER, label: '以降' },
];
