<template src="./template.html"></template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as C from 'src/consts';
import notificationMixin from 'src/mixins/notificationMixin';
import masterMixin from 'src/mixins/masterMixin';
import usersWorkplaceApi from 'src/apis/workplace_masters/users_workplace';
import timingControlMixin from 'src/mixins/timingControlMixin';
import BudgetGroupCard from './BudgetGroupCard';
import { setPageName } from 'src/hooks/displayPageNameHook';
import { ensureWorkplaceRoleGteOwner } from 'src/hooks/appRoleHook';

const displayPageName = '共有設定';

export default {
  components: { BudgetGroupCard },
  data() {
    return {
      displayPageName: displayPageName,
      searchParams: {
        displayListDisabledItem: false,
        user: {},
      },

      list: [],
      companyCandidates: [],
      saveCandidate: {},
      roleCandidates: [],
      inviteRoleCandidates: [],
      roleMap: {},
      userWorkplaceStatusMap: {},

      inviteModalInfo: {},
      deleteModalInfo: {},
      showSaveModal: false,
      showInviteModal: false,
      showCreateModal: false,
      showDeleteModal: false,

      validations: this.getValidationMap(),

      pagination: {
        perPage: 50,
        currentPage: 1,
        total: 0,
      },

      userRoleConst: {
        wOwner: C.USER_WORKPLACE_ROLE_W_OWNER,
        wLeader: C.USER_WORKPLACE_ROLE_W_LEADER,
        wMember: C.USER_WORKPLACE_ROLE_W_MEMBER,
      },
    };
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters('user', {
      userId: 'id',
    }),
    workplaceId() {
      return this.$route.params.workplaceId;
    },
    hasList() {
      return this.list.length > 0;
    },
    paginatedList() {
      const startIdx = this.pagination.perPage * Math.max(this.pagination.currentPage - 1, 0);
      return this.list.slice(startIdx, startIdx + this.pagination.perPage);
    },
    shouldPaginate() {
      return this.pagination.total > this.pagination.perPage;
    },
    hasError() {
      return this.errors.any();
    },
    hasUpdateError() {
      if (!('role' in this.saveCandidate)) {
        return false;
      }
      if (this.hasOtherOwner) {
        return false;
      }
      if (this.saveCandidate.user_id !== this.userId) {
        return false;
      }
      return this.saveCandidate.role !== this.userRoleConst.wOwner;
    },
    hasSearchParamsUser() {
      return Object.keys(this.searchParams.user).length > 0;
    },
    hasOtherOwner() {
      // whether other owner besides the logged in user exists or not
      return this.list.some((e) => {
        return e.role === this.userRoleConst.wOwner && e.user_id !== this.userId;
      });
    },
  },
  mixins: [notificationMixin, masterMixin, timingControlMixin],
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getValidationMap() {
      const ruleStr = {
        required: true,
        max: 255,
      };
      return {
        email: { required: true, email: true },
        email_confirm: { required: true, confirmed: 'inviteEmail' },
        role: { required: true },
        family_name: ruleStr,
        first_name: ruleStr,
        job_title: ruleStr,
        password: {
          required: true,
          min: 8,
        },
        password_confirmation: {
          required: true,
          confirmed: 'password',
        },
      };
    },
    clearErrors() {
      this.$validator.reset();
    },
    async getList() {
      const { data } = await usersWorkplaceApi.index({ workplaceId: this.workplaceId });
      data.forEach((e) => {
        // alias
        e.fullName = `${e.user.family_name} ${e.user.first_name}`;
        e.email = e.user.email;
      });

      // sort
      const sortValMap = {
        [this.userRoleConst.wOwner]: 1,
        [this.userRoleConst.wLeader]: 2,
        [this.userRoleConst.wMember]: 3,
      };
      data.sort((a, b) => {
        const v1 = sortValMap[a.role] || 1000;
        const v2 = sortValMap[b.role] || 1000;
        return v1 < v2 ? -1 : 1;
      });

      this.list = data;
      // default selected
      if (Object.keys(this.searchParams.user).length === 0 && this.hasList) {
        this.searchParams.user = this.list[0];
      }
      this.pagination.total = this.list.length;
    },
    async getCompanies() {
      const { data: companies } = await usersWorkplaceApi.getCompanies({ workplaceId: this.workplaceId });
      this.companyCandidates = companies;
    },
    openSaveModal(item) {
      this.saveCandidate = JSON.parse(JSON.stringify(item));
      this.showSaveModal = true;
    },
    closeSaveModal() {
      this.saveCandidate = {};
      // this.clearErrors()
      this.showSaveModal = false;
    },
    async saveItem() {
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.saveCandidate,
        };
        await usersWorkplaceApi.update(reqObj);
        this.closeSaveModal();
        this.notifySuccess1('役割を変更しました');
        // If owner changes themself to leader or member then,
        // kick-out to setting screen. (only owner can see this screen)
        if (reqObj.data.user_id === this.userId && reqObj.data.role !== this.userRoleConst.wOwner) {
          // store must be updated
          const roleMap = this.$store.getters['user/workplaceRoleMap'];
          roleMap[this.workplaceId].role = reqObj.data.role;
          await this.$router.push({ name: 'SettingsSelect' });
        } else {
          this.getList();
        }
      } catch (err) {
        const errId = 'ERR00001';
        const msg =
          '役割の変更に失敗しました。' +
          '管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
        this.notifyError1(msg, { err });
      }
    },
    openInviteModal(item) {
      this.inviteModalInfo = {
        role: this.inviteRoleCandidates[0].key,
      };
      this.showInviteModal = true;
    },
    closeInviteModal() {
      this.clearErrors();
      this.showInviteModal = false;
    },
    isDuplicateEmail(email) {
      for (const item of this.list) {
        if (item.email === email) {
          return true;
        }
      }
      return false;
    },
    async inviteToWorkplace() {
      const isValid = await this.$validator.validateAll('formInvite');
      if (!isValid) {
        return;
      }

      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.inviteModalInfo,
        };
        await usersWorkplaceApi.create(reqObj);
        this.getList();
        this.closeInviteModal();
        this.notifySuccess1('ユーザーをセンターに招待しました');
      } catch (err) {
        const errStatus = err.response.status;
        const errRes = err.response.data || {};
        if (errStatus === 404) {
          const msg = 'そのEメールで登録済のユーザーが見つかりませんでした。';
          this.notifyError1(msg, { timeout: 5 * 1000 });
          this.closeInviteModal();
          this.openCreateModal();
        } else if (errStatus === 400 && errRes.reason === 'dup_email') {
          const msg =
            'そのEメールはこのセンターに招待済みです。' +
            '招待しなおす場合は、一度削除してから、再度招待をしてください';
          this.notifyError1(msg, { timeout: 5 * 1000 });
        } else {
          const errId = 'ERR00002';
          const msg =
            'センターへの招待に失敗しました。' +
            '管理者に連絡してください。' +
            `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
          this.notifyError1(msg, { err });
        }
      }
    },
    openCreateModal(item) {
      this.inviteModalInfo.staffAgencyName = this.companyCandidates[0].name;
      this.inviteModalInfo.company_id = this.companyCandidates[0].id;
      this.showCreateModal = true;
    },
    closeCreateModal() {
      this.clearErrors();
      this.showCreateModal = false;
    },
    async createUserAndInviteToWorkplace() {
      const isValid = await this.$validator.validateAll('formCreate');
      if (!isValid) {
        return;
      }

      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          data: this.inviteModalInfo,
        };
        await usersWorkplaceApi.createWithUser(reqObj);
        this.getList();
        this.closeCreateModal();
        this.notifySuccess1('ユーザーを登録し、センターに招待しました');
      } catch (err) {
        const errId = 'ERR00002';
        const msg =
          'ユーザー登録とセンターへの招待に失敗しました。' +
          '管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
        this.notifyError1(msg, { err });
      }
    },
    openDeleteModal(item) {
      this.deleteModalInfo = item;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
    },
    async deleteShareSetting() {
      try {
        const reqObj = {
          workplaceId: this.workplaceId,
          itemId: this.deleteModalInfo.id,
        };
        await usersWorkplaceApi.delete(reqObj);
        this.getList();
        this.closeDeleteModal();
        this.notifySuccess1('共有設定を削除しました');
      } catch (err) {
        const errId = 'ERR00003';
        const msg =
          '共有設定の削除に失敗しました。' +
          '管理者に連絡してください。' +
          `(ERR: ${displayPageName} ${errId}, user_id:${this.userId})`;
        this.notifyError1(msg, { err });
      }
    },
    makeFuncGated() {
      this.saveItem = this.makeAsyncFuncGated(this.saveItem);
      this.inviteToWorkplace = this.makeAsyncFuncGated(this.inviteToWorkplace);
      this.deleteShareSetting = this.makeAsyncFuncGated(this.deleteShareSetting);
    },
    selectItem(item) {
      this.searchParams.user = item;
    },
    isSelectedItem(item) {
      if (Object.keys(this.searchParams.user).length === 0) {
        return false;
      }
      if (this.searchParams.user.user_id !== item.user_id) {
        return false;
      }
      return true;
    },
    onRoleChange() {
      if (!this.hasOtherOwner) {
        if (this.saveCandidate.role !== this.userRoleConst.wOwner) {
          this.hasUpdateError = true;
        }
      }
    },
    async onCompanyChange(company) {
      this.inviteModalInfo.company_id = company.id;
    },
  },
  created() {
    this.makeFuncGated();
  },
  async mounted() {
    setPageName(this, displayPageName);
    await this.ensureUserAndMasters();
    // 招待用のrole候補
    this.inviteRoleCandidates = window.master.lovs.user_role.vals_inuse.filter((e) => {
      // オーナーとして招待することはできない
      return e.key !== this.userRoleConst.wOwner;
    });
    // 編集用のrole候補
    this.roleCandidates = window.master.lovs.user_role.vals_inuse;
    this.roleMap = window.master.lovs.user_role.map_inuse;
    this.userWorkplaceStatusMap = window.master.lovs.user_workplace_status.map_inuse;
    await ensureWorkplaceRoleGteOwner(this, this.workplaceId);
    await this.getList();
    await this.getCompanies();
  },
};
</script>

<style lang="scss" src="./style.scss" scoped></style>
