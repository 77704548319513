





















import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { type TimetableHeader } from 'src/models/timetableHeader'
import { formatInteger, formatTime, orDefault } from '../../utils/filters'
import {
  getPredictedCompleteTime,
  getPredictedCompleteTimeColor,
  getPredictedCompleteAmount,
} from '../../utils/timetableHeaderHelpers'
import { useProgressBoard } from '../../composables/useProgressBoard'

export default defineComponent({
  filters: {
    formatInteger,
    formatTime,
    orDefault,
  },
  props: {
    timetableHeader: {
      type: Object as PropType<TimetableHeader>,
      required: true,
    },
  },
  setup(props) {
    const predictedCompleteTime = computed(() => getPredictedCompleteTime(props.timetableHeader))
    const predictedCompleteTimeColor = computed(() => getPredictedCompleteTimeColor(props.timetableHeader))
    const predictedCompleteAmount = computed(() => getPredictedCompleteAmount(props.timetableHeader))
    const { productivityGraph } = useProgressBoard()

    return {
      predictedCompleteTime,
      predictedCompleteTimeColor,
      predictedCompleteAmount,
      productivityGraph,
    }
  },
})
