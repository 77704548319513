



















import { defineComponent, computed, type PropType } from '@vue/composition-api'
import { type ProgressHeader } from 'src/models/progressHeader'
import { orDefault } from 'src/util/filters'
import { formatNumber } from '../../utils/filters'

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
  setup(props) {
    const headcount = computed(() => props.progressHeader.latest_progress_headcount_detail?.headcount ?? null)
    const headcountsByLabel = computed(() =>
      (props.progressHeader.latest_progress_headcount_detail?.headcount_by_label ?? []).filter((v) => v.count > 0)
    )

    return {
      headcount,
      headcountsByLabel,
    }
  },
})
