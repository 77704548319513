import { ref, watch, type Ref } from '@vue/composition-api'
import { useBudgetGroups } from 'src/composables/useBudgetGroups'
import { type BudgetGroup } from 'src/models/budgetGroup'
import { createInjection } from 'src/util/createInjection'
import { isExist } from 'src/util/isExist'

type Params = {
  workplaceId: number
}

type InjectionValue = {
  workplaceId: number
  dt: Ref<Date>
  budgetGroup: Ref<BudgetGroup | null>
  selectedBudgetGroupId: Ref<number | null>
  initSearchCondition: (budgetGroupId: number | undefined) => void
}

const { provide, inject } = createInjection<InjectionValue>('useSearchConditions')

export function useSearchConditionsProvider({ workplaceId }: Params): void {
  const { budgetGroups } = useBudgetGroups()
  const dt = ref(new Date())
  const budgetGroup = ref<BudgetGroup | null>(null)
  const selectedBudgetGroupId = ref<number | null>(null)
  const defaultBudgetGroupId = ref<number>()
  const initializePromiseResolver = ref<() => void>()
  const initSearchConditionPromise = new Promise<void>((resolve) => {
    initializePromiseResolver.value = resolve
  })

  const initSearchCondition = async(budgetGroupId: number | undefined) => {
    defaultBudgetGroupId.value = budgetGroupId
    // SearchCondition の準備が整ったことを伝えるための Promise を返す。
    // この Promise は budgetGroups の取得が完了し、budgetGroup の準備ができた時点で resolve する。
    return initSearchConditionPromise
  }

  watch(() => budgetGroups.value, () => {
    if (budgetGroups.value.length === 0) { return }
    const _budgetGroup = budgetGroups.value.find((budgetGroup) => budgetGroup.id === defaultBudgetGroupId.value)
    if (!isExist(_budgetGroup)) {
      budgetGroup.value = budgetGroups.value[0]
      selectedBudgetGroupId.value = budgetGroup.value.id
      return
    }

    budgetGroup.value = _budgetGroup
    selectedBudgetGroupId.value = _budgetGroup.id
    // budgetGroup の準備が完了したので initSearchConditionPromise を resolve する。
    initializePromiseResolver.value?.()
  })

  provide({
    workplaceId,
    dt,
    budgetGroup,
    selectedBudgetGroupId,
    initSearchCondition,
  })
}

export function useSearchConditions(): InjectionValue {
  return inject()
}
