import dateTimeMixin from 'src/mixins/dateTimeMixin'

export default {
  mixins: [dateTimeMixin],
  methods: {
    doNotify(msg, opts = {}) {
      const notifyObj = {
        message: msg,
        verticalAlign: opts.verticalAlign || 'top', // top | bottom
        horizontalAlign: opts.horizontalAlign || 'center', // right | center | left
        type: opts.type || 'info', // info | warning | danger | success | primary
        timeout: opts.timeout || 5 * 1000,
      }
      if (opts.title) { notifyObj.title = opts.title }
      if (opts.icon) { notifyObj.icon = opts.icon }
      if (opts.component) { notifyObj.component = opts.component }

      this.$notify(notifyObj)
    },
    notifySuccess1(msg, opts = {}) {
      opts.timeout = opts.timeout || 3 * 1000
      this.doNotify(msg, opts)
    },
    notifyError1(msg, opts = {}) {
      console.error(msg)
      if (opts.err) {
        console.error(opts.err)
      }
      opts.type = opts.type || 'danger'
      opts.timeout = opts.timeout || 60 * 1000
      opts.icon = opts.icon || 'fas fa-exclamation-triangle'
      this.doNotify(msg, opts)
    },
  }
}
