










import { defineComponent } from '@vue/composition-api'
import { useUpdateShiftPattern } from '../../composables/useUpdateShiftPattern'
import { ShiftPatternInputModal, type ShiftPatternInput } from '../ShiftPatternInputModal'

export default defineComponent({
  components: {
    ShiftPatternInputModal,
  },
  setup() {
    const {
      shiftPattern,
      showsUpdateModal,
      hideUpdateModal,
      updateShiftPattern,
    } = useUpdateShiftPattern()
    const handleSubmit = async(shiftPatternInput: ShiftPatternInput) => {
      if (shiftPattern.value !== null) {
        await updateShiftPattern({
          ...shiftPattern.value,
          ...shiftPatternInput,
        })
      }
    }

    return {
      shiftPattern,
      showsUpdateModal,
      hideUpdateModal,
      handleSubmit,
    }
  },
})
