import { type ShiftPhase, SHIFT_PHASE, ATTENDANCE_TYPE } from 'src/consts';
import { type PreShift, type PreShiftItem, type Shift, type ShiftItem } from 'src/models/regularShift';

const { ACTUAL, SCHEDULED, PROVISIONAL } = SHIFT_PHASE;

const { TBD } = ATTENDANCE_TYPE;

type ShiftType = typeof ACTUAL | typeof SCHEDULED | typeof PROVISIONAL;

export type GenericShift = {
  hoursTotal: number;
  hoursNormal: number;
  hoursOvertime: number;
  shiftId: number;
  shiftType: ShiftType;
  attendanceType: number;
  startTime: number;
  endTime: number;
};

export type NullShift = {
  hoursTotal: 0;
  hoursNormal: 0;
  hoursOvertime: 0;
  shiftId: null;
  shiftType: ShiftType;
  attendanceType: null;
  startTime: null;
  endTime: null;
};

const getGenericPreShift = (preShift: PreShift): GenericShift => {
  return {
    hoursTotal: Number(preShift.hours_total),
    hoursNormal: Number(preShift.hours_normal),
    hoursOvertime: Number(preShift.hours_overtime),
    shiftId: preShift.id,
    shiftType: PROVISIONAL,
    attendanceType: preShift.attendance_type,
    startTime: preShift.work_start_time,
    endTime: preShift.work_end_time,
  };
};

const getGenericScheduledShift = (shift: Shift): GenericShift => {
  return {
    hoursTotal: Number(shift.scheduled_hours_total),
    hoursNormal: Number(shift.scheduled_hours_normal),
    hoursOvertime: Number(shift.scheduled_hours_overtime),
    shiftId: shift.id,
    shiftType: SCHEDULED,
    attendanceType: shift.scheduled_attendance_type,
    startTime: shift.scheduled_work_start_time,
    endTime: shift.scheduled_work_end_time,
  };
};

const getGenericActualShift = (shift: Shift): GenericShift => {
  return {
    hoursTotal: Number(shift.actual_hours_total),
    hoursNormal: Number(shift.actual_hours_normal),
    hoursOvertime: Number(shift.actual_hours_overtime),
    shiftId: shift.id,
    shiftType: ACTUAL,
    attendanceType: shift.actual_attendance_type,
    startTime: shift.actual_work_start_time,
    endTime: shift.actual_work_end_time,
  };
};

const getNullShift = (shiftType: ShiftType): NullShift => {
  return {
    hoursTotal: 0,
    hoursNormal: 0,
    hoursOvertime: 0,
    shiftId: null,
    shiftType: shiftType,
    attendanceType: null,
    startTime: null,
    endTime: null,
  };
};

const selectPreferredShift = (
  scheduledShift: GenericShift | NullShift,
  preShift: GenericShift | NullShift,
): GenericShift | NullShift => {
  // 確定シフトが未定の場合、仮シフトを表示する
  return scheduledShift.attendanceType === TBD ? preShift : scheduledShift;
};

export const getGenericShift = (
  shift: ShiftItem,
  preShift: PreShiftItem,
  displayMode: ShiftPhase,
): GenericShift | NullShift => {
  const genericPreShift = preShift.data ? getGenericPreShift(preShift.data) : getNullShift(PROVISIONAL);
  const genericScheduledShift = shift.data ? getGenericScheduledShift(shift.data) : getNullShift(SCHEDULED);
  const genericActualShift = shift.data ? getGenericActualShift(shift.data) : getNullShift(ACTUAL);

  switch (displayMode) {
    case PROVISIONAL:
      return genericPreShift;
    case SCHEDULED:
      return selectPreferredShift(genericScheduledShift, genericPreShift);
    case ACTUAL:
      return genericActualShift;
    default:
      return getNullShift(ACTUAL);
  }
};
