


















import { defineComponent, watch, type PropType } from '@vue/composition-api';
import { type MacroOperationMaster } from 'src/models/macroOperationMaster';

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<MacroOperationMaster[]>,
      required: true,
    },
    macroOperationMasters: {
      type: Array as PropType<MacroOperationMaster[]>,
      required: true,
    },
  },
  setup(props, context) {
    watch(
      () => props.macroOperationMasters,
      (macroOperationMasters) => {
        const macroOperationMasterIds = macroOperationMasters.map((v) => v.id);
        const newValue = props.value.filter((v) => macroOperationMasterIds.includes(v.id));
        context.emit('input', newValue);
      },
      {
        immediate: true,
      },
    );
  },
});
