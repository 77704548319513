












import { defineComponent, computed } from '@vue/composition-api'
import { ModalForm } from 'src/components/UIComponents/Form'
import { useDeleteShiftPattern } from '../../composables/useDeleteShiftPattern'

export default defineComponent({
  components: {
    ModalForm,
  },
  setup() {
    const {
      shiftPattern,
      showsDeleteConfirmationModal,
      hideDeleteConfirmationModal,
      deleteShiftPattern,
    } = useDeleteShiftPattern()

    return {
      shiftPatternName: computed(() => shiftPattern.value?.name),
      showsDeleteConfirmationModal,
      hideDeleteConfirmationModal,
      deleteShiftPattern,
    }
  },
})
