










import { defineComponent, ref, watch, type PropType } from '@vue/composition-api';
import { TextInput } from 'src/components/UIComponents/Inputs/TextInput';
import { isNumeric } from './isNumeric';

export default defineComponent({
  components: {
    TextInput,
  },
  props: {
    value: {
      type: Number as PropType<number | null>,
      default: null,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    /**
     * VeeValidate(v2)のValidationProviderにNaNを渡すとハングするので、これを回避するためにNaNの代わりに使用する数値を指定する。
     * ValidationProviderに設定したrulesの範囲外の数値を指定することを想定している。
     * 例えば、rulesのmin_valueに0以上が設定されている場合は-1、何らかのmax_valueが設定されている場合はNumber.MAX_SAFE_INTEGERを指定する。
     * @see {@link https://github.com/logaretm/vee-validate/issues/2765}
     */
    valueInsteadOfNan: {
      type: Number as PropType<number | null>,
      default: null,
    },
    withArrows: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const valueRef = ref<string>(String(props.value ?? ''));
    const getNormalizedValue = (value: string) => {
      if (isNumeric(value)) {
        return Number(value);
      }
      return value === '' ? null : props.valueInsteadOfNan ?? NaN;
    };

    watch(valueRef, (value) => {
      context.emit('input', getNormalizedValue(value));
    });

    watch(
      () => props.value,
      (newValue) => {
        const currentValue = getNormalizedValue(valueRef.value);
        if (newValue !== currentValue && !Number.isNaN(newValue)) {
          valueRef.value = String(newValue ?? '');
        }
      },
    );

    return {
      valueRef,
    };
  },
});
