import progressHeaderApi from 'src/apis/progressHeader'
import { useErrorBoundary } from 'src/composables/useErrorBoundary'
import { useNotification } from 'src/composables/useNotification'
import { type ProgressHeader } from 'src/models/progressHeader'
import { useProgressHeader } from './useProgressHeader'

type UpdateActualCompletionTime = {
  updateActualCompletionTime: (actualCompletionTime: ProgressHeader['actual_completion_time']) => Promise<boolean>
}

export function useUpdateActualCompletionTime(): UpdateActualCompletionTime {
  const { progressHeader, fetchProgressHeader } = useProgressHeader()
  const errorBoundary = useErrorBoundary()
  const { notifySuccess } = useNotification()
  const updateActualCompletionTime = errorBoundary.wrap(
    async(actualCompletionTime: ProgressHeader['actual_completion_time']) => {
      await progressHeaderApi.update({
        id: progressHeader.value.id,
        workplace_id: progressHeader.value.workplace_id,
        actual_completion_time: actualCompletionTime,
      })
      notifySuccess('完了時刻を登録しました')
      await fetchProgressHeader()
    },
    {
      shouldContactUs: true,
    },
  )

  return {
    updateActualCompletionTime,
  }
}
