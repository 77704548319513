import { parseDatetime, parseYmdDate } from 'src/util/datetime';
import { BudgetGroupPlanBoardMiscResponse } from 'src/models/api/budgetGroupPlanBoardMiscResponse';
import { isExist } from 'src/util/isExist';

export interface BudgetGroupPlanBoardMisc {
  id: number;
  budget_group_id: number;
  dt: Date;
  last_save_timestamp: Date | null;
  last_saved_by: number | null;
  memo: string | null;
  budget_target: number | null;
  cost_target: number | null;
  planned_spot1_headcount: number | null;
  planned_spot2_headcount: number | null;
  planned_spot3_headcount: number | null;
  planned_spot4_headcount: number | null;
  actual_regular_man_seconds: number | null;
  actual_spot_man_seconds: number | null;
  actual_revenue: number | null;
  actual_cost: number | null;
  actual_indirect_cost: number | null;
}

export function convertBudgetGroupPlanBoardMiscResponse(
  response: BudgetGroupPlanBoardMiscResponse,
): BudgetGroupPlanBoardMisc {
  return {
    ...response,
    planned_spot1_headcount: isExist(response.planned_spot1_headcount)
      ? Number(response.planned_spot1_headcount)
      : null,
    planned_spot2_headcount: isExist(response.planned_spot2_headcount)
      ? Number(response.planned_spot2_headcount)
      : null,
    planned_spot3_headcount: isExist(response.planned_spot3_headcount)
      ? Number(response.planned_spot3_headcount)
      : null,
    planned_spot4_headcount: isExist(response.planned_spot4_headcount)
      ? Number(response.planned_spot4_headcount)
      : null,
    dt: parseYmdDate(response.dt),
    last_save_timestamp: response.last_save_timestamp ? parseDatetime(response.last_save_timestamp) : null,
    actual_regular_man_seconds: isExist(response.actual_regular_man_seconds)
      ? Number(response.actual_regular_man_seconds)
      : null,
    actual_spot_man_seconds: isExist(response.actual_spot_man_seconds)
      ? Number(response.actual_spot_man_seconds)
      : null,
    actual_revenue: isExist(response.actual_revenue) ? Number(response.actual_revenue) : null,
    actual_cost: isExist(response.actual_cost) ? Number(response.actual_cost) : null,
    actual_indirect_cost: isExist(response.actual_indirect_cost) ? Number(response.actual_indirect_cost) : null,
  };
}
