import { ref, type Ref } from '@vue/composition-api';
import staffShiftApi from 'src/apis/staffShift';
import { useErrorBoundary } from 'src/composables/useErrorBoundary';
import { useModal } from 'src/composables/useModal';
import { useNotification } from 'src/composables/useNotification';
import { type BudgetGroup } from 'src/models/budgetGroup';
import { createInjection } from 'src/util/createInjection';

type Params = {
  budgetGroup: Ref<BudgetGroup>;
};

type Target = 'pre' | 'pre+scheduled';

type InjectionValue = {
  target: Ref<Target>;
  showsStaffShiftCsvUploadModal: ReturnType<typeof useModal>['showsModal'];
  showStaffShiftCsvUploadModal: ReturnType<typeof useModal>['showModal'];
  hideStaffShiftCsvUploadModal: ReturnType<typeof useModal>['hideModal'];
  uploadStaffShiftCsv: (file: File) => Promise<boolean>;
};

const { provide, inject } = createInjection<InjectionValue>('useUploadStaffShiftCsv');

export function useUploadStaffShiftCsvProvider({ budgetGroup }: Params): void {
  const target = ref<Target>('pre');
  const errorBoundary = useErrorBoundary();
  const { showsModal: showsStaffShiftCsvUploadModal, showModal, hideModal: hideStaffShiftCsvUploadModal } = useModal();
  const { notifySuccess } = useNotification();
  const showStaffShiftCsvUploadModal = () => {
    target.value = 'pre';
    showModal();
  };
  const uploadStaffShiftCsv = errorBoundary.wrap(async (file: File) => {
    await staffShiftApi.importCsv({
      workplace_id: budgetGroup.value.workplace_id,
      budget_group_id: budgetGroup.value.id,
      target: target.value,
      csv: file,
    });
    hideStaffShiftCsvUploadModal();
    notifySuccess('CSVから登録を開始しました');
  });

  provide({
    target,
    showsStaffShiftCsvUploadModal,
    showStaffShiftCsvUploadModal,
    hideStaffShiftCsvUploadModal,
    uploadStaffShiftCsv,
  });
}

export function useUploadStaffShiftCsv(): InjectionValue {
  return inject();
}
