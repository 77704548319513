import axios, { AxiosResponse } from 'axios'
import { getApiUrl } from './apiUtils'
import { BudgetGroupResponse } from 'src/models/api/budgetGroupResponse'
import { BudgetGroup, convertBudgetGroupResponse } from 'src/models/budgetGroup'
import { StringOrNumber } from 'src/models/common'

const apis = {
  index(workplaceId: StringOrNumber): Promise<AxiosResponse<BudgetGroupResponse[]>> {
    return axios.get(getApiUrl(`/workplaces/${workplaceId}/budget_groups`))
  },
  show(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): Promise<AxiosResponse<BudgetGroupResponse>> {
    return axios.get(getApiUrl(`/workplaces/${workplaceId}/budget_groups/${budgetGroupId}`))
  },
}

export const apisWithTransformedData = {
  async index(workplaceId: StringOrNumber): Promise<BudgetGroup[]> {
    const { data } = await apis.index(workplaceId)
    return data.map(convertBudgetGroupResponse)
  },
  async show(workplaceId: StringOrNumber, budgetGroupId: StringOrNumber): Promise<BudgetGroup> {
    const { data } = await apis.show(workplaceId, budgetGroupId)
    return convertBudgetGroupResponse(data)
  },
}

export default apis
