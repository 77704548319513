







import { defineComponent, SetupContext, onMounted } from '@vue/composition-api'
import { setPageName } from 'src/hooks/displayPageNameHook'
import { ensureSuperAdmin } from 'src/hooks/appRoleHook'

export default defineComponent({
  setup(props, context: SetupContext) {
    setPageName(context.root, 'スーパーメンテ')
    onMounted(() => {
      ensureSuperAdmin(context)
    })
  },
})
