import type { WeekKey, WeekName } from 'src/views/Dashboard/Workplace/Settings/StaffExtension/setting';

export const WEEK: { key: WeekKey; name: WeekName; value: number }[] = [
  { key: 'mon', name: '月', value: 1 },
  { key: 'tue', name: '火', value: 2 },
  { key: 'wed', name: '水', value: 3 },
  { key: 'thu', name: '木', value: 4 },
  { key: 'fri', name: '金', value: 5 },
  { key: 'sat', name: '土', value: 6 },
  { key: 'sun', name: '日', value: 0 },
];

export const MEMO_MAX_LENGTH = 255;

export const BREAK_TIME_KEYS = [
  {
    breakNumber: 1,
    startTimeHour: 'break1_start_time_hour',
    startTimeMin: 'break1_start_time_min',
    endTimeHour: 'break1_end_time_hour',
    endTimeMin: 'break1_end_time_min',
    breakStartTimeLabelName: 'break1_start_time',
    breakEndTimeLabelName: 'break1_end_time',
  },
  {
    breakNumber: 2,
    startTimeHour: 'break2_start_time_hour',
    startTimeMin: 'break2_start_time_min',
    endTimeHour: 'break2_end_time_hour',
    endTimeMin: 'break2_end_time_min',
    breakStartTimeLabelName: 'break2_start_time',
    breakEndTimeLabelName: 'break2_end_time',
  },
] as const;

export const EXTRA_DUTY_KEYS = [
  {
    extraDutyNumber: 1,
    macroOperationMasterId: 'duty1_macro_operation_master_id',
    startTimeHour: 'duty1_start_time_hour',
    startTimeMin: 'duty1_start_time_min',
    endTimeHour: 'duty1_end_time_hour',
    endTimeMin: 'duty1_end_time_min',
    dutyStartTimeLabelName: 'duty1_start_time',
    dutyEndTimeLabelName: 'duty1_end_time',
  },
  {
    extraDutyNumber: 2,
    macroOperationMasterId: 'duty2_macro_operation_master_id',
    startTimeHour: 'duty2_start_time_hour',
    startTimeMin: 'duty2_start_time_min',
    endTimeHour: 'duty2_end_time_hour',
    endTimeMin: 'duty2_end_time_min',
    dutyStartTimeLabelName: 'duty2_start_time',
    dutyEndTimeLabelName: 'duty2_end_time',
  },
] as const;

export const MACRO_OPERATION_MASTER_ID_NO_CHANGE = 0;

export const CSV_HEADERS: string[] = [
  '社員番号',
  '姓',
  '名',
  '性別',
  'シフト管理対象',
  '計上対象',
  'スタッフメモ',
  'キープレイヤー',
  'フォークマン',
  'カスタムフラグ1',
  'カスタムフラグ2',
  'カスタムフラグ3',
  'シフトグループ',
  '工程順序1',
  '工程順序2',
  '工程順序3',
  '工程順序4',
  '工程順序5',
  '月曜日 出勤時刻',
  '月曜日 退勤時刻',
  '月曜日 休憩1 開始時刻',
  '月曜日 休憩1 終了時刻',
  '月曜日 休憩2 開始時刻',
  '月曜日 休憩2 終了時刻',
  '月曜日 優先1 シフトグループ',
  '月曜日 優先1 開始時刻',
  '月曜日 優先1 終了時刻',
  '月曜日 優先2 シフトグループ',
  '月曜日 優先2 開始時刻',
  '月曜日 優先2 終了時刻',
  '火曜日 出勤時刻',
  '火曜日 退勤時刻',
  '火曜日 休憩1 開始時刻',
  '火曜日 休憩1 終了時刻',
  '火曜日 休憩2 開始時刻',
  '火曜日 休憩2 終了時刻',
  '火曜日 優先1 シフトグループ',
  '火曜日 優先1 開始時刻',
  '火曜日 優先1 終了時刻',
  '火曜日 優先2 シフトグループ',
  '火曜日 優先2 開始時刻',
  '火曜日 優先2 終了時刻',
  '水曜日 出勤時刻',
  '水曜日 退勤時刻',
  '水曜日 休憩1 開始時刻',
  '水曜日 休憩1 終了時刻',
  '水曜日 休憩2 開始時刻',
  '水曜日 休憩2 終了時刻',
  '水曜日 優先1 シフトグループ',
  '水曜日 優先1 開始時刻',
  '水曜日 優先1 終了時刻',
  '水曜日 優先2 シフトグループ',
  '水曜日 優先2 開始時刻',
  '水曜日 優先2 終了時刻',
  '木曜日 出勤時刻',
  '木曜日 退勤時刻',
  '木曜日 休憩1 開始時刻',
  '木曜日 休憩1 終了時刻',
  '木曜日 休憩2 開始時刻',
  '木曜日 休憩2 終了時刻',
  '木曜日 優先1 シフトグループ',
  '木曜日 優先1 開始時刻',
  '木曜日 優先1 終了時刻',
  '木曜日 優先2 シフトグループ',
  '木曜日 優先2 開始時刻',
  '木曜日 優先2 終了時刻',
  '金曜日 出勤時刻',
  '金曜日 退勤時刻',
  '金曜日 休憩1 開始時刻',
  '金曜日 休憩1 終了時刻',
  '金曜日 休憩2 開始時刻',
  '金曜日 休憩2 終了時刻',
  '金曜日 優先1 シフトグループ',
  '金曜日 優先1 開始時刻',
  '金曜日 優先1 終了時刻',
  '金曜日 優先2 シフトグループ',
  '金曜日 優先2 開始時刻',
  '金曜日 優先2 終了時刻',
  '土曜日 出勤時刻',
  '土曜日 退勤時刻',
  '土曜日 休憩1 開始時刻',
  '土曜日 休憩1 終了時刻',
  '土曜日 休憩2 開始時刻',
  '土曜日 休憩2 終了時刻',
  '土曜日 優先1 シフトグループ',
  '土曜日 優先1 開始時刻',
  '土曜日 優先1 終了時刻',
  '土曜日 優先2 シフトグループ',
  '土曜日 優先2 開始時刻',
  '土曜日 優先2 終了時刻',
  '日曜日 出勤時刻',
  '日曜日 退勤時刻',
  '日曜日 休憩1 開始時刻',
  '日曜日 休憩1 終了時刻',
  '日曜日 休憩2 開始時刻',
  '日曜日 休憩2 終了時刻',
  '日曜日 優先1 シフトグループ',
  '日曜日 優先1 開始時刻',
  '日曜日 優先1 終了時刻',
  '日曜日 優先2 シフトグループ',
  '日曜日 優先2 開始時刻',
  '日曜日 優先2 終了時刻',
];
