











import { defineComponent, computed } from '@vue/composition-api'
import { splitLines } from './splitLines'

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const lines = computed(() => splitLines(props.value))

    return {
      lines,
    }
  },
})
