











import { defineComponent, ref, watch, type PropType } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import EditableNumberMain from './EditableNumberMain.vue'

export default defineComponent({
  components: {
    ValidationProvider,
    EditableNumberMain,
  },
  props: {
    rules: {
      type: String,
      default: undefined,
    },
    value: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  setup(props, context) {
    const valueRef = ref(props.value)
    const hasFocus = ref(false)
    const handleFocus = () => {
      hasFocus.value = true
    }
    const handleBlur = (valid: boolean) => {
      hasFocus.value = false
      if (valid && props.value !== valueRef.value) {
        context.emit('input', valueRef.value)
      }
    }

    watch(() => props.value, (value) => {
      if (!hasFocus.value) {
        valueRef.value = value
      }
    })

    return {
      valueRef,
      hasFocus,
      handleFocus,
      handleBlur,
    }
  },
})
