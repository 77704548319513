














import { defineComponent, type PropType } from '@vue/composition-api';
import { type ProgressHeader } from 'src/models/progressHeader';
import { orDefault } from 'src/util/filters';
import { formatNumber } from '../../utils/filters';

export default defineComponent({
  filters: {
    orDefault,
    formatNumber,
  },
  props: {
    progressHeader: {
      type: Object as PropType<ProgressHeader>,
      required: true,
    },
  },
});
