import { createInjection } from 'src/util/createInjection';
import { DailyPlan } from '../types';
import { useDisplayConditions } from './useDisplayConditions';
import { useSearchConditions } from './useSearchConditions';
import budgetGroupPlanBoardMiscApi from 'src/apis/budgetGroupPlanBoardMisc';
import { useNotification } from 'src/composables/useNotification';
import { isExist } from 'src/util/isExist';
import { useMonthlyPlanOverview } from './useMonthlyPlanOverview';
import { useMonthlyPlan } from './useMonthlyPlan';
import { convertToHeadcount, convertToManHours } from '../monthlyPlanHelper';

type InjectionValue = {
  updateMiscPlanValue: (dailyPlan: DailyPlan, label: string) => Promise<void>;
  updateMiscActualValue: (dailyPlan: DailyPlan, label: string) => Promise<void>;
  updateTargetMonthlyValue: () => Promise<void>;
  updateMemo: (dailyPlan: DailyPlan) => Promise<void>;
};

const { provide, inject } = createInjection<InjectionValue>('useBudgetGroupPlanBoardMisc');

export function useBudgetGroupPlanBoardMiscProvider(): void {
  const { budgetGroup, workplaceId } = useSearchConditions();
  const { state: displayState } = useDisplayConditions();
  const { updateOverviewState } = useMonthlyPlanOverview();
  const { notifyError, notifySuccess } = useNotification();
  const { state, calculateDailyPlan } = useMonthlyPlan();

  /**
   * 日付に紐づく予定値の更新
   */
  const updateMiscPlanValue = async (dailyPlan: DailyPlan, label: string) => {
    if (!budgetGroup.value) {
      return;
    }
    if (!isExist(state.isReady)) {
      return;
    }

    const dayIndex = state.targetDates.findIndex((date) => date.getDate() === dailyPlan.dt.getDate());
    const ManHourKeys = ['spot1ManHours', 'spot2ManHours', 'spot3ManHours', 'spot4ManHours'] as const;
    const HeadcountKeys = ['spot1Headcount', 'spot2Headcount', 'spot3Headcount', 'spot4Headcount'] as const;
    if (displayState.isDisplayedManHour) {
      ManHourKeys.forEach((manHourKey, i) => {
        const headcountKey = HeadcountKeys[i];
        state.dailyPlans[dayIndex][headcountKey] = convertToHeadcount(dailyPlan[manHourKey]);
      });
    } else {
      HeadcountKeys.forEach((headcountKey, i) => {
        const manHourKey = ManHourKeys[i];
        state.dailyPlans[dayIndex][manHourKey] = convertToManHours(dailyPlan[headcountKey]);
      });
    }

    try {
      await budgetGroupPlanBoardMiscApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: dailyPlan.dt,
        planned_spot1_headcount: dailyPlan.spot1ManHours,
        planned_spot2_headcount: dailyPlan.spot2ManHours,
        planned_spot3_headcount: dailyPlan.spot3ManHours,
        planned_spot4_headcount: dailyPlan.spot4ManHours,
      });
      const calculated = calculateDailyPlan(dailyPlan);
      state.dailyPlans[dayIndex].requiredManHours = calculated.requiredManHours;
      state.dailyPlans[dayIndex].plannedManHours = calculated.plannedManHours;
      state.dailyPlans[dayIndex].totalSpot = calculated.totalSpot;
      updateOverviewState(state);
      notifySuccess(`${label}を更新しました`);
    } catch (err: any) {
      notifyError(`${label}の更新に失敗しました`, { cause: err });
    }
  };

  /**
   * 日付に紐づく実績値の更新
   */
  const updateMiscActualValue = async (dailyPlan: DailyPlan, label: string) => {
    if (!budgetGroup.value) {
      return;
    }
    if (!isExist(state.isReady)) {
      return;
    }

    const dayIndex = state.targetDates.findIndex((date) => date.getDate() === dailyPlan.dt.getDate());
    const ManHourKeys = ['regularActualManHours', 'spotActualManHours'] as const;
    const HeadcountKeys = ['regularActualHeadcount', 'spotActualHeadcount'] as const;
    if (displayState.isDisplayedManHour) {
      ManHourKeys.forEach((manHourKey, i) => {
        const headcountKey = HeadcountKeys[i];
        state.dailyPlans[dayIndex][headcountKey] = convertToHeadcount(dailyPlan[manHourKey]);
      });
    } else {
      HeadcountKeys.forEach((headcountKey, i) => {
        const manHourKey = ManHourKeys[i];
        state.dailyPlans[dayIndex][manHourKey] = convertToManHours(dailyPlan[headcountKey]);
      });
    }

    try {
      await budgetGroupPlanBoardMiscApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: state.actualDates[dayIndex],
        actual_regular_man_seconds: isExist(dailyPlan.regularActualManHours)
          ? dailyPlan.regularActualManHours * 3600
          : null,
        actual_spot_man_seconds: isExist(dailyPlan.spotActualManHours) ? dailyPlan.spotActualManHours * 3600 : null,
        actual_revenue: dailyPlan.actualRevenue,
        actual_cost: dailyPlan.actualCost,
        actual_indirect_cost: dailyPlan.indirectBalance,
      });
      const calculated = calculateDailyPlan(dailyPlan);
      state.dailyPlans[dayIndex].actualProfit = calculated.actualProfit;
      state.dailyPlans[dayIndex].totalActualManHours = calculated.totalActualManHours;
      updateOverviewState(state);
      notifySuccess(`${label}を更新しました`);
    } catch (err: any) {
      notifyError(`${label}の更新に失敗しました`, { cause: err });
    }
  };

  /**
   * 当月目標の更新
   */
  const updateTargetMonthlyValue = async () => {
    if (!budgetGroup.value) {
      return;
    }
    if (!isExist(state.isReady)) {
      return;
    }

    try {
      await budgetGroupPlanBoardMiscApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: state.targetDates[0],
        budget_target: isExist(state.targetMonthlyRevenue) ? state.targetMonthlyRevenue * 1000 : null,
        cost_target: isExist(state.targetMonthlyCost) ? state.targetMonthlyCost * 1000 : null,
      });
      updateOverviewState(state);
      notifySuccess('当月目標を更新しました');
    } catch (err: any) {
      notifyError('当月目標の更新に失敗しました', { cause: err });
    }
  };

  /**
   * メモの更新
   */
  const updateMemo = async (dailyPlan: DailyPlan) => {
    if (!budgetGroup.value) {
      return;
    }
    if (!isExist(state.isReady)) {
      return;
    }
    if (dailyPlan.memo === state.lastMemo) {
      return;
    }

    try {
      await budgetGroupPlanBoardMiscApi.upsert({
        workplace_id: workplaceId,
        budget_group_id: budgetGroup.value.id,
        dt: dailyPlan.dt,
        memo: dailyPlan.memo,
      });
      notifySuccess('メモを更新しました');
    } catch (err: any) {
      notifyError('メモの更新に失敗しました', { cause: err });
    }
  };

  provide({
    updateMiscPlanValue,
    updateMiscActualValue,
    updateTargetMonthlyValue,
    updateMemo,
  });
}

export function useBudgetGroupPlanBoardMisc(): InjectionValue {
  return inject();
}
