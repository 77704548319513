import { type TimeRange } from './TimeRange'

function isObject(value: unknown): value is Record<keyof any, unknown> {
  return typeof value === 'object' && value !== null
}

export function isTimeRange(value: unknown): value is TimeRange {
  return isObject(value) &&
    'startTime' in value &&
    (typeof value.startTime === 'number' || value.startTime === null) &&
    'endTime' in value &&
    (typeof value.endTime === 'number' || value.endTime === null)
}
